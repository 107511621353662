// This is used by UserSettings so no existing value should be changed!
export enum CollapsingControls {
    // pe-ui controls
    Overview = 114,
    Projects = 115,
    Designs = 116,
    NotificationsMainRight = 117,
    ValidationError = 118, // component moved
    NotificationsDesign = 119,  // component moved
    ReportTemplates = 125,
    BoundaryConditions = 126, // not used
    MenuGeneral = 127,
    MenuQuickStart = 128,
    NotificationsMainLeft = 129,
    FileImports = 136,

    /* New Homepage Controls */
    Favorites = 160,
    Templates = 161,
    CompanyProjects = 162,
    Shared = 163,
    SharedByMe = 164,
    SharedWithMe = 165,
    MyProjects = 166,
    QuickStart = 124, //re-used from old Homepage
    DesignsView = 167,
    InAppMarketing = 179, //re-used from old Homepage
    HiltiAplications = 145, //re-used from old Homepage
    Learning = 168,
    TemplatesSharedByMe = 169,
    TemplatesSharedWithMe = 170,
    /* End - New Homepage Controls */

    // pe-ui-pe controls - remove when no reference is found in pe-ui
    InfoSection = 121, // not used
    Utilization = 122, // component moved
    HandrailDesign = 123, // component moved
    BaseplateUtilization = 130, // component moved
    WeldsUtilization = 131, // component moved
    StiffenersUtilization = 132, // component moved
    ConcreteUtilization = 133, // component moved
    ProfileUtilization = 134, // component moved
    AnchorPlateDesign = 135, // component moved
    AnchorLoads = 142, // component moved
    WeldCurves = 146, // not used
    SmartBaseplateDesign = 150, // component moved

    // pe-ui-c2c controls - remove when no reference is found in pe-ui
    RebarDesign = 137, // not used
    ZoneUtilizationsC2C = 143, // component moved
    UtilizationsC2C = 144, // component moved
    DesignMethod = 147, // component moved
    RebarLoads = 148, // component moved
    ShearUtilization = 149, // component moved
    TopLayer1Utilization = 151, // component moved
    TopLayer2Utilization = 152, // component moved
    BottomLayer1Utilization = 153, // component moved
    BottomLayer2Utilization = 154, // component moved
    DrillLengthUtilization = 155, // component moved
    ConcreteBreakoutUtilization = 156, // component moved
    ConcreteSplittingUtilization = 157, // component moved
    StrutAndTiesUtilization = 158, // component moved
    PirShearHiltiUtilization = 171, // component moved
    PirShearUtilization = 172, // component moved
    PirAnchorDesignUtilization = 173, // component moved
    PirTensionUtilization = 174, // never used
    PirInstallationUtilization = 175, // component moved
    PirSpliceLengthUtilization = 176, // component moved
    PirSeismicSpliceLengthUtilization = 177, // never used
    DesignGuide = 178,
    WoodLinks = 180
}
