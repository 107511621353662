import { Injectable } from '@angular/core';
import { QueryServiceBase } from '@profis-engineering/pe-ui-common/services/query.common';

import { urlPath } from '../module-constants';

@Injectable({
    providedIn: 'root',
})
export class QueryService extends QueryServiceBase {
    private initialQuery: string;
    private excludedPaths: string[];

    constructor() {
        super();

        this.excludedPaths = [
            urlPath.authenticationCallback,
            urlPath.registrationCallback,
            urlPath.logout,
            urlPath.trimbleCallback
        ];

        this.initialQuery = '';
    }

    public storeInitialQuery() {
        if (!this.excludedPaths.some(path => window.location.pathname == path)) {
            this.initialQuery = window.location.search.replace('?', '');
        }
    }

    public getInitialQuery(includeLeadingMark = true): string {
        if (this.initialQuery.length > 0) {
            return includeLeadingMark ? `?${this.initialQuery}` : this.initialQuery;
        }

        return '';
    }
}
