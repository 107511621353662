

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { CallbackService } from '../services/callback.service';
import { handleGuardAction } from './guard-handler';

export const oauthCallbackGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router = inject(Router);
    const callbackService = inject(CallbackService);

    const code = state.root.queryParamMap.get('code');
    const loginState = state.root.queryParamMap.get('state');

    return handleGuardAction(router, async () => {
        await callbackService.processLoginCallback(code, loginState);

        return true;
    });
};
