import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';

import { MainComponent } from '../components/main/main.component';
import { openConfirmChangeForOffline } from '../helpers/offline-helper';
import { LocalizationService } from '../services/localization.service';
import { ModalService } from '../services/modal.service';
import { OfflineService } from '../services/offline.service';
import { RoutingService } from '../services/routing.service';
import { UserService } from '../services/user.service';
import { ApiService } from '../services/api.service';
import { BrowserService } from '../services/browser.service';
import { DocumentService } from '../services/document.service';

export const canDeactivateMainComponentGuard: CanDeactivateFn<MainComponent> = () => {
    const routingService = inject(RoutingService);
    const offlineService = inject(OfflineService);
    const modalService = inject(ModalService);
    const userService = inject(UserService);
    const localizationService = inject(LocalizationService);
    const documentService = inject(DocumentService);
    const apiService = inject(ApiService);
    const browserService = inject(BrowserService);

    openConfirmChangeForOffline(
        routingService,
        offlineService,
        modalService,
        localizationService,
        documentService,
        apiService,
        browserService,
        userService.design,
        false,
        window
    );

    return true;
};
