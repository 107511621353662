import { Component, OnInit } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import {
    KnownRegion
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';

import { LocalizationService } from '../../services/localization.service';
import { OfflineService } from '../../services/offline.service';
import { ProductInformationService } from '../../services/product-information.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { urlValidator } from '../../validators/url.validator';

@Component({
    selector: 'app-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {
    public loginEmail: string;
    public name: string;
    public companyName: string;
    public address: string;
    public phoneNumber: string;
    public email: string;
    public fax: string;
    public website: string;

    public submitted: boolean;
    public pendingSave: boolean;

    public emailValid = true;
    public emailValidator = [Validators.email];

    public websiteValid = true;
    public urlValidator = [urlValidator];

    public sapAccountDisclaimer: string;

    constructor(
        public localization: LocalizationService,
        private modalInstance: ModalInstance,
        private userService: UserService,
        private userSettingsService: UserSettingsService,
        private offline: OfflineService,
        private productInformationService: ProductInformationService
    ) {
        this.sapAccountDisclaimer = this.createSapAccountDisclaimer();
    }

    public get formValid() {
        return this.emailValid
            && this.websiteValid;
    }

    public get disabled() {
        return this.userService.isExternalOnlineRussianUser || this.submitted;
    }

    public get showSapAccountDisclaimer() {
        return this.userService.isExternalOnlineRussianUser;
    }

    public get softwareSellingLinkAvailable() {
        const link = this.getSoftwareSellingLink();
        return link != null
            && link != '';
    }

    public ngOnInit(): void {
        // do not close modal if save is pending
        this.modalInstance.setOnClosing(() => !this.pendingSave);

        // load the controls to text boxes
        this.loginEmail = this.userService.getUserName();

        if (this.userService.isExternalOnlineRussianUser) {
            if (this.userSettingsService.ccmsUserSettings != null) {
                this.name = this.userSettingsService.ccmsUserSettings.FullName;
                this.companyName = this.userSettingsService.ccmsUserSettings.CompanyName;
                this.address = this.userSettingsService.ccmsUserSettings.Address;
                this.phoneNumber = this.userSettingsService.ccmsUserSettings.Phone;
                this.email = this.userSettingsService.ccmsUserSettings.EmailAddress;
                this.fax = this.userSettingsService.ccmsUserSettings.Fax;
            }
        }
        else {
            this.name = this.userSettingsService.settings.user.generalName.value;
            this.companyName = this.userSettingsService.settings.user.companyDetails.companyName.value;
            this.address = this.userSettingsService.settings.user.companyDetails.address.value;
            this.phoneNumber = this.userSettingsService.settings.user.companyDetails.phoneNumber.value;
            this.email = this.userSettingsService.settings.user.companyDetails.email.value;
            this.fax = this.userSettingsService.settings.user.companyDetails.fax.value;
        }

        this.website = this.userSettingsService.settings.user.companyDetails.website.value;
    }

    public close() {
        this.modalInstance.close();
    }

    public openSoftwareSellingLink() {
        const link = this.getSoftwareSellingLink();
        this.offline.nativeExternalLinkOpen(link);
    }

    public save(form: NgForm) {
        if (this.submitted || !this.formValid || !form.valid) {
            return;
        }

        this.submitted = true;
        this.pendingSave = true;

        // copy meta-data
        if (!this.userService.isExternalOnlineRussianUser) {
            this.userSettingsService.settings.user.generalName.value = this.name;
            this.userSettingsService.settings.user.companyDetails.companyName.value = this.companyName;
            this.userSettingsService.settings.user.companyDetails.address.value = this.address;
            this.userSettingsService.settings.user.companyDetails.phoneNumber.value = this.phoneNumber;
            this.userSettingsService.settings.user.companyDetails.email.value = this.email;
            this.userSettingsService.settings.user.companyDetails.fax.value = this.fax;
        }

        this.userSettingsService.settings.user.companyDetails.website.value = this.website;

        // call user settings save method
        this.userSettingsService.save()
            .then(() => {
                this.pendingSave = false;
                this.close();
            })
            .catch((err) => {
                if (err instanceof Error) {
                    console.error(err);
                }

                this.submitted = false;
                this.pendingSave = false;
            });
    }

    private createSapAccountDisclaimer() {
        const region = this.userSettingsService.getCommonRegionById(KnownRegion.Russia);
        const regionLanguage = this.userSettingsService.getRegionLanguage(KnownRegion.Russia);

        return this.localization.getString('Agito.Hilti.Profis3.UserSettings.DataFromSAPAccount.Text')
            .replace('{countryContactEmail}', region.contactUrl)
            .replace('{countryContactPhone}', region.supportPhone)
            .replace('{privacyPolicy}', `<a href="${regionLanguage.hiltiDataPrivacyUrl}" target="_blank">${this.localization.getString('Agito.Hilti.Profis3.UserSettings.DataFromSAPAccount.Text.PrivacyPolicy')}</a>`);
    }

    private getSoftwareSellingLink() {
        return this.productInformationService.regionLinksForUser(this.userSettingsService.settings.application.general.regionId.value)?.SoftwareSellingLink;
    }
}
