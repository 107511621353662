<div class="modal-header" id="general-notes-window">
    <h3 class="modal-title" l10n="Agito.Hilti.Profis3.GeneralNotes.Title"></h3>

    <button type="button" class="close" (click)="close()" id="general-notes-window-x-button">
        <span>&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="content" [innerHtml]="textHtml"></div>
</div>

<div class="modal-footer">
    <button type="button" class="modal-button button button-default"
        id="general-notes-window-copy-to-clipboard-button"
        l10n="Agito.Hilti.Profis3.GeneralNotes.Copy"
        (click)="clipboard.copy(copyText)">
    </button>

    <button type="button" class="modal-button button button-CTA"
        id="general-notes-window-close-button"
        l10n="Agito.Hilti.Profis3.GeneralNotes.Close"
        (click)="close()">
    </button>
</div>
