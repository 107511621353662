<div class="modal-header">
    <h3 class="modal-title">{{title}}</h3>

    <button type="button" class="close" (click)="close()">
        <span>&times;</span>
    </button>
</div>

<div class="modal-body">
    <img [src]="url" alt="image" />
</div>
