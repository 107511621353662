import { CodeList, getCodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { stringNullOrEmpty } from '@profis-engineering/pe-ui-common/helpers/string-helper';

import { LocalizationService } from '../services/localization.service';

export function createDesignName(localization: LocalizationService, designType: CodeList, isEntryPointSplittedPIR = false) {
    let translatedNameText = '';

    if (isEntryPointSplittedPIR) {
        translatedNameText = localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Main.DesignTitle.Concrete2ConcretePirEU');
    }
    else if(designType != null) {
        translatedNameText = designType.getTranslatedNameText(getCodeListTextDeps(localization));
    }

    if (!stringNullOrEmpty(translatedNameText)) {
        translatedNameText = `${translatedNameText} - `
    }

    return `${translatedNameText}${localization.moment(new Date()).format('ll')}`;
}
