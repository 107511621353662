import sortBy from 'lodash-es/sortBy';

import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, TrackByFunction, ViewChild } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import {
    DropdownItem,
    DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    TextBoxProps
} from '@profis-engineering/pe-ui-common/components/text-box/text-box.common';
import {
    ITrimbleConnectFileInfo, ITrimbleConnectSession
} from '@profis-engineering/pe-ui-common/entities/trimble-connect';
import {
    TrimbleItemEntity as ITCItem, TrimbleProjectEntity as ITCProject
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.TrimbleConnectService.Shared.Entities';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import {
    DateTimePattern
} from '@profis-engineering/pe-ui-common/services/date-time.common';

import { Design } from '../../entities/design';
import { Project } from '../../entities/project';
import { DateTimeService } from '../../services/date-time.service';
import { ImportService } from '../../services/import.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { ITCItemType, ITCRegion, TrimbleConnectService } from '../../services/trimble-connect.service';
import {
    ITrimbleConnectBrowserTreeContext, ITrimbleConnectItem, TrimbleConnectBrowserMode,
    TrimbleConnectItemType
} from './trimble-connect-browser-models';

export interface ITrimbleConnectBrowserComponentInput {
    mode: TrimbleConnectBrowserMode;
    session: ITrimbleConnectSession;
    projectDesign?: Blob;
    project?: Project;
    oldDesign?: Design;
    saveType?: TrimbleConnectItemType;
    onDesignImported?: (design: Design, project: Project, renameFile?: boolean, openDesign?: boolean) => Promise<boolean | void> | boolean | void;
}

export enum Loading {
    project,
    folder,
    delete,
    rename
}

@Component({
    selector: 'app-trimble-connect-browser',
    templateUrl: './trimble-connect-browser.component.html',
    styleUrls: ['./trimble-connect-browser.component.scss']
})
export class TrimbleConnectBrowserComponent implements OnInit {
    public projectDropdown: DropdownProps<ITCProject> = {};
    public serverDropdown: DropdownProps<ITCRegion> = {};
    public fileName: TextBoxProps = {};
    public location: TextBoxProps;
    public rootFolder: ITrimbleConnectItem;
    public opendFolder: ITrimbleConnectItem;
    public selectedFolderItem: ITrimbleConnectItem;
    public loading: Loading;
    public treeContext: ITrimbleConnectBrowserTreeContext;
    public treeNodeItem: ITrimbleConnectItem;
    public submitted: boolean;
    public closing: boolean;
    public renameValue: string;
    public renamingItem: ITrimbleConnectItem;

    public loadingEnum = Loading;
    public trimbleConnectItemTypeEnum = TrimbleConnectItemType;
    public requiredValidator = Validators.required;

    @ViewChild('form')
    public form: NgForm;

    private pendingOpenFolders: Record<string, Promise<ITrimbleConnectItem>>;
    private selectFolder: ITrimbleConnectItem;

    constructor(
        public localization: LocalizationService,
        private dateTime: DateTimeService,
        private numberService: NumberService,
        private trimbleConnect: TrimbleConnectService,
        private modalService: ModalService,
        private importService: ImportService,
        private modalInstance: ModalInstance<ITrimbleConnectBrowserComponentInput>,
        private changeDetector: ChangeDetectorRef
    ) { }

    public get mode() {
        return this.modalInstance.input.mode;
    }

    public get title() {
        if (this.mode == TrimbleConnectBrowserMode.import) {
            return 'Agito.Hilti.Profis3.TrimbleConnectBrowser.Title.Open';
        }

        if (this.modalInstance.input.projectDesign != null) {
            return 'Agito.Hilti.Profis3.TrimbleConnectBrowser.Title.Export';
        }

        return 'Agito.Hilti.Profis3.TrimbleConnectBrowser.Title.Save';
    }

    public get formValid() {
        return this.serverDropdown.isValid && this.serverDropdown.selectedValue != null
            && this.projectDropdown.isValid && this.projectDropdown.selectedValue != null
            && (!this.showLocationTextBox || this.fileName.isValid);
    }

    public get selectDisabled() {
        let disabled = this.loading != null || this.submitted;

        if (this.selectedFolderItem == null || this.selectedFolderItem.type != TrimbleConnectItemType.folder) {
            disabled = disabled || !this.formValid || (this.form.enabled && !this.form.valid);
        }

        if (this.mode == TrimbleConnectBrowserMode.import) {
            disabled = disabled || this.selectedFolderItem == null;
        }

        return disabled;
    }

    public get selectText() {
        if (this.selectedFolderItem != null && this.selectedFolderItem.type == TrimbleConnectItemType.folder) {
            return 'Agito.Hilti.Profis3.TrimbleConnectBrowser.Select.Open';
        }

        if (this.mode == TrimbleConnectBrowserMode.import) {
            return 'Agito.Hilti.Profis3.TrimbleConnectBrowser.Select.Import';
        }

        if ((this.mode == TrimbleConnectBrowserMode.exportDesign || this.mode == TrimbleConnectBrowserMode.selectReportLocation) && this.modalInstance.input.projectDesign != null) {
            return 'Agito.Hilti.Profis3.TrimbleConnectBrowser.Select.Export';
        }

        return 'Agito.Hilti.Profis3.TrimbleConnectBrowser.Select';
    }

    public get showLocationTextBox() {
        if (this.mode == TrimbleConnectBrowserMode.import) {
            return false;
        }

        return true;
    }

    ngOnInit(): void {
        // Do not close modal if save is pending
        this.modalInstance.setOnClosing(() => {
            if ((this.submitted || this.loading == Loading.delete || this.loading == Loading.rename) && !this.closing) {
                return false;
            }

            return true;
        });

        this.pendingOpenFolders = {};

        this.treeContext = {
            selectedItem: null,
            openFolder: this.openFolder.bind(this)
        };
        this.treeNodeItem = null;

        this.serverDropdown = {};

        // load regions
        const regions = this.trimbleConnect.getRegions();
        this.serverDropdown.items = regions.map(region => {
            return {
                value: region,
                text: this.localization.getString(region.displaykey)
            } as DropdownItem<ITCRegion>;
        });
        this.serverDropdown.selectedValue =
            this.serverDropdown.items != null && this.serverDropdown.items.length > 0
                ? this.serverDropdown.items[0].value
                : null;

        this.modalInstance.input.session.region = this.serverDropdown.selectedValue.region;
        this.trimbleConnect.region = this.modalInstance.input.session.region;

        this.projectDropdown = {
            title: this.localization.getString('Agito.Hilti.Profis3.TrimbleConnectBrowser.Project'),
            items: [
                {
                    value: null,
                    text: ''
                }
            ],
            selectedValue: null
        };

        if (this.mode == TrimbleConnectBrowserMode.exportDesign || this.mode == TrimbleConnectBrowserMode.selectReportLocation) {
            this.fileName = {
                title: this.localization.getString('Agito.Hilti.Profis3.TrimbleConnectBrowser.FileName'),
                maxLength: 255 - this.getFolderItemTypeExtension(this.modalInstance.input.saveType).length,
            };
        }

        this.location = {
            title: this.localization.getString('Agito.Hilti.Profis3.TrimbleConnectBrowser.Location')
        };

        this.loading = Loading.project;

        this.getAllProjects();
    }

    public formatFolderItemIcon(type: TrimbleConnectItemType) {
        switch (type) {
            case TrimbleConnectItemType.folder:
                return 'sprite-folder';

            case TrimbleConnectItemType.pa2:
                return 'sprite-pa2';

            case TrimbleConnectItemType.pe:
                return 'sprite-pa3';

            case TrimbleConnectItemType.pdf:
                return 'sprite-pdf';

            default:
                throw new Error('Unknown folder item type.');
        }
    }

    public formatFolderItemDateModified(date: Date) {
        return this.dateTime.format(date, DateTimePattern.dateTime);
    }

    public formatFolderItemType(type: TrimbleConnectItemType) {
        switch (type) {
            case TrimbleConnectItemType.folder:
                return this.localization.getString('Agito.Hilti.Profis3.TrimbleConnectBrowser.FolderView.Type.Folder');

            case TrimbleConnectItemType.pa2:
                return this.localization.getString('Agito.Hilti.Profis3.TrimbleConnectBrowser.FolderView.Type.Pa2');

            case TrimbleConnectItemType.pe:
                return this.localization.getString('Agito.Hilti.Profis3.TrimbleConnectBrowser.FolderView.Type.Pe');

            case TrimbleConnectItemType.pdf:
                return this.localization.getString('Agito.Hilti.Profis3.TrimbleConnectBrowser.FolderView.Type.Pdf');

            default:
                throw new Error('Unknown folder item type.');
        }
    }

    public formatFolderItemSize(size: number) {
        if (size == null || size == 0) {
            return '0 KB';
        }

        let postfix: string = null;
        let num: string = null;
        let multiplier = 0;

        while (multiplier == 0 || multiplier < 3 && Math.floor(size / 1000) > 0) {
            size /= 1000;
            multiplier++;
        }

        switch (multiplier) {
            case 1:
                postfix = 'KB';
                break;

            case 2:
                postfix = 'MB';
                break;

            case 3:
                postfix = 'GB';
                break;
        }

        num = this.numberService.format(size, 2);

        if (postfix != null) {
            return `${num} ${postfix}`;
        }

        return num;
    }

    public select() {
        if (this.submitted) {
            return;
        }

        if (this.selectedFolderItem != null && this.selectedFolderItem.type == TrimbleConnectItemType.folder) {
            this.openFolder(this.selectedFolderItem, true);

            return;
        }

        if (!this.formValid || (this.form.enabled && !this.form.valid)) {
            return;
        }

        if (this.mode == TrimbleConnectBrowserMode.import) {
            this.submitted = true;

            const fileInfo: ITrimbleConnectFileInfo = {
                id: this.selectedFolderItem.id,
                name: this.selectedFolderItem.name,
                fullName: this.selectedFolderItem.fullName,
                location: this.location.value
            };

            this.trimbleConnect.getFile(fileInfo.id)
                .then(projectDesign => this.importService.importDesign(this.modalInstance.input.project, projectDesign, fileInfo.fullName, this.modalInstance.input.oldDesign))
                .then(result => Promise.resolve(this.modalInstance.input.onDesignImported != null ? this.modalInstance.input.onDesignImported(result.design as unknown as Design, result.project as unknown as Project, result.renameDesign, result.openDesign) : undefined))
                .finally(() => {
                    this.submitted = false;
                })
                .then(loading => {
                    if (loading) {
                        this.submitted = true;
                    }

                    this.close(fileInfo);
                });
        }
        else if (this.mode == TrimbleConnectBrowserMode.exportDesign) {
            const fileInfo: ITrimbleConnectFileInfo = {
                id: this.opendFolder.id,
                name: this.fileName.value,
                fullName: this.fileName.value + this.getFolderItemTypeExtension(this.modalInstance.input.saveType),
                location: this.location.value
            };

            // export
            if (this.modalInstance.input.projectDesign != null) {
                this.submitted = true;

                this.trimbleConnect.uploadFile(fileInfo.id, fileInfo.fullName, this.modalInstance.input.projectDesign)
                    .then(() => {
                        this.close(fileInfo);
                    })
                    .catch(err => {
                        if (err instanceof Error) {
                            console.error(err);
                        }

                        this.submitted = false;
                    });
            }
            else {
                this.close(fileInfo);
            }
        }
        else if (this.mode == TrimbleConnectBrowserMode.selectReportLocation) {
            const fileInfo: ITrimbleConnectFileInfo = {
                id: this.opendFolder.id,
                name: this.fileName.value,
                fullName: this.fileName.value + this.getFolderItemTypeExtension(this.modalInstance.input.saveType),
                location: this.location.value
            };

            this.close(fileInfo);
        }
    }

    public close(fileInfo: ITrimbleConnectFileInfo) {
        this.closing = true;
        this.modalInstance.close(fileInfo);
    }

    public dismiss(reason?: string) {
        this.modalInstance.dismiss(reason);
    }

    public onProjectChanged() {
        const project = this.projectDropdown.selectedValue;

        if (project != null) {
            this.loading = Loading.project;

            this.trimbleConnect.getFolderItems(project.RootId)
                .then(items => {
                    if (project.Id == this.projectDropdown.selectedValue.Id) {
                        this.treeNodeItem = this.rootFolder = {
                            id: project.RootId,
                            dateModified: null,
                            items: this.parseITCItems(items, `/${project.Name}`),
                            name: project.Name,
                            fullName: project.Name,
                            path: '/',
                            type: TrimbleConnectItemType.folder
                        };

                        this.treeContext.selectedItem = this.rootFolder;
                        this.opendFolder = this.rootFolder;
                        this.opendFolderChange();

                        this.selectedFolderItem = null;
                        this.selectedFolderItemChanged();

                        this.selectFolderItemByFileName();
                    }
                })
                .finally(() => {
                    if (project.Id == this.projectDropdown.selectedValue.Id) {
                        this.loading = null;
                    }
                });
        }
    }

    public onServerChanged() {
        const server = this.serverDropdown.selectedValue;

        this.modalInstance.input.session.region = server.region;
        this.trimbleConnect.region = this.modalInstance.input.session.region;

        this.projectDropdown.selectedValue = null;
        this.opendFolder = null;
        this.treeNodeItem = null;

        this.getAllProjects();
    }

    public trackOpendFolderItemById: TrackByFunction<ITrimbleConnectItem> = function (_: number, item: ITrimbleConnectItem) {
        return item.id;
    };

    public folderItemSelected(event: MouseEvent, item: ITrimbleConnectItem) {
        const target = event.target as HTMLElement;
        const ignoreElements = document.querySelectorAll('.dropdown-menu-container, .folder-item.selected .rename-container');

        if (!Array.from(ignoreElements).some(ignoreElement => ignoreElement.contains(target))) {
            if (this.mode == TrimbleConnectBrowserMode.exportDesign || this.mode == TrimbleConnectBrowserMode.selectReportLocation) {
                if (this.modalInstance.input.saveType == item.type) {
                    this.fileNameChanged(item.name);

                    this.selectedFolderItem = item;
                    this.selectedFolderItemChanged();
                }
                else if (item.type == TrimbleConnectItemType.folder) {
                    this.selectedFolderItem = item;
                    this.selectedFolderItemChanged();
                }
            }
            else {
                this.selectedFolderItem = item;
                this.selectedFolderItemChanged();
            }
        }
    }

    public folderItemOpened(event: MouseEvent) {
        const target = event.target as HTMLElement;
        const ignoreElements = document.querySelectorAll('.dropdown-menu-container, .folder-item .rename-container');

        if (!Array.from(ignoreElements).some(ignoreElement => ignoreElement.contains(target))) {
            this.select();
        }
    }

    public fileNameChanged(value: string) {
        if (this.fileName != null) {
            const oldValue = this.fileName.value;
            this.fileName.value = value;

            const fixedValue = value.split('').filter(char => char != '/').join('');

            if (value != fixedValue) {
                if (oldValue == fixedValue) {
                    // The value is changed inside the same cycle so change detection
                    // needs to be run again before the new change
                    this.changeDetector.detectChanges();
                }
                this.fileName.value = fixedValue;
            }

            this.selectFolderItemByFileName();
        }
    }

    public openRenaming(event: MouseEvent, item: ITrimbleConnectItem) {
        event.stopPropagation();

        this.renamingItem = item;
        this.renameValue = item.name;

        setTimeout(() => {
            document.querySelector<HTMLElement>('.folder-item.selected .rename-container .rename-textbox')?.shadowRoot?.querySelector<HTMLElement>('.input')?.focus();
        });
    }

    public blurRenameItem(item: ITrimbleConnectItem) {
        setTimeout(() => {
            const activeElement = document.activeElement as HTMLElement;
            const renameContainer = document.querySelector('.folder-item.selected .rename-container');

            if (renameContainer.contains(activeElement)) {
                const renameContainerButtons = document.querySelector('.folder-item.selected .rename-container .button-group');
                if (!renameContainerButtons.contains(activeElement)) {
                    this.renameItem(item);
                }
            }
            else {
                this.dismissRenameItem();
            }
        });
    }

    public renameItem(item: ITrimbleConnectItem) {
        const name = this.renameValue?.trim();

        if (name != null && name != '' && item.name != name) {
            this.loading = Loading.rename;

            this.trimbleConnect.renameFolder(item.id, name)
                .then(() => {
                    return this.openFolder(this.opendFolder, false, true);
                })
                .catch((response: unknown) => {
                    const body = response instanceof HttpErrorResponse && response.status > 0
                        ? response.error
                        : response instanceof HttpResponse
                            ? response.body
                            : undefined;

                    // refresh folder on no folder error
                    if (response != null && body != null && body.errorcode == 'INVALID_OPERATION_FOLDER_DELETED') {
                        return this.openFolder(this.opendFolder, false, true)
                            .then(() => { throw response; });
                    }

                    throw response;
                })
                .finally(() => {
                    this.loading = null;

                    this.dismissRenameItem();
                });
        }

        this.dismissRenameItem();
    }

    public dismissRenameItem() {
        this.renamingItem = null;
    }

    public deleteItem(event: MouseEvent, item: ITrimbleConnectItem) {
        event.stopPropagation();

        const translationType = item.type == TrimbleConnectItemType.folder ? 'Folder' : 'File';
        const parentFolder = this.opendFolder;

        return this.modalService.openConfirmChange({
            id: 'delete-item',
            title: this.localization.getString(`Agito.Hilti.Profis3.TrimbleConnectBrowser.FolderView.Delete.${translationType}.Title`),
            message: this.localization.getString(`Agito.Hilti.Profis3.TrimbleConnectBrowser.FolderView.Delete.${translationType}.Message`),
            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.TrimbleConnectBrowser.FolderView.Delete'),
            cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.TrimbleConnectBrowser.FolderView.Cancel'),
            onConfirm: (modal) => {
                this.loading = Loading.delete;

                this.trimbleConnect.deleteFolder(item.id)
                    .then(() => {
                        return this.openFolder(parentFolder, false, true);
                    })
                    .catch((response: unknown) => {
                        const body = response instanceof HttpErrorResponse && response.status > 0
                            ? response.error
                            : response instanceof HttpResponse
                                ? response.body
                                : undefined;

                        // refresh folder on no folder error
                        if (response != null && body != null && body.errorcode == 'INVALID_OPERATION_FOLDER_DELETED') {
                            return this.openFolder(this.opendFolder, false, true);
                        }

                        throw response;
                    })
                    .finally(() => {
                        this.loading = null;
                    });

                modal.close();
            },
            onCancel: (modal) => {
                modal.close();
            }
        });
    }

    public onModalClick(event: MouseEvent) {
        if (this.selectedFolderItem == null || this.selectedFolderItem.type != TrimbleConnectItemType.folder) {
            return;
        }

        const dropdownElementsSelectors = [
            '.dropdown-items.opend',
            '.dropdown.show'
        ];
        const dropdownElements = document.querySelectorAll('#trimbleConnectBrowserModal ' + dropdownElementsSelectors.join(', #trimbleConnectBrowserModal '));
        if (dropdownElements.length > 0) {
            // Project dropdown or context menu open
            return;
        }

        // clear selected folder when clicking on white space
        const target = event.target as HTMLElement;
        const ignoreElementsSelectors = [
            '.close',
            '.project .control-container',
            '.tree-view-container',
            '.file-name .input',
            '.location .input',
            '.cancel-button',
            '.select-button',
            '.folder-item'
        ];

        const ignoreElements = document.querySelectorAll('#trimbleConnectBrowserModal ' + ignoreElementsSelectors.join(', #trimbleConnectBrowserModal '));
        if (Array.from(ignoreElements).some(ignoreElement => ignoreElement.contains(target))) {
            // Ignored container containing current target
            return;
        }

        this.selectedFolderItem = null;
        this.selectFolderItemByFileName();
    }

    private openFolder(folder: ITrimbleConnectItem, select?: boolean, refresh?: boolean): Promise<ITrimbleConnectItem> {
        const projectId = this.projectDropdown.selectedValue;

        if (select) {
            this.selectFolder = folder;
            this.loading = this.loading || Loading.folder;
        }

        if (folder.items == null || refresh) {
            if (this.pendingOpenFolders[folder.id] == null) {
                // load folder content
                return this.pendingOpenFolders[folder.id] = this.trimbleConnect.getFolderItems(folder.id)
                    .then(itcItems => {
                        // while we are loading the folder the selected project might change
                        if (projectId == this.projectDropdown.selectedValue) {
                            folder.items = this.parseITCItems(itcItems, `${folder.path.endsWith('/') ? folder.path.slice(0, -1) : folder.path}/${folder.name}/`);

                            // only select if response is the latest pending select folder
                            if (this.selectFolder != null && this.selectFolder.id == folder.id) {
                                this.opendFolder = folder;
                                this.opendFolderChange();

                                this.selectedFolderItem = null;
                                this.selectedFolderItemChanged();

                                this.treeContext.selectedItem = folder;
                            }
                        }

                        return folder;
                    })
                    .finally(() => {
                        if (projectId == this.projectDropdown.selectedValue) {
                            delete this.pendingOpenFolders[folder.id];

                            if (Object.keys(this.pendingOpenFolders).length == 0 && this.loading == Loading.folder) {
                                this.loading = null;
                            }

                            if (this.selectFolder != null && this.selectFolder.id == folder.id) {
                                this.selectFolder = null;
                            }
                        }
                    });
            }
        }
        else {
            if (Object.keys(this.pendingOpenFolders).length == 0 && this.loading == Loading.folder) {
                this.loading = null;
            }

            if (select) {
                this.opendFolder = folder;
                this.opendFolderChange();

                this.selectedFolderItem = null;
                this.selectedFolderItemChanged();

                this.treeContext.selectedItem = folder;
            }
        }

        // return pending promise if we have one
        if (this.pendingOpenFolders[folder.id] != null) {
            return this.pendingOpenFolders[folder.id];
        }

        return Promise.resolve(folder);
    }

    private opendFolderChange() {
        this.setLocation();
    }

    private selectedFolderItemChanged() {
        this.setLocation();
    }

    private setLocation() {
        let file: ITrimbleConnectItem = null;

        // selected file/folder
        if (this.selectedFolderItem != null && (this.mode == TrimbleConnectBrowserMode.import || this.selectedFolderItem.type != TrimbleConnectItemType.folder)) {
            file = this.selectedFolderItem;
        }
        else if (this.opendFolder != null) {
            file = this.opendFolder;
        }

        // location
        if (file != null) {
            this.location.value = `${file.path.endsWith('/') ? file.path.slice(0, -1) : file.path}/${file.fullName}${file.type == TrimbleConnectItemType.folder ? '/' : ''}`;
        }
        else {
            this.location.value = '';
        }

        // we have a new file name
        if ((this.mode == TrimbleConnectBrowserMode.exportDesign || this.mode == TrimbleConnectBrowserMode.selectReportLocation) &&
            (this.selectedFolderItem == null || this.selectedFolderItem.type == TrimbleConnectItemType.folder)) {

            const fileName = this.fileName.value?.trim();

            if (fileName != null && fileName != '') {
                this.location.value += fileName + this.getFolderItemTypeExtension(this.modalInstance.input.saveType);
            }
        }
    }

    private selectFolderItemByFileName() {
        if (this.mode == TrimbleConnectBrowserMode.exportDesign || this.mode == TrimbleConnectBrowserMode.selectReportLocation) {
            const fileName = this.fileName.value?.trim();

            // select the written file
            if (this.opendFolder != null) {
                const files = this.opendFolder.items.filter(item => item.type == this.modalInstance.input.saveType);
                this.selectedFolderItem = files.find(file => file.name == fileName);
                this.selectedFolderItemChanged();
            }

            this.setLocation();
        }
    }

    private getItemType(itcType: ITCItemType, name: string) {
        if (itcType == 'FOLDER') {
            return TrimbleConnectItemType.folder;
        }

        if (itcType == 'FILE') {
            const extension = '.' + name.split('.').pop();

            if (extension == '.pa2') {
                return TrimbleConnectItemType.pa2;
            }

            if (extension == '.pe') {
                return TrimbleConnectItemType.pe;
            }

            if (extension == '.pdf') {
                return TrimbleConnectItemType.pdf;
            }
        }

        throw new Error('Unknown file type.');
    }

    private getFolderItemTypeExtension(type: TrimbleConnectItemType) {
        switch (type) {
            case TrimbleConnectItemType.pa2:
                return '.pa2';

            case TrimbleConnectItemType.pe:
                return '.pe';

            case TrimbleConnectItemType.pdf:
                return '.pdf';

            default:
                throw new Error('Unknown folder item type.');
        }
    }

    private getNameFromFullName(fullName: string, type: TrimbleConnectItemType) {
        let name = fullName?.trim();

        if (type == TrimbleConnectItemType.pa2) {
            name = this.removeEndString(name, '.pa2');
        }
        else if (type == TrimbleConnectItemType.pe) {
            name = this.removeEndString(name, '.pe');
        }
        else if (type == TrimbleConnectItemType.pdf) {
            name = this.removeEndString(name, '.pdf');
        }

        return name;
    }

    private parseITCItems(itcItems: ITCItem[], path: string) {
        let items: ITrimbleConnectItem[] = [];

        // parse
        for (const itcItem of itcItems) {
            items.push(this.parseITCItem(itcItem, path));
        }

        // filter
        if (this.mode == TrimbleConnectBrowserMode.exportDesign || this.mode == TrimbleConnectBrowserMode.selectReportLocation) {
            items = items.filter(item => item.type == TrimbleConnectItemType.folder || this.modalInstance.input.saveType == item.type);
        }
        else {
            items = items.filter(item => item.type == TrimbleConnectItemType.folder || item.type == TrimbleConnectItemType.pa2 || item.type == TrimbleConnectItemType.pe);
        }

        // sort
        items = this.sortFolderItems(items);

        return items;
    }

    private parseITCItem(item: ITCItem, path: string) {
        const type = this.getItemType(item.Type as ITCItemType, item.Name);
        const name = this.getNameFromFullName(item.Name, type);

        return {
            id: item.Id,
            dateModified: item.ModifiedOn,
            size: item.Size,
            fullName: item.Name,
            name,
            path,
            type,
            expanded: false
        } as ITrimbleConnectItem;
    }

    private getAllProjects() {
        this.trimbleConnect.getAllProjects()
            .then(projects => {
                projects = sortBy(projects, project => project.Name);

                this.projectDropdown.items = projects.map(project => {
                    return {
                        value: project,
                        text: project.Name
                    };
                });

                if (this.projectDropdown.items != null && this.projectDropdown.items.length > 0) {
                    this.projectDropdown.selectedValue = this.projectDropdown.items[0].value;
                    this.onProjectChanged();
                }
            })
            .finally(() => {
                this.loading = null;

                setTimeout(() => document.querySelector<HTMLElement>('#trimbleConnectBrowserModal .project #trimbleConnectProjectDropdown')?.shadowRoot?.querySelector<HTMLElement>('.dropdown-button')?.focus());
            });
    }

    private sortFolderItems(items: ITrimbleConnectItem[]) {
        // sort by name (folders first) then by id
        return sortBy(items, item => item.type == TrimbleConnectItemType.folder ? `a${item.name}` : `b${item.name}`, item => item.id);
    }

    private removeEndString(value: string, end: string) {
        if (value == null || value == '') {
            return value;
        }

        if (value.endsWith(end)) {
            return value.substring(0, value.length - end.length);
        }

        return value;
    }
}
