import uniq from 'lodash-es/uniq';

import { Injectable } from '@angular/core';
import { Unit as UnitCodeList } from '@profis-engineering/pe-ui-common/entities/code-lists/unit';
import { IUnitProviderObject } from '@profis-engineering/pe-ui-common/entities/design';
import { UnitGroup, UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import {
    conversionMatrixDensity, conversionMatrixForce, conversionMatrixLength, conversionMatrixMoment,
    conversionMatrixPercentage, conversionMatrixSpecificWeight, conversionMatrixStress,
    conversionMatrixTime, conversionMatrixVelocity, UnitPrecision, UnitServiceBase, UnitValue
} from '@profis-engineering/pe-ui-common/services/unit.common';
import {
    UIProperty
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';

import { UnitHelperC2C } from '../helpers/bw-compat/unit-helper-c2c';
import { UnitHelperPE } from '../helpers/bw-compat/unit-helper-pe';
import { UserService } from '../services/user.service';
import { CommonCodeListService } from './common-code-list.service';
import { NumberService, NumberType } from './number.service';

@Injectable({
    providedIn: 'root'
})
export class UnitService extends UnitServiceBase {
    // C2C-10498: cleanup needed!
    private unitHelperC2C: UnitHelperC2C;
    private unitHelperPE: UnitHelperPE;

    constructor(
        private user: UserService,
        private number: NumberService,
        private commonCodeList: CommonCodeListService
    ) {
        super();
        this.unitHelperC2C = new UnitHelperC2C();
        this.unitHelperPE = new UnitHelperPE();
    }

    public parseUnitValue(value: string, unitGroup: UnitGroup, defaultUnit?: Unit, decimalSeparator?: string, groupSeparator?: string) {
        if (defaultUnit === undefined) {
            defaultUnit = this.getDefaultUnit(unitGroup);
        }

        if (value != null && typeof value == 'number') {
            return new UnitValue(value, defaultUnit);
        }

        if (value == null || (value = value.trim()) == '') {
            return null;
        }

        const posibleUnits = this.getStringEndUnits(value, unitGroup);

        // try one unit at a time to find a number
        for (const { unit, unitString } of posibleUnits) {
            const numberString = value.substring(0, value.length - unitString.length);
            const numberValue = this.parseNumber(numberString, decimalSeparator, groupSeparator);

            if (numberValue != null && !Number.isNaN(numberValue)) {
                return new UnitValue(numberValue, unit);
            }
        }

        // parse the whole value as number
        const numberValue = this.parseNumber(value, decimalSeparator, groupSeparator);
        if (numberValue == null || Number.isNaN(numberValue)) {
            return this.NaNUnitValue();
        }

        return new UnitValue(numberValue, defaultUnit);
    }

    public parseUnknownUnitValue(value: string, decimalSeparator?: string, groupSeparator?: string) {
        if (value == null || value == '') {
            return null;
        }

        for (const unitGroup of this.getUnitGroups()) {
            const unitValue = this.parseUnitValue(value, unitGroup, null, decimalSeparator, groupSeparator);

            if (unitValue != null && !Number.isNaN(unitValue.value)) {
                return unitValue;
            }
        }

        return this.NaNUnitValue();
    }

    // Get increment/decrement value based on imperial or metric lenght unit. If metric than value is 1 if imperial value is 1/8 of inch
    // we use milimeters (internal) for increment/decrement.
    // If stepValue is defined, we convert it if needed.
    public incDecValueByUnit(unit: Unit, stepValue?: number) {
        if (stepValue) {
            return this.convertUnitValueFromInternalUnitValue({ value: stepValue, unit: unit }).value;
        }

        if (unit == Unit.inch) {
            return 0.125;
        }
        else if (unit == Unit.ft) {
            return 0.1;
        }
        else if (unit == Unit.mi) {
            return 0.000002;
        }
        else {
            return 1;
        }
    }

    public getUnitGroups() {
        return [
            UnitGroup.Area,
            UnitGroup.Force,
            UnitGroup.Length,
            UnitGroup.Moment,
            UnitGroup.Stress,
            UnitGroup.Temperature,
            UnitGroup.Percentage,
            UnitGroup.ForcePerLength,
            UnitGroup.Time,
            UnitGroup.LengthLarge,
            UnitGroup.StressSmall,
            UnitGroup.Angle,
            UnitGroup.Velocity,
            UnitGroup.MomentPerLength,
            UnitGroup.Density,
            UnitGroup.AreaPerLength,
            UnitGroup.SpecificWeight,
            UnitGroup.ItemsPerArea,
            UnitGroup.Volume
        ];
    }

    public getUnits() {
        const unitGroups = this.getUnitGroups();

        return uniq(unitGroups.map(unitGroup => this.getUnitsForUnitGroup(unitGroup)).flat());
    }

    public formatUnitValue(
        value: UnitValue,
        precision?: number,
        decimalSeparator?: string,
        groupSeparator?: string,
        uiProperty?: number,
        toFixed?: boolean
    ) {
        if (value == null) {
            return null;
        }

        return this.formatUnitValueArgs(value.value, value.unit, precision, decimalSeparator, groupSeparator, uiProperty, true, toFixed);
    }

    public formatUnitValueArgs(
        value: number,
        unit?: Unit,
        precision?: number,
        decimalSeparator?: string,
        groupSeparator?: string,
        uiProperty?: number,
        appendUnit = true,
        toFixed = false
    ) {
        if (value == null) {
            return null;
        }

        if (unit == null || unit == Unit.None) {
            return this.formatNumber(value, precision, toFixed, decimalSeparator, groupSeparator);
        }

        const valueString = this.formatNumber(
            value,
            precision ?? this.getPrecision(unit, uiProperty),
            toFixed,
            decimalSeparator,
            groupSeparator
        );

        if (appendUnit) {
            return this.appendUnitToValueString(value, valueString, unit);
        }
        return valueString;
    }

    public appendUnitToValueString(value: number, valueString: string, unit?: Unit) {
        if (unit == null || unit == Unit.None) {
            return valueString;
        }

        if (unit == Unit.percent) {
            return valueString + this.formatUnit(unit);
        }

        if (Number.isFinite(value)) {
            return `${valueString} ${this.formatUnit(unit)}`;
        }

        return valueString;
    }

    public appendPrecisionLossSymbolToValueString(value: number, valueString: string) {
        if (Number.isFinite(value)) {
            return `${valueString}…`;
        }

        return valueString;
    }

    public getUnitGroupFromUnit(unit: Unit) {
        return (Math.floor(unit / 100) * 100) as UnitGroup;
    }

    public getUnitsForUnitGroup(unitGroup: UnitGroup) {
        switch (unitGroup) {
            case UnitGroup.Angle:
                return [Unit.degree, Unit.rad];
            case UnitGroup.Area:
                return [Unit.mm2, Unit.cm2, Unit.m2, Unit.inch2, Unit.ft2];
            case UnitGroup.Force:
                return [Unit.N, Unit.daN, Unit.kN, Unit.lb, Unit.Kip, Unit.kgf, Unit.tonf];
            case UnitGroup.Length:
            case UnitGroup.LengthLarge:
                return [Unit.mm, Unit.cm, Unit.m, Unit.inch, Unit.ft, Unit.mi];
            case UnitGroup.Moment:
                return [Unit.Nmm, Unit.Nm, Unit.daNm, Unit.kNm, Unit.in_lb, Unit.ft_lb, Unit.in_kip, Unit.ft_kip, Unit.kgfm, Unit.kgfcm];
            case UnitGroup.Stress:
            case UnitGroup.StressSmall:
                return [Unit.Nmm2, Unit.PSI, Unit.KSI, Unit.kNm2, Unit.MPa, Unit.Nm2, Unit.kgfcm2, Unit.PSF];
            case UnitGroup.Temperature:
                return [Unit.C, Unit.F];
            case UnitGroup.Percentage:
                return [Unit.percent, Unit.permile];
            case UnitGroup.Time:
                return [Unit.min, Unit.h, Unit.s, Unit.ms];
            case UnitGroup.ForcePerLength:
                // Force per length units are combinations of Force and Length.
                // NOTE: if appropriate Length or Force unit is missing, unit converter will crash.
                return [Unit.N_mm, Unit.N_m, Unit.daN_m, Unit.kN_m, Unit.lb_in, Unit.lb_ft, Unit.Kip_in, Unit.Kip_ft, Unit.kgf_m, Unit.kgf_cm, Unit.kN_mm];
            case UnitGroup.Velocity:
                return [Unit.m_s];
            case UnitGroup.MomentPerLength:
                // Moment per length units are combinations of Moment and Length.
                // NOTE: if appropriate Length or Moment unit is missing, unit converter will crash.
                return [Unit.Nmm_mm, Unit.kNm_m, Unit.daNm_m, Unit.Nm_m, Unit.kgfm_m, Unit.in_lb_in, Unit.ft_lb_in, Unit.ft_lb_ft, Unit.ft_kip_ft, Unit.in_kip_in, Unit.ft_kip_in, Unit.kgfcm_cm];
            case UnitGroup.Density:
                return [Unit.kg_m3, Unit.lb_ft3];
            case UnitGroup.AreaPerLength:
                return [Unit.mm2_m, Unit.cm2_m, Unit.inch2_ft];
            case UnitGroup.SpecificWeight:
                return [Unit.N_mm3, Unit.kN_m3, Unit.lbf_ft3];
            case UnitGroup.ItemsPerArea:
                return [Unit.item_mm2, Unit.item_cm2, Unit.item_m2, Unit.item_inch2, Unit.item_ft2];
            case UnitGroup.Volume:
                return [Unit.mm3, Unit.cm3, Unit.m3, Unit.inch3, Unit.ft3];
            case UnitGroup.None:
                return [Unit.None];
            default:
                throw new Error('Unknown unit group.');
        }
    }

    public getUnitGroupCodeList(unitGroup: UnitGroup) {
        switch (unitGroup) {
            case UnitGroup.Area:
                return this.commonCodeList.commonCodeLists[CommonCodeList.UnitArea] as UnitCodeList[];
            case UnitGroup.Force:
                return this.commonCodeList.commonCodeLists[CommonCodeList.UnitForce] as UnitCodeList[];
            case UnitGroup.Length:
            case UnitGroup.LengthLarge:
                return this.commonCodeList.commonCodeLists[CommonCodeList.UnitLength] as UnitCodeList[];
            case UnitGroup.Moment:
                return this.commonCodeList.commonCodeLists[CommonCodeList.UnitMoment] as UnitCodeList[];
            case UnitGroup.Stress:
            case UnitGroup.StressSmall:
                return this.commonCodeList.commonCodeLists[CommonCodeList.UnitStress] as UnitCodeList[];
            case UnitGroup.Temperature:
                return this.commonCodeList.commonCodeLists[CommonCodeList.UnitTemperature] as UnitCodeList[];
            case UnitGroup.ForcePerLength:
                return this.commonCodeList.commonCodeLists[CommonCodeList.UnitForcePerLength] as UnitCodeList[];
            case UnitGroup.Time:
                throw new Error('UnitGroup.Time is not returned by the service');
            case UnitGroup.Velocity:
                throw new Error('UnitGroup.Velocity is not returned by the service');
            case UnitGroup.MomentPerLength:
                return this.commonCodeList.commonCodeLists[CommonCodeList.UnitMomentPerLength] as UnitCodeList[];
            case UnitGroup.Density:
                return this.commonCodeList.commonCodeLists[CommonCodeList.UnitDensity] as UnitCodeList[];
            case UnitGroup.AreaPerLength:
                return this.commonCodeList.commonCodeLists[CommonCodeList.UnitAreaPerLength] as UnitCodeList[];
            case UnitGroup.SpecificWeight:
                return this.commonCodeList.commonCodeLists[CommonCodeList.UnitSpecificWeight] as UnitCodeList[];
            case UnitGroup.ItemsPerArea:
                return this.commonCodeList.commonCodeLists[CommonCodeList.UnitItemsPerArea] as UnitCodeList[];
            case UnitGroup.Volume:
                return this.commonCodeList.commonCodeLists[CommonCodeList.UnitVolume] as UnitCodeList[];
            default:
                throw new Error('Unknown unit group');
        }
    }

    public formatUnit(unit: Unit) {
        return this.getUnitStrings(unit)[0];
    }

    public parseNumber(value: string, decimalSeparator?: string, groupSeparator?: string): number {
        return this.number.parse(value, NumberType.real, decimalSeparator, groupSeparator);
    }

    public formatNumber(value: number, decimals?: number, toFixed?: boolean, decimalSeparator?: string, groupSeparator?: string) {
        return this.number.format(value, decimals, toFixed, decimalSeparator, groupSeparator);
    }

    public convertUnitValueFromInternalUnitValue(unitValue: UnitValue) {
        const unitGroup = this.getUnitGroupFromUnit(unitValue.unit);
        const internalUnit = this.getInternalUnit(unitGroup);

        return this.convertUnitValueToUnit({ unit: internalUnit, value: unitValue.value }, unitValue.unit);
    }

    public convertUnitValueToInternalUnitValue(unitValue: UnitValue) {
        const unitGroup = this.getUnitGroupFromUnit(unitValue.unit);
        const internalUnit = this.getInternalUnit(unitGroup);

        return this.convertUnitValueToUnit(unitValue, internalUnit);
    }

    public getInternalUnit(unitGroup: UnitGroup) {
        switch (unitGroup) {
            case UnitGroup.None:
                return Unit.None;
            case UnitGroup.Angle:
                return Unit.rad;
            case UnitGroup.Length:
            case UnitGroup.LengthLarge:
                return Unit.mm;
            case UnitGroup.Area:
                return Unit.mm2;
            case UnitGroup.Stress:
            case UnitGroup.StressSmall:
                return Unit.Nmm2;
            case UnitGroup.Force:
                return Unit.N;
            case UnitGroup.Moment:
                return Unit.Nmm;
            case UnitGroup.Temperature:
                return Unit.C;
            case UnitGroup.Time:
                return Unit.min;
            case UnitGroup.Percentage:
                return Unit.percent;
            case UnitGroup.ForcePerLength:
                return Unit.N_mm;
            case UnitGroup.Velocity:
                return Unit.m_s;
            case UnitGroup.MomentPerLength:
                return Unit.Nmm_mm;
            case UnitGroup.Density:
                return Unit.kg_m3;
            case UnitGroup.AreaPerLength:
                return Unit.mm2_m;
            case UnitGroup.SpecificWeight:
                return Unit.N_mm3;
            case UnitGroup.ItemsPerArea:
                return Unit.item_mm2;
            case UnitGroup.Volume:
                return Unit.mm3;
            default:
                throw new Error('Unknown unit group.');
        }
    }

    public convertUnitValueToUnit(unitValue: UnitValue, unit: Unit) {
        if (unitValue == null) {
            return null;
        }

        return new UnitValue(this.convertUnitValueArgsToUnit(unitValue.value, unitValue.unit, unit), unit);
    }

    public convertInternalValueToDefaultValue(value: number, unitGroup: UnitGroup) {
        const internalUnit = this.getInternalUnit(unitGroup);
        const defaultUnit = this.getDefaultUnit(unitGroup);

        return this.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit);
    }

    public convertInternalValueToDefaultUnitValue(value: number, unitGroup: UnitGroup): UnitValue {
        const internalUnit = this.getInternalUnit(unitGroup);
        const defaultUnit = this.getDefaultUnit(unitGroup);
        const defaultValue = this.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit);

        const retVal = new UnitValue(defaultValue, defaultUnit);
        return retVal;
    }

    public convertUnitValueArgsToUnit(value: number, unitFrom: Unit, unitTo: Unit, useDefaulPrecision?: boolean) {
        if (Number.isNaN(value)) {
            return Number.NaN;
        }

        // infinity check
        if (!Number.isFinite(value)) {
            return value;
        }

        if (unitFrom == Unit.None) {
            if (unitTo == Unit.None) {
                return value;
            }

            throw new Error('Invalid unitFrom and unitTo combination.');
        }

        if (unitTo == unitFrom) {
            return value;
        }

        if (unitTo == Unit.None) {
            throw new Error('Invalid unitFrom and unitTo combination.');
        }

        const unitGroup = this.getUnitGroupFromUnit(unitFrom);
        if (unitGroup != this.getUnitGroupFromUnit(unitTo)) {
            throw new Error('Unit group doesn\'t match for unitFrom and unitTo.');
        }

        const unitConvertFromIndex = unitFrom - unitGroup;
        const unitConvertToIndex = unitTo - unitGroup;

        switch (unitGroup) {
            case UnitGroup.Angle:
                switch (unitFrom) {
                    case Unit.degree:
                        value = (value * (Math.PI / 180)) % (Math.PI * 2);
                        break;
                    case Unit.rad:
                        value = (value * (180 / Math.PI)) % 360;
                        break;
                }
                break;
            case UnitGroup.Area:
            case UnitGroup.ItemsPerArea:
                {

                    const unitLFrom = this.getLengthUnitForAreaUnit(unitFrom);
                    const unitLTo = this.getLengthUnitForAreaUnit(unitTo);

                    const areaLengthUnitFromIndex = unitLFrom - this.getUnitGroupFromUnit(unitLFrom);
                    const areaLengthUnitToIndex = unitLTo - this.getUnitGroupFromUnit(unitLTo);

                    value = value * Math.pow(conversionMatrixLength[areaLengthUnitFromIndex][areaLengthUnitToIndex], 2);
                    break;
                }
            case UnitGroup.Force:
                value = value * conversionMatrixForce[unitConvertFromIndex][unitConvertToIndex];
                break;
            case UnitGroup.Length:
            case UnitGroup.LengthLarge:
                value = value * conversionMatrixLength[unitConvertFromIndex][unitConvertToIndex];
                break;
            case UnitGroup.Moment:
                value = value * conversionMatrixMoment[unitConvertFromIndex][unitConvertToIndex];
                break;
            case UnitGroup.Percentage:
                value = value * conversionMatrixPercentage[unitConvertFromIndex][unitConvertToIndex];
                break;
            case UnitGroup.Stress:
            case UnitGroup.StressSmall:
                value = value * conversionMatrixStress[unitConvertFromIndex][unitConvertToIndex];
                break;
            case UnitGroup.Temperature:
                switch (unitFrom) {
                    case Unit.C:
                        value = ((value * 9) / 5) + 32;
                        break;

                    case Unit.F:
                        value = ((value - 32) * 5) / 9;
                        break;
                }
                break;
            case UnitGroup.Time:
                value = value * conversionMatrixTime[unitConvertFromIndex][unitConvertToIndex];
                break;
            case UnitGroup.ForcePerLength:
                {
                    const unitsFLFrom = this.getForceAndLengthUnitsForForcePerLengthUnit(unitFrom);
                    const unitsFLTo = this.getForceAndLengthUnitsForForcePerLengthUnit(unitTo);

                    const forceUnitFromIndex = unitsFLFrom.forceUnit - this.getUnitGroupFromUnit(unitsFLFrom.forceUnit);
                    const lengthUnitFromIndex = unitsFLFrom.lengthUnit - this.getUnitGroupFromUnit(unitsFLFrom.lengthUnit);
                    const forceUnitToIndex = unitsFLTo.forceUnit - this.getUnitGroupFromUnit(unitsFLTo.forceUnit);
                    const lengthUnitToIndex = unitsFLTo.lengthUnit - this.getUnitGroupFromUnit(unitsFLTo.lengthUnit);

                    value = value * conversionMatrixForce[forceUnitFromIndex][forceUnitToIndex] / conversionMatrixLength[lengthUnitFromIndex][lengthUnitToIndex];
                    break;
                }
            case UnitGroup.MomentPerLength:
                {
                    const unitsMLFrom = this.getMomentAndLengthUnitsForMomentPerLengthUnit(unitFrom);
                    const unitsMLTo = this.getMomentAndLengthUnitsForMomentPerLengthUnit(unitTo);

                    const momentUnitFromIndex = unitsMLFrom.momentUnit - this.getUnitGroupFromUnit(unitsMLFrom.momentUnit);
                    const length2UnitFromIndex = unitsMLFrom.lengthUnit - this.getUnitGroupFromUnit(unitsMLFrom.lengthUnit);
                    const momentUnitToIndex = unitsMLTo.momentUnit - this.getUnitGroupFromUnit(unitsMLTo.momentUnit);
                    const length2UnitToIndex = unitsMLTo.lengthUnit - this.getUnitGroupFromUnit(unitsMLTo.lengthUnit);

                    value = value * conversionMatrixMoment[momentUnitFromIndex][momentUnitToIndex] / conversionMatrixLength[length2UnitFromIndex][length2UnitToIndex];
                    break;
                }
            case UnitGroup.Velocity:
                value = value * conversionMatrixVelocity[unitConvertFromIndex][unitConvertToIndex];
                break;
            case UnitGroup.Density:
                value = value * conversionMatrixDensity[unitConvertFromIndex][unitConvertToIndex];
                break;
            case UnitGroup.AreaPerLength:
                {
                    const unitAPLFrom = this.getLengthUnitsForAreaPerLengthUnit(unitFrom);
                    const unitAPLTo = this.getLengthUnitsForAreaPerLengthUnit(unitTo);

                    const areaUnitFromIndex = unitAPLFrom.areaUnit - this.getUnitGroupFromUnit(unitAPLFrom.areaUnit);
                    const areaUnitToIndex = unitAPLTo.areaUnit - this.getUnitGroupFromUnit(unitAPLTo.areaUnit);
                    const length3UnitFromIndex = unitAPLFrom.lengthUnit - this.getUnitGroupFromUnit(unitAPLFrom.lengthUnit);
                    const length3UnitToIndex = unitAPLTo.lengthUnit - this.getUnitGroupFromUnit(unitAPLTo.lengthUnit);

                    value = value * Math.pow(conversionMatrixLength[areaUnitFromIndex][areaUnitToIndex], 2) / conversionMatrixLength[length3UnitFromIndex][length3UnitToIndex];
                    break;
                }
            case UnitGroup.SpecificWeight:
                value = value * conversionMatrixSpecificWeight[unitConvertFromIndex][unitConvertToIndex];
                break;

            case UnitGroup.Volume:
                {
                    const unitLFrom = this.getLengthUnitForVolumeUnit(unitFrom);
                    const unitLTo = this.getLengthUnitForVolumeUnit(unitTo);

                    const volumeUnitFromIndex = unitLFrom - this.getUnitGroupFromUnit(unitLFrom);
                    const volumeUnitToIndex = unitLTo - this.getUnitGroupFromUnit(unitLTo);

                    value = value * Math.pow(conversionMatrixLength[volumeUnitFromIndex][volumeUnitToIndex], 3);
                    break;
                }
        }

        // Round to maximum precision to avoid values like 1.999999999999998.
        if (useDefaulPrecision) {
            return this.number.round(value, this.getDefaultPrecision());
        }

        else {
            return value;
        }
    }

    public getPrecision(unit: Unit, uiProperty?: number, unitPrecision?: UnitPrecision) {
        if (unitPrecision != null) {
            // C2C-10498: cleanup needed!
            if (typeof unitPrecision == 'boolean') {
                if (unitPrecision === true) {
                    console.warn('Probably using deprecated code. Change calls to getPrecision to provide UnitPrecision instead of true/false.');
                    return this.unitHelperC2C.getPrecision(unit, uiProperty);
                }
            }
            else if (uiProperty != null && unitPrecision.getPrecisionForProperty != null) {
                return unitPrecision.getPrecisionForProperty(uiProperty, unit) ?? unitPrecision.getPrecision(unit);
            }

            return unitPrecision.getPrecision(unit);
        }
        else if (uiProperty != null) {
            // C2C-10498: cleanup needed!
            return this.unitHelperPE.getPrecision(unit, uiProperty);
        }

        return this.getUnitOnlyPrecision(unit);
    }

    public getDefaultUnit(unitGroup: UnitGroup, design?: IUnitProviderObject) {

        const validDesign = this.user.design || design;

        if (validDesign == undefined) {
            return this.getInternalUnit(unitGroup);
        }

        switch (unitGroup) {
            case UnitGroup.Angle:
                return Unit.degree;
            case UnitGroup.Area:
                return validDesign.unitArea;
            case UnitGroup.Force:
                return validDesign.unitForce;
            case UnitGroup.Length:
                return validDesign.unitLength;
            case UnitGroup.LengthLarge:
                return validDesign.unitLengthLarge;
            case UnitGroup.Moment:
                return validDesign.unitMoment;
            case UnitGroup.Stress:
                return validDesign.unitStress;
            case UnitGroup.StressSmall:
                return validDesign.unitStressSmall;
            case UnitGroup.Temperature:
                return validDesign.unitTemperature;
            case UnitGroup.ForcePerLength:
                return validDesign.unitForcePerLength;
            case UnitGroup.Percentage:
                return Unit.percent;
            case UnitGroup.Time:
                return Unit.ms;
            case UnitGroup.Velocity:
                return Unit.m_s;
            case UnitGroup.MomentPerLength:
                return validDesign.unitMomentPerLength;
            case UnitGroup.Density:
                return validDesign.unitDensity;
            case UnitGroup.AreaPerLength:
                return validDesign.unitAreaPerLength ? validDesign.unitAreaPerLength : Unit.mm2_m;
            case UnitGroup.SpecificWeight:
                return (validDesign as any).unitSpecificWeight ? (validDesign as any).unitSpecificWeight : Unit.N_mm3;
            case UnitGroup.ItemsPerArea:
                return (validDesign as any).unitItemsPerArea ? (validDesign as any).unitItemsPerArea : Unit.item_mm2;
            case UnitGroup.Volume:
                return (validDesign as any).unitVolume ? (validDesign as any).unitVolume : Unit.mm3;
            case UnitGroup.None:
            case null:
            case undefined:
                return Unit.None;
            default:
                throw new Error('Unknown unit group.');
        }
    }

    public getUnitStringDictionary() {
        const units = this.getUnits();

        return units.reduce((unitStringDictionary, unit) => {
            unitStringDictionary[unit] = this.getUnitStrings(unit);

            return unitStringDictionary;
        }, {} as { [key: number]: string[] });
    }

    public getUnitStrings(unit: Unit) {
        const unitGroup = this.getUnitGroupFromUnit(unit);

        if (unitGroup == UnitGroup.ForcePerLength) {
            const unitsFpl = this.getForceAndLengthUnitsForForcePerLengthUnit(unit);

            const forceStrings = this.getUnitStrings(unitsFpl.forceUnit);
            const lengthStrings = this.getUnitStrings(unitsFpl.lengthUnit);

            const forcePerLengthStrings: string[] = [];

            // combine with divider
            for (const forceString of forceStrings) {
                for (const lengthString of lengthStrings) {
                    forcePerLengthStrings.push(forceString + '/' + lengthString);
                }
            }

            return forcePerLengthStrings;
        }

        if (unitGroup == UnitGroup.MomentPerLength) {
            const unitsMpl = this.getMomentAndLengthUnitsForMomentPerLengthUnit(unit);

            const momentStrings = this.getUnitStrings(unitsMpl.momentUnit);
            const lengthStrings = this.getUnitStrings(unitsMpl.lengthUnit);

            const momentPerLengthStrings: string[] = [];

            // combine with divider
            for (const momentString of momentStrings) {
                for (const lengthString of lengthStrings) {
                    momentPerLengthStrings.push(momentString + '/' + lengthString);
                }
            }

            return momentPerLengthStrings;
        }

        switch (unit) {
            // length
            case Unit.mm:
                return ['mm'];
            case Unit.cm:
                return ['cm'];
            case Unit.m:
                return ['m'];
            case Unit.inch:
                return ['in', 'inc', 'inch'];
            case Unit.ft:
                return ['ft', 'feet'];
            case Unit.mi:
                return ['mi', 'mile', 'miles'];

            // area
            case Unit.mm2:
                return ['mm²', 'mm2'];
            case Unit.cm2:
                return ['cm²', 'cm2'];
            case Unit.m2:
                return ['m²', 'm2'];
            case Unit.inch2:
                return ['in²', 'in2', 'inc2', 'inch2'];
            case Unit.ft2:
                return ['ft²', 'ft2', 'feet2'];

            // stress
            case Unit.Nmm2:
                return ['N/mm²', 'N/mm2', 'Nmm2'];
            case Unit.PSI:
                return ['psi'];
            case Unit.KSI:
                return ['ksi'];
            case Unit.kNm2:
                return ['kN/m²', 'kN/m2', 'kNm2'];
            case Unit.MPa:
                return ['MPa'];
            case Unit.Nm2:
                return ['N/m²', 'N/m2', 'Nm2'];
            case Unit.kgfcm2:
                return ['kgf/cm²', 'kgf/cm2', 'kgf-cm2'];
            case Unit.PSF:
                return ['psf'];

            // force
            case Unit.N:
                return ['N'];
            case Unit.daN:
                return ['daN'];
            case Unit.kN:
                return ['kN'];
            case Unit.lb:
                return ['lb'];
            case Unit.Kip:
                return ['kip'];
            case Unit.kgf:
                return ['kgf'];
            case Unit.tonf:
                return ['tonf'];

            // moment
            case Unit.Nmm:
                return ['Nmm'];
            case Unit.Nm:
                return ['Nm'];
            case Unit.daNm:
                return ['daNm'];
            case Unit.kNm:
                return ['kNm'];
            case Unit.in_lb:
                return ['in-lb', 'in lb'];
            case Unit.ft_lb:
                return ['ft-lb', 'ft lb'];
            case Unit.in_kip:
                return ['in-kip', 'in kip'];
            case Unit.ft_kip:
                return ['ft-kip', 'ft kip'];
            case Unit.kgfm:
                return ['kgfm'];
            case Unit.kgfcm:
                return ['kgfcm'];

            // temperature
            case Unit.C:
                return ['°C', 'C'];
            case Unit.F:
                return ['°F', 'F'];

            // time
            case Unit.ms:
                return ['ms'];
            case Unit.s:
                return ['s'];
            case Unit.min:
                return ['min'];
            case Unit.h:
                return ['h'];

            // angle
            case Unit.degree:
                return ['°', 'deg'];
            case Unit.rad:
                return ['rad'];

            // percentage
            case Unit.percent:
                return ['%'];
            case Unit.permile:
                return ['‰', 'permil'];

            // density
            case Unit.kg_m3:
                return ['kg/m³'];
            case Unit.lb_ft3:
                return ['lb/ft³'];
            // force per length
            // already returned before this switch

            // velocity
            case Unit.m_s:
                return ['m/s'];

            // area per length
            case Unit.mm2_m:
                return ['mm²/m', 'mm2/m', 'mm2m'];
            case Unit.cm2_m:
                return ['cm²/m', 'cm2/m', 'cm2m'];
            case Unit.inch2_ft:
                return ['in²/ft', 'in2/ft', 'in2ft'];

            // Specific weight
            case Unit.N_mm3:
                return ['N/mm³', 'N/mm3'];
            case Unit.kN_m3:
                return ['kN/m³', 'kN/m3'];
            case Unit.lbf_ft3:
                return ['lbf/ft³', 'lbf/ft3'];

            // items per area
            case Unit.item_mm2:
                return ['1/mm²', '1/mm2'];
            case Unit.item_cm2:
                return ['1/cm²', '1/cm2'];
            case Unit.item_m2:
                return ['1/m²', '1/m2'];
            case Unit.item_inch2:
                return ['1/in²', '1/in2'];
            case Unit.item_ft2:
                return ['1/ft²', '1/ft2'];

            // volume
            case Unit.mm3:
                return ['mm³', 'mm3'];
            case Unit.cm3:
                return ['cm³', 'cm3'];
            case Unit.m3:
                return ['m³', 'm3'];
            case Unit.inch3:
                return ['in³', 'in3'];
            case Unit.ft3:
                return ['ft³', 'ft3'];

            case Unit.None:
                return [''];

            default:
                throw new Error('Unknown unit.');
        }
    }

    public formatInternalValueAsDefault(value: number, unitGroup: UnitGroup, precision?: number, design?: IUnitProviderObject) {
        const internalUnit = this.getInternalUnit(unitGroup);
        const defaultUnit = this.getDefaultUnit(unitGroup, design);

        return this.formatUnitValueArgs(this.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit), defaultUnit, precision);
    }

    public parseDefaultValueAsInternal(value: string, unitGroup: UnitGroup, defaultUnit?: Unit) {
        if (value == null) {
            return null;
        }

        const internalUnit = this.getInternalUnit(unitGroup);

        const unitValue = this.parseUnitValue(value, unitGroup, defaultUnit);
        const internalUnitValue = this.convertUnitValueToUnit(unitValue, internalUnit);

        return internalUnitValue.value;
    }

    public formatUnitAsDefault(value: string, unitGroup: UnitGroup) {
        return this.formatInternalValueAsDefault(this.parseDefaultValueAsInternal(value, unitGroup), unitGroup);
    }

    public transformWithSeparator(value: string, fromDecimalSeparator: string, fromGroupSeparator: string, toDecimalSeparator: string, toGroupSeparator: string, precision?: number, uiProperty?: UIProperty | number) {
        if (value == null || typeof value != 'string' || (fromDecimalSeparator == toDecimalSeparator && fromGroupSeparator == toGroupSeparator)) {
            return value;
        }

        const unitValue = this.parseUnknownUnitValue(value, fromDecimalSeparator, fromGroupSeparator);

        if (unitValue == null || Number.isNaN(unitValue.value)) {
            return value;
        }

        return this.formatUnitValue(unitValue, precision, toDecimalSeparator, toGroupSeparator, uiProperty);
    }

    public getDefaultPrecision() {
        return 6;
    }

    private getUnitOnlyPrecision(unit: Unit) {
        if (this.getUnitGroupFromUnit(unit) == UnitGroup.ForcePerLength) {
            // force per length
            // always 2
            return 2;
        }

        if (this.getUnitGroupFromUnit(unit) == UnitGroup.MomentPerLength) {
            // moment per length
            // always 2
            return 2;
        }

        switch (unit) {
            // length
            case Unit.mm:
                return 1;
            case Unit.cm:
                return 2;
            case Unit.inch:
                return 3;
            case Unit.ft:
                return 2;
            case Unit.m:
                return 4;
            case Unit.mi:
                return 6;

            // area
            case Unit.mm2:
                return 0;
            case Unit.cm2:
                return 2;
            case Unit.inch2:
                return 6;
            case Unit.ft2:
                return 4;

            // stress
            case Unit.Nmm2:
                return 0;
            case Unit.Nm2:
                return 0;
            case Unit.PSI:
                return 0;
            case Unit.KSI:
                return 3;
            case Unit.kNm2:
                return 0;
            case Unit.kgfcm2:
                return 0;
            case Unit.PSF:
                return 0;

            // force
            case Unit.N:
                return 0;
            case Unit.daN:
                return 1;
            case Unit.kN:
                return 3;
            case Unit.lb:
                return 0;
            case Unit.Kip:
                return 3;
            case Unit.kgf:
                return 0;

            // moment
            case Unit.Nm:
                return 0;
            case Unit.daNm:
                return 1;
            case Unit.kNm:
                return 3;
            case Unit.in_lb:
                return 0;
            case Unit.ft_lb:
                return 3;
            case Unit.in_kip:
                return 6;
            case Unit.ft_kip:
                return 5;
            case Unit.kgfcm:
                return 0;

            // temperature
            case Unit.C:
                return 0;
            case Unit.F:
                return 0;

            // angle
            case Unit.degree:
                return 1;
            case Unit.rad:
                return 6;

            // velocity
            case Unit.m_s:
                return 1;

            // percentage
            case Unit.percent:
                return 2;

            // density
            case Unit.kg_m3:
            case Unit.lb_ft3:
                return 2;

            // area per length
            case Unit.mm2_m:
                return 0;
            case Unit.cm2_m:
            case Unit.inch2_ft:
                return 2;

            // Specific weight
            case Unit.N_mm3:
                return 0;
            case Unit.kN_m3:
            case Unit.lbf_ft3:
                return 2;

            // items per area
            case Unit.item_mm2:
                return 0;
            case Unit.item_cm2:
            case Unit.item_m2:
                return 2;
            case Unit.item_inch2:
                return 6;
            case Unit.item_ft2:
                return 4;

            // volume
            case Unit.mm3:
                return 0;
            case Unit.cm3:
                return 2;
            case Unit.m3:
                return 2;
            case Unit.inch3:
                return 6;
            case Unit.ft3:
                return 4;

            case Unit.None:
                return 6;

            // default
            default:
                return 6;
        }
    }

    private NaNUnitValue() {
        return new UnitValue(Number.NaN, Unit.None);
    }

    private getStringEndUnits(value: string, unitGroup: UnitGroup) {
        value = value.trim().toLowerCase();

        const units = this.getUnitsForUnitGroup(unitGroup);
        const values: { unit: Unit, unitString: string }[] = [];

        for (const unit of units) {
            const unitStrings = this.getUnitStrings(unit).map((unitString) => unitString.toLowerCase());

            for (const unitString of unitStrings) {
                if (value.endsWith(unitString)) {
                    values.push({ unit, unitString });
                }
            }
        }

        return values;
    }

    private getLengthUnitForAreaUnit(areaUnit: Unit) {
        switch (areaUnit) {
            case Unit.item_mm2:
            case Unit.mm2: return Unit.mm;

            case Unit.item_cm2:
            case Unit.cm2: return Unit.cm;

            case Unit.item_m2:
            case Unit.m2: return Unit.m;

            case Unit.item_inch2:
            case Unit.inch2: return Unit.inch;

            case Unit.item_ft2:
            case Unit.ft2: return Unit.ft;

            default: throw new Error('Unsupported area unit.');
        }
    }
    private getLengthUnitForVolumeUnit(areaUnit: Unit) {
        switch (areaUnit) {
            case Unit.mm3: return Unit.mm;
            case Unit.cm3: return Unit.cm;
            case Unit.m3: return Unit.m;
            case Unit.inch3: return Unit.inch;
            case Unit.ft3: return Unit.ft;

            default: throw new Error('Unsupported area unit.');
        }
    }

    private getLengthUnitsForAreaPerLengthUnit(areaUnit: Unit) {
        switch (areaUnit) {
            case Unit.mm2_m: return { areaUnit: Unit.mm, lengthUnit: Unit.m };
            case Unit.cm2_m: return { areaUnit: Unit.cm, lengthUnit: Unit.m };
            case Unit.inch2_ft: return { areaUnit: Unit.inch, lengthUnit: Unit.ft };
            default: throw new Error('Unsupported area per length unit.');
        }
    }

    private getForceAndLengthUnitsForForcePerLengthUnit(forcePerLengthUnit: Unit) {
        switch (forcePerLengthUnit) {
            case Unit.N_mm: return { forceUnit: Unit.N, lengthUnit: Unit.mm };
            case Unit.N_m: return { forceUnit: Unit.N, lengthUnit: Unit.m };
            case Unit.daN_m: return { forceUnit: Unit.daN, lengthUnit: Unit.m };
            case Unit.kN_m: return { forceUnit: Unit.kN, lengthUnit: Unit.m };
            case Unit.lb_in: return { forceUnit: Unit.lb, lengthUnit: Unit.inch };
            case Unit.lb_ft: return { forceUnit: Unit.lb, lengthUnit: Unit.ft };
            case Unit.Kip_in: return { forceUnit: Unit.Kip, lengthUnit: Unit.inch };
            case Unit.Kip_ft: return { forceUnit: Unit.Kip, lengthUnit: Unit.ft };
            case Unit.kgf_m: return { forceUnit: Unit.kgf, lengthUnit: Unit.m };
            case Unit.kgf_cm: return { forceUnit: Unit.kgf, lengthUnit: Unit.cm };
            case Unit.kN_mm: return { forceUnit: Unit.kN, lengthUnit: Unit.mm };
            default: throw new Error('Unsupported force per length unit.');
        }
    }

    private getMomentAndLengthUnitsForMomentPerLengthUnit(momentPerLengthUnit: Unit) {
        switch (momentPerLengthUnit) {
            case Unit.Nmm_mm: return { momentUnit: Unit.Nmm, lengthUnit: Unit.mm };
            case Unit.kNm_m: return { momentUnit: Unit.kNm, lengthUnit: Unit.m };
            case Unit.daNm_m: return { momentUnit: Unit.daNm, lengthUnit: Unit.m };
            case Unit.Nm_m: return { momentUnit: Unit.Nm, lengthUnit: Unit.m };
            case Unit.kgfm_m: return { momentUnit: Unit.kgfm, lengthUnit: Unit.m };
            case Unit.kgfcm_cm: return { momentUnit: Unit.kgfcm, lengthUnit: Unit.cm };
            case Unit.in_lb_in: return { momentUnit: Unit.in_lb, lengthUnit: Unit.inch };
            case Unit.ft_lb_in: return { momentUnit: Unit.ft_lb, lengthUnit: Unit.inch };
            case Unit.ft_lb_ft: return { momentUnit: Unit.ft_lb, lengthUnit: Unit.ft };
            case Unit.ft_kip_ft: return { momentUnit: Unit.ft_kip, lengthUnit: Unit.ft };
            case Unit.in_kip_in: return { momentUnit: Unit.in_kip, lengthUnit: Unit.inch };
            case Unit.ft_kip_in: return { momentUnit: Unit.ft_kip, lengthUnit: Unit.inch };
            default: throw new Error('Unsupported moment per length unit.');
        }
    }
}
