import React from 'react';

import { IImageNameSelectionProps } from '@profis-engineering/pe-ui-common/entities/main-menu/image-name-selection';
import { getMenuControlImageReactData } from '../../../../helpers/react-helpers';
import { buildHtmlTooltip, isControlHidden } from '../../MainMenuHelper';

export class ImageNameSelection extends React.PureComponent<IImageNameSelectionProps> {
    private input: HTMLInputElement;

    constructor(props?: IImageNameSelectionProps) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    public override render() {
        if (isControlHidden(this.props)) {
            return null;
        }

        const inputId = `${this.props.controlId}-radio`;
        const checked = this.props.checked != null ? this.props.checked : false;
        const text = this.props.text != null ? this.props.text : '';
        const tooltip = this.props.title != null && this.props.title != '' ? null : buildHtmlTooltip(this.props.tooltip, this.props.tooltipTitle);
        const disableClassName = this.props.disabled ? 'disabled' : '';
        const activeClassName = checked ? 'active' : '';

        const imageData = getMenuControlImageReactData(
            this.props.image,
            this.props.imageStyle
        );
        const hasImage = imageData.spriteName != null && imageData.spriteName != '' || imageData.elementStyle != null;

        return (
            <div
                data-control-id={this.props.controlId}
                className={`control ${this.props.className} ${this.props.sizeClass}`}>

                <div className={`control-container ${activeClassName}`} onClick={this.onChange}>
                    <div
                        className={`radio ${disableClassName}`}
                        data-tip={tooltip}
                        data-html={tooltip != null ? true : null}
                        data-place='right'>
                        {
                            hasImage ?
                                <div className='image-container'>
                                    <span className={`image sprite sprite-responsive ${imageData.spriteName}`} style={imageData.elementStyle}></span>
                                </div> : null
                        }
                        <div className='text radio-label'>
                            <span>
                                {text}
                            </span>
                        </div>
                        <div
                            className='radio-button'>

                            <input
                                data-control-id={inputId}
                                type='radio'
                                checked={checked}
                                onChange={this.onChange}
                                disabled={this.props.disabled}
                                ref={(e) => this.input = e} />
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private onChange(event: React.FormEvent) {
        if (this.props.checkedChanged != null) {
            this.props.checkedChanged(true, this.props.value);
        }
    }
}
