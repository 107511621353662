import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    CodeList, getCodeListTextDeps, ICodeLists
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { StructuralCalculationSoftware } from '@profis-engineering/pe-ui-common/entities/code-lists/structural-calculation-software';
import {
    Design as DesignCommon, DesignEvent, IDesignStateBase, IProperty, UIPropertyTexts
} from '@profis-engineering/pe-ui-common/entities/design';
import { IModalGridComponentInput, IModalGridItem } from '@profis-engineering/pe-ui-common/entities/modal-grid';
import { Feature, RegionHub, StructuralCalculationSoftware as StructuralCalculationSoftwareType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { DocumentModel } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Document';
import {
    DocumentIntegrationType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Enums';
import {
    IModalOpened, ModalOptions
} from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import {
    UnitGroup, UnitType as Unit
} from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import {
    CommonCodeList
} from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import {
    IGetStringOptions,
    ISanitizeTags
} from '@profis-engineering/pe-ui-common/services/localization.common';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';
import {
    IMenuServiceExtensions, MenuControl, MenuServiceBase, MenuServiceExtensionsDefault, tabExpandId, tabFavoritesId, tabHomeId
} from '@profis-engineering/pe-ui-common/services/menu.common';
import { PropertyMetaData } from '@profis-engineering/pe-ui-shared/properties/properties';
import cloneDeep from 'lodash-es/cloneDeep';
import escape from 'lodash-es/escape';
import forEach from 'lodash-es/forEach';
import sortBy from 'lodash-es/sortBy';
import { Subscription } from 'rxjs';
import Mutex from 'ts-mutex';

import { ButtonGroupDisplay, IButtonGroupItem, IButtonGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/button-group-props';
import { ButtonSize, ButtonType, IButtonProps } from '@profis-engineering/pe-ui-common/entities/main-menu/button-props';
import { ICheckboxGroupItem, ICheckboxGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/checkbox-group-props';
import { ICheckboxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/checkbox-props';
import { IControlProps, LabelRole as MainMenuLabelRole, Size } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { IDropdownItem, IDropdownProps } from '@profis-engineering/pe-ui-common/entities/main-menu/dropdown-props';
import { IGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/group-props';
import { IImageNameCheckboxGroupItem, IImageNameCheckboxGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/image-name-checkbox-group-props';
import { IImageNameCheckboxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/image-name-checkbox-props';
import {
    IImageNameRadioGroupItem, IImageNameRadioGroupProps
} from '@profis-engineering/pe-ui-common/entities/main-menu/image-name-radio-group-props';
import { IImageNameSelectionGroupItem, IImageNameSelectionGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/image-name-selection-group-props';
import { ILabelProps, LabelRole } from '@profis-engineering/pe-ui-common/entities/main-menu/Label-props';
import { IMainMenuControl, IMenu, IRegion, ITab } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import {
    BaseControl, Button as ButtonControl, ButtonGroup as ButtonGroupControl, ButtonGroupDisplay as MainMenuButtonGroupDisplay, ButtonGroupType, ButtonSize as MainMenuButtonSize, ButtonType as MainMenuButtonType, CheckBox as CheckBoxControl, CheckboxGroup as CheckboxGroupControl, CustomControl, DropDown as DropDownControl, Group as GroupControl, ICheckboxGroupOnChangeParams, IDropdownOnChangeParams, ImageNameSelectionGroup as ImageNameSelectionGroupControl, Label as LabelControl, Menu, NavigationTabWidth, RadioButtonGroup as RadioButtonGroupControl, Rotate as RotateControl, Tab, TabGroup as TabGroupControl, TextBox as TextBoxControl, ToggleButton as ToggleButtonControl, ToggleButtonGroup as ToggleButtonGroupControl,
    ToggleImageButton as ToggleImageButtonControl, TooltipType, UIPropertyBaseControl, UIPropertyWithValueBaseControl
} from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { IPopupGridPartialProps, IPopupGridProps } from '@profis-engineering/pe-ui-common/entities/main-menu/popup-grid-props';
import { IRadioButtonGroupItem, IRadioButtonGroupProps, IRadioButtonProps } from '@profis-engineering/pe-ui-common/entities/main-menu/radio-button-group-props';
import { IRangeSliderProps } from '@profis-engineering/pe-ui-common/entities/main-menu/range-slider-props';
import { IRotateProps } from '@profis-engineering/pe-ui-common/entities/main-menu/rotate-pros';
import { ISwitchWithDescriptionProps } from '@profis-engineering/pe-ui-common/entities/main-menu/switch-with-description';
import { ITabControlProps, ITabGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/tab-group-props';
import { ITextBoxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/textbox-props';
import {
    IToggleButtonGroupItem, IToggleButtonGroupProps, ToggleButtonGroupType
} from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-button-group-props';
import { IToggleButtonProps } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-button-props';
import { IToggleImageButtonProps } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-image-button-props';
import { MenuType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.UserSettings.Shared.Enums';
import { ModalGridHelper } from '@profis-engineering/pe-ui-shared/helpers/modal-grid-helper';
import { environment } from '../../environments/environment';
import { Button } from '../components/main-menu/Controls/Button/Button';
import { ButtonGroup } from '../components/main-menu/Controls/ButtonGroup/ButtonGroup';
import { Checkbox } from '../components/main-menu/Controls/Checkbox/Checkbox';
import { CheckboxGroup } from '../components/main-menu/Controls/CheckboxGroup/CheckboxGroup';
import {
    DlubalImportExport, IDlubalImportExportProps
} from '../components/main-menu/Controls/DlubalImportExport/DlubalImportExport';
import { Dropdown } from '../components/main-menu/Controls/Dropdown/Dropdown';
import {
    ETABSImportExport, IETABSImportExportProps
} from '../components/main-menu/Controls/ETABSImportExport/ETABSImportExport';
import { Group } from '../components/main-menu/Controls/Group/Group';
import { ImageNameCheckbox } from '../components/main-menu/Controls/ImageNameCheckbox/ImageNameCheckbox';
import { ImageNameCheckboxGroup } from '../components/main-menu/Controls/ImageNameCheckboxGroup/ImageNameCheckboxGroup';
import { ImageNameRadioGroup } from '../components/main-menu/Controls/ImageNameRadioGroup/ImageNameRadioGroup';
import { ImageNameSelectionGroup } from '../components/main-menu/Controls/ImageNameSelectionGroup/ImageNameSelectionGroup';
import { Label } from '../components/main-menu/Controls/Label/Label';
import { PopupGrid } from '../components/main-menu/Controls/PopupGrid/PopupGrid';
import { PopupGridPartial } from '../components/main-menu/Controls/PopupGridPartial/PopupGridPartial';
import { RadioButton } from '../components/main-menu/Controls/RadioButton/RadioButton';
import { RadioButtonGroup } from '../components/main-menu/Controls/RadioButtonGroup/RadioButtonGroup';
import { RangeSlider } from '../components/main-menu/Controls/RangeSlider/RangeSlider';
import {
    IRobotImportExportProps, RobotImportExport
} from '../components/main-menu/Controls/RobotImportExport/RobotImportExport';
import { Rotate } from '../components/main-menu/Controls/Rotate/Rotate';
import {
    ISAP2000ImportExportProps, SAP2000ImportExport
} from '../components/main-menu/Controls/SAP2000ImportExport/SAP2000ImportExport';
import {
    IStaadProImportExportProps, StaadProImportExport
} from '../components/main-menu/Controls/StaadProImportExport/StaadProImportExport';
import { SwitchWithDescription } from '../components/main-menu/Controls/SwitchWithDescription/SwitchWithDescription';
import { TabGroup } from '../components/main-menu/Controls/TabGroup/TabGroup';
import { TextBox } from '../components/main-menu/Controls/TextBox/TextBox';
import { ToggleButton } from '../components/main-menu/Controls/ToggleButton/ToggleButton';
import { ToggleButtonGroup } from '../components/main-menu/Controls/ToggleButtonGroup/ToggleButtonGroup';
import { ToggleImageButton } from '../components/main-menu/Controls/ToggleImageButton/ToggleImageButton';
import { IImportExportProps } from '../components/main-menu/MainMenu';
import { isControlHidden } from '../components/main-menu/MainMenuHelper';
import { Design, IDesignState } from '../entities/design';
import { openConfirmChangeForOffline } from '../helpers/offline-helper';
import { urlPath } from '../module-constants';
import { ApiService } from './api.service';
import { BrowserService } from './browser.service';
import { CommonCodeListService } from './common-code-list.service';
import { DocumentService } from './document.service';
import { FavoritesService } from './favorites.service';
import { FeatureVisibilityService } from './feature-visibility.service';
import { FeaturesVisibilityInfoService } from './features-visibility-info.service';
import { IntegrationsDataService } from './integrations-data.service';
import { IntegrationsDocumentService } from './integrations-document.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { ModalService } from './modal.service';
import { ModulesService } from './modules.service';
import { NumberService } from './number.service';
import { OfflineService } from './offline.service';
import { RoutingService } from './routing.service';
import { UnitService } from './unit.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class MenuService extends MenuServiceBase<Design> {
    public menuExtensions: IMenuServiceExtensions;

    public onStateChangedUpdaters: { [id: string]: (state: IDesignStateBase, menu: IMenu) => IControlProps } = {};
    public onAllowedValuesChangedUpdaters: { [id: string]: (design: DesignCommon, propertyIds: number[], state: IMenu) => IControlProps } = {};
    public onStructuralCalculationSoftwareChangedUpdaters: { [id: string]: (state: IDesignStateBase, menu: IMenu) => IControlProps } = {};
    public onApplicationSettingChangedUpdaters: { [id: string]: (state: IDesignStateBase, menu: IMenu) => IControlProps } = {};

    private isNew: boolean;
    private value: number;
    private isValueSend: boolean;
    private rangeSliderLock: Mutex = new Mutex();

    constructor(
        private readonly routingService: RoutingService,
        private readonly codeListCommon: CommonCodeListService,
        private readonly userSettingsService: UserSettingsService,
        private readonly offlineService: OfflineService,
        private readonly apiService: ApiService,
        private readonly browser: BrowserService,
        private readonly integrationsDocumentService: IntegrationsDocumentService,
        private readonly favoritesService: FavoritesService,
        private readonly featuresVisibilityInfoService: FeaturesVisibilityInfoService,
        private readonly userService: UserService,
        private readonly localizationService: LocalizationService,
        private readonly documentService: DocumentService,
        private readonly loggerService: LoggerService,
        private readonly unitService: UnitService,
        private readonly modalService: ModalService,
        private readonly numberService: NumberService,
        private readonly integrationsData: IntegrationsDataService,
        private readonly modulesService: ModulesService,
        private readonly featureVisibilityService: FeatureVisibilityService
    ) {
        super();

        this.setMenuExtensions(new MenuServiceExtensionsDefault());
    }

   public setMenuExtensions(menuExtensions: IMenuServiceExtensions) {
        this.menuExtensions = menuExtensions ?? new MenuServiceExtensionsDefault();
    }

    public createMainMenu(
        menuType: MenuType,
        tabSelected: (selectedTab: string) => void,
        setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu,
        design: DesignCommon,
        commands?: Record<string, (navigationControl?: BaseControl) => void>,
        regionCommands?: Record<string, () => void>,
        modals?: Record<number, (input?: object) => IModalOpened>
    ) {
        this.menuExtensions.validate?.(design);

        // Each time we create a menu, we need to clear the updaters otherwise they will still call updates for old menus.
        this.clearUpdaters();

        const menuStructure = this.menuExtensions.getMenuStructure(design);
        if (menuStructure == null) {
            throw new Error('Menu not found.');
        }

        const menu: IMenu = {
            menuType,
            regions: {},
            controls: {},
            selectedTab: null,
            tabs: {},
            footer: null,
            tabsOrder: [],
            dispose: null,
            menuExpanded: false
        };
        const allCommands = {
            ...(commands ?? {}),
            ...(this.menuExtensions.getMenuCommands(design))
        };
        const allRegionCommands = {
            ...(regionCommands ?? {}),
            ...(this.menuExtensions.getMenuRegionCommands?.(design) ?? {})
        };
        const allModals = {
            ...(modals ?? {}),
            ...(this.menuExtensions.getMenuModals(design))
        }

        const tabExpand: ITab = this.createTabExtend(tabSelected, setState);
        const homeTab: ITab = this.createHomeTab();

        menu.tabs[homeTab.controlId] = homeTab;
        menu.tabsOrder.push(homeTab.controlId);

        this.menuExtensions.updateTabs(design, menuStructure.Tabs);

        // favorites tab
        const allRegionIds = menuStructure.Tabs.flatMap((tab) => tab.TabRegions.map((region) => this.menuExtensions.getRegionId(tab.Name, region.Name)));

        const favoriteIds = this.favoritesService.get(menuType, design.designTypeId, design.connectionTypeId, design.designStandardId)?.filter(favId => allRegionIds.some(navigationRegion => {
            return navigationRegion == favId;
        })) ?? []; // tab region must exist, if it does not, there should also be no favorite

        if (menuStructure.Favorites) {
            const favoritesTab = this.createFavoritesTab(tabSelected, setState, design, favoriteIds);

            menu.tabs[favoritesTab.controlId] = favoritesTab;
            menu.tabsOrder.push(favoritesTab.controlId);

            // If favorites tab is hidden or disabled leave the selectedTab value empty and let the mainmenu.tsx rendering logic select the first available one
            menu.selectedTab = favoritesTab.hidden || (this.featuresVisibilityInfoService.isDisabled(Feature.Menu_Favorites, design.regionId) || this.featuresVisibilityInfoService.isHidden(Feature.Menu_Favorites, design.regionId)) ?
                null : favoritesTab.controlId;
        }

        // Pending calculation change subscriptions
        const pendingCalculationChangeSubscriptions: Subscription[] = [];

        // Add footer controls
        menu.footer = {};
        menu.footer.controls = {};
        for (const ctl of menuStructure.Footer?.Controls ?? []) {
            const control = this.createFooterMenuControl(ctl, design, setState, allModals);
            menu.footer.controls[control.controlProps.controlId] = control.controlProps;
        }

        // tabs
        const menuStructureTabs = menuStructure.Tabs;
        for (const navigationTab of menuStructureTabs) {
            const tab: ITab = this.createTab(tabSelected, setState, design, navigationTab);

            const selectedRegionId = this.userSettingsService.getCommonRegionById(this.userSettingsService.settings.application.general.regionId.value).id;

            // regions
            for (const navigationRegion of navigationTab.TabRegions ?? []) {
                const regionDisplayId = this.menuExtensions.getRegionDisplayId(navigationTab.Name, navigationRegion.Name);
                const regionId = this.menuExtensions.getRegionId(navigationTab.Name, navigationRegion.Name);
                const commonRegion = this.userSettingsService.getCommonRegionById(design.regionId);

                const region: IRegion = {
                    controlId: regionDisplayId,
                    title: this.getDesignSpecificLocalizedString(design, navigationRegion.DisplayKey, design.designTypeId, commonRegion),
                    favorite: favoriteIds.some((id) => id == regionId),
                    preventFavorites: navigationRegion.PreventFavorites,
                    collapsed: this.userSettingsService.settings.applicationCollapsedState.value[regionDisplayId] != null && this.userSettingsService.settings.applicationCollapsedState.value[regionDisplayId] == true,
                    controlsOrder: [],
                    kbNumber: null,
                    kbLinkTemplate: null,
                    kbTooltip: null,
                    disabled: this.featuresVisibilityInfoService.isDisabled(navigationRegion.Feature, design.regionId),
                    disabledTooltip: this.getDisabledTooltip(navigationRegion.Feature),
                    tooltipType: (navigationRegion.TooltipType as number),
                    isNew: navigationRegion.IsNew,
                    infoClicked: () => {
                        // execute command if present
                        if (navigationRegion.Command != null) {
                            const command = allRegionCommands[navigationRegion.Command as string];

                            if (command == null) {
                                this.loggerService.log(`Missing command with ID = ${navigationRegion.Command}.`, LogType.error);
                            }
                            else {
                                command();
                            }
                        }
                    }
                };

                this.menuExtensions.setRegionKbFields(design, selectedRegionId, navigationRegion, region);

                menu.regions[region.controlId] = region;
                tab.regionsOrder.push(region.controlId);

                // controls
                const navigationControls = navigationRegion.Controls?.filter((control) => control.ParentControlName == null) ?? [];
                const childNavigationControls = navigationRegion.Controls?.filter((control) => control.ParentControlName != null) ?? [];

                for (const navigationControl of navigationControls) {
                    const controlChildNavigationControls = childNavigationControls.filter((control) => control.ParentControlName == navigationControl.Name);
                    this.menuExtensions.updateControlKBNumberAci?.(design, navigationControl);

                    const control = this.createMainMenuControl(navigationControl, design, menu, menuStructure, setState, pendingCalculationChangeSubscriptions, allCommands, allModals, controlChildNavigationControls, navigationTab.Name);

                    if (control != null) {
                        menu.controls[control.controlProps.controlId] = control.controlProps;

                        this.onStateChangedUpdaters[control.controlProps.controlId] = control.onStateChanged;
                        this.onAllowedValuesChangedUpdaters[control.controlProps.controlId] = control.onAllowedValuesChanged;
                        this.onStructuralCalculationSoftwareChangedUpdaters[control.controlProps.controlId] = control.onStructuralCalculationSoftwareChanged;
                        this.onApplicationSettingChangedUpdaters[control.controlProps.controlId] = control.onApplicationSettingChanged;

                        region.controlsOrder.push(control.controlProps.controlId);
                    }
                }
            }

            // sort regions
            tab.regionsOrder = sortBy(tab.regionsOrder, (regionId) => {
                const regionIndex = this.favoritesService.order.findIndex((orderId) => regionId == this.menuExtensions.getRegionFavoritesId(orderId));
                return regionIndex < 0 ? Number.MAX_SAFE_INTEGER : regionIndex;
            });

            tab.hidden = tab.regionsOrder.every(regionId =>
                menu.regions[regionId].controlsOrder.every(id => isControlHidden(menu.controls[id]))
            );

            menu.tabs[tab.controlId] = tab;
            menu.tabsOrder.push(tab.controlId);
        }

        // Expand tab must be on the bottom
        menu.tabs[tabExpand.controlId] = tabExpand;
        menu.tabsOrder.push(tabExpand.controlId);

        // events
        const stateChanged: Parameters<typeof design.onStateChanged>[0] = (_design, state) => {
            setState(menu => {
                const controlPropsList: { [id: string]: IControlProps } = {};
                for (const id in this.onStateChangedUpdaters) {
                    if (this.onStateChangedUpdaters[id] != null) {
                        controlPropsList[id] = this.onStateChangedUpdaters[id](state, menu);
                    }
                }

                return this.updateAllMainMenuControls(menu, controlPropsList);
            });
        };
        design.onStateChanged(stateChanged);

        const allowedValuesChanged: Parameters<typeof design.onAllowedValuesChanged>[0] = (design, propertyIds) => {
            setState(menu => {
                const controlPropsList: { [id: string]: IControlProps } = {};
                for (const id in this.onAllowedValuesChangedUpdaters) {
                    if (this.onAllowedValuesChangedUpdaters[id] != null) {
                        controlPropsList[id] = this.onAllowedValuesChangedUpdaters[id](design, propertyIds, menu);
                    }
                }

                return this.updateAllMainMenuControls(menu, controlPropsList);
            });
        };
        design.onAllowedValuesChanged(allowedValuesChanged);

        const structuralCalculationSoftwareChanged = (design: Design, state: IDesignState) => {
            setState(menu => {
                const controlPropsList: { [id: string]: IControlProps } = {};

                for (const id in this.onStructuralCalculationSoftwareChangedUpdaters) {
                    if (this.onStructuralCalculationSoftwareChangedUpdaters[id] != null) {
                        controlPropsList[id] = this.onStructuralCalculationSoftwareChangedUpdaters[id](state, menu);
                    }
                }
                return this.updateAllMainMenuControls(menu, controlPropsList);
            });
        };
        design.on(DesignEvent.structuralCalculationSoftwareChanged, structuralCalculationSoftwareChanged);

        const applicationSettingsChanged = (design: Design, state: IDesignState) => {
            setState(menu => {
                const controlProps = Object.values(Object.entries(this.onApplicationSettingChangedUpdaters)).reduce((obj, [k, fn]) => ({ ...obj, [k]: fn(state, menu) }), {});
                return this.updateAllMainMenuControls(menu, controlProps);
            });
        };
        design.on(DesignEvent.applicationSettingChanged, applicationSettingsChanged);

        menu.dispose = () => {
            design.off(DesignEvent.allowedValuesChanged, allowedValuesChanged);
            design.off(DesignEvent.stateChanged, stateChanged);
            design.off(DesignEvent.structuralCalculationSoftwareChanged, structuralCalculationSoftwareChanged);
            design.off(DesignEvent.applicationSettingChanged, applicationSettingsChanged);

            // Unsubscribe from calculation change notifications
            pendingCalculationChangeSubscriptions.forEach(fn => fn.unsubscribe());
        };

        return menu;
    }

    public createMainMenuControl(
        navigationControl: MenuControl,
        design: DesignCommon,
        menu: IMenu,
        menuStructure: Menu<BaseControl, string>,
        setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu,
        pendingCalculationChangeSubscriptions: Subscription[],
        commands?: Record<string, (navigationControl?: MenuControl) => void>,
        modals?: Record<number, (input?: object) => IModalOpened>,
        childNavigationControls?: UIPropertyWithValueBaseControl[],
        tabName = ''
    ) {
        childNavigationControls = childNavigationControls || [];

        let control: IMainMenuControl<IDesignState, Design> = undefined;
        const property = navigationControl.UIPropertyId != null ? design.properties.get(navigationControl.UIPropertyId) : null;

        const controlDisabled = navigationControl.Disabled && (navigationControl.DisabledHiddenRegions == null || navigationControl.DisabledHiddenRegions.some(id => id == design.regionId));
        const isDisabled = controlDisabled || (property != null ? property.disabled : false);

        // Update child control text
        childNavigationControls.forEach(item => {
            if (property?.itemsTexts != null) {
                const itemTexts = property.itemsTexts[item.Value as number];
                if (itemTexts != null) {
                    item.DisplayKey = itemTexts.displayKey ?? item.DisplayKey;
                    item.TitleDisplayKey = itemTexts.titleDisplayKey ?? item.TitleDisplayKey;
                    item.TooltipDisplayKey = itemTexts.tooltip ?? item.TooltipDisplayKey;
                    item.TooltipTitleDisplayKey = itemTexts.tooltipTitle ?? item.TooltipTitleDisplayKey;
                }
            }
        });

        const controlProps: IControlProps = {
            controlId: tabName ? `${tabName}-${navigationControl.Name}` : `control-${navigationControl.Name}`,
            size: this.getControlSize(navigationControl.Size),
            sizeClass: this.getControlSizeClass(property != null ? (property.size ?? navigationControl.Size) : navigationControl.Size),
            disabled: isDisabled,
            hidden: property != null ? this.isControlHidden(navigationControl, design, property) : false,
            tooltipDisabled: navigationControl.TooltipDisabledDisplayKey != null && navigationControl.TooltipDisabledDisplayKey != '' ? this.getTranslation(navigationControl.TooltipDisabledDisplayKey) : null,
            tooltipDisabledTitle: navigationControl.TooltipDisabledTitleDisplayKey != null && navigationControl.TooltipDisabledTitleDisplayKey != '' ? this.getTranslation(navigationControl.TooltipDisabledTitleDisplayKey) : null,
            type: null,
            tooltipType: navigationControl.TooltipTypeFunc ? navigationControl.TooltipTypeFunc(design) : navigationControl.TooltipType ?? null,
            tooltipTitle: property?.tooltipTitle != null && property.tooltipTitle != ''
                ? this.getTranslation(property.tooltipTitle)
                : (navigationControl.TooltipTitleDisplayKey != null && navigationControl.TooltipTitleDisplayKey != '' ? this.formatDisplayString(navigationControl.TooltipTitleDisplayKey, design) : null),
            tooltip: property?.tooltip != null && property.tooltip != ''
                ? this.getTranslation(property.tooltip)
                : (navigationControl.TooltipDisplayKey != null && navigationControl.TooltipDisplayKey != '' ? this.formatDisplayString(navigationControl.TooltipDisplayKey, design) : null),
            title: property?.titleDisplayKey != null && property.titleDisplayKey != ''
                ? this.getTranslation(property.titleDisplayKey)
                : (navigationControl.TitleDisplayKey != null && navigationControl.TitleDisplayKey != '' ? this.getTranslation(navigationControl.TitleDisplayKey) : null),
            enableItemsSorting: navigationControl.EnableItemsSorting && !isDisabled,
            localization: this.localizationService
        };

        const propertyValue = navigationControl.UIPropertyId != null ? design.model[navigationControl.UIPropertyId] as any : null;

        switch (navigationControl.ControlType as string) {
            case 'TextBox':
                control = this.createMainMenuTextBox(design, controlProps, navigationControl as TextBoxControl, propertyValue, commands, setState);
                break;

            case 'Dropdown':
                control = this.createMainMenuDropdown(design, controlProps, navigationControl as DropDownControl, childNavigationControls, propertyValue, commands, setState);
                break;

            case 'Checkbox':
                control = this.createMainMenuCheckbox(design, controlProps, navigationControl as CheckBoxControl, propertyValue, commands, setState);
                break;

            case 'CheckboxGroup':
                control = this.createMainMenuCheckboxGroup(design, controlProps, navigationControl, childNavigationControls, propertyValue, commands, setState);
                break;

            case 'RadioButton':
                control = this.createMainMenuRadioButton(design, controlProps, navigationControl, propertyValue, setState);
                break;

            case 'RadioButtonGroup':
                control = this.createMainMenuRadioButtonGroup(design, controlProps, navigationControl as RadioButtonGroupControl, childNavigationControls, propertyValue, commands, setState);
                break;

            case 'Button':
                control = this.createMainMenuButton(design, controlProps, navigationControl as ButtonControl, commands, setState, pendingCalculationChangeSubscriptions);
                break;

            case 'ButtonGroup':
                control = this.createMainMenuButtonGroup(design, controlProps, navigationControl as ButtonGroupControl, childNavigationControls as ButtonControl[], commands);
                break;

            case 'ToggleButton':
                control = this.createMainMenuToggleButton(design, controlProps, navigationControl as ToggleButtonControl, propertyValue, commands, setState);
                break;

            case 'ToggleImageButton':
                control = this.createMainMenuToggleImageButton(design, controlProps, navigationControl as ToggleImageButtonControl, propertyValue, commands, setState);
                break;

            case 'ToggleButtonGroup':
                control = this.createMainMenuToggleButtonGroup(design, controlProps, navigationControl as ToggleButtonGroupControl, childNavigationControls, propertyValue, commands, setState);
                break;

            case 'Group':
                control = this.createMainMenuGroup(design, controlProps, navigationControl as GroupControl, childNavigationControls, menu, menuStructure, commands, setState, pendingCalculationChangeSubscriptions, tabName);
                break;

            case 'Label':
                control = this.createMainMenuLabel(design, controlProps, navigationControl as LabelControl, propertyValue, commands, setState);
                break;

            case 'Popup':
                throw new Error('Popup not supported!');

            case 'Rotate':
                control = this.createMainMenuRotate(design, controlProps, navigationControl as RotateControl);
                break;

            case 'PopupGrid':
                control = this.createMainMenuPopupGrid(design, controlProps, navigationControl, propertyValue, commands, setState);
                break;

            case 'PopupGridPartial':
                control = this.createMainMenuPopupGridPartial(design, controlProps, navigationControl, propertyValue, setState);
                break;

            case 'DlubalImportExport':
                control = this.createMainMenuDlubalImportExport(design, controlProps as IDlubalImportExportProps, navigationControl, propertyValue, setState);
                break;

            case 'SAP2000ImportExport':
                control = this.createMainMenuSAP2000ImportExport(design, controlProps as ISAP2000ImportExportProps, navigationControl, propertyValue, setState);
                break;

            case 'RobotImportExport':
                control = this.createMainMenuRobotImportExport(design, controlProps as IRobotImportExportProps, navigationControl, propertyValue, setState);
                break;

            case 'ETABSImportExport':
                control = this.createMainMenuETABSImportExport(design, controlProps as IETABSImportExportProps, navigationControl, propertyValue, setState);
                break;

            case 'StaadProImportExport':
                control = this.createMainMenuStaadProImportExport(design, controlProps as IStaadProImportExportProps, navigationControl, propertyValue, setState);
                break;

            case 'ImageNameRadioGroup':
                control = this.createImageNameRadioGroup(design, controlProps, navigationControl, propertyValue, setState);
                break;

            case 'ImageNameSelectionGroup':
                control = this.createImageNameSelectionGroup(design, controlProps, navigationControl as ImageNameSelectionGroupControl, propertyValue, setState);
                break;

            case 'ImageNameCheckbox':
                control = this.createImageNameCheckbox(design, controlProps as IImageNameCheckboxProps, navigationControl as CheckBoxControl, propertyValue, commands, setState);
                break;

            case 'ImageNameCheckboxGroup':
                control = this.createImageNameCheckboxGroup(design, controlProps, navigationControl, propertyValue, commands, setState);
                break;

            case 'SwitchWithDescription':
                control = this.createSwitchWithDescription(design, controlProps, navigationControl, propertyValue, setState, menu);
                break;

            case 'RangeSlider':
                control = this.createRangeSlider(design, controlProps, navigationControl, propertyValue, setState);
                break;

            case 'TabGroup':
                control = this.createMainMenuTabGroup(design, controlProps, navigationControl as TabGroupControl, childNavigationControls, menu, menuStructure, commands, modals, setState, pendingCalculationChangeSubscriptions, tabName);
                break;

            case 'CustomControl':
                control = (navigationControl as CustomControl<IControlProps, string>).initialize(controlProps, modals, setState);
        }

        // Try modules
        if (control == null) {
            control = this.menuExtensions.createMainMenuControlModule(design, navigationControl.ControlType as string, controlProps, navigationControl, propertyValue, commands, modals, setState);
        }

        // Control not found
        if (control == null) {
            this.loggerService.log(`Unknown control type ID = ${navigationControl.ControlType}.`, LogType.error);
            return null;
        }

        const onStateChanged = control.onStateChanged;
        control.onStateChanged = (state: IDesignState, menu: IMenu) => {
            const controlProps = (onStateChanged != null
                ? onStateChanged(state, menu)
                : {} as IControlProps) || {} as IControlProps;
            return this.updateNavigationControlProps(design, navigationControl, controlProps);
        };

        const onAllowedValuesChanged = control.onAllowedValuesChanged;
        control.onAllowedValuesChanged = (design: Design, propertyIds: number[], state: IMenu) => {
            const controlProps = onAllowedValuesChanged != null ? onAllowedValuesChanged(design, propertyIds, state) : {} as IControlProps;
            return controlProps;
        };

        const onStructuralCalculationSoftwareChanged = control.onStructuralCalculationSoftwareChanged;
        control.onStructuralCalculationSoftwareChanged = (state: IDesignState, menu: IMenu) => {
            const controlProps = onStructuralCalculationSoftwareChanged != null ? onStructuralCalculationSoftwareChanged(state, menu) : {} as IControlProps;
            return this.updateNavigationControlProps(design, navigationControl, controlProps);
        };

        const onApplicationSettingChanged = control.onApplicationSettingChanged;
        control.onApplicationSettingChanged = (state: IDesignState, menu: IMenu) => {
            const controlProps = onApplicationSettingChanged != null ? onApplicationSettingChanged(state, menu) : {} as IControlProps;
            return this.updateNavigationControlProps(design, navigationControl, controlProps);
        };

        return control as IMainMenuControl;
    }

    public createFooterMenuControl(
        navigationControl: MenuControl,
        design: DesignCommon,
        setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu,
        modals?: Record<number, (input?: object) => IModalOpened>,
    ) {

        let control: IMainMenuControl<IDesignStateBase, Design> = undefined;
        const property = navigationControl.UIPropertyId != null ? design.properties.get(navigationControl.UIPropertyId) : null;

        const isDisabled = (property != null ? property.disabled : false);

        const controlProps: IButtonProps = {
            controlId: `control-${navigationControl.Name}`,
            size: this.getControlSize(navigationControl.Size),
            sizeClass: this.getControlSizeClass(property != null ? (property.size ?? navigationControl.Size) : navigationControl.Size),
            disabled: isDisabled,
            hidden: property != null ? this.isControlHidden(navigationControl, design, property) : false,
            type: null,
            title: property?.titleDisplayKey != null && property.titleDisplayKey != ''
                ? this.getTranslation(property.titleDisplayKey)
                : (navigationControl.TitleDisplayKey != null && navigationControl.TitleDisplayKey != '' ? this.getTranslation(navigationControl.TitleDisplayKey) : null),
            localization: this.localizationService
        };

        control = (navigationControl as CustomControl<IControlProps, string>).initialize(controlProps, modals, setState);

        // Control not found
        if (control == null) {
            this.loggerService.log(`Unknown control type ID = ${navigationControl.ControlType}.`, LogType.error);
            return null;
        }

        this.onStateChangedUpdaters[control.controlProps.controlId] = control.onStateChanged;

        const onStateChanged = control.onStateChanged;
        control.onStateChanged = (state: IDesignStateBase, menu: IMenu) => {
            const controlProps = (onStateChanged != null
                ? onStateChanged(state, menu)
                : {} as IControlProps) || {} as IControlProps;
            return this.updateNavigationControlProps(design, navigationControl, controlProps);
        };

        return control as IMainMenuControl;
    }

    public updateAllMainMenuControls(menu: IMenu, controls: { [id: string]: IControlProps }): IMenu {
        for (const menuControlId in menu.controls) {
            const currentMenuControl = menu.controls[menuControlId];
            controls[menuControlId] = {
                ...currentMenuControl,
                ...controls[menuControlId]
            };

            this.updateAllMainMenuGroupControls(menuControlId, menu, controls);
        }

        const tabs = Object.values(menu.tabs).reduce((allTabs, tab) => {
            this.menuExtensions.updateTabDisplayKey?.(tab);

            if (tab.controlId != tabFavoritesId && tab.controlId != tabHomeId && tab.controlId != tabExpandId) {
                allTabs[tab.controlId] = {
                    ...tab,
                    hidden: tab.regionsOrder.every((regionId) => menu.regions[regionId].controlsOrder.every(id => isControlHidden(controls[id])))
                };
            }
            else {
                allTabs[tab.controlId] = tab;
            }

            return allTabs;
        }, {} as { [id: string]: ITab });

        const selectedTab = menu.selectedTab != null && tabs[menu.selectedTab].hidden
            ? tabFavoritesId
            : menu.selectedTab;

        this.userService.design.menuTabSelected = selectedTab;

        return {
            ...menu,
            controls: {
                ...menu.controls,
                ...controls
            },
            tabs: {
                ...menu.tabs,
                ...tabs
            },
            selectedTab
        };
    }

    public getDisabledTooltip(feature: Feature) {
        if (this.featuresVisibilityInfoService.isDisabled(feature, this.userService.design.region.id)) {
            return this.getTranslation('Agito.Hilti.Profis3.Navigation.NoLicense.' + Feature[feature] + '.Tooltip');
        }

        return null;
    }

    public clearUpdaters(): void {
        this.onStateChangedUpdaters = {};
        this.onAllowedValuesChangedUpdaters = {};
        this.onStructuralCalculationSoftwareChangedUpdaters = {};
        this.onApplicationSettingChangedUpdaters = {};
    }


    // Common
    // Tabs
    private createTab(
        tabSelected: (selectedTab: string) => void,
        setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu,
        design: DesignCommon,
        navigationTab: Tab<BaseControl, string>
    ) {
        const tabControlId = this.menuExtensions.getTabControlId(navigationTab.Name);

        return {
            controlId: tabControlId,
            backgroundColor: navigationTab.BackgroundColor,
            textColor: navigationTab.TextColor,
            image: `sprite-${navigationTab.Image}`,
            imageStyle: navigationTab.IconImage,
            selectedImage: `sprite-${navigationTab.Image}`,
            selectedImageStyle: navigationTab.IconImage,
            regionsOrder: [] as string[],
            bottomSeparator: false,
            displayKey: navigationTab.DisplayKey,
            tabSelected: () => {
                if (this.menuExtensions.isCustomTab?.(tabControlId)) {
                    this.menuExtensions.onTabSelected?.(design, tabControlId);
                    return;
                }

                this.userService.design.menuTabSelected = tabControlId;

                setState(prevMenu => {
                    const newMenu = {
                        ...prevMenu,
                        selectedTab: tabControlId
                    };

                    return newMenu;
                });

                this.menuExtensions.onTabSelected?.(design, tabControlId);

                tabSelected(tabControlId);
            },
            width: navigationTab.Width,
            disabled: this.menuExtensions.isTabDisabled(design, tabControlId)
        };
    }

    private createHomeTab() {
        return {
            controlId: tabHomeId,
            image: 'sprite-loading-logo-small',
            selectedImage: 'sprite-loading-logo-small',
            regionsOrder: [] as string[],
            bottomSeparator: true,
            tabSelected: () => {
                if (!this.offlineService.isOffline) {
                    const promises: Promise<void>[] = [];

                    if (environment.integrationServicesServerEnabled && this.userSettingsService.settings.application.general.risaEnabled.value) {
                        const url = `${environment.integrationServicesServerUrl}api/document/${DocumentIntegrationType.Risa}/listDocuments/true`;
                        promises.push(
                            this.apiService.request<DocumentModel[]>(new HttpRequest('GET', url))
                                .then(response => {
                                    this.integrationsDocumentService.RisaDocuments = response.body || [];
                                })
                        );
                    }

                    if (environment.integrationServicesServerEnabled && this.userSettingsService.settings.application.general.ramEnabled.value) {
                        const url = `${environment.integrationServicesServerUrl}api/document/${DocumentIntegrationType.Ram}/listDocuments/true`;
                        promises.push(
                            this.apiService.request<DocumentModel[]>(new HttpRequest('GET', url))
                                .then(response => {
                                    this.integrationsDocumentService.RamDocuments = response.body || [];
                                })
                        );
                    }

                    if (promises.length > 0) {
                        Promise.all(promises).then(() => this.routingService.navigateToUrl(urlPath.projectAndDesign));
                    }
                    else {
                        this.routingService.navigateToUrl(urlPath.projectAndDesign);
                    }
                }
                else {
                    if (this.userService.design.isStateChanged) {
                        openConfirmChangeForOffline(
                            this.routingService,
                            this.offlineService,
                            this.modalService,
                            this.localizationService,
                            this.documentService,
                            this.apiService,
                            this.browser,
                            this.userService.design,
                            false,
                            window);
                    }
                    else {
                        this.routingService.navigateToUrl(urlPath.projectAndDesign);
                    }
                }
            },
            displayKey: 'Agito.Hilti.Profis3.Navigation.TabHome',
            width: NavigationTabWidth.Normal
        };
    }

    private createFavoritesTab(
        tabSelected: (selectedTab: string) => void,
        setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu,
        design: DesignCommon,
        favoriteIds: string[]
    ): ITab {
        return {
            controlId: tabFavoritesId,
            image: 'sprite-tab-favorites',
            selectedImage: 'sprite-tab-favorites',
            regionsOrder: favoriteIds.map((id) => this.menuExtensions.getRegionFavoritesId(id)),
            bottomSeparator: false,
            displayKey: 'Agito.Hilti.Profis3.Navigation.TabFavorites',
            tabSelected: () => {
                setState(prevMenu => ({
                    ...prevMenu,
                    selectedTab: tabFavoritesId
                }));

                tabSelected(tabFavoritesId);
            },
            width: NavigationTabWidth.Normal,
            hidden: this.menuExtensions.isFavoritesTabHidden(design)
        };
    }

    private createTabExtend(
        tabSelected: (selectedTab: string) => void,
        setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu
    ) {
        return {
            controlId: tabExpandId,
            image: 'sprite-arrow-right-white',
            selectedImage: 'sprite-arrow-left-white',
            regionsOrder: [] as string[],
            bottomSeparator: false,
            tabSelected: () => {
                setState(prevMenu => ({
                    ...prevMenu,
                    menuExpanded: !prevMenu.menuExpanded
                }));

                tabSelected(null);
            },
            displayKey: '',
            width: NavigationTabWidth.Normal
        };
    }

    // TextBox
    private createMainMenuTextBox(design: DesignCommon, controlProps: ITextBoxProps, navigationControl: TextBoxControl, propertyValue: any, commands: Record<string, (navigationControl?: BaseControl) => void>, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {
        controlProps.type = TextBox;
        controlProps.unitService = this.unitService;
        controlProps.fixedDecimals = navigationControl.FixedDecimals;
        controlProps.value = this.formatTextboxValue(propertyValue, navigationControl.UnitGroup, navigationControl.UIPropertyId, null, navigationControl.FixedDecimals);
        controlProps.valueTitle = this.formatTextboxValueTitle(propertyValue, navigationControl.UnitGroup, navigationControl.UIPropertyId);
        controlProps.exactValue = propertyValue as number;
        controlProps.stepper = true;
        controlProps.stepValue = navigationControl.StepValue;
        controlProps.unitGroup = navigationControl.UnitGroup;
        controlProps.uiProperty = navigationControl.UIPropertyId;
        controlProps.kbTooltip = navigationControl.KBTooltipDisplayKey;
        controlProps.kbLink = this.menuExtensions.getFormattedKBLinkByRegionSpecificTemplate?.(design, navigationControl.KBNumberAciRegion);
        controlProps.commitValue = (value) => {
            // change UI property
            if (navigationControl.UIPropertyId != null) {
                const parsedValue = this.parseTextboxValueToUnitValue(value, navigationControl.UnitGroup);
                if (typeof parsedValue === 'number') {
                    design.model[navigationControl.UIPropertyId] = parsedValue;
                }
                else {
                    const newUnitValue = parsedValue;

                    if (newUnitValue == null) {
                        design.model[navigationControl.UIPropertyId] = null;
                    }
                    else if (Number.isNaN(newUnitValue.value)) {
                        // Rely on server to return error.
                        design.model[navigationControl.UIPropertyId] = value;
                    }
                    else {
                        const oldValue = design.model[navigationControl.UIPropertyId] as number;
                        const oldUnitValue = this.unitService.convertInternalValueToDefaultUnitValue(oldValue, controlProps.unitGroup);

                        if (newUnitValue.unit != oldUnitValue.unit || newUnitValue.value != oldUnitValue.value) {
                            const newUnitInternalValue = this.unitService.convertUnitValueToInternalUnitValue(newUnitValue)?.value;
                            design.model[navigationControl.UIPropertyId] = newUnitInternalValue;
                        }
                    }
                }

                // Run calculation
                this.menuExtensions.calculateAsync(design);
            }
        };

        this.menuExtensions.overrideTextBoxUnitGroup(
            design, controlProps, navigationControl,
            () => setState(menu => this.updateMainMenuControl<ITextBoxProps>(menu, controlProps.controlId, { unitGroup: controlProps.unitGroup }))
        );

        controlProps.valueChanged = (value, exactValue) => {
            // change menu
            setState(menu => this.updateMainMenuControl<ITextBoxProps>(menu, controlProps.controlId, { value, exactValue }));
        };

        controlProps.infoClicked = () => {
            // execute command if present
            if (navigationControl.Command != null) {
                const command = commands[navigationControl.Command];
                if (command == null) {
                    this.loggerService.log(`Missing command with ID = ${navigationControl.Command}.`, LogType.error);
                }
                else {
                    command(navigationControl);
                }
            }
        };

        const onStateChanged = (state: IDesignState) => {
            const value = navigationControl.UIPropertyId != null
                ? design.model[navigationControl.UIPropertyId] as any
                : null;

            const newControlProps = {
                value: this.formatTextboxValue(
                    value,
                    navigationControl.UnitGroup,
                    navigationControl.UIPropertyId,
                    null,
                    navigationControl.FixedDecimals),
                exactValue: state.model[navigationControl.UIPropertyId] as number,
                valueTitle: this.formatTextboxValueTitle(
                    value,
                    navigationControl.UnitGroup,
                    navigationControl.UIPropertyId),
                unitService: controlProps.unitService ?? this.unitService,
            } as ITextBoxProps;

            this.menuExtensions.setTextBoxOnStateChanged(state, newControlProps, navigationControl);

            return newControlProps;
        };

        this.menuExtensions.overrideTextBoxProps(design, controlProps, navigationControl);

        return {
            controlProps,
            onStateChanged
        };
    }

    private formatTextboxValueTitle(value: any, unitGroup: UnitGroup, uiPropertyId: number) {
        const displayedUnitValue = this.formatTextboxValue(value, unitGroup, uiPropertyId);

        const maxPrecision = this.unitService.getDefaultPrecision() + 1;  // Same as used in server code (UnitHelper.ConvertUnitTo)!
        const exactUnitValue = this.formatTextboxValue(value, unitGroup, uiPropertyId, maxPrecision);

        if (displayedUnitValue?.length < exactUnitValue?.length) {
            return exactUnitValue;
        }

        return undefined;
    }

    private formatTextboxValue(value: any, unitGroup: UnitGroup, uiPropertyId: number, precision?: number, toFixed?: boolean) {
        if (unitGroup) {
            const unitValue = this.unitService.convertInternalValueToDefaultUnitValue(value as number, unitGroup);
            return this.unitService.formatUnitValue(unitValue, precision, null, null, uiPropertyId, toFixed);
        }

        return this.unitService.formatNumber(this.unitService.parseNumber(value as string), precision ?? this.unitService.getPrecision(Unit.None, uiPropertyId), toFixed);
    }

    private parseTextboxValueToUnitValue(value: string, unitGroup: UnitGroup) {
        return unitGroup ?
            this.unitService.parseUnitValue(value, unitGroup) :
            this.unitService.parseNumber(value);
    }

    // Dropdown
    private createMainMenuDropdown(design: DesignCommon, controlProps: IDropdownProps, navigationControl: DropDownControl, childNavigationControls: UIPropertyWithValueBaseControl[], propertyValue: number, commands: Record<string, (navigationControl?: BaseControl) => void>, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {
        controlProps.type = Dropdown;
        controlProps.dropdownType = navigationControl.DropdownType;
        controlProps.selectedValue = propertyValue;

        const property = navigationControl.UIPropertyId != null ? design.properties.get(navigationControl.UIPropertyId) : null;

        let designCodeList: number = null;
        let codeListItems: CodeList[] = null;

        if (navigationControl.CodelistName != null && navigationControl.CodelistName != '') {
            const d = this.menuExtensions.getCodeListIdWithItems(design, navigationControl);
            designCodeList = d.codeList;
            codeListItems = d.codeListItems;
        }

        if (childNavigationControls.length > 0) {
            controlProps.initialItems = childNavigationControls.map((navigationControl) => ({
                value: navigationControl.Value,
                text: navigationControl.DisplayKey != null && navigationControl.DisplayKey != '' ? this.getTranslation(navigationControl.DisplayKey) : null,
                textTranslationKey: navigationControl.DisplayKey,
            }) as IDropdownItem);
        }
        else {
            const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService, this.getTranslationFuncBound());
            const ddip = this.menuExtensions.getDropdownItemProps(design, controlProps, navigationControl, designCodeList);

            controlProps.initialItems = codeListItems?.map((codeListItem) => {
                return {
                    value: codeListItem.id,
                    text: (() => {
                        return this.menuExtensions.getCodeListItemText(design, designCodeList, codeListItem, codeListDeps);
                    })(),
                    textTranslationKey: codeListItem.nameResourceKey,
                    addOnActive: ddip?.addOnActive?.(codeListItem) ?? false,
                    addOnText: ddip?.addOnText?.(codeListItem),
                    tag: codeListItem.tag != null && codeListItem.tag.length > 0 ? this.getTranslation(codeListItem.tag) : null,
                    isNew: ddip?.isNew?.(codeListItem) ?? false,
                    internalPortfolioOnly: ddip?.internalPortfolioOnly?.(codeListItem) ?? false,
                } as IDropdownItem;
            });
        }

        // filter dropdown items
        const allowedValues = design.properties.get(navigationControl.UIPropertyId).allowedValues;
        controlProps.items = controlProps.initialItems?.filter((item) => allowedValues != null ? allowedValues.includes(item.value) : true);

        // Update item texts if there are special cases
        if (property?.itemsTexts != null) {
            const itemTexts = property.itemsTexts;

            forEach(controlProps.items, item => {
                const displayKey = itemTexts[item.value]?.displayKey;

                if (displayKey != null) {
                    item.text = this.formatDisplayString(displayKey, design, null, navigationControl.UIPropertyId);
                }
            });
        }

        controlProps.valueChanged = (value) => {
            // change menu
            setState(menu => this.updateMainMenuControl<IDropdownProps>(menu, controlProps.controlId, { selectedValue: value }));

            // change UI property
            this.updateUiProperty(design, navigationControl.UIPropertyId, value);

        };

        controlProps.infoClicked = () => {
            // execute command if present
            if (navigationControl.Command != null) {
                const command = commands[navigationControl.Command];

                if (command == null) {
                    this.loggerService.log(`Missing command with ID = ${navigationControl.Command}.`, LogType.error);
                }
                else {
                    command(navigationControl);
                }
            }
        };

        const onStateChanged = (state: IDesignState, menu: IMenu) => {
            const control: IDropdownProps = menu.controls[controlProps.controlId];

            navigationControl.OnDropdownStateChanged?.({
                design: design,
                control: control,
                localizationService: this.localizationService
            } as IDropdownOnChangeParams);

            const onStateChangeFn = (formatTextFn: (codeListItem: CodeList, unit?: Unit) => string, unit?: Unit) => {
                const codeListItems = this.menuExtensions.getCodeListItems(navigationControl, design, designCodeList);
                const initialItems = codeListItems?.map((codeListItem) => ({
                    value: codeListItem.id,
                    text: formatTextFn(codeListItem, unit)
                }) as IDropdownItem);
                const allowedValues = design.properties.get(navigationControl.UIPropertyId).allowedValues;
                const items = initialItems?.filter((item) => allowedValues != null ? allowedValues.includes(item.value) : true);
                return {
                    selectedValue: state.model[navigationControl.UIPropertyId],
                    initialItems,
                    items
                } as IDropdownProps;
            };

            return this.menuExtensions.setDropdownOnStateChanged(
                design,
                designCodeList,
                state,
                menu,
                navigationControl,
                controlProps,
                onStateChangeFn
            );
        };

        const onAllowedValuesChanged = (design: Design, propertyIds: number[], state: IMenu) => {
            const control = (state.controls[controlProps.controlId] as IDropdownProps);

            if (!propertyIds.some((id) => id == navigationControl.UIPropertyId) || control == null) {
                return {} as IDropdownProps;
            }

            this.menuExtensions.setDropdownOnAllowedValuesChanged(design, control, navigationControl);

            const allowedValues = design.properties.get(navigationControl.UIPropertyId).allowedValues;
            return { items: control.initialItems.filter((item) => allowedValues != null ? allowedValues.includes(item.value) : true) } as IDropdownProps;
        };

        this.menuExtensions.overrideDropdownProps(design, controlProps, navigationControl);

        return {
            controlProps,
            onStateChanged,
            onAllowedValuesChanged
        };
    }

    // Checkbox
    private createMainMenuCheckbox(design: DesignCommon, controlProps: ICheckboxProps, navigationControl: CheckBoxControl, propertyValue: boolean, commands: Record<string, (navigationControl?: BaseControl) => void>, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {
        controlProps.type = Checkbox;
        controlProps.checked = propertyValue;
        controlProps.value = navigationControl.Value as number;
        controlProps.text = this.getTranslation(navigationControl?.DisplayKey);

        controlProps.iconImage = navigationControl.Image ? `sprite-${navigationControl.Image}` : null;
        controlProps.kbTooltip = navigationControl.KBTooltipDisplayKey;
        controlProps.tooltip = controlProps.tooltip ?? this.formatDisplayString(navigationControl.TooltipDisplayKey, design, null, navigationControl.UIPropertyId);

        controlProps.kbLink = this.menuExtensions.getFormattedKBLinkByRegionSpecificTemplate?.(design, navigationControl.KBNumberAciRegion);

        const controlUIProperty = navigationControl.UIPropertyId != null ? design.properties.get(navigationControl.UIPropertyId) : null;
        if (controlUIProperty?.displayKey != null) {
            controlProps.text = this.getTranslation(controlUIProperty.displayKey);
        }

        controlProps.checkedChanged = (checked) => {
            // change menu
            setState(menu => this.updateMainMenuControl<ICheckboxProps>(menu, controlProps.controlId, { checked }));

            // change UI property
            this.updateUiProperty(design, navigationControl.UIPropertyId, checked);

            // track usage
            if (checked) {
                setState(menu => {
                    const latestControlProps = menu.controls[controlProps.controlId];
                    this.menuExtensions.trackUsage(navigationControl, latestControlProps, design);
                    return menu;
                });
            }
        };

        controlProps.infoClicked = () => {
            // execute command if present
            if (navigationControl.Command != null) {
                const command = commands[navigationControl.Command];

                if (command == null) {
                    this.loggerService.log(`Missing command with ID = ${navigationControl.Command}.`, LogType.error);
                    return;
                }

                command(navigationControl);
            }
        };

        const onStateChanged = (state: IDesignState) => {
            const property = state.properties.get(navigationControl.UIPropertyId);

            const retVal = {
                checked: state.model[navigationControl.UIPropertyId],
                tooltip: this.formatDisplayString(property?.tooltip ?? navigationControl.TooltipDisplayKey, design, null, navigationControl.UIPropertyId)
            } as ICheckboxProps;

            // Rotate Right icon
            const iconUIRotatedProperty = navigationControl.RotateRightUIPropertyId != null
                ? design.properties.get(navigationControl.RotateRightUIPropertyId)
                : null;
            if (iconUIRotatedProperty != null) {
                retVal.iconSelected = state.model[navigationControl.RotateRightUIPropertyId] as boolean;
                retVal.iconDisabled = iconUIRotatedProperty.disabled;
                retVal.iconHidden = iconUIRotatedProperty.hidden;
            }

            return retVal;
        };

        // Rotate Right icon
        controlProps.iconDisabled = null;
        controlProps.iconHidden = null;
        controlProps.iconSelected = null;
        const iconUIProperty = navigationControl.RotateRightUIPropertyId != null ? design.properties.get(navigationControl.RotateRightUIPropertyId) : null;
        if (iconUIProperty != null) {
            controlProps.iconDisabled = iconUIProperty.disabled;
            controlProps.iconHidden = iconUIProperty.hidden;
            controlProps.iconSelected = design.model[navigationControl.RotateRightUIPropertyId] as boolean;

            controlProps.iconSelectedChanged = (selected) => {
                // change menu
                setState(menu => this.updateMainMenuControl<ICheckboxProps>(menu, controlProps.controlId, { iconSelected: selected }));

                // change UI property
                this.updateUiProperty(design, navigationControl.RotateRightUIPropertyId, selected);
            };
        }

        this.menuExtensions.overrideCheckboxProps(design, controlProps, navigationControl);

        return {
            controlProps,
            onStateChanged
        };
    }

    private getCodeListTranslatedTooltip(codeListItem: { tooltipDisplayKey?: string; tooltip?: string; }): string | null {
        if (codeListItem.tooltip) {
            return codeListItem.tooltip;
        }

        return codeListItem.tooltipDisplayKey ? this.getTranslation(codeListItem.tooltipDisplayKey) : null;
    }

    private formatCodeListTranslatedTooltip(codeListItem: { tooltipDisplayKey?: string; tooltip?: string; }, design: DesignCommon, codeList?: number, uiPropertyId?: number): string | null {
        if (codeListItem.tooltip) {
            return codeListItem.tooltip;
        }

        return this.formatDisplayString(codeListItem.tooltipDisplayKey, design, codeList, uiPropertyId);
    }

    private getCodeListTranslatedTooltipTitle(codeListItem: { tooltipTitleDisplayKey?: string; tooltipTitle?: string; }): string | null {
        if (codeListItem.tooltipTitle) {
            return codeListItem.tooltipTitle;
        }

        return codeListItem.tooltipTitleDisplayKey ? this.getTranslation(codeListItem.tooltipTitleDisplayKey) : null;
    }

    private formatCodeListTranslatedTooltipTitle(codeListItem: { tooltipTitleDisplayKey?: string; tooltipTitle?: string; }, design: DesignCommon, codeList?: number, uiPropertyId?: number): string | null {
        if (codeListItem.tooltipTitle) {
            return codeListItem.tooltipTitle;
        }

        return this.formatDisplayString(codeListItem.tooltipTitleDisplayKey, design, codeList, uiPropertyId);
    }

    // CheckboxGroup
    private createMainMenuCheckboxGroup(design: DesignCommon, controlProps: ICheckboxGroupProps, navigationControl: CheckboxGroupControl, childNavigationControls: UIPropertyWithValueBaseControl[], propertyValue: number[], commands: Record<string, (navigationControl?: BaseControl) => void>, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {
        controlProps.type = CheckboxGroup;
        controlProps.checkedValue = propertyValue;

        let codeListItems: CodeList[] = null;
        let designCodeList: number = null;

        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService, this.getTranslationFuncBound());

        if (childNavigationControls.length > 0) {
            controlProps.initialItems = childNavigationControls.map((navigationControl) => ({
                value: navigationControl.Value,
                text: navigationControl.DisplayKey != null && navigationControl.DisplayKey != '' ? this.getTranslation(navigationControl.DisplayKey) : null,
                textTranslationKey: navigationControl.DisplayKey,
                tooltip: navigationControl.TooltipDisplayKey != null && navigationControl.TooltipDisplayKey != '' ? this.getTranslation(navigationControl.TooltipDisplayKey) : null,
                tooltipTitle: navigationControl.TooltipTitleDisplayKey != null && navigationControl.TooltipTitleDisplayKey != '' ? this.getTranslation(navigationControl.TooltipTitleDisplayKey) : null,
                tooltiptype: navigationControl.TooltipType,
                onInfoClick: this.getOnInfoClickMethodByControl(navigationControl, commands)
            }) as ICheckboxGroupItem);
        }
        else {
            if (navigationControl.CodelistName != null && navigationControl.CodelistName != '') {
                const d = this.menuExtensions.getCodeListIdWithItems(design, navigationControl);
                codeListItems = d.codeListItems;
                designCodeList = d.codeList;
            }

            controlProps.initialItems = codeListItems.map((codeListItem) => ({
                value: codeListItem.id,
                text: this.menuExtensions.getCodeListItemText(design, designCodeList, codeListItem, codeListDeps),
                textTranslationKey: codeListItem.nameResourceKey,
                tooltip: this.getCodeListTranslatedTooltip(codeListItem),
                tooltipTitle: this.getCodeListTranslatedTooltipTitle(codeListItem),
                tooltiptype: codeListItem.infoPopupCommand ? TooltipType.Popup : TooltipType.Normal,
                onInfoClick: this.getOnInfoClickMethodByCodeList(codeListItem, commands)
            }) as ICheckboxGroupItem);
        }

        // filter group items
        const allowedValues = design.properties.get(navigationControl.UIPropertyId).allowedValues;
        controlProps.items = controlProps.initialItems.filter((item) => allowedValues != null ? allowedValues.includes(item.value) : true);

        // disabled items
        const disabledValues = design.properties.get(navigationControl.UIPropertyId).disabledValues;
        if (disabledValues)
            controlProps.items.forEach(item => item.disabled = disabledValues.includes(item.value));

        navigationControl.OnStateChanged?.({
            design,
            control: controlProps,
            localizationService: this.localizationService
        } as ICheckboxGroupOnChangeParams);

        controlProps.infoClicked = this.getOnInfoClickMethodByControl(navigationControl, commands);

        controlProps.valueChanged = (value) => {
            // change menu
            setState(menu => this.updateMainMenuControl<ICheckboxGroupProps>(menu, controlProps.controlId, { checkedValue: value }));

            // change UI property
            this.updateUiProperty(design, navigationControl.UIPropertyId, value);
        };

        const onStateChanged = (state: IDesignState, menu: IMenu) => {
            const control: ICheckboxGroupProps = menu.controls[controlProps.controlId];
            if (control == null) {
                return null;
            }

            if (childNavigationControls.length == 0) {
                control.items = control.items.map((item) => {
                    const codeListItem = codeListItems.find(cli => cli.id == item.value);
                    return {
                        ...item,
                        text: this.menuExtensions.getCodeListItemText(design, designCodeList, codeListItem, codeListDeps),
                    } as ICheckboxGroupItem;
                });
            }

            const disabledValues = design.properties.get(navigationControl.UIPropertyId).disabledValues;
            if (disabledValues)
                control.items.forEach(x => x.disabled = disabledValues.includes(x.value));

            navigationControl.OnStateChanged?.({
                design,
                control,
                localizationService: this.localizationService
            } as ICheckboxGroupOnChangeParams);

            return {
                checkedValue: state.model[navigationControl.UIPropertyId],
                items: control.items
            } as ICheckboxGroupProps;
        };

        const onAllowedValuesChanged = (design: Design, propertyIds: number[]) => {
            if (!propertyIds.some((id) => id == navigationControl.UIPropertyId)) {
                return {} as ICheckboxGroupProps;
            }

            const allowedValues = design.properties.get(navigationControl.UIPropertyId).allowedValues;
            return { items: controlProps.initialItems.filter((item) => allowedValues != null ? allowedValues.includes(item.value) : true) } as ICheckboxGroupProps;
        };

        this.menuExtensions.overrideCheckboxGroupProps(design, controlProps, navigationControl);

        return {
            controlProps,
            onStateChanged,
            onAllowedValuesChanged
        };
    }

    // RadioButton
    private createMainMenuRadioButton(design: DesignCommon, controlProps: IRadioButtonProps, navigationControl: UIPropertyWithValueBaseControl, propertyValue: boolean, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {
        controlProps.type = RadioButton;
        controlProps.checked = propertyValue;
        controlProps.value = navigationControl.Value as number;
        controlProps.text = navigationControl.DisplayKey != null && navigationControl.DisplayKey != '' ? this.getTranslation(navigationControl.DisplayKey) : null;

        controlProps.checkedChanged = (checked) => {
            // change menu
            setState(menu => this.updateMainMenuControl<IRadioButtonProps>(menu, controlProps.controlId, { checked }));

            // change UI property
            this.updateUiProperty(design, navigationControl.UIPropertyId, checked);
        };

        const onStateChanged = (state: IDesignState) => {
            return { checked: state.model[navigationControl.UIPropertyId] } as IRadioButtonProps;
        };

        this.menuExtensions.overrideRadioButtonProps(design, controlProps, navigationControl);

        return {
            controlProps,
            onStateChanged
        };
    }

    // RadioButtonGroup
    private createMainMenuRadioButtonGroup(design: DesignCommon, controlProps: IRadioButtonGroupProps, navigationControl: RadioButtonGroupControl, childNavigationControls: UIPropertyWithValueBaseControl[], propertyValue: number, commands: Record<string, (navigationControl?: BaseControl) => void>, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {
        const controlId = controlProps.controlId;

        controlProps.type = RadioButtonGroup;
        controlProps.checkedValue = propertyValue;

        let designCodeList: number = null;
        let codeListItems: CodeList[] = null;

        if (childNavigationControls.length > 0) {
            controlProps.initialItems = childNavigationControls.map((navControl) => ({
                value: navControl.Value,
                text: this.getTranslation(navControl.DisplayKey),
                tooltip: this.formatDisplayString(navControl.TooltipDisplayKey, design),
                tooltipTitle: this.formatDisplayString(navControl.TooltipTitleDisplayKey, design),
                uiPropertyId: navigationControl.UIPropertyId,
                tooltiptype: navigationControl.TooltipType,
                onInfoClick: this.getOnInfoClickMethodByControl(navigationControl, commands)
            }) as IRadioButtonGroupItem);
        }
        else {
            if (navigationControl.CodelistName != null && navigationControl.CodelistName != '') {
                const d = this.menuExtensions.getCodeListIdWithItems(design, navigationControl);
                designCodeList = d.codeList;
                codeListItems = d.codeListItems;
            }

            const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService, this.getTranslationFuncBound());
            controlProps.initialItems = codeListItems?.map((codeListItem) => ({
                value: codeListItem.id,
                text: this.menuExtensions.getCodeListItemText(design, designCodeList, codeListItem, codeListDeps),
                tooltip: this.formatCodeListTranslatedTooltip(codeListItem, design, designCodeList),
                tooltipTitle: this.formatCodeListTranslatedTooltipTitle(codeListItem, design, designCodeList),
                uiPropertyId: navigationControl.UIPropertyId,
                tag: codeListItem.tag,
                tooltiptype: codeListItem.infoPopupCommand ? TooltipType.Popup : TooltipType.Normal,
                onInfoClick: this.getOnInfoClickMethodByCodeList(codeListItem, commands)
            }) as IRadioButtonGroupItem);
        }

        // filter group items
        controlProps.items = this.getRadioButtonGroupFilteredItems(controlProps, navigationControl.UIPropertyId, design);

        controlProps.valueChanged = (value) => {
            // change menu
            setState(menu => this.updateMainMenuControl<IRadioButtonGroupProps>(menu, controlProps.controlId, { checkedValue: value }));

            // change UI property
            this.updateUiProperty(design, navigationControl.UIPropertyId, value);
        };

        controlProps.infoClicked = this.getOnInfoClickMethodByControl(navigationControl, commands);

        const onStateChanged = (state: IDesignState) => {
            const menu = setState();
            const controlProps = menu.controls[controlId] as IRadioButtonGroupProps;

            const newControlProps = {
                checkedValue: state.model[navigationControl.UIPropertyId] as number
            } as Partial<IRadioButtonGroupProps>;

            // Handle disabled items
            const items = this.getRadioButtonGroupFilteredItems(controlProps, navigationControl.UIPropertyId, design);

            if (childNavigationControls.length > 0) {
                const updateTexts = (item: IRadioButtonGroupItem) => {
                    const navigationControl = childNavigationControls.find(navigationControl => navigationControl.Value == item.value);

                    const itemProperty = item.uiPropertyId != null ? design.properties.get(item.uiPropertyId) : null;

                    let text = item.text;
                    let tooltip = item.tooltip;
                    let tooltipTitle = item.tooltipTitle;

                    if (itemProperty) {
                        const o = itemProperty.itemsTexts[item.value] || ({} as UIPropertyTexts);

                        text = this.getTranslation(o.titleDisplayKey || navigationControl.DisplayKey);
                        tooltip = this.formatDisplayString(o.tooltip || navigationControl.TooltipDisplayKey, design, designCodeList);
                        tooltipTitle = this.formatDisplayString(o.tooltipTitle || navigationControl.TooltipTitleDisplayKey, design, designCodeList);
                    }

                    return {
                        ...item, tooltipTitle, tooltip, text
                    } as IRadioButtonGroupItem;
                };

                newControlProps.initialItems = controlProps.initialItems?.map(updateTexts);
                newControlProps.items = items?.map(updateTexts);
            }
            else {
                const updateTexts = (item: IRadioButtonGroupItem) => {
                    const codeListItem = codeListItems.find(cli => cli.id == item.value);
                    const itemProperty = item.uiPropertyId != null ? design.properties.get(item.uiPropertyId) : null;

                    let text = item.text;
                    let tooltip = item.tooltip;
                    let tooltipTitle = item.tooltipTitle;

                    if (itemProperty) {
                        const o = itemProperty.itemsTexts[item.value] || ({} as UIPropertyTexts);
                        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService, this.getTranslationFuncBound());
                        text = this.getTranslation(o.titleDisplayKey) ||
                            this.menuExtensions.getCodeListItemText(design, designCodeList, codeListItem, codeListDeps);
                        tooltip = o.tooltipTitle ? this.formatDisplayString(o.tooltipTitle, design, designCodeList) : this.formatCodeListTranslatedTooltip(codeListItem, design, designCodeList);
                        tooltipTitle = o.tooltipTitle ? this.formatDisplayString(o.tooltipTitle, design, designCodeList) : this.formatCodeListTranslatedTooltipTitle(codeListItem, design, designCodeList);
                    }

                    return {
                        ...item, tooltipTitle, tooltip, text
                    } as IRadioButtonGroupItem;
                };

                newControlProps.initialItems = controlProps.initialItems?.map(updateTexts);
                newControlProps.items = items?.map(updateTexts);
            }

            return newControlProps as IRadioButtonGroupProps;
        };

        const onAllowedValuesChanged = (design: Design, propertyIds: number[]) => {
            if (!propertyIds.some((id) => id == navigationControl.UIPropertyId)) {
                return {} as IRadioButtonGroupProps;
            }

            const items = this.getRadioButtonGroupFilteredItems(controlProps, navigationControl.UIPropertyId, design);
            return ({ items }) as IRadioButtonGroupProps;
        };

        this.menuExtensions.overrideRadioButtonGroupProps(design, controlProps, navigationControl);

        return {
            controlProps,
            onStateChanged,
            onAllowedValuesChanged
        };
    }


    private getRadioButtonGroupFilteredItems(controlProps: IRadioButtonGroupProps, uiPropertyId: number, design: DesignCommon) {
        // filter group items
        const allowedValues = design.properties.get(uiPropertyId).allowedValues;
        let disabledValues = design.properties.get(uiPropertyId).disabledValues;

        const displayValues = allowedValues != null
            ? disabledValues != null
                ? [...allowedValues, ...disabledValues]
                : allowedValues
            : disabledValues;
        disabledValues = disabledValues || [];

        // Items
        // displayValues == null:    no filtering, displays all initial items
        // displayValues == []:      applies filtering, displays no items
        // displayValues == [ ... ]  applies filtering, display some items
        const items = controlProps.initialItems?.filter((item) =>
            displayValues != null
                ? displayValues.includes(item.value)
                : true);

        items?.forEach((item) => { item.disabled = disabledValues.includes(item.value); });
        return items;
    }

    private getImageNameRadioGroupSortedAndFilteredItems(controlProps: IImageNameRadioGroupProps, codeList: number, uiPropertyId: number, design: DesignCommon) {
        // filter group items
        const allowedValues = design.properties.get(uiPropertyId).allowedValues;

        // Items
        const items = controlProps.initialItems?.filter((item) =>
            allowedValues != null
                ? allowedValues.includes(item.value)
                : true);

        this.menuExtensions.applyProperSingleImage(items, design);

        const itemIds = this.menuExtensions.getApplySortOrderItemIds(design, codeList);
        this.applySortOrder(items, itemIds);

        const parentId = this.menuExtensions.getRadioGroupParentId(design, codeList);

        return ({
            items,
            parentId
        });
    }

    private applySortOrder(items: IImageNameRadioGroupItem[], itemIds: number[]) {
        if (itemIds != null && itemIds.length > 0) {
            items.forEach((item) => item.sortOrder = itemIds.indexOf(item.value));

            if (items.some(x => x.sortOrder != null)) {
                items.sort((a, b) => (a.sortOrder ?? Infinity) - (b.sortOrder ?? Infinity));
            }
        }
    }

    // Button
    private createMainMenuButton(design: DesignCommon, controlProps: IButtonProps, navigationControl: ButtonControl, commands: Record<string, (navigationControl?: BaseControl) => void>, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu, pendingCalculationChangeSubscriptions: Subscription[]) {
        controlProps.type = Button;
        controlProps.image = navigationControl.Image != null && navigationControl.Image != '' ? `sprite-${navigationControl.Image}` : null;
        controlProps.buttonSize = this.buttonSizeFromService(navigationControl.ButtonSize);
        controlProps.buttonType = this.buttonTypeFromService(navigationControl.ButtonType);

        const getDisplayText = () => {
            return this.menuExtensions.getButtonDisplayText?.(design, navigationControl) ?? this.localizationService.getString(navigationControl.DisplayKey)
        };

        controlProps.text = getDisplayText();
        pendingCalculationChangeSubscriptions.push(
            design.pendingCalculationChange.subscribe(() => {
                setState(menu => {
                    const property = navigationControl.UIPropertyId != null ? design.properties.get(navigationControl.UIPropertyId) : null;
                    const disabled = design.pendingCalculation || property.disabled;
                    const text = getDisplayText();
                    return this.updateMainMenuControl<IButtonProps>(menu, controlProps.controlId, { disabled, text });
                });
            })
        );

        controlProps.clicked = () => {
            // execute command if present
            if (navigationControl.Command != null) {
                const command = commands[navigationControl.Command];

                if (command == null) {
                    this.loggerService.log(`Missing command with ID = ${navigationControl.Command}.`, LogType.error);
                }
                else {
                    command(navigationControl);
                }
            }
            else {
                // else change UI property
                this.updateUiProperty(design, navigationControl.UIPropertyId, true);
            }

            // track usage
            setState(menu => {
                const latestControlProps = menu.controls[controlProps.controlId];

                this.menuExtensions.trackUsage(navigationControl, latestControlProps, design);

                return menu;
            });
        };
        const onStateChanged = () => {
            return { text: getDisplayText() } as IButtonProps;
        };

        this.menuExtensions.overrideButtonProps(design, controlProps, navigationControl);

        return {
            controlProps,
            onStateChanged
        };
    }

    private buttonSizeFromService(buttonSize: MainMenuButtonSize): ButtonSize {
        switch (buttonSize) {
            case null:
            case undefined:
                return undefined;
            case MainMenuButtonSize.Full:
                return ButtonSize.Full;
            case MainMenuButtonSize.Normal:
                return ButtonSize.Normal;
            default:
                throw new Error('Unknown button size.');
        }
    }

    private buttonTypeFromService(buttonType: MainMenuButtonType): ButtonType {
        switch (buttonType) {
            case null:
            case undefined:
                return undefined;
            case MainMenuButtonType.Default:
                return ButtonType.Default;
            case MainMenuButtonType.Primary:
                return ButtonType.Primary;
            case MainMenuButtonType.Ghost:
                return ButtonType.Ghost;
            case MainMenuButtonType.Link:
                return ButtonType.Link;
            case MainMenuButtonType.LargerGray:
                return ButtonType.LargerGray;
            case MainMenuButtonType.AnchorAI:
                return ButtonType.AnchorAI;
            default:
                throw new Error('Unknown button type.');
        }
    }

    // ButtonGroup
    private createMainMenuButtonGroup(design: DesignCommon, controlProps: IButtonGroupProps, navigationControl: ButtonGroupControl, childNavigationControls: ButtonControl[], commands: Record<string, (navigationControl?: BaseControl) => void>) {
        controlProps.type = ButtonGroup;
        controlProps.display = this.buttonGroupDisplayFromService(navigationControl.ButtonGroupDisplay);

        if (childNavigationControls.length > 0) {
            controlProps.items = childNavigationControls.map((navigationControl) => {
                const retVal: IButtonGroupItem = {
                    id: navigationControl.Name.toString(),
                    text: navigationControl.DisplayKey != null && navigationControl.DisplayKey != '' ? this.getTranslation(navigationControl.DisplayKey) : null,
                    image: navigationControl.Image != null && navigationControl.Image != '' ? `sprite-${navigationControl.Image}` : null,
                    buttonSize: this.buttonSizeFromService(navigationControl.ButtonSize),
                    buttonType: this.buttonTypeFromService(navigationControl.ButtonType),
                    clicked: () => {
                        // execute command if present
                        if (navigationControl.Command != null) {
                            const command = commands[navigationControl.Command];

                            if (command == null) {
                                this.loggerService.log(`Missing command with ID = ${navigationControl.Command}.`, LogType.error);
                            }
                            else {
                                command(navigationControl);
                            }
                        }
                        else {
                            // else change UI property
                            this.updateUiProperty(design, navigationControl.UIPropertyId, true);
                        }
                    },
                    tooltip: navigationControl.TooltipDisplayKey != null && navigationControl.TooltipDisplayKey != '' ? this.getTranslation(navigationControl.TooltipDisplayKey) : null,
                    tooltipTitle: navigationControl.TooltipTitleDisplayKey != null && navigationControl.TooltipTitleDisplayKey != '' ? this.getTranslation(navigationControl.TooltipTitleDisplayKey) : null
                };

                this.menuExtensions.updateButtonGroupItemProps(design, navigationControl, retVal);

                return retVal;
            });
        }
        else {
            // TODO: implement codelist mapping if needed
            controlProps.items = [];
        }

        this.menuExtensions.overrideButtonGroupProps(design, controlProps, navigationControl);

        return {
            controlProps
        };
    }

    private buttonGroupDisplayFromService(buttonType: MainMenuButtonGroupDisplay): ButtonGroupDisplay {
        switch (buttonType) {
            case null:
            case undefined:
                return undefined;
            case MainMenuButtonGroupDisplay.Join:
                return ButtonGroupDisplay.Join;
            case MainMenuButtonGroupDisplay.Separated:
                return ButtonGroupDisplay.Separate;
            default:
                throw new Error('Unknown button type.');
        }
    }

    // ToggleButton
    private createMainMenuToggleButton(design: DesignCommon, controlProps: IToggleButtonProps, navigationControl: ToggleButtonControl, propertyValue: boolean, commands: Record<string, (navigationControl?: BaseControl) => void>, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {
        controlProps.type = ToggleButton;
        controlProps.active = propertyValue;
        controlProps.value = navigationControl.Value as number;
        controlProps.selectable = navigationControl.ToggleButtonSelectable ?? true;
        controlProps.image = navigationControl.Image != null && navigationControl.Image != '' ? `sprite-${navigationControl.Image}` : null;
        controlProps.text = navigationControl.DisplayKey != null && navigationControl.DisplayKey != '' ? this.getTranslation(navigationControl.DisplayKey) : null;
        controlProps.buttonType = this.buttonTypeFromService(navigationControl.ButtonType);

        controlProps.clicked = () => {
            // execute command if present
            if (navigationControl.Command != null) {
                const command = commands[navigationControl.Command];

                if (command == null) {
                    this.loggerService.log(`Missing command with ID = ${navigationControl.Command}.`, LogType.error);
                }
                else {
                    command(navigationControl);
                }
            }
        };

        controlProps.activeChanged = (active) => {
            // change menu
            setState(menu => this.updateMainMenuControl<IToggleButtonProps>(menu, controlProps.controlId, { active }));

            // change UI property
            this.updateUiProperty(design, navigationControl.UIPropertyId, active);
        };

        const onStateChanged = (state: IDesignState) => {
            return { active: state.model[navigationControl.UIPropertyId] } as IToggleButtonProps;
        };

        this.menuExtensions.overrideToggleButtonProps(design, controlProps, navigationControl);

        return {
            controlProps,
            onStateChanged
        };
    }

    // ToggleImageButton
    private createMainMenuToggleImageButton(design: DesignCommon, controlProps: IToggleImageButtonProps, navigationControl: ToggleImageButtonControl, propertyValue: boolean, commands: Record<string, (navigationControl?: BaseControl) => void>, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {
        controlProps.type = ToggleImageButton;
        controlProps.active = propertyValue;
        controlProps.value = propertyValue ? 1 : 0;
        controlProps.selectable = navigationControl.ToggleButtonSelectable ?? true;
        controlProps.image = navigationControl.Image != null && navigationControl.Image != '' ? `sprite-${navigationControl.Image}` : null;
        controlProps.alternateImage = navigationControl.Image != null && navigationControl.Image != '' ? `sprite-${navigationControl.AlternateImage}` : null;
        controlProps.text = navigationControl.DisplayKey != null && navigationControl.DisplayKey != '' ? this.getTranslation(navigationControl.DisplayKey) : null;
        controlProps.buttonType = this.buttonTypeFromService(navigationControl.ButtonType);
        controlProps.position = navigationControl.Position ?? null;

        controlProps.clicked = () => {
            // execute command if present
            if (navigationControl.Command != null) {
                const command = commands[navigationControl.Command];

                if (command == null) {
                    this.loggerService.log(`Missing command with ID = ${navigationControl.Command}.`, LogType.error);
                }
                else {
                    command(navigationControl);
                }
            }
        };

        controlProps.activeChanged = (active) => {
            // change menu
            setState(menu => this.updateMainMenuControl<IToggleImageButtonProps>(menu, controlProps.controlId, { active }));

            // change UI property
            this.updateUiProperty(design, navigationControl.UIPropertyId, active);

        };

        const onStateChanged = (state: IDesignState) => {
            return { active: state.model[navigationControl.UIPropertyId] } as IToggleImageButtonProps;
        };

        this.menuExtensions.overrideToggleImageButtonProps(design, controlProps, navigationControl);

        return {
            controlProps,
            onStateChanged
        };
    }

    // ToggleButtonGroup
    private createMainMenuToggleButtonGroup(design: DesignCommon, controlProps: IToggleButtonGroupProps, navigationControl: ToggleButtonGroupControl, childNavigationControls: ToggleButtonControl[], propertyValue: number | number[], commands: Record<string, (navigationControl?: BaseControl) => void>, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {
        controlProps.type = ToggleButtonGroup;
        controlProps.activeValue = propertyValue;
        controlProps.toggleType = this.mainMenuToggleButtonGroupTypeFromService(navigationControl.ButtonGroupType);
        controlProps.unselectable = navigationControl.ButtonGroupUnselectable;
        controlProps.kbTooltip = navigationControl.KBTooltipDisplayKey;
        controlProps.hideChildDisplayText = navigationControl.HideChildDisplayText;

        controlProps.kbLink = this.menuExtensions.getFormattedKBLinkByRegionSpecificTemplate?.(design, navigationControl.KBNumberAciRegion);

        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService, this.getTranslationFuncBound());
        const codeListMapperFn = (codeListItem: CodeList) => {
            const controlProperty = navigationControl.UIPropertyId != null ? design.properties.get(navigationControl.UIPropertyId) : null;
            const itemTexts = controlProperty != null ? controlProperty.itemsTexts[codeListItem.id] : null;

            this.menuExtensions.updateToggleButtonGroupItemCodeListProps(design, navigationControl, codeListItem);

            const retVal: IToggleButtonGroupItem = {
                value: codeListItem.id,
                selectable: true,
                image: codeListItem.image != null && codeListItem.image != '' ? this.ensureSpriteInImageName(codeListItem.image) : null,
                text: codeListItem.getTranslatedNameText(codeListDeps),
                disabled: false,
                hidden: false,
                buttonType: null,
                clicked: null,
                tooltip: itemTexts?.tooltip != null && itemTexts.tooltip.length > 0
                    ? this.formatDisplayString(itemTexts.tooltip, design, null, navigationControl.UIPropertyId)
                    : this.formatCodeListTranslatedTooltip(codeListItem, design, null, navigationControl.UIPropertyId),
                tooltipTitle: itemTexts?.tooltipTitle != null && itemTexts.tooltipTitle.length > 0
                    ? this.getTranslation(itemTexts.tooltipTitle)
                    : this.getCodeListTranslatedTooltipTitle(codeListItem),

                tooltipDisabled: codeListItem.tooltipDisabledDisplayKey != null && codeListItem.tooltipDisabledDisplayKey != '' ? this.formatDisplayString(codeListItem.tooltipDisabledDisplayKey, design, null, navigationControl.UIPropertyId) : null,
                tooltipDisabledTitle: codeListItem.tooltipDisabledTitleDisplayKey != null && codeListItem.tooltipDisabledTitleDisplayKey != '' ? this.getTranslation(codeListItem.tooltipDisabledTitleDisplayKey) : null,
                uiPropertyId: null
            };

            this.menuExtensions.updateToggleButtonGroupItemProps(design, navigationControl, retVal);

            return retVal;
        };

        const childMapperfn = (childNavigationControl: ToggleButtonControl) => {
            const controlProperty = navigationControl.UIPropertyId != null ? design.properties.get(navigationControl.UIPropertyId) : null;
            const childProperty = childNavigationControl.UIPropertyId != null ? design.properties.get(childNavigationControl.UIPropertyId) : null;
            const itemTexts = controlProperty != null ? controlProperty.itemsTexts[childNavigationControl.Name] : null;

            const retVal: IToggleButtonGroupItem = {
                value: childNavigationControl.Value,
                selectable: childNavigationControl.ToggleButtonSelectable ?? true,
                image: childNavigationControl.Image != null && childNavigationControl.Image != '' ? `sprite-${childNavigationControl.Image}` : null,
                text: childNavigationControl.DisplayKey != null && childNavigationControl.DisplayKey != '' ? this.getTranslation(childNavigationControl.DisplayKey) : null,
                disabled: childProperty != null ? childProperty.disabled : false,
                hidden: childProperty != null ? childProperty.hidden : false,
                buttonType: this.buttonTypeFromService(childNavigationControl.ButtonType),
                tooltipDisabled: childNavigationControl.TooltipDisabledDisplayKey != null && childNavigationControl.TooltipDisabledDisplayKey != '' ? this.getTranslation(childNavigationControl.TooltipDisabledDisplayKey) : null,
                tooltipDisabledTitle: childNavigationControl.TooltipDisabledTitleDisplayKey != null && childNavigationControl.TooltipDisabledTitleDisplayKey != '' ? this.getTranslation(childNavigationControl.TooltipDisabledTitleDisplayKey) : null,
                clicked: () => {
                    // execute command if present
                    if (childNavigationControl.Command != null) {
                        const command = commands[childNavigationControl.Command];

                        if (command == null) {
                            this.loggerService.log(`Missing command with ID = ${childNavigationControl.Command}.`, LogType.error);
                        }
                        else {
                            command(childNavigationControl);
                        }
                    }
                },
                tooltip: itemTexts?.tooltip != null && itemTexts.tooltip.length > 0
                    ? this.getTranslation(itemTexts.tooltip)
                    : childNavigationControl.TooltipDisplayKey != null && childNavigationControl.TooltipDisplayKey != ''
                        ? this.getTranslation(childNavigationControl.TooltipDisplayKey)
                        : null,
                tooltipTitle: itemTexts?.tooltipTitle != null && itemTexts.tooltipTitle.length > 0
                    ? this.getTranslation(itemTexts.tooltipTitle)
                    : childNavigationControl.TooltipTitleDisplayKey != null && childNavigationControl.TooltipTitleDisplayKey != ''
                        ? this.getTranslation(childNavigationControl.TooltipTitleDisplayKey)
                        : null,
                uiPropertyId: childNavigationControl.UIPropertyId
            } as IToggleButtonGroupItem;

            this.menuExtensions.updateToggleButtonGroupItemProps(design, navigationControl, retVal);

            return retVal;
        };

        controlProps.initialItems = [];

        let designCodeList: number = null;
        let codeListItems: CodeList[] = null;
        let secondaryCodeListItems: CodeList[] = null; // used for collapsed items

        if (navigationControl.CodelistName != null && navigationControl.CodelistName != '') {
            const d = this.menuExtensions.getCodeListIdWithItems(design, navigationControl, navigationControl.CodelistName);
            designCodeList = d.codeList;
            codeListItems = d.codeListItems;

            controlProps.initialItems = codeListItems?.map((codeListItem) => codeListMapperFn(codeListItem)) ?? [];
        }
        const childItems = childNavigationControls.map((navigationControl) => childMapperfn(navigationControl));
        controlProps.initialItems = [...controlProps.initialItems, ...childItems];

        if (navigationControl.SecondaryCodelistName != null && navigationControl.SecondaryCodelistName != '') {
            const d = this.menuExtensions.getCodeListIdWithItems(design, navigationControl, navigationControl.SecondaryCodelistName);
            secondaryCodeListItems = d.codeListItems;

            controlProps.initialItemsCollapsed = secondaryCodeListItems.map((codeListItem) => codeListMapperFn(codeListItem));
            const secondaryChildItems = childNavigationControls.map((navigationControl) => childMapperfn(navigationControl)); // also show all navigation control child item when collapsed

            if (controlProps.initialItemsCollapsed.length + secondaryChildItems.length - ToggleButtonGroup.itemsPerRow <= 0) {
                controlProps.initialItemsCollapsed.push(...secondaryChildItems);
            }
            else {
                controlProps.initialItemsCollapsed = [...controlProps.initialItemsCollapsed.slice(0, controlProps.initialItemsCollapsed.length - secondaryChildItems.length), ...secondaryChildItems];
            } // but remove as much code list items as child items are added
        }

        // filter group items
        const controlItems = this.getToggleButtonGroupFilteredItems(controlProps, design, designCodeList, navigationControl.UIPropertyId);
        controlProps.items = controlItems.items;
        if (controlItems.itemsCollapsed != null) {
            controlProps.itemsCollapsed = controlItems.itemsCollapsed;
        }

        // controlProps.items change tooltips the same way as they are onStatechanged
        // set initial tooltipsTitles and tooltips. They can later be changed in onStateChanged function

        const controlProperty = navigationControl.UIPropertyId != null ? design.properties.get(navigationControl.UIPropertyId) : null;

        const setItemsProperties = (items: IToggleButtonGroupItem[]) => {
            if (items == null) {
                return null;
            }

            const returnItems: IToggleButtonGroupItem[] = [];
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                const itemTexts = controlProperty != null ? controlProperty.itemsTexts[item.value] : null;
                const codeListItem = codeListItems?.find(cli => cli.id == item.value);

                const childItem = childNavigationControls.find(ci => ci.Value == item.value);

                const propertyTooltip = itemTexts != null ? itemTexts.tooltip : null;
                const propertyTooltipTitle = itemTexts != null ? itemTexts.tooltipTitle : null;

                const itemProperty = item.uiPropertyId != null ? design.properties.get(item.uiPropertyId) : null;

                returnItems[i] = {
                    ...item,
                    disabled: itemProperty != null ? itemProperty.disabled : false || item.disabled,
                    hidden: itemProperty != null ? itemProperty.hidden : false,
                    tooltip: this.findTooltip(propertyTooltip, codeListItem, childItem, design, navigationControl.UIPropertyId),
                    tooltipTitle: this.findTooltipTitle(propertyTooltipTitle, codeListItem, childItem),
                    tooltipDisabled: this.findTooltipDisabled(codeListItem, childItem, design, navigationControl.UIPropertyId)
                };
            }

            return returnItems;
        };

        controlProps.initialItems = setItemsProperties(controlProps.initialItems);
        controlProps.items = setItemsProperties(controlProps.items);
        controlProps.initialItemsCollapsed = setItemsProperties(controlProps.initialItemsCollapsed);
        controlProps.itemsCollapsed = setItemsProperties(controlProps.itemsCollapsed);

        controlProps.valueChanged = (value) => {
            // change menu
            navigationControl.Value = value;
            setState(menu => this.updateMainMenuControl<IToggleButtonGroupProps>(menu, controlProps.controlId, { activeValue: value }));
            // execute command if present
            if (navigationControl.Command != null) {
                const command = commands[navigationControl.Command];

                if (command == null) {
                    this.loggerService.log(`Missing command with ID = ${navigationControl.Command}.`, LogType.error);
                }
                else {
                    command(navigationControl);
                }
            }
            // change UI property
            else {
                // Update model and run calculation
                this.updateUiProperty(design, navigationControl.UIPropertyId, value);
            }
        };

        const onStateChanged = (state: IDesignState, menu: IMenu) => {
            const control: IToggleButtonGroupProps = menu.controls[controlProps.controlId];
            if (control == null || Object.keys(control).length == 0) {
                return null;
            }

            const controlItems = this.getToggleButtonGroupFilteredItems(controlProps, design, designCodeList, navigationControl.UIPropertyId, true);
            return {
                activeValue: state.model[navigationControl.UIPropertyId],
                initialItems: setItemsProperties(controlProps.initialItems),
                items: setItemsProperties(controlItems.items),
                initialItemsCollapsed: setItemsProperties(controlProps.initialItemsCollapsed),
                itemsCollapsed: setItemsProperties(controlItems.itemsCollapsed)
            } as IToggleButtonGroupProps;
        };

        const onAllowedValuesChanged = (design: Design, propertyIds: number[]) => {
            if (!propertyIds.some((id) => id == navigationControl.UIPropertyId)) {
                return {} as IToggleButtonGroupProps;
            }

            const controlItems = this.getToggleButtonGroupFilteredItems(controlProps, design, designCodeList, navigationControl.UIPropertyId, true);
            return controlItems;
        };

        this.menuExtensions.overrideToggleButtonGroupProps(design, controlProps, navigationControl);

        return {
            controlProps,
            onStateChanged,
            onAllowedValuesChanged
        };
    }

    private mainMenuToggleButtonGroupTypeFromService(toggleButtonGroupType: ButtonGroupType) {
        if (toggleButtonGroupType == null) {
            return null;
        }

        switch (toggleButtonGroupType) {
            case ButtonGroupType.Multiple:
                return ToggleButtonGroupType.multiple;
            case ButtonGroupType.Single:
                return ToggleButtonGroupType.single;
            default:
                throw new Error('Unknown toggle button group type.');
        }
    }

    private getToggleButtonGroupFilteredItems(controlProps: IToggleButtonGroupProps, design: DesignCommon, codeList: number, uiPropertyId: number, clone = false) {
        const allowedValues = this.menuExtensions.getToggleButtonGroupAllowedValues(codeList, design.properties.get(uiPropertyId).allowedValues);
        let disabledValues = this.getToggleButtonGroupDisabledValues(design, uiPropertyId);

        const displayValues = allowedValues != null
            ? disabledValues != null
                ? [...allowedValues, ...disabledValues]
                : allowedValues
            : disabledValues;
        disabledValues = disabledValues || [];

        // Items
        // displayValues == null:    no filtering, displays all initial items
        // displayValues == []:      applies filtering, displays no items
        // displayValues == [ ... ]  applies filtering, display some items
        let items = controlProps.initialItems.filter((item) =>
            displayValues != null
                ? displayValues.includes(item.value)
                : true);

        if (clone) {
            items = cloneDeep(items);
        }

        items.forEach((item) => {
            item.disabled = disabledValues.includes(item.value);
            item.selectable = !disabledValues.includes(item.value);
        });

        // Collapsed items
        let itemsCollapsed: IToggleButtonGroupItem[] = null;
        // filter by allowed values and also by items that are not collapsed
        if (controlProps.initialItemsCollapsed != null) {
            itemsCollapsed = controlProps.initialItemsCollapsed.filter((item) =>
                displayValues != null
                    ? displayValues.includes(item.value)
                    : true
            ).filter((item) => items.some((mainItem) => mainItem.value == item.value));

            if (clone) {
                itemsCollapsed = cloneDeep(itemsCollapsed);
            }

            itemsCollapsed.forEach((item) => {
                item.disabled = disabledValues.includes(item.value);
                item.selectable = !disabledValues.includes(item.value);
            });
        }

        return {
            items,
            itemsCollapsed
        } as IToggleButtonGroupProps;
    }

    private getToggleButtonGroupDisabledValues(design: DesignCommon, uiPropertyId: number) {
        return design.properties.get(uiPropertyId).disabledValues;
    }

    // Group
    private createMainMenuGroup(
        design: DesignCommon,
        controlProps: IGroupProps,
        navigationControl: GroupControl,
        childNavigationControls: UIPropertyBaseControl[],
        menu: IMenu,
        menuStructure: Menu<BaseControl, string>,
        commands: Record<string, (navigationControl?: BaseControl) => void>,
        setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu,
        pendingCalculationChangeSubscriptions: Subscription[],
        tabName = ''
    ) {
        controlProps.type = Group;
        controlProps.controlsOrder = [];
        controlProps.collapsableGroup = navigationControl.CollapsableGroup;
        controlProps.multilineGroup = navigationControl.MultilineGroup;
        controlProps.controls = {};

        for (const childNavigationControl of childNavigationControls) {
            const childNavigationSubgroupControls: UIPropertyBaseControl[] = menuStructure.Tabs.flatMap(x => x.TabRegions).flatMap(x => x.Controls).filter(x => x.ParentControlName == childNavigationControl.Name) ?? null;
            this.menuExtensions.setGroupChildProps?.(design, childNavigationControl);

            const control = this.createMainMenuControl(childNavigationControl, design, menu, menuStructure, setState, pendingCalculationChangeSubscriptions, commands, null, childNavigationSubgroupControls, tabName);

            if (control != null) {
                menu.controls[control.controlProps.controlId] = control.controlProps;
                controlProps.controls[control.controlProps.controlId] = control.controlProps;
                this.onStateChangedUpdaters[control.controlProps.controlId] = control.onStateChanged;
                this.onAllowedValuesChangedUpdaters[control.controlProps.controlId] = control.onAllowedValuesChanged;
                this.onStructuralCalculationSoftwareChangedUpdaters[control.controlProps.controlId] = control.onStructuralCalculationSoftwareChanged;
                this.onApplicationSettingChangedUpdaters[control.controlProps.controlId] = control.onApplicationSettingChanged;
                controlProps.controlsOrder.push(control.controlProps.controlId);
            }
        }

        controlProps.hidden = controlProps.controlsOrder.every((id) => controlProps.controls[id].hidden) || controlProps.hidden;

        const onStateChanged = () => {
            return ({ hidden: controlProps.controlsOrder.every((id, index) => this.updateNavigationControlProps(design, childNavigationControls[index], controlProps.controls[id]).hidden) } as IGroupProps);
        };

        this.menuExtensions.overrideGroupProps(design, controlProps, navigationControl);

        return {
            controlProps,
            onStateChanged
        };
    }

    // Label
    private createMainMenuLabel(design: DesignCommon, controlProps: ILabelProps, navigationControl: LabelControl, propertyValue: string | string[] | object, commands: Record<string, (navigationControl?: BaseControl) => void>, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {
        controlProps.type = Label;
        controlProps.text = this.getMainMenuLabelText(propertyValue);
        controlProps.role = this.labelRoleFromService(navigationControl.Role);

        const onStateChanged = (state: IDesignState) => {
            return { text: this.getMainMenuLabelText(state.model[navigationControl.UIPropertyId] as any) } as ILabelProps;
        };

        controlProps.infoClicked = () => {
            // execute command if present
            if (navigationControl.Command != null) {
                const command = commands[navigationControl.Command];

                if (command == null) {
                    this.loggerService.log(`Missing command with ID = ${navigationControl.Command}.`, LogType.error);
                }
                else {
                    command(navigationControl);
                }
            }
        };

        this.menuExtensions.overrideLabelProps(design, controlProps, navigationControl);

        return {
            controlProps,
            onStateChanged
        };
    }

    private getMainMenuLabelText(propertyValue: string | string[] | object) {
        if (propertyValue == null || typeof propertyValue != 'object' || Array.isArray(propertyValue)) {
            return propertyValue as string | string[];
        }

        return this.menuExtensions.getLocalizedStringWithTranslationFormat(propertyValue as object);
    }

    private labelRoleFromService(buttonSize: MainMenuLabelRole) {
        switch (buttonSize) {
            case null:
            case undefined:
                return undefined;
            case MainMenuLabelRole.Default:
                return LabelRole.Default;
            case MainMenuLabelRole.Regular:
                return LabelRole.Regular;
            case MainMenuLabelRole.SubHeading:
                return LabelRole.SubHeading;
            default:
                throw new Error('Unknown button size.');
        }
    }

    // Rotate
    private createMainMenuRotate(design: DesignCommon, controlProps: IRotateProps, navigationControl: RotateControl) {
        controlProps.type = Rotate;

        const rotateLeftUIProperty = navigationControl.RotateLeftUIPropertyId != null ? design.properties.get(navigationControl.RotateLeftUIPropertyId) : null;
        const rotateRightUIProperty = navigationControl.RotateRightUIPropertyId != null ? design.properties.get(navigationControl.RotateRightUIPropertyId) : null;

        controlProps.rotateLeftDisabled = rotateLeftUIProperty != null ? rotateLeftUIProperty.disabled : false;
        controlProps.rotateRightDisabled = rotateLeftUIProperty != null ? rotateRightUIProperty.disabled : false;
        controlProps.rotateLeftHidden = rotateLeftUIProperty != null ? rotateLeftUIProperty.hidden : false;
        controlProps.rotateRightHidden = rotateLeftUIProperty != null ? rotateRightUIProperty.hidden : false;

        controlProps.rotateLeftClicked = () => {
            // change UI property
            if (navigationControl.RotateLeftUIPropertyId != null) {
                design.addModelChange(navigationControl.RotateLeftUIPropertyId, true, true);
            }
        };

        controlProps.rotateRightClicked = () => {
            // change UI property
            if (navigationControl.RotateRightUIPropertyId != null) {
                design.addModelChange(navigationControl.RotateRightUIPropertyId, true, true);
            }
        };

        const onStateChanged = () => {
            const rotateLeftUIProperty = navigationControl.RotateLeftUIPropertyId != null ? design.properties.get(navigationControl.RotateLeftUIPropertyId) : null;
            const rotateRightUIProperty = navigationControl.RotateRightUIPropertyId != null ? design.properties.get(navigationControl.RotateRightUIPropertyId) : null;
            return {
                rotateLeftDisabled: rotateLeftUIProperty != null ? rotateLeftUIProperty.disabled : false,
                rotateRightDisabled: rotateRightUIProperty != null ? rotateRightUIProperty.disabled : false,
                rotateLeftHidden: rotateLeftUIProperty != null ? rotateLeftUIProperty.hidden : false,
                rotateRightHidden: rotateRightUIProperty != null ? rotateRightUIProperty.hidden : false,
            } as IRotateProps;
        };

        this.menuExtensions.overrideRotateProps(design, controlProps, navigationControl);

        return {
            controlProps,
            onStateChanged
        };
    }

    // PopupGrid
    private createMainMenuPopupGrid(design: DesignCommon, controlProps: IPopupGridProps, navigationControl: UIPropertyBaseControl, propertyValue: number, commands: Record<string, (navigationControl?: BaseControl) => void>, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {
        const designCodeList = this.menuExtensions.getDesignCodeList(navigationControl.CodelistName);
        const projectCodeList = this.menuExtensions.getProjectCodeList(navigationControl.CodelistName);
        if (designCodeList == null && projectCodeList == null) {
            this.logMissingCodeList(navigationControl, design);
        }

        controlProps.type = PopupGrid;
        controlProps.selectedValue = propertyValue;
        controlProps.wrapName = navigationControl.WrapName;

        const codeList = this.menuExtensions.getCodeListIdWithItems(design, navigationControl, navigationControl.CodelistName);
        const itemMapping = ModalGridHelper.getPopupGridItemMapping(this.localizationService, this.unitService, designCodeList);
        const gridItems = ModalGridHelper.createPopupGridItems(codeList.codeListItems, itemMapping);
        controlProps.initialItems = this.menuExtensions.customizePopupGridItems(gridItems, design, designCodeList);
        controlProps.hideDescriptionOnButton = this.menuExtensions.getPopupGridHideShowDescriptionOnButton(designCodeList);

        controlProps.clicked = (selectedValue) => {
            const allItems = controlProps.initialItems;

            // filter group items
            const allowedValues = design.properties.get(navigationControl.UIPropertyId).allowedValues;
            const filteredItems = allItems.filter((item) => allowedValues != null ? allowedValues.includes(item.value) : true);

            const modalProps: IModalGridComponentInput<IModalGridItem<number>> = {
                popupTitle: navigationControl.DisplayKey != null && navigationControl.DisplayKey != ''
                    ? this.getTranslation(navigationControl.DisplayKey)
                    : null,
                items: filteredItems,
                selectedItem: allItems.find((item) => item.value == selectedValue),
                onSelect: async (item) => {
                    this.updateUiProperty(design, navigationControl.UIPropertyId, item.value);

                    // change menu
                    setState(menu => this.updateMainMenuControl<IPopupGridProps>(menu, controlProps.controlId, { selectedValue: item.value }));
                }
            };

            // set some custom modal properties
            const modalOpts: ModalOptions = {};
            this.menuExtensions.customizePopupGridModal(modalProps, modalOpts, designCodeList, design);

            this.modalService.openModalGrid(modalProps, modalOpts);
        };

        controlProps.infoClicked = () => {
            // execute command if present
            if (navigationControl.Command != null) {
                const command = commands[navigationControl.Command];

                if (command == null) {
                    this.loggerService.log(`Missing command with ID = ${navigationControl.Command}.`, LogType.error);
                    return;
                }

                command(navigationControl);
            }
        };

        const onStateChanged = (state: IDesignState) => {
            return { selectedValue: state.model[navigationControl.UIPropertyId] } as IPopupGridProps;
        };

        this.menuExtensions.overridePopupGridProps(design, controlProps, navigationControl);

        return {
            controlProps,
            onStateChanged
        };
    }

    // PopupGridPartial
    private createMainMenuPopupGridPartial(design: DesignCommon, controlProps: IPopupGridPartialProps, navigationControl: UIPropertyBaseControl, propertyValue: number, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {
        const designCodeList = this.menuExtensions.getDesignCodeList(navigationControl.CodelistName);
        const projectCodeList = this.menuExtensions.getProjectCodeList(navigationControl.CodelistName);

        if (designCodeList == null && projectCodeList == null) {
            this.logMissingCodeList(navigationControl, design);
        }

        controlProps.type = PopupGridPartial;
        controlProps.selectedValue = propertyValue;

        const codeLists = (design.designData as any).designCodeLists as ICodeLists;
        const itemMapping = ModalGridHelper.getPopupGridPartialItemMapping(this.localizationService, designCodeList);
        const gridItems = ModalGridHelper.createPopupGridPartialItems(designCodeList, codeLists, itemMapping);
        controlProps.initialItems = this.menuExtensions.customizePopupGridPartialItems(gridItems, design, designCodeList);

        // filter group items
        const allowedValues = design.properties.get(navigationControl.UIPropertyId).allowedValues;
        controlProps.items = controlProps.initialItems?.filter((item) => allowedValues != null ? allowedValues.includes(item.value) : true);

        // set some custom modal properties
        this.menuExtensions.customizePopupGridPartialControl(controlProps, designCodeList);

        controlProps.clicked = (selectedValue) => {
            const allItems = controlProps.initialItems;

            // filter group items
            const allowedValues = design.properties.get(navigationControl.UIPropertyId).allowedValues;
            const filteredItems = allItems.filter((item) => allowedValues != null ? allowedValues.includes(item.value) : true);

            const modalProps: IModalGridComponentInput<IModalGridItem<number>> = {
                popupTitle: navigationControl.DisplayKey != null && navigationControl.DisplayKey != ''
                    ? this.getTranslation(navigationControl.DisplayKey)
                    : null,
                items: filteredItems,
                selectedItem: allItems.find((item) => item.value == selectedValue),
                onSelect: async (item) => {
                    this.updateUiProperty(design, navigationControl.UIPropertyId, item.value);

                    // change menu
                    setState(menu => this.updateMainMenuControl<IPopupGridPartialProps>(menu, controlProps.controlId, { selectedValue: item.value }));
                }
            };

            // set some custom modal properties
            const modalOpts: ModalOptions = {};
            this.menuExtensions.customizePopupGridPartialModal(modalProps, modalOpts, designCodeList);

            this.modalService.openModalGrid(modalProps, modalOpts);
        };

        controlProps.valueChanged = (value) => {
            this.updateUiProperty(design, navigationControl.UIPropertyId, value);

            // change menu
            setState(menu => this.updateMainMenuControl<IPopupGridPartialProps>(menu, controlProps.controlId, { selectedValue: value }));
        };

        const onStateChanged = (state: IDesignState) => {
            return { selectedValue: state.model[navigationControl.UIPropertyId] } as IPopupGridPartialProps;
        };

        const onAllowedValuesChanged = (design: Design, propertyIds: number[]) => {
            if (!propertyIds.some((id) => id == navigationControl.UIPropertyId)) {
                return {} as IPopupGridPartialProps;
            }

            const allowedValues = design.properties.get(navigationControl.UIPropertyId).allowedValues;
            return { items: controlProps.initialItems.filter((item) => allowedValues != null ? allowedValues.includes(item.value) : true) } as IPopupGridPartialProps;
        };

        this.menuExtensions.overridePopupGridPartialProps(design, controlProps, navigationControl);

        return {
            controlProps,
            onStateChanged,
            onAllowedValuesChanged
        };
    }

    // Integrations
    private createMainMenuDlubalImportExport(design: DesignCommon, controlProps: IDlubalImportExportProps, navigationControl: UIPropertyBaseControl, propertyValue: string, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {
        this.setImportExportProps(controlProps, DlubalImportExport);
        controlProps.importClick = () => this.modalService.openDlubalImport();
        controlProps.exportClick = () => this.modalService.openDlubalExport();

        this.menuExtensions.overrideDlubalImportExportProps(design, controlProps, navigationControl);

        return {
            controlProps
        };
    }

    private createMainMenuSAP2000ImportExport(design: DesignCommon, controlProps: ISAP2000ImportExportProps, navigationControl: UIPropertyBaseControl, propertyValue: string, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {
        this.setImportExportProps(controlProps, SAP2000ImportExport);
        controlProps.importClick = () => this.modalService.openSapImport();

        this.menuExtensions.overrideSAP2000ImportExportProps(design, controlProps, navigationControl);

        return {
            controlProps
        };
    }

    private createMainMenuRobotImportExport(design: DesignCommon, controlProps: IRobotImportExportProps, navigationControl: UIPropertyBaseControl, propertyValue: string, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {
        this.setImportExportProps(controlProps, RobotImportExport);
        controlProps.importClick = () => this.modalService.openRobotImport();

        this.menuExtensions.overrideRobotImportExportProps(design, controlProps, navigationControl);

        return {
            controlProps
        };
    }

    private createMainMenuETABSImportExport(design: DesignCommon, controlProps: IETABSImportExportProps, navigationControl: UIPropertyBaseControl, propertyValue: string, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {
        this.setImportExportProps(controlProps, ETABSImportExport);
        controlProps.importClick = () => this.modalService.openEtabsImport();

        this.menuExtensions.overrideETABSImportExportProps(design, controlProps, navigationControl);

        return {
            controlProps
        };
    }

    private createMainMenuStaadProImportExport(design: DesignCommon, controlProps: IStaadProImportExportProps, navigationControl: UIPropertyBaseControl, propertyValue: string, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {
        this.setImportExportProps(controlProps, StaadProImportExport);
        controlProps.importClick = () => this.modalService.openStaadProImport();

        this.menuExtensions.overrideStaadProImportExportProps(design, controlProps, navigationControl);

        return {
            controlProps
        };
    }

    private setImportExportProps(controlProps: IImportExportProps, controlType: React.ComponentClass<any>): void {
        let importExportType: string;
        controlProps.integrationsData = this.integrationsData;

        switch (controlType) {
            case DlubalImportExport:
                importExportType = 'Dlubal';
                controlProps.exportButtonText = this.getTranslation(`Agito.Hilti.Profis3.Navigation.TabLoads.Region${importExportType}.Export`);
                break;
            case SAP2000ImportExport:
                importExportType = 'SAP2000';
                break;
            case RobotImportExport:
                importExportType = 'Robot';
                break;
            case ETABSImportExport:
                importExportType = 'ETABS';
                break;
            case StaadProImportExport:
                importExportType = 'StaadPro';
                break;
            default:
                return;
        }

        controlProps.type = controlType;
        controlProps.importButtonText = this.getTranslation(`Agito.Hilti.Profis3.Navigation.TabLoads.Region${importExportType}.Import`);
        controlProps.tryAgainButtonText = this.getTranslation(`Agito.Hilti.Profis3.Navigation.TabLoads.Region${importExportType}.TryAgain`);
        controlProps.errorText = this.getTranslation(`Agito.Hilti.Profis3.Navigation.TabLoads.Region${importExportType}.Error`);

        const notConnectedTextHereValue = escape(this.getTranslation(`Agito.Hilti.Profis3.${importExportType}Import.ServiceError.Message.DownloadUrlText`));
        const notConnectedText = formatKeyValue(escape(this.getTranslation(`Agito.Hilti.Profis3.${importExportType}Import.ServiceError.Message`)), { downloadUrl: `<a href="${escape(environment.thirdPartyInterfaceDownloadUrl)}" class="download-link" target="_blank">${notConnectedTextHereValue}</a>` });
        controlProps.notConnectedText = notConnectedText;

        const oldVersionDetectedTextHereValue = escape(this.getTranslation(`Agito.Hilti.Profis3.Navigation.TabLoads.Region${importExportType}.OldVersionDetected.DownloadUrlText`));
        const oldVersionDetectedText = formatKeyValue(escape(this.getTranslation(`Agito.Hilti.Profis3.Navigation.TabLoads.Region${importExportType}.OldVersionDetected`)), { downloadUrl: `<a href="${escape(environment.thirdPartyInterfaceDownloadUrl)}" class="download-link" target="_blank">${oldVersionDetectedTextHereValue}</a>` });
        controlProps.oldVersionDetectedText = oldVersionDetectedText;
    }

    private isStructuralCalculationSoftwareControlHidden(type: StructuralCalculationSoftwareType): boolean {
        // Hide control if adding/importing new loads is disabled (example: HK + FOS3)
        if (this.userService.design.newAndImportLoadDisabled) {
            return true;
        }

        // Get the regions in which this software should be enabled by default
        const regions = (this.codeListCommon.commonCodeLists[CommonCodeList.StructuralCalculationSoftware] as StructuralCalculationSoftware[]).find(scs => scs.id === type).regions;
        const isDefaultRegion = regions.indexOf(this.userSettingsService.settings.application.general.regionId.value) > -1;

        switch (type) {
            case StructuralCalculationSoftwareType.SAP2000: {
                if (this.userSettingsService.settings.application.general.sap2000Enabled.value == null && isDefaultRegion) {
                    return false;
                }

                return !this.userSettingsService.settings.application.general.sap2000Enabled.value || false;
            }
            case StructuralCalculationSoftwareType.Robot: {
                if (this.userSettingsService.settings.application.general.robotEnabled.value == null && isDefaultRegion) {
                    return false;
                }

                return !this.userSettingsService.settings.application.general.robotEnabled.value || false;
            }
            case StructuralCalculationSoftwareType.ETABS: {
                if (this.userSettingsService.settings.application.general.etabsEnabled.value == null && isDefaultRegion) {
                    return false;
                }

                return !this.userSettingsService.settings.application.general.etabsEnabled.value || false;
            }
            case StructuralCalculationSoftwareType.StaadPro: {
                if (this.userSettingsService.settings.application.general.staadProEnabled.value == null && isDefaultRegion) {
                    return false;
                }

                return !this.userSettingsService.settings.application.general.staadProEnabled.value || false;
            }
            case StructuralCalculationSoftwareType.Risa: {
                return !this.userSettingsService.settings.application.general.risaEnabled.value || false;
            }
            case StructuralCalculationSoftwareType.RAM: {
                return !this.userSettingsService.settings.application.general.ramEnabled.value || false;
            }
            default:
                return true;
        }
    }

    // ImageNameRadioGroup
    private createImageNameRadioGroup(design: DesignCommon, controlProps: IImageNameRadioGroupProps, navigationControl: UIPropertyBaseControl, propertyValue: number, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {
        controlProps.type = ImageNameRadioGroup;
        controlProps.checkedValue = propertyValue;
        controlProps.initialItems = [];

        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService, this.getTranslationFuncBound());
        const codeListMapperFn = (codeListItem: CodeList) => {
            const retVal: IImageNameRadioGroupItem = {
                value: codeListItem.id,
                text: codeListItem.getTranslatedNameText(codeListDeps),
                image: codeListItem.image != null && codeListItem.image != '' ? codeListItem.image : null,
                sortOrder: null,
                tooltip: this.formatCodeListTranslatedTooltip(codeListItem, design),
                tooltipTitle: this.formatCodeListTranslatedTooltipTitle(codeListItem, design),
                uiPropertyId: navigationControl.UIPropertyId
            };

            this.menuExtensions.updateImageNameRadioGroupItemProps(design, navigationControl, retVal);

            return retVal;
        };

        let designCodeList: number = null;
        let codeListItems: CodeList[] = null;

        if (navigationControl.CodelistName != null && navigationControl.CodelistName != '') {
            const d = this.menuExtensions.getCodeListIdWithItems(design, navigationControl);
            designCodeList = d.codeList;
            codeListItems = d.codeListItems;

            // initialize initial items
            controlProps.initialItems = codeListItems?.map((codeListItem) => codeListMapperFn(codeListItem));
        }

        // sort and filter initial items by allowed values and user sort order
        const controlItems = this.getImageNameRadioGroupSortedAndFilteredItems(controlProps, designCodeList, navigationControl.UIPropertyId, design);
        controlProps.items = controlItems.items;
        controlProps.parentId = controlItems.parentId;

        controlProps.valueChanged = (value) => {
            // change menu
            setState(menu => this.updateMainMenuControl<IImageNameRadioGroupProps>(menu, controlProps.controlId, { checkedValue: value }));
            this.updateUiProperty(design, navigationControl.UIPropertyId, value);
        };

        controlProps.sortOrderChanged = async (newSortOrder: number[], parentId?: number) => {
            await this.menuExtensions.updateImageNameRadioGroupSortOrder(design, designCodeList, newSortOrder, parentId);

            // get new sorted list of items
            const controlItems = this.getImageNameRadioGroupSortedAndFilteredItems(controlProps, designCodeList, navigationControl.UIPropertyId, design);

            // update control inside menu
            setState(menu => this.updateMainMenuControl<IImageNameRadioGroupProps>(menu, controlProps.controlId, { items: controlItems.items }));
        };

        const onStateChanged = (state: IDesignState, menu: IMenu) => {
            const control: IImageNameRadioGroupProps = menu.controls[controlProps.controlId];

            if (control == null || Object.keys(control).length == 0) {
                return null;
            }

            const controlItems = this.getImageNameRadioGroupSortedAndFilteredItems(controlProps, designCodeList, navigationControl.UIPropertyId, design);

            return {
                checkedValue: state.model[navigationControl.UIPropertyId],
                initialItems: controlProps.initialItems,
                items: controlItems.items,
                parentId: controlItems.parentId
            } as IImageNameRadioGroupProps;
        };

        const onAllowedValuesChanged = (design: Design, propertyIds: number[]) => {
            if (!propertyIds.some((id) => id == navigationControl.UIPropertyId)) {
                return {} as IImageNameRadioGroupProps;
            }

            const controlItems = this.getImageNameRadioGroupSortedAndFilteredItems(controlProps, designCodeList, navigationControl.UIPropertyId, design);

            return {
                checkedValue: controlProps.checkedValue,
                initialItems: controlProps.initialItems,
                items: controlItems.items,
                parentId: controlItems.parentId
            } as IImageNameRadioGroupProps;
        };

        this.menuExtensions.overrideImageNameRadioGroupProps(design, controlProps, navigationControl);

        return {
            controlProps,
            onStateChanged,
            onAllowedValuesChanged
        };
    }

    // ImageNameSelectionGroup
    private createImageNameSelectionGroup(design: DesignCommon, controlProps: IImageNameSelectionGroupProps, navigationControl: ImageNameSelectionGroupControl, propertyValue: number, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {
        controlProps.type = ImageNameSelectionGroup;
        controlProps.checkedValue = propertyValue;
        controlProps.initialItems = [];

        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService, this.getTranslationFuncBound());
        const codeListMapperFn = (codeListItem: CodeList) => {
            const retVal = {
                value: codeListItem.id,
                text: codeListItem.getTranslatedNameText(codeListDeps),
                image: codeListItem.image != null && codeListItem.image != '' ? `sprite-${codeListItem.image}` : null,
                sortOrder: null,
                tooltip: this.formatCodeListTranslatedTooltip(codeListItem, design),
                tooltipTitle: this.formatCodeListTranslatedTooltipTitle(codeListItem, design),
                uiPropertyId: navigationControl.UIPropertyId
            } as IImageNameSelectionGroupItem;

            this.menuExtensions.updateImageNameSelectionGroupItemProps(design, navigationControl, retVal);

            return retVal;
        };

        let designCodeList: number = null;
        let codeListItems: CodeList[] = null;

        if (navigationControl.CodelistName != null && navigationControl.CodelistName != '') {
            const d = this.menuExtensions.getCodeListIdWithItems(design, navigationControl);
            designCodeList = d.codeList;
            codeListItems = d.codeListItems;

            // initialize initial items
            controlProps.initialItems = codeListItems?.map((codeListItem) => codeListMapperFn(codeListItem));
        }

        // sort and filter initial items by allowed values and user sort order
        const controlItems = this.getImageNameRadioGroupSortedAndFilteredItems(controlProps, designCodeList, navigationControl.UIPropertyId, design);
        controlProps.items = controlItems.items;
        controlProps.parentId = controlItems.parentId;

        controlProps.valueChanged = (value) => {
            // change menu
            setState(menu => this.updateMainMenuControl<IImageNameRadioGroupProps>(menu, controlProps.controlId, { checkedValue: value }));

            this.updateUiProperty(design, navigationControl.UIPropertyId, value);
        };

        const onStateChanged = (state: IDesignState, menu: IMenu) => {
            const control: IImageNameRadioGroupProps = menu.controls[controlProps.controlId];

            if (control == null || Object.keys(control).length == 0) {
                return null;
            }

            const controlItems = this.getImageNameRadioGroupSortedAndFilteredItems(controlProps, designCodeList, navigationControl.UIPropertyId, design);

            return {
                checkedValue: state.model[navigationControl.UIPropertyId],
                initialItems: controlProps.initialItems,
                items: controlItems.items,
                parentId: controlItems.parentId
            } as IImageNameRadioGroupProps;
        };

        const onAllowedValuesChanged = (design: Design, propertyIds: number[]) => {
            if (!propertyIds.some((id) => id == navigationControl.UIPropertyId)) {
                return {} as IImageNameRadioGroupProps;
            }

            const controlItems = this.getImageNameRadioGroupSortedAndFilteredItems(controlProps, designCodeList, navigationControl.UIPropertyId, design);

            return {
                checkedValue: controlProps.checkedValue,
                initialItems: controlProps.initialItems,
                items: controlItems.items,
                parentId: controlItems.parentId
            } as IImageNameRadioGroupProps;
        };

        this.menuExtensions.overrideImageNameSelectionGroupProps(design, controlProps, navigationControl);

        return {
            controlProps,
            onStateChanged,
            onAllowedValuesChanged
        };
    }

    // ImageNameCheckbox
    private createImageNameCheckbox(design: DesignCommon, controlProps: IImageNameCheckboxProps, navigationControl: CheckBoxControl, propertyValue: boolean, commands: Record<string, (navigationControl?: BaseControl) => void>, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {
        controlProps.type = ImageNameCheckbox;
        controlProps.checked = propertyValue;
        controlProps.text = this.getTranslation(navigationControl?.DisplayKey);

        controlProps.image = navigationControl.Image ? `sprite-${navigationControl.Image}` : null;
        controlProps.imageStyle = navigationControl.IconImage;
        controlProps.tooltip = controlProps.tooltip ?? this.formatDisplayString(navigationControl.TooltipDisplayKey, design, null, navigationControl.UIPropertyId);

        const controlUIProperty = navigationControl.UIPropertyId != null ? design.properties.get(navigationControl.UIPropertyId) : null;
        if (controlUIProperty?.displayKey != null) {
            controlProps.text = this.getTranslation(controlUIProperty.displayKey);
        }

        controlProps.checkedChanged = (checked) => {
            if (navigationControl.Command != null) {
                const command = commands[navigationControl.Command];

                if (command == null) {
                    this.loggerService.log(`Missing command with ID = ${navigationControl.Command}.`, LogType.error);
                    return;
                }

                command(navigationControl);
            }
            else {
                // change menu
                setState(menu => this.updateMainMenuControl<ICheckboxProps>(menu, controlProps.controlId, { checked }));

                // change UI property
                this.updateUiProperty(design, navigationControl.UIPropertyId, checked);

                // track usage
                if (checked) {
                    setState(menu => {
                        const latestControlProps = menu.controls[controlProps.controlId];
                        this.menuExtensions.trackUsage(navigationControl, latestControlProps, design);
                        return menu;
                    });
                }
            }
        };

        const onStateChanged = (state: IDesignState) => {
            const property = state.properties.get(navigationControl.UIPropertyId);

            const retVal = {
                checked: state.model[navigationControl.UIPropertyId],
                tooltip: this.formatDisplayString(property?.tooltip ?? navigationControl.TooltipDisplayKey, design, null, navigationControl.UIPropertyId)
            } as ICheckboxProps;

            return retVal;
        };

        this.menuExtensions.overrideCheckboxProps(design, controlProps, navigationControl);

        return {
            controlProps,
            onStateChanged
        };
    }

    // ImageNameCheckboxGroup
    private createImageNameCheckboxGroup(design: DesignCommon, controlProps: IImageNameCheckboxGroupProps, navigationControl: UIPropertyBaseControl, propertyValue: number[], commands: Record<string, (navigationControl?: BaseControl) => void>, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {
        controlProps.type = ImageNameCheckboxGroup;
        controlProps.checkedValue = propertyValue;
        controlProps.initialItems = [];

        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService, this.getTranslationFuncBound());
        const codeListMapperFn = (codeListItem: CodeList) => {
            const retVal = {
                value: codeListItem.id,
                text: codeListItem.getTranslatedNameText(codeListDeps),
                image: codeListItem.image != null && codeListItem.image != '' ? codeListItem.image : null,
                sortOrder: null,
                tooltip: this.formatDisplayString(codeListItem.tooltipDisplayKey, design),
                tooltipTitle: this.formatDisplayString(codeListItem.tooltipTitleDisplayKey, design),
                uiPropertyId: navigationControl.UIPropertyId
            } as IImageNameCheckboxGroupItem;

            this.menuExtensions.updateImageNameCheckboxGroupItemProps?.(design, navigationControl, retVal);

            return retVal;
        };

        controlProps.infoClicked = () => {
            // execute command if present
            if (navigationControl.Command != null) {
                const command = commands[navigationControl.Command];

                if (command == null) {
                    this.loggerService.log(`Missing command with ID = ${navigationControl.Command}.`, LogType.error);
                    return;
                }

                command(navigationControl);
            }
        };

        let designCodeList: number = null;
        let codeListItems: CodeList[] = null;

        if (navigationControl.CodelistName != null && navigationControl.CodelistName != '') {
            const d = this.menuExtensions.getCodeListIdWithItems(design, navigationControl);
            designCodeList = d.codeList;
            codeListItems = d.codeListItems;

            // initialize initial items
            controlProps.initialItems = codeListItems?.map((codeListItem) => codeListMapperFn(codeListItem));
        }

        // sort and filter initial items by allowed values and user sort order
        const controlItems = this.getImageNameCheckboxGroupSortedAndFilteredItems(controlProps, designCodeList, navigationControl.UIPropertyId, design);
        controlProps.items = controlItems.items;
        controlProps.parentId = controlItems.parentId;

        controlProps.valueChanged = (value) => {
            // change menu
            setState(menu => this.updateMainMenuControl<IImageNameCheckboxGroupProps>(menu, controlProps.controlId, { checkedValue: value }));
            this.updateUiProperty(design, navigationControl.UIPropertyId, value);
        };

        controlProps.sortOrderChanged = async (newSortOrder: number[], parentId?: number) => {
            // get new sorted list of items
            const controlItems = this.getImageNameCheckboxGroupSortedAndFilteredItems(controlProps, designCodeList, navigationControl.UIPropertyId, design);

            // update control inside menu
            setState(menu => this.updateMainMenuControl<IImageNameCheckboxGroupProps>(menu, controlProps.controlId, { items: controlItems.items }));
        };

        const onStateChanged = (state: IDesignState, menu: IMenu) => {
            const control: IImageNameCheckboxGroupProps = menu.controls[controlProps.controlId];

            if (control == null || Object.keys(control).length == 0) {
                return null;
            }

            const controlItems = this.getImageNameCheckboxGroupSortedAndFilteredItems(controlProps, designCodeList, navigationControl.UIPropertyId, design);

            return {
                checkedValue: state.model[navigationControl.UIPropertyId],
                initialItems: controlProps.initialItems,
                items: controlItems.items,
                parentId: controlItems.parentId
            } as IImageNameCheckboxGroupProps;
        };

        const onAllowedValuesChanged = (design: Design, propertyIds: number[]) => {
            if (!propertyIds.some((id) => id == navigationControl.UIPropertyId)) {
                return {} as IImageNameCheckboxGroupProps;
            }

            const controlItems = this.getImageNameCheckboxGroupSortedAndFilteredItems(controlProps, designCodeList, navigationControl.UIPropertyId, design);

            return {
                checkedValue: controlProps.checkedValue,
                initialItems: controlProps.initialItems,
                items: controlItems.items,
                parentId: controlItems.parentId
            } as IImageNameCheckboxGroupProps;
        };

        this.menuExtensions.overrideImageNameCheckboxGroupProps?.(design, controlProps, navigationControl);

        return {
            controlProps,
            onStateChanged,
            onAllowedValuesChanged
        };
    }

    private getImageNameCheckboxGroupSortedAndFilteredItems(controlProps: IImageNameCheckboxGroupProps, codeList: number, uiPropertyId: number, design: DesignCommon) {
        const { displayValues, disabledValuesArray } = this.getAllowedAndDisabledItems(uiPropertyId, design);

        // Items
        // displayValues == null:    no filtering, displays all initial items
        // displayValues == []:      applies filtering, displays no items
        // displayValues == [ ... ]  applies filtering, display some items
        const items = controlProps.initialItems?.filter((item) =>
            displayValues != null
                ? displayValues.includes(item.value)
                : true);

        items?.forEach((item) => { item.disabled = disabledValuesArray.includes(item.value); });

        let parentId: number | undefined;

        return ({
            items,
            parentId
        });
    }

    private getAllowedAndDisabledItems(uiPropertyId: number, design: DesignCommon) {
        // filter group items
        const allowedValues = design.properties.get(uiPropertyId).allowedValues;
        let disabledValues = design.properties.get(uiPropertyId).disabledValues;

        // filter out values in disabledValues that are not in allowed values
        if (allowedValues != null && disabledValues != null) {
            disabledValues = disabledValues.filter((value) => allowedValues.includes(value));
        }

        return this.getDisplayValues(allowedValues, disabledValues);
    }

    private getDisplayValues(allowedValues: number[] | undefined, disabledValues: number[] | undefined) {
        let displayValues: number[] | undefined;
        if (allowedValues && disabledValues) {
            displayValues = [...allowedValues, ...disabledValues];
        } else if (allowedValues && !disabledValues) {
            displayValues = allowedValues;
        } else if (!allowedValues && disabledValues) {
            displayValues = disabledValues;
        }
        const disabledValuesArray = disabledValues || [];
        return { displayValues, disabledValuesArray };
    }

    // SwitchWithDescription
    private createSwitchWithDescription(design: DesignCommon, controlProps: ISwitchWithDescriptionProps, navigationControl: UIPropertyBaseControl, propertyValue: boolean, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu, menu: IMenu) {
        controlProps.type = SwitchWithDescription;
        controlProps.checked = propertyValue;
        controlProps.header = this.getTranslation(navigationControl.SwitchLabel);
        controlProps.enabledLabel = this.getTranslation(navigationControl.SwitchEnabledText);
        controlProps.disabledLabel = this.getTranslation(navigationControl.SwitchDisabledText);
        controlProps.showBeta = navigationControl.SwitchShowBeta && this.featureVisibilityService.isFeatureEnabled('PE_SmartAnchorEnableTco');

        controlProps.checkChanged = (value) => {
            setState(menu => this.updateMainMenuControl<ISwitchWithDescriptionProps>(menu, controlProps.controlId, { checked: value }));

            // change UI property
            this.updateUiProperty(design, navigationControl.UIPropertyId, value);

            this.menuExtensions.trackUsage(navigationControl, controlProps, design);
        };

        const onStateChanged = (state: IDesignState, menu: IMenu): Partial<ISwitchWithDescriptionProps> => {
            const control: ISwitchWithDescriptionProps = menu.controls[controlProps.controlId];

            if (control == null) {
                return null;
            }

            const favoritesTab = menu.tabs['tab-favorites'];

            if (favoritesTab != null) {
                favoritesTab.hidden = this.menuExtensions.isFavoritesTabHidden(design);
            }

            Object.values(menu.tabs).forEach(tab => tab.disabled = this.menuExtensions.isTabDisabled(design, tab.controlId));

            menu = this.menuExtensions.setFooterControlVisibility(design, menu);

            return {
                checked: state.model[navigationControl.UIPropertyId] as boolean
            };
        };

        this.menuExtensions.overrideSwitchWithDescriptionProps(design, controlProps, navigationControl);
        this.menuExtensions.setFooterControlVisibility(design, menu);


        return {
            controlProps,
            onStateChanged: onStateChanged as (state: IDesignState, menu: IMenu) => ISwitchWithDescriptionProps
        };
    }

    // RangeSlider
    private createRangeSlider(design: DesignCommon, controlProps: IRangeSliderProps, navigationControl: UIPropertyBaseControl, propertyValue: number, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {
        controlProps.type = RangeSlider;

        const longPoll = async () => {
            if (!this.isValueSend) {
                do {
                    this.isNew = false;
                    await new Promise(f => setTimeout(f, 1000));
                } while (this.isNew);

                this.isValueSend = true;

                this.updateUiProperty(design, navigationControl.UIPropertyId, this.value);
            }
        };

        controlProps.checkChanged = (value) => {
            setState(menu => {
                return this.updateMainMenuControl<IRangeSliderProps>(menu, controlProps.controlId, { value });
            });

            this.value = value;
            this.isNew = true;
            this.isValueSend = false;
            this.rangeSliderLock.use(longPoll);
        };

        const onStateChanged = (state: IDesignState) => {
            return { value: state.model[navigationControl.UIPropertyId] } as IRangeSliderProps;
        };

        this.menuExtensions.overrideRangeSliderProps(design, controlProps, navigationControl);

        return {
            controlProps,
            onStateChanged
        };
    }

    // TabGroup
    private createMainMenuTabGroup(
        design: DesignCommon,
        controlProps: ITabGroupProps,
        navigationControl: TabGroupControl,
        childNavigationControls: MenuControl[],
        menu: IMenu,
        menuStructure: Menu<BaseControl, string>,
        commands: Record<string, (navigationControl?: BaseControl) => void>,
        modals: { [modal: number]: (input?: object) => IModalOpened },
        setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu,
        pendingCalculationChangeSubscriptions: Subscription[],
        tabName = ''
    ) {
        controlProps.type = TabGroup;
        controlProps.controlsOrder = [];
        controlProps.childControls = {};

        this.menuExtensions.overrideTabGroupItems(controlProps, navigationControl);
        const navigationControlTabs = navigationControl.Tabs ?? [];
        controlProps.items = (navigationControlTabs ?? []).map((x, i) => {
            return {
                displayText: this.getTranslation(x.DisplayKey),
                value: i,
                tag: x.Tag
            };
        });

        for (const tab of navigationControlTabs) {
            const itemsByTag = this.menuExtensions.getTabGroupItemsByTag(childNavigationControls, tab, navigationControl);
            for (const childControl of itemsByTag) {
                const childNavigationSubgroupControls: UIPropertyBaseControl[] = menuStructure.Tabs.flatMap(x => x.TabRegions).flatMap(x => x.Controls).filter(x => x.ParentControlName == childControl.Name) ?? null;
                const control = this.createMainMenuControl(childControl, design, menu, menuStructure, setState, pendingCalculationChangeSubscriptions, commands, modals, childNavigationSubgroupControls, tabName);
                if (control != null) {
                    menu.controls[control.controlProps.controlId] = control.controlProps;
                    controlProps.childControls[control.controlProps.controlId] = {
                        ...control.controlProps,
                        parentTabName: tab.Tag,
                    };
                    this.onStateChangedUpdaters[control.controlProps.controlId] = control.onStateChanged;
                    this.onAllowedValuesChangedUpdaters[control.controlProps.controlId] = control.onAllowedValuesChanged;
                    this.onStructuralCalculationSoftwareChangedUpdaters[control.controlProps.controlId] = control.onStructuralCalculationSoftwareChanged;
                    this.onApplicationSettingChangedUpdaters[control.controlProps.controlId] = control.onApplicationSettingChanged;
                    controlProps.controlsOrder.push(control.controlProps.controlId);
                }
            }
        }

        const updateChildControlsFn = (updated: ITabControlProps[]): { [id: string]: ITabControlProps } => {
            const controlPropsList: { [id: string]: ITabControlProps } = {};
            for (const prop of updated) {
                controlPropsList[prop.controlId] = prop;
                controlProps.childControls[prop.controlId] = prop;
            }

            return controlPropsList;
        };

        controlProps.onChildControlsUpdate = (updated: ITabControlProps[]) => {
            const controlProps = updateChildControlsFn(updated);
            setState(menu => this.updateAllMainMenuControls(menu, controlProps));
        };

        this.menuExtensions.overrideTabGroupProps(design, controlProps, navigationControl);

        return {
            controlProps
        };
    }


    // Tooltip
    private findTooltip(propertyTooltip: string, codeListItem: CodeList, childItem: BaseControl, design: DesignCommon, uiPropertyId: number) {
        if (propertyTooltip != null && propertyTooltip != '') {
            return this.formatDisplayString(propertyTooltip, design, null, uiPropertyId);
        }

        const codeListItemTooltip = codeListItem != null ? this.formatCodeListTranslatedTooltip(codeListItem, design, null, uiPropertyId) : null;
        if (codeListItemTooltip) {
            return codeListItemTooltip;
        }

        if (childItem?.TooltipDisplayKey) {
            return this.formatDisplayString(childItem.TooltipDisplayKey, design, null, uiPropertyId);
        }
        return null;
    }

    private findTooltipDisabled(codeListItem: CodeList, childItem: BaseControl, design: DesignCommon, uiPropertyId: number) {
        if (codeListItem?.tooltipDisplayKey != null && codeListItem.tooltipDisplayKey != '') {
            return this.formatDisplayString(codeListItem.tooltipDisabledDisplayKey, design, null, uiPropertyId);
        }
        if (childItem?.TooltipDisplayKey != null && childItem.TooltipDisplayKey != '') {
            return this.formatDisplayString(childItem.TooltipDisabledDisplayKey, design, null, uiPropertyId);
        }
        return null;
    }

    private findTooltipTitle(propertyTooltipTitle: string, codeListItem: CodeList, childItem: BaseControl) {
        if (propertyTooltipTitle != null && propertyTooltipTitle != '') {
            return this.getTranslation(propertyTooltipTitle);
        }

        const codeListItemTooltipTitle = codeListItem != null ? this.getCodeListTranslatedTooltipTitle(codeListItem) : null;
        if (codeListItemTooltipTitle) {
            return codeListItemTooltipTitle;
        }

        if (childItem?.TooltipTitleDisplayKey != null && childItem.TooltipTitleDisplayKey != '') {
            return this.getTranslation(childItem.TooltipTitleDisplayKey);
        }
        return null;
    }


    // Common
    private getControlSize(size: number): Size {
        switch (size) {
            case 100:
                return Size.full;
            case 30:
                return Size.third;
            case 50:
            default:
                return Size.half;
        }
    }

    private getControlSizeClass(size: number): string {
        switch (size) {
            case 100:
                return 'size-100';
            case 10:
                return 'size-10';
            case 30:
                return 'size-30';
            case 85:
                return 'size-85';
            case 50:
            default:
                return '';
        }
    }

    private updateNavigationControlProps(design: DesignCommon, navigationControl: UIPropertyBaseControl, controlProps: IControlProps) {
        const property = navigationControl.UIPropertyId != null ? design.properties.get(navigationControl.UIPropertyId) : null;
        const controlDisabled = navigationControl.Disabled && (navigationControl.DisabledHiddenRegions == null || navigationControl.DisabledHiddenRegions.some(id => id == design.regionId));
        const isDisabled = controlDisabled || (property != null ? property.disabled : false);

        controlProps.disabled = isDisabled;
        controlProps.hidden = property != null ? this.isControlHidden(navigationControl, design, property) : false;
        if (navigationControl.TooltipTypeFunc) {
            controlProps.tooltipType = navigationControl.TooltipTypeFunc(design);
        }
        controlProps.tooltipTitle = property?.tooltipTitle != null && property.tooltipTitle != ''
            ? this.getTranslation(property.tooltipTitle)
            : (navigationControl.TooltipTitleDisplayKey != null && navigationControl.TooltipTitleDisplayKey != '' ? this.formatDisplayString(navigationControl.TooltipTitleDisplayKey, design) : null);
        if (controlProps.tooltip == null || controlProps.tooltip == '') {
            controlProps.tooltip = property?.tooltip != null && property.tooltip != ''
                ? this.getTranslation(property.tooltip)
                : (navigationControl.TooltipDisplayKey != null && navigationControl.TooltipDisplayKey != '' ? this.formatDisplayString(navigationControl.TooltipDisplayKey, design) : null);
        }
        controlProps.title = property?.titleDisplayKey != null && property.titleDisplayKey != ''
            ? this.getTranslation(property.titleDisplayKey)
            : (navigationControl.TitleDisplayKey != null && navigationControl.TitleDisplayKey != '' ? this.getTranslation(navigationControl.TitleDisplayKey) : null);
        controlProps.sizeClass = this.getControlSizeClass(property != null ? (property.size ?? navigationControl.Size) : navigationControl.Size);
        controlProps.enableItemsSorting = navigationControl.EnableItemsSorting && !isDisabled;

        return controlProps;
    }

    private updateAllMainMenuGroupControls(currentControlId: string, menu: IMenu, controls: { [id: string]: IControlProps }) {
        const currentMenuControl = menu.controls[currentControlId];
        if (currentMenuControl.type == Group) {
            const currentMenuGroupControl = currentMenuControl as IGroupProps;
            const updatedControlsGroup = controls[currentControlId] as IGroupProps;

            for (const menuSubControlId in currentMenuGroupControl.controls) {
                updatedControlsGroup.controls = {
                    ...updatedControlsGroup.controls,
                    [menuSubControlId]: {
                        ...menu.controls[menuSubControlId],
                        ...controls[menuSubControlId]
                    }
                };

                this.updateAllMainMenuGroupControls(menuSubControlId, menu, controls);
            }
        } else if (currentMenuControl.type == TabGroup) {
            const currentMenuTabControl = currentMenuControl as ITabGroupProps;
            const updatedControlTab = controls[currentControlId] as ITabGroupProps;

            for (const childId in currentMenuTabControl.childControls) {
                const parentTabName = currentMenuTabControl.childControls[childId].parentTabName;
                updatedControlTab.childControls = {
                    ...updatedControlTab.childControls,
                    [childId]: {
                        ...menu.controls[childId],
                        ...controls[childId],
                        parentTabName
                    },
                };

                this.updateAllMainMenuGroupControls(childId, menu, controls);
            }
        }
    }

    private updateMainMenuControl<TControl extends IControlProps>(menu: IMenu, id: string, control: Partial<TControl>): IMenu {
        return {
            ...menu,
            controls: {
                ...menu.controls,
                [id]: {
                    ...menu.controls[id],
                    ...control
                }
            }
        };
    }

    private getDesignSpecificLocalizedString(design: DesignCommon, translationKey: string, designTypeId: number, region: CommonRegion, tags?: ISanitizeTags, optional?: boolean) {
        if ([RegionHub.E1, RegionHub.E2, RegionHub.E3, RegionHub.E4, RegionHub.EE].includes(region.hubId) &&
            this.hasTranslation(`${translationKey}.EU`)) {
            translationKey = `${translationKey}.EU`;
        }

        return this.getDesignSpecificLocalizedStringInternal(design, translationKey, designTypeId, tags, optional);
    }

    /**
     * Try get design specific translation, otherwise returns translation for given key.
     */
    private getDesignSpecificLocalizedStringInternal(design: DesignCommon, key: string, designTypeId: number, tags?: ISanitizeTags, optional?: boolean): string {
        const designTypeKey = this.modulesService.designTypes.find((desingTypeCodeListItem) => desingTypeCodeListItem.id == designTypeId)?.displayKey;
        const specificDesignTypeKey = `${key}.${designTypeKey}`;

        const designStandardKey = this.menuExtensions.getDesignStandard(design);
        const specificDesignTypeAndStandardKey = `${specificDesignTypeKey}.${designStandardKey}`;

        if (this.hasTranslation(specificDesignTypeAndStandardKey)) {
            return this.getTranslation(specificDesignTypeAndStandardKey, { tags, optional });
        }

        if (this.hasTranslation(specificDesignTypeKey)) {
            return this.getTranslation(specificDesignTypeKey, { tags, optional });
        }

        return this.getTranslation(key, { tags, optional });
    }

    private logMissingCodeList(navigationControl: UIPropertyBaseControl, design: DesignCommon) {
        const isVisible = navigationControl.UIPropertyId == null || !design.properties.get(navigationControl.UIPropertyId).hidden;

        if (isVisible) {
            this.loggerService.log(`Missing code list: ${navigationControl.CodelistName}`, LogType.warn);
        }
    }

    private isControlHidden(navigationControl: UIPropertyBaseControl, design: DesignCommon, uiProperty: IProperty) {
        if ((navigationControl.Hidden && (navigationControl.DisabledHiddenRegions == null || navigationControl.DisabledHiddenRegions.some(id => id == design.regionId))) ||
            uiProperty.hidden ||
            this.menuExtensions.clientHidden(navigationControl.UIPropertyId, design)) {
            return true;
        }

        // if navigationControl is dlubal control, then shown/hidden dependent on the dlubal enabled setting.
        if (navigationControl.DlubalControl) {
            // Hide control if adding/importing new loads is disabled (example: HK + FOS3)
            if (this.userService.design.newAndImportLoadDisabled) {
                return true;
            }

            if (this.offlineService.isOffline) {
                // dlubal is always enabled in offline mode
                return true;
            }

            return !this.userSettingsService.settings.application.general.dlubalEnabled.value || false;
        }

        const navigationControlType = navigationControl.ControlType as string;
        // If navigation control is SAP import/export, then show/hide based on SAP 2000 enabled setting and region if the setting does not exist
        if (navigationControlType === 'SAP2000ImportExport') {
            return this.isStructuralCalculationSoftwareControlHidden(StructuralCalculationSoftwareType.SAP2000);
        }

        // If navigation control is robot import/export, then show/hide based on robot enabled setting and region if the setting does not exist
        if (navigationControlType === 'RobotImportExport') {
            return this.isStructuralCalculationSoftwareControlHidden(StructuralCalculationSoftwareType.Robot);
        }

        // If navigation control is ETABS import/export, then show/hide based on ETABS enabled setting and regions if the setting does not exist
        if (navigationControlType === 'ETABSImportExport') {
            return this.isStructuralCalculationSoftwareControlHidden(StructuralCalculationSoftwareType.ETABS);
        }

        if (navigationControlType === 'StaadProImportExport') {
            return this.isStructuralCalculationSoftwareControlHidden(StructuralCalculationSoftwareType.StaadPro);
        }

        // If navigation control is Risa export button, then show/hide based on Risa enabled setting
        if (navigationControl.UIPropertyId === PropertyMetaData.Loads_ExportRisa.id) {
            return this.isStructuralCalculationSoftwareControlHidden(StructuralCalculationSoftwareType.Risa);
        }

        return false;
    }

    private formatDisplayString(textKey: string, design: DesignCommon, codeList?: number, uiPropertyId?: number) {
        if (textKey == null || textKey.length == 0) {
            return null;
        }

        return this.menuExtensions.formatDisplayStringModule(textKey, design, codeList, uiPropertyId) ?? this.getTranslation(textKey);
    }

    private updateUiProperty(design: DesignCommon, uiPropertyId: number, value: any) {
        if (uiPropertyId != null) {
            // Update model and run calculation
            this.menuExtensions.calculateAsync(design,
                (design) => {
                    design.model[uiPropertyId] = value;
                }
            );
        }
    }

    private getOnInfoClickMethodByControl(navigationControl: UIPropertyWithValueBaseControl, commands: Record<string, (navigationControl?: BaseControl) => void>): () => void | undefined {
        if (navigationControl.Command)
            return this.getOnInfoClickMethod(navigationControl.Command, commands);

        return null;
    }

    private getOnInfoClickMethodByCodeList(codeList: CodeList, commands: Record<string, (navigationControl?: BaseControl) => void>): () => void | undefined {
        if (codeList.infoPopupCommand)
            return this.getOnInfoClickMethod(codeList.infoPopupCommand, commands);

        return null;
    }

    private getOnInfoClickMethod(commandName: string, commands: Record<string, (navigationControl?: BaseControl) => void>): () => void {
        return () => {
            const command = commands[commandName];

            if (command == null) {
                this.loggerService.log(`Missing command with ID = ${commandName}.`, LogType.error);
            }
            else {
                command();
            }
        }
    }

    private ensureSpriteInImageName(image: string): string {
        return image.startsWith('sprite-') ? image : `sprite-${image}`;
    }

    private hasTranslation(key: string): boolean {
        const localizationExtension = this.menuExtensions.localizationExtension;
        return localizationExtension?.checkForTranslation ? localizationExtension.checkForTranslation(key) : this.localizationService.hasTranslation(key);
    }

    private getTranslation(key: string, opts?: IGetStringOptions): string {
        const localizationExtension = this.menuExtensions.localizationExtension;
        return localizationExtension?.getTranslation ? localizationExtension.getTranslation(key, opts) : this.localizationService.getString(key, opts);
    }

    private getTranslationFuncBound(): (key: string, opts?: IGetStringOptions) => string | null {
        return this.menuExtensions.localizationExtension?.getTranslation?.bind(this);
    }
}
