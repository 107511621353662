import debounce from 'lodash-es/debounce';
import {AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnDestroy,OnInit, Output, ViewChild} from '@angular/core';
import { Design as DesignCommon } from '@profis-engineering/pe-ui-common/entities/design';
import { Design } from '../../../entities/design';
import { Project } from '../../../entities/project';
import { BrowserService } from '../../../services/browser.service';
import { FeatureVisibilityService } from '../../../services/feature-visibility.service';
import { ImportService } from '../../../services/import.service';
import { LocalizationService } from '../../../services/localization.service';
import { ModalService } from '../../../services/modal.service';
import { ModulesService } from '../../../services/modules.service';
import { OfflineService } from '../../../services/offline.service';
import { TrimbleConnectService } from '../../../services/trimble-connect.service';
import { DocumentModel } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Document';
import {
    DocumentIntegrationType
} from '@profis-engineering/pe-ui-common/generated-modules//Hilti.PE.IntegrationServices.Shared.Entities.Enums';
import { PendingAction } from '../home-page.common';
import { IntegrationsDocumentService } from '../../../services/integrations-document.service';
import { IntegrationsNotificationService } from '../../../services/integrations-notification.service';
import { IntegrationsDataService } from '../../../services/integrations-data.service';
export interface IIMportDesign {
    importing: boolean;
    mode: ImportDesignMode;
    disabled: boolean;
    onDesignImporting?: () => Project;
    selectImportFile?: () => void;
}

export enum ImportDesignMode {
    normal,
    compact
}

@Component({
  selector: 'app-import-designs',
  templateUrl: './import-designs.component.html',
  styleUrls: ['./import-designs.component.scss']
})

export class ImportDesignsComponent implements AfterViewInit, OnDestroy, OnInit, IIMportDesign {
  public importDesignMode: ImportDesignMode;

  public over: boolean;

  public allowedExtensions: string;

  @Input()
  public mode: ImportDesignMode;

  @Input()
  public importing: boolean;

  @Output()
  public importingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public disabled: boolean;

  @Input()
  public onDesignImporting: () => Project;


  @Output()
  public onDesignImported = new EventEmitter<{ design: Design, project: Project, renameFile?: boolean, openDesign?: boolean }>();

  @HostBinding('class.compact')
  public compactMode: boolean;

  public ImportDesignMode = ImportDesignMode;

  @ViewChild('importDesignRef')
  private inputElement: ElementRef;

  private design: Design;

  private project: Project;

  private changeOverDebounce: (over: boolean) => void;

  public pendingAction: PendingAction;

  @Output()
  public openNewDesignFromIntegration = new EventEmitter<{document: DocumentModel, documentType: DocumentIntegrationType}>();
  public DocumentIntegrationType: Record<keyof typeof DocumentIntegrationType, DocumentIntegrationType> = {
    Unknown: DocumentIntegrationType.Unknown,
    Risa: DocumentIntegrationType.Risa,
    ProfisEngineer: DocumentIntegrationType.ProfisEngineer,
    Ram: DocumentIntegrationType.Ram,
    AnchorCalculationIntegration: DocumentIntegrationType.AnchorCalculationIntegration
  };

  constructor(
      public localization: LocalizationService,
      public browser: BrowserService,
      public trimbleConnect: TrimbleConnectService,
      private offline: OfflineService,
      private importService: ImportService,
      private modal: ModalService,
      private featureVisibilityService: FeatureVisibilityService,
      private modulesService: ModulesService,
      private elementRef: ElementRef,
      public integrationsDocumentService: IntegrationsDocumentService,
      private integrationsNotification: IntegrationsNotificationService,
      private integrationsData: IntegrationsDataService
  ) { }


  public get contentMessage(): string {
      return 'Agito.Hilti.Profis3.ProjectAndDesing.ImportDesign.ContentMessage';
  }

  public get supportedFileTypeMessage(): string {
      return this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage') ?
          'Agito.Hilti.Profis3.Homepage.ProjectAndDesign.ImportDesign.SupportedFiles' :
          'Agito.Hilti.Profis3.ProjectAndDesing.ImportDesign.SupportedFiles';
  }

  public get chooseFileText(): string {
      return 'Agito.Hilti.Profis3.ProjectAndDesing.ImportDesign.ChooseFileText';
  }

  ngOnInit(): void {
      this.compactMode = this.mode == ImportDesignMode.compact;

      const generalExtensions = ['.pe','.pac','.pa2','.profis3'];
      const modulesImportFileExtensions = this.modulesService.getImportFileExtensions();

      // Add *.dia extension only if Decking is enabled.
      const FFDeckingGlobal = this.featureVisibilityService.isFeatureEnabled('Decking_Global');
      if (FFDeckingGlobal) {
          generalExtensions.push('.dia');
      }

      // sort and remove duplicates from all extensions
      const allowedExtensions = generalExtensions.concat(modulesImportFileExtensions)
          .map((extension) => extension.toLowerCase())
          .filter((value, index, self) => self.indexOf(value) === index)
          .sort()
          .join(',');

      this.allowedExtensions = allowedExtensions;
      this.integrationsDocumentService.handleUnopenedExternalDesignsPopup();
  }

  ngAfterViewInit(): void {
      this.setupGlobalEvents();
      this.changeOverDebounce = debounce(this.changeOver.bind(this), 10);
      this.integrationsNotification
            .connect()
            .then((success) => {
                if (!success) {
                    return;
                }
                this.integrationsData.registerNewDataAvailableHandler();
                this.integrationsDocumentService.registerNewDocumentNotificationHandler();
            });
  }

  @HostListener('drag', ['$event'])
  @HostListener('dragstart', ['$event'])
  public drag(event: DragEvent): void {
      this.handleDragEvents(event);
  }


  @HostListener('dragover', ['$event'])
  @HostListener('dragenter', ['$event'])
  public dragEnter(event: DragEvent): void {
      this.handleDragEvents(event);
      this.changeOver(true);
      this.changeOverDebounce(true);
  }

  @HostListener('dragleave', ['$event'])
  @HostListener('dragend', ['$event'])
  @HostListener('drop', ['$event'])
  public dragLeave(event: DragEvent) {
      this.handleDragEvents(event);
      this.changeOverDebounce(false);
  }

  @HostListener('drop', ['$event'])
  public drop(event: DragEvent): void {
      if (!this.isFile(event)) {
          return;
      }
      const files = event.dataTransfer.files;
      this.inputElement.nativeElement.value = null;
      if (files != null && files.length > 0) {
          this.import(files[0]);
      }
      else {
          this.modal.openAlertWarning(
              this.localization.getString('Agito.Hilti.Profis3.FileUpload.NotAFileAlert.Title'),
              this.localization.getString('Agito.Hilti.Profis3.FileUpload.NotAFileAlert.Message')
          );
      }
  }

  public containerClick(): void {
      if (this.mode == ImportDesignMode.compact) {
          this.selectImportFile();
      }
  }

  public selectImportFile(): void {
      if (this.importing || this.disabled) {
          return;
      }

      if (this.offline.isOffline) {
          this.offline.openImportDialog((file) => {
              this.import(file, file.name);
          });
      }
      else if (this.inputElement.nativeElement.value == null || this.inputElement.nativeElement.value == '') {
              setTimeout(() => {
                  this.inputElement.nativeElement.click();
              });
      }
  }

  public importFileSelected(): void {
      if (this.inputElement.nativeElement.value != null && this.inputElement.nativeElement.value != '') {
          const file = this.inputElement.nativeElement.files[0];
          this.inputElement.nativeElement.value = null;
          this.import(file);
      }
  }

  public async importTrimbleConnect(): Promise<DesignCommon> {
      try {
          this.project = this.onDesignImporting();
          const data = await this.trimbleConnect.importDesign(this.project, this.design, (design, project, renameFile, openDesign) => this.onDesignImported.emit({
              design,
              project,
              renameFile,
              openDesign
          }));
          return data.design;
      }
      catch (_) { return null; }
  }

  ngOnDestroy(): void {
      window.removeEventListener('dragenter', this.windowDrag);
      window.removeEventListener('dragover', this.windowDrag);
      window.removeEventListener('drop', this.windowDrag);
  }

  private setupGlobalEvents(): void {
      this.windowDrag = this.windowDrag.bind(this);
      window.addEventListener('dragenter', this.windowDrag);
      window.addEventListener('dragover', this.windowDrag);
      window.addEventListener('drop', this.windowDrag);
  }

  private isFile(event: DragEvent): boolean {
      const types = Array.from(event.dataTransfer.types);
      return types?.length > 0 && types.some((type) => type.toLowerCase() == 'files');
  }

  private windowDrag(event: DragEvent): void {
      if (event.target !== this.elementRef.nativeElement) {
          event.preventDefault();

          event.dataTransfer.effectAllowed = 'none';
          event.dataTransfer.dropEffect = 'none';
      }
  }

  private changeOver(over: boolean): void {
      this.over = over;
  }

  private async import(projectDesign: File | Blob, name?: string): Promise<void> {
      this.importing = true;
      this.importingChange.emit(true);

      this.project = this.onDesignImporting();

      try {
          await this.importService.import(this.project, this.design, projectDesign, name, false);
      }
      finally {
          this.importing = false;
          this.importingChange.emit(false);
      }
  }

  private handleDragEvents(event: DragEvent): void {
      if (!this.isFile(event)) {
          return;
      }

      event.preventDefault();
      event.stopPropagation();
      event.dataTransfer.dropEffect = 'copy';
  }
  public openNewDesignFromIntegrationDocument(document: DocumentModel, documentType: DocumentIntegrationType) {
    this.openNewDesignFromIntegration.emit({document, documentType});
  }
  public hasIntegrationDocuments(): boolean {
    return (this.integrationsDocumentService.RisaDocuments.length > 0 || this.integrationsDocumentService.RamDocuments.length > 0);
  }
}
