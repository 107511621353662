

import { inject } from '@angular/core';

import { DataService } from '../services/data.service';
import { GuardFactory } from './combined.guard';

// Guard for scenarios where initial data is not needed (e.g. when displaying /version)
export const noInitialDataGuard: GuardFactory = (handleGuardAction) => {
    const dataService = inject(DataService);

    return () => handleGuardAction(async () => {
        dataService.initialDataNotNeeded = true;

        // Ensure version set.
        await dataService.setApplicationVersion();

        return true;
    });
};
