import { Injectable } from '@angular/core';
import { IDetailedDisplayDesign as IDetailedDisplayDesignC2C } from '@profis-engineering/pe-ui-c2c/entities/display-design';
import {
    ProjectDesignEntityC2C
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { Design as DesignCommon } from '@profis-engineering/pe-ui-common/entities/design';
import { IDetailedDisplayDesign as IDetailedDisplayDesignCommon } from '@profis-engineering/pe-ui-common/entities/display-design';
import { ProjectUser } from '@profis-engineering/pe-ui-common/entities/project';
import {
    ArchiveDocumentResponseModel, DocumentGetResponse
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.Documents';
import {
    ArchiveProjectResponseModel, ProjectExpandedState, ProjectGetAllProjectsContentResponseModel, ProjectGetProjectContentResponseModel
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.Projects';
import {
    DesignExternalMetaData as DesignExternalMetaDataCommon, DocumentAccessMode, DocumentServiceBase,
    IAddDesignBaseOptions, IDefferedData as IDefferedDataCommon, IDesignListItem as IDesignListItemCommon,
    IGetDesignContentBaseOptions, ISmallDesignChangeOptions, IUpdateDesignWithNewContentBaseOptions
} from '@profis-engineering/pe-ui-common/services/document.common';
import { BaseDesign } from '@profis-engineering/pe-ui-decking/src/decking/entities/decking-design/base-design';
import { IDetailedDisplayDesign as IDetailedDisplayDesignPe } from '@profis-engineering/pe-ui-shared/entities/display-design';
import {
    ProjectDesignBaseEntity
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import { Design, IBaseDesign } from '../entities/design';
import { DesignExternalMetaData } from '../entities/design-external-meta-data';
import { DocumentUser, IDocumentArchive, SharedDocumentUser } from '../entities/document';
import { IProjectArchive, Project } from '../entities/project';
import { DocumentServiceWebV1 } from './document-web-v1.service';
import { DocumentWebV2Service } from './document-web-v2.service';
import { DocumentService, IDesignListItem } from './document.service';
import { FeatureVisibilityService } from './feature-visibility.service';
import { LoggerService } from './logger.service';
import { ModulesService } from './modules.service';

@Injectable()
export class DocumentServiceImpl extends DocumentService implements DocumentServiceBase {
    constructor(
        loggerService: LoggerService,
        modulesService: ModulesService,
        private readonly featureVisibilityService: FeatureVisibilityService,
        private readonly documentServiceV1: DocumentServiceWebV1,
        private readonly documentServiceV2: DocumentWebV2Service,
    ) {
        super(
            'DocumentService',
            loggerService,
            modulesService
        );
    }

    private get documentService() {
        return this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage') ? this.documentServiceV2 : this.documentServiceV1;
    }

    /**
     * A list of root level projects. Sub projects are accessible in subprojects node of the root level projects.
     */
    public override get projects(): Record<string, Project> {
        return this.documentService.projects;
    }
    public override get draftsProject(): Project {
        return this.documentService.draftsProject;
    }
    public override get pendingRequests(): number {
        return this.documentService.pendingRequests;
    }
    public override get myProjects(): Record<string, Project> {
        return this.documentService.myProjects;
    }
    public override get projectsArchive(): IProjectArchive[] {
        return this.documentService.projectsArchive;
    }
    public override get documentsArchive(): IDocumentArchive[] {
        return this.documentService.documentsArchive;
    }
    public override get projectsLoaded(): boolean {
        return this.documentService.projectsLoaded;
    }
    public override get projectsFlat(): Record<string, Project> {
        return this.documentService.projectsFlat;
    }
    public override get designsFlat(): Record<string, IDesignListItem | IDesignListItemCommon> {
        return this.documentService.designsFlat;
    }

    public override findProjectById(projectId: string): Project {
        return this.documentService.findProjectById(projectId);
    }
    /**
     * Creates a unique file name from a given name
     */
    public createUniqueName(oldName: string, usedNames: string[]): string {
        return this.documentService.createUniqueName(oldName, usedNames);
    }

    public async updateDesignProject(designId: string, projectId: string): Promise<void> {
        return this.documentService.updateDesignProject(designId, projectId);
    }

    /**
     * Deletes (permanently) the project from internal store and call external document service.
     * @projectId project
     */
    public async removeArchivedProject(projectId: string): Promise<void> {
        return this.documentService.removeArchivedProject(projectId);
    }

    /**
     * Restores the design from internal store and call external document service.
     * @documentId document
     */
    public async restoreExistingDesign(documentId: string): Promise<void> {
        return this.documentService.restoreExistingDesign(documentId);
    }

    /**
     * Deletes (permanently) the design from internal store and call external document service.
     * @documentIds documents
     */
    public async removeArchivedDesign(documentIds: string[]): Promise<void> {
        return this.documentService.removeArchivedDesign(documentIds);
    }

    /**
     * Deletes (permanently) the project from internal store and call external document service.
     * @projectId project
     */
    public async removeProject(projectId: string): Promise<void> {
        return this.documentService.removeProject(projectId);
    }

    /**
     * Convert (permanently) the project to company (shared) project and call external document service.
     * @projectId project
     */
    public async convertProject(projectId: string): Promise<void> {
        return this.documentService.convertProject(projectId);
    }

    public async getDocumentThumbnails(documentIds: string[]): Promise<Record<string, string>> {
        return this.documentService.getDocumentThumbnails(documentIds);
    }

    /**
     * Restores the project from internal store and call external document service.
     * @projectId project
     */
    public async restoreProject(projectId: string): Promise<string[]> {
        return this.documentService.restoreProject(projectId);
    }

    public initialize(data: DocumentGetResponse, dataArchive: ArchiveProjectResponseModel[], dataDocumentArchive?: ArchiveDocumentResponseModel[]): void {
        return this.documentService.initialize(data, dataArchive, dataDocumentArchive);
    }

    /*
    * Saves a new or existing project. Returns promise when the new id of the project is returned.
    */
    public async saveProject(project: Project): Promise<void> {
        return this.documentService.saveProject(project);
    }

    /*
     * Toggles expanded state for project.
     */
    public async toggleExpandProject(project: Project): Promise<void> {
        return this.documentService.toggleExpandProject(project);
    }

    /**
     * Remove the project from internal store and call external document service.
     * @projectId project
     */
    public async archiveProject(projectId: string): Promise<void> {
        return this.documentService.archiveProject(projectId);
    }

    /**
     * Update design thumbnail image.
     * @param designId Design id
     * @param thumbnailContent Thumbnail image
     * @param respond If server should send response
     */
    public async updateDesignThumbnailImage(designId: string, thumbnailContent: string, respond: boolean): Promise<void> {
        return this.documentService.updateDesignThumbnailImage(designId, thumbnailContent, respond);
    }

    /**
     * Remove the design from internal store and call external document service.
     * @designId design
     */
    public async archiveDesign(designId: string): Promise<void> {
        return this.documentService.archiveDesign(designId);
    }

    /**
     * Deletes (permanently) the designs from internal store and call external document service.
     * @designIds list of designs
     */
    public async removeDesigns(designIds: string[]): Promise<void> {
        return this.documentService.removeDesigns(designIds);
    }

    /**
     * Upload design printscreen image when a design report is ready for export
     * @param designId - design id
     * @param imageContent - the design image content in base64 encoded xml format
     * @param base64ThumbnailXmlContent - the design thumbnail image content in base64 encoded xml format
     */
    public uploadDesignImage(designId: string, imageContent: string, thumbnailContent: string): Promise<void> {
        return this.documentService.uploadDesignImage(designId, imageContent, thumbnailContent);
    }

    /**
     * Publishes the design.
     * @param id - The design id.
     */
    public async publish(id: string): Promise<void> {
        return this.documentService.publish(id);
    }

    /**
     * Returns users on the project
     * @param projectId - Project id
     */
    public async getUsersOnProjectById(projectId: string): Promise<ProjectUser[]> {
        return this.documentService.getUsersOnProjectById(projectId);
    }

    /**
     * Adds user to the project
     * @param data - Project user data
     */
    public addUsersOnProjectById(data: ProjectUser): Promise<void> {
        return this.documentService.addUsersOnProjectById(data);
    }

    /**
     * Removes user from the project
     * @param data - Project user data
     */
    public removeUsersOnProjectById(data: ProjectUser): Promise<void> {
        return this.documentService.removeUsersOnProjectById(data);
    }

    public userHasAccessToProject(projectId: string): Promise<boolean> {
        return this.documentService.userHasAccessToProject(projectId);
    }

    public deleteProjectLocal(projectId: string): void {
        return this.documentService.deleteProjectLocal(projectId);
    }

    /**
     * Find design by name.
     * @param designName - Design name
     * @param projectId - Project id
     */
    public findDesignByName(designName: string, projectId: string, forceFromServer?: boolean): Promise<IDesignListItemCommon> {
        return this.documentService.findDesignByName(designName, projectId, forceFromServer);
    }

    public openNewSessionForDesign(designId: string): string {
        return this.documentService.openNewSessionForDesign(designId);
    }

    public getSessionKeyForDesign(designId: string): string {
        return this.documentService.getSessionKeyForDesign(designId);
    }

    public getNumberOfDocumentsOwnedByUser(): number {
        return this.documentService.getNumberOfDocumentsOwnedByUser();
    }

    public async updateDocumentDesignContent(document: IDesignListItem, base64Content: string, unlock: boolean, exclusiveLock: boolean, documentAccessMode: DocumentAccessMode): Promise<void> {
        return this.documentService.updateDocumentDesignContent(document, base64Content, unlock, exclusiveLock, documentAccessMode);
    }

    public async getProjectContent(projectId: string): Promise<ProjectGetProjectContentResponseModel> {
        return this.documentService.getProjectContent(projectId);
    }

    public async getAllProjectsContent(): Promise<ProjectGetAllProjectsContentResponseModel> {
        return this.documentService.getAllProjectsContent();
    }

    public async moveDesign(documentId: string, projectId: string): Promise<void> {
        return this.documentService.moveDesign(documentId, projectId);
    }

    public async copyDesign(documentId: string, documentName: string, projectId: string): Promise<void> {
        return this.documentService.copyDesign(documentId, documentName, projectId);
    }

    public override async toggleExpandProjectV2(projectId: string, expandedState: ProjectExpandedState): Promise<void> {
        return this.documentService.toggleExpandProjectV2(projectId, expandedState);
    }

    public override addToFavorite(documentIds: string[], documentType?: number): Promise<void> {
        return this.documentService.addToFavorite(documentIds, documentType);
    }

    public override findDesignById(designId: string): IDesignListItem {
        return this.documentService.findDesignById(designId);
    }

    public override removeFromFavorite(documentIds: string[], documentType?: number): Promise<void> {
        return this.documentService.removeFromFavorite(documentIds, documentType);
    }

    public override getUsersOnDocumentById(documentId: string): Promise<DocumentUser[]> {
        return this.documentService.getUsersOnDocumentById(documentId);
    }

    public override addUsersOnDocument(data: SharedDocumentUser): Promise<void> {
        return this.documentService.addUsersOnDocument(data);
    }

    public override removeUsersOnDocument(data: SharedDocumentUser, markDesign = false): Promise<void> {
        return this.documentService.removeUsersOnDocument(data, markDesign);
    }

    public override deleteDocumentLocal(projectId: string): Promise<void> {
        return this.documentService.deleteDocumentLocal(projectId);
    }

    public override archiveDocument(documentIds: string[]): Promise<void> {
        return this.documentService.archiveDocument(documentIds);
    }

    public override projectNameExists(projectName: string, projectId: string): boolean {
        return this.documentService.projectNameExists(projectName, projectId);
    }

    public override designNameExistsOnNew(project: Project, designName: string): boolean {
        return this.documentService.designNameExistsOnNew(project, designName);
    }

    public override findAllDesignsByProject(project: Project): { [id: string]: IDesignListItemCommon } {
        return this.documentService.findAllDesignsByProject(project);
    }

    public override getMissingThumbnailList(documentIds: string[]): string[] {
        return this.documentService.getMissingThumbnailList(documentIds);
    }

    public override sortAllProjectList(): void {
        return this.documentService.sortAllProjectList();
    }



    public getMetadataFromDesign(designType: number, design: object): DesignExternalMetaDataCommon {
        return this.documentService.getMetadataFromDesign(designType, design);
    }


    public async openDesignCommon<TProjectDesign>(
        design: IBaseDesign
    ): Promise<TProjectDesign> {
        return await this.documentService.openDesignCommon(design);
    }


    public async openDesignExclusiveByType<TProjectDesign>(
        designType: number,
        design: IBaseDesign
    ): Promise<TProjectDesign> {
        return await this.documentService.openDesignExclusiveByType(designType, design);
    }

    public async openDesignExclusiveBase<TProjectDesign>(
        design: IBaseDesign,
        adjustContent?: (content: TProjectDesign, designName: string, projectName: string) => TProjectDesign
    ): Promise<TProjectDesign> {
        return await this.documentService.openDesignExclusiveBase(design, adjustContent);
    }

    public async openDesignExclusive<TProjectDesign>(design: IBaseDesign, adjustContent?: (content: TProjectDesign, designName: string, projectName: string) => TProjectDesign): Promise<TProjectDesign> {
        return await this.documentService.openDesignExclusive(design, adjustContent);
    }


    public async addDesignBase(options: IAddDesignBaseOptions): Promise<IDesignListItem> {
        return this.documentService.addDesignBase(options);
    }

    /**
     * Add a completely new design
     * @param projectId - To what project are we adding this design.
     * @param design - The new design entity.
     */
    public async addDesignCommon(projectId: string, design: DesignCommon, canGenerateUniqueName: boolean, ignoreConflict: boolean): Promise<IDesignListItemCommon> {
        return this.documentService.addDesignCommon(projectId, design, canGenerateUniqueName, ignoreConflict);
    }


    public async getDesignContentBase<TProjectDesign>(
        options: IGetDesignContentBaseOptions<TProjectDesign>
    ): Promise<TProjectDesign> {
        return await this.documentService.getDesignContentBase(options);
    }

    public async getContent<TProjectDesign>(designId: string): Promise<TProjectDesign> {
        return await this.documentService.getContent(designId);
    }


    /**
     * Updates a design with the new project design.
     * @param design - The design entity.
     */
    public async updateDesign(design: DesignCommon): Promise<void> {
        await this.documentService.updateDesign(design);
    }

    public async updateDesignWithNewContentByType(
        designType: number,
        designId: string,
        projectId: string,
        designName: string,
        contentOverride: object,
        metadataOverride: DesignExternalMetaDataCommon,
        displayDesign: IDetailedDisplayDesignCommon,
        unlock = false,
        exclusiveLock = false,
        documentAccessMode = DocumentAccessMode.Update
    ) {
        await this.documentService.updateDesignWithNewContentByType(
            designType,
            designId,
            projectId,
            designName,
            contentOverride,
            metadataOverride,
            displayDesign,
            unlock,
            exclusiveLock,
            documentAccessMode
        );
    }

    public async updateDesignWithNewContentBase<TProjectDesign, TDetailedDisplayDesign>(
        opts: IUpdateDesignWithNewContentBaseOptions<TProjectDesign, TDetailedDisplayDesign>
    ): Promise<void> {
        return this.documentService.updateDesignWithNewContentBase(opts);
    }

    public async updateDesignWithNewContentCommon<TDisplayDesign>(
        design: DesignCommon,
        displayDesign: TDisplayDesign,
        unlock = false,
        exclusiveLock = false,
        documentAccessMode = DocumentAccessMode.Update
    ): Promise<void> {
        this.documentService.updateDesignWithNewContentCommon(design, displayDesign, unlock, exclusiveLock, documentAccessMode);
    }


    public async smallDesignChangeBase<TProjectDesign, TDetailedDisplayDesign>(
        options: ISmallDesignChangeOptions<TProjectDesign, TDetailedDisplayDesign>
    ): Promise<void> {
        await this.documentService.smallDesignChangeBase(options);
    }

    public async smallDesignChangeCommon<TProjectDesign, TDetailedDisplayDesign>(
        internalDesign: IDesignListItemCommon,
        projectDesign: TProjectDesign,
        data: IDefferedDataCommon<TProjectDesign, TDetailedDisplayDesign>,
        metaData: DesignExternalMetaDataCommon
    ): Promise<void> {
        await this.documentService.smallDesignChangeCommon(internalDesign, projectDesign, data, metaData);
    }



    /* BUDQBP-36886: MODULARIZATION CLEANUP */
    public async openDesign(design: IBaseDesign): Promise<ProjectDesignBaseEntity> {
        return this.documentService.openDesign(design);
    }

    public async openDesignC2C(design: IBaseDesign): Promise<ProjectDesignEntityC2C> {
        return this.documentService.openDesignC2C(design);
    }

    /**
     * Takes the design id file and returns JSON object, representing it.
     * In some cases object deriving from CantOpenDesignReason is returned.
     * @param design - Design
     */
    public async openDesignExclusivePe(design: IBaseDesign): Promise<ProjectDesignBaseEntity> {
        return this.documentService.openDesignExclusivePe(design);
    }

    public async openDesignExclusiveC2C(design: IBaseDesign): Promise<ProjectDesignEntityC2C> {
        return this.documentService.openDesignExclusiveC2C(design);
    }

    public async openDesignExclusiveDecking(design: IBaseDesign): Promise<IBaseDesign> {
        return this.documentService.openDesignExclusiveDecking(design);
    }


    public async addDesign(projectId: string, design: Design, canGenerateUniqueName: boolean, ignoreConflict: boolean, useDeckingDesign: boolean, deckingProject: BaseDesign): Promise<IDesignListItem> {
        return this.documentService.addDesign(projectId, design, canGenerateUniqueName, ignoreConflict, useDeckingDesign, deckingProject);
    }


    public async getDeckingDesignContent(designId: string, sessionId: string, documentId: string, isLock: boolean): Promise<any> {
        return this.documentService.getDeckingDesignContent(designId, sessionId, documentId, isLock);
    }


    /**
     * Updates a design with the new project design. Instead of using the ProjectDesignBaseEntity in design file use the ProjectDesignBaseEntity in the override parameter.
     * @param design - The design entity.
     */
    public async updateDesignWithNewContent(
        designId: string,
        projectId: string,
        designName: string,
        contentOverride: ProjectDesignBaseEntity,
        metadataOverride: DesignExternalMetaData,
        displayDesign: IDetailedDisplayDesignPe,
        unlock = false,
        exclusiveLock = false,
        documentAccessMode = DocumentAccessMode.Update
    ): Promise<void> {
        return this.documentService.updateDesignWithNewContent(designId, projectId, designName, contentOverride, metadataOverride, displayDesign, unlock, exclusiveLock, documentAccessMode);
    }

    public async updateDesignWithNewContentC2C(
        designId: string,
        projectId: string,
        designName: string,
        contentOverride: ProjectDesignEntityC2C,
        metadataOverride: DesignExternalMetaData,
        displayDesign: IDetailedDisplayDesignC2C,
        unlock = false,
        exclusiveLock = false,
        documentAccessMode = DocumentAccessMode.Update
    ): Promise<void> {
        this.documentService.updateDesignWithNewContentC2C(designId, projectId, designName, contentOverride, metadataOverride, displayDesign, unlock, exclusiveLock, documentAccessMode);
    }
    /* BUDQBP-36886: MODULARIZATION CLEANUP */
}
