import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import {
    IAuthorizeResult, IOfflineUser, ISetupLanguageRegistryInfo, OfflineService
} from './offline.service';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineServiceImpl extends OfflineService {
    public readonly isOffline = false;

    constructor(
        apiService: ApiService,
        sessionStorageService: SessionStorageService
    ) {
        super(apiService, sessionStorageService);
    }



    /**
     * Open the URL link from the INTERNET.
     * @param path - Path to the web page.
     */
    public nativeExternalLinkOpen(path: string): void {
        window.open(path, '_blank');
    }

    public nativeLocalFileOpen(path: string): void {
        window.open(path, '_blank');
    }

    /* eslint-disable @typescript-eslint/no-unused-vars */
    public loading(_ignoreErrors?: boolean): Promise<void> {
        throw new Error('Not implemented.');
    }

    public checkForUpdates(): Promise<void> {
        throw new Error('Not implemented.');
    }

    public buildRedirectUri(path: string): string {
        return window.location.origin + path;
    }

    /**
     * Opens or stores the file when run as native application (in Electron).
     * @param blob - The file to open or store.
     * @param fileName - Name of the file.
     * @param storeInTemp - Store the file temp folder, or show the save as dialog.
     * @param openAfterSave - Open the file after save.
     * @param filePath - Save the file to a already known local path - offline only.
     */
    public nativeFileSave(
        _blob: Blob,
        _fileName: string,
        _storeInTemp: boolean,
        _openAfterSave: boolean,
        _filePath?: string
    ): Promise<string> {
        throw new Error('Not implemented.');
    }

    /**
     * Opens the file from a specified local path or URL.
     * @param path - PAth to the file that needs to be opened.
     * @param fileName - Name of the file.
     * @param storeInTemp - Store the file temp folder, or show the save as dialog.
     * @param openAfterSave - Open the file after save.
     */
    public nativeLocalPathOpen(
        path: string,
        _fileName: string,
        _storeInTemp: boolean,
        _openAfterSave: boolean
    ): void {
        window.open(path, '_blank');
    }

    public getFilePath(_file: File): string {
        throw new Error('Not implemented.');
    }

    public getAppDataPath(): string {
        throw new Error('Not implemented.');
    }

    public openImportDialog(_callback?: (file: File) => void) {
        throw new Error('Not implemented.');
    }

    public getCurrentUser(): IOfflineUser {
        throw new Error('Not implemented.');
    }

    public getSetupLanguageFromRegistry(): Promise<string> {
        throw new Error('Not implemented.');
    }

    public parseSetupLanguage(_source: string): ISetupLanguageRegistryInfo {
        throw new Error('Not implemented.');
    }

    public loginUser(_state: string, _language: string): Promise<IAuthorizeResult> {
        throw new Error('Not implemented.');
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
