import debounce, { DebouncedFunc } from 'lodash-es/debounce';
import { TooltipServiceBase } from '@profis-engineering/pe-ui-common/services/tooltip.common';

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TooltipService extends TooltipServiceBase {
    private readonly delayInMs = 500;
    private debouncDelay: DebouncedFunc<() => void> = null;

    private text = '';
    private mouseIsDown = false;

    private mouseX: number;
    private mouseY: number;

    private isHidden = false;

    private tooltipDiv: HTMLElement;
    private tooltipTextDiv: HTMLElement;
    private tooltipElement: HTMLElement;

    constructor() {
        super();
        this.mouseMoveEvent = this.mouseMoveEvent.bind(this);
        this.mouseUpEvent = this.mouseUpEvent.bind(this);
        this.mouseDownEvent = this.mouseDownEvent.bind(this);
        this.dragEndEvent = this.dragEndEvent.bind(this);

        this.initTooltip();
        this.registerEvents();
    }


    public show(text: string) {
        if (this.text != text) {
            this.onHide();
        }

        this.text = text;

        if (this.text != null && this.text.length > 0 && !this.mouseIsDown) {
            this.onShow();
        }
        else {
            this.onHide();
        }
    }

    public hide(text: string) {
        if (this.text == text) {
            this.onHide();
        }
    }

    private initTooltip() {
        const tooltipDiv = document.createElement('div');
        tooltipDiv.id = 'tooltip-3d';
        tooltipDiv.classList.add('tooltip-3d-down');

        const tooltipArrowDiv = document.createElement('div');
        tooltipArrowDiv.classList.add('tooltip-arrow');

        const tooltipTextDiv = document.createElement('div');
        tooltipTextDiv.classList.add('text');

        tooltipDiv.appendChild(tooltipArrowDiv);
        tooltipDiv.appendChild(tooltipTextDiv);

        this.tooltipDiv = tooltipDiv;
        this.tooltipTextDiv = tooltipTextDiv;
        this.tooltipElement = document.getElementById('tooltip-3d');
        document.body.append(this.tooltipDiv);
        this.onHide();
    }

    private onShow() {
        if (this.debouncDelay != null) {
            this.debouncDelay.cancel();
            this.debouncDelay = null;
        }

        this.debouncDelay = debounce(() => {
            this.tooltipTextDiv.innerHTML = this.text;
            const updatePostition = () => {
                let isDown = true;
                let glModel = document.getElementsByTagName('pe-gl-model')[0];
                if (!glModel) {
                    glModel = document.getElementsByTagName('c2c-gl-model')[0];
                }
                if (this.mouseY + 35 >= glModel?.clientHeight) {
                    this.tooltipDiv.classList.remove('tooltip-3d-down');
                    this.tooltipDiv.classList.add('tooltip-3d-up');
                    isDown = false;
                } else {
                    this.tooltipDiv.classList.remove('tooltip-3d-up');
                    this.tooltipDiv.classList.add('tooltip-3d-down');
                }

                this.tooltipDiv.style.display = 'block';
                this.tooltipDiv.style.left = (this.mouseX - this.tooltipDiv.clientWidth / 2 + (isDown ? - 10 : -3)) + 'px';
                this.tooltipDiv.style.top = (isDown ? this.mouseY + 35 : this.mouseY - 20 - this.tooltipDiv.clientHeight) + 'px';
            };
            updatePostition();
            updatePostition();
            this.isHidden = false;
        }, this.delayInMs);

        this.debouncDelay();
    }

    private onHide() {
        if (this.debouncDelay != null) {
            this.debouncDelay.cancel();
            this.debouncDelay = null;
        }

        if (!this.isHidden) {
            this.tooltipDiv.querySelector('.text').innerHTML = '';
            this.tooltipDiv.style.display = 'none';
            this.isHidden = true;
        }
    }

    private mouseMoveEvent(e: any) {
        this.mouseX = e.pageX;
        this.mouseY = e.pageY;
    }

    private mouseDownEvent() {
        this.mouseIsDown = true;
        this.onHide();
    }

    private mouseUpEvent() {
        this.mouseIsDown = false;
    }

    private dragEndEvent() {
        this.mouseIsDown = false;
    }

    private registerEvents() {
        if (this.tooltipElement == null) {
            this.tooltipElement = document.getElementById('tooltip-3d');
        }

        document.body.addEventListener('mousemove', this.mouseMoveEvent, false);
        document.body.addEventListener('mousedown', this.mouseDownEvent, false);
        document.body.addEventListener('mouseup', this.mouseUpEvent, false);
        document.body.addEventListener('dragend', this.dragEndEvent, false);
    }

    private unregisterEvents() {
        document.body.removeEventListener('mousemove', this.mouseMoveEvent, false);
        document.body.removeEventListener('mousedown', this.mouseDownEvent, false);
        document.body.removeEventListener('mouseup', this.mouseUpEvent, false);
        document.body.removeEventListener('dragend', this.dragEndEvent, false);
        this.mouseIsDown = false;
    }
}
