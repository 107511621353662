import { Component, ElementRef, OnInit } from '@angular/core';
import {
    IConfirmChangeProps
} from '@profis-engineering/pe-ui-common/components/confirm-change/confirm-change.common';
import {
    IHideText, ModalInstance, MODAL_DISMISS_REASON_BACKDROP, MODAL_DISMISS_REASON_ESC
} from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { LocalizationService } from '../../services/localization.service';

@Component({
    selector: 'app-confirm-change',
    templateUrl: './confirm-change.component.html',
    styleUrls: ['./confirm-change.component.scss']
})
export class ConfirmChangeComponent implements OnInit {
    public hideAdditionalInfo = false;
    private readonly hideTextProps?: IHideText = undefined;

    constructor(
        private readonly localization: LocalizationService,
        private readonly modalInstance: ModalInstance<IConfirmChangeProps>,
        private readonly elementRef: ElementRef<HTMLElement>
    ) {
        if(this.modalInstance.input?.hideText != null) {
            this.hideTextProps = this.modalInstance.input?.hideText();
            this.hideAdditionalInfo = this.setHideAdditionalInfo();
        }
    }

    public get canDismiss() {
        return this.modalInstance.input?.canDismiss != null ? this.modalInstance.input.canDismiss : true;
    }

    public get id() {
        return this.modalInstance.input?.id;
    }

    public get title() {
        return this.modalInstance.input?.title != null && this.modalInstance.input?.title != ''
            ? this.localization.sanitizeText(this.modalInstance.input.title, LocalizationService.SubSup)
            : '&nbsp;';
    }

    public get message() {
        const sanitizeTags = { ...LocalizationService.PBrB, ...LocalizationService.SubSup, ...LocalizationService.A };
        const message = this.modalInstance.input?.message;
        return this.modalInstance.input?.message != null && this.modalInstance.input?.message != ''
            ? this.localization.sanitizeText(message, sanitizeTags)
            : '&nbsp;';
    }

    public get hasShowMore() {
        return this.hideTextProps != null;
    }

    public get showMoreButtonText() {
        return this.hideAdditionalInfo ? this.localization.getString('Agito.Hilti.Profis3.Main.Popup.ShowMore') : this.localization.getString('Agito.Hilti.Profis3.Main.Popup.ShowLess');
    }

    public get hasOnConfirm() {
        return this.modalInstance.input?.onConfirm != null;
    }

    public get confirmButtonText() {
        return this.modalInstance.input?.confirmButtonText != null && this.modalInstance.input?.confirmButtonText != ''
            ? this.localization.sanitizeText(this.modalInstance.input.confirmButtonText, LocalizationService.SubSup)
            : '&nbsp;';
    }

    public get hasOnCancel() {
        return this.modalInstance.input?.onCancel != null;
    }

    public get cancelButtonText() {
        return this.modalInstance.input?.cancelButtonText != null && this.modalInstance.input?.cancelButtonText != ''
            ? this.localization.sanitizeText(this.modalInstance.input.cancelButtonText, LocalizationService.SubSup)
            : '&nbsp;';
    }

    ngOnInit(): void {
        this.modalInstance.setOnClosing((result) => {
            // Dismissal prevented, e.g. shear load distribution type pop up.
            const preventDismiss = !this.canDismiss;
            if (preventDismiss && (result == MODAL_DISMISS_REASON_BACKDROP || result == MODAL_DISMISS_REASON_ESC)) {
                return false;
            }

            return true;
        });

        if (this.modalInstance.input?.additionalStyles) {
            const additionalStyles = document.createElement('style');
            additionalStyles.textContent = this.modalInstance.input.additionalStyles;

            const root = this.elementRef.nativeElement;
            root.insertBefore(additionalStyles, root.firstChild);
        }
    }

    public onConfirm() {
        this.modalInstance.input.onConfirm(this.modalInstance);
    }

    public onCancel() {
        this.modalInstance.input.onCancel(this.modalInstance);
    }

    public dismiss(reason?: string) {
        this.modalInstance.dismiss(reason);
    }

    public toggleShowMore() {
        this.hideAdditionalInfo = !this.hideAdditionalInfo;
    }

    private setHideAdditionalInfo() {
        const hideText = this.modalInstance.input?.hideText();
        if(hideText?.hideByDefault) {
            return true;
        }

        return false;
    }
}
