import some from 'lodash-es/some';

import { Component, OnInit } from '@angular/core';
import { Language } from '@profis-engineering/pe-ui-common/entities/code-lists/language';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { ButtonEventType } from '@profis-engineering/pe-ui-common/services/common-tracking.common';
import { Region } from '@profis-engineering/pe-ui-shared/entities/code-lists/region';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { CommonTrackingService } from '../../services/common-tracking.service';
import { LocalizationService } from '../../services/localization.service';
import { OfflineService } from '../../services/offline.service';
import { ProductInformationService } from '../../services/product-information.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';

export interface ComparisonTable {
    attributes: Attribute[],
    description: string,
    groups: Group[],
    items: License[],
    timestamp: string,
    title: string,
}

export interface Attribute {
    description: string,
    group: string,
    id: string,
    name: string
}

export interface Group {
    id: string,
    name: string
}

export interface License {
    additionalDescription: string;
    attributes: string[];
    description: string;
    name: string;
    productIdMonthly: string;
    productIdYearly: string;
    type: string;
    url: string;
    prices?: Price[];
}

export interface Price {
    currency: string,
    productId: string,
    subscriptionDetails: SubscriptionDetails
}

export interface SubscriptionDetails {
    unitPriceInformation: UnitPriceInformation
}

export interface UnitPriceInformation {
    yearlyPrice: number,
    yearlyPriceFormatted: string
    monthlyPrice: number,
    monthlyPriceFormatted: string
}

export interface ILicenseComparisonHolData {
    data: ComparisonTable;
    holLicenseLanguageId: number;
    isCheckbot: boolean;
}

@Component({
    selector: 'app-license-comparison-hol',
    templateUrl: './license-comparison-hol.component.html',
    styleUrls: ['./license-comparison-hol.component.scss']
})
export class LicenseComparisonHolComponent implements OnInit {
    public showYearlyPrices = true;
    comparisonTableData: ComparisonTable;
    submitted = false;
    private culture: string;

    private requiredTranslations = [
        'Agito.Hilti.Profis3.LicenseComparison.YearlySubcription',
        'Agito.Hilti.Profis3.LicenseComparison.MonthlySubcription',
        'Agito.Hilti.Profis3.TrialBanner.ContinueWithStandard.Button',
        'Agito.Hilti.Profis3.LicenseComparison.Buy',
        'Agito.Hilti.Profis3.Trial.Welcome.Title',
        'Agito.Hilti.Profis3.LicenseComparison'
    ];

    constructor(
        private localization: LocalizationService,
        private modalInstance: ModalInstance<ILicenseComparisonHolData>,
        private userSettings: UserSettingsService,
        private commonTrackingService: CommonTrackingService,
        private productInformation: ProductInformationService,
        private commonCodeList: CommonCodeListService,
        private offline: OfflineService,
        private user: UserService,
        private productInformationService: ProductInformationService
    ) { }

    async ngOnInit(): Promise<void> {
        this.culture = (this.commonCodeList.commonCodeLists[CommonCodeList.Language] as Language[]).find(x => x.id == this.modalInstance.input.holLicenseLanguageId).culture;
        await this.localization.selectTranslations(this.culture, this.requiredTranslations);

        // do not close modal if save is pending
        this.modalInstance.setOnClosing(() => {
            if (!this.submitted) {
                return false;
            }

            return true;
        });
    }

    public get isCheckbot() {
        return this.modalInstance?.input?.isCheckbot ?? false;
    }

    public get holData() {
        return this.modalInstance.input.data;
    }

    public get groups() {
        return this.holData.groups;
    }

    public get attributes() {
        return this.holData.attributes
    }

    public get licenses() {
        return this.holData.items.filter(x => !this.isTrialLicense(x))
    }

    private get buyLicenseHolLink() {
        return this.productInformationService.regionLinksForUser()?.BuyLicenseHolLink;
    }

    private get getRegionByCountryCode() {
        const regionCodeList = this.commonCodeList.commonCodeLists[CommonCodeList.Region] as Region[];
        return regionCodeList.find((item) => item.countryCode.toLowerCase() == this.user.authentication.country.toLowerCase())?.id;
    }

    public get comparisonRedirectUrl() {
        return this.productInformation.regionLinksForUser(this.getRegionByCountryCode)?.UpgradeToPremiumLink;
    }

    public get showSubscriptionSwitchButton() {
        return this.licenses.filter(x => !this.isStandardLicense(x)).every(x => x.productIdMonthly != '');
    }

    public subscriptionPrice(license: License) {
        return this.showYearlyPrices ? this.getYearlyPrice(license) : this.getMonthlyPrice(license)
    }

    public buyLicense(license: License) {
        if (this.buyLicenseHolLink == undefined || this.buyLicenseHolLink.trim() == '') {
            throw new Error('Buy license link is not specified')
        }

        const productId = this.showYearlyPrices ? license.productIdYearly : license.productIdMonthly;
        const comparisonRedirectUrl = this.buyLicenseHolLink.replace('{productId}', productId);

        if (!this.offline.isOffline) {
            window.open(comparisonRedirectUrl, '_blank');
        } else {
            this.offline.nativeExternalLinkOpen(comparisonRedirectUrl);
        }
    }
    public get subscriptionText() {
        return this.showYearlyPrices
            ? this.translate('Agito.Hilti.Profis3.LicenseComparison.YearlySubcription')
            : this.translate('Agito.Hilti.Profis3.LicenseComparison.MonthlySubcription');
    }
    public continueWithStandard() {
        // save continue clicked to user settings to skip opening license popup next time
        this.userSettings.settings.stayOnStandardLicense.value = true;
        this.userSettings.save();

        // track event
        this.commonTrackingService.trackOnButtonClick(ButtonEventType.ContinueWithStandard);

        this.submitted = true;
        this.close();
    }

    public translate(key: string) {
        return this.localization.getLocalizedStringByCulture(key, this.culture);
    }

    public getAttributesByGroupId(groupId: string): Attribute[] {
        const attributes = this.attributes.filter((item) => item.group == groupId);
        return attributes;
    }

    public getSprite(attributeId: string, license: License): string {
        return this.doesLicenseIncludeAttribute(attributeId, license) ? 'sprite-hilti-styled-notification-ok-small' : 'sprite-notification-alert-small';
    }

    public isPremiumLicense(license: License): boolean {
        return license.type.toLowerCase() == 'Premium'.toLowerCase();
    }

    public isStandardLicense(license: License): boolean {
        return license.type.toLowerCase() == 'Standard'.toLowerCase();
    }

    public isTrialLicense(license: License): boolean {
        return license.type.toLowerCase() == 'Trial'.toLowerCase();
    }

    private doesLicenseIncludeAttribute(attributeId: string, license: License): boolean {
        return some(license.attributes, (att) => att == attributeId);
    }

    private getYearlyPrice(license: License) {
        return license.prices?.find(x => x.productId == license.productIdYearly)?.subscriptionDetails?.unitPriceInformation?.yearlyPriceFormatted ?? "";
    }

    private getMonthlyPrice(license: License) {
        return license.prices?.find(x => x.productId == license.productIdMonthly)?.subscriptionDetails?.unitPriceInformation?.monthlyPriceFormatted ?? "";
    }

    private close() {
        this.modalInstance.close();
    }
}
