import escape from 'lodash-es/escape';

import { HttpErrorResponse, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
    ErrorDetails, IApplicationError
} from '@profis-engineering/pe-ui-common/entities/application-error';
import {
    MODAL_DISMISS_REASON_BACKDROP, MODAL_DISMISS_REASON_ESC, ModalInstance
} from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { ISignalRError } from '@profis-engineering/pe-ui-common/services/signalr.common';
import {
    MessageResponseType
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Common.Enums';

import { environment } from '../../../environments/environment';
import { ApiService } from '../../services/api.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { AuthenticationService } from '../../services/authentication.service';

export const enum AlertType {
    Unknown = 0,

    Warning = 1,
    Error = 2,

    GLError = 10,

    ServiceError = 20,

    SignalRError = 30,

    LicenseError = 40
}

export interface IAlertComponentInput {
    type: AlertType;
    title?: string;
    message?: string;
    applicationError?: IApplicationError;
}

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
    public title: string;
    public message: string;
    public showSupportButton = true;
    public showLogoutButton = false;
    public preventClose = false;

    private applicationError: IApplicationError;

    constructor(
        private apiService: ApiService,
        private localization: LocalizationService,
        private modal: ModalService,
        private userSettings: UserSettingsService,
        private authentication: AuthenticationService,
        private modalInstance: ModalInstance<IAlertComponentInput>
    ) { }

    ngOnInit(): void {
        // don't close the modal if save is pending
        this.modalInstance.setOnClosing((result) => {
            if (this.preventClose && (result == MODAL_DISMISS_REASON_BACKDROP || result == MODAL_DISMISS_REASON_ESC)) {
                return false;
            }

            return true;
        });

        // Get field values
        let title = '';
        let message = '';
        let applicationError: IApplicationError;
        if (this.modalInstance.input != null) {
            if (this.modalInstance.input.applicationError != null) {
                applicationError = this.modalInstance.input.applicationError;
            }

            switch (this.modalInstance.input.type) {
                case AlertType.Warning:
                    // Title and message already specified
                    // This is user-related error; do not show support button
                    title = this.modalInstance.input.title;
                    message = this.modalInstance.input.message;
                    this.showSupportButton = false;
                    break;

                case AlertType.Error:
                    // Title and message already specified
                    title = this.modalInstance.input.title;
                    message = this.modalInstance.input.message;
                    break;

                case AlertType.GLError:
                    // GL error
                    // Do not sshow support button, prevent close
                    title = this.localization.getString('Agito.Hilti.Profis3.3DErrorAlert.Title', { defaultString: 'ERROR' });
                    message = this.localization.getString('Agito.Hilti.Profis3.3DErrorAlert.Message', { defaultString: 'Your browser has disabled 3D rendering. Please wait a moment or refresh the website to continue.' });
                    this.showSupportButton = false;
                    this.preventClose = true;
                    break;

                case AlertType.ServiceError:
                    // Service error; title and message need to be obtained from applicationError
                    [title, message] = this.getServiceErrorFieldValues(applicationError);
                    break;

                case AlertType.SignalRError:
                    // SignalR error; title and message need to be obtained from applicationError
                    [title, message] = this.getSignalRErrorFieldValues(applicationError);
                    break;

                case AlertType.LicenseError:
                    // License error
                    // Show logout button
                    title = this.localization.getString('Agito.Hilti.Profis3.LicenseErrorAlert.Title', { defaultString: 'UNKNOWN LICENSE' });
                    message = this.localization.getString('Agito.Hilti.Profis3.LicenseErrorAlert.Message', { defaultString: 'Something went wrong with identifying your license type. Your are currently using standard license, if you think you should have a different type of license, please try to re-login in a few minutes or contact Hilti.' });
                    this.showSupportButton = false;
                    this.showLogoutButton = true;
                    break;

                case AlertType.Unknown:
                default:
                    break;
            }
        }

        // And set them
        this.setFieldValues(title, message, applicationError);
    }

    public close() {
        this.modalInstance.close();
    }

    public logout() {
        this.authentication.logout();
    }

    public openSupport() {
        // open modal for support after modal for alert is closed
        new Promise<void>((resolve, reject) => {
            try {
                this.close();
                resolve();
            }
            catch {
                reject();
            }
        })
            .then(() => this.modal.openSupport(this.applicationError))
            .catch((err: any) => console.error(err));
    }

    private getServiceErrorFieldValues(applicationError: IApplicationError) {
        if (applicationError.response instanceof Error) {
            // do not log the error into console (duplicate console log)
            // the one calling alert.open should log the error if needed
        }

        this.setErrorDetails(applicationError);

        if (applicationError.endPointUrl == null && applicationError.response instanceof HttpResponseBase) {
            applicationError.endPointUrl = applicationError.response.url;
        }

        const httpBody = applicationError.response instanceof HttpErrorResponse && applicationError.response.status > 0
            ? applicationError.response.error
            : applicationError.response instanceof HttpResponse
                ? applicationError.response.body
                : undefined;

        const defaultTitle = this.localization.getString('Agito.Hilti.Profis3.ServerErrorAlert.Title', { defaultString: 'ERROR' });

        // check if it's a message type
        if (httpBody != null
            && httpBody.Type != null
            && httpBody.Message != null
            && httpBody.Message != ''
        ) {
            return this.handleTitleBodyMessage(httpBody, defaultTitle);
        }

        const defaultMessage = this.localization.getString('Agito.Hilti.Profis3.ServerErrorAlert.Message', { defaultString: 'Please try again later. If the problem persists contact us at' });
        return this.handleGeneralError(httpBody, defaultTitle, defaultMessage, applicationError);
    }

    private handleTitleBodyMessage(httpBody: any, defaultTitle: string) {
        let title = httpBody.Title;
        let message = httpBody.Message;

        switch (httpBody.Type) {
            case MessageResponseType.Message:
                if (title == null || title == '') {
                    title = defaultTitle;
                }
                break;

            case MessageResponseType.Translation:
                message = this.localization.getString(message);

                if (title == null || title == '') {
                    title = defaultTitle;
                }
                else {
                    title = this.localization.getString(title);
                }
                break;

            default:
                throw new Error('Unsupported message response type.');
        }

        return [title, message];
    }

    private handleGeneralError(httpBody: any, defaultTitle: string, defaultMessage: string, applicationError: IApplicationError) {
        const region = this.userSettings.settingsLoaded
            ? this.userSettings.getCommonRegionById(this.userSettings.settings.application.general.regionId.value)
            : null;

        let contactUrl = null as string;
        if (region != null) {
            contactUrl = region.contactUrl;
        }
        contactUrl = (contactUrl == null || contactUrl == '') ? 'profis@hilti.com' : contactUrl.replace('mailto:', '');

        const title = defaultTitle;
        let message = escape(defaultMessage) + ' <span class="nowrap">' + escape(contactUrl) + '.</span>';
        let description = '';

        if (applicationError.response != null && applicationError.response instanceof HttpResponseBase) {
            const httpResponse = applicationError.response;
            // service failed statement
            let serviceDescription: string;
            if (httpResponse.url) {
                serviceDescription = this.localization
                    .getString('Agito.Hilti.Profis3.ServerErrorAlert.ServiceDescription')
                    .replace('{service}', this.apiService.getServiceName(httpResponse.url));
            }

            if (serviceDescription != null && serviceDescription != '') {
                description += serviceDescription;
            }

            // error description statement
            let errorDescription: string;
            if (httpResponse.status === 0) {
                // request was not sent at all - probably there is no internet connection
                errorDescription = this.localization.getString('Agito.Hilti.Profis3.ServerErrorAlert.NoConnectionDescription');
            }
            else if (httpResponse.status === 503 || httpResponse.status === 408) {
                // temporarily unavailable or request timed out - probably server is overloaded
                errorDescription = this.localization.getString('Agito.Hilti.Profis3.ServerErrorAlert.ServerOverloadDescription');
            }
            else if (httpBody?.Message
                && typeof httpBody.Message == 'string') {
                // message was returned, let's display it
                errorDescription = this.localization
                    .getString('Agito.Hilti.Profis3.ServerErrorAlert.ErrorDescription')
                    .replace('{error}', httpBody.Message);
            }

            if (errorDescription != null && errorDescription != '') {
                description += description == '' ? errorDescription : ' ' + errorDescription;
            }
        }

        // if there is additional description, prepend it to message
        if (description != null && description != '') {
            message = escape(description + '\n\n') + message;
        }

        return [title, message];
    }

    private getSignalRErrorFieldValues(applicationError: IApplicationError) {
        if (applicationError.response instanceof Error) {
            console.error(applicationError.response);
        }

        let errorMessage = null;
        let statusCode = null;
        let response = null;

        if (typeof applicationError.response == 'string') {
            response = applicationError.responsePayload;
        }
        else {
            response = applicationError.response;
        }

        if (response != null && (response as ISignalRError).data != undefined) {
            response = response as ISignalRError;
            errorMessage = response.message
                ? response.message
                : response.data.ExceptionMessage;
            statusCode = response.data.StatusCode
                ? response.data.StatusCode.toString()
                : null;
        }

        if (errorMessage != null || statusCode != null) {
            applicationError.errors = [{
                errorMessage,
                statusCode
            } as ErrorDetails];
        }

        const defaultTitle = this.localization.getString('Agito.Hilti.Profis3.ServerErrorAlert.Title', { defaultString: 'ERROR' });
        const defaultMessage = this.localization.getString('Agito.Hilti.Profis3.ServerErrorAlert.Message', { defaultString: 'Please try again later. If the problem persists contact us at' });

        const title = defaultTitle;
        let message: string;

        const serviceDescription = this.localization
            .getString('Agito.Hilti.Profis3.ServerErrorAlert.ServiceDescription')
            .replace('{service}', environment.signalRServiceName);
        message = escape(serviceDescription);

        if (applicationError.response != null) {
            if (typeof applicationError.response == 'string') {
                if (applicationError.response) {
                    message += '\n\n' + applicationError.response;
                }
            }
            else if ((applicationError.response as ISignalRError).message != undefined) {
                message += '\n\n' + (applicationError.response as ISignalRError).message;
            }
        }

        const region = this.userSettings.getCommonRegionById(this.userSettings.settings.application.general.regionId.value);

        let contactUrl = null as string;
        if (region != null) {
            contactUrl = region.contactUrl;
        }
        contactUrl = (contactUrl == null || contactUrl == '')
            ? 'info@info'
            : contactUrl.replace('mailto:', '');

        message += escape('\n\n' + defaultMessage) + ('\n' + escape(contactUrl));

        return [title, message];
    }

    private setFieldValues(title: string, message: string, applicationError: IApplicationError) {
        // Title
        this.title = title != null && title != ''
            ? escape(title)
            : '&nbsp;';


        // Message
        if (message == null || message == '') {
            this.message = '&nbsp;';
        }
        else if (this.localization.isHtml(message)) {
            message = message.replace(this.localization.htmlStartTagRegExp, '');
            this.message = message.replace(this.localization.htmlEndTagRegExp, '');
        }
        else {
            this.message = escape(message);
        }


        // Application error
        if (applicationError?.response != null) {
            if (applicationError.errors == null) {
                applicationError.errors = [];
            }

            if (applicationError.response instanceof Error) {
                if (applicationError.errors.length == 0) {
                    applicationError.errors.push({
                        errorMessage: applicationError.response.message,
                        errorStackTrace: applicationError.response.stack
                    });
                }
            }

            if (applicationError.endPointUrl == null
                && applicationError.response instanceof HttpResponseBase
            ) {
                applicationError.endPointUrl = applicationError.response.url;
            }

            if (applicationError.responsePayload == null && applicationError.response != null) {
                applicationError.responsePayload = applicationError.response;
            }
        }
        this.applicationError = applicationError;
    }

    private setErrorDetails(applicationError: IApplicationError) {
        if (applicationError == null) {
            return;
        }

        let errorMessage = null;
        let errorStackTrace = null;
        let statusCode = null;

        if (applicationError.response instanceof Error) {
            errorMessage = applicationError.response.message;
            errorStackTrace = applicationError.response.stack;
        }
        else if (applicationError.response instanceof HttpErrorResponse && applicationError.response.error instanceof Error) {
            const error = applicationError.response.error;

            errorMessage = error.message;
            errorStackTrace = error.stack;
        }
        else if (applicationError.response instanceof HttpErrorResponse && applicationError.response.status > 0) {
            const response = applicationError.response;

            errorMessage = response.error?.Message ?? response.statusText;
            statusCode = response.status.toString();
        }
        else if (applicationError.response instanceof HttpResponse) {
            const response = applicationError.response;

            errorMessage = response.body?.Message ?? response.statusText;
            statusCode = response.status.toString();
        }

        if (errorMessage != null || errorStackTrace != null || statusCode != null) {
            applicationError.errors = [{
                errorMessage,
                errorStackTrace,
                statusCode
            }];
        }
    }
}
