import * as React from 'react';

import { ControlHeader } from '../../ControlHeader';
import { ControlType } from '../../ControlInfo';
import { isControlHidden } from '../../MainMenuHelper';
import { ImageNameCheckbox } from '../ImageNameCheckbox/ImageNameCheckbox';
import { IImageNameCheckboxGroupProps, IItemsSortOrder } from '@profis-engineering/pe-ui-common/entities/main-menu/image-name-checkbox-group-props';
import { TooltipType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';

interface IImageNameCheckboxGroupState {
    isCollapsed: boolean;
}

export class ImageNameCheckboxGroup extends React.PureComponent<IImageNameCheckboxGroupProps, IImageNameCheckboxGroupState> {
    private readonly maxItems = 4;
    private readonly checkboxItemClassName = 'react-image-name-checkbox-control';

    private dragged: string;
    private oldIndex: number;
    private newIndex: number;

    constructor(props?: IImageNameCheckboxGroupProps) {
        super(props);

        this.onCollapseChange = this.onCollapseChange.bind(this);
        this.onItemCheck = this.onItemCheck.bind(this);

        this.onDragOver = this.onDragOver.bind(this);
        this.dragStarted = this.dragStarted.bind(this);
        this.dragEnded = this.dragEnded.bind(this);

        this.onInfoClick = this.onInfoClick.bind(this);

        this.state = {
            isCollapsed: true
        };
    }

    private get collapsable() {
        return this.props.items.length > this.maxItems;
    }

    private get items() {
        return this.collapsable && this.state.isCollapsed ?
            this.props.items.slice(0, this.maxItems) :
            this.props.items;
    }

    public override render() {
        if (isControlHidden(this.props)) {
            return null;
        }

        const groupId = `${this.props.controlId}-group`;

        const infoTooltipKey = 'Agito.Hilti.Profis3.ControlTooltip.Popup';

        return (
            <div
                data-control-id={groupId}
                className={`control ${this.checkboxItemClassName}-group ${this.props.sizeClass}`} >

                <div className='control-container'>
                    <ControlHeader
                        controlId={groupId}
                        text={this.props.title}
                        tooltip={this.props.tooltip}
                        tooltipTitle={this.props.tooltipTitle}
                        localization={this.props.localization}
                        infoClick={this.props.tooltipType == TooltipType.Popup ? this.onInfoClick : undefined}
                        infoTooltip={this.props.tooltipType == TooltipType.Popup ? infoTooltipKey : undefined}
                    />
                </div>
                <div
                    data-control-id={`${groupId}-radio-buttons`}
                    className='radio-buttons'
                    onDrop={this.onDrop}
                    onDragOver={this.onDragOver} >
                    {
                        this.items.map((item) =>
                            <ImageNameCheckbox
                                controlId={`${this.props.controlId}-${item.value}`}
                                className={this.checkboxItemClassName}
                                key={`${this.props.controlId}-${item.value}`}
                                value={item.value}
                                text={item.text}
                                image={item.image}
                                imageStyle={item.imageStyle}
                                checked={(this.props.checkedValue ?? []).includes(item.value)}
                                disabled={item.disabled || this.props.disabled}
                                hidden={this.props.hidden}
                                localization={this.props.localization}
                                dragStarted={this.dragStarted}
                                dragEnded={this.dragEnded}
                                size={null}
                                title={null}
                                type={ImageNameCheckbox}
                                checkedChanged={this.onItemCheck}
                                tooltip={item.tooltip}
                                tooltipTitle={item.tooltipTitle}
                                enableItemsSorting = {this.props.enableItemsSorting}
                            />)
                    }
                </div>

                {this.collapsable ? <ImageNameCheckboxGroupCollapse collapsed={this.state.isCollapsed} collapseChanged={this.onCollapseChange} /> : null}
            </div>
        );
    }

    private onInfoClick(event: React.MouseEvent) {
        if (this.props.infoClicked != null) {
            this.props.infoClicked();
        }
    }

    private onCollapseChange(collapsed: boolean) {
        this.setState({
            isCollapsed: collapsed
        });
    }

    private onItemCheck(checked: boolean, value: number) {
        if (this.props.valueChanged != null) {
            const checkValues = this.props.checkedValue ?? [];

            if (checked && !checkValues.includes(value)) {
                const checkedValue = checkValues.slice();
                checkedValue.push(value);
                this.props.valueChanged(checkedValue);
            }
            else if (!checked && checkValues.includes(value)) {
                const index = checkValues.indexOf(value);
                const checkedValue = checkValues.filter(item => item !== value);
                this.props.valueChanged(checkedValue);
            }
        }
    }

    private onDrop(event: React.DragEvent) {
        event.preventDefault();
    }

    private onDragOver(event: React.DragEvent) {
        if (this.dragged == null) {
            return;
        }

        event.preventDefault();

        const activeElement = (event.target as HTMLElement).closest('.' + this.checkboxItemClassName) as HTMLElement;
        if (activeElement != null && activeElement.dataset['dragId'] != this.dragged) {
            const itemsOrder = this.props.items.map((item) => item.value.toString());

            // save dragged element old and new position index
            this.oldIndex = itemsOrder.indexOf(this.dragged);
            this.newIndex = itemsOrder.indexOf(activeElement.dataset['dragId']);
        }
    }

    private dragStarted(draggedItem: string) {
        this.dragged = draggedItem;
    }

    private dragEnded() {
        if (this.dragged != null) {
            const initialItems = this.props.items.map((item, index) =>
                ({
                    id: item.value,
                    sortOrder: index + 1
                }) as IItemsSortOrder);

            this.fixSortOrderAfterItemDragged(initialItems);

            // save new sort order
            const newSortOrder = initialItems
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map(item => item.id);



            if (this.props.sortOrderChanged != null) {
                this.props.sortOrderChanged(newSortOrder, this.props.parentId);
            }
        }

        this.dragged = null;
    }

    private fixSortOrderAfterItemDragged(initialItems: IItemsSortOrder[]) {
        // save sort order of new position -> drag to target
        const targetSortOrder = initialItems[this.newIndex].sortOrder;

        // in case we drag item from bottom to top in list
        if (this.oldIndex > this.newIndex) {
            // insert new item location in current sort order
            for (let i = this.newIndex; i < this.oldIndex + 1; i++) {
                if (initialItems[i].sortOrder != null) {
                    initialItems[i].sortOrder++;
                }
            }
        }

        // in case we drag item from top to bottom in list
        if (this.newIndex > this.oldIndex) {
            // insert new item location in current sort order
            for (let i = this.oldIndex; i < this.newIndex + 1; i++) {
                if (initialItems[i].sortOrder != null) {
                    initialItems[i].sortOrder--;
                }
            }
        }

        initialItems[this.oldIndex].sortOrder = targetSortOrder;
    }
}

ControlType.ImageNameCheckboxGroup = ImageNameCheckboxGroup;

interface IImageNameCheckboxGroupCollapseProps {
    collapsed: boolean;
    collapseChanged: (collapsed: boolean) => void;
}

class ImageNameCheckboxGroupCollapse extends React.PureComponent<IImageNameCheckboxGroupCollapseProps> {
    constructor(props?: IImageNameCheckboxGroupCollapseProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    public override render() {
        const openedClass = !this.props.collapsed ? 'opened' : '';

        return (
            <div className={`design-right-collapse-container ${openedClass}`}>
                <span className='design-right-collapse-line'></span>
                <button type='button' className='design-right-collapse-button' onClick={this.onClick}>
                    <span className='sprite design-right-collapse-image'></span>
                </button>
                <span className='design-right-collapse-line'></span>
            </div>
        );
    }

    private onClick(event: React.MouseEvent) {
        if (this.props.collapseChanged != null) {
            this.props.collapseChanged(!this.props.collapsed);
        }
    }
}
