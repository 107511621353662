import * as React from 'react';
import { Subscription } from 'rxjs';

import {
    DataIntegrationType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Enums';

import { IntegrationsConnectionStatus } from '../../../../services/integrations-data.service';
import { IImportExportProps } from '../../MainMenu';
import { Button } from '../Button/Button';
import { ButtonSize, ButtonType } from '@profis-engineering/pe-ui-common/entities/main-menu/button-props';

export interface IDlubalImportExportProps extends IImportExportProps {
    importClick: () =>  void;
    exportClick: () =>  void;
}

interface IDlubalImportExportState {
    dlubalStatus?: IntegrationsConnectionStatus;
}

export class DlubalImportExport extends React.PureComponent<IDlubalImportExportProps, IDlubalImportExportState> {
    private statusChangedSubscription: Subscription;

    constructor(props?: IDlubalImportExportProps) {
        super(props);
        this.onTryAgainClick = this.onTryAgainClick.bind(this);

        const { integrationsData } = this.props;

        this.state = {
            dlubalStatus: integrationsData.dlubalStatus
        };

        this.statusChangedSubscription = integrationsData.statusChanged.subscribe(({ type, status }) => {
            if (type == DataIntegrationType.Dlubal) {
                this.setState({ dlubalStatus: status });
            }
        });
    }

    public override render() {
        if (this.props.hidden) {
            return null;
        }

        const { dlubalStatus } = this.state;

        let content: React.ReactNode = null;

        if (dlubalStatus == IntegrationsConnectionStatus.oldVersionDetected) {
            content = (
                <div className='error-container'>
                    <div className='try-again-text' dangerouslySetInnerHTML={{ __html: this.props.oldVersionDetectedText }}></div>
                </div>
            );
        }
        else if (dlubalStatus == IntegrationsConnectionStatus.pending || dlubalStatus == IntegrationsConnectionStatus.connected) {
            content = (
                <div className='buttons-container'>
                    <Button
                        controlId={`${this.props.controlId}-import-button`}
                        className='dlubal-button import-button'
                        buttonSize={ButtonSize.Full}
                        buttonType={ButtonType.Default}
                        disabled={this.props.disabled}
                        hidden={false}
                        image={null}
                        text={this.props.importButtonText}
                        type={Button}
                        title={null}
                        size={null}
                        localization={this.props.localization}
                        clicked={this.props.importClick.bind(this)}
                        loading={dlubalStatus == IntegrationsConnectionStatus.pending}
                    />

                    {dlubalStatus == IntegrationsConnectionStatus.connected &&
                        <Button
                            controlId={`${this.props.controlId}-export-button`}
                            className='dlubal-button export-button'
                            buttonSize={ButtonSize.Full}
                            buttonType={ButtonType.Default}
                            disabled={this.props.disabled}
                            hidden={false}
                            image={null}
                            text={this.props.exportButtonText}
                            type={Button}
                            title={null}
                            size={null}
                            localization={this.props.localization}
                            clicked={this.props.exportClick.bind(this)}
                        />
                    }
                </div>
            );
        }
        else {
            content = (
                <div className='error-container'>
                    <Button
                        controlId={`${this.props.controlId}-try-again-button`}
                        className='dlubal-button try-again-button'
                        buttonSize={ButtonSize.Full}
                        buttonType={ButtonType.Default}
                        disabled={false}
                        hidden={false}
                        image={null}
                        text={this.props.tryAgainButtonText}
                        type={Button}
                        title={null}
                        size={null}
                        localization={this.props.localization}
                        clicked={this.onTryAgainClick}
                    />

                    {dlubalStatus == IntegrationsConnectionStatus.notConnected ?
                        <div className='try-again-text' dangerouslySetInnerHTML={{ __html: this.props.notConnectedText }}></div>
                        :
                        <div className='try-again-text'>{this.props.errorText}</div>
                    }
                </div>
            );
        }

        return (
            <div data-control-id={this.props.controlId} className='control react-dlubal-import-export-control size-100'>
                {content}
            </div>
        );
    }

    public override componentWillUnmount() {
        this.statusChangedSubscription.unsubscribe();
    }

    private onTryAgainClick() {
        this.props.integrationsData.refreshIntegrationAvailability(DataIntegrationType.Dlubal);
    }
}
