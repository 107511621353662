import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { Project } from '../entities/project';
import { DesignTemplateFolderDetail } from '../components/home-page/template-folder';
import { DesignTemplateService } from '../services/design-template.service';
import { DocumentService } from '../services/document.service';

export function designNameValidator(
    project: Project,
    oldProject: Project,
    oldName: string,
    documentService: DocumentService,
    errorMessage: string,
    isNewHomePage?: boolean
): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const designName = control.value ?? '';
        let valid = true;
        project = (project) ? project : oldProject
        if (
            project == null
            || document == null
            ||
            (
                oldName != null
                && designName.toLowerCase().trim() == oldName.toLowerCase().trim()
                && project == oldProject
            )
        ) {
            return null;
        }
        if (isNewHomePage) {
            const designs = documentService.findAllDesignsByProject(project);
            valid = !Object.values(designs).some((d) => d.designName.toLowerCase().trim() == designName.toLowerCase().trim());
        } else {
            const parentProject = project.parentId
                ? documentService.findProjectById(project.parentId)
                : project;

            valid = !Object.values(documentService.designsFlat).some((d) => d.projectId == parentProject.id && d.designName.toLowerCase().trim() == designName.toLowerCase().trim());
            if (valid) {
                for (const subProjectid in parentProject.subProjects) {
                    if (Object.values(documentService.designsFlat).some((d) => d.projectId == subProjectid && d.designName.toLowerCase().trim() == designName.toLowerCase().trim())) {
                        valid = false;
                        break;
                    }
                }
            }
        }
        if (!valid) {
            return {
                name: errorMessage
            };
        }

        return null;
    };
}

export function templateNameValidator(
    templateFolder: DesignTemplateFolderDetail,
    oldName: string,
    templateService: DesignTemplateService,
    errorMessage: string,
    oldTemplateFolder?: DesignTemplateFolderDetail
) {
    return (control: AbstractControl): ValidationErrors | null => {
        const newDesignTemplateName  = control.value ?? '';
        let valid = true;
        if(!templateFolder) {
            templateFolder = oldTemplateFolder;
        }
        if (oldName != null
            && newDesignTemplateName.toLowerCase().trim() == oldName.toLowerCase().trim()
            && templateFolder == oldTemplateFolder) {
            return null;
        }
        valid = !Object.values(templateService.templateV2).some(
            (template) =>
                (!templateFolder && template.templateFolderId == null && template.DesignTemplateName.toLowerCase().trim() == newDesignTemplateName.toLowerCase().trim())
                || (templateFolder && template.templateFolderId == templateFolder.templateFolderId && template.DesignTemplateName.toLowerCase().trim() == newDesignTemplateName.toLowerCase().trim())
        );

        if (!valid) {
            return {
                name: errorMessage
            };
        }

        return null;
    };
}
