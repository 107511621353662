<div class="trimble-connect">
    <button [disabled]="importing || disabled"
        id="import-design-trimble-connect-button" class="button button-default enabled"
        (click)="importTrimbleConnect()"
        l10n="Agito.Hilti.Profis3.ProjectAndDesing.ImportDesign.TrimbleConnectImport" type="button"
        *ngIf="trimbleConnect.isEnabled && browser.isOfflineOnLine">
    </button>
    <div class="button button-default offline"
        disabled
        l10n="Agito.Hilti.Profis3.ProjectAndDesing.ImportDesign.TrimbleConnectImport"
        [ngbTooltip]="localization.getLocalizedString('Agito.Hilti.Profis3.Browser.NoInternetConnection')"
        [openDelay]="0" [disableTooltip] = "!trimbleConnect.isEnabled || browser.isOfflineOnLine" *ngIf="!trimbleConnect.isEnabled || !browser.isOfflineOnLine">
    </div>
    <div class="drag-drop-box" [ngClass]="{ 'over': over, 'show-loading-text': importing }" [ngClass]="importing ? 'disabled-drag-box' : ''"
        (click)="containerClick()" id="drag-and-drop-box-button">
        <div class="import-trimble container"  disabled="importing || disabled">
            <div class="import-icon-container">
                <span class="sprite sprite-upload-up-arrow content"></span>
            </div>
            <div class="inner-content">
                <div>
                    <span class="text"  class="content-message" l10n="{{contentMessage}}"></span>&nbsp;
                    <a href="javascript:void(0);"  loading="importing" (click)="selectImportFile()" class="choose-file" id="import-design-button"><span class="text" class="content-message" l10n="{{chooseFileText}}"></span></a><br>
                    <span class="text"  class="supported-files" l10n="{{supportedFileTypeMessage}}"></span>
                </div>
            </div>
            <div class="inprogress">
                <pe-loading-button  [loading]="importing && mode != ImportDesignMode.compact"></pe-loading-button>
            </div>
        </div>
        <div class="loading-container compact-loading" *ngIf="mode == ImportDesignMode.compact && importing">
                <pe-loading [color]="'#524f53'"></pe-loading>
        </div>
        <input #importDesignRef class="import-design-input"  (change)="importFileSelected()" name="project" type="file"
        [accept]="allowedExtensions" (click)="$event.stopPropagation();" />
    </div>
</div>
<div id="integration" class="box-section-content" >
    <div class="buttons" *ngIf="hasIntegrationDocuments()">
        <div class="notification-container notification-container-with-button notification-info"
            *ngFor="let risaDocument of integrationsDocumentService.RisaDocuments">
            <div class="notification-content-wrapper">
                <div class="notification-content notification-content-text"
                [innerHTML]="integrationsDocumentService.exportDocumentReportButtonMessage(risaDocument.DocumentName, DocumentIntegrationType.Risa)">
                    <!-- There is a new RISA update for:211306 - STR - HERKIMER@(4 T)_L1_F -->
                </div>
            </div>
            <div class="notification-content notification-button">
                <button class="button button-default button-margin-top"
                    l10n="Agito.Hilti.Profis3.Notifications.ExportRisa.ButtonContent"
                    (click)="openNewDesignFromIntegrationDocument(risaDocument, DocumentIntegrationType.Risa);" [disabled]="pendingAction != null"
                    type="button"></button>
            </div>
        </div>
        <div class="notification-container notification-container-with-button notification-info"
            *ngFor="let ramDocument of integrationsDocumentService.RamDocuments">
            <div class="notification-content-wrapper">
                <div class="notification-content notification-content-text"
                    [innerHTML]="integrationsDocumentService.exportDocumentReportButtonMessage(ramDocument.DocumentName, DocumentIntegrationType.Ram)">
                </div>
            </div>
            <div class="notification-content notification-button">
                <button class="button button-default button-margin-top"
                    l10n="Agito.Hilti.Profis3.Notifications.ExportRam.ButtonContent"
                    (click)="openNewDesignFromIntegrationDocument(ramDocument, DocumentIntegrationType.Ram);" [disabled]="pendingAction != null"
                    type="button"></button>
            </div>
        </div>
    </div>
</div>