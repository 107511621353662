// C2C-10498: cleanup needed!
import {
    UIProperty as UIPropertyC2C
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { PropertyMetaDataC2C } from '@profis-engineering/pe-ui-c2c/properties/properties';
import { UnitGroup, UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

/**
 * @deprecated DO NOT USE FOR NEW CODE. Overridden in pe-ui-pe and pe-ui-c2c.
 */
export class UnitHelperC2C {
    public getPrecision_Loads_C2C_Zone_Base(unit: Unit) {
        switch (unit) {
            case Unit.Nmm2:
            case Unit.Nm2:
            case Unit.kgfcm2:
                return 6;
            default:
                return 0;
        }
    }

    public getPrecision_Loads_C2C_SeismicLoadMultiplierValue() {
        return 1;
    }

    public getPrecision_Loads_C2C_NormalStress(unit: Unit) {
        switch (unit) {
            case Unit.Nmm2:
            case Unit.PSI:
            case Unit.KSI:
            case Unit.kNm2:
            case Unit.Nm2:
            case Unit.kgfcm2:
                return 2;
            default:
                return 0;
        }
    }

    public getPrecision_C2C_UnitStressBase(unit: Unit) {
        switch (unit) {
            case Unit.PSI:
            case Unit.KSI:
            case Unit.Nmm2:
            case Unit.kNm2:
            case Unit.Nm2:
            case Unit.kgfcm2:
                return 1;
            default:
                return 0;
        }
    }

    public getPrecision_Loads_C2C_Zone1 = this.getPrecision_Loads_C2C_Zone_Base;
    public getPrecision_Loads_C2C_Zone2 = this.getPrecision_Loads_C2C_Zone_Base;
    public getPrecision_Loads_C2C_Zone3 = this.getPrecision_Loads_C2C_Zone_Base;
    public getPrecision_Loads_C2C_Zone4 = this.getPrecision_Loads_C2C_Zone_Base;
    public getPrecision_Loads_C2C_ZoneGeneric = this.getPrecision_Loads_C2C_Zone_Base;
    public getPrecision_Loads_C2C_DynamicZone1 = this.getPrecision_Loads_C2C_Zone_Base;
    public getPrecision_Loads_C2C_DynamicZone2 = this.getPrecision_Loads_C2C_Zone_Base;
    public getPrecision_Loads_C2C_DynamicZone3 = this.getPrecision_Loads_C2C_Zone_Base;
    public getPrecision_Loads_C2C_DynamicZone4 = this.getPrecision_Loads_C2C_Zone_Base;
    public getPrecision_Loads_C2C_DynamicZoneGeneric = this.getPrecision_Loads_C2C_Zone_Base;
    public getPrecision_Loads_C2C_NormalStressACI = this.getPrecision_Loads_C2C_NormalStress;
    public getPrecision_Loads_C2C_NormalStressCSA = this.getPrecision_Loads_C2C_NormalStress;
    public getPrecision_Overlay_C2C_BaseMaterial_ExistingStructure_FcCube = this.getPrecision_BaseMaterial_CubeStrength;
    public getPrecision_Overlay_C2C_BaseMaterial_ExistingStructure_FcCyl = this.getPrecision_BaseMaterial_CubeStrength;
    public getPrecision_Overlay_C2C_BaseMaterial_NewStructure_FcCube = this.getPrecision_BaseMaterial_CubeStrength;
    public getPrecision_Overlay_C2C_BaseMaterial_NewStructure_FcCyl = this.getPrecision_BaseMaterial_CubeStrength;
    public getPrecision_ExistingStructure_C2C_BaseMaterial_FcCube = this.getPrecision_BaseMaterial_CubeStrength;
    public getPrecision_ExistingStructure_C2C_BaseMaterial_FcCyl = this.getPrecision_BaseMaterial_CubeStrength;
    public getPrecision_ExistingStructure_C2C_BaseMaterial_NewFcCube = this.getPrecision_BaseMaterial_CubeStrength;
    public getPrecision_ExistingStructure_C2C_BaseMaterial_NewFcCyl = this.getPrecision_BaseMaterial_CubeStrength;
    public getPrecision_NewStructure_C2C_AdditionalInformation_TransversePressure = this.getPrecision_C2C_UnitStressBase;
    public getPrecision_Reinforcement_C2C_LongitudinalGrade = this.getPrecision_C2C_UnitStressBase;
    public getPrecision_Reinforcement_C2C_CastInSplicedGrade = this.getPrecision_C2C_UnitStressBase;
    public getPrecision_Reinforcement_C2C_ReinforcementYieldStrengthFyk = this.getPrecision_C2C_UnitStressBase;
    public getPrecision_Loads_C2C_MaxAmplitude = this.getPrecision_C2C_UnitStressBase;
    public getPrecision_Loads_C2C_MaxSteelStress = this.getPrecision_C2C_UnitStressBase;
    public getPrecision_ExistingStructure_C2C_TransversePressure = this.getPrecision_C2C_UnitStressBase;
    public getPrecision_Loads_C2C_TedX = this.getPrecision_Loads_C2C_Zone_Base;
    public getPrecision_Loads_C2C_TedY = this.getPrecision_Loads_C2C_Zone_Base;
    public getPrecision_Option_C2C_EpsilonC2 = this.getPrecision_forGeneralValue;
    public getPrecision_Option_C2C_EpsilonCU2 = this.getPrecision_forGeneralValue;
    public getPrecision_Option_C2C_EpsilonUD = this.getPrecision_forGeneralValue;
    public getPrecision_Options_C2C_AS_EpsilonUd = this.getPrecision_forGeneralValue;
    public getPrecision_Loads_C2C_SeismicLoadMultiplier = this.getPrecision_Loads_C2C_SeismicLoadMultiplierValue;

    public getPrecision(unit: Unit, uiProperty?: UIPropertyC2C) {
        let extraPrecision = 0;

        if (uiProperty != null) {
            let precisionFunctionName: string;

            if (PropertyMetaDataC2C.getById(uiProperty) != null) {
                precisionFunctionName = 'getPrecision_' + PropertyMetaDataC2C.getById(uiProperty).name;
            }

            if ((this as any)[precisionFunctionName] != null) {
                extraPrecision = (this as any)[precisionFunctionName](unit);
            }
        }

        return this.getUnitOnlyPrecision(unit) + extraPrecision;
    }


    private getPrecision_BaseMaterial_CubeStrength(unit: Unit) {
        switch (unit) {
            case Unit.Nmm2:
                return 1;
            case Unit.PSI:
                return 1;
            case Unit.KSI:
                return 1;
            default:
                return 0;
        }
    }

    private getPrecision_forGeneralValue() {
        return 2;
    }

    private getUnitOnlyPrecision(unit: Unit) {
        if (this.getUnitGroupFromUnit(unit) == UnitGroup.ForcePerLength) {
            // force per length
            // always 2
            return 2;
        }

        if (this.getUnitGroupFromUnit(unit) == UnitGroup.MomentPerLength) {
            // moment per length
            // always 2
            return 2;
        }

        switch (unit) {
            case Unit.mm:
            case Unit.mm2:
            case Unit.Nmm2:
            case Unit.Nm2:
            case Unit.PSI:
            case Unit.kNm2:
            case Unit.kgfcm2:
            case Unit.PSF:
            case Unit.N:
            case Unit.kgf:
            case Unit.lb:
            case Unit.Nm:
            case Unit.in_lb:
            case Unit.kgfcm:
            case Unit.C:
            case Unit.F:
            case Unit.mm2_m:
                return 0;
            case Unit.cm:
            case Unit.daN:
            case Unit.daNm:
            case Unit.degree:
            case Unit.m_s:
                return 1;
            case Unit.cm2:
            case Unit.percent:
            case Unit.kg_m3:
            case Unit.lb_ft3:
            case Unit.cm2_m:
            case Unit.inch2_ft:
            case Unit.None:
            case Unit.ft:
                return 2;
            case Unit.inch:
            case Unit.kN:
            case Unit.Kip:
            case Unit.kNm:
            case Unit.ft_lb:
                return 3;
            case Unit.m:
            case Unit.ft2:
                return 4;
            case Unit.ft_kip:
                return 5;
            case Unit.mi:
            case Unit.inch2:
            case Unit.in_kip:
            case Unit.rad:
                return 6;

            default:
                return 6;
        }
    }

    private getUnitGroupFromUnit(unit: Unit) {
        return (Math.floor(unit / 100) * 100) as UnitGroup;
    }
}
