import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { IDeckingDesignListInfo } from '@profis-engineering/pe-ui-decking/src/decking/entities/decking-design-list-info';
import { GuardFactory } from '../../guards/combined.guard';
import { DesignTypeId, ModulesService } from '../../services/modules.service';

const checkModuleTimeoutMs = 10000;
const checkModulePollingMs = 100;

export const deckingMainGuard: GuardFactory = (handleGuardAction) => {
    const modulesService = inject(ModulesService);
    const router = inject(Router);

    // Emulates functionality from main.guard.ts, waits for module methods
    async function checkDesignListInfoUntilTimeout(timeoutInMs: number, intervalInMs: number): Promise<IDeckingDesignListInfo> {
        const startTime = Date.now();

        const getDeckingDesignListInfo = () => modulesService.getDesignListInfoByDesignType(DesignTypeId.DiaphragmDesign) as IDeckingDesignListInfo;
        let value = getDeckingDesignListInfo();

        const subscription = modulesService.designListInfoProvided.subscribe(() => {
            value = getDeckingDesignListInfo();
        });

        while (value == null && Date.now() - startTime < timeoutInMs) {
            await new Promise(resolve => setTimeout(resolve, intervalInMs));
        }

        subscription.unsubscribe();
        return value;
    }

    return (route: ActivatedRouteSnapshot) => handleGuardAction(async () => {
        const deckingDesignListInfo = await checkDesignListInfoUntilTimeout(checkModuleTimeoutMs, checkModulePollingMs);
        const result = await deckingDesignListInfo.handleDeckingMainGuard(route.paramMap.get('id'));
        return result ? true : router.createUrlTree(['/']);
    });
};
