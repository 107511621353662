<div class="trimble-connect-browser-tree-node noselect"
    [class.no-folders]="hasNoFolders"
    [class.selected]="isSelected"
    [class.hidden]="item == null"
    [class.loading]="loading">
    <div class="tree-node"
        [ngStyle]="{ 'padding-left': (level * 8) + 'px' }"
        (click)="itemClick()"
        (dblclick)="expand($event)">
        <button class="expand-button"
            type="button"
            (click)="expand($event)" >
            <span class="image expand-image sprite"
                [class.sprite-arrow-down]="expanded"
                [class.sprite-arrow-right]="!expanded"></span>
        </button>

        <span class="image folder-image sprite sprite-folder"></span>
        <p class="name">{{item?.name}}</p>
    </div>

    <div class="tree-loading" *ngIf="loading && expanded"
        [ngStyle]="{ 'margin-left': (level * 8 + 36) + 'px' }">
        <pe-loading [width]="32" [height]="10" [color]="'#524f53'"></pe-loading>
    </div>

    <div *ngIf="folderItems != null"
        class="trimble-connect-browser-tree-wrapper"
        [class.expanded]="expanded">
        <div *ngFor="let item of folderItems; trackBy: trackTreeNodeById">
            <app-trimble-connect-browser-tree-node
                [item]="item"
                [context]="context"
                [expanded]="false"
                [level]="level + 1">
            </app-trimble-connect-browser-tree-node>
        </div>
    </div>
</div>
