import sortBy from 'lodash-es/sortBy';

import { Component, Input, OnChanges } from '@angular/core';
import {
    CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import {
    StructuralCalculationSoftware
} from '@profis-engineering/pe-ui-common/entities/code-lists/structural-calculation-software';
import {
    StructuralCalculationSoftware as StructuralCalculationSoftwareType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    CommonCodeList
} from '@profis-engineering/pe-ui-common/services/common-code-list.common';

import { IStructuralCalculationSoftwareData } from '../../helpers/app-settings-helper';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { LocalizationService } from '../../services/localization.service';
import { RadioButtonProps } from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import { FeatureVisibilityService } from '../../services/feature-visibility.service';

@Component({
    selector: 'app-app-settings-structural-calculation-software',
    templateUrl: './app-settings-structural-calculation-software.component.html',
    styleUrls: ['./app-settings-structural-calculation-software.component.scss']
})
export class AppSettingsStructuralCalculationSoftwareComponent implements OnChanges {
    @Input()
    public submitted!: boolean;

    @Input()
    public structuralInterfacesValues!: IStructuralCalculationSoftwareData;

    public structuralCalculationSoftwares!: CheckboxButtonProps<StructuralCalculationSoftwareType>;
    public dataLoaded = false;

    constructor(
        private localization: LocalizationService,
        private commonCodeList: CommonCodeListService,
        private featuresVisibilityInfo: FeaturesVisibilityInfoService,
        private featuresVisibilityService: FeatureVisibilityService
    ) { }

    public checkbotLvl2!: RadioButtonProps<boolean>;
    public get complexDesignsAvailable() {
        return this.featuresVisibilityService.isFeatureEnabled('PE_Checkbot20');
    }

    ngOnChanges(): void {
        if (!this.dataLoaded) {
            this.checkbotLvl2 = {
                id: 'default-calculation-method-2',
                title: 'Checkbot Lvl 2',
                items: [
                    {
                        text: 'Enabled',
                        value: true,
                        id: `checkbot-lvl-2-true`
                    },
                    {
                        text: 'Disabled',
                        value: false,
                        id: `checkbot-lvl-2-false`
                    }
                ],
                selectedValue: this.structuralInterfacesValues.checkbotLvl2Enabled
            }
            const available = this.commonCodeList.commonCodeLists[CommonCodeList.StructuralCalculationSoftware] as StructuralCalculationSoftware[];
            this.structuralCalculationSoftwares = {
                items: sortBy(available, x => x.sortOrder).map(x => ({
                    id: `application-settings-interfaces-to-structural-calculation-software-${x.id}`,
                    text: this.localization.getString(x.nameResourceKey ?? ''),
                    value: x.id ?? 0,
                    disabled: this.interfacesToStructuralCalculationSoftwareDisabled(x.id),
                    tooltip: this.interfacesToStructuralCalculationSoftwareDisabled(x.id)
                        ? this.localization.getString('Agito.Hilti.Profis3.Features.Application.Settings.InterfacesToStructuralCalculationSoftware.Tooltip')
                        : undefined
                })),
                // selectedValues are set in setInitialStructuralCalculationSoftwareEnabled
                selectedValues: new Set()
            };

            available.forEach(el => {
                this.setInitialStructuralCalculationSoftwareEnabled(el.id);
            });

            this.dataLoaded = true;
        }
    }

    public onCheckboxChange(structuralInterfaces: Set<StructuralCalculationSoftwareType>) {
        this.structuralCalculationSoftwares.selectedValues = structuralInterfaces;

        this.structuralInterfacesValues.dlubalEnabled = structuralInterfaces.has(StructuralCalculationSoftwareType.Dlubal);
        this.structuralInterfacesValues.etabsEnabled = structuralInterfaces.has(StructuralCalculationSoftwareType.ETABS);
        this.structuralInterfacesValues.risaEnabled = structuralInterfaces.has(StructuralCalculationSoftwareType.Risa);
        this.structuralInterfacesValues.robotEnabled = structuralInterfaces.has(StructuralCalculationSoftwareType.Robot);
        this.structuralInterfacesValues.sap2000Enabled = structuralInterfaces.has(StructuralCalculationSoftwareType.SAP2000);
        this.structuralInterfacesValues.staadProEnabled = structuralInterfaces.has(StructuralCalculationSoftwareType.StaadPro);
        this.structuralInterfacesValues.ramEnabled = structuralInterfaces.has(StructuralCalculationSoftwareType.RAM);
    }

    private interfacesToStructuralCalculationSoftwareDisabled(type?: StructuralCalculationSoftwareType) {
        return !this.featuresVisibilityInfo.areIntegrationsEnabled && type != StructuralCalculationSoftwareType.RAM;
    }

    private setInitialStructuralCalculationSoftwareEnabled(type?: StructuralCalculationSoftwareType): void {
        // If integrations are disabled, disable the specific integration by setting it to false
        if (type && this.interfacesToStructuralCalculationSoftwareDisabled(type)) {
            this.structuralCalculationSoftwares.selectedValues?.delete(type);
            return;
        }

        // If a setting for a software does not exists and user's region is one of those that require the software to be enabled by default, we must enable it
        // Otherwise we take the setting value or false to leave the check box unchecked
        switch (type) {
            case StructuralCalculationSoftwareType.Risa:
                this.addOrRemoveStructuralCalculationSoftware(this.structuralInterfacesValues.risaEnabled, StructuralCalculationSoftwareType.Risa);
                break;
            case StructuralCalculationSoftwareType.Dlubal:
                this.addOrRemoveStructuralCalculationSoftware(this.structuralInterfacesValues.dlubalEnabled, StructuralCalculationSoftwareType.Dlubal);
                break;
            case StructuralCalculationSoftwareType.SAP2000:
                this.addOrRemoveStructuralCalculationSoftware(this.structuralInterfacesValues.sap2000Enabled, StructuralCalculationSoftwareType.SAP2000);
                break;
            case StructuralCalculationSoftwareType.Robot:
                this.addOrRemoveStructuralCalculationSoftware(this.structuralInterfacesValues.robotEnabled, StructuralCalculationSoftwareType.Robot);
                break;
            case StructuralCalculationSoftwareType.ETABS:
                this.addOrRemoveStructuralCalculationSoftware(this.structuralInterfacesValues.etabsEnabled, StructuralCalculationSoftwareType.ETABS);
                break;
            case StructuralCalculationSoftwareType.StaadPro:
                this.addOrRemoveStructuralCalculationSoftware(this.structuralInterfacesValues.staadProEnabled, StructuralCalculationSoftwareType.StaadPro);
                break;
            case StructuralCalculationSoftwareType.RAM:
                this.addOrRemoveStructuralCalculationSoftware(this.structuralInterfacesValues.ramEnabled, StructuralCalculationSoftwareType.RAM);
                break;
            default:
                throw new Error('Unknown calculation software type.');
        }
    }

    private addOrRemoveStructuralCalculationSoftware(enabled: boolean, data: StructuralCalculationSoftwareType): void {
        if (enabled) {
            this.structuralCalculationSoftwares.selectedValues?.add(data);
        }
        else {
            this.structuralCalculationSoftwares.selectedValues?.delete(data);
        }
    }
}
