import { Component, OnInit } from '@angular/core';
import { DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { Project } from '../../../entities/project';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { LocalizationService } from '../../../services/localization.service';
import { DocumentService } from '../../../services/document.service';
import { TextBoxProps } from '@profis-engineering/pe-ui-common/components/text-box/text-box.common';
import { NgForm, Validators } from '@angular/forms';
import { ModulesService } from '../../../services/modules.service';
import { designNameValidator } from '../../../validators/design-name.validator';
import { OfflineService } from '../../../services/offline.service';
import { ModalDialogType } from '../../add-edit-design/add-edit-design-models';
import { ModalService } from '../../../services/modal.service';
import { DesignTemplateEntity } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.DesignTemplate';
import { createDesignName } from '../../../helpers/design-name-helper';
import { UserService } from '../../../services/user.service';
import { RadioButtonProps } from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';

export interface IDesignFromTemplateComponentInput {
    template?: DesignTemplateEntity;
}

export enum ProjectType{
    MyProject,
    CompanyProject
}
const NewProjectId = '0';

@Component({
    selector: 'app-design-from-template',
    templateUrl: './design-from-template.component.html',
    styleUrls: ['./design-from-template.component.scss']
})
export class DesignFromTemplateComponent implements OnInit {
    public projectDropdown: DropdownProps<Project>;
    public newProject: string;
    public submitted = false;
    public nameTextBox: TextBoxProps;
    public requiredValidator = Validators.required;
    public projectType!: RadioButtonProps<ProjectType>;

    constructor(
        public modalInstance: ModalInstance<IDesignFromTemplateComponentInput>,
        public localization: LocalizationService,
        private document: DocumentService,
        private offline: OfflineService,
        private modulesService: ModulesService,
        private modal: ModalService,
        private user: UserService
    ) {
    }

    public get projectDropdownLabel() {
        return this.isCompanyProjectVisible ? 'Agito.Hilti.Profis3.HomePage.DesignFromTemplate.SaveLocation' : 'Agito.Hilti.Profis3.AddEditDesign.Project';
    }

    public get titleKey() {
        return 'Agito.Hilti.Profis3.HomePage.DesignFromTemplate';
    }

    public get isProjectTextBoxDisplay() {
        return !this.offline.isOffline && this.projectDropdown.selectedValue?.id == '0';
    }

    public get template() {
        return this.modalInstance?.input?.template;
    }

    public get isCompanyProjectVisible() {
        return this.user.authentication != null && !this.user.hasTrialLicense
            && (this.user.authentication.customerOriginId && this.user.authentication.country);
    }

    ngOnInit() {
        const designTypeId = this.template?.DesignTypeId;
        const designType = this.modulesService.designTypes.find((desingTypeCodeListItem) => desingTypeCodeListItem.id == designTypeId)
        this.nameTextBox = {
            id: 'design-from-template-name',
            title: this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.Name'),
            value: this.document.createUniqueName(
                createDesignName(this.localization, designType),
                Object.values(this.document.draftsProject?.designs ?? []).map((item) => item.designName))
        };

        this.projectType = {
            id: 'project-type',
            items: [
                {
                    text: this.localization.getString('Agito.Hilti.Profis3.HomePage.DesignFromTemplate.MyProject'),
                    value: ProjectType.MyProject,
                    id: `design-from-template-projecttype-${ProjectType.MyProject}`
                },
                {
                    text: this.localization.getString('Agito.Hilti.Profis3.HomePage.DesignFromTemplate.CompanyProject'),
                    value: ProjectType.CompanyProject,
                    id: `design-from-template-projecttype-${ProjectType.CompanyProject}`
                }
            ],
            selectedValue: ProjectType.MyProject
        };

        this.initProject(ProjectType.MyProject);

        this.nameTextBox.validationData = {
            showValidationErrors: true
        };

        this.nameTextBox.validators = [
            this.requiredValidator,
            designNameValidator(
                this.projectDropdown.selectedValue,
                null,
                null,
                this.document,
                this.localization.getString('Agito.Hilti.Profis3.Naming.DesignNameExists.Message')
            )
        ];
    }

    public selectedProjectTypeChange(type: ProjectType){
       this.projectType.selectedValue = type;
       this.newProject = null;
       this.initProject(type);
    }

    private initProject(type?: ProjectType) {
        const projectItems = this.getProjectDropdownItems(type);

        if (type == ProjectType.MyProject) {
            this.projectDropdown = {
                id: 'design-from-template-project-dropdown',
                title: this.isCompanyProjectVisible ? this.localization.getString('Agito.Hilti.Profis3.HomePage.DesignFromTemplate.SaveLocation') : this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.Project'),
                items: [
                    {
                        value: new Project({ id: NewProjectId }),
                        text: this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.NewProject'),
                        isSpecial: true
                    },
                    {
                        value: this.document.draftsProject,
                        text: this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Designs.Drafts'),
                    },
                    ...projectItems
                ],
                selectedValue: this.document.draftsProject
            };
        }

        if (type == ProjectType.CompanyProject) {
            this.projectDropdown = {
                id: 'design-from-template-project-dropdown',
                title: this.isCompanyProjectVisible ? this.localization.getString('Agito.Hilti.Profis3.HomePage.DesignFromTemplate.SaveLocation') : this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.Project'),
                items: [
                    {
                        value: new Project({ id: NewProjectId }),
                        text: this.localization.getString('Agito.Hilti.Profis3.HomePage.CompanyProjectFolder.New'),
                        isSpecial: true
                    },
                    ...projectItems
                ]
            };
        }
    }

    public newProjectCancel() {
        this.onProjectDropdownSelectedValueChange(this.document.draftsProject);
        this.newProject = null;

        setTimeout(() => {
            (document.querySelector('.project-dropdown .dropdown-button') as HTMLElement).focus();
        });
    }

    public onProjectDropdownSelectedValueChange(project: Project) {
        const oldProject = this.projectDropdown.selectedValue;
        this.projectDropdown.selectedValue = project;

        if (project && oldProject) {
            this.nameTextBox.validators = [
                this.requiredValidator,
                designNameValidator(
                    project,
                    null,
                    null,
                    this.document,
                    this.localization.getString('Agito.Hilti.Profis3.Naming.DesignNameExists.Message')
                )
            ];

            //New project
            if (project.id == NewProjectId && oldProject.id != NewProjectId) {
                setTimeout(() => (document.querySelector('.modal-body .new-project-container .new-project input') as HTMLElement).focus());
            }
        }
    }

    public close() {
        this.modalInstance.close();
    }

    public save(form: NgForm) {
        if (this.submitted || (form.enabled && !form.valid)) return;

        this.submitted = true;
        const selectedProject = this.projectDropdown.selectedValue;
        let project = Promise.resolve(selectedProject);
        const isCompanyProject = this.projectType.selectedValue == ProjectType.CompanyProject;
        if (selectedProject != null && selectedProject.id == NewProjectId) {
            const newProject = new Project({
                name: this.newProject.trim(),
                owner: true,
                isCompanyProject: isCompanyProject
            });

            if (this.document.projectNameExists(newProject.name, null)) {
                this.openProjectRenameModal();
                this.submitted = false;
                return;
            }

            project = this.document
                .saveProject(newProject, ModalDialogType.project)
                .then(() => {
                    return newProject;
                });
        }

        project.then(async (project) => {
            const template = this.modalInstance?.input?.template;
            const designsInfo = this.modulesService.getDesignListInfoByDesignType(template.DesignTypeId);
            if (designsInfo?.newDesignFromTemplate != null) {
                try {
                    await designsInfo.newDesignFromTemplate(template.DesignTemplateDocumentId, project.id, this.nameTextBox.value).finally(() => {
                        this.submitted = false;
                        this.user.setTemplateFolder(null);
                        this.close();
                    });
                }
                catch (err) {
                    console.error(err);
                    this.submitted = false;
                }
            }
        }).catch((err) => {
            if (err instanceof Error) {
                console.error(err);
            }
            this.submitted = false;
        });
    }

    private getProjectDropdownItems(type?: ProjectType) {
        if (type == ProjectType.CompanyProject) {
            return this.document.getProjectDropdownItemsNewHomePage(true);
        }

        return this.document.getProjectDropdownItemsNewHomePage();
    }

    private openProjectRenameModal() {
        this.modal.openConfirmChange({
            id: 'confirm-project-rename',
            title: this.localization.getString('Agito.Hilti.Profis3.Naming.ProjectNameExists.Title'),
            message: this.localization.getString('Agito.Hilti.Profis3.Naming.ProjectNameExists.Message'),
            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Naming.ProjectNameExists.Confirm'),
            onConfirm: (modal) => {
                modal.close();
            }
        });
    }
}
