<div class="maintenance">
    <div class="maintenance-container">
        <div class="maintenance-header">
            <span class="logo sprite sprite-main-logo"></span>
        </div>
        <div class="maintenance-content">
            <p class="title">
                PROFIS ENGINEERING
            </p>
            <br />
            <div class="title-row-2">
                MAINTENANCE
            </div>
            <div class="message-row">
                <p>
                    PROFIS Engineering is on maintenance but will be back soon! Thanks for your patience.
                </p>
                <p>
                    We will be back as soon as possible.
                </p>
            </div>
        </div>
    </div>
</div>
