import sortBy from 'lodash-es/sortBy';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
    TextBoxProps
} from '@profis-engineering/pe-ui-common/components/text-box/text-box.common';
import { ProjectUser } from '@profis-engineering/pe-ui-common/entities/project';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { Project } from '../../entities/project';
import { DateTimeService } from '../../services/date-time.service';
import { DocumentService } from '../../services/document.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { emailsValidator } from '../../validators/emails.validator';

export interface IShareProjectComponentInput {
    selectedProject: Project;
    onRemoveProject: () => void;
    onConfirm?: (modal: boolean) => any;
}

@Component({
    selector: 'app-share-project',
    templateUrl: './share-project.component.html',
    styleUrls: ['./share-project.component.scss']
})
export class ShareProjectComponent implements OnInit {
    public projectSharedUsers: ProjectUser[];
    public submitted: boolean;
    public userTextBox: TextBoxProps = {};

    public sharedUsersHeaderHeight = 39;    // must be the same value as in css
    public sharedUsersRowHeight = 35;       // must be the same value as in css
    public scrollElement: Element;

    private pendingSave: boolean;

    constructor(
        public localization: LocalizationService,
        public dateTime: DateTimeService,
        private document: DocumentService,
        private modal: ModalService,
        private modalInstance: ModalInstance<IShareProjectComponentInput>
    ) { }

    public get selectedProject() {
        return this.modalInstance.input?.selectedProject;
    }

    public get hideShareTable() {
        return this.projectSharedUsers.length < 1;
    }

    public get formValid() {
        return this.userTextBox?.isValid;
    }

    private get onRemoveProject() {
        return this.modalInstance.input?.onRemoveProject;
    }

    ngOnInit(): void {
        // don't close the modal if save is pending
        this.modalInstance.setOnClosing(() => {
            if(this.selectedProject.users?.length == 1)
            {
                this.modalInstance.input.onConfirm?.(true);
            }

            if (this.pendingSave) {
                return false;
            }

            if (this.onRemoveProject != null) {
                this.document.userHasAccessToProject(this.selectedProject.id)
                    .then((res) => {
                        if (!res) {
                            this.onRemoveProject();
                        }
                    });
            }

            return true;
        });

        this.scrollElement = document.querySelector('.modal');

        this.updateProjectSharedUsers();

        this.userTextBox = {
            id: 'share-project-add-users',
            title: this.localization.getString('Agito.Hilti.Profis3.ShareProject.AddUserTitle'),
            placeholder: this.localization.getString('Agito.Hilti.Profis3.ShareProject.AddUserPlaceholder'),
            maxLength: 250,
            validators: [emailsValidator(';')]
        };

        setTimeout(() => this.userTextBoxFocus());
    }

    public close() {
        this.modalInstance.close();
    }

    public addUser(form: NgForm) {
        if (this.submitted
            || this.pendingSave
            || !this.formValid
            || (form.enabled && !form.valid)
            || this.userTextBox.value == null
            || this.userTextBox.value.trim() == ''
        ) {
            return;
        }

        this.submitted = true;
        this.pendingSave = true;

        const inputUser = this.userTextBox.value.toLowerCase().trim();
        const users = inputUser.split(';').map(it => it.trim()).filter(it => it != '');
        if (users.length == 0) {
            return;
        }

        let completed = 0;
        const updatedUsers: ProjectUser[] = [];
        const now = new Date();
        const nowUtc = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds()));

        const promises: Promise<string>[] = [];
        const processedUsers: string[] = [];
        const selectedProject = this.selectedProject;
        for (const user of users) {
            if (user == null || user.trim() == '') {
                continue;
            }

            // add only if entry does not exist in the selected project
            if (selectedProject.users.findIndex((item) => item.projectid === selectedProject.id && item.user.toLowerCase() === user.toLowerCase()) >= 0) {
                continue;
            }

            // add only if not yet processed in current request
            if (processedUsers.findIndex((item) => item.toLowerCase() === user.toLowerCase()) >= 0) {
                continue;
            }

            const newUser: ProjectUser = {
                projectid: selectedProject.id,
                user,
                modified: true,
                dateadded: nowUtc,
                isowner: false
            };
            promises.push(this.document.addUsersOnProjectById(newUser).then(_ => user));

            processedUsers.push(user.toLowerCase());
        }

        if (promises.length > 0) {
            new Promise((resolve, reject) => {
                promises.forEach((prom) => {
                    prom.then((userName) => {
                        if (userName != undefined) {
                            const user: ProjectUser = {
                                projectid: selectedProject.id,
                                user: userName,
                                modified: true,
                                dateadded: nowUtc,
                                isowner: false
                            };

                            updatedUsers.push(user);
                            selectedProject.users.push(user);
                        }
                    }).catch((err) => {
                        if (err instanceof Error) {
                            console.error(err);
                        }
                    }).finally(() => {
                        completed++;
                        if (completed == promises.length) {
                            if (updatedUsers.length == completed) {
                                resolve(promises);
                            }
                            else {
                                reject(promises);
                            }
                            this.updateProjectSharedUsers();
                            this.modalInstance.input.onConfirm?.(true);
                        }
                    });
                });
            }).finally(() => {
                this.submitted = false;
                this.pendingSave = false;

                this.userTextBox.value = null;
            });
        }
        else {
            this.submitted = false;
            this.pendingSave = false;

            this.userTextBox.value = null;
        }
    }

    public deleteUser(user: ProjectUser) {
        if (this.submitted || this.pendingSave) {
            return;
        }

        const opened = this.modal.openConfirmChange({
            id: 'confirm-delete-project-permanently',
            title: this.localization.getString('Agito.Hilti.Profis3.ShareProject.ConfirmDelete.Title'),
            message: this.localization.getString('Agito.Hilti.Profis3.ShareProject.ConfirmDelete.Message'),
            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.ShareProject.ConfirmDelete.Ok'),
            cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.ShareProject.ConfirmDelete.Cancel'),
            onConfirm: (modal) => {
                modal.close(true);
            },
            onCancel: (modal) => {
                modal.close(false);
            }
        }).result;

        this.submitted = this.pendingSave = true;

        opened
            .then((result: any) => {
                // Confirm closed
                new Promise<void>((resolve, reject) => {
                    if (result == true) {
                        this.document.removeUsersOnProjectById(user)
                            .then(() => {
                                this.selectedProject.users = this.selectedProject.users.filter((item) => item != user);
                                this.updateProjectSharedUsers();
                                resolve();
                            });
                        return;
                    }

                    reject();
                }).finally(() => {
                    this.submitted = this.pendingSave = false;
                    this.modalInstance?.input?.onConfirm?.(true);
                });
            })
            .catch(() => {
                // Confirm dismissed
                this.submitted = this.pendingSave = false;
            });
    }

    private updateProjectSharedUsers() {
        const selectedProject = this.selectedProject;
        if (selectedProject?.users == null) {
            this.projectSharedUsers = [];
            return;
        }

        this.projectSharedUsers = sortBy(
            selectedProject.users.filter((u) => !u.isowner),
            (user) => user.dateadded
        ).reverse();
    }

    private userTextBoxFocus() {
        document.querySelector<HTMLElement>('#share-project-add-users-wrapper')
            ?.shadowRoot?.querySelector<HTMLElement>('#share-project-add-users')
                ?.focus();
    }
}
