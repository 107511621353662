import { DebouncedFunc } from 'lodash-es/debounce';
import padStart from 'lodash-es/padStart';
import round from 'lodash-es/round';
import throttle from 'lodash-es/throttle';

import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IHttpResponseBase } from '@profis-engineering/pe-ui-common/helpers/http';
import {
    ITraceOptions, LoggerServiceBase, LogMessage, LogType
} from '@profis-engineering/pe-ui-common/services/logger.common';

import { environment } from '../../environments/environment';

interface ITrace {
    name: string;
    start: number;
    end: number;
    printTime: boolean;
    children: ITrace[];
    parent: ITrace;
    noLog: boolean;
    promise: Promise<any>;
}

type IThrottleFn = DebouncedFunc<(trace: ITrace) => void>

/* eslint-disable  @typescript-eslint/no-explicit-any */
@Injectable({
    providedIn: 'root'
})
export class LoggerService extends LoggerServiceBase {
    private throttleFunctions: Record<string, IThrottleFn> = {};
    private currentTrace: ITrace;

    public log(message: string, logType?: LogType, ...args: any[]) {
        if (!environment.isLogEnabled && logType != LogType.error && logType != LogType.warn) {
            return;
        }

        this.print(this.formatMessage(message), logType, args);
    }

    public logAlways(message: string, logType?: LogType, ...args: any[]) {
        this.print(this.formatMessage(message), logType, args);
    }

    public logServiceError(response: IHttpResponseBase | string | unknown, serviceName: string, requestName: string) {
        if (response instanceof Error) {
            console.error(response);
        }

        if (response instanceof HttpErrorResponse && response.error instanceof Error) {
            console.error(response.error);
        }

        const error: unknown = response != null
            ? (typeof response == 'string'
                ? response
                : response instanceof HttpErrorResponse && response.status > 0
                    ? response.error
                    : response instanceof HttpResponse
                        ? response.body
                        : undefined
            )
            : null;

        this.log(`Calling web service failed (Error::${serviceName}::${requestName}). Error message: ${error}`, LogType.error);
    }

    public logServiceRequest(serviceName: string, fnName: string, ...args: any[]) {
        if (!environment.isLogEnabled) {
            return;
        }

        this.logService(serviceName, 'request', fnName, ...args);
    }

    public logServiceResponse(serviceName: string, fnName: string, ...args: any[]) {
        if (!environment.isLogEnabled) {
            return;
        }

        this.logService(serviceName, 'response', fnName, ...args);
    }

    public logGroup(group: LogMessage, logs: LogMessage[], logType?: LogType, noTimestamp?: boolean) {
        if (!environment.isLogEnabled) {
            return;
        }

        const consoleGroup = (console.groupCollapsed || console.group) as (group: string, ...args: any[]) => void;
        const consoleGroupEnd = console.groupEnd;

        if (consoleGroup != null && consoleGroupEnd != null) {
            consoleGroup.apply(console, [noTimestamp ? group.message : this.formatMessage(group.message)].concat(group.args || []) as [string, ...any[]]);

            for (const log of logs) {
                this.print(log.message, logType, log.args);
            }

            consoleGroupEnd.apply(console);
        }
        else {
            this.log.apply(this, [group.message, logType, group.args || []]);

            for (const log of logs) {
                this.print(`\t${log.message}`, logType, log.args);
            }
        }
    }

    public logGroupFn(group: LogMessage, fn: () => void, logType?: LogType) {
        if (!environment.isLogEnabled) {
            return;
        }

        const consoleGroup = (console.groupCollapsed || console.group) as (group: string, ...args: any[]) => void;
        const consoleGroupEnd = console.groupEnd;

        if (consoleGroup != null && consoleGroupEnd != null) {
            consoleGroup.apply(console, [this.formatMessage(group.message)].concat(group.args || []) as [string, ...any[]]);

            fn();

            consoleGroupEnd.apply(console);
        }
        else {
            this.log.apply(this, [group.message, logType, group.args || []]);

            fn();
        }
    }

    public logTrace<TValue>(name: string, action: () => TValue, options?: ITraceOptions): TValue {
        action = action || (() => undefined);

        if (!environment.isLogEnabled) {
            return action();
        }

        return this.logTraceInternal(name, true, action, options != null ? options.noLog || false : false, options != null ? options.throttle || 0 : 0);
    }

    public logTraceAsync<TValue>(name: string, action: () => Promise<TValue>, options?: ITraceOptions): Promise<TValue> {
        action = action || (() => undefined);

        if (!environment.isLogEnabled) {
            return action();
        }

        return this.logTraceInternal(name, true, action, options != null ? options.noLog || false : false, options != null ? options.throttle || 0 : 0);
    }

    public logTraceMessage<TValue>(name: string, action?: () => TValue, options?: ITraceOptions): TValue {
        return this.logTraceInternal(name, false, action, options != null ? options.noLog || false : false, options != null ? options.throttle || 0 : 0);
    }

    protected logService(serviceName: string, type: string, fnName: string, ...args: any[]) {
        if (type == null || type == '') {
            return;
        }

        args = args || [];

        let argsFormat = args.length > 0 ? ': ' : '';

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const _arg of args) {
            argsFormat += '%o ';
        }

        if (args.length > 0) {
            argsFormat = argsFormat.substring(0, argsFormat.length - 1);
        }

        // trim strings
        for (let i = 0; i < args.length; i++) {
            args[i] = this.trimStringToLength(args[i]);
        }

        this.log(`${serviceName}::${fnName}.${type}${argsFormat}`, LogType.debug, ...args);
    }

    private trimStringToLength(value: any, length = 100) {
        if (value != null && typeof value == 'string' && value.length > length) {
            return value.substring(0, length - 4) + ' ...';
        }

        return value;
    }

    private logTraceInternal<TValue>(name: string, printTime: boolean, action: () => TValue, noLog: boolean, throttleWait: number): TValue {
        action = action || (() => undefined);

        if (!environment.isLogEnabled) {
            return action();
        }

        this.currentTrace = { name, parent: this.currentTrace, children: [], start: null, end: null, printTime, noLog, promise: null };

        if (this.currentTrace.parent != null) {
            this.currentTrace.parent.children.push(this.currentTrace);
        }

        this.currentTrace.start = performance.now();
        let result: any = null;

        try {
            result = action();
        }
        finally {
            this.currentTrace.end = performance.now();
            if (result != null && result.$$state != null) {
                this.currentTrace.promise = result;
            }
            if (this.currentTrace.parent != null) {
                this.currentTrace = this.currentTrace.parent;
            }
            else {
                if (throttleWait > 0) {
                    let throttleFn = this.throttleFunctions[name];
                    if (throttleFn == null) {
                        this.throttleFunctions[name] = throttleFn = throttle((currentTrace: ITrace) => this.printTrace(currentTrace), throttleWait, { trailing: false });
                    }

                    throttleFn(this.currentTrace);
                }
                else {
                    this.printTrace(this.currentTrace);
                }

                this.currentTrace = null;
            }
        }
        return result;
    }

    private printTraceInternal(trace: ITrace, promiseTime?: number) {
        if (trace.children.length > 0) {
            const consoleGroup = (console.groupCollapsed || console.group) as (group: string, ...args: any[]) => void;
            const consoleGroupEnd = console.groupEnd;

            if (consoleGroup != null && consoleGroupEnd != null) {
                consoleGroup.apply(console, [this.formatTrace(trace, promiseTime)]);

                for (const childTrace of trace.children) {
                    this.printTrace(childTrace);
                }

                consoleGroupEnd.apply(console);
            }
            else {
                this.print(this.formatTrace(trace, promiseTime));

                for (const childTrace of trace.children) {
                    this.printTrace(childTrace);
                }
            }
        }
        else {
            this.print(this.formatTrace(trace, promiseTime));
        }
    }

    private printTrace(trace: ITrace) {
        if (!trace.noLog) {
            let promiseTime: number = null;

            if (trace.promise != null) {
                const promiseStart = performance.now();
                trace.promise.finally(() => {
                    promiseTime = performance.now() - promiseStart;
                    this.printTraceInternal(trace, promiseTime);
                });
            }
            else {
                this.printTraceInternal(trace);
            }
        }
    }

    private formatTrace(trace: ITrace, promiseTime?: number) {
        let time = trace.printTime ? ` - ${round(trace.end - trace.start, 2)} ms` : '';
        if (promiseTime != null) {
            time += ' (' + round(promiseTime, 2) + ' ms)';
        }

        return `${(promiseTime != null) ? 'Async - ' : ''}${trace.name}${time}`;
    }

    private formatMessage(message: string) {
        const currentdate = new Date();
        const datetime = `${padStart(currentdate.getDate().toString(), 2, '0')}/${padStart((currentdate.getMonth() + 1).toString(), 2, '0')}/${currentdate.getFullYear()} @ ${padStart(currentdate.getHours().toString(), 2, '0')}:${padStart(currentdate.getMinutes().toString(), 2, '0')}:${padStart(currentdate.getSeconds().toString(), 2, '0')}`;

        return `(${datetime}): ${message}`;
    }

    private print(message: string, logType?: LogType, args?: any[]) {
        switch (logType) {
            case LogType.debug:
                if (args != null && args.length > 0) {
                    console.debug(message, ...args);
                }
                else {
                    console.debug(message);
                }

                break;

            case LogType.error:
                if (args != null && args.length > 0) {
                    console.error(message, ...args);
                }
                else {
                    console.error(message);
                }

                break;

            case LogType.warn:
                if (args != null && args.length > 0) {
                    console.warn(message, ...args);
                }
                else {
                    console.warn(message);
                }

                break;

            case LogType.info:
                if (args != null && args.length > 0) {
                    console.info(message, ...args);
                }
                else {
                    console.info(message);
                }

                break;

            default:
                if (args != null && args.length > 0) {
                    console.log(message, ...args);
                }
                else {
                    console.log(message);
                }

                break;
        }
    }
}
/* eslint-enable  @typescript-eslint/no-explicit-any */
