import { Subscription } from 'rxjs';

import {
    AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input,
    OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChanges, ViewChild
} from '@angular/core';
import { NgForm, NgModel, ValidatorFn } from '@angular/forms';
import {
    Tooltip, ngbTooltipTemplate
} from '@profis-engineering/pe-ui-common/components/content-tooltip/content-tooltip.common';
import {
    ControlTitleLook, InfoPopup
} from '@profis-engineering/pe-ui-common/components/control-title/control-title.common';
import {
    DropdownItem, DropdownLook, DropdownTag
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { randomString } from '@profis-engineering/pe-ui-common/helpers/random';
import {
    IValidationAdditionalData
} from '@profis-engineering/pe-ui-common/helpers/validation-helper';
import {
    customErrorValidator
} from '@profis-engineering/pe-ui-common/validators/custom-error.validator';
import {
    IIconStyle
} from '@profis-engineering/pe-ui-common/helpers/image-helper';

import { LocalizationService } from '../../services/localization.service';

const keyUp = 'Up';
const keyArrowUp = 'ArrowUp';
const keyDown = 'Down';
const keyArrowDown = 'ArrowDown';
const keyEnter = 'Enter';
const keyTab = 'Tab';
const keySpace = ' ';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent<TValue> implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    public isHiltiStyled = false;
    public controlTitleStyle = ControlTitleLook.Normal;
    public ngbTooltipTemplate = ngbTooltipTemplate;
    @Input()
    public id = randomString(8);

    @Input()
    public title: string;

    @Input()
    public items: DropdownItem<TValue>[];

    @Input()
    public disabled = false;

    @Input()
    public selectedValue: TValue;

    @Output()
    public selectedValueChange = new EventEmitter<TValue>();

    @Input()
    public notSelectedText: string;

    @Input()
    public tooltip: Tooltip;

    @Input()
    public infoPopup: InfoPopup;

    @Input()
    public infoPopupTooltip: Tooltip;

    @Input()
    public submitted = false;

    @Output()
    public submittedChange = new EventEmitter<boolean>();

    // Look
    @Input()
    public look = DropdownLook.Normal;

    @Input()
    public openUp = false;

    @Input()
    public tags: DropdownTag[];

    // Validation
    @Input()
    public validators: ValidatorFn[];

    @Input()
    public validationData: IValidationAdditionalData;

    @Output()
    public isValid = new EventEmitter<boolean>();

    // Internal
    @ViewChild('controlContainer')
    controlContainerElement: ElementRef;

    @ViewChild('dropdownItems')
    dropdownItemsElement: ElementRef;

    @ViewChild('dropdownButton')
    buttonElement: ElementRef;

    @ViewChild('ngModel')
    public ngModel: NgModel;

    private opened: boolean;
    private selectedItem: DropdownItem<TValue>;
    private searchText: string;
    private searchTimeout: number;
    private form: NgForm;
    private ngSubmitSubscription: Subscription;

    constructor(
        private localizationService: LocalizationService,
        private changeDetectorRef: ChangeDetectorRef,
        @Optional() form: NgForm
    ) {
        this.form = form;
    }

    public get isOpen() {
        return this.opened;
    }

    public get text() {
        let text = '';

        const selectedItem = this.getSelectedItem();
        if (selectedItem != null) {
            text = selectedItem.text;
        }
        else if (this.notSelectedText != null && this.notSelectedText != '') {
            text = this.notSelectedText;
        }
        else {
            text = this.localizationService.getString('Agito.Hilti.Profis3.Dropdown.NoneSelected');
        }

        return text;
    }

    public get image() {
        let image: IIconStyle | undefined;

        const selectedItem = this.getSelectedItem();
        if (selectedItem != null) {
            image = selectedItem.image;
        }

        return image;
    }

    public get showValidationErrors() {
        if (!this.validationData?.showValidationErrors) {
            return false;
        }

        if (
            this.ngModel == null
            || this.ngModel.disabled
            || this.ngModel.valid
            || !this.ngModel.touched && !this.submitted
        ) {
            return false;
        }

        return true;
    }

    ngOnInit(): void {
        this.opened = false;
        this.selectedItem = this.getSelectedItem();
    }

    ngAfterViewInit(): void {
        // add control to the form and sync submitted
        if (this.form != null) {
            if (this.ngModel != null) {
                this.form.addControl(this.ngModel);
            }

            this.ngSubmitSubscription = this.form.ngSubmit.subscribe(() => {
                if (this.submitted != this.form.submitted) {
                    this.submitted = this.form.submitted;
                    this.submittedChange.emit(this.submitted);
                }
            });
        }

        if (this.ngModel != null) {
            // sync isValid
            this.ngModel.statusChanges.subscribe(() => {
                this.isValid.emit(this.ngModel.disabled || this.ngModel.valid);
            });
        }

        this.updateValidators();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes['validators']?.firstChange === false
            || changes['validationData']?.firstChange === false
        ) {
            this.updateValidators();
        }

        this.isHiltiStyled = this.look == DropdownLook.HiltiStyled;
        this.controlTitleStyle = this.isHiltiStyled
            ? ControlTitleLook.HiltiStyled
            : ControlTitleLook.Normal;
    }

    public ngOnDestroy(): void {
        // remove control from form and unsubscribe from ngSubmit
        if (this.form != null) {
            if (this.ngModel != null) {
                this.form.removeControl(this.ngModel);
            }

            this.ngSubmitSubscription?.unsubscribe();
        }
    }

    @HostListener('document:click', ['$event.target'])
    clickedOut(target: HTMLElement) {
        const controlContainer = this.controlContainerElement.nativeElement as HTMLElement;
        if (!controlContainer.contains(target) && this.opened) {
            this.opened = false;
            this.searchTimeout = null;
        }
    }

    public itemSelected(item: DropdownItem<TValue>) {
        if (this.selectedItem) {
            return item.value == this.selectedItem.value;
        }

        return false;
    }

    public onClick() {
        if (this.items != null && this.items.length > 0) {
            this.opened = !this.opened;

            if (this.opened) {
                this.searchText = '';
                this.selectedItem = this.getSelectedItem();

                setTimeout(() => this.scrollIntoView(this.selectedValue), 100);
            }
            else if (this.ngModel) {
                // Mark dropdown as touched if we closed it.
                this.ngModel.control.markAsTouched();
            }
        }
    }

    public onKeyPress(event: KeyboardEvent) {
        if (!this.opened) {
            return;
        }

        event.preventDefault();
        const eventKey = event.key;

        if (this.searchTimeout != null) {
            clearTimeout(this.searchTimeout);
        }

        this.searchTimeout = setTimeout(() => {
            this.searchTimeout = null;
            this.searchText = '';
        }, 1000);

        this.searchText += eventKey;

        const itemToSelect = this.items.find(item => item.text.substr(0, this.searchText.length).toLowerCase() == this.searchText.toLowerCase());
        this.updateSelectedItem(itemToSelect);
    }

    public onKeyDown(event: KeyboardEvent) {
        if (!this.opened) {
            return;
        }

        const eventCode = event.key;
        if (eventCode == keyEnter || eventCode == keyTab || eventCode == keySpace) {
            event.preventDefault();

            // set selected value
            const selectedItem = this.selectedItem;
            if (selectedItem != null) {
                this.clearCustomErrors();

                this.selectedValue = selectedItem.value;
                this.selectedValueChange.emit(selectedItem.value);
            }

            this.opened = false;
            this.ngModel?.control?.markAsTouched();
        }
        else if (eventCode == keyDown || eventCode == keyArrowDown) {
            event.preventDefault();

            const selectedIndex = this.selectedItem != null
                ? this.items.findIndex(item => item.value == this.selectedItem.value)
                : -1;

            let nextItem: DropdownItem<TValue>;
            if (selectedIndex != -1) {
                if (selectedIndex < this.items.length - 1) {
                    nextItem = this.items[selectedIndex + 1];
                }
            }
            else if (this.items != null && this.items.length > 0) {
                // select first if none is selected
                nextItem = this.items[0];
            }

            this.updateSelectedItem(nextItem);
        }
        else if (eventCode == keyUp || eventCode == keyArrowUp) {
            event.preventDefault();

            const selectedIndex = this.selectedItem != null
                ? this.items.findIndex(item => item.value == this.selectedItem.value)
                : -1;

            let previousItem: DropdownItem<TValue>;
            if (selectedIndex != -1) {
                if (selectedIndex > 0) {
                    previousItem = this.items[selectedIndex - 1];
                }
            }
            else if (this.items != null && this.items.length > 0) {
                // select first if none is selected
                previousItem = this.items[0];
            }

            this.updateSelectedItem(previousItem);
        }
    }

    public onItemClick(item: DropdownItem<TValue>) {
        this.clearCustomErrors();

        this.selectedItem = item;
        this.selectedValue = this.selectedItem.value;
        this.selectedValueChange.emit(this.selectedValue);

        this.opened = !this.opened;
    }

    private scrollIntoView(value: TValue) {
        const offset = 2;

        const selectedIndex = this.items.findIndex(i => i.value === value);
        if (selectedIndex != -1) {
            const dropdownItemsHTMLElement = this.dropdownItemsElement.nativeElement as HTMLElement;

            const selectedElement = dropdownItemsHTMLElement.querySelectorAll('.dropdown-item')[selectedIndex] as HTMLElement;
            const dropdownFullHeight = dropdownItemsHTMLElement.scrollHeight;
            const dropdownHeight = dropdownItemsHTMLElement.offsetHeight;

            if (dropdownFullHeight > dropdownHeight) {
                const selectedElementHeight = selectedElement.offsetHeight;
                const dropdownTop = dropdownItemsHTMLElement.scrollTop;
                const dropdownBottom = dropdownItemsHTMLElement.scrollTop + dropdownHeight;

                if (selectedElement.offsetTop < dropdownTop) {
                    dropdownItemsHTMLElement.scrollTop = selectedElement.offsetTop - offset;
                }
                else if (selectedElement.offsetTop + selectedElementHeight + offset * 2 > dropdownBottom) {
                    dropdownItemsHTMLElement.scrollTop = selectedElement.offsetTop - dropdownHeight + selectedElementHeight + offset * 2;
                }
            }
        }
    }

    private updateValidators() {
        if (this.ngModel != null) {
            const validators = [...(this.validators ?? [])];
            if (this.validationData?.customErrorMessage != null) {
                validators.push(customErrorValidator(this.validationData?.customErrorMessage));
            }

            this.ngModel.control.setValidators(validators);
            this.ngModel.control.updateValueAndValidity();
            this.changeDetectorRef.detectChanges();
        }
    }

    private updateSelectedItem(item: DropdownItem<TValue>) {
        if (item == null) {
            return;
        }

        this.selectedItem = item;
        this.scrollIntoView(this.selectedItem.value);
    }

    private getSelectedItem() {
        let selectedItem: DropdownItem<TValue> = null;

        if (this.selectedValue != null && this.items != null) {
            selectedItem = this.items.find(item => item.value == this.selectedValue);
        }

        return selectedItem;
    }

    private clearCustomErrors() {
        if (this.validationData?.customErrorMessage != null) {
            this.validationData.customErrorMessage = null;
            this.updateValidators();
        }
    }
}
