import * as React from 'react';
import { ControlHeader } from '../../ControlHeader';
import { IRangeSliderProps } from '@profis-engineering/pe-ui-common/entities/main-menu/range-slider-props';

export class RangeSlider extends React.PureComponent<IRangeSliderProps> {
    private value: number;

    constructor(props?: IRangeSliderProps) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    public override render() {
        this.value = this.props.value;

        return (
            <div className={`range-slider control ${this.props.sizeClass}`} >
                <ControlHeader
                    text={this.props.title + ' ' + this.value + '%'}
                    controlId={this.props.controlId}
                    tooltip={this.props.tooltip}
                    tooltipTitle={this.props.tooltipTitle}
                    localization={this.props.localization}
                />
                <div className='range-slider-content'>
                    <p className='value'>1%</p>
                    <input type='range' className='slider' min='1' max='100' step='1' value={this.value} onChange={this.handleChange} />
                    <p className='value'>100%</p>
                </div>
            </div>
        );
    }

    private handleChange(event: any) {
        this.value = event.target.value;
        this.props.checkChanged(this.value);
    }
}
