import { UAParser } from 'ua-parser-js';

import { HttpRequest } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import {
    TextBoxProps
} from '@profis-engineering/pe-ui-common/components/text-box/text-box.common';
import {
    IApplicationError
} from '@profis-engineering/pe-ui-common/entities/application-error';
import {
    SupportApplicationType, SupportRequest
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.SupportService.Shared.Entities';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import {
    DateTimePattern
} from '@profis-engineering/pe-ui-common/services/date-time.common';

import { IValidatorFn } from '@profis-engineering/pe-ui-common/helpers/validation-helper';
import { environment } from '../../../environments/environment';
import { Design } from '../../entities/design';
import { DesignHelperBwCompat } from '../../helpers/bw-compat/design-helper-bw-compat';
import { ApiService } from '../../services/api.service';
import { BrowserService } from '../../services/browser.service';
import { DateTimeService } from '../../services/date-time.service';
import { LicenseService } from '../../services/license.service';
import { LocalizationService } from '../../services/localization.service';
import { LoggerService } from '../../services/logger.service';
import { MarketingService } from '../../services/marketing.service';
import { ModulesService } from '../../services/modules.service';
import { OfflineService } from '../../services/offline.service';
import { ProductInformationService } from '../../services/product-information.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';

export interface ISupportComponentInput {
    applicationError?: IApplicationError;
    projectDesign?: object;
}

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
    public submitted = false;
    public subjectTextBox: TextBoxProps = {};
    public message: string;

    private parser: UAParser;
    private currentDateTime: Date;

    constructor(
        public localization: LocalizationService,
        public offlineService: OfflineService,
        private userSettings: UserSettingsService,
        private user: UserService,
        private browser: BrowserService,
        private dateTime: DateTimeService,
        private license: LicenseService,
        private logger: LoggerService,
        private modalInstance: ModalInstance<ISupportComponentInput>,
        private apiService: ApiService,
        private marketingService: MarketingService,
        private productInformationService: ProductInformationService,
        private modulesService: ModulesService,
    ) { }

    public get region() {
        return this.userSettings.getCommonRegionById(this.userSettings.settings.application.general.regionId.value);
    }

    public get requiresInternetConnection() {
        return this.offlineService.isOffline && !this.browser.isOfflineOnLine;
    }

    public get supportOpeningHours() {
        return this.region.supportHours;
    }

    public get supportEmail() {
        return this.region.contactUrl;
    }

    public get supportPhone() {
        return this.region.supportPhone;
    }

    public get faqLinkMessage() {
        return this.askHiltiLink != null ?
            this.translate('Agito.Hilti.Profis3.Support.AskHilti.Message') :
            this.translate('Agito.Hilti.Profis3.Support.FAQ.Message');
    }

    public get faqLinkText() {
        return this.askHiltiLink != null ?
            this.translate('Agito.Hilti.Profis3.Region.Link.AskHilti') :
            this.translate('Agito.Hilti.Profis3.Region.Link.KnowledgeBaseUrl');
    }

    public get faqLinkUrl() {
        return this.askHiltiLink ?? this.kbUrl;
    }

    // In case of Ask Hilti link is present in database we use Ask Hilti link
    // otherwise we use Knowledge Base url (kbUrl)
    // if both are missing we should hide FAQ section in support popup
    public get askHiltiLink() {
        return this.productInformationService.regionLinksForUser()?.AskHiltiLink;
    }

    public get kbUrl() {
        return this.region.profis3KBUrl;
    }

    public get hideFAQ() {
        return this.askHiltiLink == null &&
            (this.kbUrl == null || this.kbUrl.length == 0);
    }

    public get disableSend() {
        return this.offlineService.isOffline;
    }

    public get isSupportDataAvailable() {
        // Check if either the email or phone are defined.
        if (this.supportEmail || this.supportPhone) {
            return true;
        }
        else {
            return false;
        }
    }

    public get applicationVersion() {
        return environment.applicationVersion;
    }

    public get operatingSystem() {
        const ua = this.parser.getResult();
        const os = ua.os != null ? ua.os.name + ' ' + ua.os.version : null;
        return os;
    }

    public get browserVersion() {
        const ua = this.parser.getResult();
        const bv = ua.browser != null ? ua.browser.name + ' ' + ua.browser.version : null;
        return bv;
    }

    public get formatedDateTime() {
        return this.dateTime.format(this.currentDateTime, DateTimePattern.dateTime);
    }

    public get userName() {
        return this.user.authentication.userName;
    }

    public get regionName() {
        return this.region.displayKey;
    }

    public get country() {
        return this.user.authentication.countryOfResidence
            ? this.user.authentication.countryOfResidence
            : this.user.authentication.country;
    }

    public get correlationId() {
        return this.modalInstance.input?.applicationError?.correlationId;
    }

    public get licenseType() {
        return this.license.getLicense();
    }

    public get translatedLicenseType() {
        return this.translate('Agito.Hilti.Profis3.License.' + this.licenseType);
    }

    public get designName() {
        if (this.user.design != null) {
            const design = this.user.design;

            return design.designName;
        }

        return null;
    }

    public get formValid() {
        return this.subjectTextBox?.isValid;
    }

    ngOnInit(): void {
        // don't close the modal if save is pending
        this.modalInstance.setOnClosing(() => {
            return !this.submitted;
        });

        this.subjectTextBox = {
            id: 'support-subject',
            title: this.translate('Agito.Hilti.Profis3.Support.Subject'),
            value: '',
            validators: [Validators.required as IValidatorFn]
        };

        this.parser = new UAParser();
        this.currentDateTime = new Date();
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }

    public async save(form: NgForm) {
        if (this.submitted || !this.formValid || (form.enabled && !form.valid)) {
            return;
        }

        this.submitted = true;

        const subject = this.subjectTextBox.value;
        const message = this.message;
        const regionId = this.userSettings.settings.application.general.regionId.value;

        const design = this.user.design;

        let designName: string = null;
        if (design != null) {
            designName = design.designName;
        }

        const data: SupportRequest = {
            Subject: subject,
            Message: message,
            Design: null,
            DesignName: designName,
            Logs: null,
            ApplicationVersion: this.applicationVersion,
            BrowserVersion: this.browserVersion,
            OperatingSystemVersion: this.operatingSystem,
            DateTime: this.currentDateTime.toUTCString(),
            RegionId: regionId,
            ApplicationType: SupportApplicationType.ProfisEngineering,
            Country: this.country,
            LicenseType: this.licenseType,
            CorrelationId: this.correlationId
        };

        data.Design = await this.getProjectDesign(design);

        this.sendSupportMessage(data);
    }

    public formatSupportOpeningHours(supportOpeningHours: string) {
        return this.translate('Agito.Hilti.Profis3.Support.Info.OpeningHours').concat(': ').concat(supportOpeningHours);
    }

    public formatSupportEmail(supportEmail: string) {
        return this.translate('Agito.Hilti.Profis3.Support.Info.Email').concat(': ').concat(supportEmail).replace('mailto:', '');
    }

    public formatSupportPhone(supportPhone: string) {
        return this.translate('Agito.Hilti.Profis3.Support.Info.Phone').concat(': ').concat(supportPhone);
    }

    public formatSupportText(translationText: string, supportText: string) {
        return this.translate(translationText).concat(': ').concat(supportText);
    }

    public openUserManual() {
        // open url
        this.offlineService.nativeExternalLinkOpen(this.marketingService.getUserManualForSelectedLanguage());
    }

    private sendSupportMessage(data: SupportRequest) {
        let url = `${environment.supportServiceUrl}send`;
        if (this.offlineService.isOffline) {
            url = `${environment.baseplateApplicationWebServiceUrl}Support`;
        }

        this.apiService.request(new HttpRequest('POST', url, data))
            .finally(() => {
                this.submitted = false;
            })
            .then(() => {
                this.close();
            })
            .catch(response => {
                this.logger.logServiceError(response, 'Support', 'SendEmailToSupport');
            });
    }

    private async getProjectDesign(design: Design) {
        if (this.modalInstance.input?.projectDesign != null) {
            return this.browser.toBase64(this.modalInstance.input?.projectDesign) as any;
        }

        if (design == null) {
            return undefined;
        }

        let projectDesign = this.modulesService.getProjectDesignFromDesign(design, design.designTypeId);
        if (projectDesign == null) {
            projectDesign = DesignHelperBwCompat.getProjectDesignFromDesign(design);
        }

        if (projectDesign == null) {
            return undefined;
        }

        let designBytes = await this.modulesService.getDesignFileBytes(projectDesign, design.designTypeId);
        if (designBytes != null) {
            // a module provides getDesignFileBytes for specified designTypeId
            if (designBytes.length > 0) {
                return designBytes
            }

            return undefined;
        }

        if (DesignHelperBwCompat.isDesignFileConversionNeeded(design?.designTypeId)) {
            try {
                designBytes = await DesignHelperBwCompat.getDesignFileBytes(this.apiService, projectDesign, design.designTypeId);

                if (designBytes != null) {
                    // S2C
                    if (designBytes.length > 0) {
                        return designBytes;
                    }
                }

            } catch (response) {
                console.error(response);
            }

            return undefined;
        }

        return this.browser.toBase64(projectDesign) as any;
    }
}
