import { Component, OnInit } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { FeatureVisibilityService } from '../../services/feature-visibility.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-launch-darkly-flags',
    templateUrl: './launch-darkly-flags.component.html',
    styleUrls: ['./launch-darkly-flags.component.scss']
})

export class LaunchDarklyFlagsComponent implements OnInit {
    public list!: LDFlagSet;
    public flagslist: { key: string, value: unknown }[] = [];

    constructor(
        private featureVisibilityService: FeatureVisibilityService,
        private modalInstance: ModalInstance,
        public userService: UserService,
        public userSettingsService: UserSettingsService
    ) { }

    ngOnInit() {
        this.initLaunchDarklyFlags();
    }

    public userName() {
        if (this.userService.isExternalOnlineRussianUser) {
            return this.userSettingsService.ccmsUserSettings != null ? this.userSettingsService.ccmsUserSettings.FullName : '';
        }

        return this.userSettingsService.settings.user.generalName.value?.trim()
            ? this.userSettingsService.settings.user.generalName.value
            : this.userService.getUserName();
    }

    public close() {
        this.modalInstance.close();
    }

    public printLaunchDarklyFlagValue(value: unknown) {
        if (value === true) {
            return 'On';
        }

        if (value === false) {
            return 'Off';
        }

        return JSON.stringify(value);
    }

    private initLaunchDarklyFlags() {
        this.list = this.featureVisibilityService.allFlags();
        const keys = this.getSortedKeys();
        keys.forEach((x: string) => {
            const value = this.list[x];
            const newpair = { key: x, value: value };
            this.flagslist.push(newpair);
        });
    }

    private getSortedKeys() {
        return Object.keys(this.list)
            .sort((a, b) => {
                const aa = a.toLowerCase();
                const bb = b.toLowerCase();
                if (aa < bb) {
                    return -1;
                }
                if (aa > bb) {
                    return 1;
                }
                return 0;
            });
    }
}
