import { Injectable } from '@angular/core';
import {
    FeatureVisibilityInfoModel
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.CodeLists';
import {
    Feature
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';

import { FeaturesVisibilityInfoServiceBase } from '@profis-engineering/pe-ui-common/services/features-visibility-info.common';
import { LocalizationService } from './localization.service';

@Injectable({
    providedIn: 'root'
})
export class FeaturesVisibilityInfoService extends FeaturesVisibilityInfoServiceBase {
    private featuresVisibilityInfo: { [feature: number]: FeatureVisibilityInfoModel };

    constructor(
        private localization: LocalizationService
    ) {
        super();
    }

    /**
     * Value indicating whether integrations are enabled or disabled. Shortcut for:
     * !featuresVisibilityInfo.isDisabled(Feature.Application_Settings_InterfacesToStructuralCalculationSoftware)
     */
    public get areIntegrationsEnabled(): boolean {
        return !this.isDisabled(Feature.Application_Settings_InterfacesToStructuralCalculationSoftware);
    }

    public get hasAdvancedFeature(): boolean {
        return !this.isDisabled(Feature.Design_AdvancedBaseplateCalculation);
    }

    public initialize(featuresVisibilityInfo: FeatureVisibilityInfoModel[]) {
        this.featuresVisibilityInfo = featuresVisibilityInfo.reduce((featuresVisibilityInfo, disabledFeature) => {
            featuresVisibilityInfo[disabledFeature.Feature] = disabledFeature;
            return featuresVisibilityInfo;
        }, {} as { [feature: number]: FeatureVisibilityInfoModel });
    }

    public isHidden(feature: Feature, regionId?: number) {
        const result = this.featuresVisibilityInfo[feature];

        return result && result.Hidden && (regionId == null || result.DisabledRegions == null || result.DisabledRegions.some(x => x == regionId));
    }

    public isDisabled(feature: Feature, regionId?: number) {
        const result = this.featuresVisibilityInfo[feature];

        return result && result.Disabled && (regionId == null || result.DisabledRegions == null || result.DisabledRegions.some(x => x == regionId));
    }

    public tooltip(feature: Feature) {
        return this.featuresVisibilityInfo[feature] == null ? null :
            this.localization.getString(this.featuresVisibilityInfo[feature].TooltipDisplayKey);
    }
}
