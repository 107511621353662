import * as React from 'react';
import { Subscription } from 'rxjs';

import {
    DataIntegrationType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Enums';

import { IntegrationsConnectionStatus } from '../../../../services/integrations-data.service';
import { IImportExportProps } from '../../MainMenu';
import { Button } from '../Button/Button';
import { ButtonSize, ButtonType } from '@profis-engineering/pe-ui-common/entities/main-menu/button-props';
export interface IRobotImportExportProps extends IImportExportProps {
    importClick: () => void;
}

interface IRobotImportExportState {
    robotStatus?: IntegrationsConnectionStatus;
}

export class RobotImportExport extends React.PureComponent<IRobotImportExportProps, IRobotImportExportState> {
    private statusChangedSubscription: Subscription;

    constructor(props?: IRobotImportExportProps) {
        super(props);

        this.onTryAgainClick = this.onTryAgainClick.bind(this);

        this.state = {
            robotStatus: this.props.integrationsData.robotStatus
        };

        this.statusChangedSubscription = this.props.integrationsData.statusChanged.subscribe(({ type, status }) => {
            if (type == DataIntegrationType.Robot) {
                this.setState({ robotStatus: status });
            }
        });
    }

    public override render() {
        if (this.props.hidden) {
            return null;
        }

        const { robotStatus } = this.state;

        let content: React.ReactNode = null;

        if (robotStatus == IntegrationsConnectionStatus.oldVersionDetected) {
            content = (
                <div className='error-container'>
                    <div className='try-again-text' dangerouslySetInnerHTML={{ __html: this.props.oldVersionDetectedText }}></div>
                </div>
            );
        }
        else if (robotStatus == IntegrationsConnectionStatus.pending || robotStatus == IntegrationsConnectionStatus.connected) {
            content = (
                <div className='buttons-container'>
                    <Button
                        controlId={`${this.props.controlId}-import-button`}
                        className='robot-button import-button'
                        buttonSize={ButtonSize.Full}
                        buttonType={ButtonType.Default}
                        disabled={this.props.disabled}
                        hidden={false}
                        image={null}
                        text={this.props.importButtonText}
                        type={Button}
                        title={null}
                        size={null}
                        localization={this.props.localization}
                        clicked={this.props.importClick.bind(this)}
                        loading={robotStatus == IntegrationsConnectionStatus.pending}
                    />
                </div>
            );
        }
        else {
            content = (
                <div className='error-container'>
                    <Button
                        controlId={`${this.props.controlId}-try-again-button`}
                        className='robot-button try-again-button'
                        buttonSize={ButtonSize.Full}
                        buttonType={ButtonType.Default}
                        disabled={this.props.disabled}
                        hidden={false}
                        image={null}
                        text={this.props.tryAgainButtonText}
                        type={Button}
                        title={null}
                        size={null}
                        localization={this.props.localization}
                        clicked={this.onTryAgainClick}
                    />

                    {robotStatus == IntegrationsConnectionStatus.notConnected ?
                        <div className='try-again-text' dangerouslySetInnerHTML={{ __html: this.props.notConnectedText }}></div>
                        :
                        <div className='try-again-text'>{this.props.errorText}</div>
                    }
                </div>
            );
        }

        return (
            <div data-control-id={this.props.controlId} className='control react-robot-import-export-control size-100'>
                {content}
            </div>
        );
    }

    public override componentWillUnmount() {
        this.statusChangedSubscription.unsubscribe();
    }

    private onTryAgainClick() {
        this.props.integrationsData.refreshIntegrationAvailability(DataIntegrationType.Robot);
    }
}
