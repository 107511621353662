import * as React from 'react';

import { LocalizationService } from '../../services/localization.service';
import { IControlHeaderProps } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { buildHtmlTooltip } from './MainMenuHelper';

export class ControlHeader extends React.PureComponent<IControlHeaderProps> {
    constructor(props?: IControlHeaderProps) {
        super(props);
        this.onKBTooltipClick = this.onKBTooltipClick.bind(this);
    }

    public override render() {
        if (this.props.text != null && this.props.text != '') {
            const tooltip = buildHtmlTooltip(this.props.tooltip, this.props.tooltipTitle);
            const kbTooltip = this.props.kbTooltip != null ? this.props.localization.getString(this.props.kbTooltip) : null;
            const infoTooltip = this.props.infoTooltip != null ? this.props.localization.getString(this.props.infoTooltip) : null;

            let labelClass = this.props.customClass != null && this.props.customClass != ''
                ? `control-label ${this.props.customClass}`
                : `control-label`;

            let infoClass = 'control-tooltip-popup sprite sprite-info-tooltip';
            let kbLinkClass = infoClass + ' kb-tooltip';

            if (this.props.infoClick || this.props.kbLink) {
                infoClass += ' info-margin';
                kbLinkClass += ' info-margin';
                labelClass += ' label-with-info';
            }

            return (<div className='control-header'>
                <label
                    className={labelClass}
                    data-tip={tooltip}
                    data-html={tooltip != null ? true : null}
                    dangerouslySetInnerHTML={{ __html: this.sanitizeText(this.props.text) }}>
                </label>

                {this.props.kbLink == null ? null :
                    <button
                        type='button'
                        className={kbLinkClass}
                        onClick={this.onKBTooltipClick}
                        data-tip={kbTooltip}
                        data-html={kbTooltip != null ? true : null}>
                    </button>}

                {this.props.infoClick == null ? null :
                    <button
                        type='button'
                        className={infoClass}
                        onClick={this.props.infoClick}
                        data-tip={infoTooltip}
                        data-html={infoTooltip != null ? true : null}>
                    </button>
                }
            </div>);
        }
        return null;
    }

    private sanitizeText(text: string) {
        const sanitizeTags = { ...LocalizationService.PBrB, ...LocalizationService.SubSup };
        return this.props.localization.sanitizeText(text, sanitizeTags);
    }

    private onKBTooltipClick(event: React.MouseEvent) {
        window.open(this.props.kbLink, '_blank');
    }
}
