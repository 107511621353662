import * as React from 'react';

import { ICheckboxGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/checkbox-group-props';
import { TooltipType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { ControlHeader } from '../../ControlHeader';
import { isControlHidden } from '../../MainMenuHelper';
import { Checkbox } from '../Checkbox/Checkbox';

export class CheckboxGroup extends React.PureComponent<ICheckboxGroupProps> {
    constructor(props?: ICheckboxGroupProps) {
        super(props);

        this.onItemCheck = this.onItemCheck.bind(this);
        this.onInfoClick = this.onInfoClick.bind(this);
    }

    public override render() {
        if (isControlHidden(this.props)) {
            return null;
        }

        const groupId = `${this.props.controlId}-group`;

        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled ? this.props.tooltipDisabled : this.props.tooltip;

        return (
            <div data-control-id={this.props.controlId} className={`control react-checkbox-group-control ${this.props.sizeClass}`}>

                <div className='control-container'>
                    <ControlHeader
                        text={this.props.title}
                        controlId={groupId}
                        tooltip={tooltipText}
                        tooltipTitle={tooltipTitle}
                        localization={this.props.localization}
                    />

                    {this.props.tooltipType == TooltipType.Popup &&
                        <button
                            type='button'
                            className='control-tooltip-popup sprite sprite-info-tooltip'
                            onClick={this.onInfoClick}
                            data-tip={this.props.localization.getString('Agito.Hilti.Profis3.ControlTooltip.Popup')}>
                        </button>
                    }
                </div>

                <div
                    className='checkboxes'
                    data-control-id={groupId} >
                    {
                        this.props.items.map((item) =>
                            <Checkbox
                                controlId={`${this.props.controlId}-${item.value}`}
                                key={`${this.props.controlId}-${item.value}`}
                                value={item.value}
                                text={item.text}
                                checked={this.props.checkedValue.includes(item.value)}
                                disabled={item.disabled || this.props.disabled}
                                hidden={this.props.hidden}
                                localization={this.props.localization}
                                size={null}
                                title={null}
                                type={Checkbox}
                                checkedChanged={this.onItemCheck}
                                tooltip={item.tooltip}
                                tooltipTitle={item.tooltipTitle}
                                tooltipType={item.tooltiptype}
                                infoClicked={item.onInfoClick}
                            />)
                    }
                </div>
            </div>
        );
    }

    private onItemCheck(checked: boolean, value: number) {
        if (this.props.valueChanged != null) {
            if (checked && !this.props.checkedValue.includes(value)) {
                const checkedValue = this.props.checkedValue.slice();
                checkedValue.push(value);
                this.props.valueChanged(checkedValue);
            }
            else if (!checked && this.props.checkedValue.includes(value)) {
                const index = this.props.checkedValue.indexOf(value);
                const checkedValue = this.props.checkedValue.filter(item => item !== value);
                this.props.valueChanged(checkedValue);
            }
        }
    }

    private onInfoClick() {
        if (this.props.infoClicked != null) {
            this.props.infoClicked();
        }
    }
}
