import { IControlProps } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { IGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/group-props';
import { IRotateProps } from '@profis-engineering/pe-ui-common/entities/main-menu/rotate-pros';
import { ITabGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/tab-group-props';
import { IToggleButtonGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-button-group-props';
import { ControlType } from './ControlInfo';

export function isControlHidden(controlProps: IControlProps): boolean {
    switch (controlProps.type) {
        case ControlType.Rotate: {
            const rotateProps = controlProps as IRotateProps;
            return rotateProps.hidden || (rotateProps.rotateLeftHidden && rotateProps.rotateRightHidden);
        }
        case ControlType.Group: {
            const groupProps = controlProps as IGroupProps;
            return groupProps.hidden || groupProps.controlsOrder == null || groupProps.controlsOrder.every(id => (groupProps.controls ? isControlHidden(groupProps.controls[id]) : true));
        }
        case ControlType.ToggleButtonGroup: {
            const toggleButtonGroupProps = controlProps as IToggleButtonGroupProps;
            return toggleButtonGroupProps.hidden || (toggleButtonGroupProps.items || []).every(item => item.hidden) && (toggleButtonGroupProps.itemsCollapsed || []).every(item => item.hidden);
        }
        case ControlType.TabGroup: {
            const tabGroupProps = controlProps as ITabGroupProps;
            return tabGroupProps.hidden || tabGroupProps.controlsOrder == null || tabGroupProps.controlsOrder.every(id => (tabGroupProps.childControls ? isControlHidden(tabGroupProps.childControls[id]) : true));
        }
        default:
            return controlProps.hidden;
    }
}

export function buildHtmlTooltip(tooltip?: string, tooltipTitle?: string) {
    let result = '';

    if (tooltipTitle != null && tooltipTitle != '') {
        result += `<p class="title">${tooltipTitle}</p>`;
    }

    if (tooltip != null && tooltip != '') {
        result += `<p class="content">${tooltip}</p>`;
    }

    return result == '' ? null : result;
}
