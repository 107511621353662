import { Injectable } from '@angular/core';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import {
    IIntegrationsNotificationPopupComponentInput
} from '@profis-engineering/pe-ui-shared/components/integrations-notification-popup';
import {
    IntegrationsDocumentServiceBase
} from '@profis-engineering/pe-ui-shared/services/integrations-document.service.base';
import {
    DocumentModel
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Document';
import {
    DocumentIntegrationType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Enums';
import { IntegrationsNotificationService } from './integrations-notification.service';
import { LocalizationService } from './localization.service';
import { ModalService } from './modal.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';
import { FeatureVisibilityService } from './feature-visibility.service';

@Injectable({
    providedIn: 'root'
})
export class IntegrationsDocumentService extends IntegrationsDocumentServiceBase {
    private displayNotificationPopup = false;
    private _risaDocuments: DocumentModel[] = [];
    private _ramDocuments: DocumentModel[] = [];

    constructor(
        private userService: UserService,
        private localizationService: LocalizationService,
        private modalService: ModalService,
        private integrationsNotificationService: IntegrationsNotificationService,
        private userSettingsService: UserSettingsService,
        private featureVisibilityService: FeatureVisibilityService
    ) {
        super();
     }

    public get RisaDocuments(): DocumentModel[] {
        return this._risaDocuments;
    }

    public set RisaDocuments(value: DocumentModel[]) {
        this._risaDocuments = value ?? [];
    }

    public get RamDocuments(): DocumentModel[] {
        return this._ramDocuments;
    }

    public set RamDocuments(value: DocumentModel[]) {
        this._ramDocuments = value ?? [];
    }

    public initialize(data: { [key: string]: DocumentModel[] }) {
        if (this.userSettingsService.settings.application.general.risaEnabled.value) {
            this._risaDocuments = data['Risa'] ?? [];
        }
        if (this.userSettingsService.settings.application.general.ramEnabled.value) {
            this._ramDocuments = data['Ram'] ?? [];
        }
    }

    public handleUnopenedExternalDesignsPopup() {
        this.handleDisplayNotificationFlag();

        if (this.displayNotificationPopup && !this.userService.design) {
            let hasUnopenedDesigns = false;
            let popupServicesMessage: string = null;

            // check risa/ram documents
            if (this._risaDocuments.length > 0) {
                hasUnopenedDesigns = true;
                popupServicesMessage = this.concatenateUnopenedExternalDesignsMessage(
                    popupServicesMessage,
                    this._risaDocuments,
                    'RISA');
            }

            if (this._ramDocuments.length > 0) {
                hasUnopenedDesigns = true;
                popupServicesMessage = this.concatenateUnopenedExternalDesignsMessage(
                    popupServicesMessage,
                    this._ramDocuments,
                    'RAM');
            }

            if (hasUnopenedDesigns) {
                const popUpKey = this.getPopupTextKey();
                const props: IIntegrationsNotificationPopupComponentInput = {
                    id: 'integrations-notification-popup-prompt',
                    title: this.localizationService.getString('Agito.Hilti.Profis3.UnopenedExternalDesigns.Popup.Title'),
                    message: formatKeyValue(
                            this.localizationService.getString(popUpKey, {tags: LocalizationService.PBrB}),
                            {
                                services: popupServicesMessage
                            }
                        ),
                    confirmButtonText: this.localizationService.getString('Agito.Hilti.Profis3.UnopenedExternalDesigns.Popup.Confirm')
                };
                this.modalService.openIntegrationsNotification(props);
            }
            else {
                popupServicesMessage = popupServicesMessage + '.';
            }
        }
    }
    private getPopupTextKey(): string {
        return this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage') ?
                'Agito.Hilti.Profis3.Homepage.UnopenedExternalDesigns.Popup.Message' : 'Agito.Hilti.Profis3.UnopenedExternalDesigns.Popup.Message';
    }
    public exportDocumentReportButtonMessage(
        documentName: string,
        documentIntegrationType: DocumentIntegrationType
    ): string {
        let baseMessage: string;

        if (documentIntegrationType == DocumentIntegrationType.Risa) {
            baseMessage = this.localizationService.getString('Agito.Hilti.Profis3.DocumentModel.Risa.BaseMessage');
        }
        else if (documentIntegrationType == DocumentIntegrationType.Ram) {
            baseMessage = this.localizationService.getString('Agito.Hilti.Profis3.DocumentModel.Ram.BaseMessage');
        }

        const result = baseMessage + documentName;

        return result;
    }

    public registerNewDocumentNotificationHandler(): void {
        this.integrationsNotificationService.registerNewDocumentNotificationHandler((document: DocumentModel) => {

            if (document.IntegrationType == DocumentIntegrationType.Risa) {
                this.addDocumentNotification(this._risaDocuments, document);
            }
            else if (document.IntegrationType == DocumentIntegrationType.Ram) {
                this.addDocumentNotification(this._ramDocuments, document);
            }
        });
    }

    private addDocumentNotification(documents: DocumentModel[], newDocument: DocumentModel) {
        // Don't add duplicates
        if (documents.find(d => d.DocumentId == newDocument.DocumentId)) {
            return;
        }

        documents.push(newDocument);

        if (documents.length == 1) {
            this.handleUnopenedExternalDesignsPopup();
        }
    }

    private handleDisplayNotificationFlag() {
        if (
            (this.userSettingsService.settings.application.general.risaEnabled.value && this._risaDocuments?.length > 0)
            ||
            (this.userSettingsService.settings.application.general.ramEnabled.value && this._ramDocuments?.length > 0)
        ) {
            this.displayNotificationPopup = true;
        }
        else {
            this.displayNotificationPopup = false;
        }
    }

    private concatenateUnopenedExternalDesignsMessage(concatenationString: string, documentModel: DocumentModel[], appender: string) {
        if (documentModel.length > 0) {
            if (concatenationString == null) {
                concatenationString = appender;
            }
            else {
                concatenationString = concatenationString + ', ' + appender;
            }
        }

        return concatenationString;
    }
}
