<div class="project-header">
    {{translate('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Archive.ProjectFolderHeader')}}
</div>

<div class="archive-container">
    <div class="table-archive-projects-row-header">
        <div>{{translate('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Archive.ProjectFolder')}}</div>
        <div>{{translate('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Archive.Designs')}}</div>
        <div>{{translate('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Archive.Members')}}</div>
        <div>{{translate('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Archive.Created')}}</div>
        <div>{{translate('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Archive.Archived')}}</div>
        <div class="action-buttons-header">&nbsp;</div>
    </div>
    <div class="archive-container-virtual-scroller-wrapper">
        <virtual-scroller #scrollProjects [parentScroll]="scrollElement" [scrollAnimationTime]="0" [ngStyle]="{ 'height': maxProjectsTableRows + 'px' }">
            <div class="table-archive">
                <div *ngFor="let item of projects" class="table-archive-row-project">
                    <div><span>{{item.projectName}}</span></div>
                    <div>{{item.designs}}</div>
                    <div>{{item.members}}</div>
                    <div>{{item.created}}</div>
                    <div>{{item.archived}}</div>
                        <div class="action-buttons-wrapper">
                            <div class="action-buttons">
                                <button class="button-delete collapse-button"
                                    (click)="deleteArchivedProject(item.projectId)" [disabled]="submitted"
                                    [ngbTooltip]="'Agito.Hilti.Profis3.Archive.Delete' | l10n:localization.selectedLanguage"
                                    placement="top">
                                    <div class="sprite sprite-trash"></div>
                                </button>

                                <button class="button-restore collapse-button" (click)="restoreProject(item.projectId)"
                                    [disabled]="submitted"
                                    [ngbTooltip]="'Agito.Hilti.Profis3.Archive.Restore' | l10n:localization.selectedLanguage"
                                    placement="top">
                                    <div class="sprite sprite-refresh-l-dark"></div>
                                </button>
                            </div>
                        </div>
                </div>
            </div>
        </virtual-scroller>
    </div>
</div>

<div class="document-header">
    {{translate('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Archive.DocumentHeader')}}
</div>

<!-- Grid for archived documents -->
<div class="archive-container">
    <div class="table-archive-designs-row-header">
        <div>{{translate('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Archive.Document')}}</div>
        <div>{{translate('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Archive.ProjectFolder')}}</div>
        <div>&nbsp;</div>
        <div>{{translate('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Archive.Created')}}</div>
        <div>{{translate('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Archive.Archived')}}</div>
        <div class="action-buttons-header">&nbsp;</div>
    </div>
    <div class="archive-documents-container-virtual-scroller-wrapper">
        <virtual-scroller #scrollArchivedDocuments [parentScroll]="scrollElementDesign" [scrollAnimationTime]="0" [ngStyle]="{ 'height': maxDocumentTableRows + 'px' }">
            <div class="table-archive">
                <div *ngFor="let item of archivedDocuments" class="table-archive-row-document">
                    <div><span>{{item.name}}</span></div>
                    <div><span>{{item.projectname}}</span></div>
                    <div>&nbsp;</div>
                    <div>{{item.created}}</div>
                    <div>{{item.archived}}</div>
                    <div class="action-buttons-wrapper">
                        <div class="action-buttons">
                            <button class="button-delete collapse-button"
                                (click)="deleteArchivedDocument(item.documentId)" [disabled]="submitted"
                                [ngbTooltip]="'Agito.Hilti.Profis3.Archive.DeleteDocument' | l10n:localization.selectedLanguage"
                                placement="top">
                                <div class="sprite sprite-trash"></div>
                            </button>

                            <button class="button-restore collapse-button" (click)="restoreDocument(item.documentId)" [disabled]="submitted"
                                [ngbTooltip]="'Agito.Hilti.Profis3.Archive.RestoreDocument' | l10n:localization.selectedLanguage"
                                placement="top">
                                <div class="sprite sprite-refresh-l-dark"></div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </virtual-scroller>
    </div>
</div>