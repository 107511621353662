import { UserSettingsC2C } from '@profis-engineering/pe-ui-c2c/entities/user-settings';
import { UserSettingsSection } from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { UserSettingsPe } from '@profis-engineering/pe-ui-shared/entities/user-settings';

import { ModulesService } from '../../services/modules.service';

export function registerUserSettingsInfoPe(modulesService: ModulesService) {
    const settings = new UserSettingsPe(false);
    const userSettingsInfoPe = {
        userSettingsKey: 'pe',
        userSettings: {} as unknown as UserSettingsSection,
        userSettingsRoot: {
            profileSizeFavorites: settings.profileSizeFavorites,
            steelTypeFavorites: settings.steelTypeFavorites,
            anchorFavorites: settings.anchorFavorites,

            concretevirtualtourSeen: settings.concretevirtualtourSeen,
            handrailvirtualtourSeen: settings.handrailvirtualtourSeen,
            smartanchorvirtualtourSeen: settings.smartanchorvirtualtourSeen,
            searchSolutionsCount: settings.searchSolutionsCount,
            smartdesignvirtualtourseen: settings.smartdesignvirtualtourseen,

            generateReportFollowUpActionsConcreteHidden: settings.generateReportFollowUpActionsConcreteHidden,
        } as unknown as UserSettingsSection,
        quickStart: settings.quickStart as unknown as UserSettingsSection,
        applicationImportLoads: settings.applicationImportLoads as unknown as UserSettingsSection,
        applicationModelDisplayOptions: settings.applicationModelDisplayOptions as unknown as UserSettingsSection
    };

    modulesService.addUserSettingsInfo(userSettingsInfoPe);
}

export function registerUserSettingsInfoC2C(modulesService: ModulesService) {
    const settings = new UserSettingsC2C(false);
    const userSettingsInfoC2C = {
        userSettingsKey: 'c2c',
        userSettings: {} as unknown as UserSettingsSection,
        userSettingsRoot: {
            rebarFavorites: settings.rebarFavorites,
            overlayvirtualtourSeen: settings.overlayvirtualtourSeen
        } as unknown as UserSettingsSection,
        applicationSettings: { concrete2Concrete: settings.application.concrete2Concrete } as unknown as UserSettingsSection,
        quickStart: settings.quickStart as unknown as UserSettingsSection,
        applicationImportLoads: settings.applicationImportLoads as unknown as UserSettingsSection,
        applicationModelDisplayOptions: settings.applicationModelDisplayOptions as unknown as UserSettingsSection
    };

    modulesService.addUserSettingsInfo(userSettingsInfoC2C);
}