import { Injectable } from '@angular/core';
import { ConsoleApiName } from '@datadog/browser-core';
import { environment } from '../../environments/environment';
import { datadogLogs } from '@datadog/browser-logs';
import { RemoteLoggingService } from './remote-logging.service';
import { FeatureVisibilityService } from './feature-visibility.service';

@Injectable({
    providedIn: 'root'
})
export class RemoteLoggingServiceImpl extends RemoteLoggingService {
    constructor(private featureVisibilityService: FeatureVisibilityService) {
        super();
    }

    override init(userId: string, email: string): void {
        // feature is enabled for specific users or for specific countries in wich user is located
        const enabled = this.featureVisibilityService.isFeatureEnabled('DataDogRemoteLogging') || environment.datadogLogEnabled;
        if (enabled) {
            this.initLogger(userId, email);
        }
    }

    private initLogger(userId: string, email: string) {
        datadogLogs.init({
            clientToken: environment.datadogLogClientToken,
            site: environment.datadogLogSite,
            service: environment.datadogLogService,
            env: environment.datadogLogEnvironment,
            version: environment.applicationVersion,
            sessionSampleRate: 100,
            forwardConsoleLogs: <ConsoleApiName[]>environment.datadogLogForwardConsoleLogs,
            forwardErrorsToLogs: environment.datadogLogForwardErrorsToLogs,
            beforeSend: (log) => {
                // do not send empty message
                if (log.message === '') {
                    return false;
                }

                return true;
            }
        });

        datadogLogs.clearUser();
        datadogLogs.setUser({ id: userId, name: email, email: email });

        datadogLogs.logger.info(`Datadog logging initialized `);
    }
}
