import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { RoutingServiceBase } from '@profis-engineering/pe-ui-common/services/routing.common';

@Injectable({
    providedIn: 'root'
})
export class RoutingService extends RoutingServiceBase {
    constructor(
        private router: Router,
        private ngZone: NgZone
    ) {
        super();
    }

    public get currentUrl() {
        return new URL(window.location.href);
    }

    public get currentPath() {
        return this.currentUrl.pathname;
    }

    public getUrl(url: string, queryParams?: { [key: string]: any }) {
        const retVal = this.router.parseUrl(url);
        retVal.queryParams = queryParams;
        return retVal.toString();
    }

    public reloadWindow(url: string) {
        NgZone.assertInAngularZone();

        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([url]));
    }

    public navigateToUrl(url: string) {
        NgZone.assertInAngularZone();

        return this.router.navigateByUrl(url);
    }

    public navigateToUrlWithData(url: string, dataToSend: { [key: string]: any }){
        return this.router.navigate([url], { state: dataToSend });
    }
}
