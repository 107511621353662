import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

const EMAIL_REGEXP = /^[a-z0-9@!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

export function emailsValidator(emailSeparator?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (value == null || value == '') {
            return null;
        }

        if (emailSeparator != null && emailSeparator.length > 0) {
            const emails = value.trim()
                .split(emailSeparator)
                .filter((value: string) => value != '');
            if (emails.length == 0) {
                return {
                    emails: {
                        value: control.value
                    }
                };
            }

            if (emails.every((it: string) => EMAIL_REGEXP.test(it.trim()))) {
                return null;
            }

            return {
                emails: {
                    value: control.value
                }
            };
        }

        // Fallback to built-in email validator
        return Validators.email(control);
    };
}
