import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    getJSONDateWithTimezoneOffset
} from '@profis-engineering/pe-ui-common/helpers/date-time-helper';

import { environment } from '../../environments/environment';
import { npsMinDesigns } from '../module-constants';
import { ApiService } from './api.service';
import { CommonTrackingService } from './common-tracking.service';
import { DocumentService } from './document.service';
import { ModalService } from './modal.service';
import { OfflineService } from './offline.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class NpsService {
    constructor(
        private offlineService: OfflineService,
        private modalService: ModalService,
        private userSettingsService: UserSettingsService,
        private userService: UserService,
        private apiService: ApiService,
        private commonTrackingService: CommonTrackingService,
        private documentService: DocumentService
    ) { }

    private get isNpsAvailable() {
        return this.offlineService.isOffline || this.documentService.getNumberOfDocumentsOwnedByUser() > npsMinDesigns;
    }

    public async handleSurvey(): Promise<void> {
        const showPopup = await this.getIsNewSurveyAvailable();
        if(showPopup) {
            const userId = environment.includeHCHeaders ? this.userService.authentication.userId : null;
            const userName = environment.includeHCHeaders ? this.userService.authentication.userName : null;
            const date = getJSONDateWithTimezoneOffset();
            const userDetails = this.commonTrackingService.getTrackingUserData(date.timezoneOffset);
            const region = this.userSettingsService.getCommonRegionById(this.userSettingsService.settings.application.general.regionId.value);

            const accessToken = environment.includeHCHeaders ? null : this.userService.authentication.accessToken;

            return this.modalService.openNpsSurveyPopup({
                userId, // user id
                username: userName, // username
                applicationId: environment.applicationId, // change this to actual value (develop testing example: "2D65CFDA-03DC-4F2D-A07D-38713157DC88")
                applicationVersion: environment.applicationVersion, // application version
                regionId: region.id.toString(), // region id (develop testing example: "242")
                region: region.countryCode, // region
                languageId: this.userSettingsService.settings.application.general.languageId.value.toString(), // language id
                accessToken,  // access token
                baseUrlPath: environment.npsSurveyWebUIUrl, // web UI url path
                debugScripts: environment.npsDebugScripts, // determine which scripts to load
                customerCountry: userDetails.CustomerCountry,
                countryOfResidence: userDetails.CountryOfResidence,
                customerOriginId: userDetails.CustomerOriginId,
                buyingCustomerOriginId: userDetails.BuyingCustomerOriginId,
                timezoneOffset: userDetails.TimezoneOffset.toString(),
                operatingSystem: userDetails.OperatingSystem
            }).closed;
        }
        else {
            return Promise.resolve();
        }
    }

    private async getIsNewSurveyAvailable(): Promise<boolean> {
        if (!environment.npsSurveyEnabled) {
            return false;
        }

        if (!this.isNpsAvailable) {
            return false;
        }

        const applicationId = environment.applicationId;
        const languageId = this.userSettingsService.settings.application.general.languageId.value;
        const regionId = this.userSettingsService.settings.application.general.regionId.value;

        const url = `${environment.npsSurveyWebServiceUrl}/available?ApplicationId=${applicationId}&LanguageId=${languageId}&RegionId=${regionId}`;

        return (await this.apiService.request<boolean>(new HttpRequest('GET', url), { supressErrorMessage: true })).body;
    }
}
