import { LocalizationService } from '../../services/localization.service';
import { LeftNavigationPrimaryButtons } from './home-page.common';
import { ITreeViewItem } from './tree-view/tree-view.component';

export function normalizeArray<T>(array: T[], indexKey: keyof T) {
    const normalizedObject: { [key: string]: T } = {};
    array.forEach(element => {
        const key = String(element[indexKey]);
        normalizedObject[key] = element;
    });

    return normalizedObject;
}

export function getVirtualTourMyProjectsTreeData(localizationService: LocalizationService, expanded: boolean): ITreeViewItem<string> {
    const subProject = localizationService.getString('Hilti.Profis3.HomePageWalktrough.MyProject.SubProjectName')
    const project = localizationService.getString('Hilti.Profis3.HomePageWalktrough.MyProject.ProjectName')
    return {
        id: 'virtual-tour-my-projects',
        icon: 'my-projects',
        name: localizationService.getString('Agito.Hilti.Profis3.HomePage.MyProjects'),
        expanded: expanded,
        subItems: [{
            id: 'my-project-1',
            expanded: true,
            icon: 'folder',
            name: `${project}`,
            subItems: [{
                id: 'my-sub-project-1',
                expanded: true,
                icon: 'folder',
                name: `${subProject} 1`,
                subItems: [{
                    id: 'my-sub-project-2',
                    expanded: true,
                    icon: 'folder',
                    name: `${subProject} 2`,
                    subItems: [{
                        id: 'my-sub-project-3',
                        expanded: true,
                        icon: 'folder',
                        name: `${subProject} 3`
                    }]
                }]
            }]
        } as ITreeViewItem<string>],
    };
}
export function getVirtualTourSharedProjectsTreeData(localizationService: LocalizationService, expanded: boolean): ITreeViewItem<string> {
    return {
        id: LeftNavigationPrimaryButtons.Shared,
        icon: 'shared',
        name: localizationService.getString('Agito.Hilti.Profis3.HomePage.Shared'),
        expanded: expanded,
        subItems: [{
            id: 'project-1',
            expanded: true,
            icon: 'shared-by-me',
            name: localizationService.getString('Agito.Hilti.Profis3.HomePage.Shared.SharedByMe'),
            subItems: [{
                id: 'shared-sub-project-2',
                expanded: true,
                icon: 'folder',
                name: localizationService.getString('Hilti.Profis3.HomePageWalktrough.Shared.SharedByMe')
            },
            {
                id: 'project-1',
                expanded: true,
                icon: 'shared-with-me',
                name: localizationService.getString('Agito.Hilti.Profis3.HomePage.Shared.SharedWithMe'),
                subItems: [{
                    id: 'shared-sub-project-3',
                    expanded: true,
                    icon: 'folder',
                    name: localizationService.getString('Hilti.Profis3.HomePageWalktrough.Shared.SharedWithMe')
                }]
            }]
        } as ITreeViewItem<string>],
    };
}