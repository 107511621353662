import { Component, Input, OnChanges } from '@angular/core';
import {
    DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    CommonRegion
} from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import {
    Separator as SeparatorEntity
} from '@profis-engineering/pe-ui-common/entities/code-lists/separator';
import {
    KnownRegion
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    AppSettingsNumericTextBoxProps, QuickStartType
} from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { Region } from '@profis-engineering/pe-ui-shared/entities/code-lists/region';
import { ProjectCodeList } from '@profis-engineering/pe-ui-shared/enums/project-code-list';
import {
    AppSettingsHelperWithUnit
} from '@profis-engineering/pe-ui-shared/helpers/app-settings-helper';
import { PropertyMetaData } from '@profis-engineering/pe-ui-shared/properties/properties';

import { environment } from '../../../environments/environment';
import {
    getNumericTextBoxDecimalSeparator, getNumericTextBoxGroupSeparator, IAppSettingsDefaultParametersData
} from '../../helpers/app-settings-helper';
import { CodeListService } from '../../services/code-list.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { NumberService } from '../../services/number.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';

@Component({
    selector: 'app-app-settings-default-parameters',
    templateUrl: './app-settings-default-parameters.component.html',
    styleUrls: ['./app-settings-default-parameters.component.scss']
})
export class AppSettingsDefaultParametersComponent implements OnChanges {
    @Input()
    public submitted = false;

    @Input()
    public region!: CommonRegion;

    @Input()
    public decimalSeparator!: SeparatorEntity;

    @Input()
    public groupSeparator!: SeparatorEntity;

    @Input()
    public concreteSteelCalculationMethodId!: number;

    @Input()
    public defaultParametersData!: IAppSettingsDefaultParametersData;

    public anchorPlateFactor?: AppSettingsNumericTextBoxProps;
    public anchorPlateThickness?: AppSettingsNumericTextBoxProps;
    public permanentLoads?: AppSettingsNumericTextBoxProps;
    public variableLoads?: AppSettingsNumericTextBoxProps;
    public minimumAnchorToProfileDistance?: AppSettingsNumericTextBoxProps;
    public steelSafetyFactorGammaM0?: AppSettingsNumericTextBoxProps;
    public steelSafetyFactorGammaM1?: AppSettingsNumericTextBoxProps;
    public steelSafetyFactorGammaM2?: AppSettingsNumericTextBoxProps;
    public materialSafetyFactor?: AppSettingsNumericTextBoxProps;
    public minimumConcreteCover?: AppSettingsNumericTextBoxProps;
    public steelCapacityFactor?: AppSettingsNumericTextBoxProps;
    public weldsCapacityFactor?: DropdownProps<number>;
    public concreteCapacityFactor?: AppSettingsNumericTextBoxProps;
    public stoSteelSafetyFactorGammaM?: AppSettingsNumericTextBoxProps;
    public inSteelSafetyFactorGammaM0?: AppSettingsNumericTextBoxProps;
    public inSteelSafetyFactorGammaM1?: AppSettingsNumericTextBoxProps;
    public inSteelSafetyFactorGammaMw?: AppSettingsNumericTextBoxProps;
    public inSteelSafetyFactorGammaMb?: AppSettingsNumericTextBoxProps;

    public dataLoaded = false;

    private appSettingsHelper: AppSettingsHelperWithUnit;

    private regionDefaultValues!: {
        materialSafetyFactor?: number;
    };

    private peDataAvailable = false;

    constructor(
        private localization: LocalizationService,
        private userSettings: UserSettingsService,
        private unit: UnitService,
        private codeList: CodeListService,
        private commonCodeList: CommonCodeListService,
        private numberService: NumberService
    ) {
        this.peDataAvailable = this.codeList?.projectCodeLists != null;
        this.appSettingsHelper = new AppSettingsHelperWithUnit(this.localization, this.userSettings, this.codeList, this.commonCodeList, this.unit, this.numberService, environment.useDevFeatures);
    }

    public get getNumericTextBoxDecimalSeparator() {
        return getNumericTextBoxDecimalSeparator(this.decimalSeparator, this.localization, this.userSettings);
    }

    public get getNumericTextBoxGroupSeparator() {
        return getNumericTextBoxGroupSeparator(this.groupSeparator, this.localization, this.userSettings);
    }


    public get supportsAnchorPlateThicknessSettings() {
        return this.anchorPlateThickness != null;
    }

    public get supportsAnchorPlateFactorSettings() {
        return this.anchorPlateFactor != null;
    }

    public get supportsAnchorPlateSettings() {
        return this.supportsAnchorPlateThicknessSettings
            || this.supportsAnchorPlateFactorSettings;
    }


    public get supportsPermanentLoadsSettings() {
        return this.permanentLoads != null;
    }

    public get supportsVariableLoadsSettings() {
        return this.variableLoads != null;
    }

    public get supportsLoadsSettings() {
        return this.supportsPermanentLoadsSettings
            || this.supportsVariableLoadsSettings;
    }


    public get supportsMinAnchorToProfileDistanceSettings() {
        return this.minimumAnchorToProfileDistance != null;
    }

    public get supportsSteelSafetyFactorGammaM0Settings() {
        return this.steelSafetyFactorGammaM0 != null
            && this.concreteDesignStandardEnBased;
    }


    public get supportsSteelSafetyFactorGammaM1Settings() {
        return this.steelSafetyFactorGammaM1 != null
            && this.concreteDesignStandardEnBased;
    }

    public get supportsSteelSafetyFactorGammaM2Settings() {
        return this.steelSafetyFactorGammaM2 != null
            && this.concreteDesignStandardEnBased;
    }

    public get supportsMaterialSafetyFactorSettings() {
        return this.materialSafetyFactor != null
            && this.appSettingsHelper.isConcreteDesignStandardAiscBased(this.concreteSteelCalculationMethodId);
    }

    public get supportsFactorSettings() {
        return this.supportsSteelSafetyFactorGammaM1Settings
            || this.supportsSteelSafetyFactorGammaM2Settings
            || this.supportsMaterialSafetyFactorSettings;
    }


    public get supportsMinConcreteCoverSettings() {
        return this.minimumConcreteCover != null;
    }


    public get supportsSteelCapacityFactorSettings() {
        return this.steelCapacityFactor != null
            && this.concreteDesignStandardAsBased;
    }

    public get supportsWeldsCapacityFactorSettings() {
        return this.weldsCapacityFactor != null
            && this.concreteDesignStandardAsBased;
    }

    public get supportsConcreteCapacityFactorSettings() {
        return this.concreteCapacityFactor != null
            && this.concreteDesignStandardAsBased;
    }

    public get supportsCapacityFactorSettings() {
        return this.supportsSteelCapacityFactorSettings
            || this.supportsWeldsCapacityFactorSettings
            || this.supportsConcreteCapacityFactorSettings;
    }


    public get supportsStoSteelSafetyFactorGammaMSettings() {
        return this.stoSteelSafetyFactorGammaM != null
            && this.appSettingsHelper.isConcreteDesignStandardStoBased(this.concreteSteelCalculationMethodId);
    }


    public get supportsInSteelSafetyFactorGammaM0Settings() {
        return this.inSteelSafetyFactorGammaM0 != null
            && this.concreteDesignStandardInBased;
    }

    public get supportsInSteelSafetyFactorGammaM1Settings() {
        return this.inSteelSafetyFactorGammaM1 != null
            && this.concreteDesignStandardInBased;
    }

    public get supportsInSteelSafetyFactorGammaMwSettings() {
        return this.inSteelSafetyFactorGammaMw != null
            && this.concreteDesignStandardInBased;
    }

    public get supportsInSteelSafetyFactorGammaMbSettings() {
        return this.inSteelSafetyFactorGammaMb != null
            && this.concreteDesignStandardInBased;
    }

    public get supportsInSteelSafetyFactorsSettings() {
        return this.supportsInSteelSafetyFactorGammaM0Settings
            || this.supportsInSteelSafetyFactorGammaM1Settings
            || this.supportsInSteelSafetyFactorGammaMwSettings
            || this.supportsInSteelSafetyFactorGammaMbSettings;
    }


    private get concreteDesignStandardEnBased() {
        return this.appSettingsHelper.isConcreteDesignStandardEnBased(this.concreteSteelCalculationMethodId);
    }

    private get concreteDesignStandardAsBased() {
        return this.appSettingsHelper.isConcreteDesignStandardAsBased(this.concreteSteelCalculationMethodId);
    }

    private get concreteDesignStandardInBased() {
        return this.appSettingsHelper.isConcreteDesignStandardInBased(this.concreteSteelCalculationMethodId);
    }


    ngOnChanges(): void {
        if (!this.dataLoaded) {
            if (this.peDataAvailable) {
                this.anchorPlateThickness = this.appSettingsHelper.createNumericTextBox('anchor-plate-thickness', 'AnchorPlateThickness', undefined, 3, 500);

                this.anchorPlateFactor = this.appSettingsHelper.createAnchorPlateFactorComponent('anchor-plate-factor', 'AnchorPlateFactor');
                this.anchorPlateFactor.tooltip = {
                    title: this.localization.getString('Agito.Hilti.Profis3.TextBox.SettingsAnchorPlateFactor.Tooltip.Title'),
                    content: this.localization.getString('Agito.Hilti.Profis3.TextBox.SettingsAnchorPlateFactor.Tooltip')
                };

                this.permanentLoads = this.appSettingsHelper.createPermanentLoadsComponent('permanent-loads', 'PermanentLoads');
                this.variableLoads = this.appSettingsHelper.createVariableLoadsComponent('variable-loads', 'VariableLoads');
                this.minimumAnchorToProfileDistance = this.appSettingsHelper.createMinimumAnchorToProfileDistanceComponent('');
                this.steelSafetyFactorGammaM0 = this.appSettingsHelper.createGammaComponent(QuickStartType.None, 'M0');
                this.steelSafetyFactorGammaM1 = this.appSettingsHelper.createGammaComponent(QuickStartType.None, 'M1');
                this.steelSafetyFactorGammaM2 = this.appSettingsHelper.createGammaComponent(QuickStartType.None, 'M2');

                this.materialSafetyFactor = this.appSettingsHelper.createSteelCalculationComponent(
                    QuickStartType.None, 'materialSafetyFactor', PropertyMetaData.Option_MaterialSafetyFactor);
                this.steelCapacityFactor = this.appSettingsHelper.createSteelCalculationComponent(
                    QuickStartType.None, 'steelCapacityFactor', PropertyMetaData.Option_SteelCapacityFactor);
                this.weldsCapacityFactor = this.appSettingsHelper.createCapacityFactorDropdownControl(
                    QuickStartType.None, 'weldsCapacityFactor');
                this.concreteCapacityFactor = this.appSettingsHelper.createSteelCalculationComponent(
                    QuickStartType.None, 'concreteCapacityFactor', PropertyMetaData.Option_ConcreteCapacityFactor);

                this.minimumConcreteCover = this.appSettingsHelper.createNumericTextBox(
                    'minimum-concrete-cover', 'MinimumConcreteCover', undefined, PropertyMetaData.Option_MinimumConcreteCover.minValue, PropertyMetaData.Option_MinimumConcreteCover.maxValue
                );
                this.stoSteelSafetyFactorGammaM = this.appSettingsHelper.createStoSteelSafetyFactorGammaMComponent('stoSteelSafetyFactorGammaM');
                this.inSteelSafetyFactorGammaM0 = this.appSettingsHelper.createInGammaComponent(QuickStartType.None, 'M0');
                this.inSteelSafetyFactorGammaM1 = this.appSettingsHelper.createInGammaComponent(QuickStartType.None, 'M1');
                this.inSteelSafetyFactorGammaMw = this.appSettingsHelper.createInGammaComponent(QuickStartType.None, 'Mw');
                this.inSteelSafetyFactorGammaMb = this.appSettingsHelper.createInGammaComponent(QuickStartType.None, 'Mb');

                this.regionDefaultValues = { materialSafetyFactor: undefined };

                this.setMaterialSafetyFactorValues(this.region.id ?? KnownRegion.Unknown);
            }

            this.dataLoaded = true;
        }
    }


    public getNumericTextBoxPlaceholder(property: string): number | string {
        const region = this.appSettingsHelper.getRegionById(this.region.id);

        switch (property) {
            case 'defaultAnchorPlateThickness':
                return region.thickness;

            case 'defaultVariableLoads':
                return region.safetyFactorForVariableLoads;

            case 'defaultPermanentLoads':
                return region.safetyFactorForPermamentLoads;

            case 'defaultAnchorPlateFactor':
                return region.anchorPlateFactor;

            case 'materialSafetyFactor':
                return this.appSettingsHelper.setSteelCalculationPlaceholder(this.regionDefaultValues.materialSafetyFactor ?? PropertyMetaData.Option_MaterialSafetyFactor.defaultValue ?? 0);

            case 'steelCapacityFactor':
                return this.appSettingsHelper.setSteelCalculationPlaceholder(PropertyMetaData.Option_SteelCapacityFactor.defaultValue ?? 0);

            case 'concreteCapacityFactor':
                return this.appSettingsHelper.setSteelCalculationPlaceholder(PropertyMetaData.Option_ConcreteCapacityFactor.defaultValue ?? 0);

            case 'minimumConcreteCover':
                return PropertyMetaData.Option_MinimumConcreteCover.defaultValue ?? 0;

            default:
                return this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.Default');
        }
    }

    public getNumericTextBoxUnit(property: string) {
        const region = this.region;

        switch (property) {
            case 'defaultAnchorPlateThickness':
            case 'defaultMinimumAnchorToProfileDistance':
            case 'defaultMinimumConcreteCover':
                return region.defaultUnitLength;
            default:
                return Unit.None;
        }
    }


    private setMaterialSafetyFactorValues(regionId: number) {
        // TODO: get min/max from DB.
        // min/max
        const minValue: number = this.appSettingsHelper.getMaterialSafetyFactorMinValue(regionId) ?? 0;
        this.setDefaultMaterialSafetyFactorValues(minValue);

        // set region specific placeholder
        const regionEntity = (this.codeList.projectCodeLists[ProjectCodeList.Region] as Region[]).find(region => region.id == regionId);
        this.regionDefaultValues.materialSafetyFactor = regionEntity?.defaultMaterialSafetyFactor ?? 0;
    }

    private setDefaultMaterialSafetyFactorValues(minValue: number) {
        this.materialSafetyFactor.minValue = minValue;

        if (this.materialSafetyFactor.value != null &&
            this.materialSafetyFactor.value < minValue) {
            this.materialSafetyFactor.value = minValue;
        }
    }
}
