

import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { CallbackService } from '../services/callback.service';
import { handleGuardAction } from './guard-handler';

export const logoutCallbackGuard: CanActivateFn = () => {
    const router = inject(Router);
    const callbackService = inject(CallbackService);

    return handleGuardAction(router, async () => {
        await callbackService.processLogoutCallback();

        return true;
    });
};
