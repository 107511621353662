import { Injectable } from '@angular/core';

/**
 * AppStorage interface
 */
export interface IAppStorageService {
    get<T>(key: string): T;
    set(key: string, value: any): void;
    remove(key: string): void;
}

@Injectable({
    providedIn: 'root'
})
/**
 * Local storage dictionary
 */
export class AppStorageService implements IAppStorageService {
    get<T>(key: string) {
        return JSON.parse(localStorage.getItem(key)) as T;
    }

    set<T>(key: string, value: T) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    remove(key: string) {
        localStorage.removeItem(key);
    }
}
