import * as React from 'react';

import { ICheckboxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/checkbox-props';
import { TooltipType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { environment } from '../../../../../environments/environment';
import { getMenuControlImageReactData, IMenuControlImageData } from '../../../../helpers/react-helpers';
import { LocalizationService } from '../../../../services/localization.service';
import { ControlHeader } from '../../ControlHeader';
import { buildHtmlTooltip, isControlHidden } from '../../MainMenuHelper';

const keyEnter = 13;
const keySpace = 32;

export class Checkbox extends React.PureComponent<ICheckboxProps> {
    private input: HTMLInputElement;
    private htmlTooltip: string;

    constructor(props?: ICheckboxProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onInfoClick = this.onInfoClick.bind(this);
        this.onIconClick = this.onIconClick.bind(this);
        this.onKBTooltipClick = this.onKBTooltipClick.bind(this);
    }

    public override render() {
        if (isControlHidden(this.props)) {
            return null;
        }

        const inputId = `${this.props.controlId}-checkbox`;
        const checked = this.props.checked != null ? this.props.checked : false;
        const tabIndex = this.props.disabled ? -1 : 0;
        const text = this.props.text != null ? this.props.text : '';

        const iconButtonId = this.props.iconHidden != null ? `${this.props.controlId}-icon-button` : '';
        const iconTooltip = this.props.iconHidden != null ? buildHtmlTooltip(this.props.tooltipDisabled, this.props.tooltipDisabledTitle) : null;

        let imageName: string | undefined = undefined;
        let iconImageData: IMenuControlImageData | undefined = undefined;

        if (this.props.iconHidden != null) {
            imageName = this.props.iconImage;
            let imageStyle = this.props.iconImageStyle;

            if (this.props.iconSelected) {
                imageName = `${this.props.iconImage}-selected`;
                imageStyle = this.props.iconImageSelectedStyle;
            }

            iconImageData = getMenuControlImageReactData(imageName, imageStyle);
        }

        const hasIconImage = iconImageData?.spriteName != null && iconImageData?.spriteName != '' || iconImageData?.elementStyle != null;

        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled && this.props.tooltipDisabled != null ? this.props.tooltipDisabled : this.props.tooltip;

        const infoTooltipKey = 'Agito.Hilti.Profis3.ControlTooltip.Popup';

        const checkboxLabelClass = 'text checkbox-label';
        let controlTooltipInfoClass = 'control-tooltip-popup sprite sprite-info-tooltip';

        if (this.props.tooltipType == TooltipType.Popup) {
            this.htmlTooltip = this.props.tooltip;

            controlTooltipInfoClass += ' info-margin';

            if (this.props.localization.isHtml(this.htmlTooltip)) {
                this.htmlTooltip = this.htmlTooltip.replace(/agt-src="~/g, 'src="' + environment.baseUrl.replace(/\/+$/, ''));
            }
        }

        let tooltipContent: string;
        if (tooltipText != null || tooltipTitle != null) {
            tooltipContent = this.props.title != null && this.props.title != '' && !this.props.disabled ? null : buildHtmlTooltip(tooltipText, tooltipTitle);
        }

        return (
            <div data-control-id={this.props.controlId} className={`control react-checkbox-control ${this.props.sizeClass}`} data-tip={tooltipContent} data-html={tooltipContent != null ? true : null}>

                <ControlHeader
                    text={this.props.title}
                    controlId={inputId}
                    tooltip={tooltipText}
                    tooltipTitle={tooltipTitle}
                    localization={this.props.localization}
                    infoClick={this.props.tooltipType == TooltipType.Popup ? this.onInfoClick : undefined}
                    infoTooltip={this.props.tooltipType == TooltipType.Popup ? infoTooltipKey : undefined}
                />

                <div className='control-container'>
                    <div className='checkbox'>
                        <div
                            className='checkbox-button'
                            onClick={this.onClick}
                            tabIndex={tabIndex}
                            onKeyPress={this.onKeyPress} >
                            <input
                                data-control-id={inputId}
                                type='checkbox'
                                checked={checked}
                                onChange={this.onChange}
                                disabled={this.props.disabled}
                                ref={(e) => this.input = e} />
                            <span></span>
                        </div>
                        <div>
                            <span
                                className={checkboxLabelClass}
                                onClick={this.onClick}
                                data-tip={tooltipContent}
                                data-html={tooltipContent != null ? true : null}
                                dangerouslySetInnerHTML={{ __html: this.props.localization.sanitizeText(text, LocalizationService.SubSup) }} >
                            </span>

                            {this.props.tooltipType == TooltipType.Popup &&
                                <button
                                    type='button'
                                    className={controlTooltipInfoClass}
                                    onClick={this.onInfoClick}
                                    data-tip={this.props.localization.getString(infoTooltipKey)}>
                                </button>
                            }

                            {this.props.kbLink == null ? null :
                                <button
                                    type='button'
                                    className={controlTooltipInfoClass}
                                    onClick={this.onKBTooltipClick}
                                    data-tip={this.props.localization.getString(this.props.kbTooltip)}></button>
                            }
                        </div>
                    </div>
                    {this.props.iconHidden == null || this.props.iconHidden ? null :
                        <button
                            data-control-id={iconButtonId}
                            type='button'
                            className={`icon-button sprite`}
                            disabled={this.props.iconDisabled}
                            onClick={this.onIconClick}
                            data-tip={iconTooltip}
                            data-html={iconTooltip != null ? true : null}>
                            {hasIconImage ? <div className='image-container'><span data-image-name={imageName} className={`image sprite ${iconImageData?.spriteName ?? ''}`} style={iconImageData?.elementStyle}></span></div> : null}
                        </button>
                    }
                </div>
            </div>
        );
    }

    private onClick(event: React.MouseEvent) {
        this.input.click();
    }

    private onChange(event: React.FormEvent) {
        const checked = (event.target as HTMLInputElement).checked;

        if (this.props.checkedChanged != null) {
            this.props.checkedChanged(checked, this.props.value);
        }
    }

    private onKeyPress(event: React.KeyboardEvent) {
        const eventCode = event.which || event.keyCode || event.charCode;
        if (eventCode == keyEnter || eventCode == keySpace) {
            event.preventDefault();
            this.input.click();
        }
    }

    private onInfoClick(event: React.MouseEvent) {
        if (this.props.infoClicked != null) {
            this.props.infoClicked();
        }
    }

    private onKBTooltipClick(event: React.MouseEvent) {
        window.open(this.props.kbLink, '_blank');
    }

    private onIconClick(event: React.MouseEvent) {
        if (this.props.iconSelectedChanged != null) {
            this.props.iconSelectedChanged(!this.props.iconSelected, this.props.value);
        }
    }
}
