import { ICodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { IDisplayDesign } from '../../entities/display-design';
import { Project } from '@profis-engineering/pe-ui-common/entities/project';
import { LocalizationServiceBase } from '@profis-engineering/pe-ui-common/services/localization.common';
import { NumberServiceBase } from '@profis-engineering/pe-ui-common/services/number.common';
import { CollapsingControls } from '../../entities/collapsing-controls';
import { DesignTemplateFolderDetail } from './template-folder';

export interface IDisplayProject {
    id: string;
    name: string;
    parentId: string;
    rawProject: Project;
    designs: IDisplayDesign[];
    subProjects: IDisplayProject[];
    created: Date;
    owner: boolean;
    isCompanyProject: boolean;
    expanded: boolean;
    readOnly: boolean;
    isSharedByMe: boolean;
}


export enum PendingAction {
    newProject,
    newSubProject,
    renameProject,
    import,
    openDesign,
    loadTemplate,
    deleteTemplate,
    createDesign,
    assignProject,
    archiveProject,
    createQuickStartDesign,
    deleteDesign,
    downloadProject,
    downloadAllDesigns,
    copyDesign,
    renameDesign,
    addToFavorite,
    removeFromFavorite,
    newTemplateFolder,
    shareDesign,
    renameTemplateFolder,
    archiveTemplateFolder,
    downloadTemplateFolder,
    editTemplate,
    createTemplateSuccess,
    RefershTree
}

export enum MenuOptions {
    MENU_SEPERATOR,
    NewProject,
    NewCompanyProject,
    AddSubFolder,
    CreateDesign,
    ConvertToCompanyProject,
    Share,
    ManageSharing,
    Sharing,
    Duplicate,
    DesignSettings,
    Rename,
    Archive,
    Download,
    AddToFavorite,
    RemoveFromFavorite,
    GenerateBulkReports,
    ClearSelectedDesigns,
    NewTemplate,
    AddFolder,
    TemplateSetting,
    EditTemplate
}

export enum LeftNavigationPrimaryButtons {
    AllDesigns = 'AllDesigns',
    Drafts = 'Drafts',
    Favourites = 'Favourites',
    Templates = 'Templates',
    CompanyProjects = 'CompanyProjects',
    Shared = 'Shared',
    MyProjects = 'MyProjects',
    Archive = 'Archive',
    None = 'None'
}

export enum LeftNavigationSecondaryButtons {
    SharedWithMe = 'SharedWithMe',
    SharedByMe = 'SharedByMe',
    TemplatesSharedWithMe = 'TemplatesSharedWithMe',
    TemplatesSharedByMe = 'TemplatesSharedByMe',
    None = 'None'
}

export enum DesignSort {
    Newest = 1,
    Oldest = 2,
    AtoZ = 3,
    ZtoA = 4
}

export interface IGroupedDisplayDesign {
    designType: number;
    designTypeName: string;
    designs: IDisplayDesign[];
    designsCount: number;
}

export function getCodeListTextDeps(
    localizationService?: LocalizationServiceBase,
    numberService?: NumberServiceBase
) {
    return {
        localizationService,
        numberService
    } as ICodeListTextDeps;
}

export enum DisplayDesignType {
    design,
    template
}

export interface ILeftNavigationSelectedState {
    primarySelection: LeftNavigationPrimaryButtons;
    secondarySelection?: LeftNavigationSecondaryButtons;
}

export interface IDisplayDesignInput {
    leftNavigationSelectedState?: ILeftNavigationSelectedState;
    selectedProject?: IDisplayProject;
    selectedTemplateFolder?: DesignTemplateFolderDetail;
}

export interface IDisplayDesignChangeOutput {
    action: PendingAction;
    displayDesigns?: IDisplayDesign[];
}

export interface ISectionCollapsed {
    control: CollapsingControls;
    collapsed: boolean;
}

export interface ISectionsCollapsed {
    // Left Navigation Items (Tree navigation)
    favorites?: ISectionCollapsed;
    templates?: ISectionCollapsed;
    companyProjects?: ISectionCollapsed;
    shared?: ISectionCollapsed;
    sharedByMe?: ISectionCollapsed;
    sharedWithMe?: ISectionCollapsed;
    templatesSharedByMe?: ISectionCollapsed;
    templatesSharedWithMe?: ISectionCollapsed;
    myProjects?: ISectionCollapsed;

    // Middle section
    quickStart?: ISectionCollapsed;
    designsView?: ISectionCollapsed;

    // Right section
    inAppMarketing?: ISectionCollapsed;
    hiltiApplications?: ISectionCollapsed;
    learning?: ISectionCollapsed;
}

export enum DocumentType {
    Document = 1,
    DesignTemplateDocument = 2
}

export enum ProjectType {
    Projects,
    Templates
}
