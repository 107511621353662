<div class="modal-content">
    <div class="modal-header">
        <div class="header-text">
            <span class="white"> {{translate('Agito.Hilti.Profis3.Trial.Welcome.Title')}}</span>
        </div>
    </div>

    <div class="modal-body">
        <p>
            {{isCheckbot ? translate("Agito.Hilti.Profis3.Integrations.Checkbot.Mapping.Modal.Licence.Comparison.SubTitle")
            : translate('Agito.Hilti.Profis3.LicenseComparison')}}
        </p>
        <div class="price-switch-wrapper" *ngIf="showSubscriptionSwitchButton">
            <div>
                <pe-button
                    [id]="'monthly-price'"
                    class="modal-button"
                    [look]="showYearlyPrices ? 'HiltiStyledButtonSecondary' : 'HiltiStyledButtonPrimary'"
                    (buttonClick)="showYearlyPrices = false"
                    [width]="'100%'"
                    [text]="translate('Agito.Hilti.Profis3.LicenseComparison.MonthlySubcription')"></pe-button>
            </div>
            <div>
                <pe-button
                    [id]="'yearly-price'"
                    class="modal-button"
                    [look]="showYearlyPrices ? 'HiltiStyledButtonPrimary' : 'HiltiStyledButtonSecondary'"
                    (buttonClick)="showYearlyPrices = true"
                    [width]="'100%'"
                    [text]="translate('Agito.Hilti.Profis3.LicenseComparison.YearlySubcription')"></pe-button>
            </div>
        </div>

        <div class="section license-info">
            <div class="section-content">
                <div class="comparison-table">
                    <div class="license-description-row">
                        <div class="empty-cell">&nbsp;</div>
                        <div *ngFor="let license of licenses" class="license-description-section">
                            <p [innerHtml]="license.type" class="license-type" [class.license-type-premium]="isPremiumLicense(license)"></p>
                            <p [innerHtml]="license.name" class="license-name"></p>
                            <p [innerHtml]="license.description"></p>
                            <p [innerHtml]="subscriptionPrice(license)" class="license-price"></p>
                            <p [innerHtml]="subscriptionText" class="license-price-info" *ngIf="subscriptionPrice(license) != ''"></p>

                            <pe-button
                                *ngIf="isStandardLicense(license)"
                                [id]="'continue-with-standard-button'"
                                [look]="'HiltiStyledButtonSecondary'"
                                [width]="'100%'"
                                (buttonClick)="continueWithStandard()"
                                [text]="translate('Agito.Hilti.Profis3.TrialBanner.ContinueWithStandard.Button')">
                            </pe-button>

                            <pe-button
                                *ngIf="isPremiumLicense(license)"
                                [id]="'buy-license-button'"
                                [look]="'HiltiStyledButtonPrimary'"
                                [width]="'100%'"
                                class="modal-button"
                                (buttonClick)="buyLicense(license)"
                                [text]="translate('Agito.Hilti.Profis3.LicenseComparison.Buy')">
                            </pe-button>
                        </div>
                    </div>
                    <div *ngFor="let group of groups" class="license-attribute-row">
                        <div class="group">
                            <div class="group-name" [innerHtml]="group.name"></div>
                            <div *ngFor="let license of licenses" class="empty-cell">
                                &nbsp;
                            </div>
                        </div>
                        <div *ngFor="let attribute of getAttributesByGroupId(group.id)" class="attribute">
                            <div class="attribute-name"><p [innerHtml]="attribute.name"></p></div>

                            <div *ngFor="let license of licenses" class="attribute-check">
                                <span class="image sprite sprite-responsive" [class]="getSprite(attribute.id, license)"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>