<ng-container *ngIf="isLoaded">
    <div class="advanced-calculation-inputs" [class.add-edit-design]="isAddEditDesign">
        <div class="content fixed-inputs">
            <p class="title-strike" l10n="Agito.Hilti.Profis3.ApplicationSettings.AdvancedCalculation.AnalysisAndChecks">
            </p>
            <div class="content-controls">
                <pe-radio-button
                    class="radio-button-group control"
                    [id]="useULSStresses.id"
                    [title]="useULSStresses.title"
                    [items]="useULSStresses.items"
                    [selectedValue]="advancedCalculationData.useULSStresses"
                    (selectedValueChange)="advancedCalculationData.useULSStresses = $any($event).detail"
                    [disabled]="submitted">
                </pe-radio-button>
            </div>
        </div>

        <div class="content fixed-inputs" *ngIf="isConcreteBlockAvailable">
            <p class="title-strike" l10n="Agito.Hilti.Profis3.ApplicationSettings.AdvancedCalculation.ConcreteBlock"></p>

            <div class="content-controls">
                <div class="control-row" *ngIf="euBased || stoBased || auBased">
                    <pe-numeric-text-box
                        [id]="jointCoefficientBj.id"
                        [title]="jointCoefficientBj.title"
                        [placeholder]="jointCoefficientBj.placeholder"
                        [value]="advancedCalculationData.jointCoefficientBj"
                        (valueChange)="advancedCalculationData.jointCoefficientBj = $any($event).detail"
                        [unit]="getNumericTextBoxUnit('jointCoefficientBj')"
                        [minValue]="jointCoefficientBj.minValue"
                        [maxValue]="jointCoefficientBj.maxValue"
                        [decimalSeparator]="jointCoefficientBj.decimalSeparator"
                        [groupSeparator]="jointCoefficientBj.groupSeparator"
                        [disabled]="submitted"
                        [tooltip]="jointCoefficientBj.tooltip">
                    </pe-numeric-text-box>
                </div>
                <div class="control-row" *ngIf="euBased || stoBased || auBased">
                    <pe-numeric-text-box
                        [id]="effectiveArea.id"
                        [title]="effectiveArea.title"
                        [placeholder]="effectiveArea.placeholder"
                        [value]="advancedCalculationData.effectiveArea"
                        (valueChange)="advancedCalculationData.effectiveArea = $any($event).detail"
                        [unit]="getNumericTextBoxUnit('effectiveArea')"
                        [minValue]="effectiveArea.minValue"
                        [maxValue]="effectiveArea.maxValue"
                        [decimalSeparator]="effectiveArea.decimalSeparator"
                        [groupSeparator]="effectiveArea.groupSeparator"
                        [disabled]="submitted">
                    </pe-numeric-text-box>
                </div>
                <div class="control-row" *ngIf="aciBased">
                    <pe-numeric-text-box
                        [id]="effectiveAreaAISC.id"
                        [title]="effectiveAreaAISC.title"
                        [placeholder]="effectiveAreaAISC.placeholder"
                        [value]="advancedCalculationData.effectiveAreaAISC"
                        (valueChange)="advancedCalculationData.effectiveAreaAISC = $any($event).detail"
                        [unit]="getNumericTextBoxUnit('effectiveAreaAISC')"
                        [minValue]="effectiveAreaAISC.minValue"
                        [maxValue]="effectiveAreaAISC.maxValue"
                        [decimalSeparator]="effectiveAreaAISC.decimalSeparator"
                        [groupSeparator]="effectiveAreaAISC.groupSeparator"
                        [disabled]="submitted">
                    </pe-numeric-text-box>
                </div>
                <div class="control-row" *ngIf="euBased || stoBased || auBased">
                    <pe-numeric-text-box
                        [id]="loadDistributionAngle.id"
                        [title]="loadDistributionAngle.title"
                        [placeholder]="loadDistributionAngle.placeholder"
                        [value]="advancedCalculationData.loadDistributionAngle"
                        (valueChange)="advancedCalculationData.loadDistributionAngle = $any($event).detail"
                        [unit]="getNumericTextBoxUnit('loadDistributionAngle')"
                        [minValue]="loadDistributionAngle.minValue"
                        [maxValue]="loadDistributionAngle.maxValue"
                        [decimalSeparator]="loadDistributionAngle.decimalSeparator"
                        [groupSeparator]="loadDistributionAngle.groupSeparator"
                        [disabled]="submitted">
                    </pe-numeric-text-box>
                </div>
                <div class="control-row" *ngIf="euBased || stoBased || auBased">
                    <pe-numeric-text-box
                        [id]="alphaCC.id"
                        [title]="alphaCC.title"
                        [placeholder]="alphaCC.placeholder"
                        [value]="advancedCalculationData.alphaCC"
                        (valueChange)="advancedCalculationData.alphaCC = $any($event).detail"
                        [unit]="getNumericTextBoxUnit('alphaCC')"
                        [minValue]="alphaCC.minValue"
                        [maxValue]="alphaCC.maxValue"
                        [decimalSeparator]="alphaCC.decimalSeparator"
                        [groupSeparator]="alphaCC.groupSeparator"
                        [disabled]="submitted"
                        [tooltip]="alphaCC.tooltip">
                    </pe-numeric-text-box>
                </div>

                <pe-dropdown
                    *ngIf="inBased"
                    class="control control-design-standard-method"
                    [id]="concreteInCompressionMethod.id"
                    [items]="concreteInCompressionMethod.items"
                    [selectedValue]="advancedCalculationData.concreteInCompressionMethod"
                    (selectedValueChange)="advancedCalculationData.concreteInCompressionMethod = $any($event).detail"
                    [title]="concreteInCompressionMethod.title"
                    [disabled]="submitted">
                </pe-dropdown>
            </div>
        </div>

        <div class="content fixed-inputs">
            <p class="title-strike" l10n="Agito.Hilti.Profis3.ApplicationSettings.AdvancedCalculation.CheckSettings"></p>

            <div class="content-controls">
                <div class="control-row">
                    <pe-numeric-text-box
                        [id]="limitPlasticStrain.id"
                        [title]="limitPlasticStrain.title"
                        [placeholder]="limitPlasticStrain.placeholder"
                        [value]="advancedCalculationData.limitPlasticStrain"
                        (valueChange)="advancedCalculationData.limitPlasticStrain = $any($event).detail"
                        [unit]="getNumericTextBoxUnit('limitPlasticStrain')"
                        [minValue]="limitPlasticStrain.minValue"
                        [maxValue]="limitPlasticStrain.maxValue"
                        [decimalSeparator]="limitPlasticStrain.decimalSeparator"
                        [groupSeparator]="limitPlasticStrain.groupSeparator"
                        [disabled]="submitted">
                    </pe-numeric-text-box>
                </div>
            </div>
        </div>

        <div class="content fixed-inputs">
            <p class="title-strike" l10n="Agito.Hilti.Profis3.ApplicationSettings.AdvancedCalculation.Mesh"></p>

            <div class="content-controls">
                <div class="control-row">
                    <pe-numeric-text-box
                        [id]="divisionOfSurfaceOfCHS.id"
                        [title]="divisionOfSurfaceOfCHS.title"
                        [placeholder]="divisionOfSurfaceOfCHS.placeholder"
                        [value]="advancedCalculationData.divisionOfSurfaceOfCHS"
                        (valueChange)="advancedCalculationData.divisionOfSurfaceOfCHS = $any($event).detail"
                        [unit]="getNumericTextBoxUnit('divisionOfSurfaceOfCHS')"
                        [minValue]="divisionOfSurfaceOfCHS.minValue"
                        [maxValue]="divisionOfSurfaceOfCHS.maxValue"
                        [decimalSeparator]="divisionOfSurfaceOfCHS.decimalSeparator"
                        [groupSeparator]="divisionOfSurfaceOfCHS.groupSeparator"
                        [disabled]="submitted || divisionOfSurfaceOfCHS.disabled">
                    </pe-numeric-text-box>
                </div>
                <div class="control-row">
                    <pe-numeric-text-box
                        [id]="numberOfElementsOfEdge.id"
                        [title]="numberOfElementsOfEdge.title"
                        [placeholder]="numberOfElementsOfEdge.placeholder"
                        [value]="advancedCalculationData.numberOfElementsOfEdge"
                        (valueChange)="advancedCalculationData.numberOfElementsOfEdge = $any($event).detail"
                        [unit]="getNumericTextBoxUnit('numberOfElementsOfEdge')"
                        [minValue]="numberOfElementsOfEdge.minValue"
                        [maxValue]="numberOfElementsOfEdge.maxValue"
                        [decimalSeparator]="numberOfElementsOfEdge.decimalSeparator"
                        [groupSeparator]="numberOfElementsOfEdge.groupSeparator"
                        [disabled]="submitted">
                    </pe-numeric-text-box>
                </div>
                <div class="control-row">
                    <pe-numeric-text-box
                        [id]="numberOfAnalysisIterations.id"
                        [title]="numberOfAnalysisIterations.title"
                        [placeholder]="numberOfAnalysisIterations.placeholder"
                        [value]="advancedCalculationData.numberOfAnalysisIterations"
                        (valueChange)="advancedCalculationData.numberOfAnalysisIterations = $any($event).detail"
                        [unit]="getNumericTextBoxUnit('numberOfAnalysisIterations')"
                        [minValue]="numberOfAnalysisIterations.minValue"
                        [maxValue]="numberOfAnalysisIterations.maxValue"
                        [decimalSeparator]="numberOfAnalysisIterations.decimalSeparator"
                        [groupSeparator]="numberOfAnalysisIterations.groupSeparator"
                        [disabled]="submitted">
                    </pe-numeric-text-box>
                </div>
                <div class="control-row">
                    <pe-numeric-text-box
                        [id]="divergentIterationsCount.id"
                        [title]="divergentIterationsCount.title"
                        [placeholder]="divergentIterationsCount.placeholder"
                        [value]="advancedCalculationData.divergentIterationsCount"
                        (valueChange)="advancedCalculationData.divergentIterationsCount = $any($event).detail"
                        [unit]="getNumericTextBoxUnit('divergentIterationsCount')"
                        [minValue]="divergentIterationsCount.minValue"
                        [maxValue]="divergentIterationsCount.maxValue"
                        [decimalSeparator]="divergentIterationsCount.decimalSeparator"
                        [groupSeparator]="divergentIterationsCount.groupSeparator"
                        [disabled]="submitted">
                    </pe-numeric-text-box>
                </div>
                <div class="control-row">
                    <pe-numeric-text-box
                        [id]="minimumSizeOfElement.id"
                        [title]="minimumSizeOfElement.title"
                        [placeholder]="minimumSizeOfElement.placeholder"
                        [value]="advancedCalculationData.minimumSizeOfElement"
                        (valueChange)="advancedCalculationData.minimumSizeOfElement = $any($event).detail"
                        [unit]="getNumericTextBoxUnit('minimumSizeOfElement')"
                        [minValue]="minimumSizeOfElement.minValue"
                        [maxValue]="minimumSizeOfElement.maxValue"
                        [decimalSeparator]="minimumSizeOfElement.decimalSeparator"
                        [groupSeparator]="minimumSizeOfElement.groupSeparator"
                        [disabled]="submitted">
                    </pe-numeric-text-box>
                </div>
                <div class="control-row">
                    <pe-numeric-text-box
                        [id]="maximumSizeOfElement.id"
                        [title]="maximumSizeOfElement.title"
                        [placeholder]="maximumSizeOfElement.placeholder"
                        [value]="advancedCalculationData.maximumSizeOfElement"
                        (valueChange)="advancedCalculationData.maximumSizeOfElement = $any($event).detail"
                        [unit]="getNumericTextBoxUnit('maximumSizeOfElement')"
                        [minValue]="maximumSizeOfElement.minValue"
                        [maxValue]="maximumSizeOfElement.maxValue"
                        [decimalSeparator]="maximumSizeOfElement.decimalSeparator"
                        [groupSeparator]="maximumSizeOfElement.groupSeparator"
                        [disabled]="submitted">
                    </pe-numeric-text-box>
                </div>
            </div>
        </div>
    </div>
</ng-container>
