import { Component, OnInit } from '@angular/core';
import {
    MODAL_DISMISS_REASON_BACKDROP, MODAL_DISMISS_REASON_ESC, ModalInstance
} from '@profis-engineering/pe-ui-common/helpers/modal-helper';

import { OfflineService } from '../../services/offline.service';

@Component({
    selector: 'app-alert-testing-environment',
    templateUrl: './alert-testing-environment.component.html',
    styleUrls: ['./alert-testing-environment.component.scss']
})
export class AlertTestingEnvironemtComponent implements OnInit {
    public preventClose = false;

    constructor(
        private modalInstance: ModalInstance,
        private offlineService: OfflineService,
    ) { }

    ngOnInit(): void {
        // don't close the modal if save is pending
        this.modalInstance.setOnClosing((result) => {
            if (this.preventClose && (result == MODAL_DISMISS_REASON_BACKDROP || result == MODAL_DISMISS_REASON_ESC)) {
                return false;
            }

            return true;
        });
    }

    public close() {
        this.modalInstance.close();
    }

    public goToProfis() {
        this.offlineService.nativeExternalLinkOpen("https://profisengineering.hilti.com");
    }
}
