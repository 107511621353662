import * as React from 'react';

import { ButtonType } from '@profis-engineering/pe-ui-common/entities/main-menu/button-props';
import { IToggleButtonProps, ToggleButtonRole } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-button-props';
import { getMenuControlImageReactData } from '../../../../helpers/react-helpers';
import { ControlHeader } from '../../ControlHeader';
import { buildHtmlTooltip, isControlHidden } from '../../MainMenuHelper';


export class ToggleButton extends React.PureComponent<IToggleButtonProps> {
    constructor(props?: IToggleButtonProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    public override render() {
        if (isControlHidden(this.props)) {
            return null;
        }

        const buttonId = `${this.props.controlId}-toggle-button`;
        const buttonActiveClass = this.props.active ? 'active' : '';
        const buttonTypeClass = (() => {
            switch (this.props.buttonType) {
                case ButtonType.Default:
                    return 'button-default';
                case ButtonType.Primary:
                    return 'button-primary';
                case ButtonType.Ghost:
                    return 'button-ghost';
                case ButtonType.Link:
                    return 'button-link';
                default:
                    return 'button-ghost';
            }
        })();

        const roleClass = this.props.role == ToggleButtonRole.Tab ? 'role-tab' : '';
        const tooltipContent = this.props.disabled ? buildHtmlTooltip(this.props.tooltipDisabled, this.props.tooltipDisabledTitle) : buildHtmlTooltip(this.props.tooltip, this.props.tooltipTitle);
        const tooltip = (this.props.title != null && this.props.title != '') ? null : tooltipContent;

        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled ? this.props.tooltipDisabled : this.props.tooltip;

        const imageData = getMenuControlImageReactData(
            this.props.image,
            this.props.imageStyle
        );
        const hasImage = imageData.spriteName != null && imageData.spriteName != '' || imageData.elementStyle != null;

        return (
            <div data-control-id={this.props.controlId} className={`control react-toggle-button ${this.props.sizeClass} ${buttonActiveClass}`}>
                <ControlHeader
                    text={this.props.title}
                    controlId={buttonId}
                    tooltip={tooltipText}
                    tooltipTitle={tooltipTitle}
                    localization={this.props.localization}
                />

                <div className={`control-container ${roleClass}`}
                    data-tip={tooltip}
                    data-html={tooltip != null ? true : null}>
                    <button
                        data-control-id={buttonId}
                        className={`button button-element button-ghost-white ${buttonActiveClass} ${buttonTypeClass}`}
                        type='button'
                        disabled={this.props.disabled}
                        onClick={this.onClick}>
                        {hasImage ? <div className='image-container'><span className={`image sprite ${imageData.spriteName}`} style={imageData.elementStyle}></span></div> : null}
                        {this.props.text != null && this.props.text != '' ? <div className='text'>{this.props.text}</div> : null}
                    </button>
                </div>
            </div>
        );
    }

    private onClick(event: React.MouseEvent) {
        if (this.props.clicked != null) {
            this.props.clicked();
        }

        if (this.props.selectable) {
            this.toggle();
        }
    }

    private toggle() {
        if (this.props.activeChanged != null) {
            this.props.activeChanged(!this.props.active, this.props.value);
        }
    }
}
