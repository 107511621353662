import { Injectable, Injector, NgZone, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap/modal/modal.module';
import { lastValueFrom } from 'rxjs';

import {
    IRecommendDifferentProductComponentInput
} from '@profis-engineering/pe-ui-c2c/entities/recommend-different-product-input';
import {
    DialogsEntityC2C
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    IConfirmChangeProps
} from '@profis-engineering/pe-ui-common/components/confirm-change/confirm-change.common';
import {
    IAddEditDesignComponentFromModuleInput
} from '@profis-engineering/pe-ui-common/entities/add-edit-design-component';
import { IApplicationError } from '@profis-engineering/pe-ui-common/entities/application-error';
import {
    IDesignSectionExportComponentInput
} from '@profis-engineering/pe-ui-common/entities/design-section-export';
import { IModalGridComponentInput } from '@profis-engineering/pe-ui-common/entities/modal-grid';
import {
    RegionMarketingCampaign, ReleaseNote
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.ProductInformationService.Shared.Entities';
import {
    ApplicationSettingsDisplayType
} from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { HtmlValue } from '@profis-engineering/pe-ui-common/helpers/html';
import {
    IModalOpened, ModalContent, ModalInstance, ModalInstanceImpl, ModalOptions
} from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { ModalServiceBase } from '@profis-engineering/pe-ui-common/services/modal.common';
import {
    AdvancedBaseplateCalculationComponentInput
} from '@profis-engineering/pe-ui-shared/components/advanced-baseplate-calculation-popup';
import {
    IIntegrationsNotificationPopupComponentInput
} from '@profis-engineering/pe-ui-shared/components/integrations-notification-popup';
import {
    AdvancedCalculationType
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    IAddEditDesignComponentInput
} from '../components/add-edit-design/add-edit-design.component';
import { AlertType } from '../components/alert/alert.component';
import { IConfirmChangeInputProps } from '../components/home-page/confirm-change-input/confirm-change-input.component';
import { IDesignFromTemplateComponentInput } from '../components/home-page/design-from-template/design-from-template.component';
import { ICheckbotMappingInputProps } from '../components/import-from-checkbot/import-from-checkbot.component';
import {
    ComparisonTable
} from '../components/license-comparison-hol/license-comparison-hol.component';
import {
    INpsSurveyPopupComponentInput
} from '../components/nps-survey-popup/nps-survey-popup.component';
import {
    ITrimbleConnectBrowserComponentInput
} from '../components/trimble-connect-browser/trimble-connect-browser.component';
import { Design } from '../entities/design';
import { ComponentProviderService, DeclarationType } from './component-provider.service';
import { GeneralErrorService } from './general-error.service';
import { LocalizationService } from './localization.service';

@Injectable({
    providedIn: 'root'
})
export class ModalService extends ModalServiceBase {
    constructor(
        private readonly componentProviderService: ComponentProviderService,
        private readonly generalErrorService: GeneralErrorService,
        private readonly ngbModal: NgbModal,
        private readonly injector: Injector,
        private readonly localizationService: LocalizationService
    ) {
        super();
    }


    public openModal(
        content?: ModalContent,
        options?: ModalOptions,
        inputValues?: object
    ): IModalOpened {
        NgZone.assertInAngularZone();

        if (
            content == null
            || content instanceof TemplateRef
            || content instanceof HtmlValue
        ) {
            return null;
        }

        // If WebComponentModalType passed to function
        // - extract Web Component name
        // - use GenericModalComponent as content
        let componentName = '';
        // Check if object has property componentName set.
        if (typeof content == 'object' && typeof content.componentName == 'string' && content.componentName) {
            componentName = content.componentName;
        }

        if (componentName) {
            content = this.componentProviderService.get(DeclarationType.GenericModalComponent);
        }

        if (typeof content == 'function') {
            const modalInstance = new ModalInstanceImpl(
                inputValues,
                componentName
            );
            const modalInstanceInjector = Injector.create({
                providers: [{ provide: ModalInstance, useValue: modalInstance }],
                parent: this.injector
            });

            options = options ?? {};

            const modalOpts: NgbModalOptions = {
                animation: (options.noAnimation !== true),
                size: options.size,
                windowClass: options.windowClass ?? 'default-modal generic-modal',
                backdrop: 'static',
                injector: modalInstanceInjector
            };

            const modal = this.ngbModal.open(content, modalOpts);
            modalInstance.init(modal);

            // Supress 'Error: Uncaught (in promise)'
            modal.result
                .then(null, () => { return; })
                .catch(() => { return; });

            return {
                closed: lastValueFrom(modal.closed, { defaultValue: undefined }),
                shown: lastValueFrom(modal.shown, { defaultValue: undefined }),
                result: modal.result,
                close: modalInstance.close,
                dismiss: modalInstance.dismiss
            };
        }

        return null;
    }

    public openWebComponentModal(
        componentName: string,
        options?: ModalOptions,
        inputValues?: object
    ): IModalOpened {
        return this.openModal({ componentName }, options, inputValues);
    }

    public openUserSettings() {
        this.openModal(this.componentProviderService.get(DeclarationType.UserSettingsComponent), { size: 'lg' });
    }

    public openApplicationSettings(initialDisplayType?: ApplicationSettingsDisplayType) {
        let inputValues = null;
        if (initialDisplayType != null) {
            inputValues = { initialDisplayType };
        }

        this.openModal(this.componentProviderService.get(DeclarationType.AppSettingsComponent), { size: 'xl' }, inputValues);
    }

    public openUserAgreementSettings() {
        this.openModal(this.componentProviderService.get(DeclarationType.UserAgreementSettingsComponent), {});
    }

    public openTrialBanner() {
        return this.openModal(this.componentProviderService.get(DeclarationType.TrialBannerComponent), { size: 'fullWidth' });
    }

    public openLicenseComparison(isCheckbot = false) {
        this.openModal(this.componentProviderService.get(DeclarationType.LicenseComparisonComponent), { size: 'fullWidth' }, { isCheckbot });
    }

    public openLicenseComparisonHol(data: ComparisonTable, holLicenseLanguageId: number, isCheckbot = false) {
        return this.openModal(this.componentProviderService.get(DeclarationType.LicenseComparisonHolComponent), { size: 'fullWidth' }, { data, holLicenseLanguageId, isCheckbot });
    }

    public openConfirmChange(props?: IConfirmChangeProps) {
        return this.openModal(this.componentProviderService.get(DeclarationType.ConfirmChangeComponent), { size: props?.size }, props);
    }

    public openConfirmChangeInput(props?: IConfirmChangeInputProps) {
        return this.openModal(this.componentProviderService.get(DeclarationType.ConfirmChangeInputComponent), {}, props);
    }

    public openUserAgreement() {
        return this.openModal(this.componentProviderService.get(DeclarationType.UserAgreementComponent), { size: 'lg' });
    }

    public openVersionDetails() {
        return this.openModal(this.componentProviderService.get(DeclarationType.VersionPopupComponent), { size: 'lg' });
    }

    public openLDFlags() {
        return this.openModal(this.componentProviderService.get(DeclarationType.LaunchDarklyFlagsComponent), { size: 'lg' });
    }

    public openUserAgreementPrivacy() {
        return this.openModal(this.componentProviderService.get(DeclarationType.UserAgreementPrivacyComponent), { size: 'lg' });
    }

    public openUnauthorizedAccess() {
        this.openModal(this.componentProviderService.get(DeclarationType.UnauthorizedAccessComponent), {});
    }

    public openWhatsNew(releaseNotes: ReleaseNote[]) {
        let inputValues = null;
        if (releaseNotes != null) {
            inputValues = { releaseNotes };
        }

        this.openModal(this.componentProviderService.get(DeclarationType.WhatsNewComponent), { size: 'lg' }, inputValues);
    }

    public openSelectRegionLanguage() {
        return this.openModal(this.componentProviderService.get(DeclarationType.SelectRegionLanguageComponent), { size: 'md' });
    }

    public openShortcutIcon() {
        this.openModal(this.componentProviderService.get(DeclarationType.ShortcutIconModalComponent), {});
    }

    public openFeedBackForm() {
        this.openModal(this.componentProviderService.get(DeclarationType.FeedbackFormComponent), { size: 'adaptive', windowClass: 'd-block default-modal generic-modal modal-new'});
    }

    public openImage(title: string, url: string) {
        const inputValues = {
            title,
            url
        };
        return this.openModal(this.componentProviderService.get(DeclarationType.ImageModalComponent), {}, inputValues);
    }

    public openMarketingCampaign(marketingCampaign: RegionMarketingCampaign, dontShowOnLaunchVisible: boolean) {
        const inputValues = {
            marketingCampaign,
            dontShowOnLaunchVisible
        };
        return this.openModal(this.componentProviderService.get(DeclarationType.MarketingCampaignComponent), { size: 'adaptive' }, inputValues);
    }

    public openModalGrid(props?: IModalGridComponentInput<any>, opts?: ModalOptions) {
        return this.openWebComponentModal(props?.componentName ?? 'pe-modal-grid', opts ?? {}, props);
    }

    public openLoadsMessages(messages: string[]) {
        const input = {
            messages
        };
        this.openModal(this.componentProviderService.get(DeclarationType.LoadsMessagesComponent), {}, input);
    }

    public openSpecificationText(specificationText: string, showPerfectSpec?: boolean) {
        const input = {
            specificationText,
            showPerfectSpec
        };

        this.openWebComponentModal('pe-specification-text', {}, input);
    }

    public openGeneralNotes(text: string, copyText: string) {
        const input = {
            copyText,
            text
        };

        this.openModal(this.componentProviderService.get(DeclarationType.GeneralNotesComponent), { size: 'lg' }, input);
    }

    public openDesktopLicenseWarning(isExpirationMessage: boolean, username: string) {
        const input = {
            isExpirationMessage,
            username
        };

        this.openModal(this.componentProviderService.get(DeclarationType.DesktopLicenseWarningComponent), { size: 'md' }, input);
    }

    public openArchive(onRestore?: () => void) {
        const input = {
            onRestore
        };

        this.openModal(this.componentProviderService.get(DeclarationType.ArchiveComponent), { size: 'lg' }, input);
    }

    public openIntegrationsNotification(props?: IIntegrationsNotificationPopupComponentInput) {
        this.openWebComponentModal('pe-integrations-notification-popup', { size: 'adaptive' }, props);
    }

    public openSaveAsTemplate(props?: object) {
        this.openModal(this.componentProviderService.get(DeclarationType.SaveAsTemplateComponent), {}, props);
    }

    public openRenameDesign(props?: object) {
        return this.openModal(this.componentProviderService.get(DeclarationType.RenameDesignComponent), { size: 'lg' }, props);
    }

    public openDeckingRenameDesign(props?: object) {
        return this.openWebComponentModal('decking-rename-design', { size: 'lg' }, props);
    }

    public openCopyDesign(props?: object) {
        return this.openModal(this.componentProviderService.get(DeclarationType.CopyDesignComponent), { size: 'lg' }, props);
    }

    public openShareProject(props?: object) {
        this.openModal(this.componentProviderService.get(DeclarationType.ShareProjectComponent), {}, props);
    }

    public openShareTemplateFolder(props?: object) {
        this.openModal(this.componentProviderService.get(DeclarationType.ShareTemplateFolderComponent), {}, props);
    }

    public openShareView(props?: object) {
        this.openModal(this.componentProviderService.get(DeclarationType.ShareViewComponent), {}, props);
    }

    public openExportReports(props?: object) {
        this.openModal(this.componentProviderService.get(DeclarationType.ExportReportsComponent), {}, props);
    }

    public openDlubalImport() {
        return this.openWebComponentModal('pe-dlubal-import', { size: 'lg' });
    }

    public openDlubalExport() {
        return this.openWebComponentModal('pe-dlubal-export', { size: 'lg' });
    }

    public openEtabsImport() {
        return this.openWebComponentModal('pe-etabs-import', { size: 'lg' });
    }

    public openRobotImport() {
        return this.openWebComponentModal('pe-robot-import', { size: 'lg' });
    }

    public openSapImport() {
        return this.openWebComponentModal('pe-sap-import', { size: 'lg' });
    }

    public openStaadProImport() {
        return this.openWebComponentModal('pe-staad-pro-import', { size: 'lg' });
    }

    public openSupport(applicationError?: IApplicationError, projectDesign?: object) {
        const input = {
            applicationError,
            projectDesign
        };

        this.openModal(this.componentProviderService.get(DeclarationType.SupportComponent), {}, input);
    }

    public openReportTemplates(templateId?: number) {
        const input = {
            templateId
        };

        return this.openModal(this.componentProviderService.get(DeclarationType.ReportTemplatesComponent), { size: 'xl' }, input);
    }

    public openAlertTestingEnvironment() {

        return this.openModal(this.componentProviderService.get(DeclarationType.AlertTestingEnvironemtComponent));
    }

    public openAlertWarning(title: string, message: string) {
        return this.openAlert(AlertType.Warning, title, message);
    }

    public openAlertError(title: string, message: string, applicationError?: IApplicationError) {
        return this.openAlert(AlertType.Error, title, message, applicationError);
    }

    public openAlertGLError() {
        return this.openAlert(AlertType.GLError);
    }

    public openAlertServiceError(applicationError: IApplicationError) {
        // don't show the error popup if we are already on the error page
        if (this.generalErrorService.showErrorGeneral) {
            return;
        }

        this.openAlert(AlertType.ServiceError, null, null, applicationError);
    }

    public openAlertSignalRError(applicationError: IApplicationError) {
        // don't show the error popup if we are already on the error page
        if (this.generalErrorService.showErrorGeneral) {
            return;
        }

        this.openAlert(AlertType.SignalRError, null, null, applicationError);
    }

    public openAlertLicenseError() {
        return this.openAlert(AlertType.LicenseError);
    }

    public openDesignSectionExport(props?: IDesignSectionExportComponentInput) {
        return this.openWebComponentModal('pe-design-section-export', {}, props);
    }

    public openNpsSurveyPopup(props?: INpsSurveyPopupComponentInput) {
        return this.openModal(this.componentProviderService.get(DeclarationType.NpsSurveyPopupComponent), { size: 'adaptive' }, props);
    }

    public openTrimbleConnectBrowser(props: ITrimbleConnectBrowserComponentInput) {
        return this.openModal(this.componentProviderService.get(DeclarationType.TrimbleConnectBrowserComponent), { size: 'lg' }, props);
    }

    public openVirtualTourPopup(selectTab?: (tab: string) => void) {
        const input = {
            selectTab
        };

        this.openModal(this.componentProviderService.get(DeclarationType.VirtualTourPopupComponent), {}, input);
    }

    // this method should be removed when modularization of all modules finished
    public openAddEditDesign(props: IAddEditDesignComponentInput) {
        return this.openModal(this.componentProviderService.get(DeclarationType.AddEditDesignComponent), { size: 'lg' }, props);
    }

    public openAddEditDesignFromModule(props?: IAddEditDesignComponentFromModuleInput) {
        return this.openModal(this.componentProviderService.get(DeclarationType.AddEditDesignComponent), { size: 'lg' }, props);
    }

    public openMaxDesignsLimitReached() {
        return this.openConfirmChange({
            id: 'max-designs-limit-reached-popup',
            title: this.localizationService.getString('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.MaximumDesignsLimitReached.Title'),
            message: this.localizationService.getString('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.MaximumDesignsLimitReached.Description'),
            confirmButtonText: this.localizationService.getString('Agito.Hilti.Profis3.ControlTooltip.Ok'),
            onConfirm: (modal) => {
                modal.close();
            }
        }).closed;
    }

    public openMaxDesignTemplatesLimitReached() {
        return this.openConfirmChange({
            id: 'max-design-templates-limit-reached-popup',
            title: this.localizationService.getString('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.MaximumDesigTemplatesLimitReached.Title'),
            message: this.localizationService.getString('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.MaximumDesigTemplatesLimitReached.Description'),
            confirmButtonText: this.localizationService.getString('Agito.Hilti.Profis3.ControlTooltip.Ok'),
            onConfirm: (modal) => {
                modal.close();
            }
        }).closed;
    }

    public openDesignFromTemplate(input?: IDesignFromTemplateComponentInput) {
        return this.openModal(this.componentProviderService.get(DeclarationType.DesignFromTemplateComponent), {}, input);
    }

    public openImportFromCheckbot(input?: ICheckbotMappingInputProps) {
        return this.openModal(this.componentProviderService.get(DeclarationType.ImportFromCheckbotComponent), { size: 'adaptive', windowClass: 'default-modal modal-checkbot' }, input);
    }

    public openDocumentInUseWarning(user: string) {
        return this.openAlertWarning(
            this.localizationService.getString('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotOpenInUseBy.Title'),
            formatKeyValue(
                this.localizationService.getString('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotOpenInUseBy.Description'),
                { user }
            )
        );
    }

    // MODULARIZATION - to be moved
    // pe-ui-pe
    public openAdvancedBaseplateCalculation(input: AdvancedBaseplateCalculationComponentInput) {
        return this.openWebComponentModal('pe-advanced-baseplate-calculation', { size: 'lg', noAnimation: true }, input);
    }

    public openAdvancedBaseplateLicenseRequired() {
        this.openWebComponentModal('pe-advanced-baseplate-license-required');
    }

    public openDefaultCalculationMethod(design: Design, calculationType: AdvancedCalculationType) {
        const inputValues = {
            design,
            calculationType
        };

        return this.openWebComponentModal('pe-default-calculation-method', {}, inputValues);
    }

    public openLoadsInput() {
        this.openWebComponentModal('pe-loads-input', { size: 'md' });
    }
    // pe-ui-pe

    // pe-ui-c2c
    private dialogDataInternalC2C: DialogsEntityC2C;

    public set dialogDataC2C(dialog) {
        this.dialogDataInternalC2C = dialog;
    }

    public get dialogDataC2C() {
        return this.dialogDataInternalC2C;
    }

    public openLShapeLimitation() {
        return this.openWebComponentModal('c2c-l-shape-limitation-modal');
    }

    public openRecommendDifferentProductC2C(props?: IRecommendDifferentProductComponentInput) {
        this.openWebComponentModal('c2c-recommend-different-product', { size: 'md' }, props);
    }
    // pe-ui-c2c
    // MODULARIZATION - to be moved


    // TODO: move this outside of modal-service
    public loadingCustomShown: boolean;
    public loadingCustomOnCancel: () => void;
    public loadingCustomLoadingText: string;

    public loadingCustomOpen(text?: string, onCancel?: () => void) {
        this.loadingCustomLoadingText = text;
        this.loadingCustomShown = true;
        this.loadingCustomOnCancel = onCancel;
    }

    public loadingCustomClose() {
        this.loadingCustomShown = false;
    }
    // TODO: move this outside of modal-service

    private openAlert(type: AlertType, title?: string, message?: string, applicationError?: IApplicationError) {
        const input = {
            type,
            title,
            message,
            applicationError
        };

        return this.openModal(this.componentProviderService.get(DeclarationType.AlertComponent), {}, input);
    }
}
