import { AfterViewInit, Component } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';

export interface IConfirmChangeInputProps<T = unknown | undefined> {
  id: string;
  title?: string;
  message?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  maxlength?: number;
  input?: string;
  details?: T
  onConfirm?: (modal: ModalInstance<IConfirmChangeInputProps<T>>) => void;
  onCancel?: (modal: ModalInstance<IConfirmChangeInputProps<T>>) => void;
}
@Component({
  selector: 'app-confirm-change-input',
  templateUrl: './confirm-change-input.component.html',
})
export class ConfirmChangeInputComponent implements AfterViewInit {
  public pendingSave = false;
  value: string;
  maxlength: number;

  constructor(
    public modalInstance: ModalInstance<IConfirmChangeInputProps>
  ) {
    this.value = modalInstance.input?.input;
    this.maxlength = modalInstance.input.maxlength ?? 250;
  }

  ngAfterViewInit(): void {
    // Set focus on input
    (document.querySelector('.project-text-box').shadowRoot.getElementById('project-add-or-rename') as HTMLElement)?.focus();
  }

  cancel() {
    this.modalInstance.input.onCancel(this.modalInstance);
  }

  async save() {
    this.pendingSave = true;
    this.modalInstance.input.onConfirm(this.modalInstance);
  }

  textChange(e: string) {
    this.value = e;
    this.modalInstance.input.input = e;
  }
}
