import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DocumentService } from '../../../services/document.service';
import { LocalizationService } from '../../../services/localization.service';
import { ModalService } from '../../../services/modal.service';
import { ModalDialogType } from '../../add-edit-design/add-edit-design-models';
import { DesignTemplateService } from '../../../services/design-template.service';
import { DocumentType, ProjectType } from '../home-page.common';

interface Project {
    projectId: string;
    projectName: string;
    designs: number;
    members: number;
    created: string;
    archived: string;
    parentProjectId: string;
    projectType: ProjectType;
    archivedDate?: Date;
}

interface ArchivedDocument {
    documentId: string;
    name: string;
    members: number;
    created: string;
    archived: string;
    parentProjectId: string;
    projectname: string;
    documentType: DocumentType;
    archivedDate?: Date;
}

@Component({
    selector: 'app-pe-archive',
    templateUrl: './archive.component.html',
    styleUrls: ['./archive.component.scss']
})
export class ArchiveHomePageComponent implements OnInit {
    public submitted: boolean;
    public projects: Project[];
    public archivedDocuments: ArchivedDocument[];

    public projectHeaderHeight = 39;    // must be the same value as in css
    public projectRowHeight = 35;       // must be the same value as in css
    public maxProjectsTableRows = 0;
    public maxDocumentTableRows = 0;

    public scrollElement: Element;
    public scrollElementDesign: Element;
    private pendingSave: boolean;

    @Output()
    public projectsTreeUpdateOnRestore: EventEmitter<ProjectType> = new EventEmitter<ProjectType>();

    constructor(
        public localization: LocalizationService,
        private documentService: DocumentService,
        private modal: ModalService,
        private designTemplateService: DesignTemplateService
    ) { }

    ngOnInit(): void {
        this.scrollElement = document.querySelector('.archive-container-virtual-scroller-wrapper');
        this.scrollElementDesign = document.querySelector('.archive-documents-container-virtual-scroller-wrapper');
        this.initProjects();
        this.initArchivedDocuments();

        const centerContent = document.querySelector(".content-center");
        const quickStart = centerContent.querySelector(".quick-start");

        // 140 is height or container header + archive project header + archive document header + padding.
        const remainingSpace = centerContent.clientHeight - quickStart.clientHeight - 140;

        // we want to use max 30% (or at least 3 projects) of the remaining space for projects and 70% for documents
        if(this.projects?.length > 0){
            this.maxProjectsTableRows = Math.min(remainingSpace * 0.3, (this.projects?.length ?? 0) * this.projectRowHeight);
            if(this.maxProjectsTableRows < this.projectRowHeight) {
                this.maxProjectsTableRows = Math.min(this.projects.length, 3) * this.projectRowHeight;
            }
        }

        if(this.archivedDocuments?.length > 0){
            this.maxDocumentTableRows = remainingSpace - this.maxProjectsTableRows - (2 * this.projectHeaderHeight);
            if(this.maxDocumentTableRows < this.projectRowHeight) {
                this.maxDocumentTableRows = Math.min(this.archivedDocuments.length, 3) * this.projectRowHeight;
            }
        }
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public restoreProject(projectId: string) {
        if (this.submitted || this.pendingSave) {
            return;
        }

        this.submitted = this.pendingSave = true;
        const projectLevelType = this.projects.find((item) => item.projectId == projectId).parentProjectId == null
            ? ModalDialogType.project
            : ModalDialogType.subproject;
        const projectType = this.projects.find((item) => item.projectId == projectId).projectType;
        if (projectType == ProjectType.Projects) {
            this.documentService.restoreProject(projectId, projectLevelType).then((response) => {
                this.projects = this.projects.filter((item) => !response.includes(item.projectId));
                this.archivedDocuments = this.archivedDocuments.filter((item) => !response.includes(item.parentProjectId));
            }).finally(() => {
                this.submitted = this.pendingSave = false;
                this.projectsTreeUpdateOnRestore.emit();
            });
        }
        if (projectType == ProjectType.Templates) {
            this.designTemplateService.restoreExistingTemplateFolder(projectId).then((response) => {
                this.projects = this.projects.filter((item) => !response.includes(item.projectId));
                this.archivedDocuments = this.archivedDocuments.filter((item) => !response.includes(item.parentProjectId));
            }).finally(() => {
                this.submitted = this.pendingSave = false;
                this.projectsTreeUpdateOnRestore.emit(ProjectType.Templates);
            });
        }
    }

    public restoreDocument(documentid: string) {
        if (this.submitted || this.pendingSave) {
            return;
        }

        this.submitted = this.pendingSave = true;
        const documentType = this.archivedDocuments.find((item) => item.documentId == documentid).documentType;

        if (documentType === DocumentType.Document) {
            this.documentService.restoreExistingDesign(documentid).then(() => {
                const documentProjId = this.archivedDocuments.find((x) => x.documentId == documentid).parentProjectId;
                if (this.projects.find((project) => project.projectId == documentProjId)) {
                    this.projects.find((project) => project.projectId == documentProjId).designs--;
                }
                this.archivedDocuments = this.archivedDocuments.filter((item) => item.documentId != documentid);
            }).finally(() => {
                this.submitted = this.pendingSave = false;
                this.projectsTreeUpdateOnRestore.emit();
            });
        }
        if (documentType === DocumentType.DesignTemplateDocument) {
            this.designTemplateService.restoreExisitingTemplate(documentid).then(() => {
                this.archivedDocuments = this.archivedDocuments.filter((item) => item.documentId != documentid);
            }).finally(() => {
                this.submitted = this.pendingSave = false;
                this.projectsTreeUpdateOnRestore.emit(ProjectType.Templates);
            });
        }
    }

    public deleteArchivedProject(projectId: string) {
        if (this.submitted || this.pendingSave) {
            return;
        }

        const projectType = this.projects.find((item) => item.projectId == projectId).projectType;
        const opened = this.modal.openConfirmChange({
            id: projectType == ProjectType.Templates ? 'confirm-delete-template-folder-permanently' : 'confirm-delete-project-permanently',
            title: projectType == ProjectType.Templates ? this.translate('Agito.Hilti.Profis3.Homepage.TemplateFolder.ConfirmDelete.Title') : this.translate('Agito.Hilti.Profis3.Archive.ConfirmDelete.Title'),
            message: projectType == ProjectType.Templates ? this.translate('Agito.Hilti.Profis3.Homepage.TemplateFolder.ConfirmDelete.Message') : this.translate('Agito.Hilti.Profis3.Archive.ConfirmDelete.Message'),
            confirmButtonText: this.translate('Agito.Hilti.Profis3.Archive.ConfirmDelete.Ok'),
            cancelButtonText: this.translate('Agito.Hilti.Profis3.Archive.ConfirmDelete.Cancel'),
            onConfirm: (modal) => {
                modal.close(true);
            },
            onCancel: (modal) => {
                modal.close(false);
            }
        }).result;

        this.submitted = this.pendingSave = true;

        opened
            .then((result: boolean) => {
                // Modal closed
                const promise = new Promise<void>((resolve, reject) => {
                    if (result) {
                        if (projectType == ProjectType.Projects) {
                            this.documentService.removeArchivedProject(projectId)
                                .then(() => {
                                    this.projects = this.projects.filter((item) => item.projectId != projectId);
                                    this.archivedDocuments = this.archivedDocuments.filter((item) => item.parentProjectId != projectId);
                                    resolve();
                                })
                                .catch((e) => {
                                    reject(e);
                                });
                            return;
                        }
                        if (projectType == ProjectType.Templates) {
                            this.designTemplateService.deleteTemplateFolderLocal(projectId).then(() => {
                                this.projects = this.projects.filter((item) => item.projectId != projectId);
                                this.archivedDocuments = this.archivedDocuments.filter((item) => item.parentProjectId != projectId);
                                resolve();
                            })
                                .catch((e) => {
                                    reject(e);
                                });
                            return;
                        }
                        return;
                    }

                    resolve();
                });

                promise.finally(() => {
                    this.submitted = this.pendingSave = false;
                });
            })
            .catch(() => {
                // Modal dismissed
                this.submitted = this.pendingSave = false;
            });
    }

    public deleteArchivedDocument(documentId: string) {
        if (this.submitted || this.pendingSave) {
            return;
        }

        const documentType = this.archivedDocuments.find((item) => item.documentId == documentId).documentType;
        const deletePopupOpened = this.modal.openConfirmChange({
            id: documentType == DocumentType.DesignTemplateDocument ? 'confirm-delete-template' : 'confirm-delete-design',
            title: documentType == DocumentType.DesignTemplateDocument ? this.translate('Agito.Hilti.Profis3.ProjectAndDesign.ConfirmDeleteTemplate.Title') : this.translate('Agito.Hilti.Profis3.ProjectAndDesign.ConfirmDeleteDesign.Title'),
            message: documentType == DocumentType.DesignTemplateDocument ? this.translate('Agito.Hilti.Profis3.ProjectAndDesign.ConfirmDeleteTemplate.Message') : this.translate('Agito.Hilti.Profis3.ProjectAndDesign.ConfirmDeleteDesign.Message'),
            confirmButtonText: this.translate('Agito.Hilti.Profis3.ProjectAndDesign.ConfirmDeleteDesign.Confirm'),
            cancelButtonText: this.translate('Agito.Hilti.Profis3.ProjectAndDesign.ConfirmDeleteDesign.Cancel'),
            onConfirm: (modal) => {
                modal.close(true);
            },
            onCancel: (modal) => {
                modal.close(false);
            }
        }).result;
        this.submitted = this.pendingSave = true;

        deletePopupOpened
            .then((result: boolean) => {
                // Modal closed
                const promise = new Promise<void>((resolve, reject) => {
                    if (result) {
                        if (documentType == DocumentType.Document) {
                            this.documentService.removeArchivedDesign([documentId])
                                .then(() => {
                                    const projId = this.archivedDocuments.find(x => x.documentId == documentId)?.parentProjectId;
                                    if (this.projects.find((project) => project.projectId == projId)) {
                                        this.projects.find((project) => project.projectId == projId).designs--
                                    }
                                    this.archivedDocuments = this.archivedDocuments.filter(x => x.documentId != documentId);
                                    resolve();
                                })
                                .catch((e) => {
                                    reject(e);
                                });
                            return;
                        }
                        if (documentType == DocumentType.DesignTemplateDocument) {
                            this.designTemplateService.delete(documentId)
                                .then(() => {
                                    this.archivedDocuments = this.archivedDocuments.filter(x => x.documentId != documentId);
                                    resolve();
                                })
                                .catch((e) => {
                                    reject(e);
                                });
                            return;
                        }
                        return;
                    }
                    resolve();
                });

                promise.finally(() => {
                    this.submitted = this.pendingSave = false;
                });
            })
            .catch(() => {
                // Modal dismissed
                this.submitted = this.pendingSave = false;
            });
    }

    private initProjects() {
        this.projects = [];
        for (const idx in this.documentService.projectsArchive) {
            const item = this.documentService.projectsArchive[idx];
            const utcTimeProjectArchived = this.localization.moment(item.archived).utc(true)?.toDate();
            const localTimeArchive = this.localization.moment(utcTimeProjectArchived).format('MMM D, YYYY h:mm A');
            const utcTimeProject = this.localization.moment(item.created).utc(true)?.toDate();
            const localTimeCreatedProj = this.localization.moment(utcTimeProject).format('MMM D, YYYY h:mm A');
            this.projects.push({
                projectId: item.id,
                projectName: item.name,
                members: item.members,
                designs: item.designs,
                created: localTimeCreatedProj,
                archived: localTimeArchive,
                parentProjectId: item.parentId,
                projectType: ProjectType.Projects,
                archivedDate: utcTimeProjectArchived
            } as Project);
        }

        for (const idx in this.designTemplateService.templateFoldersArchive) {
            const item = this.designTemplateService.templateFoldersArchive[idx];
            const utcTimeTemplateArchived = this.localization.moment(item.archived).utc(true)?.toDate();
            const localTimeArchive = this.localization.moment(utcTimeTemplateArchived).format('MMM D, YYYY h:mm A');
            const utcTimeTemplate = this.localization.moment(item.created).utc(true)?.toDate();
            const localTimeCreatedTemplate = this.localization.moment(utcTimeTemplate).format('MMM D, YYYY h:mm A');
            this.projects.push({
                projectId: item.id,
                projectName: item.name,
                members: item.members,
                designs: item.designs,
                created: localTimeCreatedTemplate,
                archived: localTimeArchive,
                parentProjectId: item.parentId,
                projectType: ProjectType.Templates,
                archivedDate: utcTimeTemplateArchived
            } as Project);
        }
        this.projects.sort((a, b) => {
            return a.archivedDate?.getTime() - b.archivedDate?.getTime();
        }).reverse();
    }

    private initArchivedDocuments() {
        this.archivedDocuments = [];
        for (const idx in this.documentService.documentsArchive) {
            const item = this.documentService.documentsArchive[idx];
            const archiveDate = item.archived ?? this.documentService.projectsArchive.find(x => x.id == item.parentProjectId).archived;
            const utcTimeArchivedDoc = this.localization.moment(archiveDate).utc(true)?.toDate();
            const localTimeArchive = this.localization.moment(utcTimeArchivedDoc).format('MMM D, YYYY h:mm A');
            const utcTimeDoc = this.localization.moment(item.created).utc(true)?.toDate();
            const localTimeCreatedDoc = this.localization.moment(utcTimeDoc).format('MMM D, YYYY h:mm A');
            this.archivedDocuments.push({
                documentId: item.id,
                name: item.name,
                members: item.members,
                designs: item.designs,
                created: localTimeCreatedDoc,
                archived: localTimeArchive,
                archivedDate: utcTimeArchivedDoc,
                parentProjectId: item.parentProjectId,
                projectname: item.projectname == '#$draft' ? this.translate('Agito.Hilti.Profis3.Documents.SpecialProject.draft') : item.projectname,
                documentType: DocumentType.Document
            } as ArchivedDocument);
        }

        for (const idx in this.designTemplateService.designTemplateArchive) {
            const item = this.designTemplateService.designTemplateArchive[idx];
            const archiveDate = item.archived ?? this.designTemplateService.templateFoldersArchive.find(x => x.id == item.parentProjectId).archived;
            const utcTimeArchivedDesign = this.localization.moment(archiveDate).utc(true)?.toDate();
            const localTimeArchive = this.localization.moment(utcTimeArchivedDesign).format('MMM D, YYYY h:mm A');
            const utcTime = this.localization.moment(item.created).utc(true)?.toDate();
            const localTimeCreatedDesign = this.localization.moment(utcTime).format('MMM D, YYYY h:mm A');
            this.archivedDocuments.push({
                documentId: item.id,
                name: item.name,
                members: item.members,
                designs: item.designs,
                created: localTimeCreatedDesign,
                archived: localTimeArchive,
                archivedDate: utcTimeArchivedDesign,
                parentProjectId: item.parentProjectId,
                projectname: item.projectname ?? this.translate('Agito.Hilti.Profis3.HomePage.Templates'),
                documentType: DocumentType.DesignTemplateDocument
            } as ArchivedDocument);
        }

        this.archivedDocuments.sort((a, b) => {
            return a.archivedDate?.getTime() - b.archivedDate?.getTime();
        }).reverse();
    }
}
