<div class="content-group">
    <div class="content">
        <p class="title-strike" l10n="Agito.Hilti.Profis3.ApplicationSettings.InteractionSettings">
        </p>

        <div class="content-controls">
            <pe-dropdown class="control"
                [id]="controlsRotateDropdown.id"
                [items]="controlsRotateDropdown.items"
                [selectedValue]="interactionValues.rotate"
                (selectedValueChange)="onControlsRotateDropdownChange($any($event).detail)"
                [title]="controlsRotateDropdown.title"
                [disabled]="submitted">
            </pe-dropdown>

            <pe-dropdown class="control"
                [id]="controlsPanDropdown.id"
                [items]="controlsPanDropdown.items"
                [selectedValue]="interactionValues.pan"
                (selectedValueChange)="onControlsPanDropdownChange($any($event).detail)"
                [title]="controlsPanDropdown.title"
                [disabled]="submitted">
            </pe-dropdown>
        </div>
    </div>
</div>
