import { Injectable } from '@angular/core';
import {
    ProjectOpenType as ProjectOpenTypeC2C
} from '@profis-engineering/pe-ui-c2c/entities/tracking-data';
import {
    TrackDesignCloseRequest
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.CommonTrackingService.Shared.Entities';
import {
    TrackingDesignType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.CommonTrackingService.Shared.Enums';
import { trySendingUsingFetch } from '@profis-engineering/pe-ui-common/helpers/browser';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';
import {
    ProjectOpenType
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display.Enums';
import {
    OnProjectCloseRequest
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Tracking.Entities';
import { environment } from '../../../environments/environment';
import { Design } from '../../entities/design';
import { LoggerService } from '../../services/logger.service';
import { TrackingServiceC2C } from '../../services/tracking-c2c.service';
import { TrackingService } from '../../services/tracking.service';
import { UserService } from '../../services/user.service';

/**
 * @deprecated DO NOT USE FOR NEW CODE. Will be removed with cleanup task: BUDQBP-36273.
 */
@Injectable({
    providedIn: 'root'
})
export class TrackingServiceBwCompat {
    constructor(
        private readonly trackingService: TrackingService,
        private readonly trackingServiceC2C: TrackingServiceC2C,
        private readonly userService: UserService,
        private readonly loggerService: LoggerService
    ) { }

    public saveTrackingData(design: Design): Promise<void> {
        if (design.isC2C) {
            return this.saveTrackingDataC2C(design);
        }

        return this.saveTrackingDataPe(design);
    }

    private saveTrackingDataPe(design: Design): Promise<void> {
        return this.trackingService.saveTrackingActData(
            design.designData.projectDesign,
            design.usageCounter.toUsageCounter(),
            design.projectOpenType as ProjectOpenType,
            design.id,
            design.createdFromTemplate,
            design.templateId,
            design.designLocation
        );
    }

    private async saveTrackingDataC2C(design: Design): Promise<void> {
        return this.trackingServiceC2C.saveTrackingActDataC2C(
            design.designData.projectDesignC2C,
            design.usageCounterC2C.toUsageCounterC2C(),
            design.projectOpenType as ProjectOpenTypeC2C,
            design.id,
            design.createdFromTemplate,
            design.templateId
        );
    }



    public async trackOnTemplateClose(design: Design): Promise<void> {
        if (design.isC2C) {
            return this.trackOnTemplateCloseC2C(design);
        }

        return this.trackOnTemplateClosePe(design);
    }

    private trackOnTemplateClosePe(design: Design): Promise<void> {
        return this.trackingService.trackOnTemplateClose(
            design.designData.projectDesign,
            design.usageCounter.toUsageCounter(),
            design.projectOpenType as ProjectOpenType,
            design.id,
            design.createdFromTemplate,
            design.templateId,
            design.designLocation
        );
    }

    private trackOnTemplateCloseC2C(design: Design): Promise<void> {
        return this.trackingServiceC2C.trackOnTemplateCloseC2C(
            design.designData.projectDesignC2C,
            design.usageCounterC2C.toUsageCounterC2C(),
            design.projectOpenType as ProjectOpenTypeC2C,
            design.createdFromTemplate,
            design.templateId
        );
    }



    public async trackOnDesignOpen(design: Design): Promise<void> {
        if (design.isC2C) {
            return this.trackOnDesignOpenC2C(design);
        }

        return this.trackOnDesignOpenPe(design);
    }

    private trackOnDesignOpenPe(design: Design): Promise<void> {
        return this.trackingService.trackOnDesignOpen(
            design.designType,
            design.id
        );
    }

    private trackOnDesignOpenC2C(design: Design): Promise<void> {
        return this.trackingServiceC2C.trackOnDesignOpen(
            design.designType.id,
            design.id,
            design.designData.projectDesignC2C.options.designStandard,
            design.designData.projectDesignC2C.options.connectionType
        );
    }


    public async trackOnDesignClose(design: Design): Promise<void> {
        if (design.isC2C) {
            return this.trackOnDesignCloseC2C(design);
        }

        return this.trackOnDesignClosePe(design);
    }

    private trackOnDesignClosePe(design: Design): Promise<void> {
        return this.trackingService.trackOnDesignClose(
            design.designData.projectDesign,
            design.usageCounter.toUsageCounter(),
            design.projectOpenType as ProjectOpenType,
            design.id,
            design.createdFromTemplate,
            design.templateId,
            design.baseplateDesignData,
            design.designData.reportData.BaseplateDesignTiming,
            design.designLocation
        );
    }

    private trackOnDesignCloseC2C(design: Design): Promise<void> {
        if (!environment.c2cTrackingServiceEnabled) {
            return Promise.resolve();
        }

        return this.trackingServiceC2C.trackOnDesignCloseC2C(
            design.designData.projectDesignC2C,
            design.usageCounterC2C.toUsageCounterC2C(),
            design.projectOpenType as ProjectOpenTypeC2C,
            design.id,
            design.createdFromTemplate,
            design.templateId
        );
    }



    public async handleDesignBrowserUnload(sessionKey: string, design: Design): Promise<void> {
        if (design.isC2C) {
            return this.handleDesignBrowserUnloadC2C(design);
        }

        return this.handleDesignBrowserUnloadPe(sessionKey, design);
    }

    private handleDesignBrowserUnloadPe(sessionKey: string, design: Design): Promise<void> {
        design.usageCounter.DateClosed = new Date();

        const trackingData = this.trackingService.getDesignTrackingData(
            design.designData.projectDesign,
            design.usageCounter.toUsageCounter(),
            design.projectOpenType as ProjectOpenType,
            design.id,
            design.createdFromTemplate,
            design.templateId,
            design.designLocation
        );
        const data: OnProjectCloseRequest = {
            DesignId: design.id,
            SessionKey: sessionKey,
            TrackingData: trackingData
        };

        const url = `${environment.baseplateApplicationWebServiceUiApiUrl}OnProjectCloseSync`;
        const httpHeaders = this.userService.getHeaders(url, true);
        const jsonData = data != null
            ? JSON.stringify(data)
            : null;

        const trackingPromise = trySendingUsingFetch(url, httpHeaders, jsonData, this.loggerService)
            .catch((error) => {
                console.error(error);
                this.loggerService.log(error.message, LogType.error, error);
            })
            .then(() => {
                if (data != null) {
                    design.usageCounter.resetCounter();
                }
            });
        return trackingPromise;
    }

    private handleDesignBrowserUnloadC2C(design: Design): Promise<void> {
        design.usageCounterC2C.DateClosed = new Date();

        if (!environment.c2cTrackingServiceEnabled) {
            return Promise.resolve();
        }

        const trackingData = this.trackingServiceC2C.getDesignTrackingDataC2C(
            design.designData.projectDesignC2C,
            design.usageCounterC2C.toUsageCounterC2C(),
            design.projectOpenType as ProjectOpenTypeC2C,
            design.id,
            design.createdFromTemplate,
            design.templateId
        );
        const data: TrackDesignCloseRequest = {
            DesignType: TrackingDesignType.Concrete2Concrete,
            TrackingData: trackingData,
            DesignId: design.id
        };

        const url = `${environment.peTrackingServiceUrl}Tracking/TrackOnProjectDesignCloseSync`;
        const httpHeaders = this.userService.getHeaders(url, true);
        const jsonData = data != null
            ? JSON.stringify(data)
            : null;

        const trackingPromise = trySendingUsingFetch(url, httpHeaders, jsonData, this.loggerService)
            .then(() => {
                design.usageCounter.resetCounter();
            });
        return trackingPromise;
    }
}
