import uniq from 'lodash-es/uniq';

import { HttpErrorResponse, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Design as DesignCommon } from '@profis-engineering/pe-ui-common/entities/design';
import { IImportData } from '@profis-engineering/pe-ui-common/entities/import-data';
import {
    IImportDesignProvider, IProjectDesignEntity
} from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import {
    SafeFunctionInvokerHelper
} from '@profis-engineering/pe-ui-common/helpers/safe-function-invoker-helper';
import { format } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { ImportServiceBase } from '@profis-engineering/pe-ui-common/services/import.common';
import {
    IDeckingDesignListInfo
} from '@profis-engineering/pe-ui-decking/src/decking/entities/decking-design-list-info';

import { environment } from '../../environments/environment';
import { Design } from '../entities/design';
import { Project } from '../entities/project';
import { DocumentService } from './document.service';
import { FeatureVisibilityService } from './feature-visibility.service';
import { LocalizationService } from './localization.service';
import { ModalService } from './modal.service';
import { DesignTypeId, ModulesService } from './modules.service';
import { OfflineService } from './offline.service';
import { QueryService } from './query.service';
import { RoutingService } from './routing.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class ImportService extends ImportServiceBase {
    public disabled: boolean;
    public project: Project;
    public design: Design;
    public onDesignImporting: () => Promise<void> | void;
    public onDesignImported: (design: DesignCommon, project: Project, renameFile?: boolean, openDesign?: boolean) => Promise<boolean | void> | boolean | void;
    public onDesignImportFailed: () => Promise<void> | void;

    constructor(
        private routingService: RoutingService,
        private userService: UserService,
        private documentService: DocumentService,
        private queryService: QueryService,
        private modalService: ModalService,
        private localizationService: LocalizationService,
        private offlineService: OfflineService,
        private modulesService: ModulesService,
        private featureVisibilityService: FeatureVisibilityService
    ) {
        super();
    }

    public async import(importProject: Project, importDesign: Design, projectDesign: File | Blob, name: string, openNewWindow?: boolean) {

        const FFDeckingGlobal = this.featureVisibilityService.isFeatureEnabled('Decking_Global');
        // Only for Decking, Import Profis DF Diaphragm Projects (*.dia)
        if (FFDeckingGlobal) {
            // If the file is .dia, call directly DeckingImportService to translate to a PE Decking Design.
            if (projectDesign instanceof File && projectDesign.name.endsWith('dia')) {
                const deckingDesignListInfo = this.modulesService.getDesignListInfoByDesignType(DesignTypeId.DiaphragmDesign) as IDeckingDesignListInfo;

                return await deckingDesignListInfo.importDFDesign(projectDesign, importProject.id);
            }
        }

        const fileName = projectDesign instanceof File && name == null ? projectDesign.name : name;
        const fileContent = await this.readFileAsText(projectDesign);

        if (this.onDesignImporting != null) {
            await this.onDesignImporting();
        }

        const selectedImportProvider = this.findImportDesignProvider(fileContent, fileName);
        if (selectedImportProvider == null) {
            return Promise.reject(new Error('Cannot find import design provider!'));
        }

        return await this.importFile(selectedImportProvider, importProject, importDesign, projectDesign, name, openNewWindow, fileContent);
    }

    public async importDesign(
        project: Project,
        projectDesign: File | Blob,
        name?: string,
        design?: Design,
        disableCalcMessages?: boolean,
        importDesignProvider?: IImportDesignProvider,
        projectDesignContent?: string,
        trackingEnabled?: boolean,
        openNewWindow?: boolean
    ) {
        // set name
        if (projectDesign instanceof File && name == null) {
            name = projectDesign.name;
        }
        else if (name == null) {
            throw new Error('Name not provided.');
        }

        // File is empty/does not exist
        if (projectDesign.size == 0) {
            this.modalService.openAlertWarning(
                this.localizationService.getString('Agito.Hilti.Profis3.FileUpload.FileNotFound.Title'),
                this.localizationService.getString('Agito.Hilti.Profis3.FileUpload.FileNotFound.Message'),
            );
        }

        // name has to end with '.profis3' or '.pe' or '.pa2' or 'pac'
        const nameLowerCase = name.toLowerCase();
        const importProviders = this.modulesService.getImportDesignProviders();
        const validExtensions = uniq(importProviders.flatMap(x => SafeFunctionInvokerHelper.safeInvoke(x.getValidFileExtensions, [])));
        if (!validExtensions.some(ext => nameLowerCase.endsWith(ext.toLowerCase()))) {
            this.modalService.openAlertWarning(
                this.localizationService.getString('Agito.Hilti.Profis3.FileUpload.WrongTypeAlert.Title'),
                format(this.localizationService.getString('Agito.Hilti.Profis3.FileUpload.WrongTypeAlert.Message'), validExtensions.join(' '))
            );
            throw new Error('Invalid file extension.');
        }

        if (importDesignProvider == null) {
            // Try finding importDesignProvider if noet set (only applicable when calling this method from trimble connect service)
            const fileContent = await this.readFileAsText(projectDesign);
            importDesignProvider = this.findImportDesignProvider(fileContent, name);

            if (importDesignProvider == null) {
                return Promise.reject(new Error('Cannot find import design provider!'));
            }
        }

        return await this.importAndUploadDesign(importDesignProvider, false, project, projectDesign, name, name, design, disableCalcMessages, projectDesignContent, trackingEnabled, openNewWindow);
    }

    private async importFile(
        importDesignProvider: IImportDesignProvider,
        importProject: Project,
        design: Design,
        projectDesign: File | Blob,
        name: string,
        openNewWindow?: boolean,
        projectDesignContent?: string
    ) {
        try {
            const result = await this.importDesign(importProject, projectDesign, name, design, false, importDesignProvider, projectDesignContent, !openNewWindow, openNewWindow);
            if (this.onDesignImported != null) {
                await this.onDesignImported(result.design, importProject, result.renameDesign, result.openDesign);
            }

            this.openDesign(result.design as Design, result.renameDesign, importDesignProvider.path, projectDesign, importProject, openNewWindow);
        }
        catch (error) {
            console.error(error);

            if (this.onDesignImportFailed != null) {
                this.onDesignImportFailed();
            }
        }
    }

    private async importAndUploadDesign(
        importDesignProvider: IImportDesignProvider,
        secondTry: boolean,
        project: Project,
        projectDesign: File | Blob,
        name?: string,
        nameProposal?: string,
        design?: Design,
        disableCalcMessages?: boolean,
        projectDesignContent?: string,
        trackingEnabled?: boolean,
        openNewWindow?: boolean
    ) {
        const fileName = name.substring(0, name.lastIndexOf('.')).trim();
        const fileNameProposal = nameProposal != null ? nameProposal.substring(0, nameProposal.lastIndexOf('.')).trim() : null;

        try {
            const projectDesignEntity = await importDesignProvider.createProjectDesignEntity(projectDesign, project.name, fileNameProposal, nameProposal ?? name, projectDesignContent, openNewWindow);
            const result = await this.importData(importDesignProvider, design, project, fileName, fileNameProposal, secondTry, disableCalcMessages, projectDesignEntity, openNewWindow);
            return await this.createDesign(importDesignProvider, result, project, design, projectDesign, name, secondTry, disableCalcMessages, trackingEnabled, openNewWindow);
        }
        catch (error) {
            this.importError(error);
        }
    }

    private importError(response: unknown): never {
        // closed means closed by user so skip displaying error
        // notAllowedC2C means it was rejected by environment flag on UI and was already handled. Remove once 2c2demo flag is removed completely.
        if (response != 'closed' && response != 'notAllowedC2C') {
            const httpResponse = response instanceof HttpResponseBase ? response : undefined;
            const httpBody = response instanceof HttpErrorResponse
                ? response.error
                : response instanceof HttpResponse
                    ? response.body
                    : undefined;

            // can fail for multiple reasons (popup close, promise rejection, http failed ...)
            if (httpResponse?.status === 401) {
                throw response;
            }
            else if (httpResponse?.status === 422) {
                this.modalService
                    .openAlertError(
                        this.localizationService.getString('Agito.Hilti.Profis3.FileUpload.UnsupportedFileAlert.Title'),
                        httpBody?.Message,
                        {
                            response,
                        }
                    );
            }
            else if (httpResponse?.status === 415) {
                const importProviders = this.modulesService.getImportDesignProviders();
                const validExtensions = uniq(importProviders.flatMap(x => SafeFunctionInvokerHelper.safeInvoke(x.getValidFileExtensions, [])));
                this.modalService
                    .openAlertError(
                        this.localizationService.getString('Agito.Hilti.Profis3.FileUpload.WrongTypeAlert.Title'),
                        format(this.localizationService.getString('Agito.Hilti.Profis3.FileUpload.WrongTypeAlert.Message'), validExtensions.join(' ')),
                        {
                            response,
                        }
                    );
            }
            else if (httpResponse?.status === 409 && httpBody?.content?.conflictType === 1) {
                throw response;
            }
            else {
                this.modalService.openAlertServiceError({
                    response
                });
            }
        }

        throw response;
    }

    private async importData(
        importDesignProvider: IImportDesignProvider,
        design: Design,
        project: Project,
        fileName: string,
        fileNameProposal: string,
        secondTry: boolean,
        disableCalcMessages: boolean,
        projectDesign?: IProjectDesignEntity,
        openNewWindow?: boolean
    ): Promise<IImportData> {
        const importData = {} as IImportData;

        // don't check unique names for offline version
        if (this.offlineService.isOffline) {
            importData.projectDesign = projectDesign;
            importData.project = project;
            return importData;
        }

        if (this.documentService.designNameExistsOnNew(project, fileName) || secondTry) {
            try {
                return await this.modalService.openConfirmChange({
                    id: 'confirm-design-exists',
                    title: this.localizationService.getString('Agito.Hilti.Profis3.Naming.DesignNameExists.Title'),
                    message: this.localizationService.getString('Agito.Hilti.Profis3.Naming.DesignNameExists.Import.Message'),
                    confirmButtonText: this.localizationService.getString('Agito.Hilti.Profis3.Naming.DesignNameExists.Replace'),
                    cancelButtonText: this.localizationService.getString('Agito.Hilti.Profis3.Naming.DesignNameExists.Keep'),
                    onConfirm: async (modal) => {
                        const projectId = project.parentId ? project.parentId : project.id;
                        const oldDesign = await this.documentService.findDesignByName(fileName, projectId, secondTry);

                        // replace design
                        importData.openDesign = design != null && design.id == oldDesign.id;
                        importData.designPromise = importDesignProvider.updateDesignFromExternalFile(oldDesign, projectDesign, disableCalcMessages, openNewWindow) as Promise<Design>;

                        importData.project = this.documentService.findProjectById(oldDesign.projectId);

                        modal.close(importData);
                    },
                    onCancel: (modal) => {
                        if (secondTry) {
                            fileName = fileNameProposal;
                        }
                        let designs = this.documentService.findAllDesignsByProject(project);
                        designs = designs == null ? project.designs : designs;

                        const uniqueName = this.documentService.createUniqueName(fileName, Object.values(designs).map((item) => item.designName));
                        importDesignProvider.setDesignName(projectDesign, uniqueName);

                        // rename design
                        importData.renameDesign = false;
                        importData.projectDesign = projectDesign;
                        importData.project = project;

                        modal.close(importData);
                    }
                }).result;
            }
            catch (err) {
                console.error(err);

                this.importError('closed');
            }
        }

        importData.projectDesign = projectDesign;
        importData.project = project;

        return importData;
    }

    private async createDesign(
        importDesignProvider: IImportDesignProvider,
        result: IImportData,
        project: Project,
        design: Design,
        projectDesign: File | Blob,
        name: string,
        secondTry: boolean,
        disableCalcMessages: boolean,
        trackingEnabled?: boolean,
        openNewWindow?: boolean,
    ): Promise<IImportData> {
        // we have the design
        if (result.design != null) {
            return result;
        }

        if (result.designPromise != null) {
            design = (await result.designPromise) as Design;

            result.designPromise = null;
            result.design = design;

            return result;
        }

        // create design
        result = await importDesignProvider.createDesign(result, project.id, name, secondTry, disableCalcMessages, trackingEnabled, openNewWindow);
        if (result != null && result.retryImport && !secondTry) {
            const newNameProposal = result.retryNameProposal;

            result.retryImport = false;
            result.retryNameProposal = undefined;

            // retry import (second try)
            return await this.importAndUploadDesign(importDesignProvider, true, project, projectDesign, name,
                newNameProposal ?? name, design, disableCalcMessages, undefined, undefined, openNewWindow);
        }

        return result;
    }

    private openDesign(design: Design, renameDesign: boolean, rootPath: string, projectDesign: File | Blob, importProject: Project, openNewWindow: boolean) {
        if (!design) {
            return null;
        }

        const path = `${rootPath}${design.id}`;

        if (openNewWindow) {
            window.open(`${environment.baseUrl.replace(/\/+$/, '')}${path}${this.queryService.getInitialQuery()}`, '_blank');
        }
        else {
            const currentProject = Object.values(this.documentService.projects).find((proj) => proj.id == importProject.id);

            this.userService.changeDesign(currentProject, design);
            this.userService.renameFile = renameDesign;
            design.setSavedStateIdx?.();
            this.routingService.reloadWindow(path);
        }

        return design;
    }

    // Open file and read content to detect file type JSON | XML
    private readFileAsText(inputFile: File | Blob) {
        return new Promise<string>((resolve, reject) => {
            const temporaryFileReader = new FileReader();

            temporaryFileReader.addEventListener('error', () => {
                temporaryFileReader.abort();
                reject(new DOMException('Problem parsing input file.'));
            });
            temporaryFileReader.addEventListener('load', () => {
                resolve(temporaryFileReader.result as string);
            });
            temporaryFileReader.readAsText(inputFile);
        });
    }

    private findImportDesignProvider(fileContent: string, fileName: string) {
        let selectedImportProvider: IImportDesignProvider;

        const importProviders = this.modulesService.getImportDesignProviders();
        for (const importProvider of importProviders) {
            if (SafeFunctionInvokerHelper.safeInvoke(() => importProvider.checkFile(fileContent, fileName), false)) {
                selectedImportProvider = importProvider;
                break;
            }
        }

        if (selectedImportProvider != null) {
            return selectedImportProvider;
        }

        const validExtensions = uniq(importProviders.flatMap(x => SafeFunctionInvokerHelper.safeInvoke(x.getValidFileExtensions, [])));
        console.error('Import provider for selected design is not found.');
        this.modalService.openAlertWarning(
            this.localizationService.getString('Agito.Hilti.Profis3.FileUpload.WrongTypeAlert.Title'),
            format(this.localizationService.getString('Agito.Hilti.Profis3.FileUpload.WrongTypeAlert.Message'), validExtensions.join(' '))
        );

        return undefined;
    }
}
