import get from 'lodash-es/get';

import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    DesignTemplateEntity
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.DesignTemplate';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';
import {
    DesignTemplateServiceBase, IDesignTemplateDocument
} from '@profis-engineering/pe-ui-common/services/design-template.common';
import { IDefferedImageData, IDefferedRequests } from '@profis-engineering/pe-ui-common/services/document.common';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { DateTimeService } from './date-time.service';
import { DesignTemplateService } from './design-template.service';
import { DocumentService } from './document.service';
import { ErrorHandlerService } from './error-handler.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { ModalService } from './modal.service';
import { OfflineService } from './offline.service';

@Injectable({
    providedIn: 'root'
})
export class DesignTemplateWebV1Service extends DesignTemplateService implements DesignTemplateServiceBase {

    private documentThumbnails: Record<string, string> = {};
    private documentThumbnailPromise: Record<string, Promise<unknown>> = {};
    private defferedImageUpload: IDefferedRequests<unknown, unknown> = {};

    constructor(
        loggerService: LoggerService,
        dateTimeService: DateTimeService,
        apiService: ApiService,
        documentService: DocumentService,
        errorHandlerService: ErrorHandlerService,
        modalService: ModalService,
        offlineService: OfflineService,
        localizationService: LocalizationService
    ) {
        super('DesignTemplateService', loggerService, dateTimeService, apiService, documentService, errorHandlerService, modalService, offlineService, localizationService);
    }

    public async getDocumentThumbnails(documentIds: string[]): Promise<Record<string, string>> {
        const url = `${environment.documentWebServiceUrl}DocumentDesignTemplate/GetMultipleThumbnail`;

        if (documentIds == null || documentIds.length == 0) {
            return this.documentThumbnails;
        }

        const missingImages: string[] = [];

        for (const id of documentIds) {
            if (this.documentThumbnails[id] == null && this.documentThumbnailPromise[id] == null) {
                missingImages.push(id);
            }
        }

        if (missingImages.length > 0) {
            const promise = this.apiService.request<unknown>(new HttpRequest('POST', url, missingImages), this.getApiOptions());
            for (const id of missingImages) {
                this.documentThumbnailPromise[id] = promise;
            }

            try {
                const response = await promise;
                const data: any = response.body;

                this.logServiceResponse('getDocumentThumbnails', data);

                for (const item of data) {
                    this.documentThumbnails[item.designTemplateDocumentId] =
                        item.thumbnailImageContent != null && item.thumbnailImageContent != '' ? item.thumbnailImageContent : '';
                }

                return this.documentThumbnails;
            }
            finally {
                for (const id of missingImages) {
                    this.documentThumbnailPromise[id] = null;
                }
            }
        }
        else {
            return this.documentThumbnails;
        }
    }

    /**
     * Upload design printscreen image when a design report is ready for export
     * @param designId - desing id
     * @param imageContent - the design image content in base64 encoded xml format
     * @param base64ThumbnailXmlContent - the design thumbnail image content in base64 encoded xml format
     */
    public uploadDesignImage(designId: string, imageContent: string, thumbnailContent: string): Promise<void> {
        return this.uploadDesignImageInternal(designId, imageContent, thumbnailContent);
    }

    /**
     * Update design thumbnail image.
     * @param designId Design id
     * @param thumbnailContent Thumbnail image
     * @param respond If server should send response
     */
    public async updateDesignThumbnailImage(designId: string, thumbnailContent: string, respond: boolean): Promise<void> {
        // If there are no pending requests start normally
        if (this.defferedImageUpload[designId] == null || (this.defferedImageUpload[designId] != null && this.defferedImageUpload[designId].running == null)) {
            this.defferedImageUpload[designId] = {
                running: this.updateDesignThumbnailImagePromise({ designId, thumbnailContent, respond } as IDefferedImageData),
                defferedData: null,
                deffered: null
            };

            await this.defferedImageUpload[designId].running;
        }
        else {
            // Else create deffer object save request data and return deffered promise
            if (this.defferedImageUpload[designId].deffered == null) {
                this.defferedImageUpload[designId].deffered = new Deferred();
            }

            this.defferedImageUpload[designId].defferedData = {
                designId,
                thumbnailContent,
                respond,
                isTemplate: true
            };

            await this.defferedImageUpload[designId].deffered.promise;
        }
    }

    /**
     * Creates promise to update thumbnail image.
     * @param data Request data
     */
    private async updateDesignThumbnailImagePromise(data: IDefferedImageData): Promise<void> {
        try {
            await this.uploadDesignImageInternal(data.designId, null, data.thumbnailContent, data.respond);
        }
        finally {
            this.defferedImageUpload[data.designId].running = null;

            // start new request for deffered and save reference into runningDefferd to resolve it later
            // clear defferd so new requests can be added
            if (this.defferedImageUpload[data.designId].deffered != null) {
                const runningDeffered = this.defferedImageUpload[data.designId].deffered;
                this.defferedImageUpload[data.designId].running = runningDeffered.promise;

                this.updateDesignThumbnailImagePromise(this.defferedImageUpload[data.designId].defferedData as IDefferedImageData)
                    .then(() => runningDeffered.resolve())
                    .catch((err) => runningDeffered.reject(err));

                this.defferedImageUpload[data.designId].deffered = null;
                this.defferedImageUpload[data.designId].defferedData = null;
            }
        }
    }

    /**
     * Update design printscreen image when a design report is ready for export
     * @param id - desing id
     * @param base64XmlContent - the design image content in base64 encoded xml format
     * @param base64ThumbnailXmlContent - the design thumbnail image content in base64 encoded xml format
     */
    private async uploadDesignImageInternal(
        designId: string,
        base64XmlContent: string,
        base64ThumbnailXmlContent: string,
        respond = true
    ): Promise<void> {
        const url = `${this.getUrl()}DocumentDesignTemplate/ThumbnailUpdate`;
        const rData = {
            designTemplateDocumentId: designId,
            thumbnailImageContent: base64ThumbnailXmlContent
        };

        this.logServiceRequest('uploadDocumentImage', rData, url);
        const request = new HttpRequest('POST', url, rData, {
            responseType: 'json'
        });

        try {
            const response = await this.apiService.request<any>(request, this.getApiOptions());

            if (respond) {
                this.logServiceResponse('uploadDocumentImage', response);
                const data = response.body;

                this.documentThumbnails[data.designTemplateDocumentId] = data.thumbnailImageContent;
            }
            else {
                this.documentThumbnails[designId] = base64ThumbnailXmlContent;
            }
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'design-template-service', 'upload design image failed');

            if (!this.handleError(response, { designId }, [428])) {
                if (typeof response != 'object' || !('status' in response) || response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: rData
                    });
                }
            }
        }
    }

    /**
     * Get list of all Design Templates
     * @param options - Api Options
     */
    public async getList(options?: ApiOptions) {
        const url = `${this.getUrl()}DocumentDesignTemplate/List`;

        const response = await this.apiService.request<DesignTemplateEntity[]>(new HttpRequest('GET', url, options));
        this.initialize(response.body);

        return this.templates;
    }

    /**
     * Get specific template
     * @param templateId - template Id
     */
    public async getById(templateId: string) {
        const url = `${this.getUrl()}DocumentDesignTemplate/Get/` + templateId;

        const { body: template } = await this.apiService.request<DesignTemplateEntity>(new HttpRequest('GET', url));

        template.DateCreate = this.dateTimeService.toDate(template.DateCreate);
        return template;
    }

    public async create(
        designTemplateDocument: IDesignTemplateDocument,
        thumbnailId?: string
    ) {
        if (!designTemplateDocument.templateName) {
            throw new Error('Missing Template Name');
        }

        const url = `${this.getUrl()}DocumentDesignTemplate/Create`;

        try {
            if (thumbnailId) {
                const thumbnails = await this.documentService.getDocumentThumbnails([thumbnailId]);
                designTemplateDocument.thumbnailImageContent = thumbnails[thumbnailId];
            }
            const response = await this.apiService.request<string>(new HttpRequest('POST', url, designTemplateDocument), this.getApiOptions());
            await this.getList();

            return response.body;
        }
        catch (error: unknown) {
            this.handleCreateErrors(error, url, designTemplateDocument);

            return undefined;
        }
    }

    private handleCreateErrors(error: unknown, url: string, designTemplateDocument: IDesignTemplateDocument) {
        if (!this.handleError(error, null, null)) {
            if (typeof error == 'object' && 'status' in error && error.status == 409) {
                const conflictType: unknown = get(error, ['error', 'content', 'conflictType']);

                if (conflictType == 1) {
                    this.modalService.openMaxDesignTemplatesLimitReached();
                    throw error;
                }
                else if (conflictType == 0) {
                    this.errorHandlerService.showExistingTemplateNameModal(error, url, designTemplateDocument);
                    throw error;
                }
            }

            if (typeof error != 'object' || !('status' in error) || error.status != 401) {
                this.modalService.openAlertServiceError({
                    response: error,
                    endPointUrl: url,
                    requestPayload: designTemplateDocument
                });
                throw error;
            }
        }
    }

    public async delete(templateId: string) {
        const url = `${this.getUrl()}DocumentDesignTemplate/Delete/` + templateId;
        const request = new HttpRequest('DELETE', url, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });

        await this.apiService.request(request);
        await this.getList();
    }

    public async update(
        designTemplateDocument: IDesignTemplateDocument
    ) {
        if (!designTemplateDocument.designTemplateDocumentId) {
            throw new Error('Missing design Template Document Id');
        }
        if (!designTemplateDocument.templateName) {
            throw new Error('Missing Template Name');
        }

        const url = `${this.getUrl()}DocumentDesignTemplate/Update`;

        try {
            await this.apiService.request(new HttpRequest('PUT', url, designTemplateDocument), this.getApiOptions());
            await this.getList();
        }
        catch (error: unknown) {
            if (!this.handleError(error, null, null)) {
                if (typeof error == 'object' && 'status' in error && error.status == 409) {
                    this.errorHandlerService.showExistingTemplateNameModal(error, url, designTemplateDocument);
                }
                else if (typeof error != 'object' || !('status' in error) || error.status != 401) {
                    this.modalService.openAlertServiceError({
                        response: error,
                        endPointUrl: url,
                        requestPayload: designTemplateDocument
                    });
                }
            }

            throw error;
        }
    }
}
