import * as React from 'react';

import { ControlHeader } from '../../ControlHeader';
import { ControlType } from '../../ControlInfo';
import { isControlHidden } from '../../MainMenuHelper';
import { IGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/group-props';

interface IGroupState {
    isCollapsed: boolean;
}

export class Group extends React.PureComponent<IGroupProps, IGroupState> {
    constructor(props?: IGroupProps) {
        super(props);

        this.onCollapseChange = this.onCollapseChange.bind(this);

        this.state = {
            isCollapsed: true
        };
    }

    public override render() {
        if (isControlHidden(this.props)) {
            return null;
        }

        if (this.props.controls == null || this.props.controlsOrder == null ||
            this.props.controlsOrder.every(id => isControlHidden(this.props.controls[id]))) {
            return null;
        }

        const collapsableClass = this.props.collapsableGroup ? 'collapsable' : '';
        const multilineClass = this.props.multilineGroup ? 'multiline' : '';
        const collapsed = this.props.collapsableGroup && this.state.isCollapsed ? 'hidden' : '';

        const groupId = `${this.props.controlId}-group`;

        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled ? this.props.tooltipDisabled : this.props.tooltip;

        return (
            <div data-control-id={this.props.controlId} className={`control react-group-control ${this.props.sizeClass} ${collapsableClass}`}>
                <ControlHeader
                    text={this.props.title}
                    controlId={groupId}
                    tooltip={tooltipText}
                    tooltipTitle={tooltipTitle}
                    localization={this.props.localization}
                />

                {this.props.collapsableGroup ? <GroupCollapseButton collapsed={this.state.isCollapsed} collapseChanged={this.onCollapseChange} /> : null}

                <div
                    className={`controls ${multilineClass} ${collapsed}`}
                    data-control-id={groupId}>
                    {
                        this.props.controlsOrder.map((id) => {
                            const control = this.props.controls[id];
                            const props = {
                                ...control,
                                key: control.controlId,
                                controls: (control as any).controlsOrder != null ? this.props.controls : undefined,  // HACK: so that group controls work
                            };

                            if(this.props.applyControlsStyle != undefined) {
                                this.props.applyControlsStyle(control.type);
                            }

                            return React.createElement(control.type, props);
                        })
                    }
                </div>
            </div>
        );
    }

    private onCollapseChange(collapsed: boolean) {
        this.setState({
            isCollapsed: collapsed
        });
    }
}

ControlType.Group = Group;

interface IGroupCollapseButtonProps {
    collapsed: boolean;
    collapseChanged: (collapsed: boolean) => void;
}

class GroupCollapseButton extends React.PureComponent<IGroupCollapseButtonProps> {
    constructor(props?: IGroupCollapseButtonProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    public override render() {
        const openedClass = !this.props.collapsed ? 'opened' : '';

        return (
            <div className={`design-right-collapse-container ${openedClass}`}>
                <span className='design-right-collapse-line'></span>
                <button type='button' className='design-right-collapse-button' onClick={this.onClick}>
                    <span className='sprite design-right-collapse-image'></span>
                </button>
                <span className='design-right-collapse-line'></span>
            </div>
        );
    }

    private onClick(event: React.MouseEvent) {
        if (this.props.collapseChanged != null) {
            this.props.collapseChanged(!this.props.collapsed);
        }
    }
}
