<div class="section modal-body">
    <!-- header with img-->
    <div class="modal-body-header"
        [class.grape]="modalType == modalTypeEnum.trialExpired || modalType == modalTypeEnum.standard">
        <div class="header-text">
            <span l10n="Agito.Hilti.Profis3.Trial.Welcome.Title" class="red"
                [class.white]="modalType == modalTypeEnum.trialExpired || modalType == modalTypeEnum.standard">
            </span>

            <button id="trial-banner-close-button" type="button" class="close" (click)="close()"
                [disabled]="submitted" *ngIf="modalType == modalTypeEnum.trial">
                <span>&times;</span>
            </button>
        </div>
    </div>

    <div class="section license-info">
        <div class="section-title">
            <p [class.red]="modalType == modalTypeEnum.trialExpired || modalType == modalTypeEnum.standard" [innerHtml]="getSectionTitle"></p>
        </div>

        <div class="info">
            <div class="info-text">
                <p class="body-header-title">
                    <span [l10n]="trialBannerInfoText"></span>
                    <button *ngIf="showLicenseComparisonButton" class="click-here" (click)="licenseCompare()"
                        id="click-me">
                        <div class="hilti-styled-text" l10n="Agito.Hilti.Profis3.clickHere.Button.Text"></div>
                    </button>
                </p>
            </div>

            <div class="info-button">
                <button (click)="close()" id="dismiss-button"
                    class="hilti-styled-button hilti-styled-button-secondary close-button"
                    *ngIf="modalType == modalTypeEnum.trial">
                    <div class="hilti-styled-text" l10n="Agito.Hilti.Profis3.Dismiss.Button.Text"></div>
                </button>

                <div class="button-wrapper" *ngIf="modalType == modalTypeEnum.standard">
                    <button (click)="continueWithStandard()" id="continue-with-standard-button"
                        class="hilti-styled-button hilti-styled-button-secondary close-button">
                        <div class="hilti-styled-text" l10n="Agito.Hilti.Profis3.TrialBanner.ContinueWithStandard.Button"></div>
                    </button>
                    <span l10n="Agito.Hilti.Profis3.TrialBanner.ContinueWithStandard.Info"></span>
                </div>

                <button (click)="licenseCompare()" id="compare-premium"
                    class="hilti-styled-button hilti-styled-button-primary save-button"
                    *ngIf="showLicenseComparisonButton">
                    <div class="hilti-styled-text" l10n="Agito.Hilti.Profis3.Compare.Button.Text"></div>
                </button>

                <button (click)="purchase()" id="purchase-button"
                    class="hilti-styled-button hilti-styled-button-primary save-button"
                    *ngIf="showPurchaseButton">
                    <div class="hilti-styled-text" l10n="Agito.Hilti.Profis3.Purchase.Button.Text"></div>
                </button>
            </div>
        </div>

        <div class="section-footer">
            <span class="section-logout" *ngIf="modalType == modalTypeEnum.trialExpired || modalType == modalTypeEnum.standard" (click)="logout()"
                l10n="Agito.Hilti.Profis3.TrialExpired.Button.Logout.Text"></span>
        </div>
    </div>
</div>
