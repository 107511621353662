import isEqual from 'lodash-es/isEqual';
import some from 'lodash-es/some';
import sortBy from 'lodash-es/sortBy';
import split from 'lodash-es/split';
import { Subscription } from 'rxjs';
import Sortable, { Options } from 'sortablejs';

import { HttpRequest } from '@angular/common/http';
import {
    AfterViewInit, Component, ElementRef, OnDestroy, OnInit, TrackByFunction, ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
    DesignStandard as DesignStandardC2C
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/design-standard';
import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    ExportReportRowBase
} from '@profis-engineering/pe-ui-common/components/export-report-row-base/export-report-row-base.common';
import {
    CodeList, getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { IProjectAndDesignId } from '@profis-engineering/pe-ui-common/entities/design';
import {
    DisplayDesignType, IDetailedDisplayDesign as IDetailedDisplayDesignCommon
} from '@profis-engineering/pe-ui-common/entities/display-design';
import { IMainHeaderComponent } from '@profis-engineering/pe-ui-common/entities/main-header';
import {
    IQuickStartApplication
} from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import {
    NotificationType, NotificationTypeImage, NotificationTypeMap, NotificationTypeName
} from '@profis-engineering/pe-ui-common/entities/notifications';
import { ProjectType } from '@profis-engineering/pe-ui-common/entities/project';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import {
    DocumentModel
} from '@profis-engineering/pe-ui-common/generated-modules//Hilti.PE.IntegrationServices.Shared.Entities.Document';
import {
    DocumentIntegrationType
} from '@profis-engineering/pe-ui-common/generated-modules//Hilti.PE.IntegrationServices.Shared.Entities.Enums';
import {
    Feature, KnownRegion, RegionHub
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    RegionMarketingCampaign
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.ProductInformationService.Shared.Entities';
import { MenuType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.UserSettings.Shared.Enums';
import {
    ApplicationSettingsDisplayType, SpecialRegion
} from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import {
    SafeFunctionInvokerHelper
} from '@profis-engineering/pe-ui-common/helpers/safe-function-invoker-helper';
import {
    format, stringNullOrEmpty, trim
} from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { ButtonEventType } from '@profis-engineering/pe-ui-common/services/common-tracking.common';
import {
    CantArchiveProjectsBecauseDocumentInUse, CantOpenDesignBecauseLockedByOtherUser
} from '@profis-engineering/pe-ui-common/services/document.common';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';
import {
    ProjectAndDesignDesignGroup as DesignGroup, ProjectAndDesignView as DesignView
} from '@profis-engineering/pe-ui-common/services/user.common';
import {
    IDeckingDesignListInfo
} from '@profis-engineering/pe-ui-decking/src/decking/entities/decking-design-list-info';
import {
    DesignStandard
} from '@profis-engineering/pe-ui-shared/entities/code-lists/design-standard';
import { DesignType } from '@profis-engineering/pe-ui-shared/entities/code-lists/design-type';
import {
    DesignTemplateInternalEntity
} from '@profis-engineering/pe-ui-shared/generated-modules//Hilti.PE.Core.Entities.Baseplate.Template';
import {
    IIMportDesign, ImportDesignMode
} from 'src/app/components/import-design/import-design.component';
import { environment } from '../../../environments/environment';
import { ModalDialogType } from '../../components/add-edit-design/add-edit-design-models';
import { DragActiveEvent } from '../../directives/drag.directive';
import { CollapsingControls } from '../../entities/collapsing-controls';
import { Design } from '../../entities/design';
import { IDisplayDesign } from '../../entities/display-design';
import { Project } from '../../entities/project';
import { urlPath } from '../../module-constants';
import { ApiService } from '../../services/api.service';
import { BrowserService } from '../../services/browser.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { CommonTrackingService } from '../../services/common-tracking.service';
import { DesignTemplateService } from '../../services/design-template.service';
import { DocumentService, IDesignListItem } from '../../services/document.service';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { ExportService } from '../../services/export.service';
import { ExternalAppsService } from '../../services/external-apps.service';
import { FavoritesService } from '../../services/favorites.service';
import { FeatureVisibilityService } from '../../services/feature-visibility.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { GuidService } from '../../services/guid.service';
import { IntegrationsDataService } from '../../services/integrations-data.service';
import { IntegrationsDocumentService } from '../../services/integrations-document.service';
import { IntegrationsNotificationService } from '../../services/integrations-notification.service';
import { LicenseService } from '../../services/license.service';
import { LocalizationService } from '../../services/localization.service';
import { LoggerService } from '../../services/logger.service';
import { ModalService } from '../../services/modal.service';
import { DesignTypeId, ModulesService } from '../../services/modules.service';
import { NpsService } from '../../services/nps.service';
import { NumberService } from '../../services/number.service';
import { OfflineService } from '../../services/offline.service';
import { ProductInformationService } from '../../services/product-information.service';
import { RegionOrderService } from '../../services/region-order.service';
import { RoutingService } from '../../services/routing.service';
import { TourService } from '../../services/tour.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { ComparisonTable } from '../license-comparison-hol/license-comparison-hol.component';

export interface ILeftNavigationItem {
    id: string;
    image: string;
    tooltip: string;
    showRedDot: boolean;
    click: () => void;
    bottomSeparator?: boolean;
    visible: () => boolean;
}

export enum PendingAction {
    newProject,
    newSubProject,
    renameProject,
    import,
    openDesign,
    loadTemplate,
    deleteTemplate,
    createDesign,
    assignProject,
    archiveProject,
    createQuickStartDesign,
    deleteDesign,
    downloadProject,
    downloadAllDesigns,
    copyDesign,
    renameDesign
}

export enum DesignSort {
    Newest = 1,
    Oldest = 2,
    AtoZ = 3,
    ZtoA = 4
}

export enum EditProjectType {
    none,
    new,
    newSub,
    rename,
    newCompanyProject,
    newCompanySub
}

export enum OpenDesignType {
    threeDee,
    settings
}

enum LeftNavigationItems {
    home = 'home',
    settings = 'settings',
    templates = 'report-templates',
    learning = 'e-learning'
}

export interface IDisplayProject {
    id: string;
    name: string;
    parentId: string;
    rawProject: Project;
    designs: IDisplayDesign[];
    subProjects: IDisplayProject[];
    created: Date;
    owner: boolean;
    isCompanyProject: boolean;
    expanded: boolean;
    readOnly: boolean;
}

export interface IGroupedDisplayDesign {
    designType: number;
    designTypeName: string;
    designs: IDisplayDesign[];
    designsCount: number;
}

export interface INotification {
    message: string;
    type: NotificationType;
}

interface ISectionCollapsed {
    control: CollapsingControls;
    collapsed: boolean;
}

interface ISectionsCollapsed {
    overview: ISectionCollapsed;
    projects: ISectionCollapsed;
    notificationsMainLeft: ISectionCollapsed;
    quickStart: ISectionCollapsed;
    hiltiApplications: ISectionCollapsed;
    designs: ISectionCollapsed;
    fileImports: ISectionCollapsed;
    inAppMarketing: ISectionCollapsed;
    notificationsMainRight: ISectionCollapsed;
    designGuide: ISectionCollapsed;
    woodLinks: ISectionCollapsed;
}

@Component({
    selector: 'app-project-and-design',
    templateUrl: './project-and-design.component.html',
    styleUrls: ['./project-and-design.component.scss']
})
export class ProjectAndDesignComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('mainHeaderRef')
    public mainHeaderComponentRef!: ElementRef<IMainHeaderComponent>;

    public quickStartApplications: IQuickStartApplication[] = [];

    public _designView: DesignView = DesignView.allDesigns;

    public projects: IDisplayProject[] = [];
    public groupedDesigns: IGroupedDisplayDesign[];
    public editProjectType: EditProjectType;
    public editProjectName: string;
    public sortDesignsDropdown: Pick<DropdownProps<DesignSort>, 'items' | 'selectedValue'>;
    public _designSearchValue: string;
    public _projectFilterString: string;
    public projectFilterResult: { [id: string]: boolean };
    public pendingAction: PendingAction;
    public pendingActionDesign: IProjectAndDesignId;
    public pendingActionProject: Project;
    public importDesign: IIMportDesign;
    public pendingActionQuickStart: string;
    public leftNavigation: ILeftNavigationItem[];
    public leftNavigationSelectedButton: ILeftNavigationItem;
    public notifications: INotification[];
    public overviewItemCount: { [key: number]: number } = {};
    public projectItemCount: { [key: string]: number } = {};
    public renameProjectType: { [key: string]: EditProjectType } = {};
    public groupedDesignsLimit: { [key: number]: number } = {};
    public sortableLeftMenuProject: Options;
    public sortableRightMenuProject: Options;
    public inAppCampaigns: RegionMarketingCampaign[];

    public SafeFunctionInvokerHelper = SafeFunctionInvokerHelper;

    public sectionsCollapsed: ISectionsCollapsed = {
        overview: {
            collapsed: false,
            control: CollapsingControls.Overview
        },
        projects: {
            collapsed: false,
            control: CollapsingControls.Projects
        },
        notificationsMainLeft: {
            collapsed: false,
            control: CollapsingControls.NotificationsMainLeft
        },
        quickStart: {
            collapsed: false,
            control: CollapsingControls.QuickStart
        },
        hiltiApplications: {
            collapsed: false,
            control: CollapsingControls.HiltiAplications
        },
        designs: {
            collapsed: false,
            control: CollapsingControls.Designs
        },
        fileImports: {
            collapsed: false,
            control: CollapsingControls.FileImports
        },
        inAppMarketing: {
            collapsed: false,
            control: CollapsingControls.InAppMarketing
        },
        notificationsMainRight: {
            collapsed: false,
            control: CollapsingControls.NotificationsMainRight
        },
        designGuide: {
            collapsed: false,
            control: CollapsingControls.DesignGuide
        },
        woodLinks: {
            collapsed: false,
            control: CollapsingControls.WoodLinks
        }
    };

    public newProjectParent: IDisplayProject;
    public disabled: boolean;

    public DesignView = DesignView;
    public DesignGroup = DesignGroup;
    public EditProjectType = EditProjectType;
    public PendingAction = PendingAction;

    public DisplayDesignType = DisplayDesignType;
    public OpenDesignType = OpenDesignType;
    public NotificationType = NotificationTypeMap;
    public NotificationTypeImage = NotificationTypeImage;
    public NotificationTypeName = NotificationTypeName;

    public designDragDisabled = false;
    public designDropActive = false;
    public designDropDisabled = false;

    public DocumentIntegrationType: Record<keyof typeof DocumentIntegrationType, DocumentIntegrationType> = {
        Unknown: DocumentIntegrationType.Unknown,
        Risa: DocumentIntegrationType.Risa,
        ProfisEngineer: DocumentIntegrationType.ProfisEngineer,
        Ram: DocumentIntegrationType.Ram,
        AnchorCalculationIntegration: DocumentIntegrationType.AnchorCalculationIntegration
    };

    public displayedDropdown: string;
    public readonly typeDesignsMaxHeight = 600;
    public groupedDesignsLimitStep: number;
    public selectedProjectDisplayName = '';
    public deckingEnabled = false;
    public isWoodModuleShown = false;
    public selectedDesigns = new Array<IDisplayDesign>();
    private _selectedProject: IDisplayProject;

    private selectedRenameProject: IDisplayProject;
    private _importing: boolean;
    private _isModalOpen: boolean;


    private localizationChangeSubscription: Subscription;
    private userSettingsSavedChangeSubscription: Subscription;
    private quickStartModulesAddedSubscription: Subscription;
    private designListInfoProvidedSubscription: Subscription;

    private regions: { [id: string]: CommonRegion; };
    private designTypes: { [id: string]: DesignType; };

    constructor(
        public localization: LocalizationService,
        public offlineService: OfflineService,
        public userSettingsService: UserSettingsService,
        public integrationsDocument: IntegrationsDocumentService,
        public license: LicenseService,
        public sanitizer: DomSanitizer,
        public externalAppsService: ExternalAppsService,
        private readonly routingService: RoutingService,
        private readonly featuresVisibilityInfo: FeaturesVisibilityInfoService,
        private readonly document: DocumentService,
        private readonly tour: TourService,
        private readonly user: UserService,
        private readonly logger: LoggerService,
        private readonly guid: GuidService,
        private readonly modal: ModalService,
        private readonly codeListCommon: CommonCodeListService,
        private readonly commonTrackingService: CommonTrackingService,
        private readonly designTemplate: DesignTemplateService,
        private readonly regionOrder: RegionOrderService,
        private readonly favorites: FavoritesService,
        private readonly browser: BrowserService,
        private readonly onServiceErrorHandler: ErrorHandlerService,
        private readonly productInformation: ProductInformationService,
        private readonly npsService: NpsService,
        private readonly integrationsNotification: IntegrationsNotificationService,
        private readonly integrationsData: IntegrationsDataService,
        private readonly apiService: ApiService,
        private readonly userSettings: UserSettingsService,
        private readonly numberService: NumberService,
        private readonly modulesService: ModulesService,
        private readonly featureVisibilityService: FeatureVisibilityService,
        private readonly exportService: ExportService
    ) { }

    public get region() {
        return this.getRegionById(this.userSettingsService.settings.application.general.regionId.value);
    }

    public get designView(): DesignView {
        return this._designView;
    }

    public set designView(value: DesignView) {
        if (value !== this._designView) {
            this._designView = value;

            this.onDesignViewChange(value);
        }
    }

    public get selectedProject(): IDisplayProject {
        return this._selectedProject;
    }

    public set selectedProject(value: IDisplayProject) {
        if (value !== this._selectedProject) {
            const idChanged = value?.id !== this._selectedProject?.id;
            this._selectedProject = value;

            if (idChanged) {
                this.onSelectedProjectChange(value);
            }
        }
    }

    public get sortDesignsSelectedValue(): DesignSort {
        return this.sortDesignsDropdown.selectedValue;
    }

    public set sortDesignsSelectedValue(value: DesignSort) {
        if (this.sortDesignsDropdown.selectedValue !== value) {
            this.sortDesignsDropdown.selectedValue = value;
            this.onDesignSortChange(value, this.sortDesignsDropdown.selectedValue);
        }
    }

    public get designSearchValue(): string {
        return this._designSearchValue;
    }

    public set designSearchValue(value: string) {
        if (value !== this._designSearchValue) {
            this._designSearchValue = value;
            this.refreshProjectsAndDesigns();
        }
    }

    public get projectFilterString(): string {
        return this._projectFilterString;
    }

    public set projectFilterString(value: string) {
        if (this._projectFilterString !== value) {
            this._projectFilterString = value;
            this.filterProjects();
        }
    }

    public get regionLanguage() {
        return this.userSettingsService.getRegionLanguage();
    }

    public get globalRegion() {
        return this.userSettingsService.getCommonRegionById(this.userSettingsService.settings.application.general.regionId.value);
    }

    public get hasProject() {
        return this.projects.length > 0;
    }

    public get hasDesign() {
        return this.document.draftsProject.designs != null && Object.keys(this.document.draftsProject.designs).length > 0 ||
            Object.values(this.document.draftsProject.subProjects).some(p => p.designs != null && Object.keys(p.designs).length > 0) ||
            this.projects.some(p => p.designs.length > 0 || p.subProjects.some(sub => sub.designs.length > 0));
    }

    public get hasTemplate() {
        return this.designTemplate.templates.length > 0;
    }

    public get importing() {
        return this._importing;
    }

    public set importing(value) {
        if (this._importing !== value) {
            this._importing = value;

            // sync pending action
            if (this._importing) {
                this.pendingAction = PendingAction.import;
            }
            else if (this.pendingAction == PendingAction.import) {
                this.pendingAction = null;
            }
        }
    }

    public get draftsProject() {
        return this.document.draftsProject;
    }

    public get designGroup() {
        return this.userSettingsService.settings.designGroup.value != null ? this.userSettingsService.settings.designGroup.value : DesignGroup.none;
    }

    public set designGroup(value) {
        this.userSettingsService.settings.designGroup.value = value;
        this.userSettingsService.save();
    }

    public get designSort() {
        return this.userSettingsService.settings.designSort.value != null ? this.userSettingsService.settings.designSort.value : DesignSort.Newest;
    }

    public set designSort(value) {
        this.userSettingsService.settings.designSort.value = value;
        this.userSettingsService.save();
    }

    public get projectFilterPlaceholder() {
        return this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesign.Navigation.Projects.Search');
    }

    public get isArchiveVisible() {
        return (this.document.projectsArchive || []).length > 0;
    }

    public get archiveButtonText() {
        return this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.ArchiveButton') + ` (${(this.document.projectsArchive || []).length})`;
    }

    public get designGuides() {
        const designGuides = this.productInformation.designGuies?.filter(x => x.RegionId == this.userSettings.settings.application.general.regionId.value) ?? [];
        const designGuidesFiltered = designGuides.filter(x => x.Icid == this.userSettings.settings.application.general.languageId.value);

        if (!designGuidesFiltered.length) {
            return designGuides.filter(x => x.Icid == 0);
        }

        return designGuidesFiltered;
    }

    public get woodLinks() {
        const woodLinks = this.productInformation.woodLinks?.filter(x => x.RegionId == this.userSettings.settings.application.general.regionId.value) ?? [];
        const woodLinksFiltered = woodLinks.filter(x => x.LanguageId == this.userSettings.settings.application.general.languageId.value);

        if (!woodLinksFiltered.length) {
            return woodLinks.filter(x => x.LanguageId == 0);
        }

        return woodLinksFiltered;
    }

    public get addNewCompanyProjectVisible() {
        return this.user.authentication != null && !this.user.hasTrialLicense
            && (this.user.authentication.customerOriginId && this.user.authentication.country);
    }

    public get companyProjectDisabled() {
        return this.user.hasFreeLicense || this.user.hasfloatingLimitReached;
    }

    public get getCompanyDisabledTooltip() {
        if (!this.companyProjectDisabled) {
            return null;
        }

        return this.localization.getString('Agito.Hilti.Profis3.Convert.Disabled.ToolTip');
    }

    public get templatesDisabled() {
        return this.featuresVisibilityInfo.isDisabled(Feature.Application_DesignTemplate, this.globalRegion.id);
    }

    public get templatesHidden() {
        return this.featuresVisibilityInfo.isHidden(Feature.Application_DesignTemplate, this.globalRegion.id);
    }

    public get templatesTooltip() {
        return this.featuresVisibilityInfo.tooltip(Feature.Application_DesignTemplate);
    }

    public get isHiltiApplicationsSectionVisible() {
        return this.externalAppsService.hiltiApplications.length > 0;
    }

    public get showElearningnLink() {
        const betLink = this.productInformation.regionLinksForUser(this.userSettingsService.settings.application.general.regionId.value)?.BetLearningLink;
        return betLink != null && betLink != '';
    }

    public get allDesignsButtonTranslation() {
        if (this.offlineService.isOffline) {
            return this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.QuickStart');
        }

        return this.getOverViewTranslation('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Designs.AllDesigns', DesignView.allDesigns);
    }

    public get fileImportsSectionVisible() {
        // Integrations are disabled for users with standard license. In case of RAM we should still allow users to import it with standard license.
        const showRisaImportSection = this.featuresVisibilityInfo.areIntegrationsEnabled && this.userSettingsService.settings.application.general.risaEnabled.value && this.integrationsDocument.RisaDocuments?.length > 0;
        const showRamImportSection = this.userSettingsService.settings.application.general.ramEnabled.value && this.integrationsDocument.RamDocuments?.length > 0;

        return showRisaImportSection || showRamImportSection;
    }

    public get canExportReports(): string {
        const messages: Array<string> = [];
        if (this.selectedDesigns.filter(x => x.isCBFEM).length > ExportReportRowBase.MAX_CBFEM_REPORTS)
            messages.push(format(this.localization.getString('Agito.Hilti.Profis3.ExportReports.Message.MaxCBFEM'), ExportReportRowBase.MAX_CBFEM_REPORTS));

        if (this.selectedDesigns.filter(x => !x.isCBFEM).length > ExportReportRowBase.MAX_REPORTS)
            messages.push(format(this.localization.getString('Agito.Hilti.Profis3.ExportReports.Message.MaxRigid'), ExportReportRowBase.MAX_REPORTS));

        return messages.join(', ');
    }

    public async ngOnInit() {
        this.deckingEnabled = this.featureVisibilityService.isFeatureEnabled('Decking_Global') || environment.deckingEnabled;
        this.resetWoodModule();

        this.logger.log('ProjectAndDesignController::ctor', LogType.debug);
        this.regions = this.normalizeArray(this.codeListCommon.commonCodeLists[CommonCodeList.Region] as CommonRegion[], 'id');
        this.groupedDesignsLimitStep = 12;
        this.projectFilterResult = {};

        this.designTypes = this.normalizeArray(this.modulesService.designTypes as DesignType[], 'id');

        this.reloadQuickStartApplications();
        this.quickStartModulesAddedSubscription = this.modulesService.quickStartModulesAdded.subscribe(() => this.reloadQuickStartApplications());
        this.designListInfoProvidedSubscription = this.modulesService.designListInfoProvided.subscribe(() => {
            this.designTypes = this.normalizeArray(this.modulesService.designTypes as DesignType[], 'id');
            this.selectedProject = this.toDisplayProject(this.user.project || this.document.draftsProject);
            this.refreshProjectsAndDesigns();
            this.resetGroupedDesignsLimit();
            this.onDesignViewChange(this.designView);
        });

        this.resize = this.resize.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.viewContentLoaded = this.viewContentLoaded.bind(this);
        this.filterProjects = this.filterProjects.bind(this);
        this.onDesignImporting = this.onDesignImporting.bind(this);
        this.languageChanged = this.languageChanged.bind(this);
        this.resetWoodModule = this.resetWoodModule.bind(this);
        this.startTour = this.startTour.bind(this);
        this.openApplicationSettings = this.openApplicationSettings.bind(this);
        this.newDesignFromDesignTypeId = this.newDesignFromDesignTypeId.bind(this);
        this.showVirtualTour = this.showVirtualTour.bind(this);

        this.sortableLeftMenuProject = {
            handle: '.drag-handle-static',
            store: {
                get: (sortable) => {
                    return [];
                },
                set: (sortable) => {
                    this.regionOrder.update(sortable.toArray(), MenuType.LeftMenuProject);
                }
            }
        };

        this.sortableRightMenuProject = {
            handle: '.drag-handle-static',
            store: {
                get: (sortable) => {
                    return [];
                },
                set: (sortable) => {
                    this.regionOrder.update(sortable.toArray(), MenuType.RightMenuProject);
                }
            }
        };

        this.userSettingsSavedChangeSubscription = this.userSettingsService.userSettingsSaved.subscribe(this.resetWoodModule);
        this.localizationChangeSubscription = this.localization.localizationChange.subscribe(this.languageChanged);
        document.body.classList.add(`project-and-design-view-body-${this.offlineService.isOffline ? 'offline' : 'online'}`);

        this.user.changeDesign(undefined, null);

        this.editProjectType = EditProjectType.none;

        this.importDesign = {
            disabled: this.disabled,
            mode: this.offlineService.isOffline ? ImportDesignMode.compact : ImportDesignMode.normal,
            importing: false
        };

        this.sortDesignsDropdown = {
            selectedValue: this.designSort,
            items: this.initSortDropdownItems()
        };

        this._designView = this.offlineService.isOffline ? DesignView.allDesigns : this.user.projectAndDesignView;

        if (this.designView == DesignView.templates) {
            this.selectedProject = this.toDisplayProject(this.document.draftsProject);
        }
        else {
            this.selectedProject = this.toDisplayProject(this.user.project || this.document.draftsProject);
        }

        this.sectionsCollapsed.overview.collapsed = this.userSettingsService.isSectionCollapsed(this.sectionsCollapsed.overview.control);
        this.sectionsCollapsed.projects.collapsed = this.userSettingsService.isSectionCollapsed(this.sectionsCollapsed.projects.control);
        this.sectionsCollapsed.notificationsMainLeft.collapsed = this.userSettingsService.isSectionCollapsed(this.sectionsCollapsed.notificationsMainLeft.control);
        this.sectionsCollapsed.quickStart.collapsed = this.userSettingsService.isSectionCollapsed(this.sectionsCollapsed.quickStart.control);
        this.sectionsCollapsed.hiltiApplications.collapsed = this.userSettingsService.isSectionCollapsed(this.sectionsCollapsed.hiltiApplications.control);
        this.sectionsCollapsed.designs.collapsed = this.userSettingsService.isSectionCollapsed(this.sectionsCollapsed.designs.control);
        this.sectionsCollapsed.fileImports.collapsed = this.userSettingsService.isSectionCollapsed(this.sectionsCollapsed.fileImports.control);
        this.sectionsCollapsed.notificationsMainRight.collapsed = this.userSettingsService.isSectionCollapsed(this.sectionsCollapsed.notificationsMainRight.control);
        this.sectionsCollapsed.designGuide.collapsed = this.userSettingsService.isSectionCollapsed(this.sectionsCollapsed.designGuide.control);
        this.sectionsCollapsed.woodLinks.collapsed = this.userSettingsService.isSectionCollapsed(this.sectionsCollapsed.woodLinks.control);

        this.refreshProjectsAndDesigns();
        this.resetGroupedDesignsLimit();
        this.onDesignViewChange(this.designView);

        this.initLeftNavigation();
        this.loadNotifications();

        this.integrationsDocument.handleUnopenedExternalDesignsPopup();

        this._isModalOpen = false;

        window.addEventListener('resize', this.resize, false);
        document.addEventListener('keydown', this.onKeyDown, false);
        if (this.license.displayTrialInfo) {
            this.license.displayTrialInfo = false;

            let failedRequest: boolean;
            let data: ComparisonTable;
            let holLicenseLanguageId = this.userSettings.getLanguage().id;
            const region = this.userSettings.getRegionByCountryCode(this.user.authentication.country);
            // For trial license we use old popup where we show when license will expire.
            if (region != null && !this.license.isTrial()) {
                const licenseComparisonPageLink = this.productInformation.regionLinksForUser(region.id)?.LicenseComparisonPageLink;
                if (licenseComparisonPageLink != undefined && licenseComparisonPageLink.trim() != '') {
                    try {
                        /*
                        * BUDQBP-32961:
                        * The language of the license page is selected based on the region and language mapping we received from the HOL team.
                        * We also select a holLicense LanguageId for a specific region, which is used to translate our translation keys into the same language supported by HOL.
                        * If we did not get a mapping for a specific region, then the language list does not exist. In this case, we are trying to get data based on culture
                        */
                        let language: string;
                        if (region.licensePageLanguages != null) {
                            const licensePageLanguage = region.licensePageLanguages.find(x => x.LcId == holLicenseLanguageId) ?? region.licensePageLanguages.find(x => x.DefaultForRegion);
                            language = licensePageLanguage.HolLanguageCode;
                            holLicenseLanguageId = licensePageLanguage.LcId;
                        }
                        else {
                            const cultureLanguage = this.userSettings.getCountryCodeCulture(this.user.authentication.country)
                            language = split(cultureLanguage, '-')[0];
                        }
                        const url = licenseComparisonPageLink.replace("{language}", language);
                        const request = new HttpRequest('GET', url);

                        data = (await this.apiService.request<ComparisonTable>(request, { supressErrorMessage: true, forceIncludeAuthenticationHeaders: true })).body;
                    }
                    catch (err) {
                        console.warn(err);
                        failedRequest = true;
                    }
                }
            }

            if (!failedRequest && data != null) {
                this.modal.openLicenseComparisonHol(data, holLicenseLanguageId).closed
                    .then(async () => {
                        await this.npsService.handleSurvey();
                        this.handleTourAndMarketingCampaigns();
                    })
            }
            else {
                this.modal.openTrialBanner().closed
                    .then(async () => {
                        await this.npsService.handleSurvey();
                        this.handleTourAndMarketingCampaigns();
                    });
            }
        }
        else {
            await this.npsService.handleSurvey();
            this.handleTourAndMarketingCampaigns()
        }

        // If user has floating license and all seats are taken, show him a message
        if (this.license.displayFloatingLimitReachedInfo) {
            this.license.displayFloatingLimitReachedInfo = false;
            this.modal.openConfirmChange({
                id: 'floating-license-limit-reached-popup',
                title: this.translate('Agito.Hilti.Profis3.License.Floating.LimitReached.Title'),
                message: this.translate('Agito.Hilti.Profis3.License.Floating.LimitReached.Text'),
                confirmButtonText: this.translate('Agito.Hilti.Profis3.ControlTooltip.Ok'),
                onConfirm: (modal) => {
                    modal.close();
                }
            });
        }

        if (environment.alertTestingEnvironment) {
            this.modal.openAlertTestingEnvironment();
        }
    }

    public ngAfterViewInit(): void {
        setTimeout(() => this.viewContentLoaded());

        this.integrationsNotification
            .connect()
            .then((success) => {
                if (!success) {
                    return;
                }
                this.integrationsData.registerNewDataAvailableHandler();
                this.integrationsDocument.registerNewDocumentNotificationHandler();
            });
    }

    public ngOnDestroy(): void {
        // Remove subscriptions
        this.localizationChangeSubscription?.unsubscribe();
        this.userSettingsSavedChangeSubscription?.unsubscribe();
        this.quickStartModulesAddedSubscription?.unsubscribe();
        this.designListInfoProvidedSubscription?.unsubscribe();

        document.body.classList.remove(`project-and-design-view-body-${this.offlineService.isOffline ? 'offline' : 'online'}`);

        // Remove event listeners
        window.removeEventListener('resize', this.resize, false);
        document.removeEventListener('keydown', this.onKeyDown, false);
    }

    public sortableLeftMenuProjectInit(sortable: Sortable) {
        sortable.sort(this.favorites.leftMenuProjectOrder.map(String));
    }

    public sortableRightMenuProjectInit(sortable: Sortable) {
        sortable.sort(this.favorites.rightMenuProjectOrder.map(String));
    }

    public dragActive(input: DragActiveEvent): void {
        this.designDropActive = input.active;
        const designId = (event.currentTarget as HTMLElement).dataset['designId'];
        const design = this.document.findDesignById(designId);
        input.event.dataTransfer.setData('Text', JSON.stringify({ designId: design.id, projectId: design.projectId } as IProjectAndDesignId));
    }

    public async dropEvent(event: DragEvent) {
        if (this.pendingAction != null || event.dataTransfer.getData('Text') == '') {
            return;
        }

        const projectId = (event.currentTarget as HTMLElement).dataset['projectId'];
        const project = this.document.findProjectById(projectId);

        const transferDesign: IProjectAndDesignId = JSON.parse(event.dataTransfer.getData('Text'));

        if (transferDesign.projectId == project.id) {
            return;
        }

        const design = this.document.findDesignById(transferDesign.designId);
        this.pendingAction = PendingAction.renameDesign;
        this.pendingActionDesign = ({ designId: transferDesign.designId, projectId: transferDesign.projectId } as IProjectAndDesignId);

        if (design != null && this.document.designNameExistsOnEdit(project, design)) {
            const isDecking = design.metaData.designType === DesignTypeId.DiaphragmDesign;
            if (isDecking) {
                await this.modal.openDeckingRenameDesign({
                    documentId: design.id,
                    dropProject: project,
                    onDesignRenamed: (project: Project) => {
                        this.selectedProject = this.toDisplayProject(project);
                        this.projectFilterResult[project.id] = true;
                    }
                }).closed;
            } else {
                await this.modal.openRenameDesign({
                    design: await this.getDesign(design.metaData.designType, design.id),
                    dropProject: project,
                    onDesignRenamed: (project: Project) => {
                        this.selectedProject = this.toDisplayProject(project);
                        this.projectFilterResult[project.id] = true;
                    }
                }).closed;
            }
        } else {
            await this.document.moveDesign(transferDesign.designId, project.id);
            this.selectedProject = this.toDisplayProject(project);
        }
        this.refreshProjectsAndDesigns();
        this.clearPendingAction();
    }

    private async getDesign(designTypeId: number, designId: string): Promise<Design> {
        const designInfo = this.modulesService.getDesignListInfo().find(x => x.designTypeId == designTypeId);
        if (designInfo?.getDesign) {
            try {
                return await designInfo.getDesign(designId) as unknown as Design;
            }
            catch (err) {
                console.error(err);
                throw new Error(`get design has thrown an error for design type ${designTypeId}`)
            }
        }

        throw new Error(`get design not supported for design type ${designTypeId}`)
    }

    public trackProjectById: TrackByFunction<IDisplayProject> = (_: number, project: IDisplayProject) => {
        return project.id;
    }

    public trackSubprojectById: TrackByFunction<IDisplayProject> = (_: number, subproject: IDisplayProject) => {
        return subproject.id;
    }

    public trackApplicationById: TrackByFunction<CodeList> = (_: number, application: CodeList) => {
        return application.id;
    }

    public trackGroupByDesignType: TrackByFunction<IGroupedDisplayDesign> = (_: number, group: IGroupedDisplayDesign) => {
        return group.designType;
    }

    public trackDesignTypeById: TrackByFunction<IDisplayDesign> = (_: number, designType: IDisplayDesign) => {
        return designType.id;
    }

    public getSortDesignsDropdownItems() {
        return this.sortDesignsDropdown.items;
    }

    public designDropConfigForProject(project: IDisplayProject): boolean {
        return project.readOnly ? true : false;
    }

    public openInBrowser() {

        const selectedRegion = this.getRegionById(this.userSettingsService.settings.application.general.regionId.value);
        const hiltiOnlineUrl = selectedRegion.hiltiOnlineUrl != null ? selectedRegion.hiltiOnlineUrl : this.getRegionById(KnownRegion.UnitedStates).hiltiOnlineUrl;

        this.offlineService.nativeExternalLinkOpen(hiltiOnlineUrl);
    }

    public shareProjectOpen(project: IDisplayProject) {
        if (this.isShareProjectHidden() || this.isShareProjectDisabled()) {
            return;
        }

        const selectedProject = this.document.findProjectById(project.id);
        selectedProject.loadUsers(this.document)
            .then(() => this.modal.openShareProject({
                selectedProject,
                onRemoveProject: () => {
                    this.document.deleteProjectLocal(this.selectedProject.id);

                    const selectedProject = Object.values(this.document.projects).find((project) => project.projectType == ProjectType.draft);
                    this.selectedProject = this.toDisplayProject(selectedProject);
                }
            }));
    }

    public isShareProjectHidden() {
        return this.featuresVisibilityInfo.isHidden(Feature.Application_FileSharing, this.globalRegion.id);
    }

    public isShareProjectDisabled() {
        return this.featuresVisibilityInfo.isDisabled(Feature.Application_FileSharing, this.globalRegion.id);
    }

    public shareProjectTooltip() {
        return this.featuresVisibilityInfo.tooltip(Feature.Application_FileSharing);
    }

    public archivePopupOpen() {
        const onRestore = this.refreshProjectsAndDesigns.bind(this, false);
        this.modal.openArchive(onRestore);
    }

    public scrollToItem(item: IDisplayProject) {
        if (this.projects.length == 0) {
            return;
        }

        const selectedIndex = this.projects.findIndex(project => this.selectedProject.id == project.id);
        const previousIndex = selectedIndex > 0 ? selectedIndex - 1 : 0;

        const gridBodyElement = document.querySelector(`.project-and-design-view .content .content-control .content-control-body .navigation .projects-container .container-content .projects`);

        if (gridBodyElement != null) {
            gridBodyElement.scrollTop = 36 * previousIndex;
        }
    }

    public filterProjects() {
        for (const project of this.projects) {
            this.projectFilterResult[project.id] = this.doesProjectMatchSearchFilter(project);

            for (const subproject of project.subProjects) {
                this.projectFilterResult[subproject.id] = this.doesSubprojectMatchSearchFilter(project, subproject);
            }
        }
    }

    public getNotificationCount(type: NotificationType) {
        if (this.notifications == null) {
            return 0;
        }

        return this.notifications.filter((item) => isEqual(item.type, type)).length;
    }

    public newDesignClick(designTypeId?: number, addEditType: AddEditType = AddEditType.add, regionId?: number, connectionType?: number) {
        if (this.importing || this.pendingAction != null) {
            return;
        }

        const selectedDefaultRegion = regionId != null ? regionId : this.userSettings.settings.application.general.regionId.value;
        const region = this.regions[selectedDefaultRegion];

        const designType = designTypeId ?? this.modulesService.designTypes.length > 0 ? this.modulesService.designTypes[0].id : undefined;
        // this code should moved to specific module with modularization
        const designInfo = this.modulesService.getDesignInfoForDesignType(designType, selectedDefaultRegion, connectionType);

        this.modal.openAddEditDesignFromModule({
            selectedModuleDesignInfo: designInfo,
            addEditType: addEditType,
            design: {
                designType: designType,
                region: region,
                projectId: this.selectedProject.id,
                projectName: this.selectedProject.name
            }
        });
    }

    public async copyDesignClick(displayDesign: IDisplayDesign | IDetailedDisplayDesignCommon) {
        if (this.pendingAction != null) {
            return;
        }

        this.pendingAction = PendingAction.copyDesign;
        this.pendingActionDesign = ({ designId: displayDesign.id, projectId: displayDesign.projectId } as IProjectAndDesignId);

        if (displayDesign.designType === DesignTypeId.DiaphragmDesign) {
            displayDesign = this.createModalDesign(displayDesign);
        }

        this.modal.openCopyDesign({
            design: displayDesign,
            onDesignCopied: (project: Project) => {
                this.updateProjectToDisplay(project);
                return true;
            },
            onOffline: (name: string, designId: string) => this.modulesService.downloadDesign(name, designId, displayDesign.designType)
        }).closed
            .finally(() => {
                this.refreshProjectsAndDesigns();
            });

        this.clearPendingAction();
    }

    public newProject() {
        this.showEditProject(EditProjectType.new);
    }

    public newCompanyProject() {
        if (!this.companyProjectDisabled) {
            this.showEditProject(EditProjectType.newCompanyProject);
        }
    }

    public companyProjectVisible(project: IDisplayProject) {
        return this.addNewCompanyProjectVisible && !project.isCompanyProject;
    }

    public editProjectInputBlur() {
        if (!this._isModalOpen) {
            setTimeout(() => {
                const activeElement = document.activeElement as HTMLElement;

                if (!activeElement.classList.contains('new-project') && activeElement.closest('.new-project') == null) {
                    this.editProjectConfirm();
                }
            });
        }
    }

    public renameProjectInputBlur() {
        if (!this._isModalOpen) {
            setTimeout(() => {
                const activeElement = document.activeElement;

                if (activeElement != null && activeElement != undefined && activeElement.id !== 'project-and-design-navigation-rename-project-ok-button') {
                    this.renameProjectReject();
                }
            });
        }
    }

    public getOverViewTranslation(translationKey: string, countId: number) {
        const countValue = this.overviewItemCount[countId];
        return format(this.localization.getString(translationKey) + ' ({0})', [countValue]);
    }

    public editProjectConfirm() {
        if (this.editProjectType == EditProjectType.none || this.pendingAction != null) {
            return;
        }

        switch (this.editProjectType) {
            case EditProjectType.newCompanyProject:
            case EditProjectType.new:
                this.editProjectNew();

                break;
            case EditProjectType.newCompanySub:
            case EditProjectType.newSub:
                this.editProjectNewSub();

                break;
            case EditProjectType.rename:
                this.editProjectRename();

                break;
            default:
                throw new Error('Unknown EditProjectType.');
        }
    }

    public renameProjectConfirm() {
        if (this.editProjectName == null ||
            this.editProjectName.trim() == '' ||
            this.selectedRenameProject.rawProject.getDisplayName(this.localization) == this.editProjectName) {
            this.renameProjectReject();
        }
        else if (this.selectedRenameProject != null &&
            this.document.projectNameExists(
                this.editProjectName.trim(),
                this.selectedRenameProject.parentId)) {

            this.onServiceErrorHandler.showExistingProjectNameModal();
        }
        else {
            const projectLevelType = this.selectedRenameProject.parentId ? ModalDialogType.subproject : ModalDialogType.project;
            const project = this.document.findProjectById(this.selectedRenameProject.id);
            const prevProjName = project.name;

            project.name = this.editProjectName;

            // call document service
            this.pendingAction = PendingAction.renameProject;
            this.document.saveProject(project, projectLevelType)
                .then(x => {
                    // refreshing off all parts where designs are stored
                    if (project.id == this.selectedProject.id) {
                        this.selectedProject = this.toDisplayProject(project);
                    }
                    else { // optimization: selectedProject watch already performs refresh
                        this.refreshProjectsAndDesigns();
                    }
                    this.scrollToItem(this.selectedProject);
                })
                .catch((err) => {
                    if (err instanceof Error) {
                        console.error(err);
                    }

                    project.name = prevProjName;
                })
                .finally(() => {
                    this.renameProjectType = {};
                    this.closeNewProject();
                    this.pendingAction = null;
                });
        }
    }

    public editProjectReject() {
        this.closeNewProject();
    }

    public renameProjectReject() {
        if (this.selectedRenameProject != null) {
            this.renameProjectType[this.selectedRenameProject.id] = EditProjectType.none;
        }
        this.closeNewProject();
    }

    public newSubProject(project: IDisplayProject) {
        this.newProjectParent = project;

        this.showEditProject(EditProjectType.newSub);
    }

    public newCompanySubProject(project: IDisplayProject) {
        if (!this.companyProjectDisabled) {
            this.newProjectParent = project;
            this.showEditProject(EditProjectType.newCompanySub);
        }
    }

    public renameProject(project: IDisplayProject) {
        this.selectedRenameProject = project;

        this.showEditProject(EditProjectType.rename, project.rawProject.getDisplayName(this.localization));
    }

    public archiveProject(project: IDisplayProject) {
        const deletedProjectId = project.id;

        const pCandidate = this.document.findProjectById(deletedProjectId);

        this.pendingAction = PendingAction.archiveProject;
        this.pendingActionProject = project.rawProject;

        this.document.archiveProject(deletedProjectId)
            .finally(() => {
                this.clearPendingAction();
            })
            .then(() => {
                if (pCandidate.subProjects && pCandidate.subProjects != null &&
                    (pCandidate.subProjects == null || Object.keys(pCandidate.subProjects).length == 0)) {
                    for (const p in pCandidate.subProjects) {
                        this.projects = this.projects.filter((it) => it.id != pCandidate.subProjects[p].id);
                    }
                }

                this.projects = this.projects.filter((it) => it.id != deletedProjectId);

                if (!this.offlineService.isOffline) {
                    if (deletedProjectId == this.selectedProject.id) {
                        this.designView = DesignView.allDesigns;
                    }
                    else { // optimization: selectedProject watch already performs refresh
                        this.refreshProjectsAndDesigns();
                    }
                }
            })
            .catch((e) => {
                if (e instanceof Error) {
                    console.error(e);
                }

                if (e instanceof CantArchiveProjectsBecauseDocumentInUse) {
                    const cantEx: CantArchiveProjectsBecauseDocumentInUse = e as CantArchiveProjectsBecauseDocumentInUse;
                    const descriptionForDocument = this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotRemoveProjectWithLockedDocuments.DescriptionForSpecificDocument');
                    let description = this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotRemoveProjectWithLockedDocuments.Description');
                    description += '\n';
                    let docCount = 0;
                    for (const doc of cantEx.documentsInQuestion) {
                        docCount++;
                        if (docCount > 5) {
                            description += '\n\t\t' + this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotRemoveProjectWithLockedDocuments.DescriptionForTooMuchDocuments');
                            break;
                        }
                        description += '\n\t\t' + descriptionForDocument.replace('{document}', doc.document).replace('{user}', doc.username);
                    }
                    description += '\n';

                    this.modal.openAlertWarning(
                        this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotRemoveProjectWithLockedDocuments.Title'),
                        description
                    );
                }
            });
    }

    public downloadProject(project: Project) {
        this.pendingAction = PendingAction.downloadProject;
        this.pendingActionProject = project;

        this.exportService.getProjectZip(project.id)
            .then(blob => {
                this.browser.downloadBlob(blob, project.name + '.zip', false, false);
            })
            .finally(() => {
                this.clearPendingAction();
            });
    }

    public openDeleteProjectDialog() {
        this.modal.openConfirmChange({
            id: 'delete-project-popup',
            title: this.translate('Agito.Hilti.Profis3.DeleteProject.Title'),
            message: this.translate('Agito.Hilti.Profis3.DeleteProject.Content'),
            confirmButtonText: this.translate('Agito.Hilti.Profis3.Delete'),
            cancelButtonText: this.translate('Agito.Hilti.Profis3.Cancel'),
            onConfirm: (modal) => {
                this.document.removeProject(this.user.project.id)
                    .then(() => {
                        this.designView = DesignView.allDesigns;
                        this.refreshProjectsAndDesigns();
                    });
                modal.close(true);
            },
            onCancel: (modal) => {
                modal.close(false);
            }
        });
    }

    public openConvertProjectDialog() {
        if (this.companyProjectDisabled) {
            return;
        }

        this.modal.openConfirmChange({
            id: 'delete-project-popup',
            title: this.translate('Agito.Hilti.Profis3.ConvertProject.Title'),
            message: this.translate('Agito.Hilti.Profis3.ConvertProject.Content'),
            confirmButtonText: this.translate('Agito.Hilti.Profis3.Convert'),
            cancelButtonText: this.translate('Agito.Hilti.Profis3.Cancel'),
            onConfirm: (modal) => {
                this.document.convertProject(this.user.project.id)
                    .then(() => {
                        this.refreshProjectsAndDesigns();
                    });
                modal.close(true);
            },
            onCancel: (modal) => {
                modal.close(false);
            }
        });
    }

    public downloadAllDesigns() {
        this.pendingAction = PendingAction.downloadAllDesigns;
        this.exportService.getAllProjectsZip()
            .then(blob => {
                this.browser.downloadBlob(blob, this.translate('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Designs.AllDesigns') + '.zip', false, false);
            })
            .finally(() => {
                this.pendingAction = null;
            });
    }

    public emptyProject(project: Project | IDisplayProject) {
        return project.designs == null || Object.keys(project.designs).length == 0;
    }

    public newDesignFromDesignTypeId(designTypeId: number) {
        const quickStartApplication = sortBy(this.modulesService.getQuickStartApplications(), p => p.order).find(x => x.designType == designTypeId);
        if (quickStartApplication != null) {
            this.newDesignFromQuickStart(quickStartApplication);
        }
    }

    public newDesignFromQuickStart(quickStartApplication: IQuickStartApplication) {
        if (SafeFunctionInvokerHelper.safeInvoke(quickStartApplication.isDesignTypeDisabled, true)) {
            return;
        }

        // close any tour if it's open
        this.tour.onNewDesignFromQuickStart();

        return this.newDesign(quickStartApplication);
    }

    public newDesign(quickStartApplication: IQuickStartApplication, integrationDocument?: DocumentModel) {
        if (this.pendingAction != null) {
            return;
        }

        // For designs created from integration documents (RISA, RAM) a Drafts project needs to be selected
        // as defined in BUDQBP-9667
        const projectId = (!integrationDocument) ? this.selectedProject.id : this.document.draftsProject.id;
        const project = this.document.findProjectById(projectId);

        let name: string;
        if (quickStartApplication?.getNewDesignName != null) {
            try {
                name = quickStartApplication.getNewDesignName();
            }
            catch (err) {
                console.error(err);
                this.modal.openAlertWarning(
                    this.translate('Agito.Hilti.Profis3.ProjectAndDesign.Alerts.CannotCreateNewDesign.Title'),
                    this.translate('Agito.Hilti.Profis3.ProjectAndDesign.Alerts.CannotCreateNewDesign.Description')
                );
                return;
            }
        }

        this.handleProjectDesignName(name, project, integrationDocument)
            .then(async (newName) => {
                if (newName == null) {
                    return;
                }

                name = newName;

                if (project != null) {
                    const projectForName = project.parentId != null
                        ? this.document.findProjectById(project.parentId)
                        : project;
                    name = this.document.createUniqueName(name, Object.values(projectForName.designs).map((item) => item.designName));
                }

                // Modularization: implement newDesignFromModuleQuickStart function for PE and C2C module
                if (quickStartApplication?.createNewDesign != null) {
                    await this.newDesignFromModuleQuickStart(quickStartApplication, name, project, integrationDocument);
                }
            });
    }

    private async newDesignFromModuleQuickStart(quickStartApplication: IQuickStartApplication, designName: string, project: Project, integrationDocument: DocumentModel) {
        this.pendingAction = PendingAction.createQuickStartDesign;
        this.pendingActionQuickStart = quickStartApplication.idQuickStart;

        try {
            const saveResultData = await quickStartApplication.createNewDesign({ designName, projectName: project.name, projectId: project.id, integrationDocument });
            this.user.changeDesign(project, saveResultData.design as Design);
            this.routingService.navigateToUrl(`${saveResultData.path}${saveResultData.designId}`);
        }
        catch {
            /* Nothing to do. */
        }
        finally {
            this.pendingAction = null;
            this.pendingActionQuickStart = undefined;
        }
    }

    public showLoading(quickStartApp: IQuickStartApplication) {
        return this.pendingAction == PendingAction.createQuickStartDesign && this.pendingActionQuickStart && this.pendingActionQuickStart == quickStartApp.idQuickStart;
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public startTour() {
        this.tour.openNavigationTour(this.newDesignFromDesignTypeId, this.showVirtualTour);
    }

    public downloadLicenceOffline() {
        const openUrl = `${environment.baseUrl}Content/Pdfs/PROFIS_licenses.pdf`;
        this.offlineService.nativeLocalPathOpen(openUrl, 'PROFIS_licenses.pdf', true, true);
    }

    public openReportTemplates() {
        this.modal.openReportTemplates();
    }

    public openELearning() {
        this.userSettingsService.settings.elearnDoceboSeen.value = true;
        this.userSettingsService.save();

        this.leftNavigation.find(item => item.id == LeftNavigationItems.learning).showRedDot = false;

        const betLearnLink = this.productInformation.regionLinksForUser(this.userSettingsService.settings.application.general.regionId.value).BetLearningLink;
        const region = this.getRegionById(this.userSettingsService.settings.application.general.regionId.value);

        this.offlineService.nativeExternalLinkOpen(betLearnLink);
        this.commonTrackingService.trackOnButtonClick(ButtonEventType.ELearnClicked, region.displayKey);
    }

    public openApplicationSettings() {
        this.modal.openApplicationSettings();
    }

    public onDesignImporting() {
        return this.document.findProjectById(this.selectedProject.id);
    }

    public onDesignImported($event: { project: Project; design: Design; renameFile?: boolean; }) {
        this.user.changeDesign($event.project, $event.design);
        this.user.renameFile = $event.renameFile;
        this.navigate(urlPath.main + $event.design.id);
        return true;
    }

    public hasChildProject(project: IDisplayProject) {
        return some(project.subProjects);
    }

    public setSelectedProject(project: IDisplayProject) {
        if (!project.readOnly) {
            this.selectedProject = project;
        }
    }

    public toggleExpandProject(displayProject: IDisplayProject) {
        const project = this.document.findProjectById(displayProject.id);
        const wasExpanded = displayProject.expanded;

        this.document.toggleExpandProject(project)
            .then(() => {
                // if we collapse read only project, then all designs are selected
                if (project.readOnly && wasExpanded) {
                    this.designView = DesignView.allDesigns;
                }
                this.refreshProjects();
            });
    }

    public async openDesign(displayDesign: IDisplayDesign, openDesignType: OpenDesignType) {
        if (this.pendingAction != null) {
            return;
        }

        this.pendingAction = PendingAction.openDesign;
        this.pendingActionDesign = ({ designId: displayDesign.id, projectId: displayDesign.projectId } as IProjectAndDesignId);

        const documentDesign = this.document.findDesignById(displayDesign.id);

        switch (openDesignType) {
            case OpenDesignType.threeDee: {
                await this.openDesignThreeDee(documentDesign);
                break;
            }
            case OpenDesignType.settings: {
                await this.openDesignSettings(documentDesign);
                break;
            }
        }
    }

    private async openDesignThreeDee(documentDesign: IDesignListItem) {
        const designsInfo = this.modulesService.getDesignListInfoByDesignType(documentDesign.metaData.designType);
        if (designsInfo?.openFromDocumentDesign != null) {
            await designsInfo.openFromDocumentDesign(documentDesign).catch((response) => {
                if (response instanceof CantOpenDesignBecauseLockedByOtherUser) {
                    const lockedEx: CantOpenDesignBecauseLockedByOtherUser = response as CantOpenDesignBecauseLockedByOtherUser;
                    this.modal.openDocumentInUseWarning(lockedEx.username);
                }
                this.clearPendingAction();
                throw response;
            }).finally(() => this.clearPendingAction());
        }
        else {
            throw Error(`Open design not implemented for design type ${documentDesign.metaData.designType}`);
        }
    }

    private async openDesignSettings(documentDesign: IDesignListItem) {
        const designsInfo = this.modulesService.getDesignListInfoByDesignType(documentDesign.metaData.designType);

        if (designsInfo?.openDesignSettings) {
            try {
                await designsInfo.openDesignSettings(documentDesign.id, documentDesign.designName, documentDesign.metaData.region, this.refreshProjectsAndDesigns.bind(this))
                    .finally(() => this.clearPendingAction());
            }
            catch (err) {
                console.error(err);
                this.clearPendingAction()
            }
        }
        else {
            throw Error(`Open design settings not implemented for design type ${documentDesign.metaData.designType}`);
        }
    }

    public async openTemplate(displayDesign: IDisplayDesign, openDesignType: OpenDesignType) {
        if (this.pendingAction != null) {
            return;
        }

        this.pendingAction = PendingAction.loadTemplate;
        this.pendingActionDesign = ({ designId: displayDesign.id, projectId: displayDesign.projectId } as IProjectAndDesignId);
        const designsInfo = this.modulesService.getDesignListInfoByDesignType(displayDesign.designType);
        if (designsInfo) {
            switch (openDesignType) {
                case OpenDesignType.settings:
                    try {
                        await designsInfo.openTemplateSettings(displayDesign.id, this.refreshProjectsAndDesigns.bind(this))
                            .finally(() => this.clearPendingAction());
                    }
                    catch (err) {
                        console.error(err);
                        this.clearPendingAction();
                    }
                    break;
                case OpenDesignType.threeDee:
                    try {
                        await designsInfo.openTemplate(displayDesign.id)
                            .finally(() => this.clearPendingAction());
                    }
                    catch (err) {
                        console.error(err);
                        this.clearPendingAction();
                    }
                    break;

                default: throw new Error(`Unsupported action OpenDesignType: ${openDesignType}`);
            }
        }
    }

    public async newDesignFromTemplate(displayDesign: IDisplayDesign) {
        if (this.pendingAction != null) {
            return;
        }

        const designsInfo = this.modulesService.getDesignListInfoByDesignType(displayDesign.designType);
        if (designsInfo?.newDesignFromTemplate != null) {
            this.pendingAction = PendingAction.createDesign;
            this.pendingActionDesign = ({ designId: displayDesign.id, projectId: displayDesign.projectId } as IProjectAndDesignId);
            try {
                await designsInfo.newDesignFromTemplate(displayDesign.id)
                    .finally(() => this.clearPendingAction());
            }
            catch (err) {
                console.error(err);
                this.clearPendingAction();
            }
        }
    }

    public deleteTemplate(displayDesign: IDisplayDesign) {
        if (this.pendingAction != null) {
            return;
        }

        this.modal.openConfirmChange({
            id: 'confirm-delete-template',
            title: this.translate('Agito.Hilti.Profis3.ProjectAndDesign.ConfirmDeleteTemplate.Title'),
            message: this.translate('Agito.Hilti.Profis3.ProjectAndDesign.ConfirmDeleteTemplate.Message'),
            confirmButtonText: this.translate('Agito.Hilti.Profis3.ProjectAndDesign.ConfirmDeleteTemplate.Confirm'),
            cancelButtonText: this.translate('Agito.Hilti.Profis3.ProjectAndDesign.ConfirmDeleteTemplate.Cancel'),
            onConfirm: (modal) => {
                this.pendingAction = PendingAction.deleteTemplate;
                this.pendingActionDesign = ({ designId: displayDesign.id, projectId: displayDesign.projectId } as IProjectAndDesignId);

                this.designTemplate.delete(displayDesign.id)
                    .finally(() => {
                        this.clearPendingAction();
                    })
                    .then((_) => {
                        this.refreshProjectsAndDesigns();
                    });

                if (this.deckingEnabled && displayDesign.designType === DesignTypeId.DiaphragmDesign) {
                    this.deleteDeckingDesignTemplate(displayDesign.id);
                }

                modal.close();
            },
            onCancel: (modal) => {
                modal.close();
            }
        });
    }

    private async deleteDeckingDesignTemplate(templateId: string) {
        const template = await this.designTemplate.getById(templateId);
        const designId = JSON.parse(template.ProjectDesign).designId;
        const designsInfo = this.modulesService.getDesignListInfoByDesignType(DesignTypeId.DiaphragmDesign) as IDeckingDesignListInfo;
        if (designsInfo)
            await designsInfo.deleteDesign(designId, true);
    }

    public deleteDesign(displayDesign: IDisplayDesign) {
        if (this.pendingAction != null) {
            return;
        }

        this.modal.openConfirmChange({
            id: 'confirm-delete-design',
            title: this.translate('Agito.Hilti.Profis3.ProjectAndDesign.ConfirmDeleteDesign.Title'),
            message: this.translate('Agito.Hilti.Profis3.ProjectAndDesign.ConfirmDeleteDesign.Message'),
            confirmButtonText: this.translate('Agito.Hilti.Profis3.ProjectAndDesign.ConfirmDeleteDesign.Confirm'),
            cancelButtonText: this.translate('Agito.Hilti.Profis3.ProjectAndDesign.ConfirmDeleteDesign.Cancel'),
            onConfirm: async (modal) => {
                this.confirmDeleteDesign(displayDesign.id, displayDesign.projectId, displayDesign.designType);
                modal.close();
            },
            onCancel: (modal) => {
                modal.close();
            }
        });
    }

    public displayDesignClick(displayDesign: IDisplayDesign) {
        if (displayDesign.rawProject != null) {
            switch (displayDesign.rawProject.projectType) {
                case ProjectType.common: this.user.projectAndDesignView = DesignView.projects; break;
                case ProjectType.draft: this.user.projectAndDesignView = DesignView.drafts; break;
                case ProjectType.template: this.user.projectAndDesignView = DesignView.templates; break;
            }
        }

        switch (displayDesign.displayDesignType) {
            case DisplayDesignType.design:
                this.openDesign(displayDesign, OpenDesignType.threeDee);
                break;
            case DisplayDesignType.template:
                this.newDesignFromTemplate(displayDesign);
                break;
        }
    }

    public isDesignLoading(design: IDisplayDesign) {
        const loading = this.pendingAction == PendingAction.openDesign && this.pendingActionDesign.designId == design.id
            || this.pendingAction == PendingAction.copyDesign && this.pendingActionDesign.designId == design.id
            || this.pendingAction == PendingAction.renameDesign && this.pendingActionDesign.designId == design.id
            || this.pendingAction == PendingAction.loadTemplate && this.pendingActionDesign.designId == design.id
            || this.pendingAction == PendingAction.deleteTemplate && this.pendingActionDesign.designId == design.id
            || this.pendingAction == PendingAction.createDesign && this.pendingActionDesign.designId == design.id
            || this.pendingAction == PendingAction.assignProject && this.pendingActionDesign.designId == design.id
            || this.pendingAction == PendingAction.deleteDesign && this.pendingActionDesign.designId == design.id;
        return loading;
    }

    public isProjectLoading(projectId: string) {
        const loading = this.pendingAction == PendingAction.archiveProject && this.pendingActionProject.id == projectId
            || this.pendingAction == PendingAction.downloadProject && this.pendingActionProject.id == projectId;
        return loading;
    }

    public isAllDesignsLoading() {
        const loading = this.pendingAction == PendingAction.downloadAllDesigns;
        return loading;
    }

    public showMoreDesigns(designType: number) {
        designType = designType || DesignTypeId.Unknown;
        this.groupedDesignsLimit[designType] += this.groupedDesignsLimitStep;
        this.refreshProjectsAndDesigns(false);

        const maxHeight = parseFloat(getComputedStyle(document.querySelector('.design-type-designs')).maxHeight.slice(0, -2));
        const maxHeightStyle = maxHeight * 2 + 'px';
        document.querySelectorAll('.design-type-designs').forEach(e => {
            (e as HTMLElement).style.maxHeight = maxHeightStyle;
        });
    }

    public displayShowMoreDesigns(group: IGroupedDisplayDesign) {
        return group.designsCount > group.designs.length;
    }

    public openQuickStartSettings() {
        this.modal.openApplicationSettings(ApplicationSettingsDisplayType.quickStartConcrete);
    }

    public templatesClick() {
        if (this.templatesDisabled || this.templatesHidden) {
            return;
        }

        this.designView = DesignView.templates;
    }

    public async setInAppMarketingCampaign() {
        this.inAppCampaigns = this.productInformation.getInAppCampaigns();
        if (this.userSettings.settings.application.general.isNewUser.value) {
            this.inAppCampaigns.push(...this.productInformation.getInAppCampaignsForNewUser());
            this.userSettings.settings.application.general.isNewUser.value = false;
            this.userSettings.save();
        }

        const trackingPromises: Promise<void>[] = [];
        this.inAppCampaigns?.forEach(x => {
            trackingPromises.push(this.commonTrackingService.trackPopupShown(x.CampaignType, this.campaignName(x)));
        });

        await Promise.all(trackingPromises);
    }

    public trackInAppCampaignClick(campaign: RegionMarketingCampaign) {
        this.commonTrackingService.trackPopupLinkClicked(campaign?.CampaignType, this.campaignName(campaign), campaign?.Link);
    }

    public campaignName(campaign: RegionMarketingCampaign): string {
        return `Marketing Campaign ${campaign.MarketingCampaignId}`;
    }

    public openHiltiApplication(id: number) {
        const app = this.externalAppsService.getHiltiApplication(id);
        const url = this.externalAppsService.getHiltiApplicationLink(app) ?? '';
        if (stringNullOrEmpty(url)) {
            return;
        }

        this.trackHiltiApplicationClicks(app.TrackingId);
        this.offlineService.nativeExternalLinkOpen(url);
    }

    public openDesignGuide(link: string) {
        this.offlineService.nativeExternalLinkOpen(link);
    }

    public openWoodLinks(link: string) {
        this.offlineService.nativeExternalLinkOpen(link);
        this.commonTrackingService.trackUsageFromUI('Wood');
    }

    public openExternalAppLink(id: number) {
        const app = this.externalAppsService.getExternalApp(id);
        const url = this.externalAppsService.getExternalAppLink(app) ?? '';
        if (stringNullOrEmpty(url)) {
            return;
        }

        this.commonTrackingService.trackUsageFromUI(app.TrackingId);
        this.offlineService.nativeExternalLinkOpen(url);
    }

    public resize() {
        if (this.designGroup == DesignGroup.none) {
            this.setGroupedDesignsDisplayedElements();
        }
    }

    public openKbLink(event: MouseEvent, link?: string): void {
        event.stopPropagation();
        window.open(link, '_blank');
    }

    public openNewDesignFromIntegrationDocument(document: DocumentModel, documentType: DocumentIntegrationType) {
        const quickStartApplication = this.modulesService.getQuickStartApplicationForIntegration(documentType);
        if (quickStartApplication != null) {
            this.newDesign(quickStartApplication, document);
        }
    }

    public projectMenuToggled(opend: boolean) {
        const projectsScroll = document.querySelector('.projects-scroll') as HTMLElement;

        if (opend) {
            // Disable scroll
            const currentPosition = projectsScroll.scrollTop;
            projectsScroll.onwheel = (e) => { e.preventDefault(); };
            projectsScroll.onscroll = () => { projectsScroll.scrollTop = currentPosition; };
        }
        else {
            // Enable scroll
            projectsScroll.onscroll = () => undefined;
            projectsScroll.onwheel = () => undefined;
        }
    }

    public dropdownFromBodyDirection(projectId: string) {
        const projectsScroll = document.querySelector('.projects-scroll');
        const scrollPosition = document.querySelector('.projects-scroll')?.parentElement.offsetTop;
        const projectListItem = document.getElementById('project-and-design-navigation-project-' + projectId);
        const dropdownItem = document.getElementById('dropdown-direction-' + projectId);

        if (scrollPosition == null || dropdownItem == null || projectListItem == null) {
            return;
        }

        // we have to put top on 0 otherwise it wont calculate document.height correctly
        dropdownItem.style.top = '0';
        let topPosition = scrollPosition + projectListItem.offsetTop + projectListItem.clientHeight - projectsScroll.scrollTop;

        // if dropdown-menu is below screen
        if (document.body.clientHeight < topPosition + dropdownItem.clientHeight) {
            topPosition = topPosition - dropdownItem.clientHeight - projectListItem.clientHeight - 4;
        }

        const dropdownButtonRect = document.getElementById(`project-and-design-navigation-project-${projectId}-dropdown-button`).getBoundingClientRect();
        dropdownItem.style.top = `${topPosition}px`;
        dropdownItem.style.left = `${dropdownButtonRect.left}px`;
        dropdownItem.style.position = 'fixed';
    }

    public setDropdownDirection(designId: string, displayDesignType: DisplayDesignType) {
        this.dropdownDesignDirection(designId, displayDesignType == DisplayDesignType.template);
    }

    public dropdownDesignDirection(designId: string, isTemplate: boolean) {
        const designListItem = document.getElementById('project-and-design-main-design-' + designId);
        const designDropdown = isTemplate
            ? document.getElementById('project-and-design-main-template-dropdown-' + designId)
            : document.getElementById('project-and-design-main-dropdown-' + designId);
        const dropDownMenuWidth = document.querySelector('.dropdown-menu')?.clientWidth;
        let addLeftClass = false;

        if (designListItem == null || designDropdown == null || dropDownMenuWidth == null) {
            return;
        }

        const designListItemBoundingClientRect = designListItem.getBoundingClientRect();
        const designListItemLeft = designListItemBoundingClientRect.left + window.pageXOffset;

        const designListItemParentParent = designListItem.parentElement.parentElement;
        const designListItemParentParentBoundingClientRect = designListItemParentParent.getBoundingClientRect();
        const designListItemParentParentLeft = designListItemParentParentBoundingClientRect.left + window.pageXOffset;

        if (designListItemLeft + designListItem.clientWidth + dropDownMenuWidth >
            designListItemParentParent.clientWidth + designListItemParentParentLeft) {
            addLeftClass = true;
        }

        designDropdown.classList.toggle('dropup-menu-design-flip-left', addLeftClass);
    }

    public onDesignSortChange(newVal: DesignSort, oldVal: DesignSort) {
        this.designSort = newVal;
        this.refreshProjectsAndDesigns();
    }

    public searchInputBlur() {
        if (this.designSearchValue != null) {
            this.designSearchValue = this.designSearchValue.trim();
        }
    }

    public createRegionDesignStandardApprovalNumber(region: CommonRegion, designStandard: DesignStandard | DesignStandardC2C, approvalNumber: string, designTypeId?: number) {
        if (region != null && region.id == SpecialRegion.Default) {
            region = this.getRegionById(this.userSettingsService.settings.application.general.regionId.value);
        }

        const designType = designTypeId != null ? this.designTypes[designTypeId] : null;
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
        const text = [
            region != null ? region.getTranslatedNameText(codeListDeps) : null,
            designStandard != null ? this.localization.getString(this.getDesignTypeSpecificKey(designStandard.nameResourceKey, designType)) : null,
            approvalNumber
        ].filter((value) => value != null && value != '').join(', ');

        if (text == null || text == '') {
            return this.translate('Agito.Hilti.Profis3.ProjectAndDesing.Main.DesignMetaData.Unknown');
        }

        return text;
    }

    public getDesignNameProjectName(design: IDisplayDesign): string {
        if (design.rawProject != null) {
            return `${design.name}, ${design.rawProject.getDisplayName(this.localization)}`;
        }

        return `${design.name}, ${design.projectName}`;
    }

    public getProjectName(design: IDisplayDesign): string {
        if (design.rawProject != null) {
            return design.rawProject.getDisplayName(this.localization);
        }

        return design.projectName;
    }

    public onSectionCollapsedChange(section: ISectionCollapsed, collapsed: boolean) {
        section.collapsed = collapsed;
        this.userSettingsService.setSectionCollapsed(section.control, collapsed);
    }

    public onDropdownOpenChanged(dropdownId: string, open: boolean) {
        if (open) {
            this.displayedDropdown = dropdownId;
        }
        else if (this.displayedDropdown === dropdownId) {
            this.displayedDropdown = null;
        }
    }

    private trackHiltiApplicationClicks(trackingId: string) {
        //TODO BUDQBP-33462: implement tracking for Hilti applications based on application's TrackingId
        this.commonTrackingService.trackOnButtonClick(ButtonEventType.PeDesktopClicked);
    }

    private createModalDesign(displayDesign: IDisplayDesign | IDetailedDisplayDesignCommon): IDetailedDisplayDesignCommon {
        return {
            id: displayDesign.id,
            name: displayDesign.name,
            projectId: displayDesign.projectId,
            projectName: displayDesign.projectName,
            region: null,
            designType: displayDesign.designType,
            displayDesignType: DisplayDesignType.design,
            unitLength: null,
            unitLengthLarge: null,
            unitArea: null,
            unitStress: null,
            unitStressSmall: null,
            unitForce: null,
            unitMoment: null,
            unitTemperature: null,
            unitForcePerLength: null,
            unitMomentPerLength: null,
            unitDensity: null,
            unitAreaPerLength: null
        };
    }

    private clearPendingAction(): void {
        this.pendingAction = null;
        this.pendingActionDesign = null;
    }

    private updateProjectToDisplay(project: Project): void {
        this.selectedProject = this.toDisplayProject(project);
        this.projectFilterResult[project.id] = true;
    }

    private doesProjectMatchSearchFilter(project: IDisplayProject) {
        if (this.projectFilterString) {
            return this.projectSearchMatchPrimitive(project.rawProject.name) || project.subProjects.some(subProject => this.projectSearchMatchPrimitive(subProject.rawProject.name));
        }
        else {
            return true;
        }
    }

    private doesSubprojectMatchSearchFilter(project: IDisplayProject, subproject: IDisplayProject) {
        if (this.projectFilterString) {
            return this.projectSearchMatchPrimitive(project.rawProject.name) || this.projectSearchMatchPrimitive(subproject.rawProject.name);
        }
        else {
            return true;
        }
    }

    private projectSearchMatchPrimitive(name: string) {
        return name.trim().toLowerCase().indexOf(this.projectFilterString.trim().toLowerCase()) > -1;
    }

    private async handleProjectDesignName(name: string, project: Project, integrationDocument: DocumentModel): Promise<string> {
        if (integrationDocument && integrationDocument.IntegrationType == DocumentIntegrationType.Risa) {
            name = integrationDocument.DocumentName;

            if (Object.values(project.designs).some(x => x.designName == integrationDocument.DocumentName)) {
                await this.modal.openConfirmChange({
                    id: 'change-risa-design-name',
                    title: this.localization.getString('Agito.Hilti.Profis3.Integrations.DesignNameExists.Title'),
                    message: format(this.localization.getString('Agito.Hilti.Profis3.Integrations.DesignNameExists.Message'), integrationDocument.DocumentName),
                    confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Integrations.DesignNameExists.Button.Content.ReplaceExisting'),
                    cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.Integrations.DesignNameExists.Button.Content.KeepBoth'),
                    onConfirm: async (modal) => {
                        // on replace existing design
                        const previousDesign = Object.values(project.designs).filter(x => x.designName == integrationDocument.DocumentName)[0];

                        try {
                            const displayDesign = this.toDisplayDesign(previousDesign as IDesignListItem)
                            await this.confirmDeleteDesign(displayDesign.id, displayDesign.projectId, displayDesign.designType);
                        }
                        finally {
                            modal.close();
                        }
                    },
                    onCancel: (modal) => {
                        // on keep both designs
                        modal.close();
                    }
                }).closed;
            }
        }

        return name;
    }

    private reloadQuickStartApplications() {
        this.quickStartApplications = sortBy(this.modulesService.getQuickStartApplications(), p => p.order);
    }

    private async confirmDeleteDesign(designId: string, projectId: string, designType: DesignTypeId): Promise<void> {
        if (this.deckingEnabled && designType === DesignTypeId.DiaphragmDesign) {
            const designsInfo = this.modulesService.getDesignListInfoByDesignType(designType) as IDeckingDesignListInfo;
            if (designsInfo) await designsInfo.deleteDesign(designId);
        }

        this.pendingAction = PendingAction.deleteDesign;
        this.pendingActionDesign = ({ designId: designId, projectId: projectId } as IProjectAndDesignId);

        try {
            await this.document.removeDesigns([designId]);

            if (!this.offlineService.isOffline) {
                this.refreshProjectsAndDesigns();
            }
        }
        finally {
            this.clearPendingAction();
        }
    }

    private viewContentLoaded() {
        this.setGroupedDesignsDisplayedElements();
    }

    private setGroupedDesignsDisplayedElements() {
        let newLimitStep = this.groupedDesignsLimitStep;
        const designContainer = document.querySelector('.design-type-designs');

        if (designContainer != null) {
            if (designContainer.clientWidth < 832) {
                newLimitStep = 6;
            }
            else if (designContainer.clientWidth >= 832 && designContainer.clientWidth < 1040) {
                newLimitStep = 8;
            }
            else if (designContainer.clientWidth >= 1040 && designContainer.clientWidth < 1248) {
                newLimitStep = 10;
            }
            else {
                newLimitStep = 12;
            }
        }

        if (newLimitStep != this.groupedDesignsLimitStep) {
            this.groupedDesignsLimitStep = newLimitStep;
            this.resetGroupedDesignsLimit();
            this.refreshProjectsAndDesigns();
        }
    }

    private initLeftNavigation() {
        this.leftNavigation = [
            {
                id: LeftNavigationItems.home,
                image: 'sprite-loading-logo-small',
                bottomSeparator: true,
                tooltip: 'Agito.Hilti.Profis3.Navigation.Home',
                showRedDot: false,
                visible: () => true,
                click: () => {
                    this.navigate(urlPath.projectAndDesign);
                }
            },
            {
                id: LeftNavigationItems.settings,
                image: 'sprite-settings',
                tooltip: 'Agito.Hilti.Profis3.Navigation.Settings',
                showRedDot: false,
                visible: () => true,
                click: () => {
                    this.openApplicationSettings();
                }
            },
            {
                id: LeftNavigationItems.templates,
                image: 'sprite-template',
                tooltip: 'Agito.Hilti.Profis3.Navigation.ReportTemplates',
                showRedDot: false,
                visible: () => true,
                click: () => {
                    this.openReportTemplates();
                }
            },
            {
                id: LeftNavigationItems.learning,
                image: 'sprite-elearning',
                tooltip: 'Agito.Hilti.Profis3.Navigation.ELearning',
                showRedDot: !this.userSettingsService.settings.elearnDoceboSeen.value, // show red dot for new feature
                visible: () => this.showElearningnLink,
                click: () => {
                    this.openELearning();
                }
            },
        ];

        this.leftNavigationSelectedButton = this.leftNavigation[0];
    }

    private resetWoodModule() {
        const region = this.userSettingsService.getCommonRegionById(this.userSettingsService.settings.application.general.regionId.value);
        this.isWoodModuleShown = environment?.woodModuleEnabled && ((environment?.woodModuleAvailableCountries ?? []).includes(region.id)
            ? this.featureVisibilityService.isFeatureEnabled('C2C_WoodModuleESPT')
            : region.hubId != RegionHub.E2 || region.hubId == RegionHub.E2 && this.featureVisibilityService.isFeatureEnabled('C2C_WoodModuleE2'));
    }

    private languageChanged() {
        this.loadNotifications();
        this.refreshDesigns();

        // Reasign dropdown object item values to trigger change detection in dropdown component for localized strings
        this.sortDesignsDropdown.items = this.initSortDropdownItems();
    }

    private initSortDropdownItems(): DropdownItem<DesignSort>[] {
        return [
            {
                value: DesignSort.Newest,
                text: this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesign.Newest'),
            },
            {
                value: DesignSort.Oldest,
                text: this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesign.Oldest')
            },
            {
                value: DesignSort.AtoZ,
                text: this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesignAtoZ')
            },
            {
                value: DesignSort.ZtoA,
                text: this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesignZtoA')
            }
        ];
    }

    private loadNotifications() {
        this.notifications = [];

        if (!this.offlineService.isOffline && this.productInformation.latestReleaseNotes != null) {

            const latestReleaseNotes = this.productInformation.latestReleaseNotes.Content;
            if (latestReleaseNotes != null) {
                this.notifications.push(
                    {
                        message: latestReleaseNotes,
                        type: NotificationType.info
                    } as INotification
                );
            }
        }
    }

    private editProjectNew() {
        if (this.editProjectName == null || this.editProjectName.trim() == '') {
            this.editProjectReject();
        }
        else if (this.document.projectNameExists(this.editProjectName.trim(), null)) {
            this.onServiceErrorHandler.showExistingProjectNameModal();
        }
        else {
            const project = new Project({
                id: this.guid.new(),
                name: this.editProjectName.trim(),
                owner: this.editProjectType == EditProjectType.new,
                isCompanyProject: this.editProjectType == EditProjectType.newCompanyProject,
                expanded: false
            });

            // call document service
            this.pendingAction = PendingAction.newProject;
            this.document.saveProject(project, ModalDialogType.project)
                .then(x => {
                    this.selectedProject = this.toDisplayProject(project);
                    this.refreshProjects();
                    this.scrollToItem(this.selectedProject);

                    this.projectFilterResult[project.id] = this.doesProjectMatchSearchFilter(this.selectedProject);
                })
                .finally(() => {
                    this.closeNewProject();
                    this.pendingAction = null;
                });
        }
    }

    private editProjectNewSub() {
        if (this.editProjectName == null || this.editProjectName.trim() == '') {
            this.editProjectReject();
        }
        else if (this.document.projectNameExists(this.editProjectName.trim(), this.newProjectParent.id)) {
            this.onServiceErrorHandler.showExistingProjectNameModal();
        }
        else {
            const project = new Project({
                id: this.guid.new(),
                name: this.editProjectName.trim(),
                owner: this.editProjectType == EditProjectType.newSub,
                isCompanyProject: this.editProjectType == EditProjectType.newCompanySub,
            });

            // set parent project
            const parentProject = this.document.findProjectById(this.newProjectParent.id);
            project.parentId = parentProject.id;

            // call document service
            this.pendingAction = PendingAction.newSubProject;
            this.document.saveProject(project, ModalDialogType.subproject)
                .then(x => {
                    if (parentProject != null) {
                        // Expand parent if subproject was added successfully.
                        parentProject.expanded = true;
                    }

                    this.selectedProject = this.toDisplayProject(project);
                    this.refreshProjects();
                    this.scrollToItem(this.selectedProject);

                    this.projectFilterResult[project.id] = this.doesSubprojectMatchSearchFilter(this.toDisplayProject(parentProject), this.selectedProject);
                })
                .finally(() => {
                    this.closeNewProject();
                    this.pendingAction = null;
                });
        }
    }

    private editProjectRename() {
        if (this.editProjectName == null ||
            this.editProjectName.trim() == '' ||
            this.selectedRenameProject.rawProject.getDisplayName(this.localization) == this.editProjectName) {
            this.renameProjectReject();
        }
        else if (this.selectedRenameProject != null &&
            this.document.projectNameExists(
                this.editProjectName.trim(),
                this.selectedRenameProject.parentId)) {
            this.onServiceErrorHandler.showExistingProjectNameModal();
        }
        else {
            const projectLevelType = this.selectedRenameProject.parentId ? ModalDialogType.subproject : ModalDialogType.project;
            const project = this.document.findProjectById(this.selectedRenameProject.id);
            const prevProjName = project.name;

            project.name = this.editProjectName;

            // call document service
            this.pendingAction = PendingAction.renameProject;
            this.document.saveProject(project, projectLevelType)
                .then(x => {
                    // refreshing off all parts where designs are stored
                    if (project.id == this.selectedProject.id) {
                        this.selectedProject = this.toDisplayProject(project);
                    }
                    else { // optimization: selectedProject watch already performs refresh
                        this.refreshProjectsAndDesigns();
                    }
                })
                .catch((e) => {
                    if (e instanceof Error) {
                        console.error(e);
                    }

                    project.name = prevProjName;
                })
                .finally(() => {
                    this.closeNewProject();
                    this.pendingAction = null;
                });
        }
    }

    private showEditProject(editProjectType: EditProjectType, editProjectName?: string) {
        this.editProjectName = editProjectName;

        if (editProjectType == EditProjectType.rename) {
            this.renameProjectType[this.selectedRenameProject.id] = editProjectType;
            setTimeout(() => document.getElementById('project-and-design-navigation-rename-project-textfield').focus());
        }
        else {
            this.editProjectType = editProjectType;

            if (editProjectType == EditProjectType.new) {
                setTimeout(() => document.getElementById('project-and-design-navigation-new-project-textfield').focus());
            }
            else if (editProjectType == EditProjectType.newSub || editProjectType == EditProjectType.newCompanySub) {
                setTimeout(() => document.getElementById('project-and-design-navigation-new-sub-project-textfield').focus());
            }
            else if (editProjectType == EditProjectType.newCompanyProject) {
                setTimeout(() => document.getElementById('project-and-design-navigation-new-company-project-textfield').focus());
            }
            else {
                throw new Error('Unknown EditProjectType');
            }
        }
    }

    private closeNewProject() {
        this.editProjectType = EditProjectType.none;
        this.selectedRenameProject = null;
        this.editProjectName = null;
        this.newProjectParent = null;

        setTimeout(() => {
            // set focus on something else
            document.body.focus();
        });
    }

    private filterDesigns(designs: IDisplayDesign[], filter: string) {
        if (designs == null || designs.length == 0) {
            return designs;
        }

        // Filter designs by supported design type
        designs = designs.filter((design) => {
            const designsInfo = this.modulesService.getDesignListInfoByDesignType(design.designType);
            if (designsInfo != null) {
                return designsInfo.designTypeId == design.designType && SafeFunctionInvokerHelper.safeInvoke(designsInfo.isEnabled, false);
            }

            if (design.designType == DesignTypeId.DiaphragmDesign) {
                return this.deckingEnabled;
            }

            return false;
        });

        filter = (filter || '').trim().toLowerCase();
        if (filter == '') {
            return designs;
        }

        // Filter remaining designs by properties
        return designs.filter((design) => (design.name || '').toLowerCase().indexOf(filter) > -1 || (design.displayDesignType == DisplayDesignType.design ? (design.rawProject.getDisplayName(this.localization) || '').toLowerCase().indexOf(filter) > -1 : false));
    }

    private sortDesigns(designs: IDisplayDesign[], sort: DesignSort) {
        switch (sort) {
            // newest
            case DesignSort.Newest:
                return sortBy(designs, (design: IDisplayDesign) => design.created.getTime()).reverse();

            // oldest
            case DesignSort.Oldest:
                return sortBy(designs, (design: IDisplayDesign) => design.created.getTime());

            // A to Z
            case DesignSort.AtoZ:
                return sortBy(designs, (design: IDisplayDesign) => (design.name || '').toLowerCase(), (design: IDisplayDesign) => design.displayDesignType == DisplayDesignType.design ? (design.rawProject.getDisplayName(this.localization) || '').toLowerCase() : undefined);

            // Z to A
            case DesignSort.ZtoA:
                return sortBy(designs, (design: IDisplayDesign) => (design.name || '').toLowerCase(), (design: IDisplayDesign) => design.displayDesignType == DisplayDesignType.design ? (design.rawProject.getDisplayName(this.localization) || '').toLowerCase() : undefined).reverse();

            default:
                throw new Error('Unknown DesignSort');
        }
    }

    private sortProjects(projects: IDisplayProject[]) {
        if (projects == null) {
            return null;
        }

        const displayProjects = sortBy(projects, (project: IDisplayProject) => (project.rawProject.name || '').toLowerCase());

        for (const project of displayProjects) {
            project.subProjects = this.sortProjects(project.subProjects);
        }

        return displayProjects;
    }

    private onSelectedProjectChange(newVal: IDisplayProject) {
        if (!this.offlineService.isOffline) {
            this.user.changeDesign(this.document.findProjectById(newVal.id));

            if (newVal.id != this.document.draftsProject.id && this.designView != DesignView.projects) {
                this.designView = DesignView.projects;
            }
            else { // optimization: selectedProject watch already performs refresh
                this.resetGroupedDesignsLimit();
                this.refreshProjectsAndDesigns();
            }

            if (newVal?.rawProject != null) {
                this.selectedProjectDisplayName = newVal.rawProject.getDisplayName(this.localization);
            }
            else {
                this.selectedProjectDisplayName = '';
            }
        }
    }

    private onDesignViewChange(newVal: DesignView) {
        this.user.projectAndDesignView = newVal;

        if (newVal != DesignView.projects && this.selectedProject?.id != this.document.draftsProject.id) {
            this.selectedProject = this.toDisplayProject(this.document.draftsProject);
        }
        else { // optimization: selectedProject watch already performs refresh
            this.resetGroupedDesignsLimit();
            this.refreshProjectsAndDesigns();
        }
        this.designDragDisabled = this.designView === DesignView.templates;
    }

    private getDesigns(designView: DesignView, designSearchValue: string, sort: DesignSort) {
        switch (designView) {
            case DesignView.allDesigns:
                return this.filterAndSortDesigns(this.getAllDesigns(), designSearchValue, sort);
            case DesignView.drafts:
            case DesignView.projects:
                return this.filterAndSortDesigns(this.selectedProject.designs, designSearchValue, sort);
            case DesignView.templates:
                return this.filterAndSortDesigns(this.getTemplates(), designSearchValue, sort);
            default:
                throw new Error('Unknown DesignView.');
        }
    }

    private getGroupedDesigns(designView: DesignView, designSearchValue: string, sort: DesignSort) {
        const designs = this.getDesigns(designView, designSearchValue, sort);
        let groupedDesigns: IGroupedDisplayDesign[] = [];
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);

        // group
        for (const design of designs) {
            const designTypeCodeListItem = design.designType != null ? this.designTypes[design.designType] : null;

            // after modularization will be done, remove this and in case of c2c is not enabled or decking is not enabled (and other design types) handle this in application-provider.service and by setting isEnabled property
            //       and filter out designs here
            if ((((!environment.c2cEnabled && !environment.c2cOverlayDemoEnabled) && design.designType != DesignTypeId.Concrete2Concrete) || (environment.c2cEnabled || environment.c2cOverlayDemoEnabled))
                && (this.deckingEnabled || design.designType !== DesignTypeId.DiaphragmDesign)
            ) {
                // no design type
                if (designTypeCodeListItem == null) {
                    let group = groupedDesigns.find((groupedDesign) => groupedDesign.designType == null);

                    // create container if not created
                    if (group == null) {
                        groupedDesigns.push(group = {
                            designType: null,
                            designTypeName: this.translate('Agito.Hilti.Profis3.ProjectAndDesing.Main.DesignType.Unknown'),
                            designs: [],
                            designsCount: 0
                        });
                    }

                    group.designsCount++;
                    if (group.designs.length < this.groupedDesignsLimit[DesignTypeId.Unknown]) {
                        group.designs.push(design);
                    }
                }
                else {
                    let group = groupedDesigns.find((groupedDesign) => groupedDesign.designType != null && groupedDesign.designType == design.designType);

                    // create container if not created
                    if (group == null) {
                        groupedDesigns.push(group = {
                            designType: designTypeCodeListItem.id,
                            designTypeName: designTypeCodeListItem.getTranslatedNameText(codeListDeps),
                            designs: [],
                            designsCount: 0
                        });
                    }

                    group.designsCount++;
                    if (group.designs.length < this.groupedDesignsLimit[group.designType]) {
                        group.designs.push(design);
                    }
                }
            }
        }

        // sort based on design type / sort data
        groupedDesigns = this.sortGroupedDesigns(groupedDesigns);

        return groupedDesigns;
    }

    private sortGroupedDesigns(groupedDesigns: IGroupedDisplayDesign[]): IGroupedDisplayDesign[] {
        return sortBy(groupedDesigns, (group: IGroupedDisplayDesign) => {
            const designsInfo = this.modulesService.getDesignListInfoByDesignType(group.designType);
            if (designsInfo) {
                return designsInfo.order;
            }

            if (group.designType == DesignTypeId.DiaphragmDesign) {
                return 106;
            }

            return Number.MAX_SAFE_INTEGER;
        });
    }

    private getAllDesigns() {
        return [...this.projects, this.toDisplayProject(this.document.draftsProject)].flatMap((project) => project.designs);
    }

    private getTemplates() {
        return this.designTemplate.templates.map(template => this.toDisplayDesignTemplate(template)).filter(template => template != null);
    }

    private filterAndSortDesigns(designs: IDisplayDesign[], designSearchValue: string, sort: DesignSort) {
        return this.sortDesigns(this.filterDesigns(designs, designSearchValue), sort);
    }

    private getRegionById(regionId: number) {
        const regionCodeList = this.codeListCommon.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
        return regionCodeList.find(region => region.id == regionId);
    }

    private toDisplayDesign(design: IDesignListItem): IDisplayDesign {
        const designInfo = this.modulesService.getDesignListInfo().find(x => x.designTypeId == design?.metaData?.designType);
        if (designInfo?.toDisplayDesign) {
            try {
                return designInfo.toDisplayDesign(design, this.getDesignThumbnail.bind(this)) as IDisplayDesign;
            }
            catch (err) {
                console.error(err);
            }
        }

        return undefined;
    }

    private toDisplayDesignTemplate(template: DesignTemplateInternalEntity): IDisplayDesign {

        const designInfo = this.modulesService.getDesignListInfo().find(x => x.designTypeId == template?.DesignTypeId);
        if (designInfo?.toDisplayDesignTemplate) {
            try {
                return designInfo.toDisplayDesignTemplate(template, this.getDesignThumbnail.bind(this)) as IDisplayDesign;
            }
            catch (err) {
                console.error(err);
            }
        }

        return undefined;
    }

    private getDesignTypeSpecificKey(key: string, designType: DesignType) {
        if (designType == null) {
            return key;
        }

        const newKey = `${key}.${designType.displayKey}`;
        return this.localization.getKeyExists(newKey) ? newKey : key;
    }

    private toDisplayProject(project: Project) {
        if (project == null) {
            return null;
        }

        const displayProject: IDisplayProject = {
            id: project.id,
            name: project.name,
            parentId: project.parentId,
            rawProject: project,
            subProjects: Object.values(project.subProjects).map((subProject) => this.toDisplayProject(subProject)),
            designs: Object.values(project.designs).map((design) => this.toDisplayDesign(design as IDesignListItem)).filter(design => design != undefined),
            created: project.createDate,
            owner: project.owner,
            isCompanyProject: project.isCompanyProject,
            expanded: project.expanded,
            readOnly: project.readOnly
        };

        return displayProject;
    }

    private toDisplayProjects(projects: Project[]) {
        const displayProjects = projects.filter(x => x.projectType == ProjectType.common).map((project) => this.toDisplayProject(project));
        return this.sortProjects(displayProjects);
    }

    private refreshProjectsAndDesigns(refresh = true) {
        this.refreshProjects(refresh);
        this.refreshDesigns();
        this.filterProjects();
    }

    public selectDesign(design: IDisplayDesign) {
        if (this.isDesignSelected(design))
            this.selectedDesigns = this.selectedDesigns.filter(x => x.id != design.id);
        else
            this.selectedDesigns.push(design);
    }

    public isDesignSelected(design: IDisplayDesign): boolean {
        return this.selectedDesigns.some(x => x.id == design.id);
    }

    public exportReports() {
        if (environment.useDevFeatures) {
            this.modal.openExportReports(this.selectedDesigns);
        }
    }

    public exportReportsAvailable(): boolean {
        return environment.useDevFeatures && this.selectedDesigns.length > 0;
    }

    public isSelectDesignForReportAvailable(design: IDisplayDesign): boolean {
        // check if module bulk report is disabled
        const designsInfo = this.modulesService.getDesignListInfoByDesignType(design.designType);
        if (designsInfo?.isBulkReportEnabled != null && !SafeFunctionInvokerHelper.safeInvoke(designsInfo.isBulkReportEnabled, false)) {
            return false;
        }

        return design.designType != DesignTypeId.CurtainWall && this.featureVisibilityService.isFeatureEnabled('BulkReport') && design.displayDesignType != DisplayDesignType.template;
    }

    public isCalculationTypeTextAvailable(design: IDisplayDesign): boolean {
        return environment.useDevFeatures && trim(design.calculationTypeText) != '';
    }

    private refreshDesigns() {
        this.groupedDesigns = this.getGroupedDesigns(this.designView, this.designSearchValue, this.sortDesignsDropdown.selectedValue);
        const groupedDesignIds = this.groupedDesigns.flatMap(groupedDesign => groupedDesign.designs.map(design => design.id));

        if (this.designView === DesignView.templates) {
            this.designTemplate.getDocumentThumbnails(groupedDesignIds)
                .then(thumbnails => {
                    if (thumbnails != null) {
                        for (const groupedDesign of this.groupedDesigns) {
                            groupedDesign.designs.forEach(design => {
                                if (thumbnails[design.id] != null && thumbnails[design.id] != '') {
                                    design.thumbnail = thumbnails[design.id];
                                }
                            });

                        }
                    }
                });
        }
        else {
            this.document.getDocumentThumbnails(groupedDesignIds)
                .then(thumbnails => {
                    if (thumbnails != null) {
                        for (const groupedDesign of this.groupedDesigns) {
                            groupedDesign.designs.forEach(design => {
                                if (thumbnails[design.id] != null && thumbnails[design.id] != '') {
                                    design.thumbnail = thumbnails[design.id];
                                }
                            });

                        }
                    }
                });
        }

        this.overviewItemCount[DesignView.allDesigns] = this.getDesigns(DesignView.allDesigns, undefined, DesignSort.Newest).length;
        this.overviewItemCount[DesignView.templates] = this.getDesigns(DesignView.templates, undefined, DesignSort.Newest).length;
        this.overviewItemCount[DesignView.drafts] = Object.keys(this.document.draftsProject.designs).length;
        this.overviewItemCount[DesignView.projects] = this.countProjects();
    }

    private countProjects() {
        let count = 0;
        this.projects.forEach((item) => {
            count++;
            item.subProjects.forEach(() => {
                count++;
            });
        });

        return count;
    }

    private refreshProjects(refresh = true) {
        this.projects = this.toDisplayProjects(this.getDocumentProjects());

        // refresh selected project
        if (this.selectedProject != null && refresh) {
            const project = this.findProjectById(this.selectedProject.id);

            if (project != null && !isEqual(this.selectedProject, project)) {
                this.selectedProject = project;
            }
        }

        this.projects.forEach((item) => {
            this.projectItemCount[item.id] = item.designs.length;
            item.subProjects.forEach((item2) => {
                this.projectItemCount[item2.id] = item2.designs.length;
            });
        });
        this.projectItemCount[this.document.draftsProject.id] = Object.keys(this.document.draftsProject.designs).length;
    }

    private findProjectById(projectId: string) {
        const allProjects = [...this.projects, this.toDisplayProject(this.document.draftsProject)];

        for (const project of allProjects) {
            if (project.id == projectId) {
                return project;
            }

            const subProject = project.subProjects.find(p => p.id == projectId);
            if (subProject != null) {
                return subProject;
            }
        }

        return null;
    }

    private getDocumentProjects() {
        return Object.values(this.document.projects).filter((project) => project.id != this.document.draftsProject.id);
    }

    private getDesignTypeImage(designType: number) {
        if (designType == null) {
            return undefined;
        }

        const designsInfo = this.modulesService.getDesignListInfoByDesignType(designType);
        if (designsInfo?.designTypeImage != null) {
            return designsInfo.designTypeImage;
        }

        return undefined;
    }

    private getDesignThumbnail(designId: string) {
        if (this.groupedDesigns == null) {
            return undefined;
        }

        const design = this.groupedDesigns
            .map((it) => it.designs)
            .flat()
            .find(dsg => dsg.id == designId);

        return design?.thumbnail;
    }

    private resetGroupedDesignsLimit() {
        const designTypes = this.modulesService.designTypes;
        for (const designType of designTypes) {
            this.groupedDesignsLimit[designType.id] = this.groupedDesignsLimitStep;
        }

        const maxHeightStyle = this.typeDesignsMaxHeight + 'px';
        document.querySelectorAll('.design-type-designs').forEach(e => {
            (e as HTMLElement).style.maxHeight = maxHeightStyle;
        });
    }

    private normalizeArray<T>(array: T[], indexKey: keyof T) {
        const normalizedObject: any = {};
        array.forEach(element => {
            const key = element[indexKey];
            normalizedObject[key] = element;
        });

        return normalizedObject as { [key: string]: T };
    }

    private onKeyDown(event: KeyboardEvent) {
        const target = event.target as HTMLElement;

        // keydown should already be processed by input
        if (target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement) {
            return;
        }

        // open import design popup
        if (event.ctrlKey === true && (event.key === 'I' || event.key === 'i')) {
            event.stopPropagation();
            event.preventDefault();

            this.importDesign.selectImportFile();
        }

        // open new design popup
        if (event.ctrlKey === true && (event.key === 'M' || event.key === 'm')) {
            event.stopPropagation();
            event.preventDefault();

            this.newDesignClick();
        }
    }

    private navigate(path: string) {
        this.routingService.navigateToUrl(path);
    }

    private showVirtualTour() {
        this.mainHeaderComponentRef.nativeElement.showVirtualTour();
    }

    private handleTourAndMarketingCampaigns() {
        if (this.tour.isNavigationTourInProgress ||
            !this.userSettingsService.settings.virtualtourSeen.value) {
            // Navigation tour opening after closing design view requires timeout
            setTimeout(() => { this.startTour(); }, 1000);

            this.userSettingsService.settings.virtualtourSeen.value = true;
            this.userSettingsService.save();
        }
        else {
            this.productInformation.openOnLaunchCampaigns();

            if (!this.offlineService.isOffline) {
                this.setInAppMarketingCampaign();
            }
        }
    }
}
