<div class="project-and-design-view">
    <pe-main-header #mainHeaderRef class="home-page-main-header" [startTour]="startTour"
        [openApplicationSettings]="openApplicationSettings" [useNewHomePage]="true"
        [eLearningVisible]="showElearningLink" (eLearningOpened)="openELearning()"
        [isDisabled]="pendingAction !=null"
        [setIsInternalLicenseSwitchAvailable]="isInternalLicenseSwitchAvailable">

    </pe-main-header>
    <div class="content">
        <div class="content-left">
            <div class="create-import-design">
                <!-- New button-->
                <div class="new-button-container">
                    <div ngbDropdown role="group" #createNewDropdown="ngbDropdown" #createNewDropdownElementRef id="new-button"
                        [autoClose]="!virtaulTourInProgress">
                        <button [disabled]="pendingAction != null" class="new-button button button-primary truncate-ellipsis"
                            ngbDropdownToggle>
                            {{translate('Agito.Hilti.Profis3.HomePage.New')}}
                        </button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <button [disabled]="pendingAction != null" ngbDropdownItem id="new-custom-design-button"
                                (click)="newCustomDesignClick()">
                                <span class="sprite sprite-add-document"></span><span
                                    class="truncate-ellipsis">{{translate('Agito.Hilti.Profis3.HomePage.CustomDesign')}}</span>
                            </button>
                            <ng-container *ngIf="!offlineService.isOffline">
                                <button [disabled]="pendingAction != null" ngbDropdownItem (click)="newTemplateClick()"
                                    [ngbTooltip]="getTemplateDisabledTooltip" [disabled]="isTemplatesDisabled">
                                    <span [ngClass]="{ 'disabled': isTemplatesDisabled }" class="sprite sprite-add-template"></span><span
                                        class="truncate-ellipsis">{{translate('Agito.Hilti.Profis3.HomePage.Template')}}</span>
                                </button>
                                <button [disabled]="pendingAction != null" ngbDropdownItem id="new-project-button" (click)="newProjectClick()">
                                    <span class="sprite sprite-add-folder"></span><span
                                        class="truncate-ellipsis">{{translate('Agito.Hilti.Profis3.HomePage.Project')}}</span>
                                </button>
                                <button *ngIf="addNewCompanyProjectVisible" id="new-company-project-button"
                                    [ngbTooltip]="getCompanyDisabledTooltip" (click)="newCompanyProjectClick()"
                                    [disabled]="pendingAction != null || companyProjectDisabled" ngbDropdownItem>
                                    <span [ngClass]="{ 'disabled': companyProjectDisabled }" class="sprite sprite-add-company-project"></span>
                                    <span class="truncate-ellipsis">{{translate('Agito.Hilti.Profis3.HomePage.CompanyProject')}}</span>
                                </button>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <!-- New button ends-->

                <app-import-designs #importDesign [(importing)]="importing" [mode]="importDesign.mode"
                    [disabled]="importDesign.disabled" [onDesignImporting]="onDesignImporting"
                    (onDesignImported)="onDesignImported($event)"
                    (openNewDesignFromIntegration)="openNewDesignFromIntegrationDocument($event)">
                </app-import-designs>
            </div>

            <ng-container *ngIf="this.offlineService.isOffline">
                <app-tree-view [id]="anchorDesign.id" [treeData]="anchorDesign"
                    [isTreeSelected]="true"
                    [dropdownMenufixedPosition]="true"
                    [onMenuToggled]="projectMenuToggled"
                    [selectedItem]="anchorDesign">
                </app-tree-view>
                <app-tree-view [id]="templatesTree.id" [treeData]="templatesTree"
                    [dropdownMenufixedPosition]="true"
                    [onMenuToggled]="projectMenuToggled"
                    [isTreeSelected]="false">
                </app-tree-view>
            </ng-container>

            <ng-container *ngIf="!this.offlineService.isOffline">
                <div class="project-search-container">
                    <div class="search-image-container">
                        <span class="search-image sprite sprite-search-red"></span>
                    </div>
                    <input [maxlength]="250" [(ngModel)]="searchProjectText" id="project-search-textfield" class="search-input"
                        name="project-and-design-main-search-textfield"
                        placeholder="{{translate('Agito.Hilti.Profis3.HomePage.Project.SearchPlaceholder')}}" type="text"
                        (blur)="searchInputBlur()" />
                    <button *ngIf="searchProjectText?.trim().length" id="project-search-clear-button"
                        class="clear-search-button" (click)="searchProjectText = undefined" type="button">
                        <span class="sprite sprite-cross-red"></span>
                    </button>
                </div>

                <div class="border-section"></div>

                <app-tree-view [id]="allDesigns.id" [treeData]="allDesigns"
                    [isTreeSelected]="this.selectedTree == leftNavigationButtons.AllDesigns"
                    [dropdownMenufixedPosition]="true"
                    [onMenuToggled]="projectMenuToggled">
                </app-tree-view>
                <app-tree-view [id]="drafts.id" [treeData]="drafts"
                    [isTreeSelected]="this.selectedTree == leftNavigationButtons.Drafts"
                    [dropdownMenufixedPosition]="true"
                    [onMenuToggled]="projectMenuToggled">
                </app-tree-view>
                <div class="border-section"></div>

                <div class="tree-container">
                    <div class="tree-scroll">
                        <app-tree-view [id]="favouritesTree.id" [treeData]="favouritesTree" [selectedItem]="selectedTreeItem"
                            [isTreeSelected]="this.selectedTree == leftNavigationButtons.Favourites"
                            [searchText]="searchProjectText"
                            [dropdownMenufixedPosition]="true"
                            [onMenuToggled]="projectMenuToggled">
                        </app-tree-view>
                        <app-tree-view [id]="templatesTree.id" [treeData]="templatesTree" [selectedItem]="selectedTreeItem"
                            [isTreeSelected]="this.selectedTree == leftNavigationButtons.Templates"
                            [searchText]="searchProjectText"
                            [dropdownMenufixedPosition]="true"
                            [onMenuToggled]="projectMenuToggled">
                        </app-tree-view>
                        <app-tree-view *ngIf="companySectionVisible" [id]="companyProjectsTree.id"
                            [treeData]="companyProjectsTree" [selectedItem]="selectedTreeItem"
                            [isTreeSelected]="this.selectedTree == leftNavigationButtons.CompanyProjects"
                            [searchText]="searchProjectText"
                            [dropdownMenufixedPosition]="true"
                            [onMenuToggled]="projectMenuToggled">
                        </app-tree-view>

                        <app-tree-view id="shared-projects-tree" [id]="sharedTree.id"
                            [virtaulTourInProgress]="virtaulTourInProgress"
                            [treeData]="virtaulTourInProgress ? virtualTourSharedProjectsTreeData : sharedTree"
                            [selectedItem]="selectedTreeItem"
                            [isTreeSelected]="this.selectedTree == leftNavigationButtons.Shared"
                            [searchText]="searchProjectText"
                            [dropdownMenufixedPosition]="true"
                            [onMenuToggled]="projectMenuToggled">
                        </app-tree-view>

                        <div class="border-section"></div>

                        <app-tree-view id="my-projects-tree" [id]="myProjectsTree.id"
                            [virtaulTourInProgress]="virtaulTourInProgress"
                            [treeData]="virtaulTourInProgress ? virtualTourMyProjectsTreeData : myProjectsTree"
                            [selectedItem]="selectedTreeItem"
                            [isTreeSelected]="this.selectedTree == leftNavigationButtons.MyProjects"
                            [searchText]="searchProjectText"
                            [dropdownMenufixedPosition]="true"
                            [onMenuToggled]="projectMenuToggled">
                        </app-tree-view>
                    </div>
                </div>

                <div class="border-section"></div>

                <div class="archive">
                    <app-tree-view [id]="archiveTree.id" [treeData]="archiveTree"
                        [isTreeSelected]="this.selectedTree == leftNavigationButtons.Archive"
                        [dropdownMenufixedPosition]="true"
                        [onMenuToggled]="projectMenuToggled">
                    </app-tree-view>
                </div>
            </ng-container>
        </div>

        <!-- middle section -->
        <div class="content-center">
            <!-- Quick start -->
            <div class="box-section box-section-white quick-start main-container">
                <app-quick-start [selectedProject]="selectedProject" [virtaulTourInProgress]="virtaulTourInProgress"></app-quick-start>
            </div>
            <!-- Quick start ends -->

            <!-- Designs Section -->
            <div class="design-container">
                <!-- This section is not visible in pe-desktop -->
                <ng-container *ngIf="!offlineService.isOffline">

                    <!-- Designs view-->
                    <ng-container
                        *ngIf="this.selectedTree != leftNavigationButtons.Archive ">
                        <app-design-view
                            [virtualTourInProgress]="virtaulTourInProgress"
                            [displayDesignInput]="displayDesignInput"
                            (selectedProjectChanged)="onSelectedProjectChangeFromDesignView($any($event))"
                            (designsStateChanged)="onDesignStateChangedfromDesignView($any($event))"
                            (markAsFavoriteClicked)="markAsFavoriteClicked()"
                            ></app-design-view>
                    </ng-container>
                    <!-- Designs view ends-->

                    <!-- Archive -->
                    <ng-container
                        *ngIf="this.selectedTree == leftNavigationButtons.Archive">
                        <div class="box-section box-section-white designs main-container">
                            <div class="box-section-header">
                                <div class="box-section-title container-header"><span
                                        class="title">{{translate('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Archive')}}</span>
                                </div>
                                <pe-collapse [container]="'box-section-content'" [collapsed]="false">
                                </pe-collapse>
                            </div>
                            <div class="box-section-content">
                                <div class="container-content">
                                    <app-pe-archive (projectsTreeUpdateOnRestore)="refreshTrees($any($event))"></app-pe-archive>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <!-- Archive Ends-->

                </ng-container>
                <!-- Designs Section Ends -->
            </div>

        </div>
        <!-- middle section ends -->

        <!-- right side section -->
        <div class="content-right">
            <app-right-panel [virtaulTourInProgress]="virtaulTourInProgress"></app-right-panel>
        </div>
        <!-- right side section ends -->
    </div>
</div>
