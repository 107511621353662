import * as React from 'react';

import { DropdownType, IDropdownProps } from '@profis-engineering/pe-ui-common/entities/main-menu/dropdown-props';
import { TooltipType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { ControlHeader } from '../../ControlHeader';
import { IServices } from '../../MainMenu';
import { buildHtmlTooltip, isControlHidden } from '../../MainMenuHelper';
import { LocalizationService } from '../../../../services/localization.service';

const keyEnter = 13;
const keyUp = 38;
const keyDown = 40;
const keyTab = 9;
const keySpace = 32;

interface IDropdownState {
    isOpened: boolean;
    highlightedValue: number;
}

export class Dropdown extends React.PureComponent<IDropdownProps, IDropdownState> {
    private controlContainer: HTMLDivElement;
    private dropdownItems: HTMLDivElement;
    private button: HTMLButtonElement;

    private scrollIntoHighlightedFlag: boolean;
    private focusOnButtonFlag: boolean;

    private searchText: string;
    private searchTimeout: number;

    private keyDownFlag: boolean;

    constructor(props?: IDropdownProps) {
        super(props);

        this.keyDownFlag = false;

        this.onClick = this.onClick.bind(this);
        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onItemClick = this.onItemClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onInfoClick = this.onInfoClick.bind(this);

        this.state = {
            isOpened: false,
            highlightedValue: null
        };

        this.scrollIntoHighlightedFlag = false;
        this.focusOnButtonFlag = false;
    }

    public override componentDidMount() {
        document.addEventListener('click', this.onDocumentClick);
    }

    public override componentWillUnmount() {
        document.removeEventListener('click', this.onDocumentClick);
    }

    public override componentDidUpdate(prevProps: Readonly<IDropdownProps>, prevState: Readonly<IDropdownState>, snapshot?: any) {
        if (this.scrollIntoHighlightedFlag) {
            this.scrollIntoHighlighted();
        }

        if (this.focusOnButtonFlag
            && this.props.hidden == false
            && this.props.disabled == false
        ) {
            this.button.focus();
        }

        this.scrollIntoHighlightedFlag = false;
        this.focusOnButtonFlag = false;
    }

    public override render() {
        if (isControlHidden(this.props)) {
            return null;
        }

        const typeClass = this.props.dropdownType == DropdownType.inline ? 'inline' : '';

        const opendClass = this.state.isOpened ? 'opend' : '';
        const caretClass = this.state.isOpened ? 'caret-up' : 'caret-down';
        const buttonId = `${this.props.controlId}-button`;
        const selectedItem = this.props.items.find((item) => item.value == this.props.selectedValue);
        const selectedText = selectedItem != null ? selectedItem.text : '';
        const selectedAddOnText = selectedItem != null ? selectedItem.addOnText : '';
        const tooltipDisabledOnly = this.props.tooltipTitle == null && this.props.tooltipDisabledTitle != null && this.props.tooltip == null && this.props.tooltipDisabled != null;
        const tooltipType = !this.props.disabled && tooltipDisabledOnly ? null : this.props.tooltipType;

        const tooltipTitleTmp = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipTitle = tooltipType != TooltipType.Popup ? tooltipTitleTmp : null;

        const tooltipTextTmp = this.props.disabled && this.props.tooltipDisabled != null ? this.props.tooltipDisabled : this.props.tooltip;
        const tooltipText = tooltipType != TooltipType.Popup ? tooltipTextTmp : null;

        const tooltip = this.props.title != null && this.props.title != '' && !this.props.disabled ? null : buildHtmlTooltip(tooltipText, tooltipTitle);

        const infoTooltipKey = 'Agito.Hilti.Profis3.ControlTooltip.Popup';
        const internalPortfolioOnlyTooltip = buildHtmlTooltip(this.props.localization.getString('Agito.Hilti.Profis3.Anchors.ShowFullInternalPortfolio.Tooltip'));
        return (
            <div data-control-id={this.props.controlId}
                className={`react-dropdown control ${this.props.sizeClass}`}
                data-tip={tooltip}
                data-html={tooltip != null ? true : null}>
                <div className='header-container'>
                    <ControlHeader
                        text={this.props.title}
                        controlId={buttonId}
                        tooltip={tooltipText}
                        tooltipTitle={tooltipTitle}
                        localization={this.props.localization}
                        infoClick={tooltipType == TooltipType.Popup ? this.onInfoClick : undefined}
                        infoTooltip={tooltipType == TooltipType.Popup ? infoTooltipKey : undefined}
                    />
                </div>

                <div
                    className='control-container'
                    ref={(e) => this.controlContainer = e}
                    onKeyDown={this.onKeyDown}
                    onKeyPress={this.onKeyPress} >
                    <button
                        data-control-id={buttonId}
                        className='dropdown-button'
                        type='button'
                        disabled={this.props.disabled}
                        onClick={this.onClick}
                        ref={(e) => this.button = e}
                        data-tip={tooltip}
                        data-html={tooltip != null ? true : null}>
                        <div className='dropdown-button-container'>
                            <div className='button-item item'>
                                <p className='text'>{selectedText}</p>
                                {selectedItem != null && this.props.showAddsOn && selectedItem.addOnActive
                                    ? <span className='add-on'>{selectedAddOnText}</span>
                                    : null}
                                {selectedItem != null && selectedItem.tag != null
                                    ? <span className='tag'>{selectedItem.tag}</span>
                                    : null}
                                {selectedItem != null && selectedItem.isNew
                                    ? <span className='new'>{this.props.localization.getString('Agito.Hilti.Profis3.Anchors.New')}</span>
                                    : null}
                                {selectedItem != null && selectedItem.internalPortfolioOnly
                                    ? <span className='tag'
                                        data-tip={internalPortfolioOnlyTooltip}
                                        data-html={internalPortfolioOnlyTooltip != null ? true : null}>
                                            {this.props.localization.getString('Agito.Hilti.Profis3.Anchors.ShowFullInternalPortfolio')}
                                        </span>
                                    : null}
                            </div>
                            <span className='space'></span>
                            <div className='caret-container'>
                                <div className={`caret ${caretClass}`}></div>
                            </div>
                        </div>
                    </button>
                    <div
                        className={`dropdown-items ${typeClass} ${opendClass}`}
                        data-tip={tooltip}
                        data-html={tooltip != null ? true : null}
                        ref={(e) => this.dropdownItems = e} >
                        {
                            this.props.items.map((item) =>
                                <DropdownItem
                                    controlId={`${this.props.controlId}-${item.value}`}
                                    key={`${this.props.controlId}-${item.value}`}
                                    value={item.value}
                                    text={item.text}
                                    selected={item.value == this.state.highlightedValue}
                                    showAddOn={this.props.showAddsOn && item.addOnActive}
                                    addOnText={item.addOnText}
                                    tag={item.tag}
                                    isNew={item.isNew}
                                    internalPortfolioOnly={item.internalPortfolioOnly}
                                    clicked={this.onItemClick}
                                    localization={this.props.localization as LocalizationService}
                                />)
                        }
                    </div>
                </div>
            </div>
        );
    }

    private onInfoClick() {
        if (this.props.infoClicked != null) {
            this.props.infoClicked();
        }
    }

    private onClick(_: React.MouseEvent) {
        if (this.keyDownFlag) {
            this.keyDownFlag = false;
        }
        else {
            if (this.state.isOpened) {
                this.close();
            }
            else {
                this.open();
            }

            if (this.props.onClick != null) {
                this.props.onClick(!this.state.isOpened);
            }
        }
    }

    private onDocumentClick(event: MouseEvent) {
        const target = event.composedPath()[0] as HTMLElement;
        if (!this.controlContainer?.contains(target)) {
            this.close();
        }
    }

    private onItemClick(value: number) {
        this.selectValue(value);
        this.close();
    }

    private onKeyDown(event: React.KeyboardEvent) {
        if (this.state.isOpened) {
            const eventCode = event.which || event.keyCode || event.charCode;
            if (eventCode == keyEnter || eventCode == keyTab || eventCode == keySpace) {
                this.keyDownFlag = true;
                this.selectValue(this.state.highlightedValue);
                this.close();
            }
            else if (eventCode == keyDown) {
                event.preventDefault();
                if (this.state.highlightedValue != null) {
                    const highlightedIndex = this.props.items.findIndex((item) => item.value == this.state.highlightedValue);
                    const nextIndex = highlightedIndex + 1 < this.props.items.length ? highlightedIndex + 1 : highlightedIndex;
                    this.setState((prevState, props) => {
                        return {
                            isOpened: prevState.isOpened,
                            highlightedValue: this.props.items[nextIndex].value
                        };
                    });
                    this.scrollIntoHighlightedFlag = true;
                }
                else if (this.props.items.length > 0) { // select first if none is selected
                    this.setState((prevState, props) => {
                        return {
                            isOpened: prevState.isOpened,
                            highlightedValue: this.props.items[0].value
                        };
                    });
                    this.scrollIntoHighlightedFlag = true;
                }
            }
            else if (eventCode == keyUp) {
                event.preventDefault();
                if (this.state.highlightedValue != null) {
                    const highlightedIndex = this.props.items.findIndex((item) => item.value == this.state.highlightedValue);
                    const prevIndex = highlightedIndex - 1 >= 0 ? highlightedIndex - 1 : highlightedIndex;
                    this.setState((prevState, props) => {
                        return {
                            isOpened: prevState.isOpened,
                            highlightedValue: this.props.items[prevIndex].value
                        };
                    });
                    this.scrollIntoHighlightedFlag = true;
                }
                else if (this.props.items.length > 0) { // select first if none is selected
                    this.setState((prevState, props) => {
                        return {
                            isOpened: prevState.isOpened,
                            highlightedValue: this.props.items[0].value
                        };
                    });
                    this.scrollIntoHighlightedFlag = true;
                }
            }
        }
    }

    private onKeyPress(event: React.KeyboardEvent) {
        if (this.state.isOpened) {
            event.preventDefault();
            const eventCode = event.which || event.keyCode || event.charCode;

            if (this.searchTimeout != null) {
                clearTimeout(this.searchTimeout);
            }
            this.searchTimeout = setTimeout(() => {
                this.searchTimeout = null;
                this.searchText = '';
            }, 1000);

            this.searchText += String.fromCharCode(eventCode);

            const itemToHighlight = this.props.items.find((item) => item.text.substr(0, this.searchText.length).toLowerCase() == this.searchText.toLowerCase());

            if (itemToHighlight != null) {
                this.setState((prevState, props) => {
                    return {
                        isOpened: prevState.isOpened,
                        highlightedValue: itemToHighlight.value
                    };
                });
                this.scrollIntoHighlightedFlag = true;
            }
        }
    }

    private open() {
        if (!this.state.isOpened) {
            this.setState({
                isOpened: true,
                highlightedValue: this.props.selectedValue
            });

            this.scrollIntoHighlightedFlag = true;

            this.searchText = '';
        }
    }

    private close() {
        if (this.state.isOpened) {
            this.setState({
                isOpened: false,
                highlightedValue: null
            });

            this.focusOnButtonFlag = true;

            this.searchText = null;
            if (this.searchTimeout != null) {
                clearTimeout(this.searchTimeout);
                this.searchTimeout = null;
            }
        }
    }

    private selectValue(value: number) {
        if (this.props.valueChanged != null) {
            this.props.valueChanged(value);
        }
    }

    private scrollIntoHighlighted() {
        const offset = 2;
        const selectedIndex = this.props.items.findIndex((item) => item.value == this.state.highlightedValue);

        if (selectedIndex != -1) {
            const selectedElement = this.dropdownItems.querySelectorAll('.dropdown-item')[selectedIndex] as HTMLElement;
            const dropdownFullHeight = this.dropdownItems.scrollHeight;
            const dropdownHeight = this.dropdownItems.offsetHeight;

            // we have a scrollbar
            if (dropdownFullHeight > dropdownHeight) {
                const selectedElementHeight = selectedElement.offsetHeight;
                const dropdownTop = this.dropdownItems.scrollTop;
                const dropdownBottom = this.dropdownItems.scrollTop + dropdownHeight;

                // missing top
                if (selectedElement.offsetTop < dropdownTop) {
                    this.dropdownItems.scrollTop = selectedElement.offsetTop - offset;
                }
                // missing bottom
                else if (selectedElement.offsetTop + selectedElementHeight + offset * 2 > dropdownBottom) {
                    this.dropdownItems.scrollTop = selectedElement.offsetTop - dropdownHeight + selectedElementHeight + offset * 2;
                }
            }
        }
    }
}

interface IDropdownItemProps {
    controlId: string;
    value: number;
    text: string;
    selected: boolean;
    showAddOn: boolean;
    addOnText: string;
    tag: string;
    isNew: boolean;
    internalPortfolioOnly: boolean;
    clicked: (value: number) => void;
    localization?: LocalizationService;
}

class DropdownItem extends React.PureComponent<IDropdownItemProps> {
    constructor(props?: IDropdownItemProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    public override render() {
        const selectedClass = this.props.selected ? 'selected' : '';
        const text = this.props.text != null ? this.props.text : '';
        const tag = this.props.tag != null ? this.props.tag.charAt(0) : null;
        const internalPortfolioOnlyText = this.props.localization.getString('Agito.Hilti.Profis3.Anchors.ShowFullInternalPortfolio');
        const internalPortfolioOnlyTooltip = buildHtmlTooltip(this.props.localization.getString('Agito.Hilti.Profis3.Anchors.ShowFullInternalPortfolio.Tooltip'));
        const newText = this.props.localization.getString('Agito.Hilti.Profis3.Anchors.New');

        return (
            <button
                data-control-id={this.props.controlId}
                className={`dropdown-item ${selectedClass}`}
                type='button'
                onClick={this.onClick} >
                <div className='item'>
                    <p className='text'>{text}</p>
                    {tag != null ? <span className='tag'>{tag}</span> : null}
                    {this.props.internalPortfolioOnly
                        ? <span className='tag'
                            data-tip={internalPortfolioOnlyTooltip}
                            data-html={internalPortfolioOnlyTooltip != null ? true : null}
                            >{internalPortfolioOnlyText}</span>
                        : null}
                    {this.props.isNew ? <span className='new'>{newText}</span> : null}
                    {this.props.showAddOn ? <span className='add-on'>{this.props.addOnText}</span> : null}
                </div>
            </button>
        );
    }

    private onClick(event: React.MouseEvent) {
        if (this.props.clicked != null) {
            this.props.clicked(this.props.value);
        }
    }
}
