import { Injectable } from '@angular/core';

import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { KnownRegion } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { environment } from '../../environments/environment';
import { StateGenerator } from '../helpers/state-generator';
import { storageKey, urlPath } from '../module-constants';
import { OfflineService } from './offline.service';
import { RoutingService } from './routing.service';
import { SessionStorageService } from './session-storage.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class LongRegistrationService {
    constructor(
        private userSettings: UserSettingsService,
        private userService: UserService,
        private sessionStorageService: SessionStorageService,
        private offlineService: OfflineService,
        private routingService: RoutingService
    ) { }

    public isLongRegistrationNeeded(): boolean {
        const doesCountrySupportLongRegistration = (region: CommonRegion) => {
            if (region == null) {
                return false;
            }

            const knownRegion = region.id as KnownRegion;
            const supportedCountries = environment.longRegistrationCountries as KnownRegion[];
            const supportedCountry = supportedCountries.filter(x => x == knownRegion);

            if (supportedCountry && supportedCountry.length > 0) {
                return true;
            }

            return false;
        };

        const region = this.userSettings.getActiveRegion(false);

        //
        // Check order is important here
        //
        if (this.userService.isInternalHiltiUser() || // 1.) Check if user is internal - @hilti.com
            this.offlineService.isOffline || // 2.) Check if application is offline
            this.userService.authentication.customerOriginId || // 3.) Check if customer origin ID is already set (long registration already finished).
            !doesCountrySupportLongRegistration(region) // 4.) Check if country isn't supported.
        ) {
            return false;
        }

        return true;
    }

    public async redirectToLongRegistration() {
        // Long registration needs Base64-encoded state!
        const stateEncoded = btoa(StateGenerator.generateState());
        this.sessionStorageService.set(storageKey.registrationState, stateEncoded);

        // Save the current url for when we get back from the login page
        this.sessionStorageService.set(storageKey.registrationReturnUrl, this.routingService.currentPath);

        const region = this.userSettings.getActiveRegion(false);

        const url = new URL(environment.longRegistrationUrl);
        // Country has to be upper case!
        url.searchParams.append('country', region.countryCode.toUpperCase());
        // Language only (without culture)!
        // We are not passing language (optional) because HOL cannot fallback to default language if our language is not supported (HOL throws error if country=DE and language=en)
        // url.searchParams.append('language', language);
        url.searchParams.append('redirect_url', this.offlineService.buildRedirectUri(urlPath.registrationCallback));
        url.searchParams.append('app_id', 'PE');
        url.searchParams.append('state', stateEncoded);
        url.searchParams.append('clientid', environment.externalClientId);

        window.location.href = url.toString();

        // return a promise that is never resolved or rejected since we are waiting for page navigation
        return new Promise<never>(() => { return; });
    }
}
