<div class="modal-header">
    <h3 class="modal-title" l10n="Agito.Hilti.Profis3.ApplicationSettings.Title"></h3>

    <button id="application-settings-close-button" type="button" class="close" (click)="dismiss()" [disabled]="submitted">
        <span>&times;</span>
    </button>
</div>

<form class="form-application-settings" #ngForm="ngForm" (ngSubmit)="save(ngForm)" novalidate>
    <div class="modal-body modal-body-app-settings">
        <div class="modal-body-content">
            <!-- NAVIGATION -->
            <div class="navigation">
                <div class="navigation-container box-section">
                    <pe-section [look]="collapseSectionBoxLook"
                        [title]="'Agito.Hilti.Profis3.ApplicationSettings.Menu.General' | l10n:localization.selectedLanguage"
                        [collapseTooltip]="'Agito.Hilti.Profis3.Main.Region.ShowHide' | l10n:localization.selectedLanguage"
                        [collapsed]="sectionsCollapsed.general.collapsed"
                        (collapsedChange)="onSectionCollapsedChange(sectionsCollapsed.general, $any($event).detail)">
                        <div class="navigation-container-buttons">
                            <button id="application-settings-region-language-unit" class="navigation-view-button"
                                (click)="setDisplayType(displayTypeEnum.regionLanguageUnits)"
                                [ngClass]="{ 'selected': displayType == displayTypeEnum.regionLanguageUnits }" type="button"
                                [disabled]="submitted">
                                <div class="wrapper">
                                    <span class="selector"></span>
                                    <span class="text"
                                        l10n="Agito.Hilti.Profis3.ApplicationSettings.Menu.RegionLanguageUnits"></span>
                                </div>
                            </button>
                            <button id="application-settings-default-params" class="navigation-view-button"
                                (click)="setDisplayType(displayTypeEnum.defaultParams)"
                                [ngClass]="{ 'selected': displayType == displayTypeEnum.defaultParams }" type="button"
                                [disabled]="submitted">
                                <div class="wrapper">
                                    <span class="selector"></span>
                                    <span class="text"
                                        l10n="Agito.Hilti.Profis3.ApplicationSettings.Menu.DefaultParameters"></span>
                                </div>
                            </button>
                            <button id="application.settings-interaction-settings" class="navigation-view-button"
                                (click)="setDisplayType(displayTypeEnum.interactionSettings)"
                                [ngClass]="{ 'selected': displayType == displayTypeEnum.interactionSettings }" type="button"
                                [disabled]="submitted">
                                <div class="wrapper">
                                    <span class="selector"></span>
                                    <span class="text"
                                        l10n="Agito.Hilti.Profis3.ApplicationSettings.Menu.InteractionSettings"></span>
                                </div>
                            </button>
                            <button id="application-settings-advanced-baseplate-calculation" class="navigation-view-button"
                                *ngIf="isAdvancedCalculationPossible"
                                (click)="setDisplayType(displayTypeEnum.advancedBaseplateCalculation)"
                                [ngClass]="{ 'selected': displayType == displayTypeEnum.advancedBaseplateCalculation }"
                                type="button" [disabled]="submitted">
                                <div class="wrapper">
                                    <span class="selector"></span>
                                    <span class="text"
                                        [l10n]="advancedBaseplateCalculationNavigationKey"></span>
                                </div>
                            </button>
                            <button id="application-settings-interfaces-to-structural-calculation-software"
                                class="navigation-view-button" *ngIf="isNotDesktopMode"
                                (click)="setDisplayType(displayTypeEnum.interfacesToStructuralCalculationSoftware)"
                                [ngClass]="{ 'selected': displayType == displayTypeEnum.interfacesToStructuralCalculationSoftware }"
                                type="button" [disabled]="submitted">
                                <div class="wrapper">
                                    <span class="selector"></span>
                                    <span class="text"
                                        l10n="Agito.Hilti.Profis3.ApplicationSettings.Menu.InterfacesToStructuralCalculationSoftware"></span>
                                </div>
                            </button>
                        </div>
                    </pe-section>
                </div>

                <div class="navigation-container box-section" *ngFor="let moduleSettings of moduleAppSettings">
                    <pe-section [look]="collapseSectionBoxLook"
                        [title]="moduleSettings.title ?? translate(moduleSettings.titleKey)"
                        [collapseTooltip]="moduleSettings.tooltipKey | l10n:localization.selectedLanguage"
                        [collapsed]="moduleSettings.collapsed"
                        (collapsedChange)="onModuleSectionCollapsedChange(moduleSettings, $any($event).detail)">
                        <div class="navigation-container-buttons">
                            <button *ngFor="let moduleSection of moduleSettings.sections"
                                id="{{moduleSection.id}}"
                                class="navigation-view-button"
                                (click)="showSectionComponent(moduleSection)"
                                [ngClass]="{ 'selected': isSectionSelected(moduleSection) }"
                                type="button" [disabled]="submitted">
                                <div class="wrapper">
                                    <span class="selector"></span>
                                    <span class="text">{{ moduleSection.title ?? translate(moduleSection.titleKey) }}</span>
                                </div>
                            </button>
                        </div>
                    </pe-section>
                </div>

                <decking-settings-menu
                    [rowDiaphragmSelected]="displayType == displayTypeEnum.quickStartDiaphragmDesign"
                    (selectRowDiaphragm)="setDisplayType(displayTypeEnum.quickStartDiaphragmDesign)"
                    [deckingEnabled]="deckingEnabled">
                </decking-settings-menu>
            </div>
            <!-- NAVIGATION -->

            <div class="main">
                <div class="form-body">
                    <app-app-settings-region-language
                        *ngIf="displayType == displayTypeEnum.regionLanguageUnits"
                        [regionLanguageValues]="regionLanguageData"
                        (regionChange)="handleRegionChanged($event)"
                        [submitted]="submitted">
                    </app-app-settings-region-language>

                    <app-app-settings-default-parameters
                        *ngIf="displayType == displayTypeEnum.defaultParams"
                        [region]="regionLanguageData.region"
                        [decimalSeparator]="regionLanguageData.decimalSeparator"
                        [groupSeparator]="regionLanguageData.thousandsSeparator"
                        [submitted]="submitted"
                        [defaultParametersData]="defaultParametersData"
                        [concreteSteelCalculationMethodId]="concreteSteelCalculationMethodId">
                    </app-app-settings-default-parameters>

                    <app-app-settings-advanced-baseplate-calculation
                        *ngIf="displayType == displayTypeEnum.advancedBaseplateCalculation && isAdvancedCalculationPossible"
                        [decimalSeparator]="regionLanguageData.decimalSeparator"
                        [groupSeparator]="regionLanguageData.thousandsSeparator"
                        [submitted]="submitted"
                        [advancedBaseplateCalculationValues]="advancedCalculationInputs">
                    </app-app-settings-advanced-baseplate-calculation>

                    <app-app-settings-interaction-settings [interactionValues]="interactionData" [submitted]="submitted"
                        *ngIf="displayType == displayTypeEnum.interactionSettings">
                    </app-app-settings-interaction-settings>

                    <app-app-settings-structural-calculation-software
                        [structuralInterfacesValues]="structuralCalculationData" [submitted]="submitted"
                        *ngIf="displayType == displayTypeEnum.interfacesToStructuralCalculationSoftware">
                    </app-app-settings-structural-calculation-software>

                    <decking-settings-diaphragm
                        *ngIf="deckingEnabled"
                        [hidden]="displayType != displayTypeEnum.quickStartDiaphragmDesign"
                        (deckingSettingChanged)="handleDeckingSettingChanged($any($event).detail)"
                        [deckingSetting]="deckingData"
                        [selectedRegion]="deckingSelectedRegion"
                        [submitted]="submitted"
                        [reloadDataSubject$]="reloadDeckingSettings$"
                        [saveDataSubject$]="saveDeckingSettings$">
                    </decking-settings-diaphragm>

                    <div #componentInjector></div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" id="application-settings-cancel-button" class="modal-button button button-default"
            l10n="Agito.Hilti.Profis3.ApplicationSettings.Cancel" (click)="dismiss()" [disabled]="submitted">
        </button>

        <button id="application-settings-save-button" type="submit" class="modal-button button button-CTA"
            [disabled]="(ngForm.enabled && !ngForm.valid) || submitted">
            <pe-loading-button
                [loading]="submitted"
                [text]="'Agito.Hilti.Profis3.ApplicationSettings.Save' | l10n:localization.selectedLanguage">
            </pe-loading-button>
        </button>
    </div>
</form>
