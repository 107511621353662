import { querySelectorAllDeep, querySelectorDeep } from 'query-selector-shadow-dom';


export interface IWindowTestApi extends Window {
    testApi: TestApi;
}

/**
 * API used by e2e tests.
 * Usage:
 * testApi.querySelector('#TextBox123')
 * testApi.querySelector('#TextBox123 .input')
 *
 * testApi.querySelectorAll('#export-report-design-name .text-label-container p')[1].innerHTML
 * testApi.querySelector('#export-report-details-section .design-name .text-label')
 */
export class TestApi {
    public static init() {
        const wdw = window as unknown as IWindowTestApi;
        wdw.testApi = wdw.testApi || new TestApi();
    }


    public querySelector(selector: string, root?: Document | HTMLElement, cachedElements?: HTMLElement[] | null): HTMLElement | null {
        return querySelectorDeep(selector, root, cachedElements);
    }

    public querySelectorAll(selector: string, root?: Document | HTMLElement, cachedElements?: HTMLElement[] | null): HTMLElement[] {
        return querySelectorAllDeep(selector, root, cachedElements);
    }
}
