import { Subject } from 'rxjs';

import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';
import {
    IntegrationDataRequest, IntegrationDataResponse
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities';
import {
    DataIntegrationRequestType, DataIntegrationType, ErrorType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Enums';
import {
    DlubalRequest, DlubalResponse
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.IntegrationTypes.Dlubal';
import {
    DlubalApplicationInstanceType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.IntegrationTypes.Dlubal.Enums';
import {
    IntegrationsDataServiceBase
} from '@profis-engineering/pe-ui-shared/services/integrations-data.service.base';

import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { IntegrationsNotificationService } from './integrations-notification.service';
import { LoggerService } from './logger.service';
import { OfflineService } from './offline.service';

export enum IntegrationsConnectionStatus {
    unknown,
    pending,
    connected,
    notConnected,
    error,
    oldVersionDetected
}

export interface IntegrationsConnectionStatusChange {
    type: DataIntegrationType;
    status: IntegrationsConnectionStatus;
}

@Injectable({
    providedIn: 'root'
})
export class IntegrationsDataService extends IntegrationsDataServiceBase {
    public dlubalStatus: IntegrationsConnectionStatus;
    public sap2000Status: IntegrationsConnectionStatus;
    public robotStatus: IntegrationsConnectionStatus;
    public etabsStatus: IntegrationsConnectionStatus;
    public staadProStatus: IntegrationsConnectionStatus;

    public requestDataObject?: object;
    public requestUrl?: string;

    private _statusChanged = new Subject<IntegrationsConnectionStatusChange>();
    public statusChanged = this._statusChanged.asObservable();

    private requestIds: Map<string, (response: any) => void> =
        new Map<string, (response: any) => void>();

    private refreshingDlubal = false;
    private refreshingSap2000 = false;
    private refreshingRobot = false;
    private refreshingEtabs = false;
    private refreshingStaadPro = false;

    constructor(
        private integrationsNotificationService: IntegrationsNotificationService,
        private loggerService: LoggerService,
        private apiService: ApiService,
        private offlineService: OfflineService
    ) {
        super();
        this.resetIntegrationsConnectionStatus();
    }

    /**
     * Registers the handler to be invoked when the new data available notification is received
     */
    public registerNewDataAvailableHandler(): void {
        this.integrationsNotificationService.registerNewDataAvailableHandler((response: any) => {
            if (!response || !response.RequestId) {
                this.loggerService.log('Request data does not contains request ID', LogType.error);
                return;
            }
            const requestId = response.RequestId;

            // If we are not expecting anything for the request id, do nothing
            if (!this.requestIds.has(requestId)) {
                return;
            }

            // Otherwise get the continueWith method for the specified id
            const continueWith = this.requestIds.get(requestId);

            // Make a request to get the data for this specific request id
            this.getData(requestId)
                .then((data) => {
                    // Invoke the continueWith handler if it exists with the data
                    if (continueWith !== null) {
                        continueWith(data);
                    }
                })
                .catch((error) => {
                    this.loggerService.logServiceError(
                        error,
                        'integrations-data-service',
                        'get data');
                })
                .finally(() => {
                    // Remove the request id from the set
                    this.requestIds.delete(requestId);
                });
        });
    }

    /**
     * Makes a request to get the data of the specified integration and request type,
     * and gets an id (Guid) which is later (when notification received) used to get the actual data
     * @param dataIntegrationType The type of integration data
     * @param requestType The type of request
     * @param timeoutHandler The handler that is called after the specified timeout
     * @param continueWith The handler that is called after receiving a successful response
     * @param timeout The time in milliseconds after which to call the timeout handler
     */
    public async requestData<T extends IntegrationDataResponse>(
        dataIntegrationType: DataIntegrationType,
        requestType: DataIntegrationRequestType,
        timeoutHandler: () => void = null,
        continueWith: (response: T) => void = null,
        explicitRequest: IntegrationDataRequest = null,
        timeout = 30000
    ): Promise<void> {
        const url = `${environment.integrationServicesServerUrl}api/data/requestData`;

        let request: IntegrationDataRequest;
        if (explicitRequest != null) {
            request = explicitRequest;
        }
        else {
            request = {
                DataIntegrationType: dataIntegrationType,
                RequestType: requestType,
            } as IntegrationDataRequest;
        }

        this.requestUrl = url;
        this.requestDataObject = request;

        try {
            const response = await this.apiService.request<{ RequestId: string }>(new HttpRequest('PUT', url, request), { supressErrorMessage: true });

            const responseDataModel = response.body;

            if (!responseDataModel ||
                !responseDataModel.RequestId) {
                return;
            }

            const requestId = responseDataModel.RequestId;

            // Save the request id
            this.requestIds.set(requestId, continueWith);

            // If we are still waiting for the notification for this request id after the timeout has been reached, invoke the handler
            setTimeout(() => {
                if (this.requestIds.has(requestId)) {
                    if (timeoutHandler !== null) {
                        timeoutHandler();
                    }

                    // And remove the request id so we will ignore if any response for it comes later
                    this.requestIds.delete(requestId);
                }
            }, timeout);
        }
        catch (response) {
            this.loggerService.logServiceError(
                response,
                'integrations-data-service',
                'request data');

            throw response;
        }
    }

    /**
     * Shorthand for requestData for Dlubal integration
     */
    public async requestDataDlubal<T extends IntegrationDataResponse>(
        requestType: DataIntegrationRequestType,
        applicationInstanceType: DlubalApplicationInstanceType,
        timeoutHandler: () => void = null,
        continueWith: (response: T) => void = null,
        explicitRequest: IntegrationDataRequest = null,
        timeout = 30000
    ): Promise<void> {
        if (explicitRequest === null) {
            explicitRequest = {
                DataIntegrationType: DataIntegrationType.Dlubal,
                RequestType: requestType,
                ApplicationInstanceType: applicationInstanceType
            } as DlubalRequest;
        }

        return await this.requestData(
            DataIntegrationType.Dlubal,
            requestType,
            timeoutHandler,
            continueWith,
            explicitRequest,
            timeout);
    }

    public refreshIntegrationAvailability(integrationType: DataIntegrationType): void {
        if (this.checkIntegrationAvailability(integrationType)) {
            return;
        }

        // Remove once integrations will be enabled without special query string
        if (!this.isIntegrationEnabled(integrationType)) {
            return;
        }

        this.changeStatus(integrationType, IntegrationsConnectionStatus.pending);

        const isDlubalIntegration = integrationType == DataIntegrationType.Dlubal;

        if (!this.offlineService.isOffline) {
            if (isDlubalIntegration) {
                this.refreshDlubalOnline();
            }
            else {
                this.refreshConnectionStatus(integrationType);
            }
        }
    }

    public setIntegrationStatusToNotConnected(integrationType: DataIntegrationType): void {
        if (integrationType == DataIntegrationType.Dlubal) {
            this.dlubalStatus = IntegrationsConnectionStatus.notConnected;
            this._statusChanged.next({
                type: DataIntegrationType.Dlubal,
                status: this.dlubalStatus
            });
        }
        else if (integrationType == DataIntegrationType.SAP2000) {
            this.sap2000Status = IntegrationsConnectionStatus.notConnected;
            this._statusChanged.next({
                type: DataIntegrationType.SAP2000,
                status: this.sap2000Status
            });
        }
        else if (integrationType == DataIntegrationType.Robot) {
            this.robotStatus = IntegrationsConnectionStatus.notConnected;
            this._statusChanged.next({
                type: DataIntegrationType.Robot,
                status: this.robotStatus
            });
        }
        else if (integrationType == DataIntegrationType.ETABS) {
            this.etabsStatus = IntegrationsConnectionStatus.notConnected;
            this._statusChanged.next({
                type: DataIntegrationType.ETABS,
                status: this.etabsStatus
            });
        }
        else if (integrationType == DataIntegrationType.StaadPro) {
            this.staadProStatus = IntegrationsConnectionStatus.notConnected;
            this._statusChanged.next({
                type: DataIntegrationType.StaadPro,
                status: this.staadProStatus
            });
        }
        else {
            throw new Error(
                `Disabling integration status ran into unknown integration type: ${integrationType}.`);
        }

    }

    public resetIntegrationsConnectionStatus() {
        this.dlubalStatus = IntegrationsConnectionStatus.unknown;
        this.sap2000Status = IntegrationsConnectionStatus.unknown;
        this.robotStatus = IntegrationsConnectionStatus.unknown;
        this.etabsStatus = IntegrationsConnectionStatus.unknown;
        this.staadProStatus = IntegrationsConnectionStatus.unknown;
    }

    /**
     * Makes a request to get the data for the specified id
     * @param requestId The id of the request to get the data for
     */
    private async getData(requestId: string): Promise<IntegrationDataResponse> {
        const url = `${environment.integrationServicesServerUrl}api/data/getData/${requestId}`;

        this.requestUrl = url;
        this.requestDataObject = null;

        try {
            const response = await this.apiService.request<unknown>(new HttpRequest('GET', url));
            if (response.status !== 200) {
                throw response;
            }

            const payload: IntegrationDataResponse = JSON.parse((response.body as any).Payload) as IntegrationDataResponse;

            return payload;
        }
        catch (error) {
            this.loggerService.logServiceError(
                error,
                'integrations-data-service',
                'get data');

            throw error;
        }
    }

    private refreshDlubalOnline(): void {
        this.requestDataDlubal<DlubalResponse>(
            DataIntegrationRequestType.RunningInstance,
            DlubalApplicationInstanceType.RFEM5,
            () => {
                this.changeStatus(
                    DataIntegrationType.Dlubal,
                    IntegrationsConnectionStatus.notConnected);
            },
            (response: IntegrationDataResponse) => {
                // If no error for Rfem, no need to check Rstab as well, since connection to Dlubal exists
                if (response.ErrorType === ErrorType.None) {
                    this.changeStatus(
                        DataIntegrationType.Dlubal,
                        IntegrationsConnectionStatus.connected);

                    this.refreshingDlubal = false;

                    return;
                }

                // Otherwise, check Rstab
                this.requestDataDlubal<DlubalResponse>(
                    DataIntegrationRequestType.RunningInstance,
                    DlubalApplicationInstanceType.RSTAB8,
                    () => {
                        this.changeStatus(
                            DataIntegrationType.Dlubal,
                            IntegrationsConnectionStatus.notConnected);
                    },
                    (response) => {
                        this.refreshingDlubal = false;

                        if (response.ErrorType === ErrorType.None) {
                            this.changeStatus(
                                DataIntegrationType.Dlubal,
                                IntegrationsConnectionStatus.connected);
                        }
                        else if (response.ErrorType === ErrorType.NoInstanceRunning) {
                            this.changeStatus(
                                DataIntegrationType.Dlubal,
                                IntegrationsConnectionStatus.notConnected);
                        }
                        else {
                            this.changeStatus(
                                DataIntegrationType.Dlubal,
                                IntegrationsConnectionStatus.error);
                        }
                    })
                    .catch((err) => {
                        console.error(err);

                        this.changeStatus(
                            DataIntegrationType.Dlubal,
                            IntegrationsConnectionStatus.error);

                        this.refreshingDlubal = false;
                    });
            })
            .catch((err) => {
                console.error(err);

                this.changeStatus(
                    DataIntegrationType.Dlubal,
                    IntegrationsConnectionStatus.error);

                this.refreshingDlubal = false;
            });
    }

    private checkIntegrationAvailability(integrationType: DataIntegrationType) {
        switch (integrationType) {
            case DataIntegrationType.Dlubal:
                // DLubal is always enabled
                return this.refreshingDlubal;
            case DataIntegrationType.SAP2000:
                return this.offlineService.isOffline || this.refreshingSap2000;
            case DataIntegrationType.Robot:
                return this.offlineService.isOffline || this.refreshingRobot;
            case DataIntegrationType.ETABS:
                return this.offlineService.isOffline || this.refreshingEtabs;
            case DataIntegrationType.StaadPro:
                return this.offlineService.isOffline || this.refreshingStaadPro;
            default:
                // Should never come here
                return false;
        }
    }

    // Remove once there will be integrations available without special query string
    private isIntegrationEnabled(integrationType: DataIntegrationType) {
        switch (integrationType) {
            // Dlubal, SAP2000, Robot, ETABS are always enabled
            case DataIntegrationType.Dlubal:
            case DataIntegrationType.SAP2000:
            case DataIntegrationType.Robot:
            case DataIntegrationType.ETABS:
            case DataIntegrationType.StaadPro:
                return true;
            default:
                // Unsupported integration type
                return false;
        }
    }

    private refreshConnectionStatus(integrationType: DataIntegrationType) {
        this.requestData<IntegrationDataResponse>(
            integrationType,
            DataIntegrationRequestType.RunningInstance,
            () => {
                // handle timeout from requestData
                this.changeStatus(
                    integrationType,
                    IntegrationsConnectionStatus.notConnected);
            },
            (response) => {
                this.setRefreshingStatus(integrationType, false);

                if (response.ErrorType === ErrorType.None) {
                    this.changeStatus(
                        integrationType,
                        IntegrationsConnectionStatus.connected);
                }
                else if (response.ErrorType === ErrorType.NoInstanceRunning) {
                    this.changeStatus(
                        integrationType,
                        IntegrationsConnectionStatus.notConnected);
                }
                else {
                    this.changeStatus(
                        integrationType,
                        IntegrationsConnectionStatus.error);
                }
            })
            .catch(err => {
                console.error(err);

                this.changeStatus(
                    integrationType,
                    IntegrationsConnectionStatus.error);

                this.setRefreshingStatus(
                    integrationType,
                    false);
            });
    }

    private setRefreshingStatus(
        integrationType: DataIntegrationType,
        status: boolean) {
        switch (integrationType) {
            case DataIntegrationType.Dlubal:
                this.refreshingDlubal = status;
                break;
            case DataIntegrationType.SAP2000:
                this.refreshingSap2000 = status;
                break;
            case DataIntegrationType.Robot:
                this.refreshingRobot = status;
                break;
            case DataIntegrationType.ETABS:
                this.refreshingEtabs = status;
                break;
            case DataIntegrationType.StaadPro:
                this.refreshingStaadPro = status;
                break;
            default:
                throw new Error('Unknown integration type!');
        }
    }

    private changeStatus(
        integrationType: DataIntegrationType,
        connectionStatus: IntegrationsConnectionStatus) {
        const status = connectionStatus || IntegrationsConnectionStatus.unknown;

        switch (integrationType) {
            case DataIntegrationType.Dlubal:
                this.dlubalStatus = status;
                this._statusChanged.next({
                    type: DataIntegrationType.Dlubal,
                    status: this.dlubalStatus
                });
                break;

            case DataIntegrationType.SAP2000:
                this.sap2000Status = status;
                this._statusChanged.next({
                    type: DataIntegrationType.SAP2000,
                    status: this.sap2000Status
                });
                break;

            case DataIntegrationType.Robot:
                this.robotStatus = status;
                this._statusChanged.next({
                    type: DataIntegrationType.Robot,
                    status: this.robotStatus
                });
                break;

            case DataIntegrationType.ETABS:
                this.etabsStatus = status;
                this._statusChanged.next({
                    type: DataIntegrationType.ETABS,
                    status: this.etabsStatus
                });
                break;

            case DataIntegrationType.StaadPro:
                this.staadProStatus = status;
                this._statusChanged.next({
                    type: DataIntegrationType.StaadPro,
                    status: this.staadProStatus
                });
                break;

            default:
                throw new Error(
                    `Unknown integration type: ${integrationType}.`);
        }
    }
}
