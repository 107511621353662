import isEqual from 'lodash-es/isEqual';

import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ReportDesignRequestC2C, ReportResponseEntityC2C
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.ReportService.Shared.Entities';
import {
    UploadedPictureType
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.ReportService.Shared.Enums';
import {
    ReportTemplateEntity
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';

import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { ReportTemplateService } from './report-template.service';

export interface IReportTemplateLogo {
    blob: Blob;
    url: string;
    base64: string;
}

export interface IUploadedPicture {
    type: UploadedPictureType;
    content: string;
}

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    public logos: Record<number, IReportTemplateLogo> = {};

    private pendingRequests: Record<number, Promise<IReportTemplateLogo>> = {};
    private loaded: boolean;
    private pendingRequestTemplate: Promise<ReportTemplateEntity[]>;

    constructor(
        private apiService: ApiService,
        private reportTemplateService: ReportTemplateService
    ) { }

    public get templates() {
        return this.reportTemplateService.templates;
    }

    public set templates(templates: ReportTemplateEntity[]) {
        this.reportTemplateService.templates = templates;
    }

    public async generateAndDownloadReportC2C(request: ReportDesignRequestC2C): Promise<ReportResponseEntityC2C> {
        const url = this.addC2CDemoQueryFlag(`${environment.c2cReportServiceUrl}ReportService/GenerateReport`);
        return (await this.apiService.request<ReportResponseEntityC2C>(new HttpRequest('POST', url, request))).body;
    }

    public initialize(data: ReportTemplateEntity[]): void {
        if (data != null && data.length > 0) {
            this.templates = data;
            this.pendingRequestTemplate = Promise.resolve(this.templates);
            this.loaded = true;
        }
    }

    public async getTemplates(options?: ApiOptions): Promise<ReportTemplateEntity[]> {
        if (this.loaded == true || this.pendingRequestTemplate != null) {
            return this.pendingRequestTemplate;
        }

        const response = await this.reportTemplateService.getTemplates(options);

        this.templates = response; // (might need to use _.cloneDeep in the future)
        this.loaded = true;

        return this.templates;
    }

    public async saveTemplates(templates: ReportTemplateEntity[]): Promise<number[]> {
        // only send changes
        const requestTemplates = templates.map((template): Partial<ReportTemplateEntity> => {
            if (template.Id == null || template.Id < 1) {
                return template;
            }

            const savedTemplate = this.templates.find((t) => t.Id == template.Id);

            return Object.fromEntries((Object.entries(template) as [keyof ReportTemplateEntity, unknown][])
                .filter(([key, value]) => key == 'Id' || key == 'TemplateOptions' || key == 'Logo' || !isEqual(value, savedTemplate[key])));
        });

        // save template to database
        const response = await this.reportTemplateService.updateTemplate(templates, this.templates);

        // changed logos (removed logos included, they have empty string)
        const changedLogos = requestTemplates
            .filter(requestTemplate => requestTemplate.Logo != null || !response.includes(requestTemplate.Id))
            .map(requestTemplate => requestTemplate.Id);

        for (const id of changedLogos) {
            if (this.logos[id] != null) {
                URL.revokeObjectURL(this.logos[id].url);
            }
            delete this.logos[id];
        }

        // save templates on client side (might need to use _.cloneDeep in the future)
        // also remove templates that were not returned from server
        // it is possible that template was deleted in another session
        this.templates = templates.filter(template => template.Id < 1 || response.includes(template.Id));

        // update ids (new templates get an id) and clear logo data urls
        for (let i = 0; i < response.length; i++) {
            this.templates[i].Id = response[i];
            this.templates[i].Logo = null;
        }

        return response;
    }

    public getLogo(id: number): Promise<IReportTemplateLogo> {
        if (this.logos[id] != null) {
            return Promise.resolve(this.logos[id]);
        }

        if (this.pendingRequests[id] != null) {
            return this.pendingRequests[id];
        }

        return this.reportTemplateService.getLogo(id, this.logos);
    }

    /**
     * Get custom images.
     * @param imageNames - name of images that need to be retrieved.
     * @returns
     */
    public async getCustomImagesC2C(imageNames: string[]): Promise<string[]> {
        const url = `${environment.c2cReportServiceUrl}ReportService/GetImages`;
        const req = {
            imageNames
        };

        const request = new HttpRequest('POST', url, req, {
            responseType: 'json'
        });

        return (await this.apiService.request<string[]>(request, { supressErrorMessage: true })).body;
    }

    /**
     * Uploade image for C2C design when the report is ready for export.
     * @param designId - id of the design.
     * @param images - images that need to be uploaded.
     */
    public async uploadImagesC2C(designId: string, images: IUploadedPicture[]): Promise<string[]> {
        const url = `${environment.c2cReportServiceUrl}ReportService/UploadImages`;
        const req = {
            designId,
            images
        };

        const request = new HttpRequest('POST', url, req, {
            responseType: 'json'
        });

        return (await this.apiService.request<string[]>(request, { supressErrorMessage: true })).body;
    }

    private addC2CDemoQueryFlag(url: string) {
        return environment.c2cDemoFeatures
            ? `${url}?c2cdemo=true`
            : url;
    }
}
