import { Injectable } from '@angular/core';

import { getNumberDecimalSeparator, getNumberGroupSeparator } from '@profis-engineering/pe-ui-common/helpers/localization-helper';
import { replace } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { LocalizationService } from './localization.service';
import { UserSettingsService } from './user-settings.service';
import { NumberServiceBase } from '@profis-engineering/pe-ui-common/services/number.common';

export enum NumberType {
    integer,
    real
}

@Injectable({
    providedIn: 'root'
})
export class NumberService extends NumberServiceBase {
    public static readonly int32Max = 2147483647;
    public static readonly numberGroupSizes = [3];

    constructor(
        private localization: LocalizationService,
        private userSettings: UserSettingsService
    ) {
        super();
    }

    public parse(value: string, type: NumberType, decimalSeparator?: string, groupSeparator?: string): number {
        if (value == null) {
            return null;
        }

        if (typeof value === 'number') {
            return value;
        }

        if (typeof value !== 'string') {
            return Number.NaN;
        }

        value = value.trim();

        if (value == '') {
            return Number.NaN;
        }

        const lowerCase = value.toLowerCase();

        // infinity
        if (lowerCase == '∞' || lowerCase == 'inf' || lowerCase == 'infinity' || lowerCase == 'positiveinfinity') {
            return Number.POSITIVE_INFINITY;
        }

        if (lowerCase == '-∞' || lowerCase == '-inf' || lowerCase == '-infinity' || lowerCase == 'negativeinfinity') {
            return Number.NEGATIVE_INFINITY;
        }

        // removes empty space and/or one '+' before number
        value = value.replace(/((\s+|)\+?)(\d)/, '$3');

        const numberGroupSeparator = groupSeparator || getNumberGroupSeparator(this.localization.numberFormat(), this.userSettings);
        const numberDecimalSeparator = decimalSeparator || getNumberDecimalSeparator(this.localization.numberFormat(), this.userSettings);

        // 160 -> no-break space, 32 -> normal space
        value = value.replace(String.fromCharCode(160), String.fromCharCode(32));

        // Validate group separators
        if (!this.validateGroupSeparators(value, type, numberDecimalSeparator, numberGroupSeparator)) {
            return Number.NaN;
        }

        if (numberGroupSeparator != null) {
            value = replace(value, numberGroupSeparator, '');
        }

        // allow positive decimal entry without leading zero, e.g. ".5" => "0.5"
        if (value.indexOf(numberDecimalSeparator) == 0) {
            value = '0' + value;
        }

        // allow positive decimal entry without leading zero, e.g. "+.5" => "0.5"
        else if (value.indexOf('+' + numberDecimalSeparator) == 0) {
            value = replace(value, '+', '0');
        }

        // allow negative decimal entry without leading zero, e.g. "-.5" => "-0.5"
        else if (value.indexOf('-' + numberDecimalSeparator) == 0) {
            value = replace(value, '-', '-0');
        }

        // When dot(.) is not number decimal separator nor is number decimal separator then return NaN if number contains dot(.)
        // because it is a forbidden character
        if (numberDecimalSeparator != null) {
            if (numberDecimalSeparator != '.' && value.indexOf('.') >= 0) {
                return Number.NaN;
            }

            value = replace(value, numberDecimalSeparator, '.');
        }

        if (/^-?\d+(\.\d+)?$/.test(value)) {
            return parseFloat(value);
        }

        return Number.NaN;
    }

    public format(value: number, decimals?: number, toFixed?: boolean, decimalSeparator?: string, groupSeparator?: string) {
        if (value == null) {
            return null;
        }

        if (Number.isNaN(value)) {
            return 'NaN';
        }

        // infinity
        if (value == Number.POSITIVE_INFINITY) {
            return '∞';
        }

        if (value == Number.NEGATIVE_INFINITY) {
            return '-∞';
        }

        const numberGroupSeparator = groupSeparator || getNumberGroupSeparator(this.localization.numberFormat(), this.userSettings);
        const numberDecimalSeparator = decimalSeparator || getNumberDecimalSeparator(this.localization.numberFormat(), this.userSettings);
        const numberGroupSizes = NumberService.numberGroupSizes;

        let stringValue = this.numberToString(this.round(value, decimals), decimals, toFixed);
        const negative = stringValue.startsWith('-');

        // remove minus
        if (negative) {
            stringValue = stringValue.substring(1);
        }

        if (numberDecimalSeparator != null) {
            stringValue = replace(stringValue, '.', numberDecimalSeparator);
        }

        if (numberGroupSeparator != null && numberGroupSizes != null) {
            let index = stringValue.length;
            const repeat = numberGroupSizes[numberGroupSizes.length - 1] != 0;
            let groupSizes = numberGroupSizes;

            if (stringValue.indexOf(numberDecimalSeparator || '.') > 0) {
                index = stringValue.indexOf(numberDecimalSeparator || '.');
            }

            if (!repeat) {
                groupSizes = groupSizes.slice(0, groupSizes.length - 1);
            }

            for (let i = 0; i < groupSizes.length; i++) {
                const count = groupSizes[i];

                if (index - count > 0) {
                    index = index - count;
                    stringValue = stringValue.substring(0, index) + numberGroupSeparator + stringValue.substring(index);
                }
            }

            if (repeat) {
                const repeatCount = groupSizes[groupSizes.length - 1];

                while (index - repeatCount > 0) {
                    index = index - repeatCount;
                    stringValue = stringValue.substring(0, index) + numberGroupSeparator + stringValue.substring(index);
                }
            }
        }

        // add minus
        if (negative) {
            stringValue = `-${stringValue}`;
        }

        return stringValue;
    }

    public round(value: number, decimals?: number) {
        if (value == null) {
            return null;
        }

        if (decimals == Number.MAX_SAFE_INTEGER || decimals == Number.POSITIVE_INFINITY) {
            return value;
        }

        if (decimals == null || decimals <= 0) {
            decimals = 0;
        }

        // infinity
        if (value == Number.POSITIVE_INFINITY) {
            return Number.POSITIVE_INFINITY;
        }

        if (value == Number.NEGATIVE_INFINITY) {
            return Number.NEGATIVE_INFINITY;
        }

        if (value >= 0) {
            return +(this.numberToString(Math.round(+(this.numberToString(value) + 'e+' + decimals))) + 'e-' + decimals);
        }
        else {
            return +('-' + this.numberToString(Math.round(+(this.numberToString(Math.abs(value)) + 'e+' + decimals))) + 'e-' + decimals);
        }
    }

    public equals(x: number, y: number, precision: number) {
        return this.round(x, precision) == this.round(y, precision);
    }

    public roundMod(x: number, y: number, precision: number) {
        return this.round(x, precision) % this.round(y, precision);
    }

    private numberToString(value: number, decimals?: number, toFixed?: boolean) {
        if (value == null) {
            return null;
        }

        if (decimals == null) {
            decimals = 0;
        }

        let stringValue = value.toString();

        if (Math.abs(value) < 1.0) {
            const e = parseInt(value.toString().split('e-')[1]);
            if (e) {
                value *= Math.pow(10, e - 1);
                stringValue = (value < 0 ? '-0.' : '0.') + (new Array(e)).join('0') + value.toString().substring(value < 0 ? 3 : 2);
            }
        }
        else {
            let e = parseInt(value.toString().split('+')[1]);
            if (e > 20) {
                e -= 20;
                value /= Math.pow(10, e);
                stringValue = value + (new Array(e + 1)).join('0');
            }
        }

        if (toFixed) {
            const dotIndex = stringValue.indexOf('.');

            if (dotIndex == -1) {
                stringValue += '.';
            }

            const decimalLength = dotIndex != -1 ? stringValue.substring(dotIndex).length : 1;
            for (let i = decimalLength - 1; i < decimals; i++) {
                stringValue += '0';
            }
            // removes decimals if they exceeds the number of wanted decimals
            stringValue = stringValue.replace(new RegExp('(\\d+\\.\\d{' + decimals + '})(\\d+)?'), '$1');
        }
        // removes dot if there are no decimals
        stringValue = stringValue.replace(new RegExp('(\\d+)(\\.)$'), '$1');

        return stringValue;
    }

    // /**
    //  * Returns a string representation of an number.
    //  * @param value Value.
    //  * @param fractionDigits Number of digits after the decimal point. Must be in the range 0 - 20, inclusive.
    //  */
    // private numberToString(value: number, fractionDigits?: number) {
    //     if (value == null) {
    //         return null;
    //     }

    //     if (fractionDigits != null) {
    //         return value.toFixed(fractionDigits);
    //     }

    //     return trimEnd(value.toFixed(20), '.0');
    // }

    private validateGroupSeparators(value: string, type: NumberType, decimalSeparator?: string, groupSeparator?: string) {
        const numberGroupSeparator = groupSeparator || getNumberGroupSeparator(this.localization.numberFormat(), this.userSettings);
        const numberDecimalSeparator = decimalSeparator || getNumberDecimalSeparator(this.localization.numberFormat(), this.userSettings);

        if (type == NumberType.integer) {
            // CHECK DECIMAL SEPARATOR: Integer should not have decimal separator
            if (value.indexOf(numberDecimalSeparator) != -1) {
                return false;
            }

            // CHECK GROUP SEPARATOR: Either empty indices array or indices are in correct order and value.
            if (!this.validateGroupSeparatorPositions(value, groupSeparator)) {
                return false;
            }
        }
        else {
            // CHECK GROUP SEPARATOR: Either empty indices array or indices are in correct order and of correct value in non-decimal part. Group separator should not be in decimal part.
            const splitValue = value.split(numberDecimalSeparator);
            if (!this.validateGroupSeparatorPositions(splitValue[0], groupSeparator)) {
                return false;
            }

            if (splitValue.length > 1 && splitValue[1].indexOf(numberGroupSeparator) != -1) {
                return false;
            }
        }

        return true;
    }

    private validateGroupSeparatorPositions(value: string, groupSeparator?: string) {
        const numberGroupSeparator = groupSeparator || getNumberGroupSeparator(this.localization.numberFormat(), this.userSettings);
        const indices = this.findSubstringIndexes(value, numberGroupSeparator);
        let set = -1;

        for (let i = 0; i < indices.length; i++) {
            const groupSize = (i < NumberService.numberGroupSizes.length) ? NumberService.numberGroupSizes[i] : NumberService.numberGroupSizes[NumberService.numberGroupSizes.length - 1];
            set += 1 + groupSize; // 1 represents group separator

            if (groupSize != 0 && indices[i] != set) { // 0 can be at the end of number group sizes, not validating if its elsewhere
                return false;
            }
        }

        return true;
    }

    private findSubstringIndexes(value: string, substring: string) {
        const indices = [];
        // read chars from numerically lowest to largest
        for (let i = 0; i < value.length; i++) {
            if (value[value.length - 1 - i] === substring) {
                indices.push(i);
            }
        }
        return indices;
    }
}
