import some from 'lodash-es/some';

import {
    InfoLink, RegionLinks, RegionLinksTranslations, RegionMarketingCampaign,
    ReleaseNote
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.ProductInformationService.Shared.Entities';
import { hasProperty } from '@profis-engineering/pe-ui-common/helpers/object-helper';
import { stringNullOrEmpty } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';
import {
    ProductInformationServiceBase
} from '@profis-engineering/pe-ui-common/services/product-information.common';

import { ApiService } from './api.service';
import { LicenseService } from './license.service';
import { ModalService } from './modal.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';

export interface ProductInfoCodeListsEntity {
    Id: number;
    Name: string;
}

export interface ProductInfoRegionEntity extends ProductInfoCodeListsEntity {
    HubId: number;
}

export type ProductInfoHubEntity = ProductInfoCodeListsEntity

export interface ProductInfoLanguageEntity extends ProductInfoCodeListsEntity {
    DisplayName: string;
}

export interface ProductInfoDesignGuideEntity extends ProductInfoCodeListsEntity {
    DisplayKey: string;
    C2C: boolean;
    Icid: number;
    Url: string;
    SortOrder: number;
    Image: string;
    RegionId: number;
}

export interface ProductInfoWoodLinksEntity extends ProductInfoCodeListsEntity {
    RegionId: number;
    LanguageId: number;
    Url: string;
}

export abstract class ProductInformationService extends ProductInformationServiceBase {
    public abstract readonly isProductInformationServiceEnabled: boolean;

    private regionMarketingCampaigns: RegionMarketingCampaign[] = [];
    private latestReleaseNotesInternal: ReleaseNote;
    private lastVersionDate?: Date;
    private regionLinks: RegionLinks[];
    private designGuidesList: ProductInfoDesignGuideEntity[];
    private woodLinksList: ProductInfoWoodLinksEntity[];
    private infoLinks: InfoLink[] = [];

    constructor(
        protected userSettingsService: UserSettingsService,
        protected licenseService: LicenseService,
        protected userService: UserService,
        protected apiService: ApiService,
        protected modalService: ModalService
    ) {
        super();
    }

    public get latestReleaseNotes(): ReleaseNote {
        return this.latestReleaseNotesInternal;
    }

    public get regionLinksTranslationsForUser(): RegionLinksTranslations {
        const regionLinksTranslations = this.regionLinksForUser()?.RegionLinksTranslations.find(translations => translations.LCID == this.userSettingsService.settings.application.general.languageId.value);
        return regionLinksTranslations;
    }

    public get designGuies() {
        return this.designGuidesList;
    }

    public get woodLinks() {
        return this.woodLinksList;
    }

    private get regionId(): number {
        return this.userSettingsService.settings.application.general.regionId.value;
    }

    public regionLinksForUser(regionId?: number): RegionLinks {
        const id = regionId ?? this.regionId;
        const regionLinks = this.regionLinks?.find(links => links.RegionId == id);
        return regionLinks;
    }

    public abstract getMarketingCampaignsFromService(options?: ApiOptions): Promise<RegionMarketingCampaign[]>;

    public initializeMarketingCampaigns(regionMarketingCampaigns: RegionMarketingCampaign[]): void {
        this.regionMarketingCampaigns = regionMarketingCampaigns || [];
    }

    public initializeReleaseNotes(releaseNote: ReleaseNote): void {
        this.latestReleaseNotesInternal = releaseNote;
        this.lastVersionDate = releaseNote != null
            ? new Date(releaseNote.ReleaseDate)
            : null;
    }

    public initializeRegionLinks(regionLinks: RegionLinks[]): void {
        this.regionLinks = regionLinks;
    }

    public initializeDesignGuideList(codeLists: ProductInfoDesignGuideEntity[]) {
        this.designGuidesList = codeLists;
    }

    public initializeWoodLinksList(codelists: ProductInfoWoodLinksEntity[]) {
        this.woodLinksList = codelists;
    }

    public initializeInfoLinks(infoLinks: InfoLink[]): void {
        this.infoLinks = infoLinks ?? [];
    }

    public async openNewUserCampaigns(): Promise<void> {
        const openMarketingCampaigns = this.getPopUpCampaignsForNewUser();
        await this.openCampaignsModal(openMarketingCampaigns);
    }

    public async openOnLaunchCampaigns(): Promise<void> {
        const openMarketingCampaigns = this.getOnLaunchCampaigns();
        await this.openCampaignsModal(openMarketingCampaigns, true);
    }

    public async openCampaigns(): Promise<void> {
        const openMarketingCampaigns = this.getPopUpCampaigns();
        await this.openCampaignsModal(openMarketingCampaigns);
    }

    public hasMarketingCampaigns(): boolean {
        return this.getPopUpCampaigns()?.length > 0;
    }

    public isNewReleaseNoteVersion(): boolean {
        return (this.lastVersionDate == null ? 0 : this.lastVersionDate.getTime()) > (this.userSettingsService.settings.application.general.releaseNotesVersionDate.value == null ? 0 : this.userSettingsService.settings.application.general.releaseNotesVersionDate.value.getTime());
    }

    public setNewReleaseNoteVersion(): void {
        if (this.isNewReleaseNoteVersion()) {
            this.userSettingsService.settings.application.general.releaseNotesVersionDate.value = this.lastVersionDate;
            this.userSettingsService.save();
        }
    }


    public abstract getInfoLinksFromService(options?: ApiOptions): Promise<InfoLink[]>;

    public abstract getLatestReleaseNoteFromService(selectedLanguage: string, options?: ApiOptions): Promise<ReleaseNote>;

    public abstract getRegionLinksFromService(options?: ApiOptions): Promise<RegionLinks[]>;

    public abstract getDesignGuidesCodeLists(apiOptions?: ApiOptions): Promise<ProductInfoDesignGuideEntity[]>;

    public abstract getWoodLinksCodeLists(apiOptions?: ApiOptions): Promise<ProductInfoWoodLinksEntity[]>;


    public getInAppCampaigns = (): RegionMarketingCampaign[] => this.getCampaigns(false, true);

    public getInAppCampaignsForNewUser = (): RegionMarketingCampaign[] => this.getCampaigns(true, true);

    public getInfoLink(key: string): string {
        let url: string | undefined = undefined;

        if (stringNullOrEmpty(key)) {
            return url;
        }

        const infoLinkEntry = this.infoLinks?.find(infoLink => infoLink.Key.toLowerCase() == key.toLocaleLowerCase());
        if (infoLinkEntry == null) {
            return url;
        }

        url = infoLinkEntry.DefaultLink;

        const infoLinkEntryRegion = infoLinkEntry.RegionLinks.find(infoLink => infoLink.RegionId == this.regionId);
        if (infoLinkEntryRegion != null) {
            url = infoLinkEntryRegion.Link;
        }

        return url;
    }


    private getPopUpCampaigns = (): RegionMarketingCampaign[] => this.getCampaigns();

    private getPopUpCampaignsForNewUser = (): RegionMarketingCampaign[] => this.getCampaigns(true);

    private getOnLaunchCampaigns = (): RegionMarketingCampaign[] => this.getPopUpCampaigns().filter((campaign) =>
        !hasProperty(this.userSettingsService.settings.marketingCampaignDontShowOnLaunch.value, campaign.MarketingCampaignId.toString()) &&
        campaign.DisplayOnLaunch
    )

    private getCampaigns(forNewRegisteredUser = false, inAppPlacement = false): RegionMarketingCampaign[] {
        const regionId = this.userSettingsService.settings.application.general.regionId.value;
        const lcId = this.userSettingsService.settings.application.general.languageId.value;

        const isCampaignValid = (campaign: RegionMarketingCampaign) => {
            const hasValidPostalCode = !some(campaign.PostalCodes) || campaign.PostalCodes.includes(this.userService.authentication.subscription_info.AuthorizationEntryList[0].PostalCode);
            const hasValidUrlOrLink = (campaign.Url != undefined || campaign.Link != undefined) || ((campaign.LanguageUrls?.[lcId] != undefined) || (campaign.LanguageLinks?.[lcId] != undefined));
            return campaign.RegionId === regionId && campaign.ForNewRegisteredUser === forNewRegisteredUser && campaign.InAppPlacement === inAppPlacement && hasValidPostalCode && hasValidUrlOrLink;
        };

        const getRegionLanguageCampaign = (campaign: RegionMarketingCampaign) => {
            const regionLanguageCampaign = { ...campaign };
            if (regionLanguageCampaign.LanguageLinks?.[lcId] != undefined || regionLanguageCampaign.LanguageUrls?.[lcId] != undefined) {
                regionLanguageCampaign.Link = regionLanguageCampaign.LanguageLinks?.[lcId] ?? undefined;
                regionLanguageCampaign.Url = regionLanguageCampaign.LanguageUrls?.[lcId] ?? undefined;
                regionLanguageCampaign.Title = regionLanguageCampaign.LanguageTitles?.[lcId] ?? undefined;
                regionLanguageCampaign.Description = regionLanguageCampaign.LanguageDescriptions?.[lcId] ?? undefined;
            }
            return regionLanguageCampaign;
        };

        return this.regionMarketingCampaigns
            .filter(isCampaignValid)
            .map(getRegionLanguageCampaign);
    }

    private async openCampaignsModal(openMarketingCampaigns: RegionMarketingCampaign[], dontShowOnLaunchVisible = false): Promise<void> {
        if (openMarketingCampaigns == null || openMarketingCampaigns.length == 0) {
            return;
        }

        const marketingCampaign = openMarketingCampaigns.shift();
        await this.modalService.openMarketingCampaign(marketingCampaign, dontShowOnLaunchVisible).closed;
        await this.openCampaignsModal(openMarketingCampaigns, dontShowOnLaunchVisible);
    }
}
