import { Component, OnInit } from '@angular/core';
import { FeatureVisibilityService } from '../../services/feature-visibility.service';

@Component({
    selector: 'app-home-switch',
    templateUrl: './home-switch.component.html',
    styleUrls: []
})
export class HomeSwitchComponent implements OnInit {
    public isNewHomePageEnabled: boolean;
    constructor(
        private featureVisibilityService: FeatureVisibilityService,
    ) {
    }

    ngOnInit(): void {
        this.isNewHomePageEnabled = this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage');
    }
}
