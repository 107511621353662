import {
    ApplicationSettingsCommon, GeneralValue, IApplicationImportLoadsBase,
    IApplicationModelDisplayOptionsBase, IDefaultApplicationSettingsBase,
    IGeneralApplicationSettingsBase, IQuickStartSettingsCommon, IUserSettingsBase,
    UserSettings as UserSettingsCommon
} from '@profis-engineering/pe-ui-common/entities/user-settings';
import { ProjectAndDesignDesignGroup } from '@profis-engineering/pe-ui-common/services/user.common';

import { environment } from '../../environments/environment';
import { DesignSort } from '../components/project-and-design/project-and-design.component';
import { ModulesService } from '../services/modules.service';

export const userSettingsKeys = {
    // application general
    userAgreement: 'profis3_application_general_userAgreement',
    userPrivacy: 'profis3_application_general_userPrivacy',
    languageId: 'profis3_application_general_languageId'
};

export class ApplicationSettings extends ApplicationSettingsCommon<IGeneralApplicationSettingsBase, IDefaultApplicationSettingsBase> {
    // concrete2Concrete!: IApplicationConcrete2ConcreteSettings;
}

export function createUserSettings(modulesService: ModulesService): UserSettings {
    let userSettings = new UserSettings();

    for (const userSettingsInfo of modulesService.getUserSettingsInfo()) {
        // shared user settings for module (in root)
        if (userSettingsInfo.userSettingsRoot != null) {
            userSettings = {
                ...userSettings,
                ...userSettingsInfo.userSettingsRoot
            };
        }

        // separate user settings for module (separated by userSettingsKey, e.g. '.cw.')
        userSettings[userSettingsInfo.userSettingsKey] = userSettingsInfo.userSettings;

        // quick start for module
        if (userSettingsInfo.quickStart != null) {
            userSettings.quickStart = {
                ...userSettings.quickStart,
                ...userSettingsInfo.quickStart
            }
        }

        // application settings for module
        if (userSettingsInfo.applicationSettings != null) {
            userSettings.application = {
                ...userSettings.application,
                ...userSettingsInfo.applicationSettings
            }
        }

        // import loads settings for module
        if (userSettingsInfo.applicationImportLoads != null) {
            userSettings.applicationImportLoads = {
                ...userSettings.applicationImportLoads,
                ...userSettingsInfo.applicationImportLoads
            }
        }

        // model display options for module
        if (userSettingsInfo.applicationModelDisplayOptions != null) {
            userSettings.applicationModelDisplayOptions = {
                ...userSettings.applicationModelDisplayOptions,
                ...userSettingsInfo.applicationModelDisplayOptions
            }
        }
    }

    return userSettings;
}


/**
 * Class storing all the user settings of the PE; implements UserSettingsC2C, UserSettingsPe
 */
export class UserSettings {
    [name: string]: unknown;

    // USER SETTINGS

    // company details

    public user: IUserSettingsBase;


    // APPLICATION SETTINGS

    // general

    /**
     * Model display options
     */
    public application: ApplicationSettings;

    // QUICK START SETTINGS
    public quickStart: IQuickStartSettingsCommon;


    // import loads settings
    public applicationImportLoads: IApplicationImportLoadsBase;

    /**
     * Virtual tour
     */
    public virtualtourSeen: GeneralValue<boolean>;
    public homePagevirtualtourSeen: GeneralValue<boolean>;

    /**
     * E-learn docebo
     */
    public elearnDoceboSeen: GeneralValue<boolean>;

    /**
     * Stay on standard license (when we are handling no licese as free license user should confirm, this is flag indicating user already confirmed)
     */
    public stayOnStandardLicense: GeneralValue<boolean>;

    /**
     * Is first login tracked (when user lauches the PE for the first time (after registration), we track first login, this is flag indicating user was already tracked)
     */
    public firstLoginTracked: GeneralValue<boolean>;

    /**
     * Design group list or grid.
     */
    public designGroup: GeneralValue<ProjectAndDesignDesignGroup>;

    /**
     * Design sort
     */
    public designSort: GeneralValue<DesignSort>;

    /**
     * Model display options
     */
    public applicationModelDisplayOptions: IApplicationModelDisplayOptionsBase;

    /**
     * Menu tabs collapsed state
     */
    public applicationCollapsedState: GeneralValue<{ [id: string]: boolean }>;

    public marketingCampaignDontShowOnLaunch: GeneralValue<{ [marketingCampaignId: number]: void }>;

    /**
     * Desktop login info
     */
    public desktopUsername: GeneralValue<string>;

    constructor() {

        this.quickStart = {};

        this.user = UserSettingsCommon.getBaseUserSettingsDefaults();

        this.application = {
            general: UserSettingsCommon.getBaseGeneralApplicationSettingsDefaults(environment.translate),
            defaults: UserSettingsCommon.getBaseDefaultApplicationSettingsDefaults(),
            controls: UserSettingsCommon.getBaseControlsSettingsDefaults(),
            advancedCalculation: UserSettingsCommon.getBaseAdvancedCalculationApplicationSettingsDefaults(),
        };

        this.virtualtourSeen = new GeneralValue(null);
        this.homePagevirtualtourSeen = new GeneralValue(null);
        this.elearnDoceboSeen = new GeneralValue(null);
        this.stayOnStandardLicense = new GeneralValue(false);
        this.firstLoginTracked = new GeneralValue(null);
        this.designGroup = new GeneralValue(null);
        this.designSort = new GeneralValue(null);

        this.applicationModelDisplayOptions = {};

        this.applicationCollapsedState = new GeneralValue({});
        this.marketingCampaignDontShowOnLaunch = new GeneralValue({});
        this.applicationImportLoads = UserSettingsCommon.getBaseApplicationImportLoadsDefaults();

        this.desktopUsername = new GeneralValue(null);
    }
}
