import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { lastValueFrom, Observable } from 'rxjs';
import { handleGuardAction } from './guard-handler';

export type GuardFactory = (handleGuardAction: (action: () => boolean | UrlTree | Promise<boolean | UrlTree>) => Promise<boolean | UrlTree>) => CanActivateFn;

export const combineGuards = (...guardFactories: GuardFactory[]): CanActivateFn => async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    // we have to inject everything before the first await or Angular will be angry at us
    const router = inject(Router);
    const boundHandleGuardAction = (action: () => boolean | UrlTree | Promise<boolean | UrlTree>) => handleGuardAction(router, action);

    const guards = guardFactories.map(guardFactory => guardFactory(boundHandleGuardAction));

    for (const guard of guards) {
        let result  = guard(route, state);

        // Depending on the route result, we may need to await upon it in different ways.
        if (result instanceof Promise) {
            result = await result;
        }

        if (result instanceof Observable) {
            result = await lastValueFrom(result, { defaultValue: undefined as boolean | UrlTree });
        }

        if (result === false || result instanceof UrlTree) {
            return result;
        }
    }

    return true;
};
