import { HttpRequest } from '@angular/common/http';
import {
    ConvertDesignRequest, ConvertDesignResponse
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities';
import { environment } from '../../../environments/environment';
import { Design } from '../../entities/design';
import { ApiService } from '../../services/api.service';
import { DesignTypeId } from '../../services/modules.service';

/**
 * @deprecated DO NOT USE FOR NEW CODE. Will be removed with cleanup task: BUDQBP-35492.
 */
export class DesignHelperBwCompat {
    /**
     * @deprecated DO NOT USE FOR NEW CODE. Will be removed with cleanup task: BUDQBP-35492.
     */
    public static getProjectDesignFromDesign(design: Design): object {
        if (design.designTypeId == null) {
            return undefined;
        }

        if (design.designTypeId == DesignTypeId.Concrete
            || design.designTypeId == DesignTypeId.Handrail
            || design.designTypeId == DesignTypeId.Masonry
            || design.designTypeId == DesignTypeId.MetalDeck
        ) {
            // PE design
            return design.designData.projectDesign;
        }

        if (design.designTypeId == DesignTypeId.Concrete2Concrete) {
            // C2C design
            return design.designData.projectDesignC2C;
        }

        return undefined;
    }

    /**
     * @deprecated DO NOT USE FOR NEW CODE. Will be removed with cleanup task: BUDQBP-35492.
     */
    public static isDesignFileConversionNeeded(designTypeId: number) {
        if (designTypeId == DesignTypeId.Concrete
            || designTypeId == DesignTypeId.Handrail
            || designTypeId == DesignTypeId.Masonry
            || designTypeId == DesignTypeId.MetalDeck
        ) {
            return true;
        }

        return false;
    }

    /**
     * @deprecated DO NOT USE FOR NEW CODE. Will be removed with cleanup task: BUDQBP-35492.
     */
    public static async getDesignFileBytes(apiService: ApiService, projectDesign: object, designTypeId: number) {
        if (DesignHelperBwCompat.isDesignFileConversionNeeded(designTypeId)) {
            // PE design
            const request: ConvertDesignRequest = {
                JsonDesign: JSON.stringify(projectDesign)
            }
            const url = `${environment.baseplateApplicationWebServiceUrl}ConvertDesignToXml`;
            const response = await apiService.request<ConvertDesignResponse>(new HttpRequest('POST', url, request));
            return response?.body?.XmlDesign ?? [];
        }

        return undefined;
    }
}
