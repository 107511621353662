<div class="modal-header">
    <h3 class="modal-title" l10n="Agito.Hilti.Profis3.Archive.Title"></h3>

    <button id="archive-close-button" type="button" class="close" (click)="close()" [disabled]="submitted">
        <span>&times;</span>
    </button>
</div>

<div class="modal-body">
    <virtual-scroller #scrollProjects
        [items]="projects" [parentScroll]="scrollElement" [scrollAnimationTime]="0"
        [ngStyle]="{ 'height': (projects.length * projectRowHeight + projectHeaderHeight) + 'px' }">
        <table class="table-archive">
            <caption>Archive</caption>
            <thead>
                <tr class="table-archive-row-header">
                    <th>Project name</th>
                    <th>Designs</th>
                    <th>Members</th>
                    <th>Created</th>
                    <th>Archived</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody #container>
                <tr *ngFor="let item of scrollProjects.viewPortItems" class="table-archive-row">
                    <td>{{item.projectName}}</td>
                    <td>{{item.designs}}</td>
                    <td>{{item.members}}</td>
                    <td>{{dateTime.format(item.created)}}</td>
                    <td>{{dateTime.format(item.archived)}}</td>
                    <td>
                        <div class="action-buttons">
                            <button class="button-delete collapse-button"
                                (click)="deleteArchivedProject(item.projectId)" [disabled]="submitted"
                                [ngbTooltip]="'Agito.Hilti.Profis3.Archive.Delete' | l10n:localization.selectedLanguage"
                                placement="top">
                                <div class="sprite sprite-trash"></div>
                            </button>

                            <button class="button-restore collapse-button"
                                (click)="restoreProject(item.projectId)" [disabled]="submitted"
                                [ngbTooltip]="'Agito.Hilti.Profis3.Archive.Restore' | l10n:localization.selectedLanguage"
                                placement="top">
                                <div class="sprite sprite-refresh-l-dark"></div>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </virtual-scroller>
</div>
