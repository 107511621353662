import * as React from 'react';

import { ControlHeader } from '../../ControlHeader';
import { ControlType } from '../../ControlInfo';
import { isControlHidden } from '../../MainMenuHelper';
import { IRotateProps } from '@profis-engineering/pe-ui-common/entities/main-menu/rotate-pros';

export class Rotate extends React.PureComponent<IRotateProps> {
    constructor(props?: IRotateProps) {
        super(props);

        this.onRotateLeftClick = this.onRotateLeftClick.bind(this);
        this.onRotateRightClick = this.onRotateRightClick.bind(this);
    }

    public override render() {
        if (isControlHidden(this.props)) {
            return null;
        }

        const buttonsId = `${this.props.controlId}-buttons`;
        const leftButtonId = `${this.props.controlId}-rotate-left-button`;
        const rightButtonId = `${this.props.controlId}-rotate-right-button`;
        const leftButtonTooltip = this.props.localization.getString('Agito.Hilti.Profis3.RotateControl.RotateLeft');
        const rightButtonTooltip = this.props.localization.getString('Agito.Hilti.Profis3.RotateControl.RotateRight');

        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled ? this.props.tooltipDisabled : this.props.tooltip;

        return (
            <div data-control-id={this.props.controlId} className={`control react-rotate ${this.props.sizeClass}`}>
                <ControlHeader
                    text={this.props.title}
                    controlId={buttonsId}
                    tooltip={tooltipText}
                    tooltipTitle={tooltipTitle}
                    localization={this.props.localization}
                />

                <div
                    className='control-container'
                    data-control-id={buttonsId} >
                    {
                        this.props.rotateLeftHidden ? null :
                            <button
                                className='rotate-button rotate-left button button-default button-icon'
                                data-control-id={leftButtonId}
                                type='button'
                                onClick={this.onRotateLeftClick}
                                disabled={this.props.rotateLeftDisabled}
                                data-tip={leftButtonTooltip}  >
                                <div className='rotate-button-container'>
                                    <span className='sprite sprite-rotate-left-side'></span>
                                </div>
                            </button>
                    }
                    {
                        this.props.rotateRightHidden ? null :
                            <button
                                className='rotate-button rotate-right button button-default button-icon'
                                data-control-id={rightButtonId}
                                type='button'
                                onClick={this.onRotateRightClick}
                                disabled={this.props.rotateRightDisabled}
                                data-tip={rightButtonTooltip} >
                                <div className='rotate-button-container'>
                                    <span className='sprite sprite-rotate-right-side'></span>
                                </div>
                            </button>
                    }
                </div>
            </div>
        );
    }

    private onRotateLeftClick(event: React.MouseEvent) {
        if (this.props.rotateLeftClicked != null) {
            this.props.rotateLeftClicked();
        }
    }

    private onRotateRightClick(event: React.MouseEvent) {
        if (this.props.rotateRightClicked != null) {
            this.props.rotateRightClicked();
        }
    }
}

ControlType.Rotate = Rotate;
