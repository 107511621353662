import { Subject } from 'rxjs';

import { Injectable } from '@angular/core';

import { DesignSettingsServiceBase } from '@profis-engineering/pe-ui-common/services/design-settings.common';

@Injectable({
  providedIn: 'root'
})
export class DesignSettingsService extends DesignSettingsServiceBase {

    private _controllingDimensionChanged = new Subject<void>();
    public controllingDimensionChanged = this._controllingDimensionChanged.asObservable();

    public updateControllingDimensions() {
        this._controllingDimensionChanged.next();
      }
}
