import { Injectable } from '@angular/core';
import {
    LicenseSuite
} from '@profis-engineering/pe-ui-common/entities/code-lists/license-suite';
import {
    LicenseSuiteType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    UserLicense
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Licensing.Entities';
import {
    UserLicenseFeature as UserLicenseFeatures
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Licensing.Enums';
import {
    LicenseServiceBase
} from '@profis-engineering/pe-ui-common/services/license.common';

@Injectable({
    providedIn: 'root'
})
export class LicenseService extends LicenseServiceBase {
    public userLicense: UserLicense;
    public displayTrialInfo: boolean;
    public displayFloatingLimitReachedInfo: boolean;
    public rawLicense: string;

    constructor() {
        super();
    }

    public get floatingLimitReached(): boolean {
        return this.userLicense ?
            this.userLicense.Features.indexOf(UserLicenseFeatures.NoFloatingLicensesAvailable) > -1 :
            false;
    }

    public initialize(data: UserLicense, rawLicense: string) {
        this.userLicense = data;
        this.rawLicense = rawLicense;
    }

    public isTrial() {
        return this.userLicense ?
            this.userLicense.Features.indexOf(UserLicenseFeatures.Trial) > -1 :
            false;
    }

    public isTrialExpired() {
        return this.userLicense ?
            this.userLicense.Features.indexOf(UserLicenseFeatures.TrialExpired) > -1 :
            false;
    }

    public isAdvanced() {
        return this.userLicense ?
            this.userLicense.Features.indexOf(UserLicenseFeatures.Advnc) > -1 :
            false;
    }

    public isHandrail() {
        const result = this.userLicense ?
            this.userLicense.Features.indexOf(UserLicenseFeatures.Hrail) > -1 :
            false;

        return result;
    }

    public isBasic() {
        const result = this.userLicense ?
            this.userLicense.Features.indexOf(UserLicenseFeatures.Basic) > -1 :
            false;

        return result;
    }

    public isFree() {
        const result = this.userLicense ?
            this.userLicense.Features.indexOf(UserLicenseFeatures.Free) > -1 ||
            this.userLicense.Features.indexOf(UserLicenseFeatures.NoLicense) > -1 ||
            this.userLicense.Features.indexOf(UserLicenseFeatures.LicenseParsingFailed) > -1 :
            false;

        return result;
    }

    public isOnlyBasic() {
        const result = this.userLicense ?
            !this.userLicense.Features.some(l => l != UserLicenseFeatures.Basic) :
            false;

        return result;
    }

    public isStandardLicense() {
        const result =
            (this.isFree() || this.isDesktop()) &&
            !this.isHandrail() &&
            !this.isAdvanced();

        return result;
    }

    public isDesktop() {
        return this.userLicense.Features.indexOf(UserLicenseFeatures.Desktop) > -1
    }

    public getRemainingDays(expiresAt?: Date): number {
        if (!this.userLicense) {
            return 0;
        }

        if (expiresAt == null && this.isTrial()) {
            expiresAt = this.userLicense.ExpiresAt;
        }

        const remaningMillisends = new Date(expiresAt).valueOf() - new Date().valueOf();

        if (remaningMillisends <= 0) {
            return 0;
        }

        // how to correctly determine remaining days (wrt daylight saving changes, leap seconds, etc)
        return Math.ceil(remaningMillisends / (24 * 60 * 60 * 1000));
    }

    public licenseSuiteItemCode(licenseSuites: LicenseSuite[], suiteType: LicenseSuiteType) {
        return licenseSuites.find(suite => suite.id == suiteType).itemCode;
    }

    public isNoLicenseHandledAsFree() {
        return this.userLicense.Features.indexOf(UserLicenseFeatures.NoLicense) > -1
    }

    public isLicenseParsingFailed() {
        return this.userLicense.Features.indexOf(UserLicenseFeatures.LicenseParsingFailed) > -1
    }

    // Used same logic as in tracking
    public getLicense() {
        if (this.userLicense == null
            || this.userLicense.Features.length == 0
            || this.userLicense.Features.some(l => l == UserLicenseFeatures.None)) {
            return 'NOLICENSE';
        }

        if (this.userLicense.Features.indexOf(UserLicenseFeatures.Advnc) > -1
            || this.userLicense.Features.indexOf(UserLicenseFeatures.Hrail) > -1
            || this.userLicense.Features.indexOf(UserLicenseFeatures.Basic) > -1) {
            return 'PREMIUM';
        }

        if (this.userLicense.Features.indexOf(UserLicenseFeatures.Trial) > -1) {
            return 'TRIAL';
        }

        if (this.userLicense.Features.indexOf(UserLicenseFeatures.Desktop) > -1) {
            return 'DESKTOP';
        }

        return 'STANDARD';
    }
}
