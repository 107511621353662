<pe-modal-header
    [modalInstance]="modalInstance"
    [title]="titleKey | l10n:localization.selectedLanguage">
</pe-modal-header>

<form class="form" name="form" #ngForm="ngForm" (ngSubmit)="save(ngForm)" novalidate>
    <pe-modal-body>
        <div class="section type-general">
            <div class="two-rows">

                <pe-text-box
                    class="name-text-box"
                    [id]="nameTextBox.id"
                    [look]="nameTextBox.look"
                    [title]="nameTextBox.title"
                    [validators]="nameTextBox.validators"
                    [maxLength]="nameTextBox.maxLength"
                    [value]="nameTextBox.value"
                    (valueChange)="nameTextBox.value = $any($event).detail"
                    [validationData]="nameTextBox.validationData"
                    (enterPressed)="save(ngForm)"
                    (isValid)="nameTextBox.isValid = $any($event).detail"
                    [disabled]="submitted">
                </pe-text-box>

                <pe-dropdown
                    class="project-dropdown control-row"
                    *ngIf="projectDropdownVisible && projectDropdown.selectedValue.id != newProjectId"
                    [id]="projectDropdown.id"
                    [title]="projectDropdown.title"
                    [notSelectedText]="projectDropdown.notSelectedText"
                    [look]="projectDropdown.look"
                    [items]="projectDropdown.items"
                    [selectedValue]="projectDropdown.selectedValue"
                    (selectedValueChange)="onProjectDropdownSelectedValueChange($any($event).detail)"
                    [validators]="projectDropdown.validators"
                    [validationData]="projectDropdown.validationData"
                    (isValid)="projectDropdown.isValid = $any($event).detail"
                    [disabled]="submitted"
                    [submitted]="submitted">
                </pe-dropdown>

                <div class="new-project-container control-row"
                    *ngIf="projectDropdown.selectedValue.id == newProjectId">
                    <div class="input-container">
                        <pe-text-box
                            class="project-name-text-box"
                            [title]="newProjectTextBox.title"
                            [maxLength]="newProjectTextBox.maxLength"
                            [validators]="newProjectTextBox.validators"
                            [value]="newProjectTextBox.value"
                            (valueChange)="newProjectTextBox.value = $any($event).detail"
                            [disabled]="submitted">
                        </pe-text-box>

                        <button type="button" class="cancel-button" (click)="newProjectCancel()"
                            [disabled]="submitted">
                            <span class="image sprite sprite-x-circle"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </pe-modal-body>

    <pe-modal-footer>
        <pe-button
            [id]="'copy-design-cancel-button'"
            class="modal-button"
            [look]="'Default'"
            type="button"
            [disabled]="submitted"
            (buttonClick)="close()"
            [text]="'Agito.Hilti.Profis3.CopyDesign.Cancel' | l10n:localization.selectedLanguage">
        </pe-button>

        <pe-button
            [id]="'copy-design-copy-button'"
            type="submit"
            class="modal-button"

            [look]="'Cta'"
            class="modal-button"
            [disabled]="!formValid || (ngForm.enabled && !ngForm.valid) || submitted">
            <pe-loading-button
                [loading]="submitted"
                [text]="copyButtonKey | l10n:localization.selectedLanguage">
            </pe-loading-button>
        </pe-button>
    </pe-modal-footer>
</form>
