<pe-modal-header
    [modalInstance]="modalInstance"
    [title]="titleKey | l10n:localization.selectedLanguage"
    [closeButtonId]="'save-as-template-close-button'">
</pe-modal-header>

<form class="form" name="form" #ngForm="ngForm" (ngSubmit)="save(ngForm)" novalidate>
    <pe-modal-body>
        <div class="section">
            <pe-text-box
                id="create-template-name-wrapper"
                [id]="'create-template-name'"
                [title]="'Agito.Hilti.Profis3.SaveAsTemplate.Name' | l10n:localization.selectedLanguage"
                [validators]="[requiredValidator]"
                [disabled]="submitted"
                [maxLength]="nameMaxLength"
                [value]="name"
                (valueChange)="name = $any($event).detail"
                (isValid)="nameValid = $any($event).detail"
                (enterPressed)="save(ngForm)">
            </pe-text-box>
        </div>
    </pe-modal-body>

    <pe-modal-footer>
        <pe-button
            [id]="'save-as-template-cancel-button'"
            class="modal-button"
            [look]="'Default'"
            type="button"
            [disabled]="submitted"
            (buttonClick)="close()"
            [text]="'Agito.Hilti.Profis3.SaveAsTemplate.Cancel' | l10n:localization.selectedLanguage">
        </pe-button>

        <pe-button
            [id]="'save-as-template-save-button'"
            type="submit"
            class="modal-button"

            [look]="'Cta'"
            class="modal-button"
            [disabled]="!formValid || (ngForm.enabled && !ngForm.valid) || submitted">
            <pe-loading-button
                [loading]="submitted"
                [text]="'Agito.Hilti.Profis3.SaveAsTemplate.Save' | l10n:localization.selectedLanguage">
            </pe-loading-button>
        </pe-button>
    </pe-modal-footer>
</form>
