import * as React from 'react';

import { TooltipType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { LocalizationService } from '../../../../services/localization.service';
import { ControlHeader } from '../../ControlHeader';
import { isControlHidden } from '../../MainMenuHelper';
import { ILabelProps, LabelRole } from '@profis-engineering/pe-ui-common/entities/main-menu/Label-props';

export class Label extends React.PureComponent<ILabelProps> {
    constructor(props?: ILabelProps) {
        super(props);

        this.onInfoClick = this.onInfoClick.bind(this);
    }

    public override render() {
        if (isControlHidden(this.props)) {
            return null;
        }

        const labelId = `${this.props.controlId}-text`;

        let text = Array.isArray(this.props.text) ? this.props.text : [this.props.text];
        text = text.filter(t => typeof t == 'string' && t != '');

        const noContent = text != null && text.length > 0 ? '' : 'no-content';
        const customClass = this.props.customClass != null ? this.props.customClass : '';

        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled ? this.props.tooltipDisabled : this.props.tooltip;

        const infoTooltipKey = 'Agito.Hilti.Profis3.ControlTooltip.Popup';

        return (
            <div data-control-id={this.props.controlId} className={`control react-label-control ${this.props.sizeClass} ${noContent} ${customClass}`} >
                <div style={{ paddingLeft: this.props.paddingLeft }}>
                    <ControlHeader
                        text={this.props.title}
                        controlId={labelId}
                        tooltip={tooltipText}
                        tooltipTitle={tooltipTitle}
                        localization={this.props.localization}
                        customClass={this.getStyleClass()}
                        infoClick={this.props.tooltipType == TooltipType.Popup ? this.onInfoClick : undefined}
                        infoTooltip={this.props.tooltipType == TooltipType.Popup ? infoTooltipKey : undefined}
                    />

                    {text.map((t, i) =>
                        <p
                            className='text-label'
                            key={i}
                            data-control-id={`${labelId}-${i}`}
                            dangerouslySetInnerHTML={{ __html: this.props.localization.sanitizeText(t, LocalizationService.SubSup) }} >
                        </p>
                    )}
                </div>
            </div>
        );
    }

    private getStyleClass() {
        switch (this.props.role) {
            case LabelRole.SubHeading:
                return 'role-sub-heading';
            case LabelRole.Regular:
                return 'text-label text-center';
            default:
                return '';
        }
    }

    private onInfoClick() {
        if (this.props.infoClicked != null) {
            this.props.infoClicked();
        }
    }
}
