import { IToggleButtonGroupItem, IToggleButtonGroupProps, ToggleButtonGroupType } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-button-group-props';
import { ToggleButtonRole } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-button-props';
import * as React from 'react';

import { ControlHeader } from '../../ControlHeader';
import { ControlType } from '../../ControlInfo';
import { buildHtmlTooltip, isControlHidden } from '../../MainMenuHelper';
import { ToggleButton } from '../ToggleButton/ToggleButton';

interface IToggleButtonGroupState {
    isCollapsed: boolean;
}

export class ToggleButtonGroup extends React.PureComponent<IToggleButtonGroupProps, IToggleButtonGroupState> {

    public static readonly itemsPerRow = 5;

    constructor(props?: IToggleButtonGroupProps) {
        super(props);

        this.onCollapseChange = this.onCollapseChange.bind(this);
        this.onActiveChange = this.onActiveChange.bind(this);
        this.onKBTooltipClick = this.onKBTooltipClick.bind(this);

        this.state = {
            isCollapsed: true
        };
    }

    private get collapsable() {
        return this.props.itemsCollapsed != null && this.props.itemsCollapsed.length > 0 && this.props.itemsCollapsed.length != this.props.items.length;
    }

    private get items() {
        return this.collapsable && this.state.isCollapsed ? this.props.itemsCollapsed : this.props.items;
    }

    public override render() {
        if (isControlHidden(this.props)) {
            return null;
        }

        const groupId = `${this.props.controlId}-group`;
        const hasDisabledChild = this.items.some((it) => it.disabled);
        const tooltip = ((this.props.title != null && this.props.title != '') || hasDisabledChild) ? '' : buildHtmlTooltip(this.props.tooltip, this.props.tooltipTitle);

        const display1 = hasDisabledChild ? 'hidden' : '';
        const display2 = !hasDisabledChild ? 'hidden' : '';
        const roleClass = this.props.role == ToggleButtonRole.Tab ? 'role-tab' : '';

        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled ? this.props.tooltipDisabled : this.props.tooltip;

        return (
            <div data-control-id={this.props.controlId} className={`control react-toggle-button-group ${this.props.sizeClass}`} >
                <ControlHeader
                    text={this.props.title}
                    controlId={groupId}
                    tooltip={tooltipText}
                    tooltipTitle={tooltipTitle}
                    localization={this.props.localization}
                />

                <div
                    className={`toggle-buttons join ${display1} ${roleClass}`}
                    data-control-id={groupId}
                    data-tip={tooltip}
                    data-html={tooltip != null ? true : null}>
                    {
                        this.items.map((item) => <ToggleButton
                            controlId={`${this.props.controlId}-${item.value}`}
                            key={`${this.props.controlId}-${item.value}`}
                            value={item.value}
                            text={this.props.hideChildDisplayText ? null : item.text}
                            image={item.image}
                            imageStyle={item.imageStyle}
                            active={this.isItemActive(item)}
                            selectable={item.selectable}
                            buttonType={item.buttonType}
                            disabled={item.disabled || this.props.disabled}
                            hidden={item.hidden || this.props.hidden}
                            localization={this.props.localization}
                            size={null}
                            title={null}
                            type={ToggleButton}
                            clicked={item.clicked}
                            activeChanged={this.onActiveChange}
                            tooltipDisabled={item.tooltipDisabled}
                            tooltipDisabledTitle={item.tooltipDisabledTitle}
                            tooltip={item.tooltip}
                            tooltipTitle={item.tooltipTitle}
                            sizeClass={this.props.sizeClass}
                            role={this.props.role}
                        />)
                    }
                    {this.props.kbLink == null ? null :
                        <button
                            type='button'
                            className='control-tooltip-popup sprite sprite-info-tooltip kb-tooltip'
                            onClick={this.onKBTooltipClick}
                            data-tip={this.props.localization.getString(this.props.kbTooltip)}>
                        </button>
                    }
                </div>
                <div
                    className={'toggle-buttons join ' + display2}
                    data-control-id={groupId + 'd'}>
                    {
                        this.items.map((item) => <ToggleButton
                            controlId={`${this.props.controlId}-${item.value}`}
                            key={`${this.props.controlId}-${item.value}`}
                            value={item.value}
                            text={this.props.hideChildDisplayText ? null : item.text}
                            image={item.image}
                            imageStyle={item.imageStyle}
                            active={this.isItemActive(item)}
                            selectable={item.selectable}
                            buttonType={item.buttonType}
                            disabled={item.disabled || this.props.disabled}
                            hidden={item.hidden || this.props.hidden}
                            localization={this.props.localization}
                            size={null}
                            title={null}
                            type={ToggleButton}
                            clicked={item.clicked}
                            activeChanged={this.onActiveChange}
                            tooltipDisabled={item.tooltipDisabled}
                            tooltipDisabledTitle={item.tooltipDisabledTitle}
                            tooltip={item.tooltip || (hasDisabledChild ? this.props.tooltip : '')}
                            tooltipTitle={item.tooltipTitle || (hasDisabledChild ? this.props.tooltipTitle : '')}
                        />)
                    }
                </div>

                {this.collapsable ? <ToggleButtonGroupCollapse collapsed={this.state.isCollapsed} collapseChanged={this.onCollapseChange} /> : null}
                {this.props.role == ToggleButtonRole.Tab ? <div className="tab-border" /> : null}
            </div>
        );
    }

    private onCollapseChange(collapsed: boolean) {
        this.setState({
            isCollapsed: collapsed
        });
    }

    private onActiveChange(active: boolean, value: number) {
        if (this.props.valueChanged != null) {
            if (this.props.toggleType == ToggleButtonGroupType.single) {
                // should work like radio button group
                if (active) {
                    this.props.valueChanged(value);
                }
                // also if not unselectable then allow null
                else if (!this.props.unselectable) {
                    this.props.valueChanged(null);
                }
            }
            else {
                const activeValue = this.props.activeValue as number[];

                // should work like checkbox group
                if (active && !activeValue.includes(value)) {
                    this.props.valueChanged([...activeValue, value]);
                }
                else if (!active && activeValue.includes(value)) {
                    // only unselect if not unselectable or if another value is already selected
                    if (!this.props.unselectable || activeValue.length > 1) {
                        this.props.valueChanged(activeValue.slice(activeValue.indexOf(value), 1));
                    }
                }
            }
        }
    }

    private isItemActive(item: IToggleButtonGroupItem) {
        if (this.props.toggleType == ToggleButtonGroupType.single) {
            // should work like radio button group
            return item.value == this.props.activeValue;
        }

        // should work like checkbox group
        return (this.props.activeValue as number[]).includes(item.value);
    }

    private onKBTooltipClick(event: React.MouseEvent) {
        window.open(this.props.kbLink, '_blank');
    }
}

ControlType.ToggleButtonGroup = ToggleButtonGroup;

interface IToggleButtonGroupCollapseProps {
    collapsed: boolean;
    collapseChanged: (collapsed: boolean) => void;
}

class ToggleButtonGroupCollapse extends React.PureComponent<IToggleButtonGroupCollapseProps> {
    constructor(props?: IToggleButtonGroupCollapseProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    public override render() {
        const openedClass = !this.props.collapsed ? 'opened' : '';

        return (
            <div className={`design-right-collapse-container ${openedClass}`}>
                <span className='design-right-collapse-line'></span>
                <button type='button' className='design-right-collapse-button' onClick={this.onClick}>
                    <span className='sprite design-right-collapse-image'></span>
                </button>
                <span className='design-right-collapse-line'></span>
            </div>
        );
    }

    private onClick(event: React.MouseEvent) {
        if (this.props.collapseChanged != null) {
            this.props.collapseChanged(!this.props.collapsed);
        }
    }
}
