import sortBy from 'lodash-es/sortBy';

import { Component, ElementRef, OnInit } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    TextBoxLook, TextBoxProps
} from '@profis-engineering/pe-ui-common/components/text-box/text-box.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { DisplayDesignType, IDisplayDesign } from '@profis-engineering/pe-ui-common/entities/display-design';
import { Project } from '../../entities/project';
import { DocumentService } from '../../services/document.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { OfflineService } from '../../services/offline.service';
import { designNameValidator } from '../../validators/design-name.validator';
import { ModalDialogType } from '../add-edit-design/add-edit-design-models';
import { DesignTypeId, ModulesService } from '../../services/modules.service';
import { UserService } from '../../services/user.service';
import { FeatureVisibilityService } from '../../services/feature-visibility.service';


interface ICopyDesignComponentInput {
    design: IDisplayDesign;
    onDesignCopied?: (project: Project) => void;
    onOffline?: (name: string, designId: string) => Promise<void>;
}

const NewProjectId = '0';

@Component({
    selector: 'app-copy-design',
    templateUrl: './copy-design.component.html',
    styleUrls: ['./copy-design.component.scss']
})
export class CopyDesignComponent implements OnInit {
    public submitted: boolean;

    public nameTextBox: TextBoxProps = {};
    public projectDropdown: DropdownProps<Project>;
    public newProjectTextBox: TextBoxProps;
    public duplicating: boolean;

    public newProjectId = NewProjectId;

    private pendingSave: boolean;

    constructor(
        public localization: LocalizationService,
        public modalInstance: ModalInstance<ICopyDesignComponentInput>,
        public user: UserService,
        private featureVisibilityService: FeatureVisibilityService,
        private documentService: DocumentService,
        private modal: ModalService,
        private offline: OfflineService,
        private elementRef: ElementRef<HTMLElement>,
        private modulesService: ModulesService,
    ) { }

    public get projectDropdownVisible() {
        return !this.offline.isOffline
            && this.design?.displayDesignType != DisplayDesignType.template;
    }

    private get design() {
        return this.modalInstance.input?.design;
    }

    private get project() {
        return this.documentService.findProjectById(this.design.projectId);
    }

    private get onDesignCopied() {
        return this.modalInstance.input?.onDesignCopied;
    }

    private get onOffline() {
        return this.modalInstance.input?.onOffline;
    }

    private get isCompanyProject(){
        return this.design !=null && this.documentService.findProjectById(this.modalInstance?.input?.design?.projectId)?.isCompanyProject;
    }

    public get companyProjectDisabled() {
        return this.user.hasFreeLicense || this.user.hasfloatingLimitReached;
    }

    public get getCompanyDisabledTooltip() {
        if (!this.companyProjectDisabled) {
            return null;
        }

        return this.localization.getString('Agito.Hilti.Profis3.Convert.Disabled.ToolTip');
    }

    public get titleKey() {
        return this.isNewHomePage ? 'Agito.Hilti.Profis3.DuplicateDesign.Title' : 'Agito.Hilti.Profis3.CopyDesign.Title';
    }

    public get copyButtonKey(){
        return this.isNewHomePage ? 'Agito.Hilti.Profis3.Main.DuplicateDesign' : 'Agito.Hilti.Profis3.CopyDesign.Copy';
    }
    private get isNewHomePage() {
        return this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage');
    }
    ngOnInit(): void {
        // don't close the modal if save is pending
        this.modalInstance.setOnClosing(() => {
            if (this.pendingSave && this.duplicating) {
                return false;
            }

            return true;
        });

        const oldProject = this.project;
        const projectItems = !this.isCompanyProject ? this.getProjectDropdownItemsNewHomePage() : this.getCompanyProjectDropdownItemsNewHomePage();
        const selectedProjectV2 = projectItems.find(x => x.value.id == this.project?.id)?.value || this.documentService.draftsProject;

        this.nameTextBox = {
            id: 'copy-design-name',
            look: TextBoxLook.Large,
            title: this.localization.getString('Agito.Hilti.Profis3.CopyDesign.DesignLabel.Text'),
            maxLength: 250,
            // validators will be added in onProjectDropdownSelectedValueChange!
            validationData: {
                showValidationErrors: true
            },
            value: `${this.localization.getString('Agito.Hilti.Profis3.CopyDesign.Design.Copy')} - ${this.design.name}`
        };

        this.projectDropdown = {
            id: 'copy-design-project-dropdown',
            title: this.localization.getString('Agito.Hilti.Profis3.CopyDesign.Project'),
            validators: [Validators.required],
            items: [
                {
                    value: new Project({ id: NewProjectId }),
                    text: this.localization.getString('Agito.Hilti.Profis3.CopyDesign.NewProject'),
                    isSpecial: true
                },
                {
                    value: this.documentService.draftsProject,
                    text: this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Designs.Drafts')
                },
                ...this.isNewHomePage ? projectItems :this.getProjectDropdownItems()
            ],
            selectedValue: this.isNewHomePage ? selectedProjectV2 : oldProject
        };

        if(this.isNewHomePage && this.isCompanyProject){
            this.projectDropdown = {
                id: 'copy-design-project-dropdown',
                title: this.localization.getString('Agito.Hilti.Profis3.HomePage.CompanyProjectFolder'),
                validators: [Validators.required],
                items: [
                    {
                        value: new Project({ id: NewProjectId }),
                        text: this.localization.getString('Agito.Hilti.Profis3.HomePage.CompanyProjectFolder.New'),
                        isSpecial: true,
                        disabled: this.companyProjectDisabled,
                        tooltip: this.getCompanyDisabledTooltip
                    },
                    ...projectItems
                ],
                selectedValue: selectedProjectV2
            };
        }

        if(!this.isNewHomePage){
            this.onProjectDropdownSelectedValueChange(oldProject);
        } else {
            this.onProjectDropdownSelectedValueChange(selectedProjectV2);
        }

        this.newProjectTextBox = {
            title: this.localization.getString('Agito.Hilti.Profis3.CopyDesign.Project'),
            maxLength: 250,
            validators: [Validators.required]
        };

        if(this.isNewHomePage && this.isCompanyProject){
            this.newProjectTextBox = {
                title: this.localization.getString('Agito.Hilti.Profis3.HomePage.CompanyProjectFolder'),
                maxLength: 250,
                validators: [Validators.required]
            };
        }

        setTimeout(() => {
            (this.elementRef.nativeElement.querySelector(".name-text-box").shadowRoot?.querySelector('#copy-design-name') as unknown as HTMLInputElement).focus();
        });
    }

    public onProjectDropdownSelectedValueChange(project: Project) {
        const oldProject = this.projectDropdown.selectedValue;
        this.projectDropdown.selectedValue = project;

        if (project != null && oldProject != null) {
            this.nameTextBox.validators = this.getNameTextBoxValidators(project);

            if (project.id == NewProjectId && oldProject.id != NewProjectId) {
                setTimeout(() =>
                (this.elementRef.nativeElement.querySelector(".project-name-text-box").shadowRoot?.querySelector('input') as unknown as HTMLInputElement).focus());
            }
        }
    }

    public newProjectCancel() {
        this.onProjectDropdownSelectedValueChange(this.documentService.draftsProject);
        this.newProjectTextBox.value = null;

        setTimeout(() => (this.elementRef.nativeElement.querySelector(".project-dropdown").shadowRoot?.querySelector('.dropdown-button') as unknown as HTMLInputElement).focus());
    }

    public get formValid() {
        return this.nameTextBox?.isValid;
    }

    public async save(form: NgForm) {
        if (this.submitted || !this.formValid || (form.enabled && !form.valid) || this.pendingSave) {
            return;
        }

        this.submitted = true;
        this.pendingSave = true;

        try {
            const project = await this.getSelectedProject();
            if (this.duplicating || !project) {
                return;
            }
            this.duplicating = true;

            if (this.design.designType === DesignTypeId.DiaphragmDesign) {
                const documentDesign = this.documentService.findDesignById(this.design.id);
                const designsInfo = this.modulesService.getDesignListInfoByDesignType(documentDesign.metaData.designType);
                if (designsInfo?.copyDesign != null) {
                    await designsInfo.copyDesign(this.design.id, this.nameTextBox.value, project.id);
                }
                this.onDesignCopied?.(project);
            }
            else if (this.offline.isOffline && this.onOffline) {
                await this.onOffline(this.nameTextBox.value, this.design.id);
            }
            else {
                await this.documentService.copyDesign(this.design.id, this.nameTextBox.value, project.id);
                this.onDesignCopied?.(project);
            }
            this.duplicating = false;
            this.pendingSave = false;
            this.close();
        } catch (error) {
            if (error instanceof Error) {
                console.error(error);
            }
            this.pendingSave = false;
            this.submitted = false;
        }
    }
    private getProjectDropdownItemsNewHomePage() {
        return this.documentService.getProjectDropdownItemsNewHomePage();
    }

    private getCompanyProjectDropdownItemsNewHomePage() {
        return this.documentService.getProjectDropdownItemsNewHomePage(true);
    }

    private async getSelectedProject(): Promise<Project | undefined> {
        if (this.projectDropdown.selectedValue?.id != NewProjectId) {
            return this.projectDropdown.selectedValue;
        }

        // New project
        const newProject = new Project({
            name: this.newProjectTextBox.value.trim(),
            owner: true,
            isCompanyProject: this.isCompanyProject
        });

        if (this.documentService.projectNameExists(newProject.name, null)) {
            this.modal.openConfirmChange({
                id: 'confirm-project-rename',
                title: this.localization.getString('Agito.Hilti.Profis3.Naming.ProjectNameExists.Title'),
                message: this.localization.getString('Agito.Hilti.Profis3.Naming.ProjectNameExists.Message'),
                confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Naming.ProjectNameExists.Confirm'),
                onConfirm: (modal) => {
                    modal.close();
                }
            });

            this.submitted = false;
            this.pendingSave = false;

            return undefined;
        }

        await this.documentService.saveProject(newProject, ModalDialogType.project);
        return newProject;
    }

    public close() {
        this.modalInstance.close();
    }

    private getNameTextBoxValidators(project: Project) {
        const retVal = [Validators.required];

        if (!this.offline.isOffline) {
            retVal.push(
                designNameValidator(
                    project,
                    this.project,
                    null,
                    this.documentService,
                    this.localization.getString('Agito.Hilti.Profis3.Naming.DesignNameExists.Message'),
                    this.isNewHomePage
                )
            );
        }

        return retVal;
    }

    private getProjectDropdownItems() {
        const items: DropdownItem<Project>[] = [];

        for (const project of sortBy(this.documentService.projects, (project) => (project.name || '').toLowerCase())) {
            if (project.id != this.documentService.draftsProject.id) {
                items.push({
                    value: project,
                    text: project.name,
                    disabled: project.readOnly
                });

                for (const subProject of sortBy(project.subProjects, (subProject) => (subProject.name || '').toLowerCase())) {
                    items.push({
                        value: subProject,
                        text: subProject.name,
                        level: 1
                    });
                }
            }
        }

        return items;
    }
}
