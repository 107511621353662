import { Injectable } from '@angular/core';
import {
    SessionStorageServiceBase
} from '@profis-engineering/pe-ui-common/services/session-storage.common';

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService extends SessionStorageServiceBase {
    get<T>(key: string) {
        return JSON.parse(sessionStorage.getItem(key)) as T;
    }

    set<T>(key: string, value: T) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    remove(key: string) {
        sessionStorage.removeItem(key);
    }
}
