import sortBy from 'lodash-es/sortBy';
import { Subscription } from 'rxjs';

import { Component, Input, OnDestroy, OnInit, TrackByFunction } from '@angular/core';
import { CodeList } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    IQuickStartApplication
} from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import {
    RegionHub
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    DocumentModel
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Document';
import {
    DocumentIntegrationType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Enums';
import {
    SafeFunctionInvokerHelper
} from '@profis-engineering/pe-ui-common/helpers/safe-function-invoker-helper';
import { format, stringNullOrEmpty } from '@profis-engineering/pe-ui-common/helpers/string-helper';

import { environment } from '../../../../environments/environment';
import { CollapsingControls } from '../../../entities/collapsing-controls';
import { Design } from '../../../entities/design';
import { Project } from '../../../entities/project';
import { CommonTrackingService } from '../../../services/common-tracking.service';
import { DocumentService } from '../../../services/document.service';
import { ExternalAppsService } from '../../../services/external-apps.service';
import { FeatureVisibilityService } from '../../../services/feature-visibility.service';
import { LocalizationService } from '../../../services/localization.service';
import { ModalService } from '../../../services/modal.service';
import { ModulesService } from '../../../services/modules.service';
import { OfflineService } from '../../../services/offline.service';
import { PendingActionService } from '../../../services/pending-action.service';
import { ProductInformationService } from '../../../services/product-information.service';
import { RoutingService } from '../../../services/routing.service';
import { TourService } from '../../../services/tour.service';
import { UserSettingsService } from '../../../services/user-settings.service';
import { UserService } from '../../../services/user.service';
import {
    IDisplayProject, ISectionCollapsed, ISectionsCollapsed, PendingAction
} from '../home-page.common';

@Component({
  selector: 'app-quick-start',
  templateUrl: './quick-start.component.html',
  styleUrls: ['./quick-start.component.scss']
})
export class QuickStartComponent implements OnInit, OnDestroy {

  @Input()
  public selectedProject: IDisplayProject;

  @Input()
  public virtaulTourInProgress = false;

  public quickStartApplications: IQuickStartApplication[] = [];
  private quickStartModulesAddedSubscription: Subscription;
  private designListInfoProvidedSubscription: Subscription;
  private userSettingsSavedChangeSubscription: Subscription;

  public pendingActionQuickStart: string;
  public SafeFunctionInvokerHelper = SafeFunctionInvokerHelper;
  public pendingAction: PendingAction;
  public PendingAction = PendingAction;
  public pendingActionSubscription: Subscription;
  public readonly loaderColour = '#524f53';
  public isWoodModuleShown = false;

  public sectionsCollapsed: ISectionsCollapsed = {
    quickStart: {
      collapsed: false,
      control: CollapsingControls.QuickStart
    }
  };

  constructor(
    private routingService: RoutingService,
    private localizationService: LocalizationService,
    private offlineService: OfflineService,
    private modulesService: ModulesService,
    private tourService: TourService,
    private documentService: DocumentService,
    private userService: UserService,
    private modalService: ModalService,
    private featureVisibilityService: FeatureVisibilityService,
    public userSettingsService: UserSettingsService,
    private productInformationService: ProductInformationService,
    private commonTrackingService: CommonTrackingService,
    public externalAppsService: ExternalAppsService,
    private pendingActionService: PendingActionService
  ) { }

  ngOnInit(): void {
    const region = this.userSettingsService.getCommonRegionById(this.userSettingsService.settings.application.general.regionId.value);
    this.isWoodModuleShown = environment?.woodModuleEnabled && ((environment?.woodModuleAvailableCountries ?? []).includes(region.id)
            ? this.featureVisibilityService.isFeatureEnabled('C2C_WoodModuleESPT')
            : region.hubId != RegionHub.E2 || region.hubId == RegionHub.E2 && this.featureVisibilityService.isFeatureEnabled('C2C_WoodModuleE2'));

    this.pendingActionSubscription = this.pendingActionService.pendingActions?.subscribe(value => {
      this.pendingAction = value;
    });
    this.reloadQuickStartApplications();
    this.regionChanged = this.regionChanged.bind(this);
    this.quickStartModulesAddedSubscription = this.modulesService.quickStartModulesAdded.subscribe(() => this.reloadQuickStartApplications());
    this.userSettingsSavedChangeSubscription = this.userSettingsService.userSettingsSaved.subscribe(this.regionChanged);
    this.sectionsCollapsed.quickStart.collapsed = this.userSettingsService.isSectionCollapsed(this.sectionsCollapsed.quickStart.control);
  }

  public get collapsed() {
    // When virtual tour is in progress, quick start section should't be collapsed.
    return this.sectionsCollapsed.quickStart?.collapsed || this.virtaulTourInProgress;
  }
  /* #region Private Methods */

  private reloadQuickStartApplications() {
    this.quickStartApplications = sortBy(this.modulesService.getQuickStartApplications(), p => p.order);
  }

  private clearPendingAction() {
    this.pendingActionQuickStart = null;
    this.pendingActionService.setPendingAction(null);
  }

  private async newDesignFromModuleQuickStart(quickStartApplication: IQuickStartApplication, designName: string, project: Project, integrationDocument: DocumentModel) {
    this.pendingActionQuickStart = quickStartApplication.idQuickStart;
    this.pendingActionService.setPendingAction(PendingAction.createQuickStartDesign);
    try {
      const saveResultData = await quickStartApplication.createNewDesign({ designName, projectName: project.name, projectId: project.id, integrationDocument });
      this.userService.changeDesign(project, saveResultData.design as Design);
      this.routingService.navigateToUrl(`${saveResultData.path}${saveResultData.designId}`);
    }
    catch {
      /* Nothing to do. */
    }
    finally {
      this.clearPendingAction();
    }
  }

  private async handleProjectDesignName(name: string, project: Project, integrationDocument: DocumentModel): Promise<string> {
    if (integrationDocument && integrationDocument.IntegrationType == DocumentIntegrationType.Risa) {
      name = integrationDocument.DocumentName;
      if (Object.values(project.designs).some(x => x.designName == integrationDocument.DocumentName)) {
        await this.modalService.openConfirmChange({
          id: 'change-risa-design-name',
          title: this.localizationService.getString('Agito.Hilti.Profis3.Integrations.DesignNameExists.Title'),
          message: format(this.localizationService.getString('Agito.Hilti.Profis3.Integrations.DesignNameExists.Message'), integrationDocument.DocumentName),
          confirmButtonText: this.localizationService.getString('Agito.Hilti.Profis3.Integrations.DesignNameExists.Button.Content.ReplaceExisting'),
          cancelButtonText: this.localizationService.getString('Agito.Hilti.Profis3.Integrations.DesignNameExists.Button.Content.KeepBoth'),
          onConfirm: async (modal) => {
            //const previousDesign = Object.values(project.designs).filter(x => x.designName == integrationDocument.DocumentName)[0];
            try {
              //this.toDisplayDesign(previousDesign as IDesignListItem)
            }
            finally {
              modal.close();
            }
          },
          onCancel: (modal) => {
            modal.close();
          }
        }).closed;
      }
    }
    return name;
  }
  /* #endregion Private Methods */

  public onSectionCollapsedChange(section: ISectionCollapsed, collapsed: boolean) {
    section.collapsed = collapsed;
    this.userSettingsService.setSectionCollapsed(section.control, collapsed);
  }

  public ngOnDestroy(): void {
    this.quickStartModulesAddedSubscription?.unsubscribe();
    this.designListInfoProvidedSubscription?.unsubscribe();
    this.pendingActionSubscription?.unsubscribe();
    this.userSettingsSavedChangeSubscription?.unsubscribe();
  }

  /* #region Getters / Setters */

  public get woodLinks() {
    const woodLinks = this.productInformationService.woodLinks?.filter(x => x.RegionId == this.userSettingsService.settings.application.general.regionId.value) ?? [];
    const woodLinksFiltered = woodLinks.filter(x => x.LanguageId == this.userSettingsService.settings.application.general.languageId.value);

    if (!woodLinksFiltered.length) {
      return woodLinks.filter(x => x.LanguageId == 0);
    }

    return woodLinksFiltered;
  }

  /* #endregion Getters / Setters */

  /* #region QuickStart */

  public newDesignFromQuickStart(quickStartApplication: IQuickStartApplication) {
    if (SafeFunctionInvokerHelper.safeInvoke(quickStartApplication.isDesignTypeDisabled, true)) {
      return;
    }
    this.tourService.onNewDesignFromQuickStart();
    return this.newDesign(quickStartApplication);
  }

  public newDesign(quickStartApplication: IQuickStartApplication, integrationDocument?: DocumentModel) {

    if (this.pendingAction != null) {
      return;
    }
    const projectId = (!integrationDocument && this.selectedProject) ? this.selectedProject.id : this.documentService.draftsProject.id;
    const project = this.documentService.findProjectById(projectId);
    let name: string;
    if (quickStartApplication?.getNewDesignName != null) {
        try {
            name = quickStartApplication.getNewDesignName();
        }
        catch (err) {
            console.error(err);
            this.modalService.openAlertWarning(
                this.translate('Agito.Hilti.Profis3.ProjectAndDesign.Alerts.CannotCreateNewDesign.Title'),
                this.translate('Agito.Hilti.Profis3.ProjectAndDesign.Alerts.CannotCreateNewDesign.Description')
            );
            return;
        }

    }
    this.handleProjectDesignName(name, project, integrationDocument)
      .then(async (newName) => {
        if (newName == null) {
          return;
        }
        name = newName;
        if (project != null) {
          const projectDesigns = this.documentService.findAllDesignsByProject(project);
          if (projectDesigns) {
            name = this.documentService.createUniqueName(name, Object.values(projectDesigns).map((item) => item.designName));
          }
        }
        if (quickStartApplication?.createNewDesign != null) {
          await this.newDesignFromModuleQuickStart(quickStartApplication, name, project, integrationDocument);
        }
      });
  }


  public translate(key: string) {
    return this.localizationService.getString(key);
  }

  public showLoading(quickStartApp: IQuickStartApplication) {
    return this.pendingAction == PendingAction.createQuickStartDesign && this.pendingActionQuickStart && this.pendingActionQuickStart == quickStartApp.idQuickStart;
  }

  public get isActionDisabled() {
    const allowedPendingAction = [PendingAction.addToFavorite, PendingAction.removeFromFavorite];
    return this.pendingAction && !allowedPendingAction.includes(this.pendingAction);
  }

  /* #endregion QuickStart */

  /* #region ExternalApps */

  public openExternalAppLink(id: number) {
    const app = this.externalAppsService.getExternalApp(id);
    const url = this.externalAppsService.getExternalAppLink(app) ?? '';
    if (stringNullOrEmpty(url)) {
      return;
    }

    this.commonTrackingService.trackUsageFromUI(app.TrackingId);
    this.offlineService.nativeExternalLinkOpen(url);
  }

  public trackApplicationById: TrackByFunction<CodeList> = (_: number, application: CodeList) => {
    return application.id;
  }


  /* #endregion WoodModule */


  /* #region WoodModule */

  public openWoodLinks(link: string) {
    this.offlineService.nativeExternalLinkOpen(link);
    this.commonTrackingService.trackUsageFromUI('Wood');
  }

  private regionChanged() {
    const region = this.userSettingsService.getCommonRegionById(this.userSettingsService.settings.application.general.regionId.value);
    this.isWoodModuleShown = environment?.woodModuleEnabled && ((environment?.woodModuleAvailableCountries ?? []).includes(region.id)
    ? this.featureVisibilityService.isFeatureEnabled('C2C_WoodModuleESPT')
    : region.hubId != RegionHub.E2 || region.hubId == RegionHub.E2 && this.featureVisibilityService.isFeatureEnabled('C2C_WoodModuleE2'));
  }

  /* #endregion WoodModule */

}
