import {
    EnvironmentHelper
} from '@profis-engineering/pe-ui-common/helpers/environment-helper';
import {
    QueryStringHelper
} from '@profis-engineering/pe-ui-common/helpers/query-string-helper';
import { FeatureFlag } from '../app/entities/feature-flags';
import type schema from './environment.local.json';

type EnvironmentBase = Omit<typeof schema, 'featureFlagOverrides'>;

export type Environment = EnvironmentBase & {
    authenticationRequired: string[];
    useDevFeatures: boolean;
    /**
     * @deprecated used only for backward compatibility, removed with C2C-10693
     */
    asadQuery: boolean;
    translate: boolean;
    baseUrl: string;
    debugGlModel: boolean;
    featureFlagOverrides?: Partial<Record<FeatureFlag, unknown>>;


    /**
     * @deprecated use featureVisibilityService.isFeatureEnabled
     */
    c2cDemoFeatures: boolean;
    /**
     * @deprecated use (window as any).environmentC2C?.c2cForceAvailableCountriesEnabled
     */
    c2cForceAvailableCountriesEnabled: boolean;
    /**
     * @deprecated use (window as any).environmentC2C?.c2cForceAvailableCountriesPir
     */
    c2cForceAvailableCountriesPir: number[];
    /**
     * @deprecated use (window as any).environmentC2C?.c2cForceAvailableCountries
     */
    c2cForceAvailableCountries: number[];
    /**
     * @deprecated use (window as any).environmentC2C?.c2cForceAvailableConnections
     */
    c2cForceAvailableConnections: number[];
};

export const environment: Environment = getEnvironmentSettings();

const qsHelper = new QueryStringHelper(window.location.search);

// setBaseUrl
environment.baseUrl = document.getElementsByTagName('base')[0].href;

// setTranslate
environment.translate = qsHelper.getBoolean('translate');

// setAuthenticationRequired
environment.authenticationRequired = [
    environment.translationsWebServiceUrl,
    environment.documentWebServiceUrl,
    environment.documentWebServiceRootUrl,
    environment.userSettingsWebServiceUrl,
    environment.trimbleConnectWebServiceUrl,
    environment.npsSurveyWebServiceUrl,
    environment.integrationServicesServerUrl,
    environment.integrationServicesServerWebSocketsUrl,
    environment.supportServiceUrl,
    environment.productInformationServiceUrl,
    environment.peCommonServiceUrl,
    environment.signalRCoreInitSessionUrl,
    environment.peTrackingServiceUrl,
    environment.deckingUserSettingsServiceUrl,
    environment.deckingDesignServiceUrl,
    environment.deckingDesignServiceSignalRUrl,
    environment.deckingCalculationServiceUrl,
    environment.deckingReportProcessorServiceUrl,
    environment.deckingReportProcessorServiceSignalRUrl,
    environment.deckingTrackingServiceUrl,
    environment.translationsWebServiceRootUrl,
    environment.supportServiceRootUrl,
    environment.productInformationServiceUrl,
    environment.productInformationServiceRootUrl,
    environment.userSettingsWebServiceRootUrl,
    environment.npsSurveyWebServiceRootUrl,
    environment.checkBotIntegrationServiceUrl,
    // MODULARIZATION - remove once initial data is loaded in module and not in data.service.
    environment.baseplateApplicationWebServiceUrl,
    environment.c2cCodeListServiceUrl,
].filter(x => x);

//set displayVersions
if (environment.enableDisplayVersionsQuery) {
    environment.displayVersions = qsHelper.getBoolean('displayVersions');
}

// set UseDevFeatures
if (environment.enableDevFeaturesQuery && qsHelper.queryParamExists('UseDevFeatures')) {
    environment.useDevFeatures = qsHelper.getBoolean('UseDevFeatures');
}

// setAsadQuery
environment.asadQuery = qsHelper.getBoolean('asad');

if (environment.enableC2CQuery) {
    environment.c2cDemoFeatures = qsHelper.getBoolean('c2cdemo');
}

// setC2CEnabled
if (environment.enableC2CQuery && !environment.c2cEnabled) {
    environment.c2cEnabled = qsHelper.getBoolean('c2cdemo');
}

if (environment.enableC2COverlayQuery && !environment.c2cOverlayDemoEnabled) {
    environment.c2cOverlayDemoEnabled = qsHelper.getBoolean('overlaydemo');
}

// setDeckingEnabled
if (environment.deckingQueryEnabled && !environment.deckingEnabled) {
    environment.deckingEnabled = qsHelper.getBoolean('deckingdemo');
}

// setC2CForceAvailableCountriesEnabled
if (environment.c2cEnabled && environment.enableC2CQuery && environment.c2cForceAvailableCountriesEnabled) {
    environment.c2cForceAvailableCountriesEnabled = !qsHelper.getBoolean('c2cdemo');
}

// setC2CForceAvailableCountriesEnabled
if (environment.c2cOverlayDemoEnabled && environment.enableC2COverlayQuery && environment.c2cForceAvailableCountriesEnabled) {
    environment.c2cForceAvailableCountriesEnabled = !qsHelper.getBoolean('overlaydemo');
}

// setDebugMode
if (!environment.debugModeEnabled && environment.enableDebugModeQuery) {
    environment.debugModeEnabled = qsHelper.getBoolean('debugmode');
}

// set debug
environment.debugGlModel = environment.enableDebugGlModelQuery && qsHelper.getBoolean('debug');


/**
 * Get environment settings and update service version (v1 or v2). Required for blue green deployment.
 * Supported environments
 * - Ireland: dev
 * - China: stage and prod
 */
function getEnvironmentSettings(): Environment {
    const helper = new EnvironmentHelper(window.location.host);
    const [serviceVersion, version] = helper.getServiceVersions();

    const env: Environment = (window as any).environment;
    if (serviceVersion != '') {
        env.baseplateApplicationWebServiceUrl = helper.formatServiceVersion(env.baseplateApplicationWebServiceUrl, serviceVersion);
        env.baseplateCalculationWebServiceUrl = helper.formatServiceVersion(env.baseplateCalculationWebServiceUrl, serviceVersion);
        env.peCommonServiceUrl = helper.formatServiceVersion(env.peCommonServiceUrl, serviceVersion);
        env.c2cCodeListServiceUrl = helper.formatServiceVersion(env.c2cCodeListServiceUrl, serviceVersion);
        env.c2cCalculationServiceUrl = helper.formatServiceVersion(env.c2cCalculationServiceUrl, serviceVersion);
        env.c2cCalculationServiceSignalRCoreUrl = helper.formatServiceVersion(env.c2cCalculationServiceSignalRCoreUrl, serviceVersion);
        env.c2cCalculationServiceSignalRServer = helper.formatServiceVersion(env.c2cCalculationServiceSignalRServer, serviceVersion);
        env.c2cReportServiceUrl = helper.formatServiceVersion(env.c2cReportServiceUrl, serviceVersion);
        env.peTrackingServiceUrl = helper.formatServiceVersion(env.peTrackingServiceUrl, serviceVersion);
        env.translationsWebServiceUrl = helper.formatServiceVersion(env.translationsWebServiceUrl, serviceVersion);
        env.trackingWebServiceUrl = helper.formatServiceVersion(env.trackingWebServiceUrl, serviceVersion);
        env.deckingUserSettingsServiceUrl = helper.formatServiceVersion(env.deckingUserSettingsServiceUrl, serviceVersion);
        env.deckingDesignServiceUrl = helper.formatServiceVersion(env.deckingDesignServiceUrl, serviceVersion);
        env.deckingDesignServiceSignalRUrl = helper.formatServiceVersion(env.deckingDesignServiceSignalRUrl, serviceVersion);
        env.deckingCalculationServiceUrl = helper.formatServiceVersion(env.deckingCalculationServiceUrl, serviceVersion);
        env.deckingReportProcessorServiceUrl = helper.formatServiceVersion(env.deckingReportProcessorServiceUrl, serviceVersion);
        env.deckingReportProcessorServiceSignalRUrl = helper.formatServiceVersion(env.deckingReportProcessorServiceSignalRUrl, serviceVersion);
        env.deckingTrackingServiceUrl = helper.formatServiceVersion(env.deckingTrackingServiceUrl, serviceVersion);
        env.trimbleConnectWebServiceUrl = helper.formatServiceVersion(env.trimbleConnectWebServiceUrl, serviceVersion);
        env.supportServiceUrl = helper.formatServiceVersion(env.supportServiceUrl, serviceVersion);
        env.productInformationServiceUrl = helper.formatServiceVersion(env.productInformationServiceUrl, serviceVersion);
        env.documentWebServiceUrl = helper.formatServiceVersion(env.documentWebServiceUrl, serviceVersion);
        env.documentWebServiceRootUrl = helper.formatServiceVersion(env.documentWebServiceRootUrl, serviceVersion);
        env.userSettingsWebServiceUrl = helper.formatServiceVersion(env.userSettingsWebServiceUrl, serviceVersion);
        env.signalRServerUrl = helper.formatServiceVersion(env.signalRServerUrl, serviceVersion);
        env.signalRCoreServerUrl = helper.formatServiceVersion(env.signalRCoreServerUrl, serviceVersion);
        env.asadSignalRServerUrl = helper.formatServiceVersion(env.asadSignalRServerUrl, serviceVersion);
        env.asadSignalRCoreServerUrl = helper.formatServiceVersion(env.asadSignalRCoreServerUrl, serviceVersion);
        env.npsSurveyWebServiceUrl = helper.formatServiceVersion(env.npsSurveyWebServiceUrl, serviceVersion);
        env.integrationServicesServerUrl = helper.formatServiceVersion(env.integrationServicesServerUrl, serviceVersion);
        env.integrationServicesServerWebSocketsUrl = helper.formatServiceVersion(env.integrationServicesServerWebSocketsUrl, serviceVersion);

        env.translationsWebServiceRootUrl = helper.formatServiceVersion(env.translationsWebServiceRootUrl, serviceVersion);
        env.supportServiceRootUrl = helper.formatServiceVersion(env.supportServiceRootUrl, serviceVersion);
        env.productInformationServiceRootUrl = helper.formatServiceVersion(env.productInformationServiceRootUrl, serviceVersion);
        env.documentWebServiceRootUrl = helper.formatServiceVersion(env.documentWebServiceRootUrl, serviceVersion);
        env.userSettingsWebServiceRootUrl = helper.formatServiceVersion(env.userSettingsWebServiceRootUrl, serviceVersion);
        env.npsSurveyWebServiceRootUrl = helper.formatServiceVersion(env.npsSurveyWebServiceRootUrl, serviceVersion);

        env.baseplateApplicationWebServiceUiApiUrl = helper.formatVersion(env.baseplateApplicationWebServiceUiApiUrl, version);
        env.externalQuantityCalculatorApplicationUrl = helper.formatVersion(env.externalQuantityCalculatorApplicationUrl, version);
        env.npsSurveyWebUIUrl = helper.formatVersion(env.npsSurveyWebUIUrl, version);
        env.checkBotIntegrationServiceUrl = helper.formatServiceVersion(env.checkBotIntegrationServiceUrl, serviceVersion);
    }

    return env ?? {} as Environment;
}
