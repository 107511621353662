import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiOptions, ApiServiceBase } from '@profis-engineering/pe-ui-common/services/api.common';

import { environment } from '../../environments/environment';
import { ModulesService } from './modules.service';

// update main-interceptor clone code
export interface ApiHttpRequest<T = void> extends HttpRequest<T> {
    supressErrorMessage?: boolean;
    forceIncludeAuthenticationHeaders?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class ApiService extends ApiServiceBase {
    constructor(
        private httpClient: HttpClient,
        private modulesService: ModulesService
    ) {
        super();
    }

    public request<T = void>(httpRequest: HttpRequest<any>, options?: ApiOptions) {
        if (!(httpRequest instanceof HttpRequest)) {
            // calling from other modules (pe-ui-pe, pe-ui-c2c) it is not instance of HttpRequest anymore
            Object.setPrototypeOf(httpRequest, HttpRequest.prototype);
        }

        const req = (httpRequest as ApiHttpRequest<any>);
        req.supressErrorMessage = options?.supressErrorMessage;
        req.forceIncludeAuthenticationHeaders = options?.forceIncludeAuthenticationHeaders;

        const requestObservable = this.httpClient.request<T>(req);

        return new Promise<HttpResponse<T>>((resolve, reject) => {
            const subscription = requestObservable.subscribe({
                next: (response) => response instanceof HttpResponse && resolve(response as HttpResponse<T>),
                error: reject
            });

            options?.cancellationToken?.register(() => {
                subscription.unsubscribe();
                reject('canceled');
            });
        });
    }

    public getServiceName(url: string) {
        let serviceName = this.getServiceNameInfra(url);
        if (serviceName) {
            return serviceName;
        }

        serviceName = this.modulesService.getServiceName(url);
        if (serviceName) {
            return serviceName;
        }

        return 'unknown service';
    }

    private getServiceNameInfra(url: string) {
        if (url.startsWith(environment.externalAuthenticationUrl)) {
            return 'authentication-service';
        }
        if (url.startsWith(environment.translationsWebServiceUrl)) {
            return 'translations-service';
        }
        if (url.startsWith(environment.documentWebServiceUrl)) {
            return 'document-service';
        }
        if (url.startsWith(environment.userSettingsWebServiceUrl)) {
            return 'user-settings-service';
        }
        if (url.startsWith(environment.trimbleConnectWebServiceUrl)) {
            return 'trimble-connect-service';
        }
        if (url.startsWith(environment.integrationServicesServerUrl)) {
            return 'integrations-service';
        }
        if (url.startsWith(environment.productInformationServiceUrl)) {
            return 'product-information-service';
        }
        if (url.startsWith(environment.supportServiceUrl)) {
            return 'support-service';
        }
        if (url.startsWith(environment.peTrackingServiceUrl)) {
            return 'pe-tracking-service';
        }
        if (url.startsWith(environment.peCommonServiceUrl)) {
            return 'pe-common-service';
        }
        if (url.startsWith(environment.npsSurveyWebServiceUrl)) {
            return 'nps-survey-service';
        }
        if (url.includes('/hc/')) {
            return 'hilti-cloud';
        }

        return undefined;
    }
}
