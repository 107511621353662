<div class="modal-header">
    <h3 class="modal-title" l10n="Agito.Hilti.Profis3.LoadsMessages.Title"></h3>

    <button type="button" class="close" (click)="close()">
        <span>&times;</span>
    </button>
</div>

<div class="modal-body">
    <div *ngFor="let message of messages"
        class="message-content"
        [innerHtml]="message">
    </div>
</div>

<div class="modal-footer">
    <button id="loads-messages-ok-button" type="button" class="modal-button button button-CTA"
        (click)="close()">
        <span class="text" l10n="Agito.Hilti.Profis3.LoadsMessages.Ok"></span>
    </button>
</div>
