import { Injectable } from '@angular/core';
import { hasProperty } from '@profis-engineering/pe-ui-common/helpers/object-helper';
import { initialize, LDClient } from 'launchdarkly-js-client-sdk';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { FeatureFlag } from '../entities/feature-flags';

/**
 * You probably want to use FeatureVisibilityService.
 *
 * Only used before initial data is loaded and by FeatureVisibilityService.
 */
@Injectable({
    providedIn: 'root'
})
export class LaunchDarklyService {
    private client: LDClient;
    private initialized = false;
    private initializing = false;
    public init = new BehaviorSubject<boolean>(false);

    private cache: Record<string, unknown> = {};

    constructor() {
        this.beforeunload = this.beforeunload.bind(this);

        window.addEventListener('beforeunload', this.beforeunload, false);
    }

    public get ldClient() {
        return this.client;
    }

    public async initialize(userId: string, userName: string, country?: string): Promise<void> {
        if (this.initialized) {
            throw new Error('already initialized');
        }

        if (this.initializing) {
            throw new Error('already initializing');
        }

        if (userId == null) {
            throw new Error('userId not provided');
        }

        if (userName == null) {
            throw new Error('userName not provided');
        }

        this.initializing = true;
        try {
            if (environment.launchDarklyClientSideId) {
                this.client = initialize(environment.launchDarklyClientSideId, {
                    kind: 'user',
                    key: userId,
                    name: userName,
                    country: country
                });

                await this.client.waitForInitialization();
            }
        }
        catch {
            // if launch darkly fails to initialize use default values
            // error should already be logged by launch darkly
            this.client = null;
        }
        finally {
            this.initializing = false;
        }

        this.initialized = true;

        this.init.next(this.initialized);
    }

    public get isInitialized() {
        return this.initialized;
    }

    public getFeatureValue<T>(feature: FeatureFlag, defaultValue: T): T {
        return this.variation(`P_${feature}`, defaultValue);
    }

    private variation<T>(key: string, defaultValue: T): T {
        if (!this.initialized) {
            throw new Error('FeatureFlagsService not initialized');
        }

        if (this.client == null) {
            return defaultValue;
        }

        if (!hasProperty(this.cache, key)) {
            this.cache[key] = this.client.variation(key, defaultValue);
        }

        return this.cache[key] as T;
    }

    private beforeunload() {
        //BUDQBP-35130: Added setTimeout because we need flag value for beforeunload events of descendents components
        setTimeout(() => {
            window.removeEventListener('beforeunload', this.beforeunload, false);

            if (this.client != null) {
                this.client.close();
            }
        });
    }
}
