import isEqual from 'lodash-es/isEqual';
import React from 'react';
import { ToggleButtonGroup } from '../ToggleButtonGroup/ToggleButtonGroup';
import { ControlType } from '../../ControlInfo';
import { isControlHidden } from '../../MainMenuHelper';
import { SessionStorageService } from '../../../../services/session-storage.service';
import { Size } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { ITabGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/tab-group-props'
import { IToggleButtonGroupItem, ToggleButtonGroupType } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-button-group-props'
import { ToggleButtonRole } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-button-props';


interface ITabState {
    selectedTab: number;
}

export class TabGroup extends React.PureComponent<ITabGroupProps, ITabState> {

    private sessionStorage;

    constructor(ctor: ITabGroupProps) {
        super(ctor);

        this.sessionStorage = new SessionStorageService();

        var existingSelection = this.sessionStorage.get(this.controlIdCheck(this.props.controlId)) as number;

        this.state = {
            selectedTab: existingSelection ?? this.props.items[0].value
        };

        this.onTabChanged = this.onTabChanged.bind(this);
    }

    public override componentDidUpdate(prevProps?: ITabGroupProps) {
        const updatedChildControls = Object.values(this.props.controls)
            .filter(x => Object.keys(this.props.childControls)
                .includes(x.controlId))
            .map(child => ({
                ...child,
                parentTabName: this.props.childControls[child.controlId].parentTabName
            }));

        const previousChildControls = Object.values(prevProps.controls)
            .filter(x => Object.keys(prevProps.childControls)
                .includes(x.controlId))
            .map(child => ({
                ...child,
                parentTabName: this.props.childControls[child.controlId].parentTabName
            }));

        if (!isEqual(previousChildControls, updatedChildControls)) {
            if (this.props.onChildControlsUpdate != null) {
                this.props.onChildControlsUpdate(updatedChildControls);
            }
        }
    }

    public override render() {
        if (isControlHidden(this.props) || this.props.controls == null || this.props.controlsOrder == null ||
            this.props.controlsOrder.every(id => isControlHidden(this.props.childControls[id]))) {
            return null;
        }

        const items = (() => {
            return this.props.items?.map(x => {
                return {
                    disabled: x.disabled,
                    text: x.displayText,
                    image: x.image,
                    imageStyle: x.imageStyle,
                    value: x.value,
                    clicked: () => this.onTabChanged(x.value),
                    hidden: false
                } as IToggleButtonGroupItem
            });
        })();

        const activeTabName = this.props.items.find(x => x.value == this.state.selectedTab).tag;
        const visibleControls = Object.values(this.props.childControls)
            .filter(x => x.parentTabName == activeTabName)
            .map(x => x.controlId);
        const controlsOrder = this.props.controlsOrder.filter(x => visibleControls.includes(x));

        return (
            <div data-control-id={this.props.controlId} className={`control react-tab-group-control ${this.props.sizeClass}`}>
                <div className="react-tab control size-100">
                    <ToggleButtonGroup
                        controlId={this.props.controlId}
                        localization={this.props.localization}
                        title={this.props.title}
                        size={Size.full}
                        disabled={false}
                        hidden={false}
                        type={ToggleButtonGroup}
                        items={items}
                        toggleType={ToggleButtonGroupType.single}
                        activeValue={this.state.selectedTab}
                        sizeClass="flex"
                        role={ToggleButtonRole.Tab}
                        valueChanged={this.onTabChanged}
                    />
                </div>
                <div
                    className={`controls multiline`}
                    data-control-id={`${this.props.controlId}`}>
                    {
                        controlsOrder.map((id) => {
                            const control = this.props.childControls[id];
                            const props = {
                                ...control,
                                key: control.controlId
                            };

                            if(this.props.applyControlsStyle != undefined){
                                this.props.applyControlsStyle(control.type);
                            }

                            return React.createElement(control.type, props);;
                        })
                    }
                </div>
            </div>
        );
    }

    private onTabChanged(tab: number | number[]): void {
        if (Array.isArray(tab)) {
            throw new Error('toggleType is set to single so it should be a number');
        }

        if (tab == this.state.selectedTab) {
            return;
        }

        this.setState({
            selectedTab: tab
        });

        this.sessionStorage.set(this.controlIdCheck(this.props.controlId), tab);
        this.props.onTabChanged?.();
    }

    private controlIdCheck(id: string): string {
        const tabMappings: Record<string, string> = {
            "control-reinforcement-layers-tab": "pir-tab",
            "pir-tab-reinforcement-layers-tab": "pir-tab",
            "existingreinforcement-tab-longitudinal-reinforcement-layers-tab": "existingreinforcement-tab",
            "control-longitudinal-reinforcement-layers-tab": "existingreinforcement-tab",
            "existingreinforcement-tab-transverse-reinforcement-layers-tab": "existingreinforcement-tab",
            "control-transverse-reinforcement-layers-tab": "existingreinforcement-tab"
        };

        return tabMappings[id] ?? id;
    }
}

ControlType.TabGroup = TabGroup;
