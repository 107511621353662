import { Component, OnInit } from '@angular/core';
import {
    LicenseSuiteType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';

import { LicenseSuite } from '@profis-engineering/pe-ui-common/entities/code-lists/license-suite';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { LicenseService } from '../../services/license.service';
import { ModalService } from '../../services/modal.service';
import { OfflineService } from '../../services/offline.service';
import { ProductInformationService } from '../../services/product-information.service';
import { UserSettingsService } from '../../services/user-settings.service';

export interface ILicenseComparison {
    isCheckbot: boolean;
}

@Component({
    selector: 'app-license-comparison',
    templateUrl: './license-comparison.component.html',
    styleUrls: ['./license-comparison.component.scss']
})
export class LicenseComparisonComponent implements OnInit {
    public submitted: boolean;

    constructor(
        private license: LicenseService,
        private userSettings: UserSettingsService,
        private commonCodeList: CommonCodeListService,
        private offline: OfflineService,
        private modalInstance: ModalInstance<ILicenseComparison>,
        private modal: ModalService,
        private productInformation: ProductInformationService
    ) { }

    public get isCheckbot() {
        return this.modalInstance?.input?.isCheckbot ?? false;
    }

    public get licenseSuites() {
        return this.commonCodeList.commonCodeLists[CommonCodeList.LicenseSuite] as LicenseSuite[];
    }

    public get isUpgradeLicenseAvailable() {
        return this.userSettings.isUpgradeLicenseAvailable();
    }

    public get comparisonRedirectUrl() {
        return this.productInformation.regionLinksForUser()?.UpgradeToPremiumLink;
    }

    public get comparisonRedirectText() {
        return this.productInformation.regionLinksTranslationsForUser?.UpgradeToPremiumText || this.productInformation.regionLinksForUser()?.UpgradeToPremiumText;
    }

    ngOnInit(): void {
        // do not close modal if save is pending
        this.modalInstance.setOnClosing(() => {
            return this.submitted
                ? true
                : false;
        });
    }

    public stayOnStandard() {
        if (this.isCheckbot) {
            this.submitted = true;
            this.modalInstance.close();
            return;
        }

        if (this.comparisonRedirectUrl != null) {
            const comparisonRedirectUrl = this.comparisonRedirectUrl
                .replace('{code}', this.license.licenseSuiteItemCode(this.licenseSuites, LicenseSuiteType.Standard));

            if (!this.offline.isOffline) {
                window.open(comparisonRedirectUrl, '_blank');
            } else {
                this.offline.nativeExternalLinkOpen(comparisonRedirectUrl);
            }
        }
    }

    public getPremium() {
        this.submitted = true;
        this.modalInstance.close();

        if (!this.isCheckbot) {
            this.modal.openTrialBanner();
        }

        if (this.comparisonRedirectUrl != null) {
            const comparisonRedirectUrl = this.comparisonRedirectUrl
                .replace('{code}', this.license.licenseSuiteItemCode(this.licenseSuites, LicenseSuiteType.Premium));

            if (!this.offline.isOffline) {
                window.open(comparisonRedirectUrl, '_blank');
            } else {
                this.offline.nativeExternalLinkOpen(comparisonRedirectUrl);
            }
        }
    }
}
