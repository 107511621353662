import * as React from 'react';

import { IButtonGroupProps, ButtonGroupDisplay } from '@profis-engineering/pe-ui-common/entities/main-menu/button-group-props';
import { ControlHeader } from '../../ControlHeader';
import { isControlHidden } from '../../MainMenuHelper';
import { Button } from '../Button/Button';

export class ButtonGroup extends React.PureComponent<IButtonGroupProps> {
    constructor(props?: IButtonGroupProps) {
        super(props);
    }

    public override render() {
        if (isControlHidden(this.props)) {
            return null;
        }

        const groupId = `${this.props.controlId}-group`;
        const displayClass = this.props.display == ButtonGroupDisplay.Join ? 'join' : '';

        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled ? this.props.tooltipDisabled : this.props.tooltip;

        return (
            <div data-control-id={this.props.controlId} className={`control react-button-group ${this.props.sizeClass}`}>
                <ControlHeader
                    text={this.props.title}
                    controlId={groupId}
                    tooltip={tooltipText}
                    tooltipTitle={tooltipTitle}
                    localization={this.props.localization}
                />

                <div
                    className={`buttons ${displayClass}`}
                    data-control-id={groupId} >
                    {
                        this.props.items.map((item) =>
                            <Button
                                controlId={`${this.props.controlId}-${item.id}`}
                                key={`${this.props.controlId}-${item.id}`}
                                text={item.text}
                                image={item.image}
                                imageStyle={item.imageStyle}
                                buttonSize={item.buttonSize}
                                buttonType={item.buttonType}
                                disabled={this.props.disabled}
                                hidden={this.props.hidden}
                                localization={this.props.localization}
                                size={null}
                                title={null}
                                type={Button}
                                clicked={item.clicked}
                                tooltip={item.tooltip}
                                tooltipTitle={item.tooltipTitle}
                            />)
                    }
                </div>
            </div>
        );
    }
}
