import { Component, OnInit } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import {
    TextBoxLook, TextBoxProps
} from '@profis-engineering/pe-ui-common/components/text-box/text-box.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { DocumentAccessMode } from '@profis-engineering/pe-ui-common/services/document.common';

import { Design } from '../../entities/design';
import { Project } from '../../entities/project';
import { publish } from '../../services/calculation/calculation-service-base';
import { DesignTemplateService } from '../../services/design-template.service';
import { DocumentService } from '../../services/document.service';
import { LocalizationService } from '../../services/localization.service';
import { OfflineService } from '../../services/offline.service';
import { designNameValidator } from '../../validators/design-name.validator';

export interface IRenameDesignComponentInput {
    design: Design;
    dropProject: Project;

    onDesignRenamed: (project: Project) => void;
}

@Component({
    selector: 'app-rename-design',
    templateUrl: './rename-design.component.html',
    styleUrls: ['./rename-design.component.scss']
})
export class RenameDesignComponent implements OnInit {
    public submitted: boolean;
    public designStandardId: number;
    public designMethodGroupId: number;
    public newProject: string;

    public nameTextBox: TextBoxProps = {};

    private pendingSave: boolean;

    constructor(
        public localization: LocalizationService,
        private documentService: DocumentService,
        private offline: OfflineService,
        private modalInstance: ModalInstance<IRenameDesignComponentInput>,
        private designTemplateService: DesignTemplateService
    ) { }

    public get formValid() {
        return this.nameTextBox?.isValid;
    }

    private get project() {
        return this.modalInstance.input?.dropProject;
    }

    private get design() {
        return this.modalInstance.input?.design;
    }

    private get onDesignRenamed() {
        return this.modalInstance.input?.onDesignRenamed;
    }

    private get isTemplate() {
        return this.design?.isTemplate ?? false;
    }

    ngOnInit(): void {
        this.modalInstance.setOnClosing(() => {
            if (this.pendingSave) {
                return false;
            }
            publish(this.documentService, this.designTemplateService, this.design);
            return true;
        });

        this.nameTextBox = {
            id: 'rename-design-name',
            look: TextBoxLook.Large,
            title: this.localization.getString('Agito.Hilti.Profis3.RenameDesign.DesignLabel.Text'),
            maxLength: 250,
            validators: [Validators.required],
            validationData: {
                showValidationErrors: true
            },
            value: this.design.designName
        };

        if (!this.offline.isOffline) {
            this.nameTextBox.validators.push(
                designNameValidator(
                    this.project, null, null, this.documentService,
                    this.localization.getString('Agito.Hilti.Profis3.Naming.DesignNameExists.Message')
                )
            );
        }

        setTimeout(() => this.nameTextBoxFocus());
    }

    public save(form: NgForm) {
        if (this.submitted || !this.formValid || (form.enabled && !form.valid) || this.pendingSave) {
            return;
        }

        this.submitted = true;
        this.pendingSave = true;

        this.design.designName = this.nameTextBox.value;

        if (this.design.isTemplate) {
            this.design.templateName = this.nameTextBox.value;
        }

        // change design
        if (!this.isTemplate) {
            this.design.projectId = this.project != null ? this.project.id : null;
            this.design.projectName = this.project != null ? this.project.name : null;
        }

        this.documentService.updateDesignWithNewContentCommon(this.design, null, false, false, DocumentAccessMode.Update)
            .finally(() => {
                this.pendingSave = false;
                this.submitted = false;
            })
            .then(() => {
                if (this.onDesignRenamed != null) {
                    this.onDesignRenamed(this.project);
                }
                this.close();
            });
    }

    public close() {
        this.modalInstance.close();
    }

    private nameTextBoxFocus() {
        const elt = document.querySelector<HTMLElement>('.modal-body #rename-design-name-wrapper')
            ?.shadowRoot?.querySelector<HTMLElement>('#rename-design-name');

        // Focus and blur (to show validation error)
        elt?.focus();
        elt?.blur();

        // Final focus
        elt?.focus();
    }
}
