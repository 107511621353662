<div class="modal-header">
    <h3 class="modal-title" l10n="Agito.Hilti.Profis3.UserAgreementSettings.Title"></h3>

    <button id="user-agreement-settings-close-button" type="button" class="close" (click)="close()">
        <span>&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="modal-body-content">
        <div class="section">
            <div class="control-row">
                <div class="checkbox data-item">
                    <pe-checkbox-button
                        [items]="diagnosticsAgreementCheckbox.items"
                        [selectedValues]="diagnosticsAgreementCheckbox.selectedValues"
                        (selectedValuesChange)="diagnosticsAgreementCheckbox.selectedValues = $any($event).detail">
                    </pe-checkbox-button>
                </div>

                <div *ngIf="showMarketingCommunicationPreferences">
                    <label l10n="Agito.Hilti.Profis3.UserAgreementSettings.CommunicationPreferences.Text"></label>
                    <div class="link-wrapper" [ngbTooltip]="commLinkTooltip" [disableTooltip]="!commLinkTooltipEnabled">
                        <a class="url"
                            [class.link]="!commLinkTooltipEnabled"
                            [class.link-disabled]="commLinkTooltipEnabled"
                            (click)="openMarketingCommunicationPreferencesUrl()">
                            <span class="text" l10n="Agito.Hilti.Profis3.UserAgreementSettings.CommunicationPreferences.Link"></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal-footer">
    <button type="button" id="user-agreement-settings-cancel-button" class="modal-button button button-default"
        l10n="Agito.Hilti.Profis3.UserAgreementSettings.Cancel" (click)="close()"
        [disabled]="submitted">
    </button>

    <button type="button" id="user-agreement-settings-save-button"
        class="modal-button button button-CTA"
        (click)="save()" [disabled]="submitted">
        <pe-loading-button
            [loading]="submitted"
            [text]="'Agito.Hilti.Profis3.UserAgreementSettings.Save' | l10n:localization.selectedLanguage">
        </pe-loading-button>
    </button>
</div>
