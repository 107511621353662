import * as React from 'react';

import { getMenuControlImageReactData } from '../../../../helpers/react-helpers';
import { buildHtmlTooltip, isControlHidden } from '../../MainMenuHelper';
import { IImageNameCheckboxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/image-name-checkbox-props';

const keyEnter = 13;
const keySpace = 32;

export class ImageNameCheckbox extends React.PureComponent<IImageNameCheckboxProps> {
    private readonly checkboxItemClassName = 'react-image-name-checkbox-control';

    private input: HTMLInputElement;

    private onMouseEnterFn: (event: React.MouseEvent) => void;
    private onMouseLeaveFn: (event: React.MouseEvent) => void;

    constructor(props?: IImageNameCheckboxProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);

        this.onDragStart = this.onDragStart.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);

        this.onMouseEnterFn = this.onMouseEnter.bind(this);
        this.onMouseLeaveFn = this.onMouseLeave.bind(this);
    }

    public override render() {
        if (isControlHidden(this.props)) {
            return null;
        }

        const inputId = `${this.props.controlId}-checkbox`;
        const checked = this.props.checked != null ? this.props.checked : false;
        const text = this.props.text != null ? this.props.text : '';
        const tooltip = this.props.title != null && this.props.title != '' ? null : buildHtmlTooltip(this.props.tooltip, this.props.tooltipTitle);
        const disableClassName = this.props.disabled ? 'disabled' : '';

        const imageData = getMenuControlImageReactData(
            this.props.image,
            this.props.imageStyle
        );
        const hasImage = imageData.spriteName != null && imageData.spriteName != '' || imageData.elementStyle != null;

        return (
            <div
                data-control-id={this.props.controlId}
                className={`control ${this.checkboxItemClassName}-group ${this.checkboxItemClassName} react-checkbox-control ${this.props.className} ${this.props.sizeClass}`}
                draggable={false}
                onDragStart={this.props.enableItemsSorting ? this.onDragStart : null}
                onDragEnd={this.props.enableItemsSorting ? this.onDragEnd : null}
                data-drag-id={this.props.value}>

                <div className='control-container'>
                    <div
                        onClick={this.onClick}
                        className={`checkbox ${checked ? 'bg-selected' : undefined} ${disableClassName}`}
                        data-tip={tooltip}
                        data-html={tooltip != null ? true : null}
                        data-place='right'>
                        {
                            hasImage ?
                                <div className='image-container'>
                                    <span className={`image sprite sprite-responsive ${imageData.spriteName}`} style={imageData.elementStyle}></span>
                                </div> : null
                        }
                        <div
                            className='text checkbox-label'
                            onMouseEnter={this.onMouseEnterFn}
                            onMouseLeave={this.onMouseLeaveFn}>

                            <div className='drag-handle'>
                                <span>
                                    {text}
                                </span>
                            </div>
                        </div>
                        <div
                            className='checkbox-button'
                            onKeyPress={this.onKeyPress}>

                            <input
                                data-control-id={inputId}
                                type='checkbox'
                                checked={checked}
                                onChange={this.onChange}
                                disabled={this.props.disabled}
                                ref={(e) => this.input = e} />
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private onClick(event: React.MouseEvent) {
        this.input.click();
    }

    private onChange(event: React.FormEvent) {
        const checked = (event.target as HTMLInputElement).checked;

        if (this.props.checkedChanged != null) {
            this.props.checkedChanged(checked, this.props.value);
        }
    }

    private onKeyPress(event: React.KeyboardEvent) {
        const eventCode = event.which || event.keyCode || event.charCode;
        if (eventCode == keyEnter || eventCode == keySpace) {
            event.preventDefault();
            this.input.click();
        }
    }

    private onDragStart(event: React.DragEvent) {
        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.setData('Text', this.props.value.toString());

        if (this.props.dragStarted != null) {
            this.props.dragStarted(this.props.value.toString());
        }
    }

    private onDragEnd(event: React.DragEvent) {
        if (this.props.dragEnded != null) {
            this.props.dragEnded();
        }
    }

    private onMouseEnter(event: React.MouseEvent) {
        this.manageDraggableAvailability(event, `${this.props.enableItemsSorting}`);
    }

    private onMouseLeave(event: React.MouseEvent) {
        this.manageDraggableAvailability(event, 'false');
    }

    private manageDraggableAvailability(event: React.MouseEvent, draggable: string) {
        const checkboxElement = (event.currentTarget as HTMLElement).closest('.react-image-name-checkbox-control');
        if (checkboxElement) {
            checkboxElement.setAttribute('draggable', draggable);
        }
    }
}
