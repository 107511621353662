import { Injectable } from '@angular/core';
import { CodeList } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    AdvancedCalculationType
} from '@profis-engineering/pe-ui-shared/entities/code-lists/advanced-calculation-type';
import {
    BrickGroup
} from '@profis-engineering/pe-ui-shared/entities/code-lists/brick-group';
import {
    ConcreteInCompressionMethod
} from '@profis-engineering/pe-ui-shared/entities/code-lists/concrete-in-compression-method';
import {
    ConcreteSafetyFactorGammaC
} from '@profis-engineering/pe-ui-shared/entities/code-lists/concrete-safety-factor-gamma-C';
import {
    DesignMethodGroup
} from '@profis-engineering/pe-ui-shared/entities/code-lists/design-method-group';
import {
    DesignStandard
} from '@profis-engineering/pe-ui-shared/entities/code-lists/design-standard';
import {
    DesignType
} from '@profis-engineering/pe-ui-shared/entities/code-lists/design-type';
import {
    EdgeDistanceDisplayType
} from '@profis-engineering/pe-ui-shared/entities/code-lists/edge-distance-display-type';
import { Region } from '@profis-engineering/pe-ui-shared/entities/code-lists/region';
import {
    SteelGuideline
} from '@profis-engineering/pe-ui-shared/entities/code-lists/steel-guideline';
import { ProjectCodeList } from '@profis-engineering/pe-ui-shared/enums/project-code-list';
import {
    ProjectDesignCodeLists as IProjectDesignCodeLists
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import {
    CodeListServiceBase
} from '@profis-engineering/pe-ui-shared/services/code-list.service.base';
import {
    CommonCodeList
} from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import {
    CommonRegion
} from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { CommonCodeListService } from './common-code-list.service';

@Injectable({
    providedIn: 'root'
})
export class CodeListService extends CodeListServiceBase {
    public projectCodeLists: Record<ProjectCodeList, CodeList[]>;

    constructor(
        private commonCodeListService: CommonCodeListService
    ) {
        super();
    }

    public initialize(data: IProjectDesignCodeLists) {
        this.projectCodeLists = {
            [ProjectCodeList.Region]: data.Regions.map((codeList) => Region.fromService(codeList, this.getCommonRegionById(codeList.Id))),
            [ProjectCodeList.DesignType]: data.DesignTypes.map((codeList) => DesignType.fromService(codeList)),
            [ProjectCodeList.DesignStandard]: data.DesignStandards.map((codeList) => DesignStandard.fromService(codeList)),
            [ProjectCodeList.EdgeDistanceDisplayType]: data.EdgeDistanceDisplayTypes.map((codeList) => EdgeDistanceDisplayType.fromService(codeList)),
            [ProjectCodeList.DesignMethodGroup]: data.DesignMethodGroups.map((codeList) => DesignMethodGroup.fromService(codeList)),
            [ProjectCodeList.SteelGuidelines]: data.SteelGuidelines.map((codeList) => SteelGuideline.fromService(codeList)),
            [ProjectCodeList.ConcreteInCompressionMethod]: data.ConcreteInCompressionMethods.map((codeList) => ConcreteInCompressionMethod.fromService(codeList)),
            [ProjectCodeList.ConcreteSafetyFactorGammaC]: data.ConcreteSafetyFactorGammaC.map((codeList) => ConcreteSafetyFactorGammaC.fromService(codeList)),
            [ProjectCodeList.AdvancedCalculationType]: data.AdvancedCalculationTypes.map((codeList) => AdvancedCalculationType.fromService(codeList)),
            [ProjectCodeList.BrickGroup]: data.BrickGroups.map((codeList) => BrickGroup.fromService(codeList))
        };
    }

    public getCommonRegionById(regionId: number) {
        const commonRegions = this.commonCodeListService.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
        return commonRegions.find(x => x.id == regionId);
    }
}
