<div class="modal-header">
    <h3 class="modal-title" [innerHtml]="title"></h3>

    <button type="button" id="alert-close-button" class="close" (click)="close()" *ngIf="!preventClose">
        <span>&times;</span>
    </button>
</div>
<div class="modal-body" [innerHtml]="message"></div>

<div class="modal-footer" *ngIf="showSupportButton || showLogoutButton">
    <button type="button" id="alert-support-button" *ngIf="showSupportButton"
        class="modal-button button button-default" l10n="Agito.Hilti.Profis3.Support.Button.Text"
        (click)="openSupport()"></button>
    <button type="button" id="alert-logout-button" *ngIf="showLogoutButton"
        class="modal-button button button-default" l10n="Agito.Hilti.Profis3.Logout.Button.Text"
        (click)="logout()"></button>
</div>
