import sortBy from 'lodash-es/sortBy';

import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { Language } from '@profis-engineering/pe-ui-common/entities/code-lists/language';
import {
    RegionLanguage
} from '@profis-engineering/pe-ui-common/entities/code-lists/region-language';
import { WorldArea } from '@profis-engineering/pe-ui-common/entities/code-lists/world-area';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';

import { getSpriteAsIconStyle } from '../../helpers/sprites';
import { LocalizationService } from '../../services/localization.service';
import { NumberService } from '../../services/number.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { CommonCodeListService } from '../../services/common-code-list.service';

interface WorldAreaCountryRecommendation {
    worldAreaIndex: number;
    countryCode: string | null;
}

const savedStatus = 'SelectRegionLanguageComponent:saved';

@Component({
    selector: 'app-select-region-language',
    templateUrl: './select-region-language.component.html',
    styleUrls: ['./select-region-language.component.scss']
})
export class SelectRegionLanguageComponent implements OnInit {
    public worldAreaDropdown: DropdownProps<WorldArea> = {};
    public countryDropdown: DropdownProps<RegionLanguage> = {};
    public pendingSave: boolean;

    private countryWorldAreaSuggestion: WorldAreaCountryRecommendation;

    constructor(
        private commonCodeList: CommonCodeListService,
        private userSettings: UserSettingsService,
        private localization: LocalizationService,
        private user: UserService,
        private numberService: NumberService,
        private modalInstance: ModalInstance
    ) { }

    public get regionLanguages() {
        return this.commonCodeList.commonCodeLists[CommonCodeList.RegionLanguage] as RegionLanguage[];
    }

    public get selectedRegionLanguage() {
        return this.userSettings.getLanguage();
    }

    ngOnInit(): void {
        this.modalInstance.setOnClosing((result) => {
            return result === savedStatus;
        });

        this.countryWorldAreaSuggestion = this.determineSelectionSuggested();

        const worldAreaCodeList = sortBy(
            this.commonCodeList.commonCodeLists[CommonCodeList.WorldArea] as WorldArea[],
            worldArea => worldArea.name);

        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
        this.worldAreaDropdown = {
            id: 'select-region-worldArea',
            title: this.localization.getString('Agito.Hilti.Profis3.SelectRegionLanguage.WorldAreaDropdown.Title'),
            validators: [Validators.required],
            items: worldAreaCodeList.map((worldArea) => {
                return {
                    value: worldArea,
                    text: worldArea.getTranslatedNameText(codeListDeps)
                } as DropdownItem<WorldArea>;
            }),
            selectedValue: worldAreaCodeList[this.countryWorldAreaSuggestion.worldAreaIndex]
        };

        this.countryDropdown = {
            id: 'select-region-country',
            title: this.localization.getString('Agito.Hilti.Profis3.SelectRegionLanguage.CountryDropdown.Title'),
            validators: [Validators.required],
            items: [],
            selectedValue: null
        };

        this.worldAreaDropdownSelectedValueChange(this.worldAreaDropdown.selectedValue);
    }

    public selectRegionLanguage() {
        if (
            !this.worldAreaDropdown.isValid
            ||
            !this.countryDropdown.isValid
            ||
            this.pendingSave
        ) {
            return;
        }

        this.pendingSave = true;

        const regionLanguage = this.countryDropdown.selectedValue;
        const region = (this.commonCodeList.commonCodeLists[CommonCodeList.Region] as CommonRegion[]).find(x => x.id == regionLanguage.regionId);
        const language = (this.commonCodeList.commonCodeLists[CommonCodeList.Language] as Language[]).find(x => x.id == regionLanguage.lcid);

        this.userSettings.setFirstTimeRegionLanguage(region, language)
            .then(() => this.close(savedStatus))
            .finally(() => this.pendingSave = false);
    }

    public close(result?: any) {
        this.modalInstance.close(result);
    }

    public worldAreaDropdownSelectedValueChange(selectedWorldArea: WorldArea) {
        this.worldAreaDropdown.selectedValue = selectedWorldArea;

        let regionCodeList = (this.commonCodeList.commonCodeLists[CommonCodeList.Region] as CommonRegion[])
            .filter(x => x.worldAreaId == selectedWorldArea.id);

        const regionLanguageCodeList = regionCodeList
            .flatMap(region => (this.commonCodeList.commonCodeLists[CommonCodeList.RegionLanguage] as RegionLanguage[])
                .filter(regionLanguage => regionLanguage.regionId == region.id));

        this.countryDropdown.items = regionLanguageCodeList.map((regionLanguage): DropdownItem<RegionLanguage> => {
            return {
                value: regionLanguage,
                text: regionLanguage.cultureNativeName,
                image: getSpriteAsIconStyle(regionLanguage.image)
            };
        });

        let countryIndex = 0;

        if (this.countryWorldAreaSuggestion?.countryCode != null) {
            regionCodeList = regionCodeList.filter(x => x.countryCode == this.countryWorldAreaSuggestion.countryCode);

            if (regionCodeList.length > 0) {
                const region = regionCodeList[0];
                const regionLanguage = regionLanguageCodeList.filter(x => x.regionId == region.id && x.defaultForRegion)[0];

                countryIndex = regionLanguageCodeList.indexOf(regionLanguage);
            }
        }

        // Suggestion should happen only once - setting in to null ensures this.
        this.countryWorldAreaSuggestion = null;

        this.countryDropdown.selectedValue = regionLanguageCodeList[countryIndex];
    }

    private determineSelectionSuggested(): WorldAreaCountryRecommendation {
        let suggestedCountryCode: string;

        try {
            suggestedCountryCode =
                this.user.authentication.countryOfResidence ||
                this.user.authentication.subscription_info.AuthorizationEntryList[0].Country ||
                this.user.authentication.country ||
                null;

            suggestedCountryCode = suggestedCountryCode.toLowerCase();
        }
        catch {
            suggestedCountryCode = null;
        }

        let result: WorldAreaCountryRecommendation = {
            worldAreaIndex: 0,
            countryCode: null,
        };

        if (suggestedCountryCode == null) {
            return result;
        }

        const worldAreaCodeList = sortBy(this.commonCodeList.commonCodeLists[CommonCodeList.WorldArea] as WorldArea[], worldArea => worldArea.name);
        const countryWorldArea = (this.commonCodeList.commonCodeLists[CommonCodeList.Region] as CommonRegion[])
            .filter(x => x.countryCode == suggestedCountryCode)[0];

        if (countryWorldArea == null) {
            return result;
        }

        const worldArea = worldAreaCodeList.filter(x => x.id == countryWorldArea.worldAreaId)[0];
        const worldAreaIndex = worldAreaCodeList.indexOf(worldArea);

        result = {
            worldAreaIndex,
            countryCode: suggestedCountryCode
        };

        return result;
    }
}
