import { Component, OnInit } from '@angular/core';
import {
    CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import {
    SimpleCheckboxButtonHelper
} from '@profis-engineering/pe-ui-common/helpers/simple-checkbox-button-helper';
import {
    RegionHub
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';

import { LocalizationService } from '../../services/localization.service';
import { MarketingService } from '../../services/marketing.service';
import { OfflineService } from '../../services/offline.service';
import { UserSettingsService } from '../../services/user-settings.service';

@Component({
    selector: 'app-user-agreement-privacy',
    templateUrl: './user-agreement-privacy.component.html',
    styleUrls: ['./user-agreement-privacy.component.scss']
})
export class UserAgreementPrivacyComponent implements OnInit {
    public diagnosticsAgreementCheckbox: CheckboxButtonProps<boolean>;
    public notifyAgreement: boolean;

    public pendingSave: boolean;

    private submitted: boolean;

    constructor(
        public localization: LocalizationService,
        private userSettings: UserSettingsService,
        private modalInstance: ModalInstance,
        private marketingService: MarketingService,
        private offlineService: OfflineService,
    ) { }

    ngOnInit(): void {
        this.submitted = false;

        const region = this.userSettings.getCommonRegionById(this.userSettings.settings.application.general.regionId.value);

        this.diagnosticsAgreementCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            itemText: this.localization.getString('Agito.Hilti.Profis3.UserAgreementPrivacy.DiagnosticsAgreement'),
            itemDescription: this.localization.getString('Agito.Hilti.Profis3.UserAgreementPrivacy.DiagnosticsAgreement.SubText'),
            checked: region.hubId == RegionHub.W1_HNA
        });

        this.notifyAgreement = false;

        this.modalInstance.setOnClosing(() => {
            return this.submitted
                ? true
                : false;
        });
    }

    public openMarketingCommunicationPreferencesUrl() {
        const url = this.marketingService.getDPRUrl();
        this.offlineService.nativeExternalLinkOpen(url);
    }

    public get showMarketingCommunicationPreferences(){
        return this.marketingService.getDPRUrl() != undefined && this.marketingService.getDPRUrl() != '';
    }

    public save() {
        this.pendingSave = true;

        this.userSettings.settings.application.general.userAgreement.value = true;
        this.userSettings.settings.application.general.userPrivacy.value = SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.diagnosticsAgreementCheckbox);
        this.userSettings.settings.application.general.isNewUser.value = true;

        this.userSettings.save()
            .then(() => {
                this.submitted = true;
                this.close();
            })
            .catch((err) => {
                if (err instanceof Error) {
                    console.error(err);
                }

                this.pendingSave = false;
            });
    }

    public close() {
        this.modalInstance.close();
    }
}
