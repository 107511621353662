import { DesignTemplateFolderBase, ITemplateFolderConstructorBase } from '@profis-engineering/pe-ui-common/entities/template';
import { DesignTemplateEntity } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.DesignTemplate';
import { DesignTemplateService } from '../../services/design-template.service';

export interface TemplateFolderRequestModel {
    parentTemplateId?: string;
    name: string;
}

export interface TemplateDocumentRequestModel{
    designTemplateDocumentIds? : string[];
}

export class DesignTemplateFolderDetail extends DesignTemplateFolderBase {
    public subFolders?: { [key: string]: DesignTemplateFolderDetail; } = {};
    public templates?: { [key: string]: DesignTemplateEntity; } = {};
    constructor(templateFolder?: ITemplateFolderConstructor) {
        super(templateFolder);
        if (templateFolder) {
            this.subFolders = templateFolder?.subFolders;
        }

        this.subFolders = this.subFolders || {};
    }
    public async loadUsers(templateService: DesignTemplateService): Promise<void> {
        this.users = await templateService.getUsersOnTemplateFolderById(this.templateFolderId);
    }
}

export interface ITemplateFolderConstructor extends ITemplateFolderConstructorBase {
    subFolders?: { [key: string]: DesignTemplateFolderDetail; };
}
