<div class="modal-header" id="{{id}}-confirm-change-popup">
    <h3 class="modal-title" [innerHtml]="title"></h3>

    <button [id]="id + '-close-button'" type="button" class="close" *ngIf="canDismiss" (click)="dismiss('x-click')">
        <span>&times;</span>
    </button>
</div>

<div class="modal-body" [innerHtml]="message" [class.hide-additional]="hideAdditionalInfo"></div>

<div class="modal-footer">
    <button (click)="toggleShowMore()" *ngIf="hasShowMore" class="modal-button button button-default">
        <span>{{showMoreButtonText}}</span>
    </button>

    <button [id]="id + '-button-1'" type="button" class="modal-button button button-default"
        (click)="onCancel()" *ngIf="hasOnCancel">
        <span class="text">{{cancelButtonText}}</span>
    </button>

    <button [id]="id + '-button-2'" type="button" class="modal-button button button-CTA"
        (click)="onConfirm()" *ngIf="hasOnConfirm">
        <span class="text">{{confirmButtonText}}</span>
    </button>
</div>
