import { Directive, ElementRef, HostListener, Input, EventEmitter, Output } from '@angular/core';

@Directive({
    selector: '[appDrop]'
})
export class DropDirective {

    @Input()
    public active: boolean;

    @Input()
    public disabled: boolean;

    @Output()
    public designDrop: EventEmitter<DragEvent> = new EventEmitter<DragEvent>();

    constructor(
        public elementRef: ElementRef
    ) {}

    @HostListener('drop', ['$event'])
    public onDrop(event: DragEvent): void {
        if (this.IsInactiveOrDisabled()) {
            return;
        }
        this.designDrop.emit(event);
        (event.currentTarget as HTMLElement).classList.remove('dragover');
    }

    @HostListener('dragenter', ['$event'])
    public onDragEnter(event: DragEvent): void {
        if (this.IsInactiveOrDisabled()) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        (event.currentTarget as HTMLElement).classList.add('dragover');
    }


    @HostListener('dragover', ['$event'])
    public onDragOver(event: DragEvent): void {
        if (this.IsInactiveOrDisabled()) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
    }

    @HostListener('dragleave', ['$event'])
    public onDragLeave(event: DragEvent): void {
        if (this.IsInactiveOrDisabled()) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        (event.currentTarget as HTMLElement).classList.remove('dragover');
    }

    private IsInactiveOrDisabled() : boolean
    {
        return !this.active || this.disabled;
    }

}