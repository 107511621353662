import { Component, OnInit } from '@angular/core';
import {
    LicenseSuite
} from '@profis-engineering/pe-ui-common/entities/code-lists/license-suite';
import {
    TrialBannerFeature as TrialBannerFeatureEntity
} from '@profis-engineering/pe-ui-common/entities/code-lists/trial-banner-feature';
import {
    LicenseSuiteType, TrialBannerFeature
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    LicenseKeySuffix, UserLicenseFeature as UserLicenseFeatures
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Licensing.Enums';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import {
    CommonCodeList
} from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { ButtonEventType } from '@profis-engineering/pe-ui-common/services/common-tracking.common';
import { AuthenticationService } from '../../services/authentication.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { CommonTrackingService } from '../../services/common-tracking.service';
import { LicenseService } from '../../services/license.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { OfflineService } from '../../services/offline.service';
import { ProductInformationService } from '../../services/product-information.service';
import { UserSettingsService } from '../../services/user-settings.service';

export enum TrialBannerModalType {
    trial,
    trialExpired,
    standard
}

@Component({
    selector: 'app-trial-banner',
    templateUrl: './trial-banner.component.html',
    styleUrls: ['./trial-banner.component.scss']
})
export class TrialBannerComponent implements OnInit {
    public modalTypeEnum = TrialBannerModalType;

    public submitted: boolean;

    public showLicenseComparisonButton = false;
    public showPurchaseButton = false;


    constructor(
        private localization: LocalizationService,
        private license: LicenseService,
        private userSettings: UserSettingsService,
        private offline: OfflineService,
        private modalInstance: ModalInstance,
        private modal: ModalService,
        private commonCodeList: CommonCodeListService,
        private commonTrackingService: CommonTrackingService,
        private productInformation: ProductInformationService,
        private authentication: AuthenticationService,
    ) { }


    public get modalType() {
        if (this.license.isTrialExpired()) {
            return TrialBannerModalType.trialExpired;
        }
        if (this.license.isNoLicenseHandledAsFree()) {
            return TrialBannerModalType.standard;
        }
        if (this.license.isTrial()) {
            return TrialBannerModalType.trial;
        }

        throw new Error('Trial banner modal not supported for current license.');
    }

    public get licenseSuites() {
        return this.commonCodeList.commonCodeLists[CommonCodeList.LicenseSuite] as LicenseSuite[];
    }

    public get getSectionTitle() {
        switch (this.modalType) {
            case TrialBannerModalType.trialExpired:
            case TrialBannerModalType.standard:
                return this.localization.getString('Agito.Hilti.Profis3.TrialExpired.Section.Title');

            case TrialBannerModalType.trial:
                {
                    const translation = this.localization.getString('Agito.Hilti.Profis3.Trial.Section.Title');
                    return formatKeyValue(translation, { number: this.license.getRemainingDays().toString() });
                }

            default:
                throw new Error('Unsupported trial banner modal type.');
        }
    }

    public get trialBannerInfoText() {
        switch (this.modalType) {
            case TrialBannerModalType.trialExpired:
            case TrialBannerModalType.standard:
                // Trial expiration information text
                return 'Agito.Hilti.Profis3.TrialExpired.Info';

            case TrialBannerModalType.trial:
                // For HNA users - notify them that standard license will be available after trial
                if (this.hasHnaLicenseSuffix()) {
                    return 'Agito.Hilti.Profis3.Trial.Info.NoLink.HNA';
                }

                // Trial license information text
                if (this.showLicenseComparisonButton) {
                    return 'Agito.Hilti.Profis3.Trial.Info';
                }

                return 'Agito.Hilti.Profis3.Trial.Info.NoLink';

            default:
                throw new Error('Unsupported trial banner modal type.');
        }
    }

    public get comparisonRedirectUrl() {
        return this.productInformation.regionLinksForUser()?.UpgradeToPremiumLink;
    }

    ngOnInit(): void {
        this.setMainButtonVisibility();

        // do not close modal if save is pending
        this.modalInstance.setOnClosing(() => {
            if (!this.submitted && (this.modalType == TrialBannerModalType.trialExpired || this.modalType == TrialBannerModalType.standard)) {
                return false;
            }

            return true;
        });
    }

    public purchase() {
        if (this.comparisonRedirectUrl != null) {
            const comparisonRedirectUrl = this.comparisonRedirectUrl
                .replace('{code}', this.license.licenseSuiteItemCode(this.licenseSuites, LicenseSuiteType.Premium));

            if (!this.offline.isOffline) {
                window.open(comparisonRedirectUrl, '_blank');
            } else {
                this.offline.nativeExternalLinkOpen(comparisonRedirectUrl);
            }
        }
    }

    public continueWithStandard() {
        this.submitted = true;

        // save continue clicked to user settings to skip opening license popup next time
        this.userSettings.settings.stayOnStandardLicense.value = true;
        this.userSettings.save();

        // track event
        this.commonTrackingService.trackOnButtonClick(ButtonEventType.ContinueWithStandard);

        this.close();
    }

    public close() {
        this.modalInstance.close();
    }

    public logout() {
        this.authentication.logout();
    }

    public licenseCompare() {
        this.submitted = true;

        this.modal.openLicenseComparison();

        this.close();
    }

    private hasHnaLicenseSuffix() {
        const license = this.license.userLicense;

        const trialLicenseIndex = license.Features.indexOf(UserLicenseFeatures.Trial);
        const trialLicenseDetails = license.LicenseTypes[trialLicenseIndex];

        return trialLicenseDetails.KeySuffix == LicenseKeySuffix.HNA;
    }

    private setMainButtonVisibility() {
        if (this.modalType == TrialBannerModalType.standard) {
            // When handling no license as free, always display purchaser button.
            this.showPurchaseButton = true;
            return;
        }

        const region = this.userSettings.getActiveRegion();

        // Get the regions that should not display the comparison button
        const trialBannerFeatures = this.commonCodeList.commonCodeLists[CommonCodeList.TrialBannerFeature] as TrialBannerFeatureEntity[];
        const noLicenseComparisonButtonRegions = trialBannerFeatures.find((f) => f.id === TrialBannerFeature.NoLicenseComparisonButton).regions;

        // If user is from a country that should not display the comparison button we show purchase button
        if (noLicenseComparisonButtonRegions.some((r) => r === region.id)) {
            if (this.userSettings.isUpgradeLicenseAvailable()) {
                this.showPurchaseButton = true;
            }
        }
        else {
            this.showLicenseComparisonButton = true;
        }
    }
}
