<div class="textbox-wrapper"
    [class.hilti-styled]="isHiltiStyled"
    [class.large]="isLarge">
    <pe-control-title
        class="control-title"
        [focusControlId]="id"
        [title]="title"
        [look]="controlTitleStyle"
        [tooltip]="tooltip"
        [infoPopup]="infoPopup"
        [infoPopupTooltip]="infoPopupTooltip">
    </pe-control-title>

    <div class="control-container">
        <input [id]="id" [name]="id" class="input" [type]="type"
            [class.submitted]="submitted"
            [minLength]="minLength" [attr.maxLength]="maxLength != null ? maxLength : null"
            [ngModel]="value" (ngModelChange)="ngModelChange($event)" #ngModel="ngModel"
            [ngModelOptions]="updateOnBlur ? { updateOn: 'blur' } : {}"
            [disabled]="disabled" [attr.placeholder]="placeholder != null ? placeholder : null"
            autocomplete="off" autocorrect="off" autocapitalize="off"
            spellcheck="false" (blur)="blur?.emit($any($event).target.value)"
            (keydown.enter)="onEnterPressed($any($event))" />
    </div>

    <pe-validation-error
        *ngIf="showValidationErrors"
        class="validation-error"
        [ngErrors]="ngModel?.control?.errors"
        [patternExample]="validationData?.patternExample">
    </pe-validation-error>
</div>
