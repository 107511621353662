<div class="module-quick-start-button" *ngIf="moduleEnabled && !useNewHomePage" (click)="clickEvent.emit()"
    [ngClass]="{ 'disabled': disabled }">
    <div class="image-container" [class.show-loading]="isLoading">
        <span *ngIf="imageSprite != null" class="model3d-image sprite {{imageSprite}}"></span>
        <img *ngIf="imageSrc != null" class="model3d-image sprite" src="{{imageSrc}}" alt="External Application">
        <span *ngIf="imageSprite == null && imageSrc == null" class="model3d-image sprite sprite-pe-desktop"></span>

        <div class="loading-container">
            <pe-loading [color]="'#524f53'"></pe-loading>
        </div>
    </div>

    <div *ngIf="displayIsNewLabel" class="new-label">
        <span class="text" l10n="Common.QuickStartButtons.NewStatus"></span>
    </div>

    <div class="content-container">
        <span class="text action" l10n="Agito.Hilti.Profis3.Decking.ProjectAndDesign.Main.QuickStart.Action"></span>
        <span *ngIf="titleText" class="text design-type">{{titleText}}</span>
        <span *ngIf="!titleText" class="text design-type" l10n="{{titleTextTranslationKey}}"></span>
        <span class="text settings">{{regionText}}</span>
    </div>
</div>


<div class="quick-start-button" id="quick-start-button-concrete" *ngIf="moduleEnabled && useNewHomePage"
    (click)="clickEvent.emit()" [ngClass]="{ 'disabled': disabled }">
    <div class="image-container" [class.show-loading]="isLoading">
        <img *ngIf="imageSrc != null" class="model3d-image sprite" src="{{imageSrc}}" alt="External Application">
        <span *ngIf="imageSprite != null" class="model3d-image sprite {{imageSprite}}"></span>
        <div class="loading-container">
            <pe-loading [color]="loaderColour"></pe-loading>
        </div>
    </div>
    <div class="content-container">
        <div class="badge-container" >
            <span class="text new-badge" *ngIf="displayIsNewLabel" l10n="Common.QuickStartButtons.NewStatus"></span>
        </div>
        <div class="text design-type-settings">
            <span *ngIf="titleText" class="text design-type">{{titleText}}</span>
            <span *ngIf="!titleText" class="text design-type" l10n="{{titleTextTranslationKey}}"></span>
            <span class="text settings"> {{regionText}} </span>
        </div>
    </div>
</div>
