import sortBy from 'lodash-es/sortBy';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
    TextBoxProps
} from '@profis-engineering/pe-ui-common/components/text-box/text-box.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { DateTimeService } from '../../../services/date-time.service';
import { DocumentService } from '../../../services/document.service';
import { LocalizationService } from '../../../services/localization.service';
import { ModalService } from '../../../services/modal.service';
import { emailsValidator } from '../../../validators/emails.validator';
import { Document, DocumentUser, SharedDocumentUser } from '../../../entities/document';
import { UserService } from '../../../services/user.service';
import { SharedTemplateUser, Template, TemplateUser } from '../../../entities/template';
import { DesignTemplateService } from '../../../services/design-template.service';

export interface IShareViewComponentInput {
    selectedDocument?: Document;
    selectedTemplate?: Template;
    isTemplate?: boolean;
    onAddOrRemoveUser?: (modal: boolean) => any;
}

@Component({
    selector: 'app-share-view',
    templateUrl: './share-view.component.html',
    styleUrls: ['./share-view.component.scss']
})
export class ShareViewComponent implements OnInit {
    public documentSharedUsers: DocumentUser[];
    public templateSharedUsers: TemplateUser[];
    public submitted: boolean;
    public userTextBox: TextBoxProps = {};

    public sharedUsersHeaderHeight = 39;    // must be the same value as in css
    public sharedUsersRowHeight = 35;       // must be the same value as in css
    public scrollElement: Element;

    private pendingSave: boolean;
    @Output() emitService = new EventEmitter();


    constructor(
        public localization: LocalizationService,
        public dateTime: DateTimeService,
        private document: DocumentService,
        private templateService: DesignTemplateService,
        private modal: ModalService,
        private userService: UserService,
        private modalInstance: ModalInstance<IShareViewComponentInput>
    ) { }

    public get selectedDocument() {
        return this.modalInstance.input?.selectedDocument;
    }

    public get selectedTemplate() {
        return this.modalInstance.input?.selectedTemplate;
    }

    public get isTemplate() {
        return this.modalInstance?.input?.isTemplate ?? false;
    }

    public get hideShareTable() {
        return this.isTemplate ? this.templateSharedUsers.length < 1 : this.documentSharedUsers.length < 1;
    }

    public get formValid() {
        return this.userTextBox?.isValid;
    }

    ngOnInit(): void {
        // don't close the modal if save is pending
        this.modalInstance.setOnClosing(() => {
            if (this.isTemplate) {
                if (this.pendingSave) {
                    return false;
                }
                return true;
            }

            if (this.selectedDocument.users?.length == 0) {
                this.modalInstance.input.onAddOrRemoveUser(true);
            }
            if (this.pendingSave) {
                return false;
            }
            return true;
        });

        this.scrollElement = document.querySelector('.modal');

        this.isTemplate ? this.updateTemplateSharedUsers() : this.updateDocumentSharedUsers();

        this.userTextBox = {
            id: 'share-project-add-users',
            title: this.localization.getString('Agito.Hilti.Profis3.ShareProject.AddUserTitle'),
            placeholder: this.localization.getString('Agito.Hilti.Profis3.ShareProject.AddUserPlaceholder'),
            maxLength: 250,
            validators: [emailsValidator(';')]
        };

        setTimeout(() => this.userTextBoxFocus());
    }

    public close() {
        this.modalInstance.close();
    }

    public addUserForDocument(form: NgForm) {
        if (this.submitted
            || this.pendingSave
            || !this.formValid
            || (form.enabled && !form.valid)
            || this.userTextBox.value == null
            || this.userTextBox.value.trim() == ''
        ) {
            return;
        }

        this.submitted = true;
        this.pendingSave = true;

        const inputUser = this.userTextBox.value.toLowerCase().trim();
        let users = inputUser.split(';').map(it => it.trim()).filter(it => it != '' && it != this.userService.authentication.userName);
        const now = new Date();
        const nowUtc = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds()));
        const selectedDocument = this.selectedDocument;
        for (const user of users) {
            // add only if entry does not exist in the selected project
            if (selectedDocument.users.findIndex((item) => item.documentid === selectedDocument.id && item.user.toLowerCase() === user.toLowerCase()) >= 0) {
                const removeUser = selectedDocument.users.find((item) => item.documentid === selectedDocument.id && item.user.toLowerCase() === user.toLowerCase());
                users = users.filter((u) => u != removeUser.user);
            }
        }
        if(users.length > 0)
        {
            const userSharedDocument: SharedDocumentUser = {
                documentId: this.selectedDocument.id,
                projectId: this.selectedDocument.projectId,
                sharedWithUsers: users,
                dateAdded: nowUtc
            };

            this.document.addUsersOnDocument(userSharedDocument)
            .then(() => {
                for (const user of users) {
                    const selectedUser: DocumentUser = {
                        documentid: selectedDocument.id,
                        user: user,
                        modified: true,
                        dateadded: nowUtc,
                    };
                    selectedDocument.users.push(selectedUser);
                }
            })
            .catch((err) => {
                    console.error(err);
            }).finally(() => {
                    this.updateDocumentSharedUsers();
                    this.modalInstance.input.onAddOrRemoveUser(true);
            })
        }
        this.submitted = false;
        this.pendingSave = false;
        this.userTextBox.value = null;

    }

    public deleteUserForDocument(user: DocumentUser) {
        if (this.submitted || this.pendingSave) {
            return;
        }

        const opened = this.modal.openConfirmChange({
            id: 'confirm-delete-document-permanently',
            title: this.localization.getString('Agito.Hilti.Profis3.ShareProject.ConfirmDelete.Title'),
            message: this.localization.getString('Agito.Hilti.Profis3.ShareDocument.ConfirmDelete.Message'),
            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.ShareProject.ConfirmDelete.Ok'),
            cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.ShareProject.ConfirmDelete.Cancel'),
            onConfirm: (modal) => {
                modal.close(true);
            },
            onCancel: (modal) => {
                modal.close(false);
            }
        }).result;

        this.submitted = this.pendingSave = true;

        opened
            .then((result: boolean) => {
                // Confirm closed
                new Promise<void>((resolve, reject) => {
                    if (result) {
                        const now = new Date();
                        const nowUtc = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds()));
                        const userSharedDocument: SharedDocumentUser = {
                            documentId: this.selectedDocument.id,
                            projectId: this.selectedDocument.projectId,
                            sharedWithUsers: [user.user],
                            dateAdded: nowUtc,
                        };
                        // mark design for shared by me only if , users length is 0
                        const usersLength = this.selectedDocument.users.filter((item) => item != user).length
                        this.document.removeUsersOnDocument(userSharedDocument, usersLength == 0)
                            .then(() => {
                                this.selectedDocument.users = this.selectedDocument.users.filter((item) => item != user);
                                this.updateDocumentSharedUsers();
                                resolve();
                            });
                        return;
                    }

                    reject('canceled');
                }).finally(() => {
                    this.submitted = this.pendingSave = false;
                });
            })
            .catch(() => {
                // Confirm dismissed
                this.submitted = this.pendingSave = false;
            });
    }

    public addUserForTemplate(form: NgForm) {
        if (this.submitted
            || this.pendingSave
            || !this.formValid
            || (form.enabled && !form.valid)
            || this.userTextBox.value == null
            || this.userTextBox.value.trim() == ''
        ) {
            return;
        }

        this.submitted = true;
        this.pendingSave = true;

        const inputUser = this.userTextBox.value.toLowerCase().trim();
        let users = inputUser.split(';').map(it => it.trim()).filter(it => it != '' && it != this.userService.authentication.userName);
        const now = new Date();
        const nowUtc = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds()));
        const selectedTemplate = this.selectedTemplate;
        for (const user of users) {
            // add only if entry does not exist in the selected project
            if (selectedTemplate.users.findIndex((item) => item.templateId === selectedTemplate.id && item.user.toLowerCase() === user.toLowerCase()) >= 0) {
                const removeUser = selectedTemplate.users.find((item) => item.templateId === selectedTemplate.id && item.user.toLowerCase() === user.toLowerCase());
                users = users.filter((u) => u != removeUser.user);
            }
        }
        if(users.length > 0)
        {
            const userSharedTemplate: SharedTemplateUser = {
                templateId: this.selectedTemplate.id,
                templateFolderId: this.selectedTemplate.templateFolderId,
                sharedWithUsers: users,
                dateAdded: nowUtc
            };

            this.templateService.addUsersOnTemplate(userSharedTemplate)
            .then(() => {
                for (const user of users) {
                    const selectedUser: TemplateUser = {
                        templateId: selectedTemplate.id,
                        user: user,
                        modified: true,
                        dateadded: nowUtc,
                    };
                    selectedTemplate.users.push(selectedUser);
                }
            })
            .catch((err) => {
                console.error(err);
            }).finally(() => {
                this.updateTemplateSharedUsers();
                this.modalInstance.input.onAddOrRemoveUser(true);
            })
        }
        this.submitted = false;
        this.pendingSave = false;
        this.userTextBox.value = null;
    }

    public deleteUserForTemplate(user: TemplateUser) {
        if (this.submitted || this.pendingSave) {
            return;
        }

        const opened = this.modal.openConfirmChange({
            id: 'confirm-delete-document-permanently',
            title: this.localization.getString('Agito.Hilti.Profis3.ShareProject.ConfirmDelete.Title'),
            message: this.localization.getString('Agito.Hilti.Profis3.ShareDocument.ConfirmDelete.Message'),
            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.ShareProject.ConfirmDelete.Ok'),
            cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.ShareProject.ConfirmDelete.Cancel'),
            onConfirm: (modal) => {
                modal.close(true);
            },
            onCancel: (modal) => {
                modal.close(false);
            }
        }).result;

        this.submitted = this.pendingSave = true;

        opened
            .then((result: boolean) => {
                // Confirm closed
                new Promise<void>((resolve, reject) => {
                    if (result) {
                        const now = new Date();
                        const nowUtc = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds()));
                        const userSharedTemplate: SharedTemplateUser = {
                            templateId: this.selectedTemplate.id,
                            templateFolderId: this.selectedTemplate.templateFolderId,
                            sharedWithUsers: [user.user],
                            dateAdded: nowUtc,
                        };
                        // mark design for shared by me only if , users length is 0
                        const usersLength = this.selectedTemplate.users.filter((item) => item != user).length
                        this.templateService.removeUsersOnTemplate(userSharedTemplate, usersLength == 0)
                            .then(() => {
                                this.selectedTemplate.users = this.selectedTemplate.users.filter((item) => item != user);
                                this.updateTemplateSharedUsers();
                                resolve();
                            });
                        return;
                    }

                    reject('canceled');
                }).finally(() => {
                    this.submitted = this.pendingSave = false;
                    this.modalInstance.input.onAddOrRemoveUser(true);
                });
            })
            .catch(() => {
                // Confirm dismissed
                this.submitted = this.pendingSave = false;
            });
    }

    private updateDocumentSharedUsers() {
        const selectedDocument = this.selectedDocument;
        if (selectedDocument?.users == null) {
            this.documentSharedUsers = [];
            return;
        }

        this.documentSharedUsers = sortBy(
            selectedDocument.users,
            (user) => user.dateadded
        ).reverse();
    }

    private updateTemplateSharedUsers() {
        const selectedTemplate = this.selectedTemplate;
        if (selectedTemplate?.users == null) {
            this.templateSharedUsers = [];
            return;
        }

        this.templateSharedUsers = sortBy(
            selectedTemplate.users,
            (user) => user.dateadded
        ).reverse();
    }

    private userTextBoxFocus() {
        document.querySelector<HTMLElement>('#share-project-add-users-wrapper')
            ?.shadowRoot?.querySelector<HTMLElement>('#share-project-add-users')
                ?.focus();
    }
}
