<div class="box-section-header">
    <div class="box-section-title container-header">
        <span class="title" l10n="Agito.Hilti.Profis3.HomePage.QuickStart.Title"></span>
        <div class="middle-section"></div>
        <div class="right-section">
            <ng-container *ngFor="let quickStart of quickStartApplications">
                <button id="{{quickStart.idCollapsed}}" class="button button-default quick-start-button"
                    (click)="newDesignFromQuickStart(quickStart)" type="button"
                    *ngIf="collapsed && quickStart.showInCollapsedState && SafeFunctionInvokerHelper.safeInvoke(quickStart.isEnabled, false)"
                    [disabled]="SafeFunctionInvokerHelper.safeInvoke(quickStart.isDesignTypeDisabled, true) || isActionDisabled"
                    [ngbTooltip]="SafeFunctionInvokerHelper.safeInvoke(quickStart.getButtonTooltip)">
                    <pe-loading-button [loading]="showLoading(quickStart)">
                        <span class="text">{{quickStart.collapsedTextTranslation ??
                            this.translate(quickStart.collapsedTextTranslationKey)}}</span>
                    </pe-loading-button>
                </button>
            </ng-container>
            <ng-container *ngFor="let woodLink of woodLinks; trackBy : trackApplicationById">
                <button class="button button-default quick-start-button"
                    *ngIf="isWoodModuleShown && collapsed" [disabled]="isActionDisabled"
                    (click)="openWoodLinks(woodLink.Url)" type="button">
                    <pe-loading-button>
                        <span class="text" l10n="Agito.Hilti.Profis3.HomePage.QuickStart.Trimble.DesignType"></span>
                    </pe-loading-button>
                </button>
            </ng-container>
            <ng-container *ngFor="let externalApp of externalAppsService.externalApps; trackBy : trackApplicationById">
                <button class="button button-default quick-start-button" *ngIf="collapsed"
                    [disabled]="isActionDisabled" (click)="openExternalAppLink(externalApp.Id)" type="button">
                    <pe-loading-button>
                        <span class="text">{{externalAppsService.getExternalAppTitle(externalApp,
                            'Agito.Hilti.Profis3.HomePage.QuickStart.ExternalApplications')}}</span>
                    </pe-loading-button>
                </button>
            </ng-container>
        </div>
    </div>
    <pe-collapse [container]="'box-section-content'" [collapsed]="collapsed"
        (collapsedChange)="onSectionCollapsedChange(sectionsCollapsed.quickStart, $any($event).detail)">
    </pe-collapse>
</div>
<div class="box-section-content">
    <div class="container-content">
        <ng-container *ngFor="let quickStart of quickStartApplications">
            <div class="quick-start-button" id="{{ quickStart.idQuickStart }}"
                *ngIf="quickStart.showInQuickStart && SafeFunctionInvokerHelper.safeInvoke(quickStart.isEnabled, false)"
                [ngClass]="{ 'disabled': (SafeFunctionInvokerHelper.safeInvoke(quickStart.isDesignTypeDisabled, true) || isActionDisabled) }"
                (click)="newDesignFromQuickStart(quickStart)">
                <div class="image-container" [ngClass]="{ 'show-loading': showLoading(quickStart) }">
                    <span class="model3d-image sprite" [ngStyle]="quickStart.imageStyle"></span>
                    <div class="loading-container">
                        <pe-loading [color]="loaderColour"></pe-loading>
                    </div>
                </div>
                <div class="content-container"
                    [ngbTooltip]="(quickStart.designTypeTranslation ?? translate(quickStart.designTypeTranslationKey)) + ' / ' + SafeFunctionInvokerHelper.safeInvoke(quickStart.createRegionDesignStandardApprovalNumber, '')">
                    <div class="badge-container">
                        <span class="text new-badge"
                            *ngIf="quickStart.showNewLabel != null ? SafeFunctionInvokerHelper.safeInvoke(quickStart.showNewLabel, false) : false"
                            l10n="{{ quickStart.newLabelTranslationKey }}">
                        </span>
                    </div>
                    <div class="app-type-settings">
                        <span class="text design-type">
                            {{ quickStart.designTypeTranslation ?? translate(quickStart.designTypeTranslationKey) }}
                        </span>
                        <span class="text settings">
                            {{ SafeFunctionInvokerHelper.safeInvoke(quickStart.createRegionDesignStandardApprovalNumber,
                            '')}}
                        </span>
                    </div>
                </div>
            </div>
        </ng-container>

        <app-module-quick-start-button imageSprite="sprite-wood-connection"
            titleTextTranslationKey="Agito.Hilti.Profis3.HomePage.QuickStart.Trimble.DesignType"
            [moduleEnabled]="isWoodModuleShown" [disabled]="isActionDisabled"
            *ngFor="let woodLink of woodLinks; trackBy : trackApplicationById" (click)="openWoodLinks(woodLink.Url)">
        </app-module-quick-start-button>

        <app-module-quick-start-button
            *ngFor="let externalApp of externalAppsService.externalApps; trackBy : trackApplicationById"
            imageSrc="{{externalApp.Image}}"
            titleText="{{externalAppsService.getExternalAppTitle(externalApp, 'Agito.Hilti.Profis3.HomePage.QuickStart.ExternalApplications')}}"
            [moduleEnabled]="true" [disabled]="isActionDisabled" [displayIsNewLabel]="externalApp.ShowNewLabel"
            (click)="openExternalAppLink(externalApp.Id)">
        </app-module-quick-start-button>
    </div>
</div>
