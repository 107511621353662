import { Component, OnInit } from '@angular/core';
import {
    CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import {
    SimpleCheckboxButtonHelper
} from '@profis-engineering/pe-ui-common/helpers/simple-checkbox-button-helper';

import { BrowserService } from '../../services/browser.service';
import { CommonTrackingService } from '../../services/common-tracking.service';
import { LocalizationService } from '../../services/localization.service';
import { MarketingService } from '../../services/marketing.service';
import { OfflineService } from '../../services/offline.service';
import { UserSettingsService } from '../../services/user-settings.service';

@Component({
    selector: 'app-user-agreement-settings',
    templateUrl: './user-agreement-settings.component.html',
    styleUrls: ['./user-agreement-settings.component.scss']
})
export class UserAgreementSettingsComponent implements OnInit {
    public submitted = false;

    public diagnosticsAgreementCheckbox: CheckboxButtonProps<boolean>;

    constructor(
        public localization: LocalizationService,
        private userSettings: UserSettingsService,
        private offline: OfflineService,
        private browser: BrowserService,
        private modalInstance: ModalInstance,
        private commonTrackingService: CommonTrackingService,
        private marketingService: MarketingService
    ) { }

    public get isNotifyAgreementDisabled() {
        return this.offline.isOffline;
    }

    public get commLinkTooltip() {
        return this.localization.getString('Agito.Hilti.Profis3.Browser.NoInternetConnection');
    }

    public get commLinkTooltipEnabled() {
        return !this.browser.isOfflineOnLine;
    }

    ngOnInit(): void {
        this.diagnosticsAgreementCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            checked: this.userSettings.settings.application.general.userPrivacy.value,
            itemText: this.localization.getString('Agito.Hilti.Profis3.UserAgreementSettings.Diagnostics.Name'),
            itemDescription: this.localization.getString('Agito.Hilti.Profis3.UserAgreementSettings.Diagnostics.Description'),
        });

        // do not close modal if save is pending
        this.modalInstance.setOnClosing(() => {
            return !this.submitted;
        });
    }

    public close() {
        this.modalInstance.close();
    }

    public openMarketingCommunicationPreferencesUrl() {
        if (this.commLinkTooltipEnabled) {
            return;
        }

        const url = this.marketingService.getDPRUrl();
        this.offline.nativeExternalLinkOpen(url);
    }

    public get showMarketingCommunicationPreferences(){
        return this.marketingService.getDPRUrl() != undefined && this.marketingService.getDPRUrl() != '';
    }

    public save() {
        this.submitted = true;

        // just save locally
        const diagnosticsAgreement = SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.diagnosticsAgreementCheckbox);
        this.userSettings.settings.application.general.userPrivacy.value = diagnosticsAgreement;

        this.userSettings.save()
            .finally(() => {
                // CR: we are not waiting for HideMail to finish?
                // why is this in the finally clause?
                // method names should be camelcase
                // why is this called every time?
                this.commonTrackingService.hideMail(diagnosticsAgreement);

                this.submitted = false;

                // CR: even if we fail we close the dialog?
                this.close();
            })
            .catch((err) => {
                if (err instanceof Error) {
                    console.error(err);
                }

                this.submitted = false;
            });
    }
}
