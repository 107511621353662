import { Injectable } from '@angular/core';
import { TranslationFormat } from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Common.Entities.Translation';
import { TranslationFormatServiceBase } from '@profis-engineering/pe-ui-shared/services/translation-format.service.base';

@Injectable({
    providedIn: 'root'
})
export class TranslationFormatServiceInjected extends TranslationFormatServiceBase {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public getLocalizedStringWithTranslationFormat(
        _translationFormat: TranslationFormat,
        _roundValue?: boolean,
        _preTransformedParams?: Record<string, string>): string {
        return '';
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
