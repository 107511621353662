import * as React from 'react';

import { ButtonSize, ButtonType, IButtonProps } from '@profis-engineering/pe-ui-common/entities/main-menu/button-props';
import { getMenuControlImageReactData } from '../../../../helpers/react-helpers';
import { ControlHeader } from '../../ControlHeader';
import { buildHtmlTooltip, isControlHidden } from '../../MainMenuHelper';
import { Loading } from '../Loading/Loading';

export class Button extends React.PureComponent<IButtonProps> {
    constructor(props?: IButtonProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    public override render() {
        if (isControlHidden(this.props)) {
            return null;
        }

        const buttonId = `${this.props.controlId}-button`;
        const buttonSizeClass = this.props.buttonSize == ButtonSize.Full ? 'size-full' : '';
        const buttonTypeClass = this.generateButtonTypeClassName(this.props.buttonType);

        const tooltip = this.props.title != null && this.props.title != '' ? null : buildHtmlTooltip(this.props.tooltip, this.props.tooltipTitle);

        const content: React.ReactNode[] = [];

        // image & CSS
        const imageData = getMenuControlImageReactData(
            this.props.image,
            this.props.imageStyle
        );

        if (imageData.elementStyle != null || imageData.spriteName != null && imageData.spriteName != '') {
            content.push(
                <div className='image-container' key='image'>
                    <span className={`image sprite ${imageData.spriteName}`} style={imageData.elementStyle}></span>
                </div>
            );
        }

        // text
        if (this.props.text != null && this.props.text != '') {
            content.push(
                <div className='text' key='text'>{this.props.text}</div>
            );
        }

        // loading
        if (this.props.loading) {
            content.push(
                <div className='loading-container' key='loading'>
                    <Loading width={32} height={8} color={'#524f53'}></Loading>
                </div>
            );
        }

        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled ? this.props.tooltipDisabled : this.props.tooltip;

        return (
            <div data-control-id={this.props.controlId} className={`react-control-button control ${this.props.sizeClass} ${this.props.className || ''}`}>
                <ControlHeader
                    text={this.props.title}
                    controlId={buttonId}
                    tooltip={tooltipText}
                    tooltipTitle={tooltipTitle}
                    localization={this.props.localization} />

                <div className='control-container'
                    data-tip={tooltip}
                    data-html={tooltip != null ? true : null}>
                    <button
                        data-control-id={buttonId}
                        className={`button-element button ${buttonSizeClass} ${buttonTypeClass} ${this.props.loading ? 'loading-button show-loading' : ''}`}
                        type='button'
                        disabled={this.props.disabled || this.props.loading}
                        onClick={this.onClick}>
                        {content}
                    </button>
                </div>
            </div>
        );
    }

    private onClick(event: React.MouseEvent) {
        if (this.props.clicked != null) {
            this.props.clicked();
        }
    }

    private generateButtonTypeClassName(buttonType: ButtonType) {
        switch (buttonType) {
            case ButtonType.Default:
                return 'button-default';
            case ButtonType.Primary:
                return 'button-primary';
            case ButtonType.Ghost:
                return 'button-ghost';
            case ButtonType.Link:
                return 'button-link';
            case ButtonType.LargerGray:
                return 'button-larger-gray';
            case ButtonType.AnchorAI:
                return 'button-anchor-ai';
            default:
                return 'button-default';
        }
    }
}
