import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import {
    FeatureVisibilityServiceBase
} from '@profis-engineering/pe-ui-common/services/feature-visibility.common';
import { FeatureFlag } from '../entities/feature-flags';
import { LaunchDarklyService } from './launch-darkly.service';

@Injectable({
    providedIn: 'root'
})
export class FeatureVisibilityService extends FeatureVisibilityServiceBase<FeatureFlag> {
    private featureStore: Partial<Record<FeatureFlag, unknown>> = {};

    constructor(
        private launchDarklyService: LaunchDarklyService
    ) {
        super();
    }

    public registerFeature<TValue>(feature: FeatureFlag, value: TValue): void {
        this.featureStore[feature] = value;
    }


    public getFeatureValue<TValue>(feature: FeatureFlag, defaultValue: TValue): TValue {
        return this.featureStore[feature] as TValue ?? this.launchDarklyService.getFeatureValue(feature, defaultValue);
    }

    public isFeatureEnabled(feature: FeatureFlag): boolean {
        const featureValue = (this.featureStore[feature] ?? false) || this.launchDarklyService.getFeatureValue<boolean>(feature, false);
        // Make sure the value is actual boolean (strict check). Otherwise all non-null values will be considered as enabled.
        return featureValue === true;
    }

    public allFlags() {
        return this.launchDarklyService.ldClient.allFlags();
    }

    public get init(): BehaviorSubject<boolean> {
        return this.launchDarklyService.init;
    }
}
