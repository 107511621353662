<div class="section modal-body">
    <!-- body text-->
    <div class="section trial-info">
        <span class="section trial-info-title"
            l10n="{{isCheckbot ? 'Agito.Hilti.Profis3.Integrations.Checkbot.Mapping.Modal.Licence.Comparison.Title' : 'Agito.Hilti.Profis3.FreeTrial.Body.Title'}}"></span>
        <span class="section trial-info-text"
            l10n="{{isCheckbot ? 'Agito.Hilti.Profis3.Integrations.Checkbot.Mapping.Modal.Licence.Comparison.SubTitle' : 'Agito.Hilti.Profis3.FreeTrial.Body.Subtitle'}}"></span>
        <!-- License feature comparison -->
        <app-license-comparison-table></app-license-comparison-table>

        <div class="section start-trial">
            <button (click)="getPremium()" id="get-premium"
                class="hilti-styled-button hilti-styled-button-primary save-button" *ngIf="isUpgradeLicenseAvailable">
                <div class="hilti-styled-text">{{comparisonRedirectText}}</div>
            </button>

            <button (click)="stayOnStandard()" id="stay-on-standard"
                class="hilti-styled-button hilti-styled-button-gray">
                <div class="hilti-styled-text" l10n="Agito.Hilti.Profis3.StayOnStandard.Button.Text"></div>
            </button>
        </div>
    </div>
</div>
