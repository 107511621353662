import { Component, OnInit } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { IDesignTemplateDocument } from '@profis-engineering/pe-ui-common/services/design-template.common';

import { DesignTemplateService } from '../../services/design-template.service';
import { LocalizationService } from '../../services/localization.service';
import { UserService } from '../../services/user.service';
import { LeftNavigationPrimaryButtons } from '../home-page/home-page.common';

export interface ISaveAsTemplateComponentInput {
    designTemplateDocument: IDesignTemplateDocument,
    thumbnailId?: string,
    onTemplateSaved: (templateId: string) => void;
}

enum Type {
    add,
    edit
}

@Component({
    selector: 'app-save-as-template',
    templateUrl: './save-as-template.component.html',
    styleUrls: ['./save-as-template.component.scss']
})
export class SaveAsTemplateComponent implements OnInit {
    public submitted: boolean;
    public name: string;
    public nameValid = false;

    public requiredValidator = Validators.required;
    public nameMaxLength = 250;

    private pendingSave: boolean;

    constructor(
        public localization: LocalizationService,
        private designTemplate: DesignTemplateService,
        private user: UserService,
        public modalInstance: ModalInstance<ISaveAsTemplateComponentInput>
    ) { }

    public get designTemplateDocument() {
        return this.modalInstance.input?.designTemplateDocument;
    }

    public get thumbnailId() {
        return this.modalInstance.input?.thumbnailId;
    }

    public get titleKey() {
        return 'Agito.Hilti.Profis3.SaveAsTemplate.' + (this.type == Type.add ? 'AddTitle' : 'EditTitle');
    }

    public get formValid() {
        return this.nameValid;
    }

    private get type() {
        return this.designTemplateDocument.designTemplateDocumentId
            ? Type.edit
            : Type.add;
    }

    private get onTemplateSaved() {
        return this.modalInstance.input?.onTemplateSaved;
    }

    ngOnInit(): void {
        // don't close the modal if save is pending
        this.modalInstance.setOnClosing(() => {
            return this.pendingSave
                ? false
                : true;
        });

        this.name = this.type == Type.edit
            ? this.designTemplateDocument.templateName
            : null;

        setTimeout(() => this.nameTextBoxFocus());
    }

    public async save(form: NgForm) {
        if (this.submitted || !this.formValid || (form.enabled && !form.valid)) {
            return;
        }

        const designTemplateDocument = { ...this.designTemplateDocument };
        designTemplateDocument.templateName = this.name;

        this.submitted = true;
        this.pendingSave = true;

        let templateId: string = designTemplateDocument.designTemplateDocumentId;
        try {
            if (this.type == Type.add) {
                templateId = await this.designTemplate.create(designTemplateDocument, this.thumbnailId);
            }
            else {
                await this.designTemplate.update(designTemplateDocument);
            }
            this.user.projectAndDesignViewV2 = { primarySelection: LeftNavigationPrimaryButtons.Templates };
            this.user.setTemplateFolder(null);
        } catch (error) {
            if (error instanceof Error) {
                console.error(error);
            }
            this.submitted = false;
        }
        this.pendingSave = false;
        this.close();

        if (this.submitted) {
            this.onTemplateSaved?.(templateId);
        }
    }

    public close() {
        this.modalInstance.close();
    }

    private nameTextBoxFocus() {
        document.querySelector<HTMLElement>('.modal-body #create-template-name-wrapper')
            ?.shadowRoot?.querySelector<HTMLElement>('#create-template-name')
            ?.focus();
    }
}
