import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot
} from '@angular/router';

import { TrimbleConnectService } from '../services/trimble-connect.service';
import { handleGuardAction } from './guard-handler';

export const trimbleConnectAuthCallbackGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router = inject(Router);
    const trimbleConnectService = inject(TrimbleConnectService);

    const code = state.root.queryParamMap.get('code');
    const urlState = state.root.queryParamMap.get('state');

    return handleGuardAction(router, async () => {
        await trimbleConnectService.loginAuthCallback(code, urlState);
        return true;
    });
}