import { HttpParameterCodec } from '@angular/common/http';

export class CustomURLEncoder implements HttpParameterCodec {
    public encodeKey(key: string): string {
        return encodeURIComponent(key);
    }

    public encodeValue(key: string): string {
        return encodeURIComponent(key);
    }

    public decodeKey(key: string): string {
        return decodeURIComponent(key);
     }

     public decodeValue(key: string) {
        return decodeURIComponent(key);
    }
}
