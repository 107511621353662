import { Injectable } from '@angular/core';
import { CloseVirtualTourMenuActions, OpenVirtualTourMenuActions } from '@profis-engineering/pe-ui-common/entities/main-header';
import {
    SafeFunctionInvokerHelper
} from '@profis-engineering/pe-ui-common/helpers/safe-function-invoker-helper';
import {
    ILocalizationExtension
} from '@profis-engineering/pe-ui-common/services/extensions.common';
import {
    IGetStringOptions
} from '@profis-engineering/pe-ui-common/services/localization.common';
import {
    IntroJs, NavigationTourStatus, Step, TourServiceBase
} from '@profis-engineering/pe-ui-common/services/tour.common';
import { introJs, Options } from '../../scripts/agito.intro.cjs';
import { getCSSFromIconStyle, getSpriteAsIconStyleResponsive } from '../helpers/sprites';
import { CommonTrackingService } from './common-tracking.service';
import { FeatureVisibilityService } from './feature-visibility.service';
import { LocalizationService } from './localization.service';
import { ModulesService } from './modules.service';
import { OfflineService } from './offline.service';

@Injectable({
    providedIn: 'root'
})
export class TourService extends TourServiceBase {
    public localizationExtension: ILocalizationExtension;

    private rootElement: HTMLElement = document.documentElement;
    public exitTour = false;
    public tour!: IntroJs;
    public navigationTourInProgress = NavigationTourStatus.none;
    public startNewHomePageTour = false;

    constructor(
        private featureVisibilityService: FeatureVisibilityService,
        private offlineService: OfflineService,
        private localizationService: LocalizationService,
        private modulesService: ModulesService,
        private commonTrackingService: CommonTrackingService
    ) {
        super();
    }

    public get isNavigationTourInProgress(): boolean {
        return this.navigationTourInProgress != NavigationTourStatus.none
    }

    public startNavigationTour(startNewHomePageTour: boolean) {
        this.navigationTourInProgress = NavigationTourStatus.start;
        this.startNewHomePageTour = startNewHomePageTour;
    }

    public onNewDesignFromQuickStart() {
        if (this.tour != null) {
            this.navigationTourInProgress = NavigationTourStatus.continue;
            this.close();
        }
    }

    public start(options: Options, tourName?: string) {
        const tour = introJs();

        tour.onbeforechange((el, step) => {
            if (step == -1) {
                return;
            }

            tour.setOption("disableInteraction", options.steps[step].disableInteraction ?? true);

            if (options.steps[step].onBeforeChange) {
                options.steps[step].onBeforeChange();
            }
        });

        tour.onafterchange((el, step) => {
            if (this.exitTour) {
                tour.exit();
                this.exitTour = false;
            }

            if (step == -1) {
                return;
            }

            if (options.steps[step].onAfterChange) {
                options.steps[step].onAfterChange();
            }
        });

        tour.onexit(() => {
            // clear tour after
            setTimeout(() => { this.tour = undefined; });
            // log virtual tour
            if (tourName != null) {
                this.commonTrackingService.trackVirtualTour(tourName, this.tour.currentStep(), this.tour.stepsCount());
            }
        });

        tour.oncomplete(() => {
            // clear tour after
            setTimeout(() => { this.tour = undefined; });
            // log virtual tour
            if (tourName != null) {
                this.commonTrackingService.trackVirtualTour(tourName, this.tour.currentStep(), this.tour.stepsCount());
            }
        })

        options = {
            disableInteraction: true,
            exitOnEsc: false,
            exitOnOverlayClick: false,
            showProgress: false,
            showStepNumbers: false,
            skipLabel: this.getTranslation('Agito.Hilti.Profis3.Tour.SkipLabel'),
            prevLabel: this.getTranslation('Agito.Hilti.Profis3.Tour.PrevLabel'),
            nextLabel: this.getTranslation('Agito.Hilti.Profis3.Tour.NextLabel'),
            doneLabel: this.getTranslation('Agito.Hilti.Profis3.Tour.DoneLabel'),
            ...options
        };

        tour.setOptions(options);

        tour.start();
        this.tour = tour;
        return tour;
    }

    public introStepTemplate(titleKey: string, textKey: string, numberOfSteps?: number, currentStep?: number) {

        const title = this.getTranslation(titleKey);
        const text = this.getTranslation(textKey);
        const step = currentStep && numberOfSteps ? `${currentStep} / ${numberOfSteps}` : '';
        // No title
        if (titleKey == '') {
            return `<div>${text}</div>`;
        }
        return `<div class="stepTitle">${title}<span>${step}</span></div><div>${text}</div>`;
    }

    public introFirstStepTemplate(titleKey: string, subtitleKey: string, textKey: string) {
        const title = this.getTranslation(titleKey);
        const subtitle = this.getTranslation(subtitleKey);
        const text = this.getTranslation(textKey);

        const spriteResponsive = getSpriteAsIconStyleResponsive('sprite-pe-desktop');

        return `<div class="virtual-tour-title-subtitle">
                    <div class="image-container">
                        <div class="sprite sprite-responsive" style="${getCSSFromIconStyle(spriteResponsive)}"></div>
                    </div>
                    <div>
                        <div class="virtual-tour-large-title">${title}</div>
                        <div class="virtual-tour-large-subtitle">${subtitle}</div>
                    </div>
                </div>
                <div class="virtual-tour-text">${text}</div>`;
    }

    public openNavigationTour(newDesignFromDesignTypeIdFn?: (designTypeId: number) => void, mainHeaderShowVirtualTourFn?: () => void) {
        // BUDQBP-32398: Use new virtual tour instead of navigation tour. But we leave the code until cleanup, if we need to disable new tour.
        const tour = this.newNavigationTour(newDesignFromDesignTypeIdFn, mainHeaderShowVirtualTourFn);
        this.navigationTourInProgress = NavigationTourStatus.none;
        this.startNewHomePageTour = false;

        return tour;
    }

    public navigationTour() {
        const tour = this.start({
            highlightClass: 'no-background',
            steps: this.getNavigationTourSteps()
        }, 'NavigationWalkthrough');

        return tour;
    }

    public homepageTour(
        isFirstTimeUser: boolean,
        openOrCloseNewDropdownFn: (action: 'open' | 'close') => void,
        virtualTourProjectsActionFn: (action: 'exit' | 'expandMyProject' | 'expandSharedProject') => void,
        openMainHeaderMenuFn: (action: OpenVirtualTourMenuActions) => void,
        closeMainHeaderMenuFn: (action: CloseVirtualTourMenuActions) => void) {

        this.startNewHomePageTour = false;
        this.navigationTourInProgress = NavigationTourStatus.none;

        const tour = this.start({
            showBullets: true,
            useNewDesign: true,
            steps: this.getHomepageTourSteps(isFirstTimeUser, openOrCloseNewDropdownFn, virtualTourProjectsActionFn, openMainHeaderMenuFn, closeMainHeaderMenuFn),
            doneLabel: this.getTranslation('Agito.Hilti.Profis3.Tour.CloseLabel'),
        }, 'HomePageWalkthrough');
        tour.onexit(() => {
            document.querySelector<HTMLElement>('#application-settings-cancel-button')?.click()
            openOrCloseNewDropdownFn('close');
            closeMainHeaderMenuFn('CloseAll');
            virtualTourProjectsActionFn('exit');
            setTimeout(() => {
                if (isFirstTimeUser) {
                    this.afterExitingHomePageVirtualTour(openMainHeaderMenuFn, closeMainHeaderMenuFn);
                }
            });
        });
        tour.oncomplete(() => {
            document.querySelector<HTMLElement>('#application-settings-cancel-button')?.click()
            openOrCloseNewDropdownFn('close');
            closeMainHeaderMenuFn('CloseAll');
            virtualTourProjectsActionFn('exit');
            setTimeout(() => {
                if (isFirstTimeUser) {
                    this.afterExitingHomePageVirtualTour(openMainHeaderMenuFn, closeMainHeaderMenuFn);
                }
            });
        });

        return tour;
    }

    public newNavigationTour(newDesignFromDesignTypeIdFn?: (designTypeId: number) => void, mainHeaderShowVirtualTourFn?: () => void) {
        const tour = this.start({
            highlightClass: 'no-background',
            steps: this.getNewNavigationTourSteps(newDesignFromDesignTypeIdFn),
        }, 'WelcomeToPROFISEngineering');

        tour.onexit(() => {
            setTimeout(() => {
                if (!this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage')) {
                    setTimeout(() => { this.afterExitingVirtualTour(this.rootElement.querySelector<HTMLElement>('pe-main-header'), mainHeaderShowVirtualTourFn) });
                }
            });
        });

        if (this.navigationTourInProgress == NavigationTourStatus.continue) {
            tour.goToStep(this.offlineService.isOffline ? 2 : 3);
        }

        return tour;
    }

    public getDesignTours() {
        // Get all tours that are available for design. Tours which have order == null should only be opened by name, so we filter them out.
        const tours = this.modulesService.getVirtualTours()
            .filter(x => x.order != null && SafeFunctionInvokerHelper.safeInvoke(x.isAvailable, false))
            .sort((a, b) => ((a.order ?? 0) - (b.order ?? 0)));

        return tours;
    }

    public openTourByName(name: string, selectTab: (tab: string) => void) {
        const tour = this.modulesService.getVirtualTours()
            .find(x => x.name == name);

        if (tour == null) {
            throw Error(`Tour with name ${name} not found.`);
        }

        try {
            tour.openTour(selectTab);
        }
        catch (err) {
            console.error(err)
        }

    }

    public afterExitingHomePageVirtualTour(openVirtualTourMenuFn: (action: OpenVirtualTourMenuActions) => void, closeVirtualTourMenuFn: (action: CloseVirtualTourMenuActions) => void) {
        const translationKey = 'Agito.Hilti.Profis3.VirtualTour.AfterExitVirtualTour';
        const mainElement = this.rootElement.querySelector<HTMLElement>('pe-main-header').shadowRoot
        const tour = this.start({
            highlightClass: 'no-background',
            steps: [{
                element: () => mainElement.querySelector<HTMLElement>('.learn-menu-container'),
                intro: this.introStepTemplate(`${translationKey}.Title`, `${translationKey}.Text`),
                position: 'left-main-menu',
                onBeforeChange: () => {
                    openVirtualTourMenuFn('OpenLearnMenu');
                },
                onAfterChange: () => {
                    const virtualTourMenuElement = mainElement.querySelector('#project-and-design-header-right-user-options-virtual-tours');
                    const topOffset = virtualTourMenuElement.getBoundingClientRect().top;
                    const toolTip = this.rootElement.querySelector<HTMLElement>('.introjs-tooltip');
                    toolTip.style.top = `${topOffset}px`;
                },
                afterBeforeChangeTimeout: 1,
                tooltipClass: 'virtual-tour-popup-modal',
                removeButtonsSeperator: true,
                skipButtonClass: "introjs-button-hilti-styled"
            }]
        });
        tour.oncomplete(() => { closeVirtualTourMenuFn('CloseLearnMenu'); });
        return tour;
    }
    public afterExitingVirtualTour(mainHeaderElement: HTMLElement, mainHeaderShowVirtualTourFn?: () => void) {
        const translationKey = 'Agito.Hilti.Profis3.VirtualTour.AfterExitVirtualTour';
        const steps = [
            {
                element: () => mainHeaderElement.shadowRoot?.querySelector<HTMLElement>('.header-right').querySelector<HTMLElement>('.tour'),
                intro: this.introStepTemplate(`${translationKey}.Title`, `${translationKey}.Text`),
                onBeforeChange: async () => {
                    if (mainHeaderShowVirtualTourFn != null) {
                        mainHeaderShowVirtualTourFn();
                    }
                },
                position: 'left',
                tooltipClass: 'virtual-tour-popup-modal'
            }
        ];
        const tour = this.start({
            highlightClass: 'no-background',
            steps: steps
        });

        return tour;
    }

    private getHomepageTourSteps(
        isFirstTimeUser: boolean,
        openOrCloseNewDropdownFn: (action: 'open' | 'close') => void,
        virtualTourProjectsActionFn: (action: 'exit' | 'expandMyProject' | 'expandSharedProject') => void,
        openVirtualTourMenuFn: (action: OpenVirtualTourMenuActions) => void,
        closeVirtualTourMenuFn: (action: CloseVirtualTourMenuActions) => void) {
        const mainHeader = this.rootElement?.querySelector('pe-main-header');
        const steps: Step[] = [];
        if (isFirstTimeUser) {
            steps.push(
                {
                    intro: this.introFirstStepTemplate('Agito.Hilti.Profis3.HomepageWalkthrough.FirstPageTitle',
                        'Agito.Hilti.Profis3.HomepageWalkthrough.FirstPageSubtitle',
                        'Agito.Hilti.Profis3.HomepageWalkthrough.Text'),
                    position: 'floating',
                    tooltipClass: 'virtual-tour-popup-modal virtual-tour-large'
                });
        }
        return [
            ...steps,
            {
                element: () => this.rootElement.querySelector<HTMLElement>('.create-import-design'),
                intro: this.introStepTemplate('Hilti.Profis3.HomePageWalktrough.New.Title', 'Hilti.Profis3.HomePageWalktrough.New.Description'),
                position: 'right',
                tooltipClass: 'virtual-tour-popup-modal',
                onBeforeChange: () => {
                    openOrCloseNewDropdownFn('open');
                }
            },
            {
                element: () => this.rootElement.querySelector<HTMLElement>('#shared-projects-tree'),
                intro: this.introStepTemplate('Hilti.Profis3.HomePageWalktrough.SharedProjects.Title', 'Hilti.Profis3.HomePageWalktrough.SharedProjects.Description'),
                position: 'right',
                tooltipClass: 'virtual-tour-popup-modal',
                onBeforeChange: () => {
                    virtualTourProjectsActionFn('expandSharedProject');
                    document.getElementById('Shared-Shared')?.scrollIntoView();
                },
                afterBeforeChangeTimeout: 2
            },
            {
                element: () => this.rootElement.querySelector<HTMLElement>('#my-projects-tree'),
                intro: this.introStepTemplate('Hilti.Profis3.HomePageWalktrough.MyProjects.Title', 'Hilti.Profis3.HomePageWalktrough.MyProjects.Description'),
                position: 'right',
                tooltipClass: 'virtual-tour-popup-modal',
                onBeforeChange: () => {
                    virtualTourProjectsActionFn('expandMyProject');
                    document.getElementById('virtual-tour-my-projects-my-sub-project-3')?.scrollIntoView();
                },
                afterBeforeChangeTimeout: 2
            },
            {
                element: () => this.rootElement.querySelector<HTMLElement>('.design-view-box-container').parentElement,
                intro: this.introStepTemplate('Hilti.Profis3.HomePageWalktrough.Designs.Title', 'Hilti.Profis3.HomePageWalktrough.Designs.Description'),
                position: 'right',
                tooltipClass: 'virtual-tour-popup-modal virtual-tour-popup-home-page',
                useFixParentRelative: true,
                onBeforeChange: () => {
                    document.querySelector<HTMLElement>('#application-settings-cancel-button')?.click();
                    this.rootElement.querySelector<HTMLElement>('.design-view-box-container')?.scrollIntoView();
                },
                afterBeforeChangeTimeout: 2
            },
            {
                element: () => mainHeader.shadowRoot.querySelector<HTMLElement>('#project-and-design-header-right-settings-button'),
                intro: this.introStepTemplate('Hilti.Profis3.HomePageWalktrough.ApplicationSettings.Title', 'Hilti.Profis3.HomePageWalktrough.ApplicationSettings.Description'),
                position: 'bottom',
                tooltipClass: 'virtual-tour-popup-modal',
                onBeforeChange: () => {
                    closeVirtualTourMenuFn('CloseSupportMenu');
                    openVirtualTourMenuFn('OpenApplicationSettings');
                }
            },
            {
                element: () => mainHeader.shadowRoot.querySelector<HTMLElement>('.support-menu-container'),
                intro: this.introStepTemplate('Hilti.Profis3.HomePageWalktrough.SupportMenu.Title', 'Hilti.Profis3.HomePageWalktrough.SupportMenu.Description'),
                position: 'left-main-menu',
                tooltipClass: 'virtual-tour-popup-modal',
                onBeforeChange: () => {
                    document.querySelector<HTMLElement>('#application-settings-cancel-button')?.click();
                    closeVirtualTourMenuFn('CloseLearnMenu');
                    openVirtualTourMenuFn('OpenSupportMenu');
                }
            },
            {
                element: () => mainHeader.shadowRoot.querySelector<HTMLElement>('.learn-menu-container'),
                intro: this.introStepTemplate('Hilti.Profis3.HomePageWalktrough.LearnMenu.Title', 'Hilti.Profis3.HomePageWalktrough.LearnMenu.Description'),
                position: 'left-main-menu',
                tooltipClass: 'virtual-tour-popup-modal',
                onBeforeChange: () => {
                    closeVirtualTourMenuFn('CloseSupportMenu');
                    closeVirtualTourMenuFn('CloseProfileMenu');
                    openVirtualTourMenuFn('OpenLearnMenu');
                }
            },
            {
                element: () => mainHeader.shadowRoot.querySelector<HTMLElement>('.profile-menu-container'),
                intro: this.introStepTemplate('Hilti.Profis3.HomePageWalktrough.ProfileMenu.Title', 'Hilti.Profis3.HomePageWalktrough.ProfileMenu.Description'),
                position: 'left-main-menu',
                tooltipClass: 'virtual-tour-popup-modal',
                onBeforeChange: () => {
                    closeVirtualTourMenuFn('CloseLearnMenu');
                    openVirtualTourMenuFn('OpenProfileMenu');
                }
            }
        ];
    }

    private getNavigationTourSteps() {
        const title = 'Agito.Hilti.Profis3.VirtualTour.NavigationWalktrough';
        const numberOfSteps = 3;

        return [
            {
                element: () => this.rootElement.querySelector<HTMLElement>('.main-header'),
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.TourProjectAndDesign.MessageHeader', numberOfSteps, 1),
                position: 'bottom-middle-aligned',
                tooltipClass: 'virtual-tour-popup-modal'
            },
            {
                element: () => this.rootElement.querySelector<HTMLElement>('.navigation'),
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.TourProjectAndDesign.MessageNavigation', numberOfSteps, 2),
                position: 'right',
                tooltipClass: 'virtual-tour-popup-modal'
            },
            {
                element: () => this.rootElement.querySelector<HTMLElement>('.new-design-box'),
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.TourProjectAndDesign.MessageNewDesign', numberOfSteps, 3),
                position: 'auto',
                tooltipClass: 'virtual-tour-popup-modal'
            }
        ];
    }

    // BUDQBP-32646: new tour, will replace old one
    private getNewNavigationTourSteps(newDesignFromDesignTypeIdFn?: (designTypeId: number) => void) {
        const title = 'Agito.Hilti.Profis3.HomepageWalkthrough.Title';
        const numberOfSteps = this.offlineService.isOffline ? 11 : 12;
        let currentStep = 2;
        const steps: Step[] = [
            {
                intro: this.introFirstStepTemplate('Agito.Hilti.Profis3.HomepageWalkthrough.FirstPageTitle',
                    'Agito.Hilti.Profis3.HomepageWalkthrough.FirstPageSubtitle',
                    'Agito.Hilti.Profis3.HomepageWalkthrough.Text'),
                position: 'floating',
                tooltipClass: 'virtual-tour-popup-modal virtual-tour-large'
            }
        ];

        if (!this.offlineService.isOffline) {
            steps.push({
                element: () => this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage')
                    ? this.rootElement.querySelector<HTMLElement>('.content-left')
                    : this.rootElement.querySelector<HTMLElement>('.navigation'),
                intro: this.introStepTemplate(title, this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage') ? 'Agito.Hilti.Profis3.NewHomepageWalkthrough.Step1' : 'Agito.Hilti.Profis3.HomepageWalkthrough.Step1', numberOfSteps, currentStep++),
                position: 'right',
                tooltipClass: 'virtual-tour-popup-modal'
            });
        }
        steps.push(
            {
                element: () => this.rootElement.querySelector<HTMLElement>('#quick-start-button-concrete'),
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.HomepageWalkthrough.Step2', numberOfSteps, currentStep++),
                position: 'bottom-middle-aligned',
                tooltipClass: 'virtual-tour-popup-modal',
                disableInteraction: false,
                useFixParentRelative: true
            },
            {
                intro: this.introStepTemplate(title, '', numberOfSteps, currentStep++),
                onBeforeChange: () => {
                    this.navigationTourInProgress = NavigationTourStatus.continue;
                    this.exitTour = true;
                },
                onAfterChange: () => {
                    if (newDesignFromDesignTypeIdFn != null) {
                        newDesignFromDesignTypeIdFn(1);
                    }
                },
                position: 'auto',
                tooltipClass: 'virtual-tour-popup-modal'
            });

        const virtualTourGeneralStep = {
            intro: this.introStepTemplate(title, '', numberOfSteps, currentStep++),
            position: 'auto',
            tooltipClass: 'virtual-tour-popup-modal'
        };

        steps.push(
            virtualTourGeneralStep,
            virtualTourGeneralStep,
            virtualTourGeneralStep,
            virtualTourGeneralStep,
            virtualTourGeneralStep,
            virtualTourGeneralStep,
            virtualTourGeneralStep,
            virtualTourGeneralStep);

        return steps;
    }

    private getTranslation(key: string, opts?: IGetStringOptions): string {
        return this.localizationExtension?.getTranslation ? this.localizationExtension.getTranslation(key, opts) : this.localizationService.getString(key, opts);
    }

    private close() {
        if (this.tour != null) {
            this.tour.exit();
            this.tour = undefined;
        }
    }
}
