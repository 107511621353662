import { Injectable } from '@angular/core';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';
import {
    IAuthentication, ISubscriptionInfo
} from '@profis-engineering/pe-ui-common/services/user.common';

import { environment } from '../../environments/environment';
import { storageKey, urlPath } from '../module-constants';
import { AppStorageService } from './app-storage.service';
import { AuthenticationService } from './authentication.service';
import { DataService } from './data.service';
import { GeneralErrorService } from './general-error.service';
import { LoggerService } from './logger.service';
import { ILogonResult, OfflineService } from './offline.service';
import { QueryService } from './query.service';
import { RoutingService } from './routing.service';
import { SessionStorageService } from './session-storage.service';
import { offlineLoginDateStorageKey, UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class CallbackService {
    constructor(
        private routingService: RoutingService,
        private userService: UserService,
        private sessionStorageService: SessionStorageService,
        private loggerService: LoggerService,
        private generalErrorService: GeneralErrorService,
        private dataService: DataService,
        private authenticationService: AuthenticationService,
        private offlineService: OfflineService,
        private queryService: QueryService,
        private appStorageService: AppStorageService
    ) { }

    public async processLoginCallback(code: string, state: string) {
        this.userService.invalidateAuthentication();

        const returnUrl = this.sessionStorageService.get<string>(storageKey.loginReturnUrl);
        this.sessionStorageService.remove(storageKey.loginReturnUrl);

        if (environment.authentication == 'oauth2') {
            if (this.sessionStorageService.get<string>('code_verifier') == null) {
                // code_verifier can be null in rare cases, one example is when user clicks on login url in registration email (because login url redirect directly to callback, without going to our first page).
                // In that case we have to restart the whole login procedure to generate new code_verifier.
                await this.authenticationService.login(returnUrl);
            }
        }

        // get code
        if (code == null) {
            this.loggerService.log('Missing authentication code!', LogType.error);

            this.generalErrorService.showErrorGeneral = true;

            return;
        }

        // get state
        const sessionState = this.sessionStorageService.get(storageKey.loginState);
        if (state == null || sessionState != null && sessionState != state) {
            this.loggerService.log('Failed to verify state!', LogType.error);

            this.generalErrorService.showErrorGeneral = true;

            return;
        }

        // clear state key
        this.sessionStorageService.remove(storageKey.loginState);

        const response = await this.getToken(code);
        await this.authenticationService.ensureLicense(response);

        const isAuthenticated = this.trySetAuthenticated(response);

        if (isAuthenticated) {
            if (this.offlineService.isOffline) {
                const currentDate = new Date();
                this.appStorageService.set(offlineLoginDateStorageKey, currentDate);
            }

            const url = returnUrl ?? urlPath.projectAndDesign;
            await this.routingService.navigateToUrl(`${url}${this.queryService.getInitialQuery(false)}`);
            return;
        }

        this.loggerService.log('Authentication failed!', LogType.error);

        this.generalErrorService.showErrorGeneral = true;
    }

    public processLogoutCallback() {
        return this.authenticationService.login();
    }

    public async processRegistrationCallback(state: string) {
        const returnUrl = this.sessionStorageService.get<string>(storageKey.registrationReturnUrl);
        this.sessionStorageService.remove(storageKey.registrationReturnUrl);

        // get state
        const sessionState = this.sessionStorageService.get(storageKey.registrationState);
        if (state == null || sessionState != null && sessionState != state) {
            this.loggerService.log('Failed to verify state!', LogType.error);

            this.generalErrorService.showErrorGeneral = true;

            return;
        }

        // clear state key
        this.sessionStorageService.remove(storageKey.registrationState);

        // go through login again to get new token with user account data
        await this.authenticationService.login(returnUrl);
    }

    private async getToken(code: string) {
        if (environment.authentication == 'local') {
            return this.generateLocalToken();
        }

        try {
            return await this.offlineService.getToken(code);
        }
        catch (error) {
            this.generalErrorService.showErrorGeneral = true;

            throw error;
        }
    }

    private trySetAuthenticated(authenticatinoData: ILogonResult) {
        const accessToken = authenticatinoData.access_token;
        const subscriptionInfo = authenticatinoData.subscription_info;

        return this.loginOnline(accessToken, subscriptionInfo);
    }

    private loginOnline(
        accessToken: string,
        subscriptionInfo: ISubscriptionInfo
    ) {

        const userId: string = subscriptionInfo.UID;
        const userName: string = subscriptionInfo.LogonID;
        const authorizationEntryList = subscriptionInfo.AuthorizationEntryList[0];
        const license: string = authorizationEntryList.Licenses;
        const customerId: string = authorizationEntryList.CustomerID;
        const customerOriginId: string = authorizationEntryList.CustomerOriginID;
        const contactId: string = authorizationEntryList.ContactIDs;

        let result = false;

        if (accessToken) {
            const authenticationData: IAuthentication = {
                accessToken,
                license,
                userId,
                userName,
                externalUserId: null,   // don't need this for online, just for offline
                externalUserName: null, // don't need this for online, just for offline
                customerId,
                customerOriginId,
                contactId,
                country: subscriptionInfo.Country,
                subscription_info: subscriptionInfo,
                countryOfResidence: subscriptionInfo.CountryOfResidence
            };

            this.userService.setAuthenticated(authenticationData, this.offlineService.isOffline);

            result = true;
        }

        return result;
    }

    private generateLocalToken() {

        const generateAccessToken = () => {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYSabcdefghijklmnopqrstuvwxyz0123456789.-_';
            let result = '';

            for (let i = 0; i < 1416; i++) {
                result += characters.charAt(Math.floor(Math.random() * characters.length));
            }

            return result;
        };

        const generateLicenses = () => {
            const obj = {
                application_name: 'P3 Baseplate Engineering Web D1',
                valid_until: '2099-01-12T11:11:11Z',
                licenses: [
                    {
                        name: 'P3 Eng Web  - Basic / User',
                        key: 'DL0ZA7642C',
                        valid_until: '2019-04-30T00:00:00Z',
                        extension: false
                    },
                    {
                        name: 'P3 Eng Web  - Basic / User',
                        key: '40CKEH02B4',
                        valid_until: '2019-04-30T00:00:00Z',
                        extension: false
                    },
                    {
                        name: 'Hrail',
                        key: 'FKPAC9Z1V8',
                        valid_until: '2019-04-30T00:00:00Z',
                        extension: true
                    },
                    {
                        name: 'P3 Eng/HRail Pack - Web / Single / D1',
                        key: 'WTR2AOUZBH',
                        valid_until: '2099-01-12T11:11:11Z',
                        extension: false
                    }
                ],
                features: [
                    {
                        name: 'Basic',
                        key: 'BASIC'
                    },
                    {
                        name: 'Hrail',
                        key: 'HRAIL'
                    },
                    {
                        name: 'Dlubal',
                        key: 'DLUBAL'
                    },
                    {
                        name: 'Advnc',
                        key: 'ADVNC'
                    },
                    {
                        name: 'Tekla',
                        key: 'TEKLA'
                    }
                ]
            };

            return btoa(JSON.stringify(obj));
        };

        const userId = '7cd76c29cb794905ac96ab0adff915d3';
        const userName = 'justin.bieber@agito.si';

        const customerId = '602588';
        const customerOriginId = '0010314854';
        const contactOriginId = '0002076956';
        const country = 'GB';

        const resultData: ILogonResult = {
            access_token: generateAccessToken(),
            subscription_info: {
                AuthorizationEntryList: [{
                    Scopes: '',
                    CustomerID: customerId,
                    CustomerName: '',
                    ContactIDs: '',
                    ContactFirstName: '',
                    ContactLastName: '',
                    Roles: '',
                    Licenses: generateLicenses(),
                    CustomerOriginID: customerOriginId,
                    ContactOriginID: contactOriginId,
                    City: '',
                    Country: country,
                    PostalCode: '',
                    SecondaryName: '',
                    Street: ''
                }],
                ClientID: '',
                LogonID: userName,
                CustomerID: customerId,
                UID: userId,
                Country: country,
                CountryOfResidence: ''
            },
            externalUserId: userId,
            externalUserName: userName,
        };

        return resultData;
    }
}
