import { Component } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';

import { AuthenticationService } from '../../services/authentication.service';
import { LocalizationService } from '../../services/localization.service';
import { RoutingService } from '../../services/routing.service';

export interface IDesktopLicenseWarningComponentInput {
    isExpirationWarning: boolean;
    username: string;
}

@Component({
    selector: 'app-desktop-license-warning',
    templateUrl: './desktop-license-warning.component.html',
    styleUrls: ['./desktop-license-warning.component.scss']
})
export class DesktopLicenseWarningComponent {
    constructor(
        private localization: LocalizationService,
        private authenticationService: AuthenticationService,
        private routingService: RoutingService,
        private modalInstance: ModalInstance<IDesktopLicenseWarningComponentInput>
    ) { }

    public get message() {
        if (this.isExpirationWarning) {
            return this.localization.getString('Agito.Hilti.Profis3.Desktop.LicenseExpirationWarning.Message');
        }
        else {
            return this.localization.getString('Agito.Hilti.Profis3.Desktop.UsernameWarning.Message').replace('{username}', this.modalInstance.input.username);
        }
    }

    public get isExpirationWarning() {
        return this.modalInstance.input?.isExpirationWarning;
    }

    public login() {
        this.authenticationService.login(this.routingService.currentPath);
    }

    public dismiss(reason?: string) {
        this.modalInstance.dismiss(reason);
    }

    public close() {
        this.modalInstance.close();
    }
}
