import debounce from 'lodash-es/debounce';

import { HttpRequest } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { MenuType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.UserSettings.Shared.Enums';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import { RegionOrderServiceBase } from '@profis-engineering/pe-ui-common/services/region-order.common';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { FavoritesService } from './favorites.service';
import { GuidService } from './guid.service';
import { UserService } from './user.service';

const updateDelay = 500;

export interface UserSortedTabPostEntity {
    menuId: number;
    sortedTabRegionsIds: string[];
}

@Injectable({
    providedIn: 'root'
})
export class RegionOrderService extends RegionOrderServiceBase {
    public updating: boolean;

    private debounceSave: typeof this.saveInternal;
    private setDefer: Deferred<string[]> = new Deferred();
    private updateId: string;

    constructor(
        private readonly favoritesService: FavoritesService,
        private readonly guidService: GuidService,
        private readonly userService: UserService,
        private readonly apiService: ApiService
    ) {
        super();

        this.debounceSave = debounce((...args: Parameters<typeof this.saveInternal>) => {
            NgZone.assertInAngularZone();

            return this.saveInternal(...args);
        }, updateDelay);
    }

    public async update(order: string[], menuId: MenuType): Promise<string[]> {
        order = order || [];
        const currentOrder = this.favoritesService.order || [];

        // don't update if order didn't change
        if (order.length == currentOrder.length && order.every((x, i) => x == currentOrder[i])) {
            return order;
        }

        this.favoritesService.order = this.getNewOrder(this.favoritesService.order, order);
        this.updating = true;
        this.updateId = this.guidService.new();

        const promise = this.setDefer.promise;

        if (environment.enableMenuFavorites) {
            this.debounceSave(order, this.updateId, menuId);
        }

        return await promise;
    }

    private async saveToDatabase(order: string[], menuId: number): Promise<void> {
        if (!this.userService.isAuthenticated) {
            throw new Error('Unauthenticated');
        }

        const url = `${environment.userSettingsWebServiceUrl}Favorites/UpdateUserMenuOrder`;

        const data: UserSortedTabPostEntity = {
            menuId,
            sortedTabRegionsIds: order
        };

        await this.apiService.request<boolean>(new HttpRequest('PUT', url, data));
    }

    private getNewOrder(oldOrder: string[], newOrder: string[]): string[] {
        const order = oldOrder.filter(x => !newOrder.some(y => x == y));
        newOrder.forEach(x => order.push(x));
        return order;
    }

    private saveOrder(order: string[], menuId: MenuType): void {
        switch (menuId) {
            case MenuType.LeftMenuProject:
                this.favoritesService.leftMenuProjectOrder = order;
                return;
            case MenuType.Menu2D:
                this.favoritesService.order2D = this.getNewOrder(this.favoritesService.order2D, order);
                return;
            case MenuType.Menu3D:
                this.favoritesService.order = this.getNewOrder(this.favoritesService.order, order);
                return;
            case MenuType.Menu3DRight:
                this.favoritesService.menu3DRightOrder = order;
                return;
            case MenuType.RightMenuProject:
                this.favoritesService.rightMenuProjectOrder = order;
                return;
            default:
                throw new Error('Unknown menu type');
        }
    }

    private async saveInternal(order: string[], updateId: string, menuId: MenuType): Promise<void> {
        try {
            await this.saveToDatabase(order, menuId);

            this.saveOrder(order, menuId);
            this.setDefer.resolve(order);
        }
        catch (error) {
            this.setDefer.reject(error);
        }

        // remove updating flag
        if (this.updateId == updateId) {
            this.updating = false;
        }

        this.setDefer = new Deferred();
    }
}
