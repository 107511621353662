import {
    HttpErrorResponse, HttpHeaders, HttpParams, HttpRequest, HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IImportData } from '@profis-engineering/pe-ui-common/entities/import-data';
import {
    TrimbleDeleteFolderRequest, TrimbleItemEntity, TrimbleProjectEntity, TrimbleRenameFolderRequest,
    TrimbleUploadFileRequest
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.TrimbleConnectService.Shared.Entities';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import getPkce from 'oauth-pkce';

import { IApplicationError } from '@profis-engineering/pe-ui-common/entities/application-error';
import { ITrimbleConnectFileInfo, ITrimbleConnectSession } from '@profis-engineering/pe-ui-common/entities/trimble-connect';
import { randomString } from '@profis-engineering/pe-ui-common/helpers/random';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';
import { TrimbleConnectServiceBase } from '@profis-engineering/pe-ui-common/services/trimble-connect.common';
import { environment } from '../../environments/environment';
import {
    TrimbleConnectBrowserMode, TrimbleConnectItemType
} from '../components/trimble-connect-browser/trimble-connect-browser-models';
import { Design } from '../entities/design';
import { Project } from '../entities/project';
import { urlPath } from '../module-constants';
import { ApiService } from './api.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { ModalService } from './modal.service';
import { OfflineService } from './offline.service';
import { RoutingService } from './routing.service';
import { SessionStorageService } from './session-storage.service';

export interface ITrimbleToken {
    access_token: string;
}

export type ITCItemType = 'FILE' | 'FOLDER';

export type ITCErrorCode =
    'API_INVALID_ACCESS_TOKEN' |
    'invalid_grant' |
    'INVALID_PARAM' |
    'DUPLICATE_NAME' |
    'FOLDER_NOT_MODIFIED' |
    'SAME_SOURCE_DEST_PARENT' |
    'INVALID_OPERATION_FOLDER_DELETED' |
    'INVALID_OPERATION_FILE_DELETED' |
    'INVALID_SESSION' |
    'INVALID_NAME' |
    'UNSUPPORTED_REGION';

export interface ITCException {
    errorcode: ITCErrorCode;
    message: string;
}

export interface ITCRegion {
    displaykey: string;
    region: string;
}

const trimbleAuthenticationCurrentUrlStorageKey = 'trimbleAuthenticationCurrentUrl';
const trimbleAuthenticationStorageKey = 'trimbleAuthentication';
const trimbleAuthenticationStateStorageKey = 'trimbleAuthenticationState'

@Injectable({
    providedIn: 'root'
})
export class TrimbleConnectService extends TrimbleConnectServiceBase {
    private static code_verifier_key = 'tc_code_verifier';

    private static regions: ITCRegion[] = [
        { displaykey: 'Agito.Hilti.Profis3.TrimbleConnect.Region.Europe', region: 'EU' },
        { displaykey: 'Agito.Hilti.Profis3.TrimbleConnect.Region.NorthAmerica', region: 'NA' },
        { displaykey: 'Agito.Hilti.Profis3.TrimbleConnect.Region.Asia', region: 'AS' },
        //{ displaykey: 'Agito.Hilti.Profis3.TrimbleConnect.Region.Australia', region: 'AU' },
    ];

    public region: string;
    private trimbleToken: ITrimbleToken;

    constructor(
        private apiService: ApiService,
        private modalService: ModalService,
        private localizationService: LocalizationService,
        private offlineService: OfflineService,
        private routingService: RoutingService,
        private sessionStorage: SessionStorageService,
        private loggerService: LoggerService
    ) {
        super();

        const regions = this.getRegions();
        if (regions != null && regions.length > 0) {
            this.region = regions[0].region;
        }
    }

    public get isEnabled() {
        return environment.trimbleConnectEnabled;
    }

    public get isAuthenticated() {
        return this.trimbleToken != null;
    }

    /**
     * Gets the regions.
     */
    public getRegions(): ITCRegion[] {
        return TrimbleConnectService.regions;
    }

    /**
     * Gets the projects for a region.
     */
    public async getAllProjects(): Promise<TrimbleProjectEntity[]> {
        const session = await this.ensureSession();
        if(session == null) {
            return null;
        }

        const url = this.getStorageUrl(`GetAllProjects/${session.region}`);

        const req = new HttpRequest('GET', url, {
            headers: this.getAccessTokenHeaders(session.token)
        });

        try {
            return this.handleResponse(await this.apiService.request<TrimbleProjectEntity[]>(req, { supressErrorMessage: true })).body;
        }
        catch (response) {
            this.handleError(session, response, url);
        }
    }

    /**
     * Gets the folder items (folders and files).
     */
    public async getFolderItems(folderId: string): Promise<TrimbleItemEntity[]> {
        const session = await this.ensureSession();
        if(session == null) {
            return null;
        }

        const url = this.getStorageUrl(`GetFolderItems/${session.region}/${folderId}`);

        const req = new HttpRequest('GET', url, {
            headers: this.getAccessTokenHeaders(session.token)
        });

        try {
            return this.handleResponse(await this.apiService.request<TrimbleItemEntity[]>(req, { supressErrorMessage: true })).body;
        }
        catch (response) {
            this.handleError(session, response, url);
        }
    }

    /**
     * Gets the folder items (folders and files).
     */
    public async getFile(fileId: string): Promise<Blob> {
        const session = await this.ensureSession();
        if(session == null) {
            return null;
        }

        const url = this.getStorageUrl(`GetFile/${session.region}/${fileId}`);

        const request = new HttpRequest('GET', url, {
            responseType: 'blob',
            headers: this.getAccessTokenHeaders(session.token)
                .append('Accept', 'application/xml')
        });

        try {
            return this.handleResponse(await this.apiService.request<Blob>(request, { supressErrorMessage: true })).body;
        }
        catch (response) {
            if (response instanceof HttpErrorResponse && response.error instanceof Blob) {
                await new Promise<never>((_, reject) => {
                    const fileReader = new FileReader();

                    // read blob
                    fileReader.addEventListener('loadend', () => {
                        try {
                            // eslint-disable-next-line no-ex-assign
                            response = new HttpErrorResponse({
                                error: JSON.parse(fileReader.result as string),
                                headers: (response as HttpErrorResponse).headers,
                                status: (response as HttpErrorResponse).status,
                                statusText: (response as HttpErrorResponse).statusText,
                                url: (response as HttpErrorResponse).url
                            });

                            this.handleError(session, response, url);
                        }
                        catch (error) {
                            reject(error);
                        }
                    }, false);

                    fileReader.readAsText((response as HttpErrorResponse).error);
                });

                // should never come here
                this.handleError(session, response, url);
            }
            else {
                this.handleError(session, response, url);
            }
        }
    }

    /**
     * Gets the folder items (folders and files).
     */
    public async uploadFile(folderFileId: string, fileName: string, projectDesignFile: Blob): Promise<boolean> {
        const session = await this.ensureSession();
        if(session == null) {
            return null;
        }

        const url = this.getStorageUrl(`UploadFile`);

        try {
            const content = await this.getFileBase64(projectDesignFile);

            const data: TrimbleUploadFileRequest = {
                RegionOrigin: session.region,
                FolderId: folderFileId,
                FileName: fileName,
                File: content as any
            };

            const request = new HttpRequest('POST', url, data, {
                headers: this.getAccessTokenHeaders(session.token)
                    .append('Content-Type', 'application/json')
            });

            try {
                return this.handleResponse(await this.apiService.request<boolean>(request, { supressErrorMessage: true })).body;
            }
            catch (response) {
                this.handleError(session, response, url, [], data);
            }
        }
        catch (error) {
            console.error(error);

            return false;
        }
    }

    /**
     * Creates a new folder.
     */
    public async renameFolder(folderId: string, folderName: string): Promise<TrimbleItemEntity> {
        const session = await this.ensureSession();
        if(session == null) {
            return null;
        }

        const url = this.getStorageUrl(`RenameFolder`);

        const data: TrimbleRenameFolderRequest = {
            RegionOrigin: session.region,
            FolderId: folderId,
            FolderName: folderName
        };

        const req = new HttpRequest('POST', url, data, {
            headers: this.getAccessTokenHeaders(session.token)
        });

        try {
            return this.handleResponse(await this.apiService.request<TrimbleItemEntity>(req, { supressErrorMessage: true })).body;
        }
        catch (response) {
            this.handleError(session, response, url, [], data);
        }
    }

    /**
     * Deletes the folder.
     */
    public async deleteFolder(folderId: string): Promise<boolean> {
        const session = await this.ensureSession();
        if(session == null) {
            return null;
        }

        const url: string = this.getStorageUrl(`DeleteFolder`);

        const data: TrimbleDeleteFolderRequest = {
            RegionOrigin: session.region,
            FolderId: folderId
        };

        const req = new HttpRequest('POST', url, data, {
            headers: this.getAccessTokenHeaders(session.token)
        });

        try {
            return this.handleResponse(await this.apiService.request<boolean>(req, { supressErrorMessage: true })).body;
        }
        catch (response) {
            this.handleError(session, response, url, ['INVALID_OPERATION_FOLDER_DELETED'], data);
        }
    }

    public async importDesign(
        project: Project,
        oldDesign: Design,
        onDesignImported: (design: Design, project: Project, renameFile?: boolean, openDesign?: boolean) => Promise<boolean | void> | boolean | void
    ): Promise<IImportData> {
        const session = await this.ensureSession();
        if (session == null) {
            return null;
        }

        return await this.modalService.openTrimbleConnectBrowser({
            mode: TrimbleConnectBrowserMode.import,
            session,
            project,
            oldDesign,
            onDesignImported
        }).result;
    }

    public async uploadDesign(projectDesign: Blob, browserOpend?: () => void): Promise<void> {
        const session = await this.ensureSession();
        if (session == null) {
            return null;
        }

        browserOpend?.();

        return await this.modalService.openTrimbleConnectBrowser({
            mode: TrimbleConnectBrowserMode.exportDesign,
            session,
            projectDesign,
            saveType: TrimbleConnectItemType.pe
        }).result;
    }

    public async getPdfLocation(): Promise<ITrimbleConnectFileInfo> {
        const session = await this.ensureSession();
        if (session == null) {
            return null;
        }

        return await this.modalService.openTrimbleConnectBrowser({
            mode: TrimbleConnectBrowserMode.selectReportLocation,
            session,
            saveType: TrimbleConnectItemType.pdf
        }).result;
    }

    public async loginAuthCallback(authorizationCode: string, state: string) {
        const sessionState = this.sessionStorage.get<string>(trimbleAuthenticationStateStorageKey);

        if (state == null || sessionState != null && sessionState != state) {
            this.loggerService.log('Failed to verify state!', LogType.error);

            this.modalService.openAlertError(
                this.localizationService.getString('Agito.Hilti.Profis3.TrimbleConnectService.Error.Title'),
                this.localizationService.getString('Agito.Hilti.Profis3.TrimbleConnectService.Error.State')
            );

            return;
        }

        await this.setToken(authorizationCode);
        this.sessionStorage.remove(trimbleAuthenticationStateStorageKey);

        let returnUrl = this.sessionStorage.get<string>(trimbleAuthenticationCurrentUrlStorageKey);
        this.sessionStorage.remove(trimbleAuthenticationCurrentUrlStorageKey);

        if (returnUrl == null) {
            returnUrl = urlPath.projectAndDesign;
        }
        this.routingService.navigateToUrl(returnUrl);
    }

    public async ensureSession(): Promise<ITrimbleConnectSession> {
        this.authenticatedFromStorage();

        if (!this.trimbleToken?.access_token) {
                await this.goToLogin();
        }

        // region will be set in trimble connect browser window
        const session: ITrimbleConnectSession = {
            token: this.trimbleToken.access_token,
            region: this.region ?? ''
        };
        return session;
    }

    private shouldGoToLogin(status: number, errorcode: string) {
        if (status == 401) {
            return true;
        }

        if (status != 400) {
            return false;
        }

        // Redirect to TrimbleConnect login only on specific error codes
        switch (errorcode) {
            case 'INVALID_SESSION':
            case 'API_INVALID_ACCESS_TOKEN':
                return true;

            default:
                break;
        }

        return false;
    }

    private async goToLogin() {
        const state = randomString(8);
        this.sessionStorage.set(trimbleAuthenticationStateStorageKey, state);

        const currentUrl = this.routingService.currentUrl.pathname;
        this.sessionStorage.set(trimbleAuthenticationCurrentUrlStorageKey, currentUrl);

        const loginUrl = new URL(environment.trimbleIdBaseUrl + environment.trimbleConnectAuthorize);
        loginUrl.searchParams.append('scope', environment.trimbleConnectScope);
        loginUrl.searchParams.append('client_id', environment.trimbleConnectClientId);
        loginUrl.searchParams.append('redirect_uri', this.offlineService.buildRedirectUri(urlPath.trimbleCallback));
        loginUrl.searchParams.append('response_type', 'code');
        loginUrl.searchParams.append('state', state);

        const { verifier, challenge } = await this.getPkceAsync();
        this.sessionStorage.set(TrimbleConnectService.code_verifier_key, verifier);
        loginUrl.searchParams.append('code_challenge', challenge);
        loginUrl.searchParams.append('code_challenge_method', 'S256');

        window.location.href = loginUrl.toString();
    }

    private clearSession(session: ITrimbleConnectSession): void {
        Object.keys(session).forEach(key => delete session[key as keyof ITrimbleConnectSession]);
    }

    private setSession(session: ITrimbleConnectSession, newSession: ITrimbleConnectSession): void {
        this.clearSession(session);

        Object.entries(newSession).forEach(([key, value]) => session[key as keyof ITrimbleConnectSession] = value);
    }

    private async setToken(authorizationCode: string): Promise<ITrimbleToken> {
        const request = await this.createAuthenticationRequest(authorizationCode);
        const response = await this.apiService.request<ITrimbleToken>(request);

        if (response.status == 401 || response.status == 400) {
            return null;
        }

        const tokenResult: ITrimbleToken = {
            access_token: response.body.access_token
        };

        this.sessionStorage.set(trimbleAuthenticationStorageKey, tokenResult);

        return tokenResult;
    }

    private async createAuthenticationRequest(authorizationCode: string) {
        const codeVerifier = this.sessionStorage.get<string>(TrimbleConnectService.code_verifier_key);
        this.sessionStorage.remove(TrimbleConnectService.code_verifier_key);

        const requestBody = new HttpParams({
            fromObject: {
                grant_type: "authorization_code",
                code: authorizationCode,
                code_verifier: codeVerifier,
                redirect_uri: this.offlineService.buildRedirectUri(urlPath.trimbleCallback),
                client_id: environment.trimbleConnectClientId,
            }
        });

        return new HttpRequest('POST', environment.trimbleIdBaseUrl + environment.trimbleConnectToken, requestBody);
    }

    private async authenticatedFromStorage() {
        this.trimbleToken = this.sessionStorage.get<ITrimbleToken>(trimbleAuthenticationStorageKey);
    }

    private handleResponse<TData>(response: HttpResponse<TData>): HttpResponse<TData> {
        if (response.status == 401 || response.status == 400) {
            this.goToLogin();
            return null;
        }

        if (response == null || (response.body != null && (response.body as any).errorcode != null)) {
            throw response;
        }

        return response;
    }

    private handleError(
        session: ITrimbleConnectSession,
        response: unknown,
        url: string,
        ignore: ITCErrorCode[] = [],
        requestData?: object
    ): never {
        let data: any;

        if (response instanceof HttpErrorResponse && response.status > 0) {
            data = response.error;

            if (this.shouldGoToLogin(response.status, data?.errorcode ?? '')) {
                this.goToLogin();
            }
        }
        else if (response instanceof HttpResponse) {
            data = response.body;
        }

        if (response != null && data != null && data.errorcode != null) {
            this.handleErrorWithData(data, session, response, url, ignore, requestData);
        }
        else {
            this.modalService.openAlertServiceError({
                endPointUrl: url,
                requestPayload: requestData,
                responsePayload: response as any
            });
        }

        throw response;
    }

    private handleErrorWithData(
        data: any,
        session: ITrimbleConnectSession,
        response: unknown,
        url: string,
        ignore: ITCErrorCode[] = [],
        requestData?: object
    ) {
        const responseErrorMessage = data.message ?? '';
        const trimbleApplicationError = this.initTrimbleApplicationError(responseErrorMessage, url, requestData);

        const errorMessages: { [key: string]: string } = {
            'invalid_grant': data.message
                ? formatKeyValue(
                    this.localizationService.getString('Agito.Hilti.Profis3.TrimbleConnectService.Error.Response'),
                    { response: data.message }
                  )
                : this.localizationService.getString('Agito.Hilti.Profis3.TrimbleConnectService.Error.InvalidUserName'),
            'DUPLICATE_NAME': this.localizationService.getString('Agito.Hilti.Profis3.TrimbleConnectService.Error.DuplicateName'),
            'INVALID_OPERATION_FILE_DELETED': this.localizationService.getString('Agito.Hilti.Profis3.TrimbleConnectService.Error.FileNotFound'),
            'INVALID_OPERATION_FOLDER_DELETED': this.localizationService.getString('Agito.Hilti.Profis3.TrimbleConnectService.Error.FolderNotFound'),
            'INVALID_NAME': this.localizationService.getString('Agito.Hilti.Profis3.TrimbleConnectService.Error.InvalidName'),
        };

        switch (data.errorcode) {
            case 'INVALID_SESSION':
            case 'API_INVALID_ACCESS_TOKEN': {
                this.clearSession(session);

                // try to get a new session
                const promise = this.ensureSession();
                if (promise != null) {
                    promise
                        .then(newSession => {
                            this.setSession(session, newSession);
                        }, () => { return; })
                        .finally(() => { throw response; });
                }
                break;
            }
            default: {
                if (errorMessages[data.errorcode] && !ignore.includes(data.errorcode)) {
                    this.openTrimbleAlertModal(errorMessages[data.errorcode], trimbleApplicationError);
                } else {
                    this.openTrimbleAlertModal(data.message, trimbleApplicationError);
                }
                break;
            }
        }
    }

    private getFileBase64(projectDesignFile: Blob): Promise<string> {
        return new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(projectDesignFile);

            reader.addEventListener('load', () => {
                // convert image file to base64 string
                const result = reader.result as string;
                const base64 = result.substring(result.indexOf(',') + 1);

                resolve(base64);

            }, false);
        });
    }

    private getStorageUrl(requestUri: string): string {
        let url = `${environment.trimbleConnectWebServiceUrl}trimble-connect-service/`;

        if (!this.offlineService.isOffline) {
            url += 'storage/';
        }

        return `${url}${requestUri}`;
    }

    private openTrimbleAlertModal(message: string, applicationError: IApplicationError) {
        this.modalService.openAlertError(
            this.localizationService.getString('Agito.Hilti.Profis3.TrimbleConnectService.Error.Title'),
            message,
            applicationError
        );
    }

    private initTrimbleApplicationError(response: any, url: string, requestData: object) {
        return {
            response: response,
            endPointUrl: url,
            requestPayload: requestData
        } as IApplicationError;
    }

    private async getPkceAsync() {
        return new Promise<{ verifier: string, challenge: string }>((resolve) => {
            getPkce(43, (error, { verifier, challenge }) => {
                if (error) {
                    console.error(error.message);
                    throw error;
                }
                resolve({ verifier, challenge });
            });
        });
    }

    private getAccessTokenHeaders(tcAccessToken: string): HttpHeaders {
        return new HttpHeaders({
            'TC-Token': tcAccessToken
        });
    }
}
