import {
    IProjectConstructor as IProjectConstructorBase,
    Project as ProjectBase
} from '@profis-engineering/pe-ui-common/entities/project';
import { DocumentService, IDesignListItem } from '../services/document.service';
import { IDesignListItem as IDesignListItemCommon } from '@profis-engineering/pe-ui-common/services/document.common';

export interface IProjectArchive {
    id: string;
    parentId: string;
    name: string;
    members: number;
    designs: number;
    created: Date;
    archived: Date;
}

export interface IProjectConstructor extends IProjectConstructorBase {
    directChildDesigns?: { [id: string]: IDesignListItem; };
    subProjects?: { [id: string]: Project; };
}

export class Project extends ProjectBase {
    /**
     * Designs that are directly in the project.
     */
    public directChildDesigns: { [id: string]: IDesignListItem | IDesignListItemCommon; };

    /**
     * Subprojects
     */
    public subProjects: { [id: string]: Project; };


    constructor(project?: IProjectConstructor) {
        super(project);

        if (project != null) {
            this.designs = project.designs;
            this.directChildDesigns = project.directChildDesigns;
            this.subProjects = project.subProjects;
        }

        this.designs = this.designs || {};
        this.directChildDesigns = this.directChildDesigns || {};
        this.subProjects = this.subProjects || {};
    }

    public async loadUsers(document: DocumentService): Promise<void> {
        this.users = await document.getUsersOnProjectById(this.id);
    }
}

export class ProjectDownloadPayLoadModel extends Project {
    DesignList?: IDesignExtended[];
    SubProjectList?: Project[];
    projectid?: string;
    parentprojectid?: string;
}
export class ProjectGetProjectContentResponseModel {
    projects: ProjectDownloadPayLoadModel[];
}

export interface IDesignExtended extends IDesignListItemCommon {
    documentid: string;
}