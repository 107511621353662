import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getJSONDateWithTimezoneOffset } from '@profis-engineering/pe-ui-common/helpers/date-time-helper';
import { DesignType } from '@profis-engineering/pe-ui-shared/entities/code-lists/design-type';
import { BaseplateDesignDataEntity } from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData.BaseplateDesign';
import { DesignLocationType, ProjectOpenType } from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display.Enums';
import { ProjectDesignBaseEntity } from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    SaveTrackingActDataRequest,
    TrackOnProjectCloseRequest, TrackOnProjectDesignOpenRequest, UsageCounter, UserDetails
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Tracking.Entities';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { CommonTrackingService } from './common-tracking.service';
import { FeatureVisibilityService } from './feature-visibility.service';
import { LocalizationService } from './localization.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';

/**
 * proxy for tracking service that tracks design details
 * tracking service is responsible for:
 * - tracking details when new design is created or existing design is opened
 * - tracking details when design is closed
 */
/**
 * @deprecated DO NOT USE FOR NEW CODE. Will be removed with cleanup task: BUDQBP-36273.
 */
@Injectable({
    providedIn: 'root'
})
export class TrackingService {
    constructor(
        private apiService: ApiService,
        private localizationService: LocalizationService,
        private userService: UserService,
        private userSettingsService: UserSettingsService,
        private commonTrackingService: CommonTrackingService,
        private featureVisibilityService: FeatureVisibilityService
    ) {}

    public async saveTrackingActData(
        projectDesign: ProjectDesignBaseEntity,
        usageCounter: UsageCounter,
        projectOpenType: ProjectOpenType,
        designId: string,
        createdFromTemplate: boolean,
        templateId: string,
        designLocation: DesignLocationType
    ): Promise<void> {
        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        usageCounter.DateClosed = new Date();

        const url = `${environment.trackingWebServiceUrl}SaveTrackingActData`;
        const data: SaveTrackingActDataRequest = {
            DesignId: designId,
            ProjectDesign: projectDesign,
            Language: this.localizationService.selectedLanguage,
            UsageCounter: usageCounter,
            Timestamp: date.timestamp as any,
            UserDetails: userDetails,
            ProjectOpenType: projectOpenType,
            BaseplateDesignDataExists: this.userService.design != null
                ? this.userService.design.baseplateDesignData != null
                : false,
            BaseplateDesignTiming: this.userService.design != null ? this.userService.design.designData.reportData.BaseplateDesignTiming : null,
            UseDevFeatures: environment.useDevFeatures,
            UIVersion: environment.applicationVersion,
            CreatedFromTemplate: createdFromTemplate,
            TemplateId: templateId,
            ForceFreeLicense: this.userSettingsService.settings.application.general.forceFreeLicense.value,
            DesignLocation: this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage') ? designLocation : null // Only for new home page
        };

        const request = new HttpRequest('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }

    public async trackOnTemplateClose(
        projectDesign: ProjectDesignBaseEntity,
        usageCounter: UsageCounter,
        projectOpenType: ProjectOpenType,
        designId: string,
        createdFromTemplate: boolean,
        templateId: string,
        designLocation: DesignLocationType
    ): Promise<void> {
        const url = `${environment.trackingWebServiceUrl}TrackOnProjectTemplateClose`;
        const data = this.getDesignTrackingData(projectDesign, usageCounter, projectOpenType, designId, createdFromTemplate, templateId, designLocation);

        const request = new HttpRequest('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }

    public async trackOnDesignOpen(designType: DesignType, designId: string): Promise<void> {
        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        const url = `${environment.trackingWebServiceUrl}TrackOnProjectDesignOpen`;

        const data: TrackOnProjectDesignOpenRequest = {
            DesignId: designId,
            DesignType: designType.id,
            Timestamp: date.timestamp as any,
            UserDetails: userDetails,
            UIVersion: environment.applicationVersion,
        };

        const request = new HttpRequest('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }

    public async trackOnDesignClose(
        projectDesign: ProjectDesignBaseEntity,
        usageCounter: UsageCounter,
        projectOpenType: ProjectOpenType,
        designId: string,
        createdFromTemplate: boolean,
        templateId: string,
        baseplateDesignData?: BaseplateDesignDataEntity,
        baseplateDesignTiming?: string,
        designLocation?: DesignLocationType
    ): Promise<void> {
        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        usageCounter.DateClosed = new Date();
        const url = `${environment.trackingWebServiceUrl}TrackOnProjectDesignClose`;
        const data: TrackOnProjectCloseRequest = {
            DesignId: designId,
            ProjectDesign: projectDesign,
            Language: this.localizationService.selectedLanguage,
            UsageCounter: usageCounter,
            Timestamp: date.timestamp as any,
            UserDetails: userDetails,
            ProjectOpenType: projectOpenType,
            BaseplateDesignData: baseplateDesignData,
            BaseplateDesignTiming: baseplateDesignTiming,
            UseDevFeatures: environment.useDevFeatures,
            UIVersion: environment.applicationVersion,
            CreatedFromTemplate: createdFromTemplate,
            TemplateId: templateId,
            ForceFreeLicense: this.userSettingsService.settings.application.general.forceFreeLicense.value,
            DesignLocation: this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage') ? designLocation : null // Only for new home page
        };

        const request = new HttpRequest('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }

    public getDesignTrackingData(
        projectDesign: ProjectDesignBaseEntity,
        usageCounter: UsageCounter,
        projectOpenType: ProjectOpenType,
        designId: string,
        createdFromTemplate: boolean,
        templateId: string,
        designLocation: DesignLocationType): TrackOnProjectCloseRequest {
        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        return {
            DesignId: designId,
            ProjectDesign: projectDesign,
            Language: this.localizationService.selectedLanguage,
            UsageCounter: usageCounter,
            Timestamp: date.timestamp as any,
            UserDetails: userDetails,
            ProjectOpenType: projectOpenType,
            BaseplateDesignData: this.userService.design?.baseplateDesignData,
            BaseplateDesignTiming: this.userService.design?.designData.reportData.BaseplateDesignTiming,
            UseDevFeatures: environment.useDevFeatures,
            UIVersion: environment.applicationVersion,
            CreatedFromTemplate: createdFromTemplate,
            TemplateId: templateId,
            ForceFreeLicense: this.userSettingsService.settings.application.general.forceFreeLicense.value,
            DesignLocation: this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage') ? designLocation : null // Only for new home page
        };
    }


    private getUserDetails(timezoneOffset: number): UserDetails {
        const srcData = this.commonTrackingService.getTrackingUserData(timezoneOffset);

        return {
            BrowserType: srcData.BrowserType,
            OperatingSystem: srcData.OperatingSystem,
            TimezoneOffset: srcData.TimezoneOffset,
            UserIP: srcData.UserIpAddress,
            Username: srcData.UserName,
            UUID: srcData.UserId,
            DiagnosticsAgreement: srcData.DiagnosticsAgreement,
            CustomerId: srcData.CustomerId,
            CustomerOriginId: srcData.CustomerOriginId,
            SalesOrg: srcData.SalesOrg,
            CustomerType: srcData.CustomerType,
            License: srcData.License,
            IsTrial: srcData.IsTrial,
            CountryOfResidence: srcData.CountryOfResidence,
            CustomerCountry: srcData.CustomerCountry,
            BuyingCustomerOriginId: srcData.BuyingCustomerOriginId,
            BuyingSalesOrg: srcData.BuyingSalesOrg,
            BuyingCustomerType: srcData.BuyingCustomerType
        };
    }
}
