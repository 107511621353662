import escape from 'lodash-es/escape';
import { Component } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';

interface IImageModalComponentInput {
    title?: string;
    url?: string;
}

@Component({
    selector: 'app-image-modal',
    templateUrl: './image-modal.component.html',
    styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent {

    constructor(
        private modalInstance: ModalInstance<IImageModalComponentInput>
    ) { }

    public get title() {
        return this.modalInstance.input?.title != null && this.modalInstance.input?.title != ''
            ? escape(this.modalInstance.input.title)
            : '&nbsp;';
    }

    public get url() {
        return this.modalInstance.input?.url != null && this.modalInstance.input?.url != ''
            ? escape(this.modalInstance.input.url)
            : '&nbsp;';
    }

    public close() {
        this.modalInstance.close();
    }
}
