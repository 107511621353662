import { DesignTemplateService } from '../services/design-template.service';

export interface ITemplateConstructor {
    id?: string;
    templateFolderId?: string;
    name?: string;
    changeDate?: Date;
    createDate?: Date;
    owner?: boolean;
}
export class TemplateUser {
    public templateId!: string;
    public user!: string;
    public dateadded!: Date;
    public modified!: boolean;
    public isowner?: boolean;
}

export class SharedTemplateUser {
    public templateId: string;
    public templateFolderId: string;
    public sharedWithUsers!: string[];
    public dateAdded!: Date;
    public sharedByUserId?: string;
}

export class Template {
    public id?: string;
    public templateFolderId?: string;
    public name?: string;
    public changeDate?: Date;
    public createDate?: Date;
    public owner = false;
    public users: TemplateUser[] = [];

    constructor(template?: ITemplateConstructor) {
        if (template != null) {
            this.id = template.id;
            this.name = template.name;
            this.templateFolderId = template.templateFolderId;
            this.changeDate = template.changeDate;
            this.createDate = template.createDate;
            this.owner = template.owner ?? false;
            this.users = [];
        }
    }

    public async loadUsers(templateService: DesignTemplateService): Promise<void> {
        this.users = await templateService.getUsersOnTemplateById(this.id);
    }
}