import { Component, ElementRef, OnInit } from '@angular/core';
import { ModalInstance, WebComponentModal } from '@profis-engineering/pe-ui-common/helpers/modal-helper';

export interface GenericModalComponentInput<TInputValues = object> {
    inputValues?: TInputValues;
}

@Component({
    selector: 'app-generic-modal',
    template: '',
})
export class GenericModalComponent implements OnInit {
    public contentHtml: string;

    constructor(
        private modalInstance: ModalInstance<GenericModalComponentInput>,
        private elementRef: ElementRef<HTMLElement>,
    ) { }

    public ngOnInit(): void {
        const wc = document.createElement(this.modalInstance.componentName) as HTMLElement & WebComponentModal;
        wc.modalInstance = this.modalInstance;
        this.elementRef.nativeElement.appendChild(wc);
    }
}
