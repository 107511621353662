import { Component, OnInit } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';

import {
    ComponentProviderService, DeclarationType
} from '../../services/component-provider.service';
import { LoggerService } from '../../services/logger.service';
import { ModalService } from '../../services/modal.service';

export interface INpsSurveyPopupComponentInput {
    // These are required if you wish to display the survey
    userId: string;
    username: string;
    applicationId: string;
    applicationVersion: string;
    regionId: string;
    region: string;
    languageId: string;
    accessToken: string;

    // Supporting stuff
    baseUrlPath: string;
    debugScripts: boolean;

    // NPS Tracking
    customerOriginId?: string;
    buyingCustomerOriginId?: string;
    customerCountry?: string;
    countryOfResidence?: string;
    timezoneOffset?: string;
    operatingSystem?: string;
}

/**
 * Defines the response for submitting a survey.
 */
interface ISurveySubmitResult {

    /**
     * Indicates whether the survey was submitted by clicking the "Submit" button.
     */
    submit: boolean;

    /**
     * Indicates whether the survey was successfully submitted.
     */
    success: boolean;

    /**
     * Represents the error, in case the survey response was unsuccessful.
     */
    error: string;
}

@Component({
    selector: 'app-nps-survey-popup',
    templateUrl: './nps-survey-popup.component.html',
    styleUrls: ['./nps-survey-popup.component.scss'],
})
export class NpsSurveyPopupComponent implements OnInit {
    private npsScripts: string[] = [];

    constructor(
        private loggerService: LoggerService,
        private modalInstance: ModalInstance<INpsSurveyPopupComponentInput>,
        private modalService: ModalService,
        private componentProviderService: ComponentProviderService
    ) { }

    ngOnInit(): void {
        // Do not close modal if save is pending
        this.modalInstance.setOnClosing(() => {
            this.onClose();
            return true;
        });

        // determine scripts
        this.determineScriptsToLoad(this.modalInstance.input?.debugScripts);

        // Load necessary scripts from external source
        const scriptsPromise = this.addNpsWebComponentScripts(this.modalInstance.input.baseUrlPath, this.npsScripts);

        scriptsPromise.then(() => this.displaySurveyContent());
    }

    private displaySurveyContent() {
        let username = '';
        let userId = '';
        let applicationId = '';
        let applicationVersion = '';
        let languageId = '';
        let regionId = '';
        let region = '';
        let accessToken = '';
        let customerOriginId = '';
        let buyingCustomerOriginId = '';
        let customerCountry = '';
        let countryOfResidence = '';
        let timezoneOffset = '';
        let operatingSystem = '';

        if (this.modalInstance.input != null) {
            if (this.modalInstance.input.username != null) {
                username = this.modalInstance.input.username;
            }
            if (this.modalInstance.input.userId != null) {
                userId = this.modalInstance.input.userId;
            }
            if (this.modalInstance.input.accessToken != null) {
                accessToken = this.modalInstance.input.accessToken;
            }

            applicationId = this.modalInstance.input.applicationId;
            applicationVersion = this.modalInstance.input.applicationVersion;
            languageId = this.modalInstance.input.languageId;
            regionId = this.modalInstance.input.regionId;
            region = this.modalInstance.input.region;
            customerOriginId = this.modalInstance.input.customerOriginId;
            buyingCustomerOriginId = this.modalInstance.input.buyingCustomerOriginId;
            customerCountry = this.modalInstance.input.customerCountry;
            countryOfResidence = this.modalInstance.input.countryOfResidence;
            timezoneOffset = this.modalInstance.input.timezoneOffset;
            operatingSystem = this.modalInstance.input.operatingSystem;
        }

        // Create custom survey element
        const customSurvey = document.createElement('custom-survey');
        customSurvey.setAttribute('username', username);
        customSurvey.setAttribute('userid', userId);
        customSurvey.setAttribute('applicationid', applicationId);
        customSurvey.setAttribute('applicationversion', applicationVersion);
        customSurvey.setAttribute('languageid', languageId);
        customSurvey.setAttribute('regionid', regionId);
        customSurvey.setAttribute('region', region);
        customSurvey.setAttribute('accesstoken', accessToken);
        customSurvey.setAttribute('customeroriginid', customerOriginId);
        customSurvey.setAttribute('buyingcustomeroriginid', buyingCustomerOriginId);
        customSurvey.setAttribute('customercountry', customerCountry);
        customSurvey.setAttribute('countryofresidence', countryOfResidence);
        customSurvey.setAttribute('timezoneoffset', timezoneOffset);
        customSurvey.setAttribute('operatingsystem', operatingSystem);

        // Handle survey custom event(s)
        customSurvey.addEventListener('getPopUp', () => {
            this.modalService.openModal(this.componentProviderService.get(DeclarationType.UserAgreementSettingsComponent), {});
        });

        customSurvey.addEventListener('closeSurvey', event => {
            const result = (event as CustomEvent<ISurveySubmitResult>).detail;

            if (result.submit && !result.success) {
                this.loggerService.log(`Failed to save survey answers. Error: ${result.error}`);
            }

            // If this event occurred, the user clicked on either "submit" or "cancel" so close the modal
            this.modalInstance.close();
        });

        // Render survey element
        const surveyParent = document.getElementById('nps-survey-parent') as HTMLElement;
        surveyParent.append(customSurvey);
    }

    private onClose() {
        this.removeNpsWebComponentScripts(this.npsScripts);
    }

    private determineScriptsToLoad(debugScripts: boolean) {
        if (debugScripts) {
            this.npsScripts = [
                'polyfills.js',
                'runtime.js',
                'scripts.js',
                'styles.js',
                'vendor.js',
                'main.js'];
        }
        else {
            this.npsScripts = [
                'polyfills.js',
                'runtime.js',
                'scripts.js',
                'main.js'];
        }
    }

    private async addNpsWebComponentScripts(baseUrl: string, scriptArray: string[]): Promise<void> {
        const promises: Promise<void>[] = [];

        for (let i = 0; i < scriptArray.length; i++) {
            const scriptName = scriptArray[i];
            const promise = new Promise<void>(resolve => {
                // If script does not already exists
                if (document.getElementById(this.formatScriptId(scriptName)) == null) {
                    const script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.src = `${baseUrl}/${scriptName}?${Date.now()}`;
                    script.id = this.formatScriptId(scriptName);
                    script.onload = () => resolve();

                    document.getElementsByTagName('head')[0].appendChild(script);
                }
                else {
                    resolve();
                }
            });
            promises.push(promise);
        }

        await Promise.all(promises);
    }

    private removeNpsWebComponentScripts(scriptArray: string[]) {
        for (let i = 0; i < scriptArray.length; i++) {
            const scriptName = scriptArray[i];

            // remove only, if it exists
            if (document.getElementById(this.formatScriptId(scriptName)) != null) {
                const element = document.getElementById(this.formatScriptId(scriptName));

                element.parentNode.removeChild(element); // Access parent mode of this element and remove it from children.
            }
        }
    }

    private formatScriptId(scriptName: string) {
        return `nps-script-${scriptName}`;
    }
}
