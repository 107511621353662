import { AbstractControl, ValidationErrors } from '@angular/forms';

const URL_REGEXP = /^(((ftp:|http:|https:)?\/\/(www\.)?)|\/\/)?[-a-zA-Z0-9@:%._+~#=]{2,}\.[a-z]{2,126}\b([\\\w#!:.?+*=&%@\-/()"$;]*)$/;

export function urlValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (value != null && value != '' && !URL_REGEXP.test(value)) {
        return {
            url: {
                value: control.value
            }
        };
    }

    return null;
}
