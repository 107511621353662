import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    InfoLink, RegionLinks, RegionMarketingCampaign, ReleaseNote
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.ProductInformationService.Shared.Entities';
import {
    UserLicenseOptions
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.ProductInformationService.Shared.Enums';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';

import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { LicenseService } from './license.service';
import { ModalService } from './modal.service';
import {
    ProductInfoDesignGuideEntity, ProductInformationService, ProductInfoWoodLinksEntity
} from './product-information.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';

@Injectable()
export class ProductInformationImpl extends ProductInformationService {
    public readonly isProductInformationServiceEnabled = true;

    constructor(
        userSettingsService: UserSettingsService,
        licenseService: LicenseService,
        userService: UserService,
        apiService: ApiService,
        modalService: ModalService
    ) {
        super(userSettingsService,
            licenseService,
            userService,
            apiService,
            modalService);
    }

    public async getMarketingCampaignsFromService(options?: ApiOptions): Promise<RegionMarketingCampaign[]> {
        const url = `${environment.productInformationServiceUrl}MarketingCampaign/active`;

        // tslint:disable: no-bitwise
        const userLicenseData = (this.licenseService.isTrial() ? UserLicenseOptions.Trial : UserLicenseOptions.None)
            | (this.licenseService.isStandardLicense() || this.licenseService.floatingLimitReached ? UserLicenseOptions.Standard : UserLicenseOptions.None)
            | (this.licenseService.isAdvanced() ? UserLicenseOptions.Premium : UserLicenseOptions.None);
        // tslint:enable: no-bitwise
        const email = this.userService.getUserName();

        const data = {
            UserLicenseOptions: userLicenseData,
            Email: email
        };

        return (await this.apiService.request<RegionMarketingCampaign[]>(new HttpRequest('POST', url, data), options)).body;
    }

    public async getReleaseNotesFromService(selectedLanguage: string): Promise<ReleaseNote[]> {
        const url = `${environment.productInformationServiceUrl}ReleaseNotes/${selectedLanguage}`;

        return (await this.apiService.request<ReleaseNote[]>(new HttpRequest('GET', url))).body;
    }

    public async getLatestReleaseNoteFromService(selectedLanguage: string, options?: ApiOptions): Promise<ReleaseNote> {
        const url = `${environment.productInformationServiceUrl}ReleaseNotes/${selectedLanguage}/latest`;

        return (await this.apiService.request<ReleaseNote>(new HttpRequest('GET', url), options)).body;
    }

    public async getRegionLinksFromService(options?: ApiOptions): Promise<RegionLinks[]> {
        const url = `${environment.productInformationServiceUrl}RegionLinks/GetRegionLinks`;

        return (await this.apiService.request<RegionLinks[]>(new HttpRequest('GET', url), options)).body;
    }

    public async getDesignGuidesCodeLists(apiOptions?: ApiOptions): Promise<ProductInfoDesignGuideEntity[]> {
        const url = `${environment.productInformationServiceUrl}DesignGuides/GetDesignGuides`;

        return (await this.apiService.request<ProductInfoDesignGuideEntity[]>(new HttpRequest('GET', url), apiOptions)).body;
    }

    public async getWoodLinksCodeLists(apiOptions?: ApiOptions): Promise<[ProductInfoWoodLinksEntity]> {
        const url = `${environment.productInformationServiceUrl}WoodLinks/GetWoodLinks`;

        return (await this.apiService.request<[ProductInfoWoodLinksEntity]>(new HttpRequest('GET', url), apiOptions)).body;
    }

    public async getInfoLinksFromService(options?: ApiOptions): Promise<InfoLink[]> {
        const url = `${environment.productInformationServiceUrl}InfoLinks`;

        return (await this.apiService.request<InfoLink[]>(new HttpRequest('GET', url), options)).body;
    }
}
