<div class="modal-header">
    <h3 class="modal-title">
        {{localization.getString('Agito.Hilti.Profis3.ShareProject.Title').toUpperCase() + ' ' + (isTemplate ? selectedTemplate.name : selectedDocument.name)}}
    </h3>

    <button id="share-project-close-button" type="button" class="close" (click)="close()"
        [disabled]="submitted">
        <span>&times;</span>
    </button>
</div>

<div class="modal-body">
    <form class="form" name="form" #ngForm="ngForm" novalidate>
        <div class="section">
            <div class="section-inline">
                <pe-text-box
                    class="control-row"
                    id="share-project-add-users-wrapper"
                    [id]="userTextBox.id"
                    [title]="userTextBox.title"
                    [placeholder]="userTextBox.placeholder"
                    [maxLength]="userTextBox.maxLength"
                    [validators]="userTextBox.validators"
                    [value]="userTextBox.value"
                    (valueChange)="userTextBox.value = $any($event).detail"
                    (isValid)="userTextBox.isValid = $any($event).detail"
                    (enterPressed)="isTemplate? addUserForTemplate(ngForm): addUserForDocument(ngForm)"
                    [disabled]="submitted">
                </pe-text-box>

                <div class="share-project-add-button">
                    <button class="modal-button button button-CTA"
                        (click)="isTemplate? addUserForTemplate(ngForm): addUserForDocument(ngForm)"
                        [disabled]="!formValid || (ngForm.enabled && !ngForm.valid) || submitted">
                        <pe-loading-button
                            [loading]="submitted"
                            [text]="'Agito.Hilti.Profis3.ShareProject.Add' | l10n:localization.selectedLanguage">
                        </pe-loading-button>
                    </button>
                </div>
            </div>

            <label class="control-label" l10n="Agito.Hilti.Profis3.ShareDocument.Add.Explanation"></label>
        </div>
    </form>

    <virtual-scroller #scrollSharedUsers *ngIf="!hideShareTable"
        [items]="isTemplate? templateSharedUsers: documentSharedUsers" [parentScroll]="scrollElement" [scrollAnimationTime]="0"
        [ngStyle]="{ 'height': ((isTemplate? templateSharedUsers.length: documentSharedUsers.length) * sharedUsersRowHeight + sharedUsersHeaderHeight) + 'px' }">
        <table class="table-share-project">
            <caption>Share project table</caption>
            <thead>
                <tr class="table-share-project-row-header">
                    <th>{{localization.getString('Agito.Hilti.Profis3.ShareProject.User')}}</th>
                    <th>{{localization.getString('Agito.Hilti.Profis3.ShareProject.Added')}}</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody #container>
                <tr *ngFor="let item of scrollSharedUsers.viewPortItems"
                    class="table-share-project-row">
                    <td>{{item.user}}</td>
                    <td>{{dateTime.format(item.dateadded)}}</td>
                    <td>
                        <div class="action-buttons">
                            <button class="button-delete collapse-button" (click)="isTemplate? deleteUserForTemplate(item): deleteUserForDocument(item)"
                                [disabled]="submitted"
                                [ngbTooltip]="'Agito.Hilti.Profis3.ShareProject.Delete' | l10n:localization.selectedLanguage"
                                placement="top">
                                <div class="sprite sprite-trash"></div>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </virtual-scroller>
</div>
