import { TooltipType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import * as React from 'react';

import { ControlHeader } from '../../ControlHeader';
import { isControlHidden } from '../../MainMenuHelper';
import { RadioButton } from '../RadioButton/RadioButton';
import {IRadioButtonGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/radio-button-group-props';
export class RadioButtonGroup extends React.PureComponent<IRadioButtonGroupProps> {

    constructor(props?: IRadioButtonGroupProps) {
        super(props);

        this.onItemCheck = this.onItemCheck.bind(this);
        this.onInfoClick = this.onInfoClick.bind(this);
    }

    public override render() {
        if (isControlHidden(this.props)) {
            return null;
        }

        const groupId = `${this.props.controlId}-group`;

        return (
            <div data-control-id={this.props.controlId} className={`control react-radio-button-group-control ${this.props.sizeClass}`}>

                <div className='control-container'>
                    <ControlHeader
                        text={this.props.title}
                        controlId={groupId}
                        tooltip={this.props.tooltip}
                        tooltipTitle={this.props.tooltipTitle}
                        localization={this.props.localization}
                        infoClick={this.props.tooltipType == TooltipType.Popup ? this.onInfoClick : undefined}
                    />
                </div>
                <div
                    className='radio-buttons'
                    data-control-id={groupId} >
                    {
                        this.props.items.map((item, index) =>
                            <RadioButton
                                controlId={`${this.props.controlId}-${item.value}`}
                                key={`${this.props.controlId}-${item.value}`}
                                value={item.value}
                                text={item.text}
                                checked={item.value == this.props.checkedValue}
                                disabled={item.disabled || this.props.disabled}
                                hidden={this.props.hidden}
                                localization={this.props.localization}
                                size={null}
                                title={null}
                                type={RadioButton}
                                checkedChanged={this.onItemCheck}
                                tooltip={item.tooltip}
                                tooltipTitle={item.tooltipTitle}
                                tag={item.tag}
                                tooltipType={item.tooltiptype}
                                infoClicked={item.onInfoClick}
                                hoverable={this.props.itemsHoverable}
                                alternateBackground={this.props.alternateItemsBackgrounds && index % 2==1}
                            />)
                    }
                </div>
            </div>
        );
    }

    private onItemCheck(checked: boolean, value: number) {
        if (this.props.valueChanged != null) {
            if (checked) {
                this.props.valueChanged(value);
            }
        }
    }

    private onInfoClick() {
        if (this.props.infoClicked != null) {
            this.props.infoClicked();
        }
    }
}
