import { Component, Input, OnInit } from '@angular/core';
import {
  DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
  MouseClickType
} from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { IAppSettingsInteractionData } from '../../helpers/app-settings-helper';
import { LocalizationService } from '../../services/localization.service';

@Component({
  selector: 'app-app-settings-interaction-settings',
  templateUrl: './app-settings-interaction-settings.component.html',
  styleUrls: ['../app-settings/app-settings-section.component.scss']
})
export class AppSettingsInteractionSettingsComponent implements OnInit {
  @Input()
  public submitted: boolean;

  @Input()
  public interactionValues: IAppSettingsInteractionData;

  public controlsRotateDropdown: DropdownProps<MouseClickType>;
  public controlsPanDropdown: DropdownProps<MouseClickType>;

  constructor(
    private localization: LocalizationService,
  ) {
  }

  ngOnInit(): void {
    this.controlsRotateDropdown = this.createMouseDropdown('control-rotate', 'Rotate');
    this.controlsPanDropdown = this.createMouseDropdown('control-pan', 'Pan');
  }

  public onControlsRotateDropdownChange(id: number) {
    this.interactionValues.rotate = id;

    if (id == this.interactionValues.pan) {
      this.interactionValues.pan = (this.controlsPanDropdown.items as DropdownItem<MouseClickType>[]).find((item) => item.value != id).value;
    }
  }

  public onControlsPanDropdownChange(id: number) {
    this.interactionValues.pan = id;

    if (id == this.interactionValues.rotate) {
      this.interactionValues.rotate = (this.controlsRotateDropdown.items as DropdownItem<MouseClickType>[]).find((item) => item.value != id).value;
    }
  }

  private createMouseDropdown(id: string, translationKey: string) {
    const mouseClickDropdown: DropdownProps<number> = {
      id: `application-settings-controls-${id}`,
      items: [MouseClickType.Left, MouseClickType.Right].map(item => ({
        value: item,
        text: this.mouseClickItemTranslation(item)
      }) as DropdownItem<number>),
      title: this.localization.getLocalizedString(`Agito.Hilti.Profis3.ApplicationSettings.Controls.${translationKey}`)
    };

    return mouseClickDropdown;
  }

  private mouseClickItemTranslation(id: MouseClickType) {
    switch (id) {
      case MouseClickType.Left:
        return this.localization.getLocalizedString('Agito.Hilti.Profis3.ApplicationSettings.Controls.LeftMouseClick');
      case MouseClickType.Right:
        return this.localization.getLocalizedString('Agito.Hilti.Profis3.ApplicationSettings.Controls.RightMouseClick');
    }
  }
}
