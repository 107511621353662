import { Injectable } from '@angular/core';
import {
    ApplicationType
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/application-type';
import {
    BaseMaterialData
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/base-material-data';
import { CodeList } from '@profis-engineering/pe-ui-c2c/entities/code-lists/code-list';
import { ConnectionType } from '@profis-engineering/pe-ui-c2c/entities/code-lists/connection-type';
import {
    MethodGroupConnTypeStandardRegion
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/connection-type-method-standard-regions';
import {
    CorrespondingChapter
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/corresponding-chapter';
import {
    DesignMethodGroup
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/design-method-group';
import {
    DesignStandard as DesignStandardC2C
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/design-standard';
import {
    DesignType as DesignTypeC2C
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/design-type';
import { InfoLink } from '@profis-engineering/pe-ui-c2c/entities/code-lists/info-link';
import {
    MaxReinforcementSpacing
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/max-reinforcement-spacing';
import {
    MaximumAreaReinforcement
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/maximum-area-reinforcement';
import { MetricEquation } from '@profis-engineering/pe-ui-c2c/entities/code-lists/metric-equation';
import {
    MinimumAreaReinforcement
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/minimum-area-reinforcement';
import {
    OptimizationStrategy
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/optimization-strategy';
import {
    RebarSafetyFactorsRegion
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/rebar-safety-factory-region';
import { ReportType } from '@profis-engineering/pe-ui-c2c/entities/code-lists/report-type';
import {
    SafetyFactorRegion
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/safety-factor-region';
import { SpacingMaximum } from '@profis-engineering/pe-ui-c2c/entities/code-lists/spacing-maximum';
import { StrutTies } from '@profis-engineering/pe-ui-c2c/entities/code-lists/strut-ties';
import { UIProperty } from '@profis-engineering/pe-ui-c2c/entities/code-lists/UI-property';
import { SplitEntryPoint } from '@profis-engineering/pe-ui-c2c/entities/code-lists/split-entry-point';
import {
    ProjectCodeList as ProjectCodeListC2C
} from '@profis-engineering/pe-ui-c2c/enums/project-code-list';
import {
    CodeListResponseC2C as ICodeListResponseC2C
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.C2CCodeListService.Entities';
import {
    DesignStandard
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { PropertyMetaDataC2C } from '@profis-engineering/pe-ui-c2c/properties/properties';
import { CodeListServiceBase } from '@profis-engineering/pe-ui-c2c/services/code-list.service.base';

import { environment } from '../../environments/environment';
import { FeatureVisibilityService } from './feature-visibility.service';

@Injectable({
    providedIn: 'root'
})
export class CodeListServiceC2C extends CodeListServiceBase {
    public projectCodeListsC2C: Record<ProjectCodeListC2C, CodeList[]>;

    constructor(private featureVisibilityService: FeatureVisibilityService) {
        super();
    }

    public initialize(data: ICodeListResponseC2C) {
        /* eslint-disable @typescript-eslint/no-explicit-any */
        const c2cForceAvailableCountries: number[] = (window as any).environmentC2C?.c2cForceAvailableCountries ?? environment.c2cForceAvailableCountries;
        const c2cForceAvailableCountriesPir: number[] = (window as any).environmentC2C?.c2cForceAvailableCountriesPir ?? environment.c2cForceAvailableCountriesPir;
        const c2cForceAvailableCountriesEnabled: boolean = (window as any).environmentC2C?.c2cForceAvailableCountriesEnabled ?? environment.c2cForceAvailableCountriesEnabled;
        const c2cForceAvailableConnections: number[] = (window as any).environmentC2C?.c2cForceAvailableConnections ?? environment.c2cForceAvailableConnections;
        /* eslint-enable @typescript-eslint/no-explicit-any */

        const c2cAvailableCountries = c2cForceAvailableCountries.concat(c2cForceAvailableCountriesPir.filter(item => c2cForceAvailableCountries.findIndex(x => x == item) < 0));
        const designTypeRegions = (c2cForceAvailableCountriesEnabled ? c2cAvailableCountries : data.designType.regions);

        this.projectCodeListsC2C = {
            [ProjectCodeListC2C.UIPropertiesC2C]: data.uiProperties.map((codeList) => UIProperty.fromService(codeList)),
            [ProjectCodeListC2C.ConnectionTypesC2C]: data.connectionTypes.map((codeList) => ConnectionType.fromService(codeList)),
            [ProjectCodeListC2C.DesignStandardsC2C]: data.designStandards.map((codeList) => DesignStandardC2C.fromService(codeList)),
            [ProjectCodeListC2C.DesignMethodGroupsC2C]: data.designMethodGroups.map((codeList) => DesignMethodGroup.fromService(codeList)),
            [ProjectCodeListC2C.SafetyFactorRegionC2C]: data.safetyFactorRegions.map((codeList) => SafetyFactorRegion.fromService(codeList)),
            [ProjectCodeListC2C.ReportTypeC2C]: data.reportTypes.map((codeList) => ReportType.fromService(codeList)),
            [ProjectCodeListC2C.ApplicationTypesC2C]: data.applicationTypes.map((codeList) => ApplicationType.fromService(codeList)),
            [ProjectCodeListC2C.MethodGroupConnTypeStandardRegionsC2C]: data.methodGroupConnTypeStandardRegions.map((codeList) => MethodGroupConnTypeStandardRegion.fromService(codeList)),
            [ProjectCodeListC2C.BaseMaterialDataC2C]: data.baseMaterialData.map((codeList) => BaseMaterialData.fromService(codeList)),
            [ProjectCodeListC2C.RebarSafetyFactorsRegionC2C]: data.rebarSafetyFactorsRegion.map((codeList) => RebarSafetyFactorsRegion.fromService(codeList)),
            [ProjectCodeListC2C.MinimumAreaReinforcementC2C]: data.minimumAreaReinforcement.map((codeList) => MinimumAreaReinforcement.fromService(codeList)),
            [ProjectCodeListC2C.MaximumAreaReinforcementC2C]: data.maximumAreaReinforcement.map((codeList) => MaximumAreaReinforcement.fromService(codeList)),
            [ProjectCodeListC2C.SpacingMaximumC2C]: data.spacingMaximum.map((codeList) => SpacingMaximum.fromService(codeList)),
            [ProjectCodeListC2C.StrutTiesC2C]: data.strutTies.map((codeList) => StrutTies.fromService(codeList)),
            [ProjectCodeListC2C.OptimizationStrategyC2C]: data.optimizationStrategy.map((codeList) => OptimizationStrategy.fromService(codeList)),
            [ProjectCodeListC2C.MaxReinforcementSpacingC2C]: data.maxReinforcementSpacing.map((codeList) => MaxReinforcementSpacing.fromService(codeList)),
            [ProjectCodeListC2C.InfoLinkC2C]: data.infoLink.map((codeList) => InfoLink.fromService(codeList)),
            [ProjectCodeListC2C.MetricEquationC2C]: data.metricEquation.map((codeList) => MetricEquation.fromService(codeList)),
            [ProjectCodeListC2C.DesignTypeC2C]: [DesignTypeC2C.fromService(data.designType, data.designStandards, designTypeRegions)],
            [ProjectCodeListC2C.CorrespondingChapterC2C]: data.correspondingChapters.map((codeList) => CorrespondingChapter.fromService(codeList)),
            [ProjectCodeListC2C.SplitEntryPointC2C]: data.splitEntryPoint?.map((codeList) => SplitEntryPoint.fromService(codeList)) ?? [],
        };

        if (c2cForceAvailableCountriesEnabled) {
            this.projectCodeListsC2C[ProjectCodeListC2C.ConnectionTypesC2C] = this.projectCodeListsC2C[ProjectCodeListC2C.ConnectionTypesC2C]
                .filter(x => c2cForceAvailableConnections.some(c => c == x.id));

            this.projectCodeListsC2C[ProjectCodeListC2C.MethodGroupConnTypeStandardRegionsC2C] =
                (this.projectCodeListsC2C[ProjectCodeListC2C.MethodGroupConnTypeStandardRegionsC2C] as MethodGroupConnTypeStandardRegion[])
                    .filter(x => c2cForceAvailableConnections.some(c => c == x.connectionTypeId));
        }

        if (!this.featureVisibilityService.isFeatureEnabled('C2C_EnableAciMeta') && environment.c2cAciDisabledCountries?.length) {
            this.projectCodeListsC2C[ProjectCodeListC2C.MethodGroupConnTypeStandardRegionsC2C] =
            (this.projectCodeListsC2C[ProjectCodeListC2C.MethodGroupConnTypeStandardRegionsC2C] as MethodGroupConnTypeStandardRegion[])
                .filter(x => !(environment.c2cAciDisabledCountries.includes(x.regionId) && x.designStandardId == DesignStandard.ACI));

            const uiProperty = (this.projectCodeListsC2C[ProjectCodeListC2C.UIPropertiesC2C] as UIProperty[])
                .find(x => x.id == PropertyMetaDataC2C.Option_C2C_DesignStandard.id );

            if (uiProperty)
            {
                uiProperty.propertyValues = uiProperty.propertyValues.filter(x => !(environment.c2cAciDisabledCountries.includes(x.regionId) && x.defaultValue == DesignStandard.ACI))
            }
        }
    }

    public getPropertyValue(id: number, regionId: number, connectionTypeId: number, designStandardId = 0, designMethodId = 0) {
        const c2cUIProperties = this.projectCodeListsC2C[ProjectCodeListC2C.UIPropertiesC2C] as UIProperty[];
        const propValues = c2cUIProperties.find((property) => property.id == id);

        let propertyValues = propValues.propertyValues.filter(property => property.regionId == regionId);
        if (propertyValues.length == 0) {
            propertyValues = propValues.propertyValues.filter(property => property.regionId == 0);
        }

        let propValByDesignStandard = propertyValues.filter(property => property.designStandardId == designStandardId);
        if (propValByDesignStandard.length == 0) {
            propValByDesignStandard = propertyValues.filter(property => property.designStandardId == 0);
        }

        let propValByConnection = propValByDesignStandard.filter(property => property.connectionTypeId == connectionTypeId);
        if (propValByConnection.length == 0) {
            propValByConnection = propValByDesignStandard.filter(property => property.connectionTypeId == 0);
        }

        let propValByDesignMethod = propValByConnection.filter(property => property.designMethodGroupId == designMethodId);
        if (propValByDesignMethod.length == 0) {
            propValByDesignMethod = propValByConnection.filter(property => property.designMethodGroupId == 0);
        }

        // This is the final solution if everything is filtered out
        if (propValByDesignMethod.length == 0) {
            propValByDesignMethod = propValues.propertyValues.filter(property => property.regionId == 0 && property.connectionTypeId == 0);
        }

        return propValByDesignMethod[0];
    }
}
