<div class="modal-header">
    <h3 class="modal-title" l10n="Agito.Hilti.Profis3.SelectRegionLanguage.Title"></h3>
</div>
<div class="modal-body">
    <div class="content">
        <div>
            <pe-dropdown
                [id]="worldAreaDropdown.id"
                [title]="worldAreaDropdown.title"
                [validators]="worldAreaDropdown.validators"
                [items]="worldAreaDropdown.items"
                [selectedValue]="worldAreaDropdown.selectedValue"
                (selectedValueChange)="worldAreaDropdownSelectedValueChange($any($event).detail)"
                [disabled]="pendingSave"
                (isValid)="worldAreaDropdown.isValid = $any($event).detail">
            </pe-dropdown>
        </div>

        <div class="bottom-dropdown-container">
            <pe-dropdown
                class="image"
                [id]="countryDropdown.id"
                [title]="countryDropdown.title"
                [validators]="countryDropdown.validators"
                [items]="countryDropdown.items"
                [selectedValue]="countryDropdown.selectedValue"
                (selectedValueChange)="countryDropdown.selectedValue = $any($event).detail"
                [disabled]="pendingSave"
                (isValid)="countryDropdown.isValid = $any($event).detail">
            </pe-dropdown>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div>
        <button id="select-region-button" type="button" class="modal-button button button-primary"
            (click)="selectRegionLanguage()" [disabled]="pendingSave"
            l10n="Agito.Hilti.Profis3.SelectRegionLanguage.ContinueButton">
        </button>
    </div>
</div>
