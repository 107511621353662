<div class="content-group">
    <div class="content">
        <p class="title-strike" l10n="Agito.Hilti.Profis3.ApplicationSettings.General"></p>

        <div class="content-controls">
            <pe-dropdown class="control"
                [id]="languageDropdown.id"
                [items]="languageDropdown.items"
                [selectedValue]="regionLanguageValues.language"
                (selectedValueChange)="regionLanguageValues.language = $any($event).detail"
                [title]="languageDropdown.title"
                [disabled]="submitted">
            </pe-dropdown>

            <pe-dropdown class="control"
                [id]="regionDropdown.id"
                [items]="regionDropdown.items"
                [selectedValue]="regionLanguageValues.region"
                (selectedValueChange)="onRegionDropdownChange($any($event).detail)"
                [title]="regionDropdown.title"
                [disabled]="submitted">
            </pe-dropdown>
        </div>

        <p class="title-strike" l10n="Agito.Hilti.Profis3.ApplicationSettings.NumberFormat"></p>
        <div class="content-controls">
            <pe-dropdown class="control"
                [id]="decimalSeparatorDropdown.id"
                [items]="decimalSeparatorDropdown.items"
                [selectedValue]="regionLanguageValues.decimalSeparator"
                (selectedValueChange)="onDecimalSeparatorDropdownChange($any($event).detail)"
                [title]="decimalSeparatorDropdown.title"
                [disabled]="submitted">
            </pe-dropdown>

            <pe-dropdown class="control"
                [id]="thousandsSeparatorDropdown.id"
                [items]="thousandsSeparatorDropdown.items"
                [selectedValue]="regionLanguageValues.thousandsSeparator"
                (selectedValueChange)="onThousandsSeparatorDropdownChange($any($event).detail)"
                [title]="thousandsSeparatorDropdown.title"
                [disabled]="submitted">
            </pe-dropdown>
        </div>
    </div>
</div>
