<div class="modal-header">
    <h3 class="modal-title" l10n="Agito.Hilti.Profis3.Main.UserOptions.WhatsNew"></h3>

    <button id="user-agreement-settings-close-button" type="button" class="close" (click)="close()">
        <span>&times;</span>
    </button>
</div>

<div class="modal-body">
    <div *ngFor="let note of (releaseNotes | slice:0:viewLimit)">
        <p class="title-strike title">
            <span [innerHtml]="note.SanitizedTitle"></span>

            <button class="section-collapse" type="button" (click)="note.isNoteCollapsed = !note.isNoteCollapsed"
                [ngbTooltip]="'Agito.Hilti.Profis3.Main.Region.ShowHide' | l10n:localization.selectedLanguage">
                <span class="sprite"
                    [class.sprite-lines-expanded]="note.isNoteCollapsed"
                    [class.sprite-lines]="!note.isNoteCollapsed">
                </span>
            </button>
        </p>

        <div class="section" *ngIf="!note.isNoteCollapsed">
            <span class="whats-new-content" [innerHtml]="note.SanitizedContent"></span>
        </div>
    </div>

    <button type="button" class="view-more modal-button button button-default"
        l10n="Agito.Hilti.Profis3.WhatsNew.ViewMore" (click)="viewMore()"
        *ngIf="viewLimit < releaseNotes.length">
    </button>
</div>
