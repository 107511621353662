import { Injectable } from '@angular/core';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { MarketingServiceBase } from '@profis-engineering/pe-ui-common/services/marketing.common';

import { environment } from '../../environments/environment';
import { UserSettingsService } from './user-settings.service';

@Injectable({
    providedIn: 'root'
})
export class MarketingService extends MarketingServiceBase {
    constructor(
        private userSettingsService: UserSettingsService
    ) {
        super();
     }

    public getDPRUrl() {
        return this.userSettingsService.getCommonRegionById(this.userSettingsService.settings.application.general.regionId.value)?.hiltiDataConsentUrl ?? '';
    }

    public getUserManualForSelectedLanguage() {
        const language = this.userSettingsService.getLanguage();
        // default
        let languageCode = 'EN';

        if (environment.availableUserManualLCID.includes(language.id)) {
            languageCode = this.userSettingsService.getLanguage().culture.substring(0, 2).toUpperCase();

            if (languageCode == 'CS') {
                languageCode = 'CZ';
            }
        }

        return formatKeyValue(environment.userManualUrl, { language: languageCode });
    }
}
