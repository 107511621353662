import { Injectable, Type } from '@angular/core';

// When changing this enum also change the declarations list in src\app\app.module.ts.
// This DeclarationType enum and declarations list in src\app\app.module.ts *MUST* be the same!!!
export enum DeclarationType {
    TextBoxComponent,
    UserSettingsComponent,
    L10nDirective,
    DragDirective,
    DropDirective,
    ClickStopPropagationDirective,
    DropdownComponent,
    ReportTemplatesComponent,
    GenericModalComponentRefDirective,
    L10nPipe,
    ExportReportsComponent,
    AppSettingsComponent,
    AppSettingsRegionLanguageComponent,
    AppSettingsInteractionSettingsComponent,
    UserAgreementSettingsComponent,
    TrialBannerComponent,
    LicenseComparisonComponent,
    LicenseComparisonTableComponent,
    ConfirmChangeComponent,
    UnauthorizedAccessComponent,
    UserAgreementComponent,
    UserAgreementPrivacyComponent,
    WhatsNewComponent,
    SelectRegionLanguageComponent,
    GeneralNotesComponent,
    LoadsMessagesComponent,
    ShortcutIconModalComponent,
    ImageModalComponent,
    MarketingCampaignComponent,
    ArchiveComponent,
    SaveAsTemplateComponent,
    RenameDesignComponent,
    CopyDesignComponent,
    ShareProjectComponent,
    ShareTemplateFolderComponent,
    ShareViewComponent,
    SupportComponent,
    AlertComponent,
    AlertTestingEnvironemtComponent,
    NoopComponent,
    ErrorComponent,
    ProjectAndDesignComponent,
    MainComponent,
    RootComponent,
    NpsSurveyPopupComponent,
    TrimbleConnectBrowserTreeNodeComponent,
    TrimbleConnectBrowserComponent,
    VirtualTourPopupComponent,
    AddEditDesignComponent,
    MainMenuComponent,
    ImportDesignComponent,
    DesktopLicenseWarningComponent,
    GenericModalComponent,
    DesignFromCheckbotComponent,
    MaintenanceComponent,
    AppSettingsStructuralCalculationSoftwareComponent,
    AppSettingsDefaultParametersComponent,
    AppSettingsAdvancedBaseplateCalculationComponent,
    AdvancedCalculationInputsComponent,
    LicenseComparisonHolComponent,
    FeedbackFormComponent,
    VersionComponent,
    VersionPopupComponent,
    HomePageComponent,
    ImportDesignsComponent,
    TreeViewComponent,
    ArchiveHomePageComponent,
    ConfirmChangeInputComponent,
    LaunchDarklyFlagsComponent,
    RightPanelComponent,
    DesignViewComponent,
    QuickStartComponent,
    ModuleQuickStartButtonComponent,
    DesignFromTemplateComponent,
    HomeSwitchComponent,
    ImportFromCheckbotComponent
}

@Injectable({
    providedIn: 'root'
})
export class ComponentProviderService {
    private dictionary: Record<DeclarationType, Type<object>>;

    public register(components: Record<DeclarationType, Type<object>>) {
        this.dictionary = components;
    }

    public get(type: DeclarationType): Type<object> {
        return this.dictionary[type];
    }
}
