import * as React from 'react';

import { IPopupGridProps } from '@profis-engineering/pe-ui-common/entities/main-menu/popup-grid-props';
import { getMenuControlImageReactData, IMenuControlImageData } from '../../../../helpers/react-helpers';
import { ControlHeader } from '../../ControlHeader';
import { buildHtmlTooltip, isControlHidden } from '../../MainMenuHelper';
import { TooltipType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';

export class PopupGrid extends React.PureComponent<IPopupGridProps> {
    constructor(props?: IPopupGridProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.onInfoClick = this.onInfoClick.bind(this);
    }

    public override render() {
        if (isControlHidden(this.props)) {
            return null;
        }

        const buttonId = `${this.props.controlId}-button`;
        let selectedItem = this.props.initialItems.find((item) => item.value == this.props.selectedValue);

        if (selectedItem == null && this.props.notSelectedName != null)
            selectedItem = this.getNotSelectedItem(this.props);

        const buttonName = selectedItem != null ? selectedItem.name : '';
        const buttonDescription = selectedItem != null ? selectedItem.description : '';
        const tooltip = this.props.title != null && this.props.title != '' ? null : buildHtmlTooltip(this.props.tooltip, this.props.tooltipTitle);

        let selectedButtonImageData: IMenuControlImageData | undefined = undefined;
        let useResponsiveStyle = false;
        if (selectedItem != null) {
            selectedButtonImageData = getMenuControlImageReactData(
                selectedItem.image,
                selectedItem.imageStyle?.elementStyle,
                selectedItem.imageStyle?.afterElementStyle
            );
            useResponsiveStyle = selectedButtonImageData.elementAfterStyle != null;
        }

        const infoTooltipKey = 'Agito.Hilti.Profis3.ControlTooltip.Popup';

        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled ? this.props.tooltipDisabled : this.props.tooltip;
        const descriptionTooltip = (this.props.tooltip != null && this.props.tooltip != '') || (this.props.tooltipTitle != null && this.props.tooltipTitle != '') || this.props.hideDescriptionOnButton ? null : buttonDescription;
        const nameCssClass = `name ${this.props.wrapName ? 'wrapped' : '' }`;

        return (
            <div data-control-id={this.props.controlId} className={`react-popup-grid-control control ${this.props.sizeClass}`}>
                <ControlHeader
                    text={this.props.title}
                    controlId={buttonId}
                    tooltip={tooltipText}
                    tooltipTitle={tooltipTitle}
                    localization={this.props.localization}
                    kbLink={this.props.kbLink}
                    kbTooltip={this.props.kbTooltip}
                    infoClick={this.props.tooltipType == TooltipType.Popup ? this.onInfoClick : undefined}
                    infoTooltip={this.props.tooltipType == TooltipType.Popup ? infoTooltipKey : undefined}
                />

                <div className='control-container'>
                    <button
                        data-control-id={buttonId}
                        className='popup-grid-button button-rich'
                        type='button'
                        disabled={this.props.disabled}
                        onClick={this.onClick}
                        data-tip={tooltip}
                        data-html={tooltip != null ? true : null}>
                        <div className='wrapper button-rich-content'>
                            <div className='image-container button-rich-image'>
                                <div className='image-wrapper'>
                                    <span className={`image sprite sprite-responsive ${selectedButtonImageData?.spriteName ?? ''}`} style={selectedButtonImageData?.elementStyle}>
                                        {
                                            useResponsiveStyle
                                                ? <span className='sprite-responsive-after' style={selectedButtonImageData.elementAfterStyle}></span>
                                                : null
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className='button-rich-seperator' />
                            <div className='content-wrapper button-rich-text'>
                                <p className={nameCssClass} data-tip={buttonName}>{buttonName}</p>
                                <p className='description'
                                data-html={descriptionTooltip != null ? true : null}
                                data-tip={descriptionTooltip}>{this.props.hideDescriptionOnButton ? '' : buttonDescription}</p>
                            </div>
                            <span className='sprite sprite-arrow-right button-rich-caret'></span>
                        </div>
                    </button>
                </div>
            </div>
        );
    }

    private onClick(event: React.MouseEvent) {
        if (this.props.clicked != null) {
            this.props.clicked(this.props.selectedValue);
        }
    }

    private onInfoClick(event: React.MouseEvent) {
        if (this.props.infoClicked != null) {
            this.props.infoClicked();
        }
    }

    private getNotSelectedItem(props: IPopupGridProps) {
        return {
            name: props.localization.getString(props.notSelectedName),
            image: props.notSelectedImage, description: ''
        };
    }
}
