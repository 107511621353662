import isEqual from 'lodash-es/isEqual';

import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';
import {
    ReportTemplateEntity
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';
import { ReportTemplateService } from './report-template.service';
import { IReportTemplateLogo } from './report.service';

@Injectable({
    providedIn: 'root'
})
export class ReportTemplateServiceImpl extends ReportTemplateService {

    constructor(
        loggerService: LoggerService,
        private apiService: ApiService,
    ) {
        super('ReportTemplateServiceWeb', loggerService);
    }

    public async getLogo(id: number, logos: Record<number, IReportTemplateLogo>): Promise<IReportTemplateLogo> {
        const url = `${environment.documentWebServiceUrl}ReportLayoutTemplate/Template/Logo/${id}`;
        const request = new HttpRequest('GET', url, {
            responseType: 'blob'
        });

        const response = await this.apiService.request<Blob>(request);
        if (response.body.size > 0) {
            logos[id] = {
                blob: response.body,
                url: URL.createObjectURL(response.body),
                base64: null
            };
        }
        else {
            logos[id] = null;
        }

        return logos[id];
    }

    public async getTemplates(options?: ApiOptions): Promise<ReportTemplateEntity[]> {
        const url = `${environment.documentWebServiceUrl}ReportLayoutTemplate/GetTemplates`;
        const request = new HttpRequest('GET', url);

        const response = await this.apiService.request<ReportTemplateEntity[]>(request, options);

        return response.body;
    }

    public async updateTemplate(templates: ReportTemplateEntity[], savedTemplates: ReportTemplateEntity[]): Promise<number[]> {
        const url = `${environment.documentWebServiceUrl}ReportLayoutTemplate/UpdateTemplate`;

        const requestTemplates = templates.map((template): Partial<ReportTemplateEntity> => {
            if (template.Id == null || template.Id < 1) {
                return template;
            }

            const savedTemplate = savedTemplates.find((t) => t.Id == template.Id);

            return Object.fromEntries((Object.entries(template) as [keyof ReportTemplateEntity, unknown][])
                .filter(([key, value]) => key == 'Id' || key == 'TemplateOptions' || key == 'Logo' || !isEqual(value, savedTemplate[key])));
        });

        // save template to database
        const response = await this.apiService.request<number[]>(new HttpRequest('POST', url, requestTemplates), { supressErrorMessage: true });

        return response.body;
    }

    public async createDefaultTemplate(regionId: number) {
        const url = `${environment.baseplateApplicationWebServiceUrl}CreateDefaultTemplate/` + regionId;

        await this.apiService.request(new HttpRequest('GET', url));
    }
}
