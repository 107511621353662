<div id="desktop-license-warning-modal-content-container">
    <div class="modal-header">
        <h3 class="modal-title" l10n="Agito.Hilti.Profis3.DesktopLicenseWarning.Title"></h3>

        <button id="desktop-license-warning-x-button" type="button" class="close" (click)="dismiss('x-click')">
            <span>&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="modal-message" [innerHtml]="message"></div>
    </div>

    <div class="modal-footer">
        <button id="desktop-license-warning-login-button" type="button"
            *ngIf="isExpirationWarning"
            class="modal-button button button-default"
            l10n="Agito.Hilti.Profis3.Desktop.LicenseWarning.Login"
            (click)="login()">
        </button>

        <button type="button" class="modal-button button button-CTA"
        id="desktop-license-warning-close-button"
        l10n="Agito.Hilti.Profis3.Desktop.LicenseWarning.Close"
        (click)="close()">
    </button>
    </div>
</div>