import { Component } from '@angular/core';
import { IVirtualTour } from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { urlPath } from '../../module-constants';
import { FeatureVisibilityService } from '../../services/feature-visibility.service';
import { LocalizationService } from '../../services/localization.service';
import { RoutingService } from '../../services/routing.service';
import { TourService } from '../../services/tour.service';

export interface IVirtualTourPopupComponentInput {
    selectTab?: (tab: string) => void;
}

@Component({
    selector: 'app-virtual-tour-popup',
    templateUrl: './virtual-tour-popup.component.html',
    styleUrls: ['./virtual-tour-popup.component.scss']
})
export class VirtualTourPopupComponent {
    public designTours: IVirtualTour[];

    constructor(
        private featureVisibilityService: FeatureVisibilityService,
        private tour: TourService,
        private modalInstance: ModalInstance<IVirtualTourPopupComponentInput>,
        private routingService: RoutingService,
        private localization: LocalizationService
    ) {
        // Get all tours that are available for design.
        this.designTours = this.tour.getDesignTours();
    }
    public get isNewHomePageTourAvailable(): boolean {
       return this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage');
    }

    public openNavigationTour(startNewHomePageTour: boolean) {
        this.close();
        this.tour.startNavigationTour(startNewHomePageTour);
        this.routingService.navigateToUrl(urlPath.projectAndDesign);
    }

    public openDesignTour(tour: IVirtualTour) {
        this.close();
        try {
            tour.openTour(this.modalInstance.input.selectTab);
        }
        catch (err) {
            console.error(err)
        }
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }
}
