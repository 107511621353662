<div class="import-design-title" l10n="Agito.Hilti.Profis3.ProjectAndDesing.ImportDesign.Title"
    *ngIf="mode != ImportDesignMode.compact"></div>

<div class="import-design" [ngClass]="{ 'over': over, 'show-loading-text': importing }"
    (click)="containerClick()">
    <span class="message" l10n="{{message}}"
        *ngIf="!(mode == ImportDesignMode.compact && importing)">
    </span>

    <div class="loading-container compact-loading"
        *ngIf="mode == ImportDesignMode.compact && importing">
            <pe-loading [color]="'#524f53'"></pe-loading>
    </div>

    <div class="import-design-button-container" *ngIf="mode != ImportDesignMode.compact">
        <button id="import-design-button" class="button button-default"
            (click)="selectImportFile();"
            [disabled]="importing || disabled"
            type="button">
            <pe-loading-button
                [loading]="importing">
                <span class="text" l10n="Agito.Hilti.Profis3.ProjectAndDesing.ImportDesign.File"></span>
            </pe-loading-button>
        </button>
    </div>

    <input #importDesignRef class="import-design-input"  (change)="importFileSelected()" name="project" type="file"
        [accept]="allowedExtensions" (click)="$event.stopPropagation();" />
</div>

<button [disabled]="importing || disabled"
    id="import-design-trimble-connect-button" class="button button-default import-trimble-connect"
    (click)="importTrimbleConnect()"
    l10n="Agito.Hilti.Profis3.ProjectAndDesing.ImportDesign.TrimbleConnectImport" type="button"
    *ngIf="trimbleConnect.isEnabled && browser.isOfflineOnLine">
</button>

<div class="button button-default import-trimble-connect no-connection"
    disabled
    l10n="Agito.Hilti.Profis3.ProjectAndDesing.ImportDesign.TrimbleConnectImport"
    [ngbTooltip]="localization.getLocalizedString('Agito.Hilti.Profis3.Browser.NoInternetConnection')"
    [openDelay]="0" [disableTooltip] = "!trimbleConnect.isEnabled || browser.isOfflineOnLine" *ngIf="!trimbleConnect.isEnabled || !browser.isOfflineOnLine">
</div>
