import { Injectable } from '@angular/core';
import { SafeFunctionInvokerHelper } from '@profis-engineering/pe-ui-common/helpers/safe-function-invoker-helper';
import { environment } from '../../environments/environment';
import { Design } from '../entities/design';
import { TrackingServiceBwCompat } from '../helpers/bw-compat/tracking.service.bw-compat';
import { ModulesService } from './modules.service';

@Injectable({
    providedIn: 'root'
})
export class ModulesTrackingService {
    constructor(
        private modulesService: ModulesService,
        private trackingServiceBwCompat: TrackingServiceBwCompat
    ) { }


    public async saveTrackingActData(design: Design): Promise<void> {
        const saveTrackingData = this.getTrackingInfo(design.designType.id)?.saveTrackingData;
        if (saveTrackingData == null) {
            return this.trackingServiceBwCompat.saveTrackingData(design);
        }

        return SafeFunctionInvokerHelper.safeInvoke(
            () => saveTrackingData(
                design,
                environment.applicationVersion,
                environment.useDevFeatures,
                true
            )
        );
    }


    public async trackOnTemplateClose(design: Design): Promise<void> {
        const trackOnTemplateClose = this.getTrackingInfo(design.designType.id)?.trackOnTemplateClose;
        if (trackOnTemplateClose == null) {
            return this.trackingServiceBwCompat.trackOnTemplateClose(design);
        }

        return SafeFunctionInvokerHelper.safeInvoke(
            () => trackOnTemplateClose(
                design,
                design.id,
                environment.applicationVersion,
                environment.useDevFeatures,
                true
            )
        );
    }


    public async trackOnDesignOpen(design: Design) {
        const trackOnDesignOpen = this.getTrackingInfo(design.designType.id)?.trackOnDesignOpen;
        if (trackOnDesignOpen == null) {
            return this.trackingServiceBwCompat.trackOnDesignOpen(design);
        }

        return SafeFunctionInvokerHelper.safeInvoke(
            () => trackOnDesignOpen(
                design,
                environment.applicationVersion,
                environment.useDevFeatures,
                true
            )
        );
    }

    public async trackOnDesignClose(design: Design): Promise<void> {
        const trackOnDesignClose = this.getTrackingInfo(design.designType.id)?.trackOnDesignClose;
        if (trackOnDesignClose == null) {
            return this.trackingServiceBwCompat.trackOnDesignClose(design);
        }

        return SafeFunctionInvokerHelper.safeInvoke(
            () => trackOnDesignClose(
                design,
                environment.applicationVersion,
                environment.useDevFeatures,
                true
            )
        );
    }


    public handleDesignBrowserUnload(design: Design, sessionKey: string): Promise<void> {
        const handleDesignBrowserUnload = this.getTrackingInfo(design.designType.id)?.handleDesignBrowserUnload;
        if (handleDesignBrowserUnload == null) {
            return this.trackingServiceBwCompat.handleDesignBrowserUnload(sessionKey, design);
        }

        return SafeFunctionInvokerHelper.safeInvoke(
            () => handleDesignBrowserUnload(
                sessionKey,
                design,
                environment.applicationVersion,
                environment.useDevFeatures,
                true
            )
        );
    }


    private getTrackingInfo(designType: number) {
        return this.modulesService.getTrackingInfo(designType);
    }
}
