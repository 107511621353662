import { Group } from './Controls/Group/Group';
import { ImageNameCheckboxGroup } from './Controls/ImageNameCheckboxGroup/ImageNameCheckboxGroup';
import { ImageNameRadioGroup } from './Controls/ImageNameRadioGroup/ImageNameRadioGroup';
import { Rotate } from './Controls/Rotate/Rotate';
import { TabGroup } from './Controls/TabGroup/TabGroup';
import { ToggleButtonGroup } from './Controls/ToggleButtonGroup/ToggleButtonGroup';

// used to remove circular dependencies
export const ControlType = {
    Rotate: undefined as typeof Rotate,
    Group: undefined as typeof Group,
    ToggleButtonGroup: undefined as typeof ToggleButtonGroup,
    ImageNameRadioGroup: undefined as typeof ImageNameRadioGroup,
    ImageNameCheckboxGroup: undefined as typeof ImageNameCheckboxGroup,
    TabGroup: undefined as typeof TabGroup
};
