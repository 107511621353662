<div class="modal-header">
    <h3 class="modal-title" l10n="Agito.Hilti.Profis3.MarketingCampaign.Title"></h3>

    <button id="marketing-campaign-close-button" type="button" class="close" (click)="close()">
        <span>&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="video-campaign" *ngIf="isVideo">
        <div>
            <iframe [src]="url" allowfullscreen frameborder="0" title="video campaign"></iframe>
        </div>
    </div>

    <div class="image-campaign" *ngIf="isImage">
        <a target="_blank" [href]="link" *ngIf="hasLink" (click)="trackLinkClicked()">
            <img [src]="url" alt="campaign image" />
        </a>
        <img [src]="url" *ngIf="!hasLink" alt="campaign image" />
    </div>

    <div class="dont-show" *ngIf="dontShowOnLaunchVisible">
        <pe-checkbox-button
            [items]="dontShowOnLaunchCheckbox.items"
            [selectedValues]="dontShowOnLaunchCheckbox.selectedValues"
            (selectedValuesChange)="dontShowOnLaunchCheckbox.selectedValues = $any($event).detail">
        </pe-checkbox-button>
    </div>
</div>
