import {
    UrlPath as urlPathCommon
} from '@profis-engineering/pe-ui-common/entities/module-constants';

// Application constants
// use camelCase names for controllers, directives, factories and services

export const moduleName = {
    main: 'HiltiProfis3'
};

export class urlPath extends urlPathCommon {
    public static mainDecking = '/decking/';
    public static trimbleCallback = '/tc_auth_callback';
}

export const storageKey = {
    loginState: 'loginState',
    loginReturnUrl: 'loginReturnUrl',
    registrationState: 'registrationState',
    registrationReturnUrl: 'registrationReturnUrl',
};

export const npsMinDesigns = 2;
