<div class="modal-header">
    <h3 class="modal-title" l10n="Agito.Hilti.Profis3.AlertTestingEnvironment.AttentionText.Title"></h3>

    <button type="button" id="alert-close-button" class="close" (click)="close()" *ngIf="!preventClose">
        <span>&times;</span>
    </button>
</div>
<div class="modal-body" >
    <span class="sprite sprite-warning-orange"></span>

    <div class="header" l10n="Agito.Hilti.Profis3.AlertTestingEnvironment.AttentionText.Header"></div>
    <div class="body" l10n="Agito.Hilti.Profis3.AlertTestingEnvironment.AttentionText.Body"></div>
    <div class="footer" l10n="Agito.Hilti.Profis3.AlertTestingEnvironment.AttentionText.Footer"></div>

    <button type="button" id="go-to-profis-button" (click)="goToProfis()" class="button button-primary"
        l10n="Agito.Hilti.Profis3.AlertTestingEnvironment.AttentionText.Button"></button>
</div>

