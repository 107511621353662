import cloneDeep from 'lodash-es/cloneDeep';

import {
    IDesignTemplateResponse, TemplateFolderUser
} from '@profis-engineering/pe-ui-common/entities/template';
import {
    ArchiveProjectTemplateResponseModel, ArchiveTemplateDesignResponseModel, DesignTemplateEntity
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.DesignTemplate';
import {
    ProjectExpandedState
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.Projects';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';
import {
    DesignTemplateServiceBase
} from '@profis-engineering/pe-ui-common/services/design-template.common';
import { environment } from '../../environments/environment';
import {
    DesignTemplateFolderDetail, TemplateDocumentRequestModel, TemplateFolderRequestModel
} from '../components/home-page/template-folder';
import { IDocumentArchive } from '../entities/document';
import { IProjectArchive } from '../entities/project';
import { SharedTemplateUser, TemplateUser } from '../entities/template';
import { ApiService } from './api.service';
import { DateTimeService } from './date-time.service';
import { DocumentService } from './document.service';
import { ErrorHandlerService } from './error-handler.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { ModalService } from './modal.service';
import { DesignTypeId } from './modules.service';
import { OfflineService } from './offline.service';

export interface IDesignTemplateFolderUpdate {
    designTemplateDocumentId?: string;
    templateFolderId?: string;
}

export abstract class DesignTemplateService extends DesignTemplateServiceBase {
    protected _templates: DesignTemplateEntity[] = [];
    protected _templatesV2: { [key: string]: DesignTemplateEntity } = {};
    protected _templatesFolders: { [key: string]: DesignTemplateFolderDetail } = {};
    protected _flatTemplatesFolders: { [key: string]: DesignTemplateFolderDetail } = {};
    protected _pendingRequests = 0;
    protected _templatesFolderArchive: IProjectArchive[] = [];
    protected _designTemplateArchive: IDocumentArchive[] = [];

    constructor(
        protected serviceName: string,
        protected loggerService: LoggerService,
        protected dateTimeService: DateTimeService,
        protected apiService: ApiService,
        protected documentService: DocumentService,
        protected errorHandlerService: ErrorHandlerService,
        protected modalService: ModalService,
        protected offlineService: OfflineService,
        protected localizationService: LocalizationService
    ) {
        super();
    }

    public get templates() {
        return this._templates;
    }
    public set templates(value) {
        this._templates = value;
    }
    public get templateV2() {
        return this._templatesV2;
    }

    public get templatesFolders() {
        return this._templatesFolders;
    }

    public get templatesFoldersFlat() {
        return this._flatTemplatesFolders;
    }

    public set templatesFoldersFlat(value) {
        this._flatTemplatesFolders =  value;
    }

    public get templateFoldersArchive(): IProjectArchive[] {
        return this._templatesFolderArchive;
    }

    public get designTemplateArchive(): IDocumentArchive[] {
        return this._designTemplateArchive;
    }

    private get excludedDesignTypeIds() {
        const retVal: number[] = [];

        if (!environment.c2cEnabled && !environment.c2cOverlayDemoEnabled) {
            retVal.push(DesignTypeId.Concrete2Concrete);
        }

        return retVal;
    }


    public initialize(data: DesignTemplateEntity[]) {
        this._templates = data || [];

        const excludedDesignTypeIds = this.excludedDesignTypeIds;
        if (excludedDesignTypeIds.length) {
            this._templates = this.templates.filter(x => !excludedDesignTypeIds.includes(x.DesignTypeId));
        }

        this.templates.forEach((template) => template.DateCreate = this.dateTimeService.toDate(template.DateCreate));
    }

    // Initialize new home page template and folders
    public initializeAll(data?: IDesignTemplateResponse, dataProjectTemplateArchive?: ArchiveProjectTemplateResponseModel[], dataDesignTemplateArchive?: ArchiveTemplateDesignResponseModel[]) {
        if (data) {
            const templates = Object.values(data.DesignTemplates);
            this.initialize(templates);
            this._templatesV2 = data.DesignTemplates;
            const newList: { [key: string]: DesignTemplateFolderDetail } = {};
            Object.values(data.TemplateFolder).forEach(e => {
                newList[e.templateFolderId] = new DesignTemplateFolderDetail(e);
                this.templates.filter(x => x.templateFolderId == e.templateFolderId).forEach(x => {
                    newList[e.templateFolderId].templates[x.DesignTemplateDocumentId] = x;
                });
            });

            const allParentFolders = Object.values(newList)
                .filter(folder => folder.parentTemplateFolderId === null || !new Set(Object.keys(newList))
                    .has(folder.parentTemplateFolderId));

            for (const folder of allParentFolders) {
                // Set parentTemplateFolderId to null(for building tree) as there might be some folders whose parent is not shared
                folder.parentTemplateFolderId = null;
            }

            this._flatTemplatesFolders = cloneDeep(newList);
            this._templatesFolders = cloneDeep(this.buildTemplateTree(this._flatTemplatesFolders));
            if (dataProjectTemplateArchive) {
                this.getArchiveTemplateInternal(dataProjectTemplateArchive);
            }
            if (dataDesignTemplateArchive) {
                this.getArchiveDesignTemplateInternal(dataDesignTemplateArchive);
            }
            this.setSharedStatusforPartiallySharedProject();
        }
    }

    //  /**
    //  * Sets the projects isSharedByMe property to true for a partially shared project.
    //  * This is needed for generating the SharedByMe tree structure
    //  */
    private setSharedStatusforPartiallySharedProject(): void {
        Object.values(this._flatTemplatesFolders).forEach(p => {
            if (p.isSharedByMe || Object.values(this._templatesV2).some(d => d.templateFolderId === p.templateFolderId && d.isSharedByMe)) {
                this.setSharedByMeTillParentNode(this.findTemplateFolderById(p.templateFolderId), true);
            }
        });
    }
    public setSharedByMeTillParentNode(item: DesignTemplateFolderDetail, sharedValue: boolean) {
        if (item && item.owner) {
            item.isSharedByMe = sharedValue;
            this._flatTemplatesFolders[item.templateFolderId].isSharedByMe = sharedValue;
        }

        if (item.parentTemplateFolderId) {
            const folder = this.findTemplateFolderById(item.parentTemplateFolderId);
            this.setSharedByMeTillParentNode(folder, sharedValue);
        }
    }

    private getArchiveTemplateInternal(dataArchive: ArchiveProjectTemplateResponseModel[]): void {
        this._templatesFolderArchive = [];

        for (const item in dataArchive) {
            this._templatesFolderArchive.push(this.mapArchiveTemplateResponseEntityToIProjectArchive(dataArchive[item]));
        }
    }

    private mapArchiveTemplateResponseEntityToIProjectArchive(row: ArchiveProjectTemplateResponseModel): IProjectArchive {
        return {
            archived: row.archived,
            created: row.created,
            id: row.templateid,
            designs: row.designs,
            members: row.members,
            name: row.name,
            parentId: row.parenttemplateid
        };
    }

    private getArchiveDesignTemplateInternal(dataArchive: ArchiveTemplateDesignResponseModel[]): void {
        this._designTemplateArchive = [];

        for (const item in dataArchive) {
            this._designTemplateArchive.push(this.mapArchiveDesignResponseEntityToIDocumentArchive(dataArchive[item]));
        }
    }

    public mapArchiveDesignResponseEntityToIDocumentArchive(row: ArchiveTemplateDesignResponseModel): IDocumentArchive {
        return {
            archived: row.archived,
            created: row.created,
            id: row.designTemplateDocumentId,
            designs: row.designs,
            members: row.members,
            name: row.name,
            parentProjectId: row.parenttemplatefolderid,
            projectname: row.templatename
        };
    }


    findTemplateFolderById(templateFolderId: string): DesignTemplateFolderDetail {
        return this._flatTemplatesFolders[templateFolderId];
    }

    public checkExistingTemplateFolder(templateName: string, parentId: string) {
        return Object.values(this._flatTemplatesFolders).some(
            (p) => p.name.toLowerCase().trim() == templateName.toLowerCase().trim()
            && (p.parentTemplateFolderId == parentId)
            && p.owner);
    }

    public abstract getList(options?: ApiOptions): Promise<DesignTemplateEntity[]>;

    public abstract delete(templateId: string): Promise<void>;

    public abstract getDocumentThumbnails(documentIds: string[]): Promise<Record<string, string>>;

    public abstract uploadDesignImage(designId: string, imageContent: string, thumbnailContent: string): Promise<void>;

    protected getApiOptions(): ApiOptions {
        return {
            supressErrorMessage: true,
        };
    }

    /**
     * Handle error codes that demand the same reaction no matter in what circumstances (what was called) it occurred.
     * @param _response - the entire server error response.
     * @param _additionalData - any additional data
     * @param errorsToHandle - what error code should this call handle.
     */
    protected handleError(_response: unknown, _additionalData: unknown, errorsToHandle: number[]) {
        const errorHandeled = false;
        if (errorsToHandle != undefined && errorsToHandle != null) {
            // TODO: add logic for any generally recognized errors
        }

        return errorHandeled;
    }

    protected logServiceResponse(fnName: string, ...args: unknown[]): void {
        this.loggerService.logServiceResponse(this.serviceName, fnName, ...args);
    }

    protected logServiceRequest(fnName: string, ...args: unknown[]): void {
        this.loggerService.logServiceRequest(this.serviceName, fnName, ...args);
    }

    protected getUrl() {
        return environment.documentWebServiceUrl;
    }

    public findById(templateId: string): DesignTemplateEntity {
        return this.templates.find(x => x.DesignTemplateDocumentId == templateId);
    }

    public createTemplateFolder(_templateFolder: TemplateFolderRequestModel): Promise<DesignTemplateFolderDetail> {
        return Promise.resolve(null);
    }

    public getSortedTemplateFolders(nodes: { [key: string]: DesignTemplateFolderDetail }): Record<string, DesignTemplateFolderDetail> {
        const sortedNodes = this.sortFoldersByName(nodes);

        for (const node in sortedNodes) {
            const folder = sortedNodes[node];
            if (folder.subFolders && Object.entries(folder.subFolders).length > 0) {
                folder.subFolders = this.getSortedTemplateFolders(folder.subFolders);
            }
        }

        return sortedNodes;
    }
    protected sortFoldersByName(folders: Record<string, DesignTemplateFolderDetail>): Record<string, DesignTemplateFolderDetail> {
        const sortedProjects: Record<string, DesignTemplateFolderDetail> = {};

        // Get the keys of the projects object and sort them alphabetically
        const sortedKeys = Object.keys(folders).sort((a, b) => {
            const projectA = folders[a].name.toLowerCase();
            const projectB = folders[b].name.toLowerCase();
            if (projectA < projectB) {
                return -1;
            } else if (projectA > projectB) {
                return 1;
            } else {
                return 0;
            }
        });

        // Create a new object with sorted projects
        sortedKeys.forEach((key) => {
            sortedProjects[key] = folders[key];
        });

        return sortedProjects;
    }

    public buildTemplateTree(item: { [key: string]: DesignTemplateFolderDetail }): { [key: string]: DesignTemplateFolderDetail } {

        const buildTemplateFolderNode = (item: { [id: string]: DesignTemplateFolderDetail }, id: string): DesignTemplateFolderDetail => {
            const node = item[id];
            const children: { [id: string]: DesignTemplateFolderDetail } = {};
            const templates = Object.fromEntries(Object.entries(this.templateV2).filter(([key, document]) => document.templateFolderId == id));
            node.templates = templates;
            node.isSharedByMe = node.owner && (node.isSharedByMe || Object.values(templates).some(t => t.isSharedByMe));

            for (const childId in item) {
                if (item[childId].parentTemplateFolderId === id) {
                    const childNode: DesignTemplateFolderDetail = buildTemplateFolderNode(item, childId);
                    children[childId] = childNode;
                    Object.values(childNode.templates).forEach((t) => {
                        item[id].templates[t.DesignTemplateDocumentId] = t;
                    });
                }
            }
            node.subFolders = children;
            return node;
        }

        const tree: Record<string, DesignTemplateFolderDetail> = {};
        for (const id in item) {
            if (!item[id].parentTemplateFolderId) {
                tree[id] = buildTemplateFolderNode(item, id)
            }
        }
        return tree;
    }

    /* eslint-disable @typescript-eslint/no-unused-vars */
    async getUsersOnTemplateFolderById(_templateFolderId: string): Promise<TemplateFolderUser[]> {
        return Promise.resolve([]);
    }

    async addUsersOnTemplateFolderById(_newUser: TemplateFolderUser): Promise<void> {
        return Promise.resolve();
    }

    userHasAccessToTemlateFolder(_templateFolderId: string): Promise<boolean> {
        return Promise.resolve(false);
    }

    public removeUsersOnTemplateFolderById(_data: TemplateFolderUser): Promise<void> {
        return Promise.resolve();
    }

    public renameTemplateFolder(_templateFolder: DesignTemplateFolderDetail): Promise<DesignTemplateFolderDetail> {
        return Promise.resolve(null);
    }

    public async deleteTemplateFolderLocal(_templateFolderId: string): Promise<void> {
        throw new Error('Method not implemented.');
    }

    async archiveExistingFolderTemplate(_templateFolderId: string): Promise<unknown> {
        return Promise.resolve(null);
    }

    async archiveExistingTemplate(_templateDocumentRequestModel : TemplateDocumentRequestModel): Promise<unknown>{
        return Promise.resolve(null);
    }

    public getUsersOnTemplateById(_templateId: string): Promise<TemplateUser[]> {
        const user = {} as TemplateUser[];
        return Promise.resolve(user);
    }

    public addUsersOnTemplate(_data: SharedTemplateUser): Promise<void> {
        return Promise.resolve();
    }

    public removeUsersOnTemplate(_data: SharedTemplateUser, _markDesign = false): Promise<void> {
        return Promise.resolve();
    }

    public async toggleExpandTemplateFolder(_templateFolderId: string, _expandedState: ProjectExpandedState): Promise<void> {
        return Promise.resolve();
    }

    public async restoreExistingTemplateFolder(_templateFolderId: string): Promise<string[]> {
        return Promise.resolve([]);
    }

    public async restoreExisitingTemplate(_templateId: string) : Promise<void> {
        return Promise.resolve();
    }

    public async updateTemplateFolder(_designTemplateFolder: IDesignTemplateFolderUpdate) : Promise<void> {
        return Promise.resolve();
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
