import sortBy from 'lodash-es/sortBy';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
    TextBoxProps
} from '@profis-engineering/pe-ui-common/components/text-box/text-box.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { LocalizationService } from '../../../services/localization.service';
import { DateTimeService } from '../../../services/date-time.service';
import { ModalService } from '../../../services/modal.service';
import { emailsValidator } from '../../../validators/emails.validator';
import { TemplateFolderUser } from '@profis-engineering/pe-ui-common/entities/template';
import { DesignTemplateFolderDetail } from '../template-folder';
import { DesignTemplateService } from '../../../services/design-template.service';

export interface IShareTemplateFolderComponentInput {
    selectedTemplateFolder: DesignTemplateFolderDetail;
    onRemoveTemplateFolder: () => void;
    onConfirm?: (modal: boolean) => any;
}

@Component({
    selector: 'app-share-template-folder',
    templateUrl: './share-template-folder.component.html',
    styleUrls: ['./share-template-folder.component.scss']
})
export class ShareTemplateFolderComponent implements OnInit {
    public templateFolderSharedUsers: TemplateFolderUser[];
    public submitted: boolean;
    public userTextBox: TextBoxProps = {};

    public sharedUsersHeaderHeight = 39;    // must be the same value as in css
    public sharedUsersRowHeight = 35;       // must be the same value as in css
    public scrollElement: Element;

    private pendingSave: boolean;

    constructor(
        public localization: LocalizationService,
        public dateTime: DateTimeService,
        private templateService: DesignTemplateService,
        private modal: ModalService,
        private modalInstance: ModalInstance<IShareTemplateFolderComponentInput>
    ) { }

    public get selectedTemplateFolder() {
        return this.modalInstance.input?.selectedTemplateFolder;
    }

    public get hideShareTable() {
        return this.templateFolderSharedUsers.length < 1;
    }

    public get formValid() {
        return this.userTextBox?.isValid;
    }

    private get onRemoveTemplateFolder() {
        return this.modalInstance.input?.onRemoveTemplateFolder;
    }

    ngOnInit(): void {
        // don't close the modal if save is pending
        this.modalInstance.setOnClosing(() => {
            if(this.selectedTemplateFolder.users?.length == 1)
            {
                this.modalInstance.input.onConfirm(true);
            }
            if (this.pendingSave) {
                return false;
            }

            if (this.onRemoveTemplateFolder != null) {
                this.templateService.userHasAccessToTemlateFolder(this.selectedTemplateFolder.templateFolderId)
                    .then((res) => {
                        if (!res) {
                            this.onRemoveTemplateFolder();
                        }
                    });
            }

            return true;
        });

        this.scrollElement = document.querySelector('.modal');

        this.updateTemplateFolderSharedUsers();

        this.userTextBox = {
            id: 'share-project-add-users',
            title: this.localization.getString('Agito.Hilti.Profis3.ShareProject.AddUserTitle'),
            placeholder: this.localization.getString('Agito.Hilti.Profis3.ShareProject.AddUserPlaceholder'),
            maxLength: 250,
            validators: [emailsValidator(';')]
        };

        setTimeout(() => this.userTextBoxFocus());
    }

    public close() {
        this.modalInstance.close();
    }

    public addUser(form: NgForm) {
        if (this.submitted
            || this.pendingSave
            || !this.formValid
            || (form.enabled && !form.valid)
            || this.userTextBox.value == null
            || this.userTextBox.value.trim() == ''
        ) {
            return;
        }

        this.submitted = true;
        this.pendingSave = true;

        const inputUser = this.userTextBox.value.toLowerCase().trim();
        const users = inputUser.split(';').map(it => it.trim()).filter(it => it != '');
        if (users.length == 0) {
            return;
        }

        let completed = 0;
        const updatedUsers: TemplateFolderUser[] = [];
        const now = new Date();
        const nowUtc = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds()));

        const promises: Promise<string>[] = [];
        const processedUsers: string[] = [];
        const selectedTemplateFolder = this.selectedTemplateFolder;
        for (const user of users) {
            if (user == null || user.trim() == '') {
                continue;
            }

            // add only if entry does not exist in the selected project
            if (selectedTemplateFolder.users.findIndex((item) => item.templateFolderId === selectedTemplateFolder.templateFolderId && item.user.toLowerCase() === user.toLowerCase()) >= 0) {
                continue;
            }

            // add only if not yet processed in current request
            if (processedUsers.findIndex((item) => item.toLowerCase() === user.toLowerCase()) >= 0) {
                continue;
            }

            const newUser: TemplateFolderUser = {
                templateFolderId: selectedTemplateFolder.templateFolderId,
                user,
                modified: true,
                dateadded: nowUtc,
                isowner: false
            };
            promises.push(this.templateService.addUsersOnTemplateFolderById(newUser).then(_ => user));

            processedUsers.push(user.toLowerCase());
        }

        if (promises.length > 0) {
            new Promise((resolve, reject) => {
                promises.forEach((prom) => {
                    prom.then((userName) => {
                        if (userName != undefined) {
                            const user: TemplateFolderUser = {
                                templateFolderId: selectedTemplateFolder.templateFolderId,
                                user: userName,
                                modified: true,
                                dateadded: nowUtc,
                                isowner: false
                            };

                            updatedUsers.push(user);
                            selectedTemplateFolder.users.push(user);
                        }
                    }).catch((err) => {
                        if (err instanceof Error) {
                            console.error(err);
                        }
                    }).finally(() => {
                        completed++;
                        if (completed == promises.length) {
                            if (updatedUsers.length == completed) {
                                resolve(promises);
                            }
                            else {
                                reject(promises);
                            }
                            this.updateTemplateFolderSharedUsers();
                            this.modalInstance.input.onConfirm(true);
                        }
                    });
                });
            }).finally(() => {
                this.submitted = false;
                this.pendingSave = false;

                this.userTextBox.value = null;
            });
        }
        else {
            this.submitted = false;
            this.pendingSave = false;

            this.userTextBox.value = null;
        }
    }

    public deleteUser(user: TemplateFolderUser) {
        if (this.submitted || this.pendingSave) {
            return;
        }

        const opened = this.modal.openConfirmChange({
            id: 'confirm-delete-project-permanently',
            title: 'Remove a template folder',
            message: 'Do you want to remove this user from the template folder?',
            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.ShareProject.ConfirmDelete.Ok'),
            cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.ShareProject.ConfirmDelete.Cancel'),
            onConfirm: (modal) => {
                modal.close(true);
            },
            onCancel: (modal) => {
                modal.close(false);
            }
        }).result;

        this.submitted = this.pendingSave = true;

        opened
            .then((result: any) => {
                // Confirm closed
                new Promise<void>((resolve, reject) => {
                    if (result) {
                        this.templateService.removeUsersOnTemplateFolderById(user)
                            .then(() => {
                                this.selectedTemplateFolder.users = this.selectedTemplateFolder.users.filter((item) => item != user);
                                this.updateTemplateFolderSharedUsers();
                                resolve();
                            });
                        return;
                    }

                    reject();
                }).finally(() => {
                    this.submitted = this.pendingSave = false;
                    this.modalInstance.input.onConfirm(true);
                });
            })
            .catch(() => {
                // Confirm dismissed
                this.submitted = this.pendingSave = false;
            });
    }

    private updateTemplateFolderSharedUsers() {
        const selectedTemplateFolder = this.selectedTemplateFolder;
        if (selectedTemplateFolder?.users == null) {
            this.templateFolderSharedUsers = [];
            return;
        }

        this.templateFolderSharedUsers = sortBy(
            selectedTemplateFolder.users.filter((u) => !u.isowner),
            (user) => user.dateadded
        ).reverse();
    }

    private userTextBoxFocus() {
        document.querySelector<HTMLElement>('#share-project-add-users-wrapper')
            ?.shadowRoot?.querySelector<HTMLElement>('#share-project-add-users')
                ?.focus();
    }
}
