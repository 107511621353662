import * as React from 'react';
import { ChangeEvent } from 'react';
import { isControlHidden } from '../../MainMenuHelper';
import { ISwitchWithDescriptionProps } from '@profis-engineering/pe-ui-common/entities/main-menu/switch-with-description';

export class SwitchWithDescription extends React.PureComponent<ISwitchWithDescriptionProps> {
    constructor(props?: ISwitchWithDescriptionProps) {
        super(props);

        this.toggleCheckboxChange = this.toggleCheckboxChange.bind(this);
    }

    public override render() {
        if (isControlHidden(this.props)) {
            return null;
        }
        const disableClassName = this.props.disabled ? 'disabled' : '';
        const tooltipText = this.props.disabled ? this.props?.tooltipDisabled : null;

        return (
            <div data-control-id={this.props.controlId} data-tip={tooltipText} className={`react-switch-with-description control ${this.props.sizeClass}`}>
                <div className='content-holder'>
                    <div className='text'>
                        {this.props.header}
                    </div>
                    {this.props.showBeta && (
                        <div className='beta-label'>
                            {this.props.localization.getString('Agito.Hilti.Profis3.Navigation.SmartAnchor.SmartAnchorEnabled.Beta.Title')}
                        </div>
                    )}
                    <div className='button-container'>
                        <input
                            type="checkbox"
                            checked={this.props.checked}
                            value={this.props.checked.toString()}
                            onChange={this.toggleCheckboxChange}
                            data-control-id="toggle"
                            disabled={this.props.disabled}
                            className={'toggleCheckbox' + ' ' + disableClassName}
                        />
                        <label data-for="toggle" className={'toggleContainer' + ' ' + disableClassName}>
                            <div> {this.props.disabledLabel}</div>
                            <div> {this.props.enabledLabel}</div>
                        </label>
                    </div>
                </div>
            </div>
        );
    }

    toggleCheckboxChange(event: ChangeEvent<HTMLInputElement>) {
        if (!this.props?.disabled) {
            this.props.checkChanged(event.target.checked);
        }
    }
}
