import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { IDesignListInfo } from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { ICalculationResult } from '../entities/design';
import { DocumentService, IDesignListItem } from '../services/document.service';
import { FeatureVisibilityService } from '../services/feature-visibility.service';
import { ModulesService } from '../services/modules.service';
import { UserService } from '../services/user.service';
import { GuardFactory } from './combined.guard';

const checkModuleTimeoutMs = 5000;
const checkModulePollingMs = 100;

export const mainGuard: GuardFactory = (handleGuardAction) => {
    const router = inject(Router);
    const userService = inject(UserService);
    const documentService = inject(DocumentService);
    const modulesService = inject(ModulesService);
    const featureVisibilityService = inject(FeatureVisibilityService)

    async function getCalculationResult(documentDesign: IDesignListItem) {
        const designListInfo = await checkDesignListInfoUntilTimeout(documentDesign.metaData.designType, checkModuleTimeoutMs, checkModulePollingMs);
        if (designListInfo?.checkDesign != null) {
            try {
                return await designListInfo.checkDesign(documentDesign) as ICalculationResult;
            }
            catch (error) {
                console.error(error);
                throw new Error(`Error consuming design type ${documentDesign.metaData.designType}`);
            }
        }

        throw new Error(`No module to consume design type ${documentDesign.metaData.designType}`);
    }


    async function checkDesignListInfoUntilTimeout(designTypeId: number, timeoutInMs: number, intervalInMs: number): Promise<IDesignListInfo> {
        const startTime = Date.now();

        const getDesignListInfo = () => modulesService.getDesignListInfoByDesignType(designTypeId);

        let value = getDesignListInfo();

        const subscription = modulesService.designListInfoProvided.subscribe(() => {
            value = getDesignListInfo();
        });

        while (value == null && Date.now() - startTime < timeoutInMs) {
            await new Promise(resolve => setTimeout(resolve, intervalInMs));
        }

        subscription.unsubscribe();
        return value;
    }


    return (route: ActivatedRouteSnapshot) => handleGuardAction(async () => {
        if (userService.design == null || (userService.project == null && !userService.design.isTemplate)) {
            const id = route.paramMap.get('id');
            const documentDesign = id ? documentService.findDesignById(id) : null;
            if (documentDesign == null) {
                return router.createUrlTree(['/']);
            }

            try {
                const result = await getCalculationResult(documentDesign);
                if(featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage')) {
                    userService.changeDesign(documentService.findProjectById(documentDesign.projectId), result.design);
                } else {
                    userService.changeDesign(documentService.findProjectByDesignId(id), result.design);
                }
            }
            catch (error) {
                console.error(error);

                return router.createUrlTree(['/']);
            }
        }

        return true;
    });
};
