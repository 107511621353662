import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { OfflineService } from './offline.service';


export interface ApplicationVersion {
    Version?: string;
}


@Injectable({
    providedIn: 'root'
})
export class ApplicationVersionsService {
    private serviceApplicationVersion?: string;
    private serviceApplicationVersionLoaded = false;

    private environmentApplicationVersion: string;


    constructor(
        private apiService: ApiService,
        private offlineService: OfflineService,
        private titleService: Title
    ) {
        this.environmentApplicationVersion = environment.applicationVersion;
    }


    public async getApplicationVersionFromService(forceUpdate = false, apiOptions?: ApiOptions) {
        if (this.serviceApplicationVersionLoaded && !forceUpdate) {
            return Promise.resolve();
        }

        try {
            const version = await this.getApplicationVersionFromServiceInternal('Engineering', apiOptions);
            this.serviceApplicationVersion = version;
            this.serviceApplicationVersionLoaded = true;
        }
        catch (error) {
            console.error(error);
        }
    }

    public async getCommonModuleVersionFromService(apiOptions?: ApiOptions): Promise<string> {
        try {
            const version = await this.getApplicationVersionFromServiceInternal('CommonModule', apiOptions);
            return version;
        }
        catch (error) {
            console.error(error);
        }

        return undefined;
    }


    public async setApplicationVersion(forceUpdate = false, apiOptions?: ApiOptions) {
        await this.getApplicationVersionFromService(forceUpdate, apiOptions);
        this.setApplicationVersionInternal(this.serviceApplicationVersion);
        this.updatePageTitle();
    }


    private async getApplicationVersionFromServiceInternal(application: string, apiOptions?: ApiOptions) {
        if (this.offlineService.isOffline) {
            // For offline there is no product information service to be contacted ...
            return undefined;
        }

        const httpRequest = new HttpRequest('GET', `${environment.productInformationServiceUrl}ApplicationVersions/${application}`);

        const result = await this.apiService.request<ApplicationVersion>(httpRequest, apiOptions);
        const version = (result?.body?.Version ?? '').trim();

        if (version != '') {
            return version;
        }

        return undefined;
    }

    private setApplicationVersionInternal(applicationVersion: string) {
        if (applicationVersion) {
            environment.applicationVersion = applicationVersion;
        }
        else {
            environment.applicationVersion = this.environmentApplicationVersion;
        }

        this.serviceApplicationVersionLoaded = true;
    }

    private updatePageTitle() {
        let title = environment.solutionName;

        if (this.serviceApplicationVersionLoaded) {
            title = `${title} - ${environment.applicationVersion}`;
        }

        this.titleService.setTitle(title);
    }
}
