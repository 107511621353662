<div class="modal-header">
    <h3 class="modal-title" l10n="Agito.Hilti.Profis3.Main.Support"></h3>

    <button id="support-close-button" type="button" class="close" (click)="close()">
        <span>&times;</span>
    </button>
</div>

<form class="form" name="form" #ngForm="ngForm" (ngSubmit)="save(ngForm)" novalidate>
    <div class="modal-body">
        <p class="no-internet" l10n="Agito.Hilti.Profis3.Support.RequiresInternetConnection"
            *ngIf="requiresInternetConnection"></p>

        <div class="notification-faq" *ngIf="!hideFAQ">
            <p class="text-label faq-info-header" l10n="Agito.Hilti.Profis3.Support.FAQ.Title"></p>
            <p class="text-label faq-info">{{faqLinkMessage}}</p>
            <span class="link-wrapper">
                <a class="url link" href="{{faqLinkUrl}}" target="{{offlineService.isOffline ? '_self' : '_blank'}}">{{faqLinkText}}</a>
            </span>
        </div>

        <div class="section">
            <div class="section-row">
                <div class="section-column support-column">
                    <div class="notification-support" *ngIf="isSupportDataAvailable">
                        <p class="text-label support-info-header" l10n="Agito.Hilti.Profis3.Support.Info.Header"></p>

                        <div class="support-user-manual">
                            <label l10n="Agito.Hilti.Profis3.Support.Info.UserManual.Text"></label>
                            <div class="link-wrapper">
                                <a class="url link" (click)="openUserManual()">
                                    <span class="text" l10n="Agito.Hilti.Profis3.Support.Info.UserManual"></span>
                                </a>
                            </div>
                        </div>

                        <p class="text-label" *ngIf="supportOpeningHours">
                            {{formatSupportOpeningHours(supportOpeningHours)}}</p>

                        <p class="text-label" *ngIf="supportEmail">
                            {{formatSupportEmail(supportEmail)}}</p>

                        <p class="text-label last" *ngIf="supportPhone">
                            {{formatSupportPhone(supportPhone)}}</p>
                    </div>

                    <div class="additional-data-support">
                        <p class="text-label support-additional-data-header" l10n="Agito.Hilti.Profis3.Support.AdditionalData.Header"></p>

                        <p class="text-label" *ngIf="applicationVersion">
                            {{formatSupportText('Agito.Hilti.Profis3.Support.AdditionalData.ApplicationVersion', applicationVersion)}}</p>

                        <p class="text-label" *ngIf="operatingSystem">
                            {{formatSupportText('Agito.Hilti.Profis3.Support.AdditionalData.OperatingSystem', operatingSystem)}}</p>

                        <p class="text-label" *ngIf="browserVersion">
                            {{formatSupportText('Agito.Hilti.Profis3.Support.AdditionalData.BrowserVersion', browserVersion)}}</p>

                        <p class="text-label" *ngIf="formatedDateTime">
                            {{formatSupportText('Agito.Hilti.Profis3.Support.AdditionalData.DateTime', formatedDateTime)}}</p>

                        <p class="text-label" *ngIf="userName">
                            {{formatSupportText('Agito.Hilti.Profis3.Support.AdditionalData.UserName', userName)}}</p>

                        <p class="text-label" *ngIf="designName">
                            {{formatSupportText('Agito.Hilti.Profis3.Support.AdditionalData.DesignFile', designName)}}</p>

                        <p class="text-label" *ngIf="country">
                            {{formatSupportText('Agito.Hilti.Profis3.Support.AdditionalData.Region', country)}}</p>

                        <p class="text-label" *ngIf="correlationId">
                            {{formatSupportText('Agito.Hilti.Profis3.Support.AdditionalData.CorrelationId', correlationId)}}</p>

                        <p class="text-label" *ngIf="licenseType">
                            {{formatSupportText('Agito.Hilti.Profis3.Support.AdditionalData.LicenseType', translatedLicenseType)}}</p>
                    </div>

                    <pe-text-box
                        [id]="subjectTextBox.id"
                        [title]="subjectTextBox.title"
                        [value]="subjectTextBox.value"
                        (valueChange)="subjectTextBox.value = $any($event).detail"
                        [validators]="subjectTextBox.validators"
                        (enterPressed)="save(ngForm)"
                        (isValid)="subjectTextBox.isValid = $any($event).detail"
                        [disabled]="submitted">
                    </pe-text-box>

                    <pe-control-title
                        [title]="'Agito.Hilti.Profis3.Support.Message' | l10n:localization.selectedLanguage">
                    </pe-control-title>

                    <textarea id="support-message" class="support-message input" name="message"
                        [(ngModel)]="message" [disabled]="submitted" required></textarea>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button id="support-cancel-button" type="button" class="modal-button button button-default"
            l10n="Agito.Hilti.Profis3.Support.Cancel" (click)="close()" [disabled]="submitted">
        </button>

        <button id="support-save-button" type="submit" class="modal-button button button-CTA"
            [disabled]="!formValid || (ngForm.enabled && !ngForm.valid) || submitted || requiresInternetConnection">
            <pe-loading-button
                [loading]="submitted"
                [text]="'Agito.Hilti.Profis3.Support.Send' | l10n:localization.selectedLanguage">
            </pe-loading-button>
        </button>
    </div>
</form>
