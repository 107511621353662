import getPkce from 'oauth-pkce';

import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    IAuthentication, ISubscriptionInfo
} from '@profis-engineering/pe-ui-common/services/user.common';

import {
    AuthenticationServiceBase
} from '@profis-engineering/pe-ui-common/services/authentication.common';

import { environment } from '../../environments/environment';
import { StateGenerator } from '../helpers/state-generator';
import { storageKey, urlPath } from '../module-constants';
import { ApiService } from './api.service';
import { LocalizationService } from './localization.service';
import { ILogonResult, OfflineService } from './offline.service';
import { RoutingService } from './routing.service';
import { SessionStorageService } from './session-storage.service';
import { UserService } from './user.service';

export interface IAuthObject {
    authorization_code: string;
}

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService extends AuthenticationServiceBase {
    private navigationInProgress: boolean;

    constructor(
        private routingService: RoutingService,
        private userService: UserService,
        private sessionStorageService: SessionStorageService,
        private apiService: ApiService,
        private localizationService: LocalizationService,
        private offlineService: OfflineService
    ) {
        super();
     }

    public async login(returnUrl: string = null) {
        if (!this.navigationInProgress) {
            this.userService.invalidateAuthentication();

            // we check the login state when we get back from the login page
            const loginState = StateGenerator.generateState();
            this.sessionStorageService.set(storageKey.loginState, loginState);

            // save the current url for when we get back from the login page
            this.sessionStorageService.set(storageKey.loginReturnUrl, returnUrl);

            if (environment.authentication == 'local') {
                // no login page for local auth
                window.location.href = this.routingService.getUrl(urlPath.authenticationCallback, { code: 'local', state: loginState });
            }
            else {
                const loginUrl = new URL(environment.externalAuthenticationUrl + environment.externalAuthorize);
                loginUrl.searchParams.append('client_id', environment.externalClientId);
                loginUrl.searchParams.append('redirect_uri', this.offlineService.buildRedirectUri(urlPath.authenticationCallback));
                loginUrl.searchParams.append('response_type', 'code');
                loginUrl.searchParams.append('scope', 'HC.Request.AllScopes');
                loginUrl.searchParams.append('state', loginState);

                let language: string = null;
                let countryCode: string = null;
                if (this.offlineService.isOffline) {
                    const setupDataReg = await this.offlineService.getSetupLanguageFromRegistry();
                    if (setupDataReg != null) {
                        const setupData = this.offlineService.parseSetupLanguage(setupDataReg);
                        language = this.getLanguage(setupData.language);
                    }
                    else {
                        language = environment.defaultLanguage;
                    }
                }
                else {
                    const currentUrl = this.routingService.currentUrl;
                    language = this.getLanguage(navigator.language);
                    countryCode = this.getCountryCode(currentUrl);
                }

                loginUrl.searchParams.append('lang', language);
                if (countryCode) {
                    loginUrl.searchParams.append('country', countryCode.toUpperCase());
                }

                if (environment.authentication == 'oauth2') {
                    const { verifier, challenge } = await this.getPkceAsync();
                    this.sessionStorageService.set('code_verifier', verifier);
                    loginUrl.searchParams.append('code_challenge', challenge);
                    loginUrl.searchParams.append('code_challenge_method', 'S256');
                }

                this.showLogin(loginUrl);
            }

            // ignore multiple calls to login
            this.navigationInProgress = true;
        }

        // return a promise that is never resolved or rejected since we are waiting for page navigation
        return new Promise<never>(() => { return; });
    }

    public async logout() {
        if (!this.navigationInProgress) {
            // local is used for development so no login is required
            if (environment.authentication == 'local') {
                // no login page for local auth
                window.location.href = this.routingService.getUrl(urlPath.logout, { accToken: 'invalidate' });
            }
            else {
                // call HC logout
                const accessToken = this.userService.authentication.accessToken;

                let language = (this.localizationService.selectedLanguage != undefined && this.localizationService.selectedLanguage != '')
                    ? this.localizationService.selectedLanguage
                    : environment.defaultLanguage;

                if (environment.externalLanguage) {
                    language = environment.externalLanguage;
                }

                const form = document.createElement('form');

                form.method = 'POST';
                form.action = `${environment.externalAuthenticationUrl}${environment.externalLogout}`;

                const clientIdInput = document.createElement('input');
                clientIdInput.value = environment.externalClientId;
                clientIdInput.name = 'client_id';
                form.appendChild(clientIdInput);

                const langInput = document.createElement('input');
                langInput.value = language;
                langInput.name = 'lang';
                form.appendChild(langInput);

                const accessTokenInput = document.createElement('input');
                accessTokenInput.value = accessToken;
                accessTokenInput.name = 'access_token';
                form.appendChild(accessTokenInput);

                if (environment.authentication == 'oauth1') {
                    const redirectUriInput = document.createElement('input');
                    redirectUriInput.value = this.offlineService.buildRedirectUri(urlPath.logout);
                    redirectUriInput.name = 'redirect_uri';
                    form.appendChild(redirectUriInput);
                }

                if (environment.authentication == 'oauth2') {
                    const logoutUriInput = document.createElement('input');
                    logoutUriInput.value = this.offlineService.buildRedirectUri(urlPath.logout);
                    logoutUriInput.name = 'logout_uri';
                    form.appendChild(logoutUriInput);
                }

                form.style.display = 'none';
                document.body.appendChild(form);

                form.submit();
            }

            // ignore multiple calls to logout
            this.navigationInProgress = true;
        }

        // return a promise that is never resolved or rejected since we are waiting for page navigation
        return new Promise<never>(() => { return; });
    }

    public async tryExtendToken() {
        if (
            environment.authentication == 'local'
            || !environment.externalExtendTokenUrl
            || this.offlineService.isOffline
            || !this.userService.authentication
        ) {
            return false;
        }

        const token = await this.getExtendToken();
        if (token == null) {
            return false;
        }

        const response = await this.offlineService.getToken(token.authorization_code);
        await this.ensureLicense(response);
        const isAuthenticated = this.trySetAuthenticated(response);

        return isAuthenticated;
    }

    public async ensureLicense(logonResult: ILogonResult) {
        if (this.offlineService.isOffline) {
            // For desktop we get empty license, so here we put some dummy data. Otherwise we would have to adapt the code to handle empty license.
            logonResult.subscription_info.AuthorizationEntryList[0].Licenses = this.generateDesktopLicenses();
            return logonResult;
        }

        if (environment.authentication != 'local' && environment.enableLicenseDecoupling) {
            const req = new HttpRequest('GET', environment.externalLicenseUrl, {
                headers: new HttpHeaders({
                    "Authorization": `Bearer ${logonResult.access_token}`
                })
            });

            try {
                // Supress error message because we do not want to notify used. Read commend in catch for more info.
                const res = await this.apiService.request<any>(req, { supressErrorMessage: true });
                logonResult.subscription_info.AuthorizationEntryList[0].Licenses = btoa(JSON.stringify(res.body));
            }
            catch (err) {
                if (typeof err == 'object' && 'error' in err) {
                    // License manager v1 returned error in logonResult.subscription_info.AuthorizationEntryList[0].Licenses, so we save error here to have the same behaviour.
                    // HC-License header in backend will also be filled with same error by HC gateway, this is expected.
                    logonResult.subscription_info.AuthorizationEntryList[0].Licenses = btoa(JSON.stringify(err.error));
                }
            }
        }

        return logonResult;
    }

    private async getExtendToken() {
        const language = this.getLanguage(navigator.language);

        const loginUrl = new URL(environment.externalExtendTokenUrl);
        loginUrl.searchParams.append('client_id', environment.externalClientId);
        loginUrl.searchParams.append('redirect_uri', this.offlineService.buildRedirectUri(urlPath.authenticationCallback));
        loginUrl.searchParams.append('response_type', 'code');
        loginUrl.searchParams.append('scope', 'HC.Request.AllScopes');
        loginUrl.searchParams.append('state', this.sessionStorageService.get(storageKey.loginState));
        loginUrl.searchParams.append('lang', language);
        loginUrl.searchParams.append('customer_id', this.userService.authentication.customerId);
        loginUrl.searchParams.append('contact_id', this.userService.authentication.contactId);
        loginUrl.searchParams.append('prompt', 'none');

        if (environment.authentication == 'oauth2') {
            const { verifier, challenge } = await this.getPkceAsync();
            this.sessionStorageService.set('code_verifier', verifier);
            loginUrl.searchParams.append('code_challenge', challenge);
            loginUrl.searchParams.append('code_challenge_method', 'S256');
        }

        if (environment.includeHCHeaders) {
            loginUrl.searchParams.append('access_token', this.userService.authentication.accessToken);
        }

        const request = new HttpRequest('GET', loginUrl.toString(), null);

        const response = await this.apiService.request<IAuthObject>(request);
        if (response.status == 401) {
            return null;
        }

        const resultData: IAuthObject = {
            authorization_code: response.body.authorization_code
        };
        return resultData;
    }

    private trySetAuthenticated(authenticatinoData: ILogonResult) {
        const accessToken = authenticatinoData.access_token;
        const subscriptionInfo = authenticatinoData.subscription_info;

        return this.loginOnline(accessToken, subscriptionInfo);
    }

    private loginOnline(
        accessToken: string,
        subscriptionInfo: ISubscriptionInfo
    ) {

        const userId: string = subscriptionInfo.UID;
        const userName: string = subscriptionInfo.LogonID;
        const authorizationEntryList = subscriptionInfo.AuthorizationEntryList[0];
        const license: string = authorizationEntryList.Licenses;
        const customerId: string = authorizationEntryList.CustomerID;
        const customerOriginId: string = authorizationEntryList.CustomerOriginID;
        const contactId: string = authorizationEntryList.ContactIDs;

        let result = false;
        if (accessToken) {
            const authenticationData: IAuthentication = {
                accessToken,
                license,
                userId,
                userName,
                externalUserId: null,   // don't need this for online, just for offline
                externalUserName: null, // don't need this for online, just for offline
                customerId,
                customerOriginId,
                contactId,
                country: subscriptionInfo.Country,
                subscription_info: subscriptionInfo,
                countryOfResidence: subscriptionInfo.CountryOfResidence
            };

            this.userService.setAuthenticated(authenticationData, this.offlineService.isOffline);

            result = true;
        }

        return result;
    }

    private async getPkceAsync() {
        return new Promise<{ verifier: string, challenge: string }>((resolve) => {
            getPkce(43, (error, { verifier, challenge }) => {
                if (error) {
                    console.error(error.message);
                    throw error;
                }
                resolve({ verifier, challenge });
            });
        });
    }

    private showLogin(loginUrl: URL) {
        if (this.offlineService.isOffline) {
            window.desktop.showLogin(loginUrl.toString())
                .then(callbackParams => {
                    this.routingService.navigateToUrl(urlPath.authenticationCallback + callbackParams);
                });
        }
        else {
            window.location.href = loginUrl.toString();
        }
    }

    private getLanguage(locale: string) {
        if (environment.externalLanguage) {
            return environment.externalLanguage;
        }

        if (
            locale == null
            || locale == ''
            || /[\d_]/g.test(locale)
            || (locale.match(/-/g) || []).length > 1
        ) {
            return environment.defaultLanguage;
        }

        return locale;
    }

    private getCountryCode(currentUrl: URL) {
        // Country can be set in environment
        if (environment.externalCountry) {
            return environment.externalCountry;
        }

        // Country can be forced by adding it to the url https://profisengineering.hilti.com/?country=en
        // this is used by Hilti when they link to PE from other sites
        if (currentUrl.searchParams.has('country')) {
            return currentUrl.searchParams.get('country');
        }

        // Do not set country
        return null;
    }

    private generateDesktopLicenses() {
        const obj = {
            application_name: 'P3 Baseplate Engineering Desktop D1',
            valid_until: '2099-01-12T11:11:11Z',
            licenses: [
                {
                    name: 'P3 Eng Web  - Basic / User',
                    key: 'DL0ZA7642C',
                    valid_until: '2019-04-30T00:00:00Z',
                    extension: false
                }
            ],
            features: [
                {
                    name: 'Desktop',
                    key: 'DESKTOP'
                }
            ]
        };

        return btoa(JSON.stringify(obj));
    }
}
