<pe-modal-header
    [modalInstance]="modalInstance"
    [title]="translate('Agito.Hilti.Profis3.ExportReports.Header')">
</pe-modal-header>

<pe-modal-body>

    <h2 l10n="Agito.Hilti.Profis3.ExportReports.Title"></h2>

    <ng-container *ngFor="let displayDesign of displayDesigns" >
        <pe-export-report-row *ngIf="!isC2C(displayDesign);else ExportReportC2C"
            [displayDesign]="displayDesign"
            [cancelExport]="cancelObservable"
            (exportReportCompleated)="results.push($any($event).detail)"
            (deleteExport)="deleteExport($any($event).detail)">
        </pe-export-report-row>
        <ng-template #ExportReportC2C>
            <c2c-export-report-row
                [displayDesign]="displayDesign"
                [cancelExport]="cancelObservable"
                (exportReportCompleated)="results.push($any($event).detail)"
                (deleteExport)="deleteExport($any($event).detail)">
            </c2c-export-report-row>
        </ng-template>
    </ng-container>

</pe-modal-body>

<pe-modal-footer>
    <pe-button *ngIf="pendingExport;else CloseModalButton"
        [id]="'export-reports-cancel-button'"
        [look]="'Default'"
        class="modal-button"
        (buttonClick)="cancel()"
        [text]="translate('Agito.Hilti.Profis3.Cancel')">
    </pe-button>
    <ng-template #CloseModalButton>
        <pe-button
            [id]="'export-reports-close-button'"
            [look]="'Default'"
            class="modal-button"
            (buttonClick)="close()"
            [text]="translate('Agito.Hilti.Profis3.Close')">
        </pe-button>
    </ng-template>

    <pe-button
        [id]="'export-reports-export-button'"
        [look]="'Cta'"
        class="modal-button"
        (buttonClick)="exportZip()"
        [disabled]="pendingExport || !exportPossible">
        <pe-loading-button
            [loading]="pendingExport"
            [text]="translate('Agito.Hilti.Profis3.Export')">
        </pe-loading-button>
    </pe-button>
</pe-modal-footer>
