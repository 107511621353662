import * as React from 'react';

const color1 = '#fff';
const color2 = '#fafafa';
const color3 = '#d7cebe';
const color4 = '#524f53';
const color5 = '#d2051e';

export interface ILoadingProps {
    width?: number;
    height?: number;
    duration?: number;
    color?: string;
}

export class Loading extends React.PureComponent<ILoadingProps> {
    private id: string;

    constructor(props?: ILoadingProps) {
        super(props);

        this.id = `ld-${Math.random().toString().replace('.', '')}`;
    }

    public override render() {
        return (
            <div data-control-id={this.id} className='loading'>
                <div className='bar'>
                    <div className='fill fill-1'></div>
                    <div className='space'></div>
                    <div className='fill fill-2'></div>
                    <div className='space'></div>
                    <div className='fill fill-3'></div>
                    <div className='space'></div>
                    <div className='fill fill-4'></div>
                    <div className='space'></div>
                    <div className='fill fill-5'></div>
                </div>

                <style>{this.generateCss(this.id, this.props.width, this.props.height, this.props.duration, this.props.color)}</style>
            </div>
        );
    }

    private generateCss(id: string, width: number = 32, height: number = 8, duration: number = 1, color: string = null) {
        if (color == '') {
            color = null;
        }

        return `
                [data-control-id="${id}"] .bar {
                    width: ${width}px;
                    height: ${height}px;
                }

                ${this.generateFillCss(id, 1, duration, color ?? color1, color ?? color2)}
                ${this.generateFillCss(id, 2, duration, color ?? color2)}
                ${this.generateFillCss(id, 3, duration, color ?? color3)}
                ${this.generateFillCss(id, 4, duration, color ?? color4)}
                ${this.generateFillCss(id, 5, duration, color ?? color5)}
            `;
    }

    private generateFillCss(id: string, index: number, duration: number, color: string, borderColorOverride?: string) {
        const delay = duration / 10;

        return `
                [data-control-id="${id}"] .bar .fill-${index} {
                    animation: fade ${duration}s ${(index - 1) * delay}s infinite linear;
                    background-color: ${color};
                    border: 1px solid ${borderColorOverride ? borderColorOverride : color};
                }
            `;
    }
}
