<div class="content-group" *ngIf="dataLoaded">
    <div class="content">
        <p class="title-strike" l10n="Agito.Hilti.Profis3.ApplicationSettings.InterfacesToStructuralCalculationSoftware"></p>

        <pe-checkbox-button
            [items]="structuralCalculationSoftwares.items"
            [selectedValues]="structuralCalculationSoftwares.selectedValues"
            (selectedValuesChange)="onCheckboxChange($any($event).detail)"
            [disabled]="submitted">
        </pe-checkbox-button>
    </div>

    <div *ngIf="complexDesignsAvailable" class="content">
        <p class="title-strike">
            Checkbot properties
        </p>

        <div class="content-controls calculation-method">
            <pe-radio-button
                class="control"
                [id]="checkbotLvl2.id"
                [title]="checkbotLvl2.title"
                [items]="checkbotLvl2.items"
                [selectedValue]="checkbotLvl2.selectedValue"
                (selectedValueChange)="this.structuralInterfacesValues.checkbotLvl2Enabled = $any($event).detail"
                [disabled]="submitted">
            </pe-radio-button>
        </div>
    </div>
</div>
