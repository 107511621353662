import { Component } from "@angular/core";
import { ModalInstance } from "@profis-engineering/pe-ui-common/helpers/modal-helper";

@Component({
  selector: 'app-version-popup',
  templateUrl: './version-popup.component.html',
  styleUrls: ['./version-popup.component.scss']
})
export class VersionPopupComponent {
    public title = 'PE Suite Version Details';


    constructor(
        private modalInstance: ModalInstance,
    ) { }

    public close() {
        this.modalInstance.close();
    }
}
