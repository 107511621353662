import React from 'react';
import * as jsx from 'react/jsx-runtime';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { ControlHeader } from './app/components/main-menu/ControlHeader';
import { Button } from './app/components/main-menu/Controls/Button/Button';
import { Dropdown } from './app/components/main-menu/Controls/Dropdown/Dropdown';
import { MainMenu } from './app/components/main-menu/MainMenu';
import { TextBox } from './app/components/main-menu/Controls/TextBox/TextBox';
import { Label } from './app/components/main-menu/Controls/Label/Label';
import { ButtonGroup } from './app/components/main-menu/Controls/ButtonGroup/ButtonGroup';
import { Checkbox } from './app/components/main-menu/Controls/Checkbox/Checkbox';
import { CheckboxGroup } from './app/components/main-menu/Controls/CheckboxGroup/CheckboxGroup';
import { DlubalImportExport } from './app/components/main-menu/Controls/DlubalImportExport/DlubalImportExport';
import { ETABSImportExport } from './app/components/main-menu/Controls/ETABSImportExport/ETABSImportExport';
import { Group } from './app/components/main-menu/Controls/Group/Group';
import { ImageNameCheckbox } from './app/components/main-menu/Controls/ImageNameCheckbox/ImageNameCheckbox';
import { ImageNameCheckboxGroup } from './app/components/main-menu/Controls/ImageNameCheckboxGroup/ImageNameCheckboxGroup';
import { ImageNameRadio } from './app/components/main-menu/Controls/ImageNameRadio/ImageNameRadio';
import { ImageNameRadioGroup } from './app/components/main-menu/Controls/ImageNameRadioGroup/ImageNameRadioGroup';
import { ImageNameSelection } from './app/components/main-menu/Controls/ImageNameSelection/ImageNameSelection';
import { ImageNameSelectionGroup } from './app/components/main-menu/Controls/ImageNameSelectionGroup/ImageNameSelectionGroup';
import { Loading } from './app/components/main-menu/Controls/Loading/Loading';
import { Popup } from './app/components/main-menu/Controls/Popup/Popup';
import { PopupGrid } from './app/components/main-menu/Controls/PopupGrid/PopupGrid';
import { PopupGridPartial } from './app/components/main-menu/Controls/PopupGridPartial/PopupGridPartial';
import { RadioButton } from './app/components/main-menu/Controls/RadioButton/RadioButton';
import { RadioButtonGroup } from './app/components/main-menu/Controls/RadioButtonGroup/RadioButtonGroup';
import { RangeSlider } from './app/components/main-menu/Controls/RangeSlider/RangeSlider';
import { RobotImportExport } from './app/components/main-menu/Controls/RobotImportExport/RobotImportExport';
import { Rotate } from './app/components/main-menu/Controls/Rotate/Rotate';
import { SAP2000ImportExport } from './app/components/main-menu/Controls/SAP2000ImportExport/SAP2000ImportExport';
import { StaadProImportExport } from './app/components/main-menu/Controls/StaadProImportExport/StaadProImportExport';
import { SwitchWithDescription } from './app/components/main-menu/Controls/SwitchWithDescription/SwitchWithDescription';
import { TabGroup } from './app/components/main-menu/Controls/TabGroup/TabGroup';
import { ToggleButton } from './app/components/main-menu/Controls/ToggleButton/ToggleButton';
import { ToggleButtonGroup } from './app/components/main-menu/Controls/ToggleButtonGroup/ToggleButtonGroup';
import { ToggleImageButton } from './app/components/main-menu/Controls/ToggleImageButton/ToggleImageButton';

// register service worker - no await
if (environment.serviceWorkerEnabled) {
    (async function registerServiceWorker(): Promise<void> {
        // service worker might not be available/disabled
        if (!("serviceWorker" in navigator)) {
            return;
        }

        try {
            await navigator.serviceWorker.register("/sw.js");
        }
        catch (error) {
            console.error('Service worker registration failed', error);
        }
    })();
}

declare global {
    interface Window {
        React: typeof React;
        jsx: typeof jsx;
        pe: WindowPe;
    }
}

interface WindowPe {
    reactComponents: ReactComponents;
}

interface ReactComponents {
    MainMenu: typeof MainMenu;
    ControlHeader: typeof ControlHeader;

    Button: typeof Button;
    ButtonGroup: typeof ButtonGroup;
    Checkbox: typeof Checkbox;
    CheckboxGroup: typeof CheckboxGroup;
    DlubalImportExport: typeof DlubalImportExport;
    Dropdown: typeof Dropdown;
    ETABSImportExport: typeof ETABSImportExport;
    Group: typeof Group;
    ImageNameCheckbox: typeof ImageNameCheckbox;
    ImageNameCheckboxGroup: typeof ImageNameCheckboxGroup;
    ImageNameRadio: typeof ImageNameRadio;
    ImageNameRadioGroup: typeof ImageNameRadioGroup;
    ImageNameSelection: typeof ImageNameSelection;
    ImageNameSelectionGroup: typeof ImageNameSelectionGroup;
    Label: typeof Label;
    Loading: typeof Loading;
    Popup: typeof Popup;
    PopupGrid: typeof PopupGrid;
    PopupGridPartial: typeof PopupGridPartial;
    RadioButton: typeof RadioButton;
    RadioButtonGroup: typeof RadioButtonGroup;
    RangeSlider: typeof RangeSlider;
    RobotImportExport: typeof RobotImportExport;
    Rotate: typeof Rotate;
    SAP2000ImportExport: typeof SAP2000ImportExport;
    StaadProImportExport: typeof StaadProImportExport;
    SwitchWithDescription: typeof SwitchWithDescription;
    TabGroup: typeof TabGroup;
    TextBox: typeof TextBox;
    ToggleButton: typeof ToggleButton;
    ToggleButtonGroup: typeof ToggleButtonGroup;
    ToggleImageButton: typeof ToggleImageButton;
}

// Set React and JSX as global variables for so UI modules can reuse them
window.React = React;
window.jsx = jsx;

window.pe = window.pe ?? {} as WindowPe;
const reactComponents = window.pe.reactComponents = window.pe.reactComponents ?? {} as ReactComponents;

reactComponents.MainMenu = MainMenu;
reactComponents.ControlHeader = ControlHeader;

reactComponents.Button = Button;
reactComponents.ButtonGroup = ButtonGroup;
reactComponents.Checkbox = Checkbox;
reactComponents.CheckboxGroup = CheckboxGroup;
reactComponents.DlubalImportExport = DlubalImportExport;
reactComponents.Dropdown = Dropdown;
reactComponents.ETABSImportExport = ETABSImportExport;
reactComponents.Group = Group;
reactComponents.ImageNameCheckbox = ImageNameCheckbox;
reactComponents.ImageNameCheckboxGroup = ImageNameCheckboxGroup;
reactComponents.ImageNameRadio = ImageNameRadio;
reactComponents.ImageNameRadioGroup = ImageNameRadioGroup;
reactComponents.ImageNameSelection = ImageNameSelection;
reactComponents.ImageNameSelectionGroup = ImageNameSelectionGroup;
reactComponents.Label = Label;
reactComponents.Loading = Loading;
reactComponents.Popup = Popup;
reactComponents.PopupGrid = PopupGrid;
reactComponents.PopupGridPartial = PopupGridPartial;
reactComponents.RadioButton = RadioButton;
reactComponents.RadioButtonGroup = RadioButtonGroup;
reactComponents.RangeSlider = RangeSlider;
reactComponents.RobotImportExport = RobotImportExport;
reactComponents.Rotate = Rotate;
reactComponents.SAP2000ImportExport = SAP2000ImportExport;
reactComponents.StaadProImportExport = StaadProImportExport;
reactComponents.SwitchWithDescription = SwitchWithDescription;
reactComponents.TabGroup = TabGroup;
reactComponents.TextBox = TextBox;
reactComponents.ToggleButton = ToggleButton;
reactComponents.ToggleButtonGroup = ToggleButtonGroup;
reactComponents.ToggleImageButton = ToggleImageButton;

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
