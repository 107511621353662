import { Injectable } from '@angular/core';
import { CodeList } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { PeInitialDataCodelistModel } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Web.Models';
import { Language } from '@profis-engineering/pe-ui-common/entities/code-lists/language';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { ReportPaperSize } from '@profis-engineering/pe-ui-common/entities/code-lists/report-paper-size';
import { ReportType } from '@profis-engineering/pe-ui-common/entities/code-lists/report-type';
import { Separator } from '@profis-engineering/pe-ui-common/entities/code-lists/separator';
import { Unit } from '@profis-engineering/pe-ui-common/entities/code-lists/unit';
import {
    CommonCodeList, CommonCodeListServiceBase
} from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { WorldArea } from '@profis-engineering/pe-ui-common/entities/code-lists/world-area';
import { RegionLanguage } from '@profis-engineering/pe-ui-common/entities/code-lists/region-language';
import { StructuralCalculationSoftware } from '@profis-engineering/pe-ui-common/entities/code-lists/structural-calculation-software';
import { LicenseComparisonPackage } from '@profis-engineering/pe-ui-common/entities/code-lists/license-comparison-package';
import { TrialBannerFeature } from '@profis-engineering/pe-ui-common/entities/code-lists/trial-banner-feature';
import { LicenseSuite } from '@profis-engineering/pe-ui-common/entities/code-lists/license-suite';
import { BimCadLibLanguage } from '@profis-engineering/pe-ui-common/entities/code-lists/bim-cad-lib-language';

@Injectable({
    providedIn: 'root'
})
export class CommonCodeListService extends CommonCodeListServiceBase {
    public commonCodeLists: Record<CommonCodeList, CodeList[]>;

    public initialize(data: PeInitialDataCodelistModel) {
        this.commonCodeLists = {
            [CommonCodeList.Region]: data.Regions.map((codeList) => CommonRegion.fromService(codeList)),
            [CommonCodeList.WorldArea]: data.WorldAreas.map((codeList) => WorldArea.fromService(codeList)),
            [CommonCodeList.RegionLanguage]: data.RegionLanguages.map((codeList) => RegionLanguage.fromService(codeList)),
            [CommonCodeList.ReportPaperSize]: data.ReportPaperSizes.map((codeList) => ReportPaperSize.fromService(codeList)),
            [CommonCodeList.ReportType]: data.ReportTypes.map((codeList) => ReportType.fromService(codeList)),
            [CommonCodeList.Language]: data.Languages.map((codeList) => Language.fromService(codeList)),

            // Separators
            [CommonCodeList.DecimalSeparator]: data.DecimalSeparators.map((codeList) => Separator.fromService(codeList)),
            [CommonCodeList.ThousandsSeparator]: data.ThousandsSeparators.map((codeList) => Separator.fromService(codeList)),

            // Units
            [CommonCodeList.UnitLength]: data.LengthUnits.map((codeList) => Unit.fromService(codeList)),
            [CommonCodeList.UnitLengthLarge]: data.LengthLargeUnits.map((codeList) => Unit.fromService(codeList)),
            [CommonCodeList.UnitArea]: data.AreaUnits.map((codeList) => Unit.fromService(codeList)),
            [CommonCodeList.UnitStress]: data.StressUnits.map((codeList) => Unit.fromService(codeList)),
            [CommonCodeList.UnitStressSmall]: data.StressSmallUnits.map((codeList) => Unit.fromService(codeList)),
            [CommonCodeList.UnitForce]: data.ForceUnits.map((codeList) => Unit.fromService(codeList)),
            [CommonCodeList.UnitMoment]: data.MomentUnits.map((codeList) => Unit.fromService(codeList)),
            [CommonCodeList.UnitTemperature]: data.TemperatureUnits.map((codeList) => Unit.fromService(codeList)),
            [CommonCodeList.UnitForcePerLength]: data.ForcePerLengthUnits.map((codeList) => Unit.fromService(codeList)),
            [CommonCodeList.UnitTime]: data.TimeUnits.map((codeList) => Unit.fromService(codeList)),
            [CommonCodeList.UnitMomentPerLength]: data.MomentPerLengthUnits.map((codeList) => Unit.fromService(codeList)),
            [CommonCodeList.UnitDensity]: data.DensityUnits.map((codeList) => Unit.fromService(codeList)),
            [CommonCodeList.UnitAreaPerLength]: data.AreaPerLengthUnits.map((codeList) => Unit.fromService(codeList)),
            [CommonCodeList.UnitSpecificWeight]: data.SpecificWeightUnits?.map((codeList) => Unit.fromService(codeList)) ?? [],
            [CommonCodeList.UnitItemsPerArea]: data.ItemsPerAreaUnits?.map((codeList) => Unit.fromService(codeList)) ?? [],
            [CommonCodeList.UnitVolume]: data.VolumeUnits?.map((codeList) => Unit.fromService(codeList)) ?? [],

            [CommonCodeList.StructuralCalculationSoftware]: data.StructuralCalculationSoftwareInterfaces.map((codeList) => StructuralCalculationSoftware.fromService(codeList)),
            [CommonCodeList.LicenseComparisonPackages]: data.LicenseComparisonPackages.map((codeList) => LicenseComparisonPackage.fromService(codeList)),
            [CommonCodeList.TrialBannerFeature]: data.TrialBannerFeatures.map((codeList) => TrialBannerFeature.fromService(codeList)),
            [CommonCodeList.LicenseSuite]: data.LicenseSuites.map((codeList) => LicenseSuite.fromService(codeList)),
            [CommonCodeList.BimCadLibLanguages]: data.BimCadLibLanguages.map((codeList) => BimCadLibLanguage.fromService(codeList)),
        };
    }
}
