import { HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProjectType, ProjectUser } from '@profis-engineering/pe-ui-common/entities/project';
import {
    ArchiveDocumentResponseModel, DocumentGetDocument, DocumentGetResponse
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.Documents';
import {
    ArchiveProjectResponseModel, ProjectExpandedState
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.Projects';
import {
    CantArchiveProjectsBecauseDocumentInUse,
    CantDeleteProjectsBecauseDocumentInUse,
    CantOpenDesignBecauseLockedByOtherUser, CantRestoreProjectsBecauseDocumentInUse, DesignExternalMetaData as DesignExternalMetaDataCommon,
    DocumentAccessMode, DocumentServiceBase, IDesignListItem as IDesignListItemCommon
} from '@profis-engineering/pe-ui-common/services/document.common';
import cloneDeep from 'lodash-es/cloneDeep';
import { environment } from '../../environments/environment';
import { PendingAction } from '../components/home-page/home-page.common';
import { IBaseDesign } from '../entities/design';
import { DocumentUser, IDocumentArchive, SharedDocumentUser } from '../entities/document';
import { Project } from '../entities/project';
import { ApiService } from './api.service';
import { BrowserService } from './browser.service';
import { DocumentWebCommon, IGetDocumentResponse } from './document-web-common.service';
import { IDesignListItem } from './document.service';
import { ErrorHandlerService } from './error-handler.service';
import { GuidService } from './guid.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { ModalService } from './modal.service';
import { ModulesService } from './modules.service';
import { PendingActionService } from './pending-action.service';
import { RoutingService } from './routing.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class DocumentWebV2Service extends DocumentWebCommon implements DocumentServiceBase {
    constructor(
        loggerService: LoggerService,
        modulesService: ModulesService,
        routingService: RoutingService,
        apiService: ApiService,
        browserService: BrowserService,
        modalService: ModalService,
        localizationService: LocalizationService,
        guidService: GuidService,
        userService: UserService,
        errorHandlerService: ErrorHandlerService,
        private readonly pendingActionService: PendingActionService
    ) {
        super(
            'DocumentWebV2Service',
            loggerService,
            modulesService,
            routingService,
            apiService,
            browserService,
            modalService,
            localizationService,
            guidService,
            userService,
            errorHandlerService
        );

        this.findDesignById = this.findDesignById.bind(this);
    }


    public initialize(data: DocumentGetResponse, dataArchive: ArchiveProjectResponseModel[], dataDocumentArchive?: ArchiveDocumentResponseModel[]): void {
        this.initializeCommon(data, dataArchive);
        this.getArchiveDocumentsInternal(dataDocumentArchive);
    }

    public async removeArchivedProject(projectId: string): Promise<void> {
        const pToDeleteCandidate = Object.values(this._projectsArchive).find((item) => item.id == projectId);
        if (pToDeleteCandidate == null) {
            throw new Error('Project with ID does not exist.');
        }

        try {
            await this.removeExistingArchivedProject(projectId);

            // remove from archived project and documentslist
            this._projectsArchive = this._projectsArchive.filter((x) => x.id != projectId);
            this._documentsArchive = this._documentsArchive.filter((x) => x.parentProjectId != projectId);
        }
        catch (ex) {
            // catch exception code that happens when project cannot be deleted because a file in the project is locked, and fetch additional details from the document service to display to the user whitch document is locked and by whom.
            if (ex instanceof CantDeleteProjectsBecauseDocumentInUse) {
                const lockedFiles = await this.readLockedDesignsOfProject(projectId);
                ex.documentsInQuestion = lockedFiles;
            }

            throw ex;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public removeProject(_projectId: string): Promise<void> {
        throw new Error('Method not implemented.');
    }

    /**
     * Convert (permanently) the project to company (shared) project and call external document service.
     * @projectId project
     */
    public async convertProject(projectId: string): Promise<void> {
        let matchedProject = this.findProject(projectId, this.projects);
        await this.convertExistingProject(projectId);
        // Update all the projects with companyproject=true under given project
        if (matchedProject) {
            const designList = cloneDeep(Object.keys(matchedProject.designs));
            matchedProject = this.updateCompanyProject(matchedProject, true);
            //remove designs of converted projects from parent projects
            this.removeSubChildDesigns(projectId, this.projects, designList);
            //remove converted projects from the parent projects
            this.removeSubProject(matchedProject.parentId, this.projects, matchedProject.id);
            matchedProject.parentId = null;
            this._projectsFlat[matchedProject.id].parentId = null;
            // move the converted sub projects to root
            this._projects[matchedProject.id] = matchedProject;
        }

        // flatten project to store back into _projectFlat
        // const flattenProjects = this.flattenProjects({ [matchedProject.id]: matchedProject });

        // for (const projectId in flattenProjects) {
        //     this._projectsFlat[projectId] = flattenProjects[projectId];
        // }
    }

    public async getDocumentThumbnails(documentIds: string[]): Promise<Record<string, string>> {
        const url = `${environment.documentWebServiceUrl}documentcontent/GetMultipleDocumentThumbnail`;

        if (documentIds == null || documentIds.length == 0) {
            return this.documentThumbnails;
        }

        const missingImages = this.getMissingThumbnailList(documentIds);

        if (missingImages.length > 0) {
            const promise = this.apiService.request<unknown>(new HttpRequest('POST', url, missingImages), this.getApiOptions());

            // Assign promise to missing image IDs
            for (const id of missingImages) {
                this.documentThumbnailPromise[id] = promise;
            }

            try {
                const response = await promise;
                const data: any = response.body;

                this.logServiceResponse('getDocumentThumbnails', data);

                // Update documentThumbnails with new images
                for (const item of data) {
                    this.documentThumbnails[item.documentId] = item.thumbnailImageContent || '';
                }
            } finally {
                // Remove promise for each missing image ID
                for (const id of missingImages) {
                    this.documentThumbnailPromise[id] = null;
                }
            }
        }

        return this.documentThumbnails;
    }

    public async restoreProject(projectId: string): Promise<string[]> {
        const pToRestoreCandidate = Object.values(this._projectsArchive).find((item) => item.id == projectId);
        if (pToRestoreCandidate == null) {
            throw new Error('Project with ID does not exist.');
        }
        if (this.projectNameExists(pToRestoreCandidate.name, pToRestoreCandidate.parentId)) {
            this.errorHandlerService.showExistingProjectNameModal();
            return Promise.resolve([]);
        }
        try {
            const x = await this.restoreExistingProject(projectId);

            // Add restored project and it's documents to the project menu
            if (x) {
                const { projects, documents } = x;

                Object.assign(this.internalRawProject.projects, projects);
                Object.assign(this.internalRawProject.documents, documents);

                this.getInternal(this.internalRawProject);
            }
            //Remove project from archived project list
            const projectIdsToRemove = Object.values(x.projects).map((project) => project.id);
            this._projectsArchive = this._projectsArchive.filter((x) => !projectIdsToRemove.includes(x.id));
            //Remove project designs from archived designs list
            const documentsIdsToRemove = Object.values(x.documents).map((document) => document.id);
            this._documentsArchive = this._documentsArchive.filter((x) => !documentsIdsToRemove.includes(x.id));
            return Promise.resolve(projectIdsToRemove);
        }
        catch (ex) {
            // catch exception code that happens when project cannot be deleted because a file in the project is locked, and fetch additional details from the document service to display to the user whitch document is locked and by whom.
            if (ex instanceof CantRestoreProjectsBecauseDocumentInUse) {
                const lockedFiles = await this.readLockedDesignsOfProject(projectId);
                ex.documentsInQuestion = lockedFiles;
            }

            throw ex;
        }
    }

    public async restoreExistingDesign(documentId: string): Promise<void> {
        if (documentId == null) {
            throw new Error('Document id doesnot exist');
        }
        const url = `${environment.documentWebServiceUrl}Document/dearchive`;
        const data = { documentIds: [documentId] };
        try {
            const response = await this.apiService.request<any>(new HttpRequest('PATCH', url, data), this.getApiOptions());
            this.logServiceResponse('restoreExistingDesign', response);

            const body = response.body;

            const documents =
                Object.fromEntries(Object.entries<any>(body).map(([_key, it]): [string, DocumentGetDocument] => [it.documentid, {
                    dateChanged: it.lastchange != null ? new Date(it.lastchange * 1000) : null,
                    dateCreated: it.created != null ? this.getDocumentCreatedDate(it.documentid) : null,
                    id: it.documentid,
                    locked: it.locked,
                    metadata: this.getDesignMetaData(it.metadata),
                    projectId: it.project.projectid,
                    lockedUserName: it.lockedby,
                    name: it.name,
                    type: it.type,
                    owner: it.owner
                }]));

            Object.assign(this.internalRawProject.documents, documents);
            this.getInternal(this.internalRawProject);
            //Remove design from archived project list and document list
            const documentProjId = this._documentsArchive.find((x) => x.id = documentId).parentProjectId;
            if (this._projectsArchive.find((project) => project.id == documentProjId))
                this._projectsArchive.find((project) => project.id == documentProjId).designs--;
            this._documentsArchive = this._documentsArchive.filter((x) => x.id != documentId);
        }
        catch (response) {
            if (response instanceof HttpErrorResponse) {
                if (response.status == 409) {
                    this.errorHandlerService.showExistingDesignNameModal();
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }
            throw response;
        }

    }

    public async removeArchivedDesign(documentIds: string[]): Promise<void> {
        if (documentIds == null)
            throw new Error('Document ID does not exist.');

        // eslint-disable-next-line no-useless-catch
        try {
            await this.removeExistingArchivedDesign(documentIds);

            documentIds.forEach((id) => {
                const projId = this._documentsArchive.find(x => x.id == id)?.parentProjectId;
                if (this._projectsArchive.find((project) => project.id == projId))
                    this._projectsArchive.find((project) => project.id == projId).designs--;
            });
            this._documentsArchive = this._documentsArchive.filter((x) => !documentIds.includes(x.id));
        }
        catch (ex) {
            throw ex;
        }
    }

    /*
     * Saves a new or existing project. Returns promise when the new id of the project is returned.
     */
    public async saveProject(project: Project): Promise<void> {
        if (project.name.startsWith('#$')) {
            this.modalService.openAlertWarning(
                this.localizationService.getString('Agito.Hilti.Profis3.DocumentService.Alerts.IllegalChar.Title'),
                this.localizationService.getString('Agito.Hilti.Profis3.DocumentService.Alerts.IllegalChar.Message')
            );

            throw new Error('invalid-name');
        }
        else {
            if (project.name.length > 250) {
                throw new Error('Max length for project name is 250 characters!');
            }

            if (this._projectsFlat[project.id] != null) {
                // call edit project on document service
                await this.updateExistingProject(project);

                const internalProject = this.findProjectById(project.id);
                internalProject.name = project.name;
                internalProject.changeDate = new Date();
                internalProject.createDate = new Date();
            }
            else {
                // call add new project on document service
                await this.addNewProject(project);

                //update raw data
                this.internalRawProject.projects[project.id] = {
                    id: project.id,
                    name: project.name,
                    dateChanged: new Date(),
                    dateCreated: new Date(),
                    parentId: project.parentId,
                    owner: project.owner,
                    isCompanyProject: project.isCompanyProject,
                    expanded: project.expanded,
                    readOnly: project.readOnly,
                    expandedState: project.expandedState
                };
                this._projectsFlat[project.id] = project;

                if (project.parentId) {
                    const parentItem = this.findProject(project.parentId, this.projects);
                    parentItem.subProjects[project.id] = project;
                    project.isSharedByMe = parentItem.isSharedByMe;
                } else {
                    this.projects[project.id] = project;
                }
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public toggleExpandProject(_project: Project): Promise<void> {
        throw new Error('Method not implemented.');
    }

    /**
     * Remove the project from internal store and call external document service.
     * @projectId project
     */
    public async archiveProject(projectId: string): Promise<void> {
        const pToDeleteCandidate = this.findProjectById(projectId);

        if (pToDeleteCandidate == null) {
            throw new Error('Project with ID does not exist.');
        }
        if (pToDeleteCandidate.projectType != ProjectType.common) {
            throw new Error('Only common projects can be deleted.');
        }

        // delete parent
        try {
            const resp: any = await this.archiveExistingProject(projectId);
            for (const index in resp) {
                const x = resp[index];
                const projId = x.projectid;
                if (!this._projectsArchive.find(x => x.id === projId)) {
                    this._projectsArchive.push({
                        archived: x.archived,
                        created: x.created,
                        designs: x.designs,
                        members: x.members,
                        id: x.projectid,
                        parentId: x.parentprojectid,
                        name: x.name
                    });
                }

                this.getDesignIdsByProjectId(projId).forEach((designId) => {
                    const design = this.findDesignById(designId);
                    if (projId === design.projectId) {
                        this._documentsArchive.unshift({
                            archived: x.archived,
                            created: design.createDate,
                            id: designId,
                            designs: x.designs,
                            members: x.members,
                            name: design.designName,
                            parentProjectId: design.projectId,
                            projectname: x.name
                        });
                    }
                });
                this.deleteProjectFunc(projId);
            }
            // set issharedby me for project and child project to false
            const foundedProject = this.findProject(projectId, this.projects);
            this.setSharedByMeTillChildNode(foundedProject, false);
            this.setSharedByMeTillParentNode(foundedProject, false);
            for (const index in resp) {
                const x = resp[index];
                const projId = x.projectid;

                // remove from project flat list
                delete this._projectsFlat[projId];

                // remove form hierarchical list - in line recursion
                this.deleteProject(projId, this._projects);
            }

        }
        catch (ex) {
            // catch exception code that happens when project cannot be deleted because a file in the project is locked, and fetch additional details from the document service to display to the user whitch document is locked and by whom.
            if (ex instanceof CantArchiveProjectsBecauseDocumentInUse) {
                const lockedFiles = await this.readLockedDesignsOfProject(projectId);
                ex.documentsInQuestion = lockedFiles;

                throw ex;
            }

            throw ex;
        }
    }

    public async catchDesignExclusive(response: unknown, design: IBaseDesign): Promise<never> {
        // handle document locked error
        if (response instanceof HttpErrorResponse && response.status == 423) {
            // check the detail of the conflict
            const d = await this.getDesignBasicDocumentInformation(design.id);
            const ex = new CantOpenDesignBecauseLockedByOtherUser();
            ex.username = d.lockedUser;

            throw ex;
        }

        throw response;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public archiveDesign(_designId: string): Promise<void> {
        throw new Error('Method not implemented.');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public deleteProjectLocal(_projectId: string): void {
        throw new Error('Method not implemented.');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public moveDesign(_documentId: string, _projectId: string): Promise<void> {
        throw new Error('Method not implemented.');
    }

    public async copyDesign(documentId: string, documentName: string, projectId: string): Promise<void> {
        const url = `${environment.documentWebServiceUrl}document/copy`;
        this.logServiceRequest('copyDesign', url);

        const data = {
            documentId,
            documentName,
            projectId
        };

        try {
            const response = await this.apiService.request<IGetDocumentResponse>(new HttpRequest('POST', url, data), this.getApiOptions());

            this.logServiceResponse('copyDesign', response);

            const newDesign = this.toIDesignListItem(response.body);
            newDesign.owner = true;
            this.addUpdateDesignInProject(newDesign);

        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'copy design');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 409 && response.error.content.conflictType == 0) {
                    this.errorHandlerService.showExistingDesignNameModal(response, url, data);
                }
                else if (response.status == 409 && response.error.content.conflictType == 1) {
                    await this.modalService.openMaxDesignsLimitReached();
                }
                else if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url, data);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: data
                    });
                }
            }

            throw response;
        }
    }

    public override findAllDesignsByProject(project: Project): { [id: string]: IDesignListItemCommon } {
        const parentProject = project.parentId ? this.findParentProjectByProjectId(project) : project;
        return this.projects[parentProject.id].designs;
    }

    /**
     * Check is there are already project with the same name
     * @param projectName - Project name
     * @param parentId - Parent project id
     * @param projectId - Project id
     */
    public override projectNameExists(projectName: string, parentId: string, projectId?: string): boolean {
        return Object.values(this._projectsFlat).some(
            (p) => p.name.toLowerCase().trim() == projectName.toLowerCase().trim() &&
                (p.parentId == parentId) &&
                (projectId == null || p.id != projectId) &&
                p.owner
        );
    }

    public override getMissingThumbnailList(documentIds: string[]): string[] {
        return documentIds.filter(id => this.documentThumbnails[id] == null && this.documentThumbnailPromise[id] == null);
    }

    public override findDesignById(id: string): IDesignListItem {
        return this.designsFlat[id] as IDesignListItem;
        // const projectId = this.designsFlat[id].projectId;
        // const project = this.findProject(projectId, this.projects);

        // return project.directChildDesigns[id] as IDesignListItem;
    }

    public override async addToFavorite(documentIds: string[], documentType = 1): Promise<void> {
        const url = `${environment.documentWebServiceUrl}UserFavoriteDocuments/markAsFavorite`;
        const requestData = { documentIds: documentIds, documentType: documentType };
        this.logServiceRequest('markAsFavorite', requestData, url);
        const request = new HttpRequest('POST', url, requestData, { responseType: 'json' });

        try {
            await this.apiService.request(request, this.getApiOptions());
            this.updateFavoriteDesigns(documentIds, true);
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'mark as favorite failed');

            if (response instanceof HttpErrorResponse && response.status !== 401) {
                this.modalService.openAlertServiceError({ response, endPointUrl: url });
            }

            throw response;
        }
    }

    public override async removeFromFavorite(documentIds: string[], documentType = 1): Promise<void> {
        const url = `${environment.documentWebServiceUrl}UserFavoriteDocuments/unmarkAsFavorite`;
        const requestData = { documentIds: documentIds, documentType: documentType };
        this.logServiceRequest('unmarkAsFavorite', requestData, url);
        const request = new HttpRequest('DELETE', url, requestData, { responseType: 'json' });

        try {
            await this.apiService.request(request, this.getApiOptions());
            this.updateFavoriteDesigns(documentIds, false);
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'unmark as favorite failed');

            if (response instanceof HttpErrorResponse && response.status !== 401) {
                this.modalService.openAlertServiceError({ response, endPointUrl: url });
            }

            throw response;
        }
    }

    public override async getUsersOnDocumentById(documentId: string): Promise<DocumentUser[]> {
        const response = await this.getUsersOnDocumentByIdInternal(documentId);

        return response.map((item) => {
            item.documentid = documentId;
            item.dateadded = item.dateadded != null ? new Date(item.dateadded as any) : item.dateadded;
            return item;
        });
    }

    public override async addUsersOnDocument(data: SharedDocumentUser): Promise<void> {
        return this.addUsersOnDocumentInternal(data);
    }

    public override async removeUsersOnDocument(data: SharedDocumentUser, markDesign = false): Promise<void> {
        return this.removeUsersOnDocumentInternal(data, markDesign);
    }

    public override deleteDocumentLocal(documentId: string): Promise<void> {
        return Promise.resolve(this.deleteDesignFunc(documentId));
    }

    /**
     * Remove the document from internal store and call external document service.
     * @documentId document
     */
    public override async archiveDocument(documentIds: string[]): Promise<void> {
        const sharedDocParentIds: string[] = [];
        documentIds.forEach((doc) => {
            const pToDeleteCandidate = this.findDesignById(doc);

            if (pToDeleteCandidate == null) {
                throw new Error('Document with ID does not exist.');
            }
            if (pToDeleteCandidate.isSharedByMe) {
                sharedDocParentIds.push(pToDeleteCandidate.projectId);
            }
        });
        // delete document
        const dataArchive: any = await this.archiveDocumentInternal(documentIds);
        for (const item in dataArchive) {
            this._documentsArchive.unshift(this.mapArchiveDocumentResponseEntityToIDocumentArchive(dataArchive[item]));
            this.deleteDesignFunc(dataArchive[item].documentId)
        }
        sharedDocParentIds.forEach((projectId) => {
            // set issharedby me for project and child project to false
            const foundedProject = this.findProject(projectId, this.projects);
            this.setSharedByMeTillParentNode(foundedProject, false);
        });

    }

    public removeSubProject(id: string, projects: { [id: string]: Project }, deleteSubProjId?: string): Project {
        if (!id) {
            return null;
        }

        if (projects[id]) {
            delete projects[id].subProjects[deleteSubProjId];
            return projects[id];
        }
        for (const item in projects) {
            const project = projects[item];
            const result = this.removeSubProject(id, project.subProjects, deleteSubProjId);
            if (result) {
                return result;
            }
        }

        return null;
    }

    public removeSubChildDesigns(id: string, projects: { [id: string]: Project }, designList: string[]): Project {
        if (!id) {
            return null;
        }

        if (projects[id]) {
            return projects[id];
        }
        for (const item in projects) {
            const project = projects[item];
            const result = this.removeSubChildDesigns(id, project.subProjects, designList);
            if (result) {
                designList.forEach((design) => {
                    if (project.designs[design]) {
                        delete project.designs[design];
                        delete project.directChildDesigns[design];
                    }
                });
                return result;
            }
        }

        return null;
    }

    public override async toggleExpandProjectV2(projectId: string, expandedState: ProjectExpandedState): Promise<void> {
        if (this._projectsFlat[projectId] == null) {
            throw new Error('Project with ID does not exist.');
        }

        await this.toggleExpandExistingProject(projectId, expandedState);
    }

    /**
     * Check is there are already design with the same name for a given project
     */
    public override designNameExistsOnNew(project: Project, designName: string): boolean {
        if (project == null || designName == null) {
            return false;
        }

        const designs = this.findAllDesignsByProject(project);
        return Object.values(designs).some((d) => d.designName.toLowerCase().trim() == designName.toLowerCase().trim());
    }

    public findParentProjectByProjectId(project: Project): Project {
        if (!project.parentId)
            return project;
        return this.findParentProjectByProjectId(this.findProject(project.parentId, this.projects));
    }


    protected getInternal(data: DocumentGetResponse): void {
        this.internalRawProject = data;
        this._desingsFlat = {};
        this._draftsProject = null;
        this._projectsFlat = {};
        this._projects = {};
        this._myProjects = {};

        let projects: { [projectId: string]: Project } = {};
        if (data?.projects) {
            projects = Object.fromEntries(Object.entries(data.projects).map(([key, project]) => [key, new Project({
                id: project.id,
                name: this.getProjectName(project.name),
                parentId: project.parentId,
                projectType: this.getProjectType(project.name),
                designs: null,
                subProjects: null,
                changeDate: new Date(project.dateChanged.toString()),
                createDate: new Date(project.dateCreated.toString()),
                owner: project.owner,
                isCompanyProject: project.isCompanyProject,
                expanded: project.expanded,
                expandedState: project.expandedState,
                readOnly: project.readOnly,
                isSharedByMe: project.isSharedByMe
            })]));
        }

        let documents: { [documentId: string]: IDesignListItem } = {};
        if (data?.documents) {
            documents = Object.fromEntries(Object.entries(data.documents).map(([key, document]): [string, IDesignListItem] => [key, {
                id: document.id,
                projectId: document.projectId,
                projectName: projects[document.projectId].name,
                changeDate: new Date(document.dateChanged.toString()),
                createDate: new Date(document.dateCreated.toString()),
                designName: document.name,
                locked: document.locked,
                lockedUser: document.lockedUserName,
                metaData: this.getDesignExternalMetadata(document.metadata),
                integrationDocument: undefined,
                owner: document.owner,
                isFavorite: document.isFavoriteDocument,
                isSharedByMe: document.isSharedByMe
            }]));
        }

        this._desingsFlat = documents;

        const allParentProjects = Object.values(projects)
            .filter(project => project.parentId === null || !new Set(Object.keys(projects))
                .has(project.parentId));

        const allSubProjects = Object.values(projects)
            .filter(project => !new Set(allParentProjects.map(parent => parent.id))
                .has(project.id));

        // add designs to projects
        for (const project of allParentProjects) {

            // Set parent id to null(for building tree) as there might be some projects whose parent is not shared
            project.parentId = null;

            const designs: { [id: string]: IDesignListItem } = Object.fromEntries(Object.entries(documents).filter(([_key, document]) => document.projectId == project.id));
            project.directChildDesigns = designs;
            project.designs = designs;

            this._projectsFlat[project.id] = project;
            this._projects[project.id] = project;

            // drafts project
            if (project.projectType == ProjectType.draft) {
                this._draftsProject = project;
            }
        }

        // add designs to subProjects
        for (const project of allSubProjects) {
            this._projectsFlat[project.id] = project;
        }

        const projectRaw: { [projectId: string]: Project } = cloneDeep(projects);
        this._projects = this.buildProjectTree(Object.fromEntries(Object.entries(projectRaw).filter(([_key, document]) => document.projectType == ProjectType.common || document.projectType == ProjectType.draft)));
        // TODO - not ussing myProject any more because we need to update designs in every different items
        //this._myProjects = this.buildProjectTree(Object.fromEntries(Object.entries(projectRaw).filter(([key, document]) => document.projectType == ProjectType.common)));

        // drafts project
        if (!Object.values(this._projectsFlat).some((project) => project.projectType == ProjectType.draft)) {
            const draftProject: Project = new Project({ name: '#$draft', projectType: ProjectType.draft });

            // call the actual service to add the project into store
            this.addNewProject(draftProject).then((project) => {
                this._projectsFlat[project.id] = project;
                this._projects[project.id] = project;
                this._draftsProject = project;
            });
        }

        this.setSharedStatusforPartiallySharedProject();
        this.sortAllProjectList();
    }

    protected updateProject(designId: string, projectId: string): void {
        const design = this.findDesignById(designId);
        if (design == null) {
            throw new Error('Invalid design id.');
        }

        const project = this.findProjectById(projectId);
        if (project == null) {
            throw new Error('Invalid project id.');
        }

        design.projectId = projectId;
        design.projectName = project.name;

        // remove design from project
        for (const pKey in this.projectsFlat) {
            const p = this.projectsFlat[pKey];

            if (p.directChildDesigns[design.id]) {
                delete p.directChildDesigns[design.id];
                delete p.designs[design.id];

                //remove design from tree starting from root node
                if (p.parentId == null) {
                    const proj = this.findProject(p.id, this.projects);
                    this.updateProjectTreeDesigns(proj, design.id);
                }
            }
        }

        // remove design from designs
        delete this._desingsFlat[design.id];

        // add design to project
        if (project.directChildDesigns == null) {
            project.directChildDesigns = {};
        }

        this.addUpdateDesignInProject(design);
        this.pendingActionService.setPendingAction(PendingAction.RefershTree);
    }

    /**
     * Adds user to the project
     * @param data - Project user data
     */
    protected async addUsersOnProjectByIdInternal(data: ProjectUser): Promise<void> {
        const url = `${environment.documentWebServiceUrl}User`;

        const sendData = {
            projectid: data.projectid,
            user: data.user
        };

        try {
            await this.apiService.request(new HttpRequest('POST', url, sendData), this.getApiOptions()).then(() => {
                const foundedProject = this.findProject(data.projectid, this.projects);
                this.setSharedByMeTillParentNode(foundedProject, true);
                this.setSharedByMeTillChildNode(foundedProject, true);
            });
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'add user to the project by id');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.modalService.openAlertError(
                        this.localizationService.getString('Agito.Hilti.Profis3.ShareProject.UserNotAdded.Title'),
                        this.localizationService.getString('Agito.Hilti.Profis3.ShareProject.UserNotAdded.Message'),
                        {
                            response,
                            endPointUrl: url,
                            requestPayload: sendData
                        }
                    );
                }
                else {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: sendData
                    });
                }
            }

            throw response;
        }
    }

    /**
     * Removes user from the project
     * @param data - Project user data
     */
    protected async removeUsersOnProjectByIdInternal(data: ProjectUser): Promise<void> {
        const url = `${environment.documentWebServiceUrl}User`;
        const sendData = {
            projectid: data.projectid,
            user: data.user
        };

        const request = new HttpRequest('DELETE', url, sendData, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });

        try {
            await this.apiService.request(request, this.getApiOptions()).then(() => {
                // check if parent project contains any shared users, and if not remove parent also from shared tree
                this.setSharedByMeForProject(data.projectid);
                // Remove child projects as parent project is unshared
                this.setSharedByMeForChild(data.projectid);
            });
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'delete user from the project by id');

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: sendData
                    });
                }
            }

            throw response;
        }
    }

    /**
     * Make a small change to the already exclusively locked and open design, without closing or releasing the content.
     * @param id - design id
     * @param base64XmlContent - the design content in base64 encoded xml format
     * @param sessionId - id of the design change session
     * @param metaData -
     * @param openDesign - if it's called when you open design or close/save
     */
    protected async putSmallDesignChange(
        designId: string,
        projectId: string,
        designName: string,
        base64XmlContent: string,
        sessionId: string,
        metaData: DesignExternalMetaDataCommon,
        unlock = false,
        exclusiveLock = false,
        documentAccessMode: DocumentAccessMode = DocumentAccessMode.Open
    ): Promise<void> {
        const url = `${environment.documentWebServiceUrl}documentcontent`;

        const rData = {
            unlock,
            key: sessionId,
            documentid: designId,
            projectid: projectId,
            name: designName,
            filecontent: base64XmlContent,
            metadata: this.toServiceMetaData(metaData),
            exclusiveLock,
            documentAccessMode
        };
        this.logServiceRequest('putSmallDesignChange', rData, url);

        const request = new HttpRequest('PUT', url, rData, {
            responseType: 'json'
        });

        const design = this.findDesignById(designId);
        const oldDesignName = design.designName;
        try {
            design.designName = designName;
            await this.apiService.request(request, this.getApiOptions());
        }
        catch (response) {
            design.designName = oldDesignName;
            this.loggerService.logServiceError(response, 'document-service', 'small design update failed');

            if (!this.handleSessionExpiredError(response, { designId }, [428])
                && !this.handleDesignFileLocked(response, { designId }, [423], true, documentAccessMode)
            ) {
                if (response instanceof HttpErrorResponse) {
                    if (response.status == 409) {
                        this.errorHandlerService.showExistingDesignNameModal(response, url, rData);
                    }
                    else if (response.status == 404) {
                        this.errorHandlerService.showProjectArchivedModal(response, url, rData);
                    }
                    else if (response.status != 401) {
                        this.modalService.openAlertServiceError({
                            response,
                            endPointUrl: url,
                            requestPayload: rData
                        });
                    }
                }
            }

            throw response;
        }
    }

    protected deleteDesignFunc(designId: string): void {
        const design = this.findDesignById(designId);
        const project = this.findProjectById(design?.projectId);

        if (project == null) {
            throw new Error('Invalid project ID.');
        }

        const deleteDoc = Object.keys(this.internalRawProject.documents).find((it) => this.internalRawProject.documents[it].id == designId);
        delete this.internalRawProject.documents[deleteDoc];

        // remove from project
        delete project.directChildDesigns[designId];
        delete project.designs[designId];

        // remove from all parent projects
        let parentX: Project = this.findProjectById(project.parentId);
        while (parentX != undefined && parentX != null) {
            delete parentX.designs[designId];
            parentX = this.findProjectById(parentX.parentId);
        }

        // remove from flat design list
        delete this._desingsFlat[designId];

        // remove from project object
        const proj: Project = this.findProject(project.id, this._projects);
        delete proj.directChildDesigns[designId];
        delete proj.designs[designId];

        let parentProj: Project = this.findProject(project.parentId, this._projects);
        while (parentProj) {
            delete parentProj.designs[designId];
            delete parentProj.directChildDesigns[designId];
            parentProj = this.findProject(parentProj.parentId, this._projects);
        }
    }

    protected getTimestamp() {
        const utc = this.localizationService.moment().utc();
        return new Date(utc.year(), utc.month(), utc.date(), utc.hour(), utc.minute(), utc.second(), utc.millisecond());
    }

    protected addUpdateDesignInProjectAdditional(newDesign: IDesignListItemCommon, project: Project): void {
        newDesign.isSharedByMe = project.isSharedByMe;

        const foundedProject = this.findProject(project.id, this.projects);
        this.setDesignTillParentNode(foundedProject, newDesign);
    }


    private deleteProjectFunc(projId: string): void {
        delete this.internalRawProject.projects[projId];
        const deleteDocs = Object.keys(this.internalRawProject.documents).filter((it) => this.internalRawProject.documents[it].projectId == projId);
        deleteDocs.forEach((it) => this.deleteDesignFunc(it));
    }

    private async toggleExpandExistingProject(projectId: string, expandedState: ProjectExpandedState): Promise<void> {
        const url = `${environment.documentWebServiceUrl}project/setExpanded/${projectId}`;
        const sendData = expandedState;

        this.logServiceRequest('toggleExpandExistingProject', url, expandedState);

        try {
            await this.apiService.request(new HttpRequest('PUT', url, sendData), this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'put project setExpanded');

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    private async removeExistingArchivedDesign(documentIds: string[]): Promise<void> {
        const url = `${environment.documentWebServiceUrl}Document/permanentdelete`;

        this.logServiceRequest('removeExistingArchivedDesign', url);

        try {
            await this.apiService.request(new HttpRequest('DELETE', url, documentIds, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                }
                )
            }));
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'delete design');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 423) {
                    //throw new CantDeleteProjectsBecauseDocumentInUse();
                }

                if (response.status == 404) {
                    this.errorHandlerService.showDesignDoesNotExistModal(response, url);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    private deleteProject(id: string, projects: { [id: string]: Project }): void {
        if (projects[id]) {
            delete projects[id];
        }
        for (const item in projects) {
            const project = projects[item];
            this.deleteProject(id, project.subProjects);
        }
    }

    private getDesignMetaData(x: any) {
        const metaData: { [key: string]: string } = {};
        x.forEach((item: any) => {
            metaData[item.key] = item.value;
        })
        return metaData;
    }

    private getDocumentCreatedDate(documentId: string) {
        const document = this._documentsArchive.find(document => document.id == documentId);
        return document.created;
    }

    private updateProjectTreeDesigns(project: Project, designId: string): Project {
        delete project.designs[designId];
        delete project.directChildDesigns[designId];

        if (project.subProjects && Object.entries(project.subProjects).length > 0) {
            for (const child in project.subProjects) {
                const subProject = project.subProjects[child];
                this.updateProjectTreeDesigns(subProject, designId);
            }
        }
        return project;
    }

    /**
     * Sets the projects isSharedByMe property to true for a partially shared project.
     * This is needed for generating the SharedByMe tree structure
     */
    private setSharedStatusforPartiallySharedProject(): void {
        Object.values(this.projectsFlat).forEach(p => {
            if (p.isSharedByMe || Object.values(this.designsFlat).some(d => d.projectId === p.id && d.isSharedByMe)) {
                this.setSharedByMeTillParentNode(this.findProject(p.id, this.projects), true);
            }
        });
    }

    private getArchiveDocumentsInternal(dataArchive: ArchiveDocumentResponseModel[]): void {
        this._documentsArchive = [];

        for (const item in dataArchive) {
            this._documentsArchive.push(this.mapArchiveDocumentResponseEntityToIDocumentArchive(dataArchive[item]));
        }
    }

    private mapArchiveDocumentResponseEntityToIDocumentArchive(row: ArchiveDocumentResponseModel): IDocumentArchive {
        return {
            archived: row.archived,
            created: row.created,
            id: row.documentId,
            designs: row.designs,
            members: row.members,
            name: row.name,
            parentProjectId: row.parentProjectId,
            projectname: row.projectname
        };
    }

    private setSharedByMeTillParentNode(foundedProject: Project, sharedValue: boolean) {
        if (foundedProject?.owner) {
            foundedProject.isSharedByMe = sharedValue;
        }

        if (!sharedValue) {
            Object.values(foundedProject.designs).forEach((design) => {
                if (design.isSharedByMe) {
                    foundedProject.isSharedByMe = true;
                }
            });
        }

        if (foundedProject.parentId) {
            const item = this.findProject(foundedProject.parentId, this.projects);
            this.setSharedByMeTillParentNode(item, sharedValue);
        }
    }

    private setSharedByMeTillChildNode(foundedProject: Project, isSharedByMe: boolean) {
        foundedProject.isSharedByMe = isSharedByMe;
        Object.values(foundedProject.designs).forEach((design) => {
            design.isSharedByMe = isSharedByMe;
        });
        for (const subProject in foundedProject.subProjects) {
            const project = foundedProject.subProjects[subProject];
            this.setSharedByMeTillChildNode(project, isSharedByMe);
        }
    }

    private setDesignTillParentNode(foundedProject: Project, newDesign: IDesignListItemCommon) {
        foundedProject.designs[newDesign.id] = newDesign;
        foundedProject.directChildDesigns[newDesign.id] = newDesign;

        if (foundedProject.parentId) {
            const item = this.findProject(foundedProject.parentId, this.projects);
            this.setDesignTillParentNode(item, newDesign);
        }
    }

    private buildProjectTree(item: { [id: string]: Project }): Record<string, Project> {
        const tree: Record<string, Project> = {};
        for (const id in item) {
            if (!item[id].parentId) {
                tree[id] = this.buildProjectNode(item, id)
            }
        }
        return tree;
    }

    private buildProjectNode(item: { [id: string]: Project }, id: string): Project {
        const node = item[id];
        const children: { [id: string]: Project } = {};
        const designs = Object.fromEntries(Object.entries(this._desingsFlat).filter(([_key, document]) => document.projectId == id));
        node.designs = designs;
        node.directChildDesigns = designs;

        for (const childId in item) {
            if (item[childId].parentId === id) {
                const childNode = this.buildProjectNode(item, childId);
                children[childId] = childNode;
                Object.values(childNode.designs).forEach((design) => {
                    item[id].designs[design.id] = design;
                });
            }
        }

        node.subProjects = children;
        return node;
    }

    private updateCompanyProject(project: Project, companyProject: boolean): Project {
        project.isCompanyProject = companyProject;
        const flatProject = this.findProjectById(project.id);
        this._projectsFlat[flatProject.id].isCompanyProject = companyProject;

        if (project.subProjects && Object.entries(project.subProjects).length > 0) {
            for (const child in project.subProjects) {
                const subProject = project.subProjects[child];
                this.updateCompanyProject(subProject, companyProject);
            }
        }
        return project;
    }

    private flattenProjects(projects: Record<string, Project>): Record<string, Project> {
        const flattenedProjects: Record<string, Project> = {};

        function flattenHelper(node: Project) {
            const { id, subProjects } = node;

            flattenedProjects[id] = JSON.parse(JSON.stringify(node));

            for (const childId in subProjects) {
                flattenHelper(subProjects[childId]);
            }
        }

        for (const key in projects) {
            flattenHelper(projects[key]);
        }

        for (const key in flattenedProjects) {
            flattenedProjects[key].subProjects = {};
            flattenedProjects[key].parentId = null;
        }
        return flattenedProjects;
    }

    private updateFavoriteDesigns(documentIds: string[], isFavorite: boolean): void {
        const documents = Object.values(this.designsFlat).filter(x => documentIds.includes(x.id));
        documents.forEach(x => x.isFavorite = isFavorite);
    }

    /**
* Returns users on the document
* @param documentId The document id
*/
    private async getUsersOnDocumentByIdInternal(documentId: string): Promise<DocumentUser[]> {
        const url = `${environment.documentWebServiceUrl}user/document/${documentId}`;

        try {
            return (await this.apiService.request<DocumentUser[]>(new HttpRequest('GET', url), this.getApiOptions())).body;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'get users on document by id');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    /**
* Adds user to the project
* @param data - Project user data
*/
    private async addUsersOnDocumentInternal(data: SharedDocumentUser): Promise<void> {
        const url = `${environment.documentWebServiceUrl}user/shareDocument`;

        const sendData: SharedDocumentUser = {
            sharedByUserId: this.userService.authentication.userId,
            ...data
        };

        try {
            await this.apiService.request(new HttpRequest('POST', url, sendData), this.getApiOptions()).then(() => {
                const design = this.findDesignById(data.documentId);
                design.isSharedByMe = true;
                const project = this.findProject(design?.projectId, this.projects);
                project.isSharedByMe = true;
                this.setSharedByMeTillParentNode(project, true);
            });
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'add user to the document by id');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.modalService.openAlertError(
                        this.localizationService.getString('Agito.Hilti.Profis3.ShareProject.UserNotAdded.Title'),
                        this.localizationService.getString('Agito.Hilti.Profis3.ShareProject.UserNotAdded.Message'),
                        {
                            response,
                            endPointUrl: url,
                            requestPayload: sendData
                        }
                    );
                }
                else {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: sendData
                    });
                }
            }

            throw response;
        }
    }

    /**
    * Adds user to the document
    * @param data - Document user data
    */
    private async removeUsersOnDocumentInternal(data: SharedDocumentUser, markDesign = false): Promise<void> {
        const url = `${environment.documentWebServiceUrl}user/unshareDocument`;

        const sendData: SharedDocumentUser = {
            ...data,
            sharedByUserId: this.userService.authentication.userId
        };

        try {
            const request = new HttpRequest('DELETE', url, sendData, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
            await this.apiService.request(request, this.getApiOptions()).then(() => {
                if (markDesign) {
                    const design = this.findDesignById(data.documentId);
                    design.isSharedByMe = false;
                    const project = this.findProject(design?.projectId, this.projects);
                    this.setSharedByMeForProjectByDesigns(project.id);
                }

            });
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'remove user from the document by id');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.modalService.openAlertError(
                        this.localizationService.getString('Agito.Hilti.Profis3.ShareProject.UserNotAdded.Title'),
                        this.localizationService.getString('Agito.Hilti.Profis3.ShareProject.UserNotAdded.Message'),
                        {
                            response,
                            endPointUrl: url,
                            requestPayload: sendData
                        }
                    );
                }
                else {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: sendData
                    });
                }
            }

            throw response;
        }
    }

    private async archiveDocumentInternal(documentIds: string[]): Promise<unknown> {
        const url = `${environment.documentWebServiceUrl}Document/archive`;
        const sendData = { documentIds: documentIds };

        try {
            return (await this.apiService.request(new HttpRequest('PATCH', url, sendData), this.getApiOptions())).body;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'add design/s to archive failed');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.modalService.openAlertError(
                        this.localizationService.getString('Agito.Hilti.Profis3.HomePage.ArchiveFailed.Title'),
                        this.localizationService.getString('Agito.Hilti.Profis3.HomePage.ArchiveFailed.Message'),
                        {
                            response,
                            endPointUrl: url,
                            requestPayload: sendData
                        }
                    );
                }
                else {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: sendData
                    });
                }
            }

            throw response;
        }
    }

    // set sharedbyMe for project and upward after removal of user from project
    private async setSharedByMeForProject(projectId: string) {
        const item = this.findProject(projectId, this.projects);
        const projectUsers = await this.getUsersOnProjectByIdInternal(item.id);

        if (projectUsers?.length <= 1) {
            // check if any design is marked as shared by me and if not set it false
            if (Object.values(item.designs).some((design) => design.isSharedByMe) || item.isSharedByMe) {
                this._projectsFlat[item.id].isSharedByMe = false;
                item.isSharedByMe = false;
                Object.values(item.designs).forEach((design) => {
                    design.isSharedByMe = false;
                });
            }
        }

        const parentItem = this.findProject(item.parentId, this.projects); // Find the parent item

        if (parentItem) {
            this.setSharedByMeForProject(parentItem.id); // Recursively call for the parent item
        }
    }

    private async setSharedByMeForChild(projectId: string) {
        const item = this.findProject(projectId, this.projects);
        const projectUsers = await this.getUsersOnProjectByIdInternal(item.id);

        if (projectUsers?.length <= 1) {
            this._projectsFlat[item.id].isSharedByMe = false;
            item.isSharedByMe = false;
            Object.values(item.designs).forEach((design) => {
                design.isSharedByMe = false;
            });
        }
        for (const subProject in item.subProjects) {
            const project = item.subProjects[subProject];
            this.setSharedByMeForChild(project.id);
        }
    }

    // set isSharedByMe to parent project and its parent project by design id
    private async setSharedByMeForProjectByDesigns(projectId: string) {
        const project = this.findProject(projectId, this.projects);
        project.isSharedByMe = false;
        Object.values(project.designs).forEach((design) => {
            if (design.isSharedByMe) {
                project.isSharedByMe = true;
            }
        });
        if (project.parentId) {
            this.setSharedByMeForProjectByDesigns(project.parentId);
        }
    }
}
