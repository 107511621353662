import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PendingAction } from '../components/home-page/home-page.common';

@Injectable({
    providedIn: 'root'
})
export class PendingActionService {

    private _pendingActions = new Subject<PendingAction>();
    public pendingActions = this._pendingActions.asObservable();

    public setPendingAction(value: PendingAction) {
        this._pendingActions.next(value);
    }
}