<div class="modal-header">
    <h3 class="modal-title" l10n="Agito.Hilti.Profis3.Main.Feedback"></h3>

    <button type="button" class="close" (click)="close()">
        <span>&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="feedback-form-popup" *ngIf="!isFormSubmitted || loading">
        <div class="margin" l10n="Agito.Hilti.Profis3.Feedback.Experience"></div>
        <div class="margin">
            <button id="feeback-unhappy-button" class="reaction" [ngClass]="{'selected': reactionType==react.Unhappy}" (click)="onReact(react.Unhappy)"><span class="sprite sprite-emotion_unhappy"></span></button>
            <button id="feeback-neutral-button" class="reaction" [ngClass]="{'selected': reactionType==react.Neutral}" (click)="onReact(react.Neutral)"><span class="sprite sprite-emotion_neutral"></span></button>
            <button id="feeback-happy-button" class="reaction" [ngClass]="{'selected': reactionType==react.Happy}" (click)="onReact(react.Happy)"><span class="sprite sprite-emotion_happy"></span></button>
        </div>
        <div class="margin">
            <textarea id="additonal-feedback" class="feedback" name="message" [maxlength]="10000"
            [placeholder]="'Agito.Hilti.Profis3.Feedback.AdditionalFeedback'| l10n:localization.selectedLanguage" [(ngModel)]="feedBack.additonalFeedback" ></textarea>
        </div>
        <div class="margin">
            <pe-checkbox-button  class="checkbox"
            [items]="allowToContact.items"
            [selectedValues]="allowToContact.selectedValues"
            (selectedValuesChange)="allowToContact.selectedValues = $any($event).detail;updateFeedback()"
            >
            </pe-checkbox-button>
        </div>
    </div>
    <div class="feedback-form-popup" *ngIf="isFormSubmitted && !loading">
        <div class="message-one" l10n="Agito.Hilti.Profis3.Feedback.ConfirmationMessage">
        </div>
        <div class="message-two" l10n="Agito.Hilti.Profis3.Feedback.ConfirmationSubMessage">
        </div>
    </div>
</div>

<div class="modal-footer">
    <button id="support-save-button" type="submit" class="modal-button button button-default"
        *ngIf="!isFormSubmitted || loading" [disabled]="reacted()" (click)="onSubmit()">
        <pe-loading-button [loading]="isFormSubmitted"
            [text]="'Agito.Hilti.Profis3.Feedback.Submit' | l10n:localization.selectedLanguage">
        </pe-loading-button>
    </button>
    <button id="feedback-close-button" type="button" class="modal-button button button-CTA"
        l10n="Agito.Hilti.Profis3.Ok" (click)="close()" *ngIf="isFormSubmitted && !loading"></button>
</div>
