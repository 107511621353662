import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';
import {
    ReportTemplateEntity
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate';

import { LoggerService } from './logger.service';
import { IReportTemplateLogo } from './report.service';

export abstract class ReportTemplateService {
    public templates: ReportTemplateEntity[] = [];

    protected reportTemplateLogos: Record<number, IReportTemplateLogo> = {};

    constructor(
        protected serviceName: string,
        protected loggerService: LoggerService
    ) { }

    public get logos(): Record<number, IReportTemplateLogo> {
        return this.reportTemplateLogos;
    }

    public abstract getTemplates(options?: ApiOptions): Promise<ReportTemplateEntity[]>;

    public abstract updateTemplate(templates: ReportTemplateEntity[], savedTemplates: ReportTemplateEntity[]): Promise<number[]>;

    public abstract getLogo(id: number, logos: Record<number, IReportTemplateLogo>): Promise<IReportTemplateLogo>;

    public abstract createDefaultTemplate(regionId: number): Promise<void>;

    protected logServiceRequest(fnName: string, ...args: any[]): void {
        this.loggerService.logServiceRequest(this.serviceName, fnName, ...args);
    }

    protected logServiceResponse(fnName: string, ...args: any[]): void {
        this.loggerService.logServiceResponse(this.serviceName, fnName, ...args);
    }
}
