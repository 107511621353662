import * as React from 'react';

import { IToggleImageButtonProps } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-image-button-props';
import { getMenuControlImageReactData } from '../../../../helpers/react-helpers';
import { ControlHeader } from '../../ControlHeader';
import { buildHtmlTooltip, isControlHidden } from '../../MainMenuHelper';

export class ToggleImageButton extends React.PureComponent<IToggleImageButtonProps> {
    constructor(props?: IToggleImageButtonProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    public override render() {
        if (isControlHidden(this.props)) {
            return null;
        }

        const buttonId = `${this.props.controlId}-toggle-image-button`;
        const buttonActiveClass = this.props.active ? 'active' : 'inactive';
        const buttonTypeClass = 'button-default';
        const buttonPositionClass = this.props.position ?? '';

        const tooltipContent = this.props.disabled ? buildHtmlTooltip(this.props.tooltipDisabled, this.props.tooltipDisabledTitle) : buildHtmlTooltip(this.props.tooltip, this.props.tooltipTitle);
        const tooltip = (this.props.title != null && this.props.title != '') ? null : tooltipContent;

        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled ? this.props.tooltipDisabled : this.props.tooltip;

        const imageData = !this.props.active
            ? getMenuControlImageReactData(this.props.image, this.props.imageStyle)
            : getMenuControlImageReactData(this.props.alternateImage, this.props.alternateImageStyle);

        return (
            <div data-control-id={this.props.controlId} className={`control react-toggle-image-button ${this.props.sizeClass}`}>
                <ControlHeader
                    text={this.props.title}
                    controlId={buttonId}
                    tooltip={tooltipText}
                    tooltipTitle={tooltipTitle}
                    localization={this.props.localization}
                />

                <div className='image-control-container'
                    data-tip={tooltip}
                    data-html={tooltip != null ? true : null}>
                    <button
                        data-control-id={buttonId}
                        className={`button button-element button-ghost-white ${buttonActiveClass} ${buttonTypeClass} ${buttonPositionClass}`}
                        type='button'
                        disabled={this.props.disabled}
                        onClick={this.onClick}>
                        {<div className='image-container'><span className={`image sprite ${imageData.spriteName}`} style={imageData.elementStyle}></span></div>}
                        {this.props.text != null && this.props.text != '' ? <div className='text'>{this.props.text}</div> : null}
                    </button>
                </div>
            </div>
        );
    }

    private onClick(event: React.MouseEvent) {
        if (this.props.clicked != null) {
            this.props.clicked();
        }

        if (this.props.selectable) {
            this.toggle();
        }
    }

    private toggle() {
        if (this.props.activeChanged != null) {
            this.props.activeChanged(!this.props.active, this.props.value);
        }
    }
}
