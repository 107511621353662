import { Injectable } from '@angular/core';
import isEqual from 'lodash-es/isEqual';
import union from 'lodash-es/union';
import { Change, ChangesServiceBase } from '@profis-engineering/pe-ui-common/services/changes.common';

@Injectable({
    providedIn: 'root'
})
export class ChangesService extends ChangesServiceBase {
    constructor() {
        super();
    }

    public getDeepChanges(source: any, target: any, ignoreUndefined?: boolean) {
        return this.findChanges(null, source, target, ignoreUndefined);
    }

    public getShallowChanges(source: object, target: object, ignoreUndefined?: boolean) {
        const changes: { [property: string]: Change } = {};

        if (source == null || target == null) {
            return changes;
        }

        for (const key of union(Object.keys(source), Object.keys(target))) {
            const sourceValue = source[key as keyof typeof source];
            const targetValue = target[key as keyof typeof target];

            if ((!ignoreUndefined || sourceValue != null || targetValue != null) && !isEqual(sourceValue, targetValue)) {
                changes[key] = new Change({
                    name: key,
                    oldValue: sourceValue,
                    newValue: targetValue
                });
            }
        }

        return changes;
    }

    public chainChanges(...changes: Change[][]): Change[] {
        let combinedChanges: Change[] = [];

        if (changes == null || changes.length == 0) {
            return [];
        }

        for (const change of changes) {
            combinedChanges = this.collapseChange(combinedChanges, change);
        }

        return combinedChanges;
    }

    private collapseChange(firstChanges: Change[], secondChanges: Change[]) {
        const change: Change[] = [];

        firstChanges.forEach(changeOne => {

            const changeTwo = secondChanges?.find((ch) => ch.name == changeOne.name);

            if (changeTwo == null) {
                change.push(changeOne);
            }
            else if (!isEqual(changeOne.oldValue, changeTwo.newValue)) {
                change.push(new Change({
                    name: changeOne.name,
                    oldValue: changeOne.oldValue,
                    newValue: changeTwo.newValue
                }));
            }
        });

        secondChanges?.forEach(changeTwo => {
            if (!change.some((ch) => ch.name == changeTwo.name)) {
                change.push(changeTwo);
            }
        });


        return change;
    }

    private findChanges(property: string, source: any, target: any, ignoreUndefined?: boolean) {
        const changes: { [property: string]: Change } = {};

        if (Array.isArray(source) && Array.isArray(target)) {
            for (let i = 0; i < Math.max(source.length, target.length); i++) {
                const arrayChanges = this.findChanges(`${property != null && property != '' ? property : ''}[${i}]`, (i < source.length ? source[i] : undefined), (i < target.length ? target[i] : undefined), ignoreUndefined);

                for (const key in arrayChanges) {
                    changes[key] = arrayChanges[key];
                }
            }
        }
        else if (this.isObject(source) && this.isObject(target)) {
            const objectProperties = union(Object.keys(source), Object.keys(target));

            for (const objectProperty of objectProperties) {
                const objectChanges = this.findChanges(`${property != null && property != '' ? `${property}.` : ''}${objectProperty}`, source[objectProperty], target[objectProperty], ignoreUndefined);

                for (const key in objectChanges) {
                    changes[key] = objectChanges[key];
                }
            }
        }
        else if (source !== target) {
            if (!ignoreUndefined || source != null || target != null) {
                changes[property] = new Change({
                    name: property,
                    oldValue: source,
                    newValue: target
                });
            }
        }

        return changes;
    }

    private isObject(value: any) {
        return value != null && typeof value == 'object' && !(value instanceof RegExp) && !(value instanceof Date);
    }
}
