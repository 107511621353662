

import { inject } from '@angular/core';

import { DataService } from '../services/data.service';
import { GuardFactory } from './combined.guard';

export const initializationGuard: GuardFactory = (handleGuardAction) => {
    const dataService = inject(DataService);

    return () => handleGuardAction(async () => {
        await dataService.loadInitialData();

        return true;
    });
};
