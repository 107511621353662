export enum TrimbleConnectItemType {
    folder,
    pa2,
    pe,
    pdf,
}

export interface ITrimbleConnectItem {
    id: string;
    name: string;
    fullName: string;
    path: string;
    dateModified: Date;
    type: TrimbleConnectItemType;
    size?: number;
    items?: ITrimbleConnectItem[];
}

export interface ITrimbleConnectBrowserTreeContext {
    selectedItem?: ITrimbleConnectItem;
    openFolder: (folder: ITrimbleConnectItem, select?: boolean) => Promise<ITrimbleConnectItem>;
}

export enum TrimbleConnectBrowserMode {
    import,
    exportDesign,
    selectReportLocation
}
