import { Component, OnInit } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';

import { ClipboardService } from '../../services/clipboard.service';
import { LocalizationService } from '../../services/localization.service';

interface IGeneralNotesComponentInput {
    text: string;
    copyText: string;
}

@Component({
    selector: 'app-general-notes',
    templateUrl: './general-notes.component.html',
    styleUrls: ['./general-notes.component.scss']
})
export class GeneralNotesComponent implements OnInit {
    public textHtml: string;

    constructor(
        public clipboard: ClipboardService,
        private localization: LocalizationService,
        private modalInstance: ModalInstance<IGeneralNotesComponentInput>
    ) { }

    public get copyText() {
        if (this.modalInstance?.input?.copyText != null && this.modalInstance?.input?.copyText != '') {
            return this.modalInstance?.input?.copyText;
        }

        return this.textHtml;
    }

    ngOnInit(): void {
        this.textHtml = this.localization.sanitizeText(this.modalInstance?.input?.text, LocalizationService.H1OlLi);
    }

    public close() {
        this.modalInstance.close();
    }
}
