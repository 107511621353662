import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    IntegrationsNotificationServiceBase
} from '@profis-engineering/pe-ui-shared/services/integrations-notification.service.base';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';
import {
    DocumentModel
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Document';
import {
    DocumentIntegrationType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Enums';

import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { FeaturesVisibilityInfoService } from './features-visibility-info.service';
import { LoggerService } from './logger.service';
import { OfflineService } from './offline.service';
import { buildSignalRHubConnection } from './signalr.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class IntegrationsNotificationService extends IntegrationsNotificationServiceBase {
    private readonly newDataAvailableNotificationMethodName: string = 'newDataAvailableNotification';
    private readonly newDocumentNotificationMethodName: string = 'newDocumentNotification';

    private connection: signalR.HubConnection;
    private isConnected = false;

    constructor(
        private offlineService: OfflineService,
        private userService: UserService,
        private loggerService: LoggerService,
        private featuresVisibilityInfoService: FeaturesVisibilityInfoService,
        private apiService: ApiService,
        private userSettingsService: UserSettingsService
    ) {
        super();

        const hubConnectionUrl = `${environment.integrationServicesServerWebSocketsUrl}hubs/subscribe`;

        // convert headers to query string
        const headers = this.userService.getHeaders(hubConnectionUrl, false);
        let headerQuery = '';

        if (environment.accessToken == 'local') {
            for (const headerName in headers) {
                headerQuery += '&' + encodeURIComponent(headerName) + '=' + encodeURIComponent(headers[headerName]);
            }
        }

        if (headerQuery.startsWith('&')) {
            headerQuery = '?' + headerQuery.substring(1);
        }

        const baseUrl = hubConnectionUrl + headerQuery;

        const onDisconnected = this.onDisconnected.bind(this);

        if (!this.offlineService.isOffline &&
            environment.integrationServicesServerEnabled) {
            if (environment.accessToken == 'header' || (environment.accessToken == 'local')) {
                this.connection = buildSignalRHubConnection(baseUrl, () => this.userService.authentication.accessToken, onDisconnected);
            }
            else if ((environment.accessToken == 'cookie')) {
                this.connection = buildSignalRHubConnection(baseUrl, undefined, onDisconnected);
            }
            else {
                this.loggerService.log(
                    'IntegrationsNotificationService::constructor environment.accessToken is Unknown',
                    LogType.error);
            }
        }
    }

    /**
     * Gets the value indicating whether the service is connected
     * and subscribed to the integrations SignalR hub
     */
    public get connected() {
        return this.isConnected;
    }

    /**
     * Connects and subscribes to the integrations SignalR hub and specific notifications
     */
    public async connect(): Promise<boolean> {
        if (this.offlineService.isOffline ||
            this.connected ||
            !environment.integrationServicesServerEnabled ||
            !this.featuresVisibilityInfoService.areIntegrationsEnabled
        ) {
            return false;
        }

        if (environment.accessToken == 'header' || environment.accessToken == 'local') {
            return await this.connectionStart();
        }
        else if (environment.accessToken == 'cookie') {
            await this.apiService.request(new HttpRequest('GET', environment.signalRCoreInitSessionUrl, { withCredentials: true }));

            this.loggerService.log(
                'get IntegrationsNotificationService::signalRCoreInitSessionUrl successful',
                LogType.info);

            return await this.connectionStart();
        }
        else {
            this.loggerService.log(
                'IntegrationsNotificationService::connect environment.accessToken is Unknown',
                LogType.error);

            throw new Error('IntegrationsNotificationService::connect environment.accessToken is Unknown');
        }
    }

    /**
     * Registers the handler for the new data available notification
     * @param handler The handler to invoke when the notification is received
     */
    public registerNewDataAvailableHandler(handler: (response: any) => void): void {
        this.connection.on(this.newDataAvailableNotificationMethodName, handler);
    }

    /**
     * Registers the handler for the new document notification
     * @param handler The handler to invoke when the notification is received
     */
    public registerNewDocumentNotificationHandler(handler: (document: DocumentModel) => void): void {
        this.connection.on(this.newDocumentNotificationMethodName, handler);
    }

    private async connectionStart(): Promise<boolean> {
        try {
            await this.connection.start();

            const promises: Promise<boolean>[] = [];

            promises.push(this.connection.invoke<boolean>(
                'SubscribeNewDataAvailableNotification',
                this.userService.authentication.userId));

            // check if Risa is enabled
            if (this.userSettingsService.settings.application.general.risaEnabled.value) {
                promises.push(this.connection.invoke<boolean>(
                    'SubscribeUser',
                    this.userService.authentication.userId,
                    DocumentIntegrationType.Risa));
            }

            // check if Ram is enabled
            if (this.userSettingsService.settings.application.general.ramEnabled.value) {
                promises.push(this.connection.invoke<boolean>(
                    'SubscribeUser',
                    this.userService.authentication.userId,
                    DocumentIntegrationType.Ram));
            }

            try {
                await Promise.all(promises);

                this.isConnected = true;
                return true;
            }
            catch (error) {
                this.loggerService.logServiceError(error, 'integrations-notification-service', 'subscribe to notifications');

                this.isConnected = false;
                return false;
            }
        }
        catch (error) {
            this.loggerService.logServiceError(error, 'integrations-notification-service', 'connect-signalr-hub');

            return false;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private onDisconnected(_connection: signalR.HubConnection) {
        this.isConnected = false;

        // Remove all notification method handlers
        this.connection.off(this.newDataAvailableNotificationMethodName);
        this.connection.off(this.newDocumentNotificationMethodName);
    }
}
