import { Design } from '../entities/design';
import { urlPath } from '../module-constants';
import { ApiService } from '../services/api.service';
import { BrowserService } from '../services/browser.service';
import { download } from '../services/calculation/calculation-service-base';
import { DocumentService } from '../services/document.service';
import { LocalizationService } from '../services/localization.service';
import { ModalService } from '../services/modal.service';
import { OfflineService } from '../services/offline.service';
import { RoutingService } from '../services/routing.service';

export function openConfirmChangeForOffline(
    routingService: RoutingService,
    offlineService: OfflineService,
    modalService: ModalService,
    localizationService: LocalizationService,
    documentService: DocumentService,
    apiService: ApiService,
    browser: BrowserService,
    design: Design,
    browserClosed: boolean,
    window: Window) {
    if (offlineService.isOffline) {
        if (design.isStateChanged) {
            modalService.openConfirmChange({
                id: 'offline-design-perserve-changes',
                title: localizationService.getString('Agito.Hilti.Profis3.Main.PerserveDesignChangesOffline.Title'),
                message: localizationService.getString('Agito.Hilti.Profis3.Main.PerserveDesignChangesOffline.Message'),
                confirmButtonText: localizationService.getString('Agito.Hilti.Profis3.Main.PerserveDesignChangesOffline.Confirm'),
                cancelButtonText: localizationService.getString('Agito.Hilti.Profis3.Main.PerserveDesignChangesOffline.Cancel'),
                onConfirm: (modal) => {
                    modal.close();
                    download(apiService, browser, localizationService, documentService, offlineService, design, true).then((response) => {
                        // response is null when saving cancelled
                        if (response != null) {
                            design.setSavedStateIdx();
                            if (browserClosed) {
                                window.close();
                            }
                            else {
                                routingService.navigateToUrl(urlPath.projectAndDesign);
                            }
                        }
                    });
                },
                onCancel: (modal) => {
                    modal.close();
                    design.setSavedStateIdx();
                    if (browserClosed) {
                        window.close();
                    }
                    else {
                        routingService.navigateToUrl(urlPath.projectAndDesign);
                    }
                }
            });
        }
    }
}
