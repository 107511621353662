import { Component, OnInit } from '@angular/core';
import {
    LicenseSuiteType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';

import { LicenseComparisonPackage } from '@profis-engineering/pe-ui-common/entities/code-lists/license-comparison-package';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { UserSettingsService } from '../../services/user-settings.service';

interface LicenseSuiteFeature {
    displayKey: string;
    sortOrder: number;
    isStandardLicenseFeature: boolean;
    isPremiumLicenseFeature: boolean;
}

@Component({
    selector: 'app-license-comparison-table',
    templateUrl: './license-comparison-table.component.html',
    styleUrls: ['./license-comparison-table.component.scss']
})
export class LicenseComparisonTableComponent implements OnInit {
    public allFeatures: LicenseSuiteFeature[] = [];

    constructor(
        private commonCodeList: CommonCodeListService,
        private userSettings: UserSettingsService
    ) { }

    ngOnInit() {
        const licenseSuiteFeatures = this.commonCodeList
            .commonCodeLists[CommonCodeList.LicenseComparisonPackages] as LicenseComparisonPackage[];

        // First check if features were defined for this region.
        let allFeatures = licenseSuiteFeatures
            .filter((element) => element.RegionId == this.userSettings.settings.application.general.regionId.value)
            .map((element, index) => this.getLicenseSuiteFeature(element, index));

        // If they weren't, take default features.
        if (allFeatures.length == 0) {
            allFeatures = licenseSuiteFeatures
                .filter((element) => element.RegionId == null)
                .map((element, index) => this.getLicenseSuiteFeature(element, index));
        }

        // Combine all features (before: separate entries for each feature)
        this.allFeatures = this.combineAndSortAllFeatures(allFeatures);
    }

    private getLicenseSuiteFeature(element: LicenseComparisonPackage, index: number): LicenseSuiteFeature {
        return {
            displayKey: element.TranslationKey,
            sortOrder: 1000 + index,
            isStandardLicenseFeature: element.LicenseSuiteType == LicenseSuiteType.Standard,
            isPremiumLicenseFeature: element.LicenseSuiteType == LicenseSuiteType.Premium
        }
    }

    private combineAndSortAllFeatures(allFeatures: LicenseSuiteFeature[]) {
        const retVal: LicenseSuiteFeature[] = [];
        if (allFeatures.length < 1) {
            return retVal;
        }

        // Combine
        allFeatures.forEach(f => {
            const existing = retVal.find(x => x.displayKey == f.displayKey);
            if (existing == null){
                retVal.push(f);
            }
            else {
                existing.isPremiumLicenseFeature = existing.isPremiumLicenseFeature || f.isPremiumLicenseFeature;
                existing.isStandardLicenseFeature = existing.isStandardLicenseFeature || f.isStandardLicenseFeature;

                if (existing.isPremiumLicenseFeature) {
                    existing.sortOrder -= 1000;
                }
            }
        });

        // Final sort
        retVal.sort((f1, f2) => f1.sortOrder - f2.sortOrder);

        return retVal;
    }
}
