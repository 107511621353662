import { IIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';

export interface IMenuControlImageData {
    spriteName: string;
    elementStyle: React.CSSProperties | undefined;
    elementAfterStyle: React.CSSProperties | undefined;
}

export function getMenuControlImageReactData(image: string, imageStyle?: IIconStyle, imageAfterStyle?: IIconStyle) {
    let spriteName: string | undefined = undefined;
    let elementStyle:  React.CSSProperties | undefined = undefined;
    let elementAfterStyle:  React.CSSProperties | undefined = undefined;

    if (imageStyle != null) {
        elementStyle = getReactCSSProperties(imageStyle);
    }

    if (imageAfterStyle != null) {
        elementAfterStyle = getReactCSSProperties(imageAfterStyle);
    }

    if (elementStyle == null && elementAfterStyle == null) {
        spriteName = image;
    }

    return {
        spriteName: spriteName ?? '',
        elementStyle,
        elementAfterStyle
    }
}

function getReactCSSProperties(style?: IIconStyle): React.CSSProperties | undefined {
    if (style == null) {
        return undefined;
    }

    let hasProperties = false;
    const retVal: React.CSSProperties = {};

    if (style['background-image']) {
        retVal.backgroundImage = style['background-image'];
        hasProperties = true;
    }

    if (style['background-position']) {
        retVal.backgroundPosition = style['background-position'];
        hasProperties = true;
    }

    if (style['background-size']) {
        retVal.backgroundSize = style['background-size'];
        hasProperties = true;
    }

    if (style['width']) {
        retVal.width = style['width'];
        hasProperties = true;
    }

    if (style['min-width']) {
        retVal.minWidth = style['min-width'];
        hasProperties = true;
    }

    if (style['max-width']) {
        retVal.maxWidth = style['max-width'];
        hasProperties = true;
    }

    if (style['height']) {
        retVal.height = style['height'];
        hasProperties = true;
    }

    if (style['padding-bottom']) {
        retVal.paddingBottom = style['padding-bottom'];
        hasProperties = true;
    }

    if (style['display']) {
        retVal.display = style['display'];
        hasProperties = true;
    }

    if (style['transform']) {
        retVal.transform = style['transform'];
        hasProperties = true;
    }

    if (style['transform-origin']) {
        retVal.transformOrigin = style['transform-origin'];
        hasProperties = true;
    }

    if (!hasProperties) {
        return undefined;
    }

    return retVal;
}
