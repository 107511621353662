import * as React from 'react';

import { IPopupGridPartialProps } from '@profis-engineering/pe-ui-common/entities/main-menu/popup-grid-props';
import { getMenuControlImageReactData } from '../../../../helpers/react-helpers';
import { ControlHeader } from '../../ControlHeader';
import { isControlHidden } from '../../MainMenuHelper';

export class PopupGridPartial extends React.PureComponent<IPopupGridPartialProps> {
    constructor(props?: IPopupGridPartialProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.onItemClick = this.onItemClick.bind(this);
    }

    private get numberOfButtons() {
        const numberOfButtons = this.props.numberOfButtons != null ? this.props.numberOfButtons : 3;
        return numberOfButtons;
    }

    private get headItems() {
        const headItems = this.props.items.slice(0, this.numberOfButtons);

        // if currently selected value is not in head items, set it as the last head item
        if (headItems.length > 0 && !headItems.some((item) => item.value == this.props.selectedValue)) {
            headItems[headItems.length - 1] = this.props.items.find((item) => item.value == this.props.selectedValue);
        }

        return headItems.map((item) => {
            return {
                item,
                style: getMenuControlImageReactData(
                    item.image,
                    item.imageStyle.elementStyle,
                    item.imageStyle.afterElementStyle
                )
            }
        });
    }

    public override render() {
        if (isControlHidden(this.props)) {
            return null;
        }

        const buttonId = `${this.props.controlId}-button`;
        const buttonText = this.props.localization.getString('Agito.Hilti.Profis3.PopupGridPartial.ShowAll');
        const buttonShown = this.props.items.length > this.numberOfButtons;

        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled ? this.props.tooltipDisabled : this.props.tooltip;

        return (
            <div data-control-id={this.props.controlId} className={`react-popup-grid-partial-control control ${this.props.sizeClass}`}>
                <ControlHeader
                    text={this.props.title}
                    controlId={buttonId}
                    tooltip={tooltipText}
                    tooltipTitle={tooltipTitle}
                    localization={this.props.localization}
                />

                <div className='control-container'>
                    <div className='items'>
                        {
                            this.headItems.map((item) =>
                                <span
                                    key={`${this.props.controlId}-span-${item.item.value}`}
                                    data-tip={item.item.description != null && item.item.description != '' ? item.item.description : null}>
                                    <button
                                        data-control-id={`${this.props.controlId}-${item.item.value}`}
                                        key={`${this.props.controlId}-${item.item.value}`}
                                        className={`item button button-icon button-ghost button-ghost-white ${item.item.value == this.props.selectedValue ? 'active' : ''}`}
                                        type='button'
                                        disabled={item.item.disabled}
                                        onClick={() => this.onItemClick(item.item.value)}  >
                                        <div className='image-container'>
                                            <span className={`image sprite sprite-responsive ${item.style.spriteName ?? ''}`} style={item.style.elementStyle}>
                                                {
                                                    item.style.elementAfterStyle != null
                                                        ? <span className='sprite-responsive-after' style={item.style.elementAfterStyle}></span>
                                                        : null
                                                }
                                            </span>
                                        </div>
                                    </button>
                                </span>
                            )
                        }
                    </div>
                </div>

                {
                    buttonShown
                        ? <button
                            data-control-id={buttonId}
                            className='popup-grid-partial-button'
                            type='button'
                            disabled={this.props.disabled}
                            onClick={this.onClick} >
                            <div className='button-label'>
                                <span>{buttonText}</span>
                            </div>
                        </button>
                        : null
                }
            </div>
        );
    }

    private onClick(event: React.MouseEvent) {
        if (this.props.clicked != null) {
            this.props.clicked(this.props.selectedValue);
        }
    }

    private onItemClick(value: number) {
        if (this.props.valueChanged != null) {
            this.props.valueChanged(value);
        }
    }
}

