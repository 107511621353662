import { Component } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';

@Component({
    selector: 'app-shortcut-icon-modal',
    templateUrl: './shortcut-icon-modal.component.html',
    styleUrls: ['./shortcut-icon-modal.component.scss']
})
export class ShortcutIconModalComponent {
    constructor(
        private modalInstance: ModalInstance
    ) { }

    public close() {
        this.modalInstance.close();
    }
}
