import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import sortBy from 'lodash-es/sortBy';

import {
    AfterViewInit, ChangeDetectorRef, Component, NgZone, OnInit, TrackByFunction, ViewChild
} from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import {
    CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import {
    RadioButtonProps
} from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import {
    CollapseLook
} from '@profis-engineering/pe-ui-common/components/section/section.common';
import {
    TextBoxProps
} from '@profis-engineering/pe-ui-common/components/text-box/text-box.common';
import {
    Feature, KnownRegion
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    ReportTemplateEntity as ITemplate, ReportTemplateOptionEntity as ITemplateOption
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate';
import {
    TemplateOptionLocationType, TemplateOptionType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate.Enums';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';

import { HttpErrorResponse } from '@angular/common/http';
import { ReportPaperSize } from '@profis-engineering/pe-ui-common/entities/code-lists/report-paper-size';
import { ReportType } from '@profis-engineering/pe-ui-common/entities/code-lists/report-type';
import { format, stringNullOrEmpty } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { CollapsingControls } from '../../entities/collapsing-controls';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { GuidService } from '../../services/guid.service';
import { LicenseService } from '../../services/license.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { ReportService } from '../../services/report.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';

export interface IReportTemplatesComponentInput {
    templateId?: number;
}

export interface IDisplayTemplate {
    displayId: string;
    id: number;
    name: string;
    company: string;
    address: string;
    email: string;
    contactPerson: string;
    phoneNumber: string;
    faxNumber: string;
    logo: string;
    paperSize: number;
    reportType: number;
    headerLogo: boolean;
    headerCustomText: boolean;
    headerCustomTextValue: string;
    footerCustomText: boolean;
    footerCustomTextValue: string;
    isTemplate: boolean;
    addingTemplate: boolean;
    editTemplateType: EditTemplateType;
    removeProjectHeader: boolean;
}

enum EditTemplateType {
    none,
    new,
    rename,
    duplicate
}

const PaginationPageSize = 10;
const PaginationPageStep = 1;
const PaginationButtonDelay = 500;
const PaginationStepperDelay = 70;

enum PaginationDirection {
    up,
    down
}

interface Pagination {
    currentPage: number;
    pageSize: number;
    step: number;
    show: { [key: string]: boolean };
    interval: number;
    delayedFunction: number;
}

enum ReportHeaderElement {
    CompanyAndSpecifierDetails,
    HeaderLogo,
    HeaderCustomText
}

enum ReportFooterElement {
    FooterCustomText
}

@Component({
    selector: 'app-report-templates',
    templateUrl: './report-templates.component.html',
    styleUrls: ['./report-templates.component.scss']
})
export class ReportTemplatesComponent implements OnInit, AfterViewInit {
    // TODO: define the max lengths for all inputs
    public nameMaxLength = 255;
    public companyMaxLength = 255;
    public contactPersonMaxLength = 255;
    public addressMaxLength = 255;
    public phoneNumberMaxLength = 255;
    public emailMaxLength = 255;
    public faxNumberMaxLength = 255;

    public submitted: boolean;
    public selectedTemplate: IDisplayTemplate;
    public templates: IDisplayTemplate[];
    public editTemplateType: EditTemplateType;
    public editTemplateName: string;
    public leftSideNavigationCollapsed = false;

    public editTemplateTypeEnum = EditTemplateType;
    public reportHeaderElementEnum = ReportHeaderElement;
    public reportFooterElementEnum = ReportFooterElement;
    public paginationDirectionEnum = PaginationDirection;

    public companyTextBox: TextBoxProps = {};
    public addressTextBox: TextBoxProps = {};
    public emailTextBox: TextBoxProps = {};
    public contactPersonTextBox: TextBoxProps = {};
    public phoneNumberTextBox: TextBoxProps = {};
    public faxNumberTextBox: TextBoxProps = {};
    public logo: string;
    public logoUrl: string; // this one is needed because logos are loaded sepparately as url
    public headerCompanyInfo: boolean;
    public headerProjectName: boolean;
    public headerPageNumber: boolean;
    public headerDateOfReportGeneration: boolean;
    public headerCustomTextValue: string;
    public footerCompanyInfo: boolean;
    public footerLogo: boolean;
    public footerProjectName: boolean;
    public footerPageNumber: boolean;
    public footerDateOfReportGeneration: boolean;
    public footerCustomTextValue: string;
    public templatePagination: Pagination;
    public reportHeaderElementsCheckbox: CheckboxButtonProps<ReportHeaderElement>;
    public reportFooterElementsCheckbox: CheckboxButtonProps<ReportFooterElement>;
    public paperSizesRadio: RadioButtonProps;
    public reportTypesRadio: RadioButtonProps;

    public emailValidator = [Validators.email];
    public collapseSectionBoxLook = CollapseLook.SectionBox;

    @ViewChild('form')
    public form: NgForm;

    private pendingSave: boolean;
    private selectedRenameTemplate: IDisplayTemplate;
    private selectedDuplicateTemplate: IDisplayTemplate;
    private logoUploadInput: HTMLInputElement;

    constructor(
        public localization: LocalizationService,
        public user: UserService,
        private guid: GuidService,
        private commonCodeList: CommonCodeListService,
        private report: ReportService,
        private modalService: ModalService,
        private featuresVisibilityInfo: FeaturesVisibilityInfoService,
        private userSettings: UserSettingsService,
        private licenseService: LicenseService,
        private modalInstance: ModalInstance<IReportTemplatesComponentInput>,
        private sanitizer: DomSanitizer,
        private changeDetectorRef: ChangeDetectorRef,
    ) { }

    public get isFormValid() {
        return this.selectedTemplate == null
            ||
            (
                this.form?.valid
                &&
                this.emailTextBox.isValid
            );
    }

    public get templatesTranslation() {
        return format(this.translate('Agito.Hilti.Profis3.ReportTemplates.Templates') + ' ({0})', this.templates.length);
    }

    public get sortedTemplates() {
        // sort default as last one and then reverse
        const defaultLast = sortBy(this.templates, template => template.isTemplate);
        return defaultLast.reverse();
    }

    public get sapAccountDisclaimer() {
        const region = this.userSettings.getCommonRegionById(KnownRegion.Russia);
        const regionLanguage = this.userSettings.getRegionLanguage(KnownRegion.Russia);

        return this.localization.getString('Agito.Hilti.Profis3.UserSettings.DataFromSAPAccount.Text')
            .replace('{countryContactEmail}', region.contactUrl)
            .replace('{countryContactPhone}', region.supportPhone)
            .replace('{privacyPolicy}', `<a href="${regionLanguage.hiltiDataPrivacyUrl}" target="_blank">${this.localization.getString('Agito.Hilti.Profis3.UserSettings.DataFromSAPAccount.Text.PrivacyPolicy')}</a>`);
    }

    public get newTemplateDisabled() {
        return !this.isFormValid || this.submitted || this.reportTemplatesDisabled();
    }

    public get duplicateTemplateDisabled() {
        return !this.isFormValid || this.submitted || this.reportTemplatesDisabled();
    }

    public get selectTemplateDisabled() {
        return !this.isFormValid || this.submitted;
    }

    public get showPagination() {
        return this.templates != null
            && this.templates != undefined
            && this.templates.length > PaginationPageSize;
    }

    private get paperSizes() {
        const paperSizes = this.commonCodeList.commonCodeLists[CommonCodeList.ReportPaperSize] as ReportPaperSize[];
        return paperSizes;
    }

    private get reportTypes() {
        const reportTypes = this.commonCodeList.commonCodeLists[CommonCodeList.ReportType] as ReportType[];
        return reportTypes;
    }

    public ngOnInit(): void {
        // don't close the modal if save is pending
        this.modalInstance.setOnClosing(() => {
            return this.pendingSave
                ? false
                : true;
        });

        this.editTemplateType = EditTemplateType.none;
        this.templates = [];
        this.leftSideNavigationCollapsed = this.userSettings.isSectionCollapsed(CollapsingControls.ReportTemplates);

        // data
        this.loadTemplates();

        // controls
        this.paperSizesRadio = {
            items: this.paperSizes.map((item) => ({
                id: `report-templates-paper-size-radio-button-${item.id}`,
                value: item.id,
                text: this.localization.getString(item.nameResourceKey),
                description: this.localization.getString(item.descriptionResourceKey)
            }))
        };

        this.reportTypesRadio = {
            items: this.reportTypes.map((item) => ({
                id: `report-templates-report-type-radio-button-${item.id}`,
                value: item.id,
                text: this.localization.getString(item.nameResourceKey),
                description: this.localization.getString(item.descriptionResourceKey)
            }))
        };

        this.reportHeaderElementsCheckbox = {
            items: [
                {
                    id: 'report-templates-header-checkbox-company-and-specifier-details',
                    value: ReportHeaderElement.CompanyAndSpecifierDetails,
                    text: this.localization.getString('Agito.Hilti.Profis3.ReportTemplates.CompanyAndSpecifierDetails'),
                    tooltip: this.reportTemplatesDisabledTooltip(),
                },
                {
                    id: 'report-templates-header-checkbox-logo',
                    value: ReportHeaderElement.HeaderLogo,
                    text: this.localization.getString('Agito.Hilti.Profis3.ReportTemplates.HeaderLogo'),
                    tooltip: this.reportTemplatesDisabledTooltip(),
                },
                {
                    id: 'report-templates-header-checkbox-custom-text',
                    value: ReportHeaderElement.HeaderCustomText,
                    text: this.localization.getString('Agito.Hilti.Profis3.ReportTemplates.HeaderCustomText'),
                    tooltip: this.reportTemplatesDisabledTooltip(),
                }
            ],
            selectedValues: new Set()
        };

        this.reportFooterElementsCheckbox = {
            items: [
                {
                    id: 'report-templates-footer-checkbox-custom-text',
                    value: ReportFooterElement.FooterCustomText,
                    text: this.localization.getString('Agito.Hilti.Profis3.ReportTemplates.FooterCustomText'),
                    tooltip: this.reportTemplatesDisabledTooltip(),
                },
            ],
            selectedValues: new Set()
        };

        this.initPagination();

        const template = this.modalInstance.input?.templateId != null
            ? this.templates.find(template => template.id === this.modalInstance.input.templateId)
            : this.templates.length > 0
                ? this.templates[0]
                : undefined;
        if (template != null) {
            this.setSelectedTemplate(template);
        }

        setTimeout(() => {
            this.logoUploadInput = document.getElementById('report-templates-logo-upload-input') as HTMLInputElement;
        });
    }

    public ngAfterViewInit(): void {
        // Since form is passed from HTML to TS, Angular gets confused when form becomes valid and triggers ExpressionChangedAfterItHasBeenCheckedError
        // Calling change detection here fixes this.
        this.changeDetectorRef.detectChanges();
    }

    public paginationStartMoveContinously(direction: PaginationDirection) {
        document.onmouseup = () => {
            this.paginationStopMoveContinously();
        };

        this.templatePagination.delayedFunction = setTimeout(() => {
            this.templatePagination.interval = setInterval(() => {
                this.paginationMove(direction);
                return;
            }, PaginationStepperDelay);
        }, PaginationButtonDelay);
    }

    public paginationStopMoveContinously() {
        if (this.templatePagination.delayedFunction) {
            clearTimeout(this.templatePagination.delayedFunction);
            this.templatePagination.delayedFunction = null;
        }

        if (this.templatePagination.interval) {
            clearInterval(this.templatePagination.interval);
            this.templatePagination.interval = null;
        }

        document.onmouseup = () => undefined;
    }

    public paginationMove(direction: PaginationDirection) {
        if (!this.showPagination) {
            return;
        }

        if (direction == PaginationDirection.up && !this.disabledPagination(PaginationDirection.up)) {
            this.templatePagination.currentPage -= this.templatePagination.step;
            this.updatePaginationItems();
        }
        else if (direction == PaginationDirection.down && !this.disabledPagination(PaginationDirection.down)) {
            this.templatePagination.currentPage += this.templatePagination.step;
            this.updatePaginationItems();
        }
    }

    public disabledPagination(direction: PaginationDirection) {
        if (direction == PaginationDirection.down) {
            return this.templatePagination.currentPage + this.templatePagination.pageSize >= this.templates.length ? true : false;
        }
        else if (direction == PaginationDirection.up) {
            return this.templatePagination.currentPage <= 0 ? true : false;
        }

        return true;
    }

    public paginationMoveToItem(item: IDisplayTemplate) {
        const currentPage = this.templatePagination.currentPage;
        const pageSize = this.templatePagination.pageSize - 1;

        // find where item is on the list
        let position = 0;
        stopFindPosition:
        for (const template of this.sortedTemplates) {
            if (template.id == item.id) {
                break stopFindPosition;
            }
            position++;
        }

        // find min and max visible pagination item
        const minVisible = currentPage;
        const maxVisible = currentPage + pageSize;

        // if item is already between min and max visible do nothing
        if (position >= minVisible && position <= maxVisible) {
            return;
        }

        // if item is below max visible scroll down enough to make it visible
        if (position > maxVisible) {
            this.templatePagination.currentPage = position - pageSize; // set current page so that item will be the last visible
            return;
        }

        // if item is above min visible scroll up enough to make it visible
        if (position < minVisible) {
            this.templatePagination.currentPage = position; // set current page so that item will be the first visible
            return;
        }
    }

    public trackTemplateByDisplayId: TrackByFunction<IDisplayTemplate> = function (_: number, item: IDisplayTemplate) {
        return item.id;
    };

    public sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    public save() {
        if (this.submitted || !this.isFormValid) {
            return;
        }

        this.saveFormToTemplate(this.selectedTemplate);

        this.submitted = true;
        this.pendingSave = true;


        const templateEntities: ITemplate[] = [];
        for (const template of this.templates) {
            templateEntities.push(this.getTemplateDataForService(template));
        }

        // call save method on service
        this.report.saveTemplates(templateEntities)
            .finally(() => {
                this.pendingSave = false;
                this.updatePaginationItems();
            })
            .then((ids) => {
                this.updateTemplatesWithServiceData(ids);

                this.handleTemplatesSaved();
            })
            .catch((err) => this.handleTemplatesSavingErrors(err));
    }

    public close(selectedTemplateId?: number) {
        this.modalInstance.close(selectedTemplateId);
    }

    public newTemplate() {
        if (this.submitted || !this.isFormValid) {
            return;
        }

        this.saveFormToTemplate(this.selectedTemplate);
        this.showEditTemplate(EditTemplateType.new);
    }

    public editTemplateInputBlur() {
        setTimeout(() => {
            NgZone.assertInAngularZone();

            const activeElement = document.activeElement;
            const newTemplateContainer = document.querySelector('.new-template .new-template-edit');

            if (newTemplateContainer.contains(activeElement)) {
                const newtemplateContainerButtons = newTemplateContainer.querySelector('.edit-template-button-container');
                if (!newtemplateContainerButtons.contains(activeElement)) {
                    this.editTemplateConfirm();
                }
            }
            else {
                this.editTemplateReject();
            }
        });
    }

    public editTemplateConfirm() {
        if (this.editTemplateType == EditTemplateType.none) {
            return;
        }

        switch (this.editTemplateType) {
            case EditTemplateType.new:
                this.editTemplateNew();
                break;
            case EditTemplateType.rename:
                this.editTemplateRename();
                break;
            case EditTemplateType.duplicate:
                this.editTemplateDuplicate();
                break;
            default:
                throw new Error('Unknown EditTemplateType.');
        }
    }

    public editTemplateReject() {
        this.closeNewTemplate();
    }

    public renameTemplateInputBlur() {
        setTimeout(() => {
            NgZone.assertInAngularZone();

            const activeElement = document.activeElement;
            const renameTemplateContainer = document.querySelector('.template-container .rename-template');

            if (renameTemplateContainer?.contains(activeElement)) {
                const newtemplateContainerButtons = renameTemplateContainer.querySelector('.rename-template-button-container');
                if (!newtemplateContainerButtons.contains(activeElement)) {
                    this.renameTemplateConfirm();
                }
            }
            else {
                this.renameTemplateReject();
            }
        });
    }

    public renameTemplateConfirm() {
        if (this.editTemplateName == null || this.editTemplateName.trim() == '') {
            this.renameTemplateReject();
        }
        else {
            if (this.selectedRenameTemplate != null) {
                this.selectedRenameTemplate.name = this.editTemplateName.trim();
            }
        }
        if (this.selectedRenameTemplate != null) {
            this.selectedRenameTemplate.editTemplateType = EditTemplateType.none;
        }
        this.paginationMoveToItem(this.selectedTemplate);
        this.closeNewTemplate();
    }

    public renameTemplateReject() {
        if (this.selectedRenameTemplate != null) {
            this.selectedRenameTemplate.editTemplateType = EditTemplateType.none;
        }
        this.closeNewTemplate();
    }

    public renameTemplate(template: IDisplayTemplate) {
        if (this.submitted) {
            return;
        }

        this.selectedRenameTemplate = template;

        this.showEditTemplate(EditTemplateType.rename, template.name);
    }

    public duplicateTemplate(template: IDisplayTemplate) {
        if (this.submitted || !this.isFormValid) {
            return;
        }

        this.saveFormToTemplate(this.selectedTemplate);
        this.selectedDuplicateTemplate = template;

        this.showEditTemplate(EditTemplateType.duplicate);
    }

    public deleteTemplate(template: IDisplayTemplate) {
        if (this.submitted) {
            return;
        }

        this.deleteTemplateFromList(template);
    }

    public selectTemplate(template: IDisplayTemplate) {
        if (this.selectTemplateDisabled) {
            return;
        }

        this.saveFormToTemplate(this.selectedTemplate);
        this.setSelectedTemplate(template);
    }

    public selectLogoFile() {
        if (this.logoUploadInput.value == null || this.logoUploadInput.value == '') {
            this.logoUploadInput.click();
        }
    }

    public removeLogoFile() {
        if (this.templateControlsDisabled(true)) {
            return;
        }

        this.logoUrl = this.logo = ''; // empty string will remove logo on service
    }

    public logoFileSelected() {
        if (this.logoUploadInput.value != null && this.logoUploadInput.value != '') {
            this.uploadLogoFile(this.logoUploadInput.files[0]);
            this.logoUploadInput.value = null;
        }
    }

    public templateControlsDisabled(enabledForDefault?: boolean) {
        return this.submitted || this.reportTemplatesDisabled(enabledForDefault);
    }

    public reportTemplatesDisabled(enabledForDefault?: boolean) {
        if (enabledForDefault && this.selectedTemplate?.isTemplate) {
            return false;
        }

        return this.featuresVisibilityInfo.isDisabled(Feature.Application_ReportTemplate, this.userSettings.getCommonRegionById(this.userSettings.settings.application.general.regionId.value).id);
    }

    public reportTemplatesDisabledTooltip(enabledForDefault?: boolean) {
        if (enabledForDefault && this.selectedTemplate && this.selectedTemplate.isTemplate) {
            return null;
        }

        return this.featuresVisibilityInfo.tooltip(Feature.Application_ReportTemplate);
    }

    public onLeftSideNavigationSectionCollapsedChange(collapsed: boolean) {
        this.leftSideNavigationCollapsed = collapsed;
        this.userSettings.setSectionCollapsed(CollapsingControls.ReportTemplates, collapsed);
    }

    private translate(key: string) {
        return this.localization.getString(key);
    }

    private initPagination() {
        this.templatePagination = ({
            pageSize: PaginationPageSize,
            currentPage: 0,
            step: PaginationPageStep,
            show: {},
            delayedFunction: null,
            interval: null
        } as Pagination);
        this.updatePaginationItems();
    }

    private updatePaginationItems() {
        if (this.templatePagination == null) {
            return;
        }

        let counter = 0;
        let begin = this.templatePagination.currentPage * this.templatePagination.step;

        if (begin + this.templatePagination.pageSize > this.sortedTemplates.length) {
            begin = Math.max(0, this.sortedTemplates.length - this.templatePagination.pageSize);
        }

        const end = begin + this.templatePagination.pageSize;

        const updateShows = (item: IDisplayTemplate) => {
            if (counter >= begin && counter < end) {
                this.templatePagination.show[item.displayId] = true;
            }
            else {
                this.templatePagination.show[item.displayId] = false;
            }
            counter++;
        };

        this.sortedTemplates.forEach((item) => {
            updateShows(item);
        });
    }

    private uploadLogoFile(file: File) {
        if (file.type != 'image/png' && file.type != 'image/jpeg') {
            this.modalService.openAlertWarning(
                this.localization.getString('Agito.Hilti.Profis3.ReportTemplates.WrongFileTypeAlert.Title'),
                this.localization.getString('Agito.Hilti.Profis3.ReportTemplates.WrongFileTypeAlert.Message')
            );
            return;
        }
        if (file.size > 3 * 1024 * 1024) {
            this.modalService.openAlertWarning(
                this.localization.getString('Agito.Hilti.Profis3.ReportTemplates.FileTooLargeAlert.Title'),
                this.localization.getString('Agito.Hilti.Profis3.ReportTemplates.FileTooLargeAlert.Message')
            );
            return;
        }

        const reader = new FileReader();

        reader.addEventListener('load', () => {
            const img = new Image();
            img.src = reader.result as string;
            img.addEventListener('load', () => {
                if (img.naturalWidth > 1200 || img.naturalHeight > 800) {
                    this.modalService.openAlertWarning(
                        this.localization.getString('Agito.Hilti.Profis3.ReportTemplates.ImageTooLargeAlert.Title'),
                        this.localization.getString('Agito.Hilti.Profis3.ReportTemplates.ImageTooLargeAlert.Message')
                    );
                    return;
                }
                this.logoUrl = this.logo = img.src;
            }, false);
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    private setSelectedTemplate(template: IDisplayTemplate) {
        if (isEqual(template, this.selectedTemplate)) {
            return;
        }

        this.selectedTemplate = template;

        this.fillFormFromTemplate(this.selectedTemplate);
    }

    private editTemplateNew() {
        if (this.editTemplateName == null || this.editTemplateName.trim() == '') {
            this.editTemplateReject();
        }
        else {
            const template: IDisplayTemplate = {
                displayId: this.guid.new(),
                id: 0,
                name: this.editTemplateName.trim(),
                company: null,
                address: null,
                email: null,
                contactPerson: null,
                phoneNumber: null,
                faxNumber: null,
                logo: null,
                paperSize: this.paperSizes[0].id,
                reportType: this.reportTypes[0].id,
                headerLogo: false,
                headerCustomText: false,
                headerCustomTextValue: null,
                footerCustomText: false,
                footerCustomTextValue: null,
                isTemplate: false,
                addingTemplate: false,
                editTemplateType: EditTemplateType.none,
                removeProjectHeader: false
            };
            this.templates.push(template);
            this.setSelectedTemplate(template);
            this.paginationMoveToItem(this.selectedTemplate);

            this.closeNewTemplate();
        }
    }

    private editTemplateRename() {
        if (this.editTemplateName == null || this.editTemplateName.trim() == '') {
            this.editTemplateReject();
        }
        else {
            this.selectedRenameTemplate.name = this.editTemplateName.trim();

            this.closeNewTemplate();
        }
    }

    private editTemplateDuplicate() {
        if (this.editTemplateName == null || this.editTemplateName.trim() == '') {
            this.editTemplateReject();
        }
        else {
            const template: IDisplayTemplate = cloneDeep(this.selectedDuplicateTemplate);
            template.displayId = this.guid.new();
            template.id = 0;
            template.name = this.editTemplateName.trim();
            template.isTemplate = false;
            template.addingTemplate = false;

            if (template.logo == null) {
                this.report.getLogo(this.selectedDuplicateTemplate.id)
                    .then((logo) => {
                        if (this.selectedTemplate != template) { // new template has been selected, do not update logo in this request
                            return;
                        }
                        if (logo != null) {
                            if (logo.base64 != null) {
                                this.logoUrl = this.logo = template.logo = logo.base64;
                            }
                            else {
                                const reader = new FileReader();
                                reader.addEventListener('load', () => {
                                    this.logoUrl = this.logo = template.logo = logo.base64 = (reader.result as string);
                                }, false);
                                reader.readAsDataURL(logo.blob);
                            }
                        }
                    });
            }

            this.templates.push(template);
            this.setSelectedTemplate(template);
            this.paginationMoveToItem(this.selectedTemplate);

            this.closeNewTemplate();
        }
    }

    private showEditTemplate(editTemplateType: EditTemplateType, editTemplateName?: string) {
        this.editTemplateName = editTemplateName;

        if (editTemplateType == EditTemplateType.rename) {
            this.selectedRenameTemplate.editTemplateType = editTemplateType;
            setTimeout(() => {
                document.querySelector<HTMLElement>(`.navigation-report-template #report-templates-rename-template-${this.selectedRenameTemplate.displayId} .rename-template-textfield`)
                    ?.shadowRoot?.querySelector<HTMLElement>('.input')
                    ?.focus();
            });
        }
        else {
            this.editTemplateType = editTemplateType;
            setTimeout(() => {
                document.querySelector<HTMLElement>('.navigation-report-template .edit-template-input #report-templates-new-template-textfield')
                    ?.shadowRoot?.querySelector<HTMLElement>('.input')
                    ?.focus();
            });
        }
    }

    private closeNewTemplate() {
        this.editTemplateType = EditTemplateType.none;
        this.selectedRenameTemplate = null;
        this.selectedDuplicateTemplate = null;
        this.editTemplateName = null;

        setTimeout(() => {
            if (this.selectedTemplate != null) {
                document.querySelector<HTMLElement>(`.navigation-report-template #report-templates-template-${this.selectedTemplate.displayId}`)?.focus();
            }
        });
    }

    private deleteTemplateFromList(template: IDisplayTemplate) {
        this.templates = this.templates.filter(t => t != template);

        if (template == this.selectedTemplate) {
            let selectedTemplate: IDisplayTemplate = null;
            if (this.templates.length > 0) {
                selectedTemplate = this.templates[0];
            }

            this.setSelectedTemplate(selectedTemplate);
        }
    }

    private saveFormToTemplate(template: IDisplayTemplate) {
        if (template == null) {
            return;
        }

        template.company = this.companyTextBox.value;
        template.address = this.addressTextBox.value;
        template.email = this.emailTextBox.value;
        template.contactPerson = this.contactPersonTextBox.value;
        template.phoneNumber = this.phoneNumberTextBox.value;
        template.faxNumber = this.faxNumberTextBox.value;
        template.logo = this.logo;
        template.removeProjectHeader = !this.reportHeaderElementsCheckbox.selectedValues.has(ReportHeaderElement.CompanyAndSpecifierDetails);

        template.paperSize = this.paperSizesRadio.selectedValue;
        template.reportType = this.reportTypesRadio.selectedValue;
        template.headerLogo = this.reportHeaderElementsCheckbox.selectedValues.has(ReportHeaderElement.HeaderLogo);
        template.headerCustomText = this.reportHeaderElementsCheckbox.selectedValues.has(ReportHeaderElement.HeaderCustomText);
        template.headerCustomTextValue = template.headerCustomText ? this.headerCustomTextValue : null;
        template.footerCustomText = this.reportFooterElementsCheckbox.selectedValues.has(ReportFooterElement.FooterCustomText);
        template.footerCustomTextValue = template.footerCustomText ? this.footerCustomTextValue : null;
    }

    private fillFormFromTemplate(template: IDisplayTemplate) {
        if (template == null) {
            return;
        }

        if (this.user.isExternalOnlineRussianUser) {
            if (this.userSettings.ccmsUserSettings != null) {
                this.companyTextBox.value = this.userSettings.ccmsUserSettings.CompanyName;
                this.addressTextBox.value = this.userSettings.ccmsUserSettings.Address;
                this.emailTextBox.value = this.userSettings.ccmsUserSettings.EmailAddress;
                this.contactPersonTextBox.value = this.userSettings.ccmsUserSettings.FullName;
                this.phoneNumberTextBox.value = this.userSettings.ccmsUserSettings.Phone;
                this.faxNumberTextBox.value = this.userSettings.ccmsUserSettings.Fax;
            }
        }
        else {
            this.companyTextBox.value = template.company;
            this.addressTextBox.value = template.address;
            this.emailTextBox.value = template.email;
            this.contactPersonTextBox.value = template.contactPerson;
            this.phoneNumberTextBox.value = template.phoneNumber;
            this.faxNumberTextBox.value = template.faxNumber;
        }
        this.logoUrl = this.logo = template.logo;
        this.paperSizesRadio.selectedValue = template.paperSize;
        this.reportTypesRadio.selectedValue = template.reportType;

        if (template.headerLogo) {
            this.reportHeaderElementsCheckbox.selectedValues.add(ReportHeaderElement.HeaderLogo);
        }
        else if (this.reportHeaderElementsCheckbox.selectedValues.has(ReportHeaderElement.HeaderLogo)) {
            this.reportHeaderElementsCheckbox.selectedValues.delete(ReportHeaderElement.HeaderLogo);
        }

        if (template.headerCustomText) {
            this.reportHeaderElementsCheckbox.selectedValues.add(ReportHeaderElement.HeaderCustomText);
        }
        else if (this.reportHeaderElementsCheckbox.selectedValues.has(ReportHeaderElement.HeaderCustomText)) {
            this.reportHeaderElementsCheckbox.selectedValues.delete(ReportHeaderElement.HeaderCustomText);
        }
        this.headerCustomTextValue = template.headerCustomText ? template.headerCustomTextValue : null;

        if (!template.removeProjectHeader) {
            this.reportHeaderElementsCheckbox.selectedValues.add(ReportHeaderElement.CompanyAndSpecifierDetails);
        }
        else if (this.reportHeaderElementsCheckbox.selectedValues.has(ReportHeaderElement.CompanyAndSpecifierDetails)) {
            this.reportHeaderElementsCheckbox.selectedValues.delete(ReportHeaderElement.CompanyAndSpecifierDetails);
        }

        if (template.footerCustomText) {
            this.reportFooterElementsCheckbox.selectedValues.add(ReportFooterElement.FooterCustomText);
        }
        else if (this.reportFooterElementsCheckbox.selectedValues.has(ReportFooterElement.FooterCustomText)) {
            this.reportFooterElementsCheckbox.selectedValues.delete(ReportFooterElement.FooterCustomText);
        }
        this.footerCustomTextValue = template.footerCustomText ? template.footerCustomTextValue : null;

        if (template.logo == null && template.id != 0) { // if no logo and template has id (is already in database) then try to get url of logo
            this.report.getLogo(template.id)
                .then((logo) => {
                    if (this.selectedTemplate != template) { // new template has been selected, do not update logo in this request
                        return;
                    }
                    if (logo != null) {
                        this.logoUrl = logo.url;
                    }
                });
        }

        this.updatePaginationItems();
    }

    private loadTemplates() {
        this.templates = [];

        for (const templateEntity of this.report.templates) {
            const template: IDisplayTemplate = {
                displayId: this.guid.new(),
                id: templateEntity.Id,
                name: templateEntity.Name,
                company: templateEntity.Company,
                address: templateEntity.Address,
                email: templateEntity.Email,
                contactPerson: templateEntity.ContactPerson,
                phoneNumber: templateEntity.PhoneNumber,
                faxNumber: templateEntity.FaxNumber,
                logo: null, // will be loaded later as url, if this remains null, logo will not be updated on service
                paperSize: this.paperSizes.some((paperSize) => paperSize.id === templateEntity.PaperSize) ? templateEntity.PaperSize : this.paperSizes[0].id,
                reportType: this.reportTypes.some((reportType) => reportType.id === templateEntity.ReportType) ? templateEntity.ReportType : this.reportTypes[0].id,
                headerLogo: templateEntity.TemplateOptions.some((option) => option.TemplateOptionLocationType === TemplateOptionLocationType.Header && option.TemplateOptionType === TemplateOptionType.Logo),
                headerCustomText: templateEntity.TemplateOptions.some((option) => option.TemplateOptionLocationType === TemplateOptionLocationType.Header && option.TemplateOptionType === TemplateOptionType.CustomText),
                headerCustomTextValue: templateEntity.TemplateOptions.some((option) => option.TemplateOptionLocationType === TemplateOptionLocationType.Header && option.TemplateOptionType === TemplateOptionType.CustomText)
                    ? (templateEntity.TemplateOptions.find((option) => option.TemplateOptionLocationType === TemplateOptionLocationType.Header && option.TemplateOptionType === TemplateOptionType.CustomText).Value)
                    : null,
                footerCustomText: templateEntity.TemplateOptions.some((option) => option.TemplateOptionLocationType === TemplateOptionLocationType.Footer && option.TemplateOptionType === TemplateOptionType.CustomText),
                footerCustomTextValue: templateEntity.TemplateOptions.some((option) => option.TemplateOptionLocationType === TemplateOptionLocationType.Footer && option.TemplateOptionType === TemplateOptionType.CustomText)
                    ? (templateEntity.TemplateOptions.find((option) => option.TemplateOptionLocationType === TemplateOptionLocationType.Footer && option.TemplateOptionType === TemplateOptionType.CustomText).Value)
                    : null,
                isTemplate: templateEntity.IsTemplate,
                addingTemplate: templateEntity.AddingTemplate,
                editTemplateType: EditTemplateType.none,
                removeProjectHeader: templateEntity.RemoveProjectHeader
            };

            this.templates.push(template);
        }

        this.updatePaginationItems();
    }

    private getTemplateDataForService(template: IDisplayTemplate) {
        const templateOptions: ITemplateOption[] = [];
        if (template.headerLogo) {
            templateOptions.push({
                TemplateOptionLocationType: TemplateOptionLocationType.Header,
                TemplateOptionType: TemplateOptionType.Logo,
                Value: null
            });
        }

        if (template.headerCustomText) {
            templateOptions.push({
                TemplateOptionLocationType: TemplateOptionLocationType.Header,
                TemplateOptionType: TemplateOptionType.CustomText,
                Value: template.headerCustomTextValue
            });
        }

        if (template.footerCustomText) {
            templateOptions.push({
                TemplateOptionLocationType: TemplateOptionLocationType.Footer,
                TemplateOptionType: TemplateOptionType.CustomText,
                Value: template.footerCustomTextValue
            });
        }

        const templateEntity = {
            Id: template.id,
            Name: template.name,
            Company: this.user.isExternalOnlineRussianUser ? null : template.company,
            Address: this.user.isExternalOnlineRussianUser ? null : template.address,
            Email: this.user.isExternalOnlineRussianUser ? null : template.email,
            ContactPerson: this.user.isExternalOnlineRussianUser ? null : template.contactPerson,
            PhoneNumber: this.user.isExternalOnlineRussianUser ? null : template.phoneNumber,
            FaxNumber: this.user.isExternalOnlineRussianUser ? null : template.faxNumber,
            Logo: template.logo,
            PaperSize: template.paperSize,
            ReportType: template.reportType,
            TemplateOptions: templateOptions,
            IsTemplate: template.isTemplate,
            AddingTemplate: template.addingTemplate,
            RemoveProjectHeader: template.removeProjectHeader
        } as ITemplate;
        return templateEntity;
    }

    private updateTemplatesWithServiceData(ids: number[]) {
        // remove templates that were not returned from server
        // it is possible that template was deleted in another session
        const deletedTemplates = this.templates.filter(template => template.id > 0 && !ids.includes(template.id));
        for (const deletedTemplate of deletedTemplates) {
            this.deleteTemplateFromList(deletedTemplate);
        }

        // update ids (new templates get an id)
        for (let i = 0; i < ids.length; i++) {
            this.templates[i].id = ids[i];
        }
    }

    private handleTemplatesSaved() {
        let selectedTemplateId: number | undefined = this.selectedTemplate.id;

        // For standard license we only allow to update the default template.
        if(this.licenseService.isStandardLicense() && !this.selectedTemplate.isTemplate) {
            const defaultTemplate = this.templates.find(template => template.isTemplate);
            selectedTemplateId = defaultTemplate?.id;
        }

        this.close(selectedTemplateId);
    }

    private handleTemplatesSavingErrors(err: any) {
        this.submitted = false;

        if (err instanceof Error) {
            console.error(err);
        }

        let template: IDisplayTemplate;
        let error: string;
        if (err instanceof HttpErrorResponse && err.status == 400) {
            // Validation error - consider only first
            const errors = err.error.errors;
            const errorKey = this.getFirstErrorKey(errors);
            if (errorKey != null) {
                const templateIndex = this.getReportTemplateIndexForErrorKey(errors, errorKey);
                if (templateIndex >= 0) {
                    template = this.templates[templateIndex];
                }

                error = errors[errorKey][0];
            }


            let title: string | undefined;
            let message: string | undefined;
            switch (error) {
                case 'InvalidDataUri':
                case 'InvalidBase64':
                case 'NotAnImage':
                case 'InvalidType':
                    title = this.localization.getString('Agito.Hilti.Profis3.ReportTemplates.WrongFileTypeAlert.Title');
                    message = this.localization.getString('Agito.Hilti.Profis3.ReportTemplates.WrongFileTypeAlert.Message');
                    break;
                case 'InvalidSize':
                    title = this.localization.getString('Agito.Hilti.Profis3.ReportTemplates.ImageTooLargeAlert.Title');
                    message = this.localization.getString('Agito.Hilti.Profis3.ReportTemplates.ImageTooLargeAlert.Message');
                    break;
            }

            if (template != null && !stringNullOrEmpty(message)){
                // Select template producing error
                this.selectTemplate(template);

                this.modalService.openAlertWarning(
                    title ?? this.localization.getString('Agito.Hilti.Profis3.ServerErrorAlert.Title'),
                    message ?? err.error.message
                );
                return;
            }
        }

        this.modalService.openAlertServiceError({
            response: err
        });
    }

    private getFirstErrorKey(errors: any) {
        const errorKeys = Object.keys(errors);

        for (const errorKey of errorKeys) {
            if (errors[errorKey].length > 0) {
                return errorKey;
            }
        }

        return undefined;
    }

    private getReportTemplateIndexForErrorKey(errors: any, errorKey: string) {
        if (errorKey.indexOf('[') >= 0 && errorKey.indexOf(']') >= 0) {
            const indexStr = errorKey.substring(
                errorKey.indexOf('[') + 1,
                errorKey.indexOf(']')
            );
            return parseInt(indexStr);
        }
        return -1;
    }
}
