import {
    IAdvancedCalculationDefaultValues as IAdvancedCalculationDefaultValuesCommon,
    IAdvancedCalculationInputs as IAdvancedCalculationInputsCommon
} from '@profis-engineering/pe-ui-common/entities/app-settings-concrete-component';
import {
    CommonRegion
} from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import {
    Language
} from '@profis-engineering/pe-ui-common/entities/code-lists/language';
import {
    Separator as SeparatorEntity
} from '@profis-engineering/pe-ui-common/entities/code-lists/separator';
import { MouseClickType } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import {
    getNumberDecimalSeparator, getNumberGroupSeparator
} from '@profis-engineering/pe-ui-common/helpers/localization-helper';
import { LocalizationService } from '../services/localization.service';
import { UserSettingsService } from '../services/user-settings.service';

// Data classes
export interface IAppSettingsRegionLanguageData {
    language: Language;
    region: CommonRegion;
    decimalSeparator: SeparatorEntity;
    thousandsSeparator: SeparatorEntity;
}

export interface IAppSettingsInteractionData {
    rotate: MouseClickType;
    pan: MouseClickType;
}

export interface IStructuralCalculationSoftwareData {
    dlubalEnabled: boolean;
    risaEnabled: boolean;
    sap2000Enabled: boolean;
    robotEnabled: boolean;
    etabsEnabled: boolean;
    staadProEnabled: boolean;
    ramEnabled: boolean;
    checkbotLvl2Enabled: boolean;
}

export interface IAppSettingsDefaultParametersData {
    anchorPlateFactor: number;
    anchorPlateThickness: number;
    concreteCapacityFactor: number;
    inSteelSafetyFactorGammaM0: number;
    inSteelSafetyFactorGammaM1: number;
    inSteelSafetyFactorGammaMb: number;
    inSteelSafetyFactorGammaMw: number;
    materialSafetyFactor: number;
    minimumAnchorToProfileDistance: number;
    minimumConcreteCover: number;
    permanentLoads: number;
    steelCapacityFactor: number;
    steelSafetyFactorGammaM0: number;
    steelSafetyFactorGammaM1: number;
    steelSafetyFactorGammaM2: number;
    variableLoads: number;
    weldsCapacityFactor: number;
}
export const enum CalculationMethodState {
    Unknown = 0,
    AlwaysAskMe = 1,
    WithAdvancedBaseplate = 2,
    WithoutAdvancedBaseplate = 3
}

export interface IAdvancedCalculationDefaultValues extends IAdvancedCalculationDefaultValuesCommon {
    alphaCC?: number;
}

export interface IAdvancedCalculationInputs extends IAdvancedCalculationInputsCommon<IAdvancedCalculationDefaultValues> {
    designTypeId: number;
    isApplicationSettings: boolean;
    selectedRegionId?: number;
}

export interface IAdvancedCalculationData {
    jointCoefficientBj?: number;
    effectiveArea?: number;
    effectiveAreaAISC?: number;
    loadDistributionAngle?: number;
    alphaCC?: number;
    limitPlasticStrain?: number;
    divisionOfSurfaceOfCHS?: number;
    divisionOfArcsOfRHS?: number;
    numberOfElementsOfEdge?: number;
    numberOfAnalysisIterations?: number;
    divergentIterationsCount?: number;
    minimumSizeOfElement?: number;
    maximumSizeOfElement?: number;
    useULSStresses?: boolean;
    concreteInCompressionMethod?: number;
}

export interface IAdvancedBaseplateCalculationSettings {
    calculationMethodState: number;
    advancedBaseplateInputs: IAdvancedCalculationInputs;
    advancedBaseplateData: IAdvancedCalculationData;
}

// Helper methods
export function getNumericTextBoxDecimalSeparator(decimalSeparator: SeparatorEntity | undefined, localization: LocalizationService, userSettings: UserSettingsService) {
    if (decimalSeparator) {
        return decimalSeparator.character;
    }

    return getNumberDecimalSeparator(localization.numberFormat(), userSettings);
}

export function getNumericTextBoxGroupSeparator(groupSeparator: SeparatorEntity | undefined, localization: LocalizationService, userSettings: UserSettingsService) {
    if (groupSeparator) {
        return groupSeparator.character;
    }

    return getNumberGroupSeparator(localization.numberFormat(), userSettings);
}
