<div class="modal-header">
    <h3 class="modal-title" [innerHtml]="title"></h3>

    <button id="version-popup-close-button" type="button" class="close" (click)="close()">
        <span>&times;</span>
    </button>
</div>

<div class="modal-body">
    <app-version></app-version>
</div>
