import { from, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
    HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpResponseBase
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { ApiHttpRequest } from '../services/api.service';
import { AuthenticationService } from '../services/authentication.service';
import { LocalizationService } from '../services/localization.service';
import { ModalService } from '../services/modal.service';
import { UserService } from '../services/user.service';
import { CustomURLEncoder } from '../url-encoder/custom-url-encoder';
import { RoutingService } from '../services/routing.service';
import { ModulesService } from '../services/modules.service';
import { isValidUrl } from '@profis-engineering/pe-ui-common/helpers/url-helper';

@Injectable()
export class MainInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private modalService: ModalService,
        private userService: UserService,
        private localizationService: LocalizationService,
        private routingService: RoutingService,
        private modulesService: ModulesService
    ) { }

    public intercept(req: ApiHttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const apiHeaders = this.userService.getHeaders(req.url, req.forceIncludeAuthenticationHeaders);

        if (apiHeaders == null) {
            // returns a promise that will never get resolved so it's fine if we cast it to any
            return from(this.authenticationService.login(this.routingService.currentPath)) as any;
        }

        // default headers
        let reqHeaders = req.headers;

        if (!reqHeaders.has('Accept')) {
            reqHeaders = reqHeaders
                .set('Accept', 'application/json;odata=verbose');
        }

        // merge req.headers with apiHeaders
        for (const key in apiHeaders) {
            reqHeaders = reqHeaders.set(key, apiHeaders[key]);
        }

        // default params codec https://github.com/angular/angular/issues/11058
        let reqParams = req.params;
        if (reqParams != null) {
            const paramsObject: Record<string, string[]> = {};
            for (const key of reqParams.keys()) {
                paramsObject[key] = reqParams.getAll(key);
            }

            reqParams = new HttpParams({
                fromObject: paramsObject,
                encoder: new CustomURLEncoder()
            });
        }

        // new request
        const orgReq = req;
        req = req.clone({ headers: reqHeaders, params: reqParams });

        // we need to copy every property in ApiHttpRequest by hand
        req.supressErrorMessage = orgReq.supressErrorMessage;
        req.forceIncludeAuthenticationHeaders = orgReq.forceIncludeAuthenticationHeaders;

        // invoke request
        try {
            return next.handle(req)
                .pipe(
                    catchError(async error => {
                        await this.handleError(req, error);

                        throw error;
                    })
                );
        }
        catch (error) {
            return from(this.handleError(req, error))
                .pipe(
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    map(_ => { throw error; })
                );
        }
    }

    private async handleError(req: ApiHttpRequest<any>, error: unknown) {
        const httpResponse = error instanceof HttpResponseBase ? error : undefined;

        if (httpResponse?.status === 401) {
            // logout on AuthenticationRequired urls
            if (
                environment.authenticationRequired.some((url) => isValidUrl(url) && req.url.startsWith(url))
                ||
                this.modulesService.serviceRequiresAuthentication(req.url)
            ) {
                if (httpResponse instanceof HttpErrorResponse && httpResponse.error?.reason === 'LoginOtherDevice') {
                    this.modalService.openUnauthorizedAccess();
                }
                else {
                    // returns a promise that will never get resolved
                    await this.authenticationService.login(this.routingService.currentPath);
                }
            }
        }

        if (req.supressErrorMessage === true) {
            return;
        }

        if (httpResponse?.status === 403) {
            // Show error message that access to the content is forbidden
            this.modalService.openAlertWarning(
                this.localizationService.getString('Agito.Hilti.Profis3.Main.Forbidden.Popup.Title'),
                this.localizationService.getString('Agito.Hilti.Profis3.Main.Forbidden.Popup.Message')
            );
        }
        else if (httpResponse?.status === 0 && !this.userService.isAuthenticated) {
            // Don't show errors
            return;
        }

        // show error message (ignore canceled, unauthenticated and forbidden requests)
        const requestId = req.headers.get('HC-TransactionId') ?? '';

        this.modalService.openAlertServiceError({
            response: error,
            correlationId: requestId
        });
    }
}
