<pe-modal-header [modalInstance]="modalInstance" [title]="modalInstance?.input?.title" [enableClose]="true">
</pe-modal-header>

<pe-modal-body>
    <pe-text-box [title]="modalInstance?.input?.message" class="project-text-box control-row"
        [id]="'project-add-or-rename'" (valueChange)="textChange($any($event).detail)" [value]="value"
        [disabled]="pendingSave" [maxLength]="maxlength" (keyup.enter)="save()" [autofocus]="true">
    </pe-text-box>
</pe-modal-body>

<pe-modal-footer>
    <pe-button class="modal-button" [id]="'project-add-or-rename-cancel-button'" [look]="'Default'"
        (buttonClick)="cancel()" [text]="modalInstance?.input?.cancelButtonText" [disabled]="pendingSave">
    </pe-button>

    <pe-button class="modal-button" [id]="'project-add-or-rename-save-button'" [look]="'Cta'" (buttonClick)="save()"
        [disabled]="pendingSave  || !value">
        <pe-loading-button [loading]="pendingSave" [text]="modalInstance?.input?.confirmButtonText">
        </pe-loading-button>
    </pe-button>
</pe-modal-footer>