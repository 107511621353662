<div class="modal-header">
    <span class="logo sprite sprite-hilti-logo"></span>
</div>

<div class="modal-body">
    <div class="content">
        <div class="content-row first">
            <span class="title" l10n="Agito.Hilti.Profis3.UserAgreementPrivacy.Title"></span>
            <span class="sub-title" l10n="Agito.Hilti.Profis3.UserAgreementPrivacy.Subtitle"></span>
        </div>

        <div class="content-row second">
            <span class="sprite sprite-brand-icon-statistics"></span>

            <div class="diagnostics">
                <pe-checkbox-button
                    [items]="diagnosticsAgreementCheckbox.items"
                    [selectedValues]="diagnosticsAgreementCheckbox.selectedValues"
                    (selectedValuesChange)="diagnosticsAgreementCheckbox.selectedValues = $any($event).detail">
                </pe-checkbox-button>
            </div>
        </div>

        <div class="content-row third" *ngIf="showMarketingCommunicationPreferences">
            <span class="sprite sprite-brand-icon-send-envelope"></span>

            <div class="notify">
                <label l10n="Agito.Hilti.Profis3.UserAgreementSettings.CommunicationPreferences.Text"></label>

                <a class="url link"
                    (click)="openMarketingCommunicationPreferencesUrl()"
                    l10n="Agito.Hilti.Profis3.UserAgreementSettings.CommunicationPreferences.Link">
                </a>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button type="submit" id="user-agreement-privacy-continue-button"
        class="modal-button button button-CTA"
        [disabled]="pendingSave" (click)="save()">
        <pe-loading-button
            [loading]="pendingSave"
            [text]="'Agito.Hilti.Profis3.UserAgreementPrivacy.Continue' | l10n:localization.selectedLanguage">
        </pe-loading-button>
    </button>
</div>
