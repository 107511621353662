import { Component, OnInit } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { LocalizationService } from '../../services/localization.service';
import { Category, ICheckbotMappingResponse, Status } from '../design-from-checkbot/design-from-checkbot.component';
import { IDesignListItem as IDesignListItemCommon } from '@profis-engineering/pe-ui-common/services/document.common';
interface ICheckBotDisplay {
    icon: string;
    title: string;
    message: string;
    mappingStatus: string;
    isComplexDesignsMapping: boolean;
}

export interface ICheckbotMappingInputProps {
    mappingResponse: ICheckbotMappingResponse;
    onConfirm?: (modal?: ModalInstance<ICheckbotMappingInputProps>, mapWithComplexDesigns?: boolean) => Promise<IDesignListItemCommon>;
    onCancel?: (modal?: ModalInstance<ICheckbotMappingInputProps>) => Promise<void>;
}

@Component({
    selector: 'app-import-from-checkbot',
    templateUrl: './import-from-checkbot.component.html',
    styleUrls: ['./import-from-checkbot.component.scss']
})

export class ImportFromCheckbotComponent implements OnInit {
    checkbotDisplayInfo: ICheckBotDisplay[] = [];
    submitted = false;
    allowDesignCreation = false;
    checkbotMappingResponseData: ICheckbotMappingResponse;
    continueButtonLabel = 'Agito.Hilti.Profis3.Integrations.Checkbot.Mapping.Modal.Continue';

    constructor(
        private modalInstance: ModalInstance<ICheckbotMappingInputProps>,
        public localization: LocalizationService
    ) {
    }

    async ngOnInit() {
        this.checkbotMappingResponseData = this.modalInstance.input.mappingResponse;
        if(this.modalInstance.input.mappingResponse.Mappings.some(mapping => mapping.IsComplexDesignMapping)) {
            this.continueButtonLabel = 'Agito.Hilti.Profis3.Integrations.Checkbot.Mapping.Modal.ContinueComplexDesigns';
        }

        this.checkbotMappingResponseData.Mappings.forEach((item) => {
            this.checkbotDisplayInfo.push({
                icon: this.getCategoryIcon(item.Category),
                title: this.localization.getString(item.Title),
                message: this.localization.getString(item.Message),
                mappingStatus: this.getMappingStatus(item.Status),
                isComplexDesignsMapping: item.IsComplexDesignMapping
            })
        });
    }

    public get title() {
        return this.localization.getString("Agito.Hilti.Profis3.Integrations.Checkbot.Mapping.Modal.Title");
    }

    public getCategoryIcon(category: Category) {
        const categoryIcons = {
            [Category.DesignStandard]: 'design-standard',
            [Category.AnchorLayout]: 'anchor',
            [Category.BaseMaterialDimensions]: 'basematerial-dimensions',
            [Category.BaseplateDimensions]: 'baseplate-dimensions',
            [Category.BaseplateMaterial]: 'baseplate-material',
            [Category.Loads]: 'loads',
            [Category.ProfileMaterial]: 'profile-material',
            [Category.ProfileType]: 'profile-type',
            [Category.Units]: 'units',
            [Category.BaseMaterialGrade]: 'basematerial-grade'
        };

        return categoryIcons[category] || '';
    }

    public getMappingStatus(mappingStatus: Status) {
        switch (mappingStatus) {
            case Status.Failed:
                return 'failed';
            case Status.Success:
                return 'success';
            case Status.PartialSuccess:
            case Status.None:
                return 'partial-success'
        }
    }

    public get reviewMessage() {
        const hasFailedMapping = this.checkbotMappingResponseData?.Mappings?.some(x => x.Status === Status.Failed);

        this.allowDesignCreation = !hasFailedMapping;

        return hasFailedMapping
            ? this.localization.getString("Agito.Hilti.Profis3.Integrations.Checkbot.Mapping.Modal.ErrorMessage")
            : this.localization.getString("Agito.Hilti.Profis3.Integrations.Checkbot.Mapping.Modal.ReviewMessage");
    }

    continue(useComplexDesigns: boolean) {
        this.submitted = true;
        this.modalInstance.input.onConfirm(this.modalInstance, useComplexDesigns)
            .then((result: IDesignListItemCommon) => {
                if(result)
                {
                    this.modalInstance.close();
                }
                else{
                    this.submitted = false;
                }
            });
    }

    close() {
        this.modalInstance.input.onCancel()
            .finally(() => {
                this.modalInstance.close();
            });
    }
}
