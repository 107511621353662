<div class="modal-header">
    <span class="logo sprite sprite-hilti-logo"></span>
</div>

<div class="modal-body">
    <p class="user-agreement-title" l10n="Agito.Hilti.Profis3.UserAgreement.Title"></p>
    <p class="user-agreement-sub-title" l10n="Agito.Hilti.Profis3.UserAgreement.SubTitle"></p>

    <a class="open-document" [href]="userAgreementUrl" href="#" target="_blank">
        <span class="text" l10n="Agito.Hilti.Profis3.UserAgreement.DocumentLink"></span>
        <span class="sprite sprite-arrow-right-red"></span>
    </a>
</div>

<div class="modal-footer">
    <div class="checkboxes-agreement">
        <div class="checkbox data-item">
            <pe-checkbox-button
                [items]="endUserAgreementCheckbox.items"
                [selectedValues]="endUserAgreementCheckbox.selectedValues"
                (selectedValuesChange)="endUserAgreementCheckbox.selectedValues = $any($event).detail">
            </pe-checkbox-button>
        </div>

        <div class="checkbox-with-link">
            <div class="checkbox data-item">
                <pe-checkbox-button
                    [items]="hiltiDataPrivacyAgreementCheckbox.items"
                    [selectedValues]="hiltiDataPrivacyAgreementCheckbox.selectedValues"
                    (selectedValuesChange)="hiltiDataPrivacyAgreementCheckbox.selectedValues = $any($event).detail">
                </pe-checkbox-button>
            </div>

            <span class="checkbox-link" l10n="Agito.Hilti.Profis3.UserAgreement.DataPrivacyAgreement.Link"
                (click)="openDataPrivacyLink()">
            </span>
        </div>

        <div class="checkbox data-item" *ngIf="isCustomerAcceptanceEnabled">
            <pe-checkbox-button
                [items]="customerAcceptanceCheckbox.items"
                [selectedValues]="customerAcceptanceCheckbox.selectedValues"
                (selectedValuesChange)="customerAcceptanceCheckbox.selectedValues = $any($event).detail">
            </pe-checkbox-button>
        </div>

        <div class="checkbox data-item" *ngIf="isCustomerAcceptanceEnabled">
            <pe-checkbox-button
                [items]="partyAcceptanceCheckbox.items"
                [selectedValues]="partyAcceptanceCheckbox.selectedValues"
                (selectedValuesChange)="partyAcceptanceCheckbox.selectedValues = $any($event).detail">
            </pe-checkbox-button>
        </div>
    </div>
    <div class="button-container">
        <button type="submit" id="user-agreement-continue-button"
            class="modal-button button button-CTA"
            [disabled]="isContinueDisabled" (click)="continue()">
            <pe-loading-button
                [loading]="pendingSave"
                [text]="'Agito.Hilti.Profis3.UserAgreement.Continue' | l10n:localization.selectedLanguage">
            </pe-loading-button>
        </button>
    </div>
</div>
