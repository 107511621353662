import { DocumentService } from '../services/document.service';

export interface IDocumentArchive {
    id: string;
    parentProjectId: string;
    projectname: string;
    name: string;
    members: number;
    designs: number;
    created: Date;
    archived: Date;
}
export interface IDocumentConstructor {
    id?: string;
    projectId?: string;
    name?: string;
    changeDate?: Date;
    createDate?: Date;
    owner?: boolean;
}
export class DocumentUser {
    public documentid!: string;
    public user!: string;
    public dateadded!: Date;
    public modified!: boolean;
    public isowner?: boolean;
}

export class SharedDocumentUser {
    public documentId: string;
    public projectId: string;
    public sharedWithUsers!: string[];
    public dateAdded!: Date;
    public sharedByUserId?: string;
}

export class Document {
    public id?: string;
    public projectId?: string;
    public name?: string;
    public changeDate?: Date;
    public createDate?: Date;
    public owner = false;
    public users: DocumentUser[] = [];

    constructor(document?: IDocumentConstructor) {
        if (document != null) {
            this.id = document.id;
            this.name = document.name;
            this.projectId = document.projectId;
            this.changeDate = document.changeDate;
            this.createDate = document.createDate;
            this.owner = document.owner ?? false;
            this.users = [];
        }
    }

    public async loadUsers(document: DocumentService): Promise<void> {
        this.users = await document.getUsersOnDocumentById(this.id);
    }

}

export interface DocumentPatchRequestModel {
    DocumentIds: string[];
}