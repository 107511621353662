import { IIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';

// import sprites info and images
import mainSpriteInfo from '../../bundles/main.json';
import mainImage from '../../bundles/main.png';

// sprite types
export type MainSprite = keyof typeof mainSpriteInfo;

// add more sprites with |
export type Sprite = MainSprite;

export type SpriteClass = Sprite;

interface SpriteProperties {
    imageUrl: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    spriteInfo: any
}

// add more sprites with ...
const spritesInfo = {
    ...mainSpriteInfo
};

const imagesUrl: Record<string, string> = {
    'main.png': mainImage
};

const imagePrefix = '';
const spriteInfoImagePrefix = '~src/bundles/';

export function getSpriteAsIconStyle(image: Sprite): IIconStyle;
export function getSpriteAsIconStyle(image: string): IIconStyle;
export function getSpriteAsIconStyle(image: Sprite | string): IIconStyle {
    const spriteProperties = getSpriteProperties(image);
    if (spriteProperties == null) {
        return {};
    }

    return {
        'width': spriteProperties.spriteInfo.px.width,
        'min-width': spriteProperties.spriteInfo.px.width,
        'height': spriteProperties.spriteInfo.px.height,
        'background-image': `url(${imagePrefix}${spriteProperties.imageUrl})`,
        'background-position': `${spriteProperties.spriteInfo.px.offset_x} ${spriteProperties.spriteInfo.px.offset_y}`
    };
}

export function getSpriteAsIconStyleResponsive(image: Sprite): IIconStyle;
export function getSpriteAsIconStyleResponsive(image: string): IIconStyle;
export function getSpriteAsIconStyleResponsive(image: Sprite | string): IIconStyle {
    const spriteProperties = getSpriteProperties(image);
    if (spriteProperties == null) {
        return {};
    }

    const x = ((spriteProperties.spriteInfo.x * 100) / (spriteProperties.spriteInfo.total_width - spriteProperties.spriteInfo.width));
    const y = ((spriteProperties.spriteInfo.y * 100) / (spriteProperties.spriteInfo.total_height - spriteProperties.spriteInfo.height));
    const bgWidth = spriteProperties.spriteInfo.total_width * 100 / spriteProperties.spriteInfo.width;
    const paddingBottom = spriteProperties.spriteInfo.height * 100 / spriteProperties.spriteInfo.width;
    const width = spriteProperties.spriteInfo.total_width * 100 / spriteProperties.spriteInfo.width;

    return {
        'background-image': `url(${imagePrefix}${spriteProperties.imageUrl})`,
        'background-position': `${x}% ${y}%`,
        'background-size': `${bgWidth}% auto`,
        'width': `${width}%`,
        'max-width': '100%',
        'height': '0',
        'padding-bottom': `${paddingBottom}%`,
        'display': 'block'
    };
}

export function getCSSFromIconStyle(style: IIconStyle): string {
    if (style == null) {
        return undefined;
    }
    let retVal = '';

    retVal += getCssPropertyFromIconStyle('background-image', style);
    retVal += getCssPropertyFromIconStyle('background-position', style);
    retVal += getCssPropertyFromIconStyle('background-size', style);
    retVal += getCssPropertyFromIconStyle('width', style);
    retVal += getCssPropertyFromIconStyle('min-width', style);
    retVal += getCssPropertyFromIconStyle('max-width', style);
    retVal += getCssPropertyFromIconStyle('height', style);
    retVal += getCssPropertyFromIconStyle('padding-bottom', style);
    retVal += getCssPropertyFromIconStyle('display', style);

    if (!retVal) {
        return undefined;
    }

    return retVal;
}

function getCssPropertyFromIconStyle(property: string, style: IIconStyle) {
    if (style[property]) {
        return `${property}: ${style[property]};`;
    }

    return '';
}

function getSpriteProperties(image: Sprite): SpriteProperties | undefined;
function getSpriteProperties(image: string): SpriteProperties | undefined;
function getSpriteProperties(image: Sprite | string): SpriteProperties | undefined {
    if (!(image in spritesInfo)) {
        console.warn(`Sprite with name '${image}' does not exist (missing json)`);
        return undefined;
    }

    const spriteInfo = spritesInfo[image as Sprite];
    if (!spriteInfo.image.startsWith(spriteInfoImagePrefix)) {
        console.warn(`Sprite with name '${image}' has invalid image name`);
        return undefined;
    }

    const imageName = spriteInfo.image.substring(spriteInfoImagePrefix.length);
    const imageUrl = imagesUrl[imageName];

    if (!imageUrl) {
        console.warn(`Sprite with name '${image}' does not exist (missing image)`);
        return undefined;
    }

    return {
        imageUrl: imageUrl,
        spriteInfo: spriteInfo
    };
}
