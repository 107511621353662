import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const errorGuard: CanActivateFn = () => {
    const router = inject(Router);

    // do not wrap this method with handleGuardAction or you will get an infinite loop
    // handleGuardAction => error => ErrorGuard => handleGuardAction => error => ErrorGuard => handleGuardAction => ...

    // the error route should only be shown on other urls
    // /error route will be redirected to /
    // use router.navigateByUrl('/error', { skipLocationChange: true }) to show the error page without changing the url
    if (!router.getCurrentNavigation().extras.skipLocationChange) {
        return router.parseUrl('/');
    }

    return true;
};
