<div class="modal-header">
    <h3 class="modal-title">PE Launch Darkly Flag Details</h3>

    <button id="flag-detailes-close-button" type="button" class="close" (click)="close()">
        <span>&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="text">Status of the feature flag for {{userName()}}</div>
    <div class="flags">
        <table class="table-flags">
            <caption>Flag name</caption>
            <thead>
                <tr class="table-flags-row-header">
                    <th>Flag</th>
                    <th>Status</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody #container>
                <tr *ngFor="let flag of flagslist" class="table-flags-row">
                    <td>{{flag.key}}</td>
                    <td>{{printLaunchDarklyFlagValue(flag.value)}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
