import * as React from 'react';
import { ControlHeader } from '../../ControlHeader';
import { isControlHidden } from '../../MainMenuHelper';
import { ImageNameSelection } from '../ImageNameSelection/ImageNameSelection';
import { IImageNameSelectionGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/image-name-selection-group-props';

export class ImageNameSelectionGroup extends React.PureComponent<IImageNameSelectionGroupProps> {
    private readonly radioItemClassName = 'react-image-name-selection-control';

    constructor(props?: IImageNameSelectionGroupProps) {
        super(props);
        this.onItemCheck = this.onItemCheck.bind(this);
    }

    public override render() {
        if (isControlHidden(this.props)) {
            return null;
        }

        const groupId = `${this.props.controlId}-group`;

        return (
            <div
                data-control-id={groupId}
                className={`control ${this.radioItemClassName}-group ${this.props.sizeClass}`} >

                <div className='control-container'>
                    <ControlHeader
                        controlId={groupId}
                        text={this.props.title}
                        tooltip={this.props.tooltip}
                        tooltipTitle={this.props.tooltipTitle}
                        localization={this.props.localization}
                    />
                </div>
                <div
                    data-control-id={`${groupId}-radio-buttons`}
                    className='radio-buttons'>
                    {
                        this.props.items.map((item) =>
                            <ImageNameSelection
                                controlId={`${this.props.controlId}-${item.value}`}
                                className={this.radioItemClassName}
                                key={`${this.props.controlId}-${item.value}`}
                                value={item.value}
                                text={item.text}
                                image={item.image}
                                imageStyle={item.imageStyle}
                                checked={item.value == this.props.checkedValue}
                                disabled={this.props.disabled}
                                hidden={this.props.hidden}
                                localization={this.props.localization}
                                size={null}
                                title={null}
                                type={ImageNameSelection}
                                checkedChanged={this.onItemCheck}
                                tooltip={item.tooltip}
                                tooltipTitle={item.tooltipTitle}
                            />)
                    }
                </div>
            </div>
        );
    }

    private onItemCheck(checked: boolean, value: number) {
        if (this.props.valueChanged != null && checked) {
            this.props.valueChanged(value);
        }
    }
}
