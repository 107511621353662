<div class="modal-header">
    <h3 class="modal-title" l10n="Agito.Hilti.Profis3.ReportTemplates.Title"></h3>

    <button id="report-templates-close-button" type="button" class="close" (click)="close()" [disabled]="submitted">
        <span>&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="modal-body-content">
        <div class="navigation navigation-report-template box-section">
            <pe-section
                [look]="collapseSectionBoxLook"
                [title]="templatesTranslation"
                [collapseTooltip]="'Agito.Hilti.Profis3.Main.Region.ShowHide' | l10n:localization.selectedLanguage"
                [collapsed]="leftSideNavigationCollapsed"
                (collapsedChange)="onLeftSideNavigationSectionCollapsedChange($any($event).detail)">
                <div class="left-side-content">
                    <div class="new-template"
                        [class.edit-template]="editTemplateType != null && editTemplateType != editTemplateTypeEnum.none">
                        <div class="tooltip-wrapper" [ngbTooltip]="reportTemplatesDisabledTooltip()">
                            <button id="report-templates-new-template-button"
                                class="new-template-button button button-default" (click)="newTemplate()"
                                l10n="Agito.Hilti.Profis3.ReportTemplates.Templates.NewTemplate" type="button"
                                [disabled]="newTemplateDisabled"></button>
                        </div>

                        <div class="new-template-edit">
                            <div class="edit-template-input">
                                <pe-text-box
                                    id="report-templates-new-template-textfield"
                                    [value]="editTemplateName"
                                    (valueChange)="editTemplateName = $any($event).detail"
                                    (inputBlur)="editTemplateInputBlur()"
                                    [maxLength]="nameMaxLength"
                                    [marginBottom]="'None'">
                                </pe-text-box>
                            </div>

                            <div class="edit-template-button-container">
                                <button
                                    id="report-templates-new-template-ok-button"
                                    class="edit-template-navigation-button edit-template-ok-button button button-default"
                                    (click)="$event.stopPropagation(); editTemplateConfirm()">
                                    <div class="sprite sprite-ok"></div>
                                </button>

                                <button
                                    id="report-templates-new-template-cancel-button"
                                    class="edit-template-navigation-button edit-template-cancel-button button button-default"
                                    (click)="$event.stopPropagation(); editTemplateReject()">
                                    <div class="sprite sprite-x"></div>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="templates">
                        <div *ngFor="let template of sortedTemplates; trackBy:trackTemplateByDisplayId"
                            [hidden]="!templatePagination.show[template.displayId]"
                            class="template-container">
                            <div id="report-templates-template-{{template.displayId}}"
                                *ngIf="template.editTemplateType != editTemplateTypeEnum.rename"
                                tabindex="0"
                                class="template"
                                [class.selected]="selectedTemplate.displayId == template.displayId"
                                (click)="selectTemplate(template)"
                                (keypress)="$event.keyCode == 13 || $event.keyCode == 32 ? selectTemplate(template) : undefined"
                                [attr.disabled]="selectTemplateDisabled ? true : null">
                                <span class="selector"></span>
                                <span class="sprite icon sprite-template"></span>
                                <span class="text">{{template.name}}</span>
                                <span class="space"></span>

                                <div class="template-options dropdown-menu-container" ngbDropdown>
                                    <button
                                        id="report-templates-template-{{template.displayId}}-dropdown-button"
                                        type="button"
                                        class="template-options-button dropdown-menu-button"
                                        ngbDropdownToggle>
                                        <span class="sprite dots"></span>
                                    </button>

                                    <div ngbDropdownMenu class="report-template-dropdown-menu">
                                        <button
                                            *ngIf="!template.isTemplate"
                                            id="report-templates-template-{{template.displayId}}-rename-button"
                                            class="dropdown-item-button rename"
                                            (click)="renameTemplate(template)"
                                            l10n="Agito.Hilti.Profis3.ReportTemplates.Templates.Dropdown.Rename"
                                            type="button"
                                            [disabled]="templateControlsDisabled()"
                                            ngbDropdownItem>
                                        </button>

                                        <button
                                            id="report-templates-template-{{template.displayId}}-duplicate-button"
                                            class="dropdown-item-button duplicate"
                                            (click)="duplicateTemplate(template)"
                                            l10n="Agito.Hilti.Profis3.ReportTemplates.Templates.Dropdown.Duplicate"
                                            type="button"
                                            [disabled]="duplicateTemplateDisabled"
                                            [ngbTooltip]="reportTemplatesDisabledTooltip()"
                                            ngbDropdownItem>
                                        </button>

                                        <button
                                            *ngIf="!template.isTemplate"
                                            id="report-templates-template-{{template.displayId}}-delete-button"
                                            class="dropdown-item-button delete"
                                            (click)="deleteTemplate(template)"
                                            l10n="Agito.Hilti.Profis3.ReportTemplates.Templates.Dropdown.Delete"
                                            type="button"
                                            [disabled]="templateControlsDisabled()"
                                            ngbDropdownItem>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="template.editTemplateType == editTemplateTypeEnum.rename"
                                id="report-templates-rename-template-{{template.displayId}}"
                                class="rename-template"
                                [class.edit-template]="editTemplateType != null && editTemplateType != editTemplateTypeEnum.none">
                                <div class="rename-template-input">
                                    <pe-text-box
                                        class="rename-template-textfield"
                                        [id]="'report-templates-rename-template-textfield-' + template.displayId"
                                        [value]="editTemplateName"
                                        (valueChange)="editTemplateName = $any($event).detail"
                                        (inputBlur)="renameTemplateInputBlur()"
                                        [maxLength]="nameMaxLength"
                                        [marginBottom]="'None'">
                                    </pe-text-box>
                                </div>

                                <div class="rename-template-button-container">
                                    <button
                                        id="report-templates-rename-template-ok-button"
                                        class="rename-template-navigation-button rename-template-ok-button button button-default"
                                        (click)="$event.stopPropagation(); renameTemplateConfirm()">
                                        <div class="sprite sprite-ok"></div>
                                    </button>

                                    <button
                                        id="report-templates-rename-template-cancel-button"
                                        class="rename-template-navigation-button rename-template-cancel-button button button-default"
                                        (click)="$event.stopPropagation(); renameTemplateReject()">
                                        <div class="sprite sprite-x"></div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="template-pagination" *ngIf="showPagination">
                        <button class="button button-default button-icon template-pagination-down"
                            (mousedown)="paginationStartMoveContinously(paginationDirectionEnum.down)"
                            (mouseup)="paginationStopMoveContinously()"
                            (mouseleave)="paginationStopMoveContinously()"
                            (blur)="paginationStopMoveContinously()"
                            (click)="paginationMove(paginationDirectionEnum.down)"
                            [disabled]="disabledPagination(paginationDirectionEnum.down)"
                            [ngbTooltip]="'Agito.Hilti.Profis3.ReportTemplates.Navigation.Templates.MoveDown' | l10n:localization.selectedLanguage">
                            <div class="sprite"></div>
                        </button>

                        <button class="button button-default button-icon template-pagination-up"
                            (mousedown)="paginationStartMoveContinously(paginationDirectionEnum.up)"
                            (mouseup)="paginationStopMoveContinously()"
                            (mouseleave)="paginationStopMoveContinously()"
                            (blur)="paginationStopMoveContinously()"
                            (click)="paginationMove(paginationDirectionEnum.up)"
                            [disabled]="disabledPagination(paginationDirectionEnum.up)"
                            [ngbTooltip]="'Agito.Hilti.Profis3.ReportTemplates.Navigation.Templates.MoveUp' | l10n:localization.selectedLanguage">
                            <div class="sprite"></div>
                        </button>
                    </div>
                </div>
            </pe-section>
        </div>

        <div class="main">
            <form class="form-report-templates" name="form" #form="ngForm" novalidate [hidden]="selectedTemplate == null">
                <div class="company-details main-container">
                    <p class="title-strike" l10n="Agito.Hilti.Profis3.ReportTemplates.CompanyDetails"></p>

                    <div class="main-content">
                        <div class="main-content-container">
                            <pe-text-box
                                id="report-templates-company"
                                [title]="'Agito.Hilti.Profis3.ReportTemplates.Company' | l10n:localization.selectedLanguage"
                                [maxLength]="companyMaxLength"
                                [placeholder]="'Agito.Hilti.Profis3.ReportTemplates.Company.Placeholder' | l10n:localization.selectedLanguage"
                                [value]="companyTextBox.value"
                                (valueChange)="companyTextBox.value = $any($event).detail"
                                [disabled]="user.isExternalOnlineRussianUser || templateControlsDisabled(true)"
                                [tooltip]="reportTemplatesDisabledTooltip(true)">
                            </pe-text-box>

                            <pe-text-box
                                id="report-templates-address"
                                [title]="'Agito.Hilti.Profis3.ReportTemplates.Address' | l10n:localization.selectedLanguage"
                                [maxLength]="addressMaxLength"
                                [placeholder]="'Agito.Hilti.Profis3.ReportTemplates.Address.Placeholder'| l10n:localization.selectedLanguage"
                                [value]="addressTextBox.value"
                                (valueChange)="addressTextBox.value = $any($event).detail"
                                [disabled]="user.isExternalOnlineRussianUser || templateControlsDisabled(true)"
                                [tooltip]="reportTemplatesDisabledTooltip(true)">
                            </pe-text-box>

                            <pe-text-box
                                id="report-templates-email"
                                [title]="'Agito.Hilti.Profis3.ReportTemplates.Email' | l10n:localization.selectedLanguage"
                                [maxLength]="emailMaxLength"
                                [placeholder]="'Agito.Hilti.Profis3.ReportTemplates.Email.Placeholder' | l10n:localization.selectedLanguage"
                                [value]="emailTextBox.value"
                                (valueChange)="emailTextBox.value = $any($event).detail"
                                [validators]="emailValidator"
                                (isValid)="emailTextBox.isValid = $any($event).detail"
                                [disabled]="user.isExternalOnlineRussianUser || templateControlsDisabled(true)"
                                [tooltip]="reportTemplatesDisabledTooltip(true)">
                            </pe-text-box>
                        </div>

                        <div class="main-content-container">
                            <pe-text-box
                                id="report-templates-contact-person"
                                [title]="'Agito.Hilti.Profis3.ReportTemplates.ContactPerson' | l10n:localization.selectedLanguage"
                                [maxLength]="contactPersonMaxLength"
                                [placeholder]="'Agito.Hilti.Profis3.ReportTemplates.ContactPerson.Placeholder' | l10n:localization.selectedLanguage"
                                [value]="contactPersonTextBox.value"
                                (valueChange)="contactPersonTextBox.value = $any($event).detail"
                                [disabled]="user.isExternalOnlineRussianUser || templateControlsDisabled(true)"
                                [tooltip]="reportTemplatesDisabledTooltip(true)">
                            </pe-text-box>

                            <pe-text-box
                                id="report-templates-phone-number"
                                [title]="'Agito.Hilti.Profis3.ReportTemplates.PhoneNumber' | l10n:localization.selectedLanguage"
                                [maxLength]="phoneNumberMaxLength"
                                [placeholder]="'Agito.Hilti.Profis3.ReportTemplates.PhoneNumber.Placeholder' | l10n:localization.selectedLanguage"
                                [value]="phoneNumberTextBox.value"
                                (valueChange)="phoneNumberTextBox.value = $any($event).detail"
                                [disabled]="user.isExternalOnlineRussianUser || templateControlsDisabled(true)"
                                [tooltip]="reportTemplatesDisabledTooltip(true)">
                            </pe-text-box>

                            <pe-text-box
                                id="report-templates-fax-number"
                                [title]="'Agito.Hilti.Profis3.ReportTemplates.FaxNumber' | l10n:localization.selectedLanguage"
                                [maxLength]="faxNumberMaxLength"
                                [placeholder]="'Agito.Hilti.Profis3.ReportTemplates.FaxNumber.Placeholder' | l10n:localization.selectedLanguage"
                                [value]="faxNumberTextBox.value"
                                (valueChange)="faxNumberTextBox.value = $any($event).detail"
                                [disabled]="user.isExternalOnlineRussianUser || templateControlsDisabled(true)"
                                [tooltip]="reportTemplatesDisabledTooltip(true)">
                            </pe-text-box>
                        </div>
                    </div>

                    <div class="sap-account-data-disclaimer">
                        <p *ngIf="user.isExternalOnlineRussianUser" [innerHtml]="sapAccountDisclaimer"></p>
                    </div>
                </div>

                <div class="branding main-container">
                    <p class="title-strike" l10n="Agito.Hilti.Profis3.ReportTemplates.Branding"></p>

                    <div class="main-content">
                        <div class="main-content-container">
                            <div class="row-control">
                                <p class="control-label" l10n="Agito.Hilti.Profis3.ReportTemplates.Logo"></p>

                                <div class="logo-container">
                                    <div class="logo-image">
                                        <div class="logo-image-img-wrapper">
                                            <div *ngIf="logoUrl" class="logo-image-btn-remove"
                                                (click)="removeLogoFile()"
                                                [attr.disabled]="templateControlsDisabled(true) ? true : null"
                                                [ngbTooltip]="'Agito.Hilti.Profis3.ReportTemplates.RemoveLogoTooltip' | l10n:localization.selectedLanguage">
                                            </div>
                                            <img *ngIf="logoUrl" [src]="sanitize(logoUrl)" alt="logo" />
                                        </div>
                                    </div>
                                    <div class="logo-upload">
                                        <div class="tooltip-wrapper"
                                            [ngbTooltip]="reportTemplatesDisabledTooltip()">
                                            <button
                                                id="report-templates-logo-upload-button"
                                                type="button"
                                                class="logo-upload-button button button-default"
                                                (click)="selectLogoFile()"
                                                [disabled]="templateControlsDisabled()"
                                                l10n="Agito.Hilti.Profis3.ReportTemplates.UploadLogo">
                                            </button>
                                        </div>

                                        <input
                                            id="report-templates-logo-upload-input"
                                            type="file"
                                            class="logo-upload-input"
                                            (change)="logoFileSelected()"
                                            name="report-templates-logo-upload-input"
                                            accept=".jpg,.png" />

                                        <div class="logo-upload-description"
                                            l10n="Agito.Hilti.Profis3.ReportTemplates.UploadLogoDescription"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="report-layout main-container">
                    <p class="title-strike" l10n="Agito.Hilti.Profis3.ReportTemplates.ReportLayout"></p>

                    <div class="main-content">
                        <div class="main-content-container">
                            <div class="paper-size row-control">
                                <div class="control-item">
                                    <pe-radio-button
                                        [title]="'Agito.Hilti.Profis3.ReportTemplates.PaperSize' | l10n:localization.selectedLanguage"
                                        [items]="paperSizesRadio.items"
                                        [selectedValue]="paperSizesRadio.selectedValue"
                                        (selectedValueChange)="paperSizesRadio.selectedValue = $any($event).detail"
                                        [disabled]="templateControlsDisabled(true)">
                                    </pe-radio-button>
                                </div>
                            </div>
                        </div>
                        <div class="main-content-container">
                            <div class="control-item">
                                <pe-radio-button
                                    [title]="'Agito.Hilti.Profis3.ReportTemplates.ReportType' | l10n:localization.selectedLanguage"
                                    [items]="reportTypesRadio.items"
                                    [selectedValue]="reportTypesRadio.selectedValue"
                                    (selectedValueChange)="reportTypesRadio.selectedValue = $any($event).detail"
                                    [disabled]="templateControlsDisabled(true)">
                                </pe-radio-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="header-and-footer main-container">
                    <p class="title-strike" l10n="Agito.Hilti.Profis3.ReportTemplates.HeaderAndFooter"></p>

                    <div class="main-content">
                        <div class="main-content-container">
                            <div class="header-section row-control">
                                <div class="control-item">
                                    <pe-checkbox-button
                                        [title]="'Agito.Hilti.Profis3.ReportTemplates.Header' | l10n:localization.selectedLanguage"
                                        [items]="reportHeaderElementsCheckbox.items"
                                        [selectedValues]="reportHeaderElementsCheckbox.selectedValues"
                                        (selectedValuesChange)="reportHeaderElementsCheckbox.selectedValues = $any($event).detail"
                                        [disabled]="templateControlsDisabled()">
                                    </pe-checkbox-button>
                                </div>

                                <label class="control-item"
                                    *ngIf="reportHeaderElementsCheckbox.selectedValues.has(reportHeaderElementEnum.HeaderCustomText)">
                                    <div class="custom-text">
                                        <pe-text-box
                                            id="report-templates-header-text-box-custom-text-value"
                                            [maxLength]="255"
                                            [value]="headerCustomTextValue"
                                            (valueChange)="headerCustomTextValue = $any($event).detail"
                                            [disabled]="templateControlsDisabled()"
                                            [tooltip]="reportTemplatesDisabledTooltip()">
                                        </pe-text-box>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="main-content-container">
                            <div class="footer-section row-control">
                                <div class="control-item">
                                    <pe-checkbox-button
                                        [title]="'Agito.Hilti.Profis3.ReportTemplates.Footer' | l10n:localization.selectedLanguage"
                                        [items]="reportFooterElementsCheckbox.items"
                                        [selectedValues]="reportFooterElementsCheckbox.selectedValues"
                                        (selectedValuesChange)="reportFooterElementsCheckbox.selectedValues = $any($event).detail"
                                        [disabled]="templateControlsDisabled()">
                                    </pe-checkbox-button>
                                </div>

                                <label class="control-item"
                                    *ngIf="reportFooterElementsCheckbox.selectedValues.has(reportFooterElementEnum.FooterCustomText)">
                                    <div class="custom-text">
                                        <pe-text-box
                                            id="report-templates-footer-text-box-custom-text-value"
                                            [maxLength]="255"
                                            [value]="footerCustomTextValue"
                                            (valueChange)="footerCustomTextValue = $any($event).detail"
                                            [disabled]="templateControlsDisabled()"
                                            [tooltip]="reportTemplatesDisabledTooltip()">
                                        </pe-text-box>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button type="button" id="report-templates-cancel-button" class="modal-button button button-default"
        l10n="Agito.Hilti.Profis3.ReportTemplates.Cancel" (click)="close()" [disabled]="submitted">
    </button>

    <button id="report-templates-save-button" type="button" class="modal-button button button-CTA"
        (click)="save()" [disabled]="!isFormValid || submitted">
        <pe-loading-button
            [loading]="submitted"
            [text]="'Agito.Hilti.Profis3.ReportTemplates.Save' | l10n:localization.selectedLanguage">
        </pe-loading-button>
    </button>
</div>
