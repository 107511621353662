<div class="dropdown-wrapper"
    [class.hilti-styled]="isHiltiStyled">
    <pe-control-title
        class="control-title"
        [focusControlId]="id"
        [title]="title"
        [look]="controlTitleStyle"
        [tooltip]="tooltip"
        [infoPopup]="infoPopup"
        [infoPopupTooltip]="infoPopupTooltip">
    </pe-control-title>

    <div [id]="id" class="control-container"
        title="{{text}}"
        #controlContainer
        (keypress)="onKeyPress($event)"
        (keydown)="onKeyDown($event)">
        <button
            [id]="id + '-button'"
            class="dropdown-button"
            [class.not-selected]="selectedValue == null"
            [class.submitted]="submitted"
            #dropdownButton
            type="button"
            (click)="onClick()"
            [name]="id"
            [(ngModel)]="selectedValue"
            #ngModel="ngModel"
            ngDefaultControl
            [disabled]="disabled">
            <div class="dropdown-button-container">
                <div class="button-item item">
                    <span *ngIf="image" class="image sprite sprite-responsive" [ngStyle]="image"></span>

                    <p class="text" [innerHtml]="text"></p>

                    <span *ngFor="let tag of tags"
                        class="tag" [class.new]="tag.type == 'New'" [ngbTooltip]="ngbTooltipTemplate(tag.tooltip, tagTooltipTemplate)">
                        {{tag.text}}
                        <ng-template #tagTooltipTemplate>
                            <pe-content-tooltip [tooltip]="tag.tooltip"></pe-content-tooltip>
                        </ng-template>
                    </span>
                </div>

                <span class="space"></span>

                <div class="caret-container">
                    <div class="caret" [class.caret-down]="!isOpen" [class.caret-up]="isOpen"></div>
                </div>
            </div>
        </button>

        <div class="dropdown-items" #dropdownItems
            [class.opend]="isOpen" [class.openUp]="openUp">
            <button *ngFor="let item of items" type="button" class="dropdown-item"
                [class.selected]="itemSelected(item)"
                [attr.id]="item.id ? item.id : null"
                [disabled]="item.disabled == true"
                (mousedown)="$event.preventDefault()" (click)="onItemClick(item)">
                <div class="item"
                    [class.special]="item.isSpecial"
                    [ngbTooltip]="ngbTooltipTemplate(item.tooltip, itemTooltipTemplate)"
                    [ngClass]="item.level != null && item.level > 0 ? 'item-level-' + item.level : ''">
                    <ng-template #itemTooltipTemplate>
                        <pe-content-tooltip [tooltip]="item.tooltip"></pe-content-tooltip>
                    </ng-template>
                    <span *ngIf="item.image" class="image sprite sprite-responsive" [ngStyle]="item.image"></span>

                    <p class="text" *ngIf="item.text">{{item.text}}</p>

                    <span *ngFor="let tag of item.tags"
                        class="tag" [class.new]="tag.type == 'New'" [ngbTooltip]="ngbTooltipTemplate(tag.tooltip, tagTooltipTemplate)">
                        {{tag.text}}
                        <ng-template #tagTooltipTemplate>
                            <pe-content-tooltip [tooltip]="tag.tooltip"></pe-content-tooltip>
                        </ng-template>
                    </span>
                </div>
            </button>
        </div>
    </div>

    <pe-validation-error
        *ngIf="showValidationErrors"
        class="validation-error"
        [ngErrors]="ngModel?.control?.errors">
    </pe-validation-error>
</div>
