import { Component, Input, OnChanges } from '@angular/core';
import {
    RadioButtonProps
} from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import {
    Separator as SeparatorEntity
} from '@profis-engineering/pe-ui-common/entities/code-lists/separator';
import {
    KnownRegion
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { CalculationMethodState, IAdvancedBaseplateCalculationSettings } from '../../helpers/app-settings-helper';

import { LocalizationService } from '../../services/localization.service';
import { ModulesService } from '../../services/modules.service';

@Component({
    selector: 'app-app-settings-advanced-baseplate-calculation',
    templateUrl: './app-settings-advanced-baseplate-calculation.component.html',
    styleUrls: ['./app-settings-advanced-baseplate-calculation.component.scss']
})
export class AppSettingsAdvancedBaseplateCalculationComponent implements OnChanges {
    @Input()
    public advancedBaseplateCalculationValues!: IAdvancedBaseplateCalculationSettings;

    @Input()
    public submitted = false;

    @Input()
    public decimalSeparator!: SeparatorEntity;

    @Input()
    public groupSeparator!: SeparatorEntity;

    public calculationMethodState!: RadioButtonProps<CalculationMethodState>;
    public dataLoaded = false;

    constructor(
        private localization: LocalizationService,
        private modulesService: ModulesService
    ) { }

    public get canUseBPRigidityCheck() {
        return this.modulesService.isRigidityCheckPossible(
            this.advancedBaseplateCalculationValues.advancedBaseplateInputs.designStandardId ?? 0,
            this.advancedBaseplateCalculationValues.advancedBaseplateInputs.selectedRegionId ?? KnownRegion.Unknown
        );
    }

    ngOnChanges(): void {
        if (!this.dataLoaded) {
            this.calculationMethodState = {
                id: 'default-calculation-method',
                title: this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.DefaultCalculationChoice.Title'),
                items: [
                    {
                        text: this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.AlwaysAskMe'),
                        value: CalculationMethodState.AlwaysAskMe,
                        id: `default-calculation-method-${CalculationMethodState.AlwaysAskMe}`
                    },
                    {
                        text: this.canUseBPRigidityCheck ?
                            this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.WithAdvancedBaseplate.BPRigidityCheck') :
                            this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.WithAdvancedBaseplate'),
                        value: CalculationMethodState.WithAdvancedBaseplate,
                        id: `default-calculation-method-${CalculationMethodState.WithAdvancedBaseplate}`
                    },
                    {
                        text: this.canUseBPRigidityCheck ?
                            this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.WithoutAdvancedBaseplate.BPRigidityCheck') :
                            this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.WithoutAdvancedBaseplate'),
                        value: CalculationMethodState.WithoutAdvancedBaseplate,
                        id: `default-calculation-method-${CalculationMethodState.WithoutAdvancedBaseplate}`
                    }
                ],
                selectedValue: this.advancedBaseplateCalculationValues?.calculationMethodState
            };

            this.dataLoaded = true;
        }
    }
}
