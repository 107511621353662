import cloneDeep from 'lodash-es/cloneDeep';
import escape from 'lodash-es/escape';

import { HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    sanitizePropertyValueForJson
} from '@profis-engineering/pe-ui-c2c/helpers/ui-property-helpers';
import {
    getCodeListTextDeps, ICodeLists
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    CalculationType, Design as DesignCommon, DesignEvent, StateChange
} from '@profis-engineering/pe-ui-common/entities/design';
import {
    IModalGridComponentInput, IModalGridItem
} from '@profis-engineering/pe-ui-common/entities/modal-grid';
import { Project } from '@profis-engineering/pe-ui-common/entities/project';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import {
    getNumberDecimalSeparator, getNumberGroupSeparator
} from '@profis-engineering/pe-ui-common/helpers/localization-helper';
import {
    MODAL_DISMISS_REASON_BACKDROP, MODAL_DISMISS_REASON_ESC
} from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { Change } from '@profis-engineering/pe-ui-common/services/changes.common';
import { DocumentAccessMode, IDesignListItem as IDesignListItemCommon } from '@profis-engineering/pe-ui-common/services/document.common';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';
import {
    AnchorEmbedmentDepth
} from '@profis-engineering/pe-ui-shared/entities/code-lists/anchor-embedment-depth';
import { AnchorFamily } from '@profis-engineering/pe-ui-shared/entities/code-lists/anchor-family';
import { AnchorFilter } from '@profis-engineering/pe-ui-shared/entities/code-lists/anchor-filter';
import {
    AnchorFilterGroup
} from '@profis-engineering/pe-ui-shared/entities/code-lists/anchor-filter-group';
import {
    AnchorLayout as AnchorLayoutEntity
} from '@profis-engineering/pe-ui-shared/entities/code-lists/anchor-layout';
import {
    AnchorLayoutSymmetry as AnchorLayoutSymmetryEntity
} from '@profis-engineering/pe-ui-shared/entities/code-lists/anchor-layout-symmetry';
import {
    AnchorPositionsAsadClass
} from '@profis-engineering/pe-ui-shared/entities/code-lists/anchor-position-asad';
import { AnchorSize } from '@profis-engineering/pe-ui-shared/entities/code-lists/anchor-size';
import {
    AnchorTorqueType
} from '@profis-engineering/pe-ui-shared/entities/code-lists/anchor-torque-type';
import {
    AnchorType as AnchorTypeEntity
} from '@profis-engineering/pe-ui-shared/entities/code-lists/anchor-type';
import { BeamType } from '@profis-engineering/pe-ui-shared/entities/code-lists/beam-type';
import { BrickGroup } from '@profis-engineering/pe-ui-shared/entities/code-lists/brick-group';
import { BrickLayout } from '@profis-engineering/pe-ui-shared/entities/code-lists/brick-layout';
import {
    BrickSize as BrickSizeCodelist
} from '@profis-engineering/pe-ui-shared/entities/code-lists/brick-size';
import { BrickStrength } from '@profis-engineering/pe-ui-shared/entities/code-lists/brick-strength';
import {
    BrickStrengthAc58
} from '@profis-engineering/pe-ui-shared/entities/code-lists/brick-strength-ac58';
import { BuildingArea } from '@profis-engineering/pe-ui-shared/entities/code-lists/building-area';
import {
    BuildingCategory
} from '@profis-engineering/pe-ui-shared/entities/code-lists/building-category';
import {
    CladdingProfile
} from '@profis-engineering/pe-ui-shared/entities/code-lists/cladding-profile';
import {
    CladdingType as CladdingTypeEntity
} from '@profis-engineering/pe-ui-shared/entities/code-lists/cladding-type';
import {
    CleaningMethod
} from '@profis-engineering/pe-ui-shared/entities/code-lists/cleaning-method';
import {
    ConcreteCharacteristic as ConcreteCharacteristicEntity
} from '@profis-engineering/pe-ui-shared/entities/code-lists/concrete-characteristic';
import { ConcreteGrade } from '@profis-engineering/pe-ui-shared/entities/code-lists/concrete-grade';
import {
    ConcreteReinforcement as ConcreteReinforcementEntity
} from '@profis-engineering/pe-ui-shared/entities/code-lists/concrete-reinforcement';
import {
    CoordinateSystemCenter
} from '@profis-engineering/pe-ui-shared/entities/code-lists/coordinate-system-center';
import {
    CustomLoadCategory
} from '@profis-engineering/pe-ui-shared/entities/code-lists/custom-load-category';
import { DeckThickness } from '@profis-engineering/pe-ui-shared/entities/code-lists/deck-thickness';
import {
    DesignMethodHNA as DesignMethodHNAEntity
} from '@profis-engineering/pe-ui-shared/entities/code-lists/design-method-HNA';
import {
    DistanceInsideTownTerrain
} from '@profis-engineering/pe-ui-shared/entities/code-lists/distance-inside-town-terrain';
import {
    DistanceUpwindToShoreline
} from '@profis-engineering/pe-ui-shared/entities/code-lists/distance-upwind-to-shoreline';
import {
    DrillingMethod as DrillingMethodEntity
} from '@profis-engineering/pe-ui-shared/entities/code-lists/drilling-method';
import {
    EdgeReinforcement
} from '@profis-engineering/pe-ui-shared/entities/code-lists/edge-reinforcement';
import {
    EmbedmentOptimizationType
} from '@profis-engineering/pe-ui-shared/entities/code-lists/embedment-optimization-type';
import { EmbedmentType } from '@profis-engineering/pe-ui-shared/entities/code-lists/embedment-type';
import {
    EnvironmentType
} from '@profis-engineering/pe-ui-shared/entities/code-lists/environment-type';
import {
    FasteningOption
} from '@profis-engineering/pe-ui-shared/entities/code-lists/fastening-option';
import {
    FatigueLoadType as FatigueLoadTypeEntity
} from '@profis-engineering/pe-ui-shared/entities/code-lists/fatigue-load-type';
import { FireDuration } from '@profis-engineering/pe-ui-shared/entities/code-lists/fire-duration';
import { FireExposure } from '@profis-engineering/pe-ui-shared/entities/code-lists/fire-exposure';
import { Grout } from '@profis-engineering/pe-ui-shared/entities/code-lists/grout';
import { GroutType } from '@profis-engineering/pe-ui-shared/entities/code-lists/grout-type';
import {
    HandrailApplicationType as HandrailApplicationTypeEntity
} from '@profis-engineering/pe-ui-shared/entities/code-lists/handrail-application-type';
import {
    HandrailConnectionType as HandrailConnectionTypeEntity
} from '@profis-engineering/pe-ui-shared/entities/code-lists/handrail-connection-type';
import {
    HandrailSafetyClass
} from '@profis-engineering/pe-ui-shared/entities/code-lists/handrail-safety-class';
import {
    HeadJointSelection
} from '@profis-engineering/pe-ui-shared/entities/code-lists/head-joint-selection';
import { HoleCondition } from '@profis-engineering/pe-ui-shared/entities/code-lists/hole-condition';
import { HoleType } from '@profis-engineering/pe-ui-shared/entities/code-lists/hole-type';
import {
    HorizontalPostProfileConnectionType
} from '@profis-engineering/pe-ui-shared/entities/code-lists/horizontal-post-profile-connection-type';
import {
    InstallationDirection
} from '@profis-engineering/pe-ui-shared/entities/code-lists/installation-direction';
import {
    InstallationType
} from '@profis-engineering/pe-ui-shared/entities/code-lists/installation-type';
import { JointMaterial } from '@profis-engineering/pe-ui-shared/entities/code-lists/joint-material';
import {
    LoadCombinationHNAEquation
} from '@profis-engineering/pe-ui-shared/entities/code-lists/load-combination-HNA-equation';
import {
    LoadCombinationHNAFactors
} from '@profis-engineering/pe-ui-shared/entities/code-lists/load-combination-HNA-factors';
import {
    LoadType as LoadTypeEntity
} from '@profis-engineering/pe-ui-shared/entities/code-lists/load-type';
import {
    MasonryAnchorPosition
} from '@profis-engineering/pe-ui-shared/entities/code-lists/masonry-anchor-position';
import {
    MasonryFastenerConfiguration
} from '@profis-engineering/pe-ui-shared/entities/code-lists/masonry-fastener-configuration';
import {
    MasonryUseCategory
} from '@profis-engineering/pe-ui-shared/entities/code-lists/masonry-use-category';
import { MetalDeck } from '@profis-engineering/pe-ui-shared/entities/code-lists/metal-deck';
import {
    MetalDeckAnchorPosition
} from '@profis-engineering/pe-ui-shared/entities/code-lists/metal-deck-anchor-position';
import {
    PlateShape as PlateShapeEntity
} from '@profis-engineering/pe-ui-shared/entities/code-lists/plate-shape';
import { ProfileFamily } from '@profis-engineering/pe-ui-shared/entities/code-lists/profile-family';
import {
    ProfilePositionsAsadClass
} from '@profis-engineering/pe-ui-shared/entities/code-lists/profile-position-asad';
import { ProfileSize } from '@profis-engineering/pe-ui-shared/entities/code-lists/profile-size';
import {
    ProfileStandard
} from '@profis-engineering/pe-ui-shared/entities/code-lists/profile-standard';
import {
    ReinforcementShearCondition
} from '@profis-engineering/pe-ui-shared/entities/code-lists/reinforcement-shear-condition';
import {
    ReinforcementTensionCondition
} from '@profis-engineering/pe-ui-shared/entities/code-lists/reinforcement-tension-condition';
import { SafetyLevel } from '@profis-engineering/pe-ui-shared/entities/code-lists/safety-level';
import {
    SeismicCategory
} from '@profis-engineering/pe-ui-shared/entities/code-lists/seismic-category';
import {
    SeismicDesignType
} from '@profis-engineering/pe-ui-shared/entities/code-lists/seismic-design-type';
import {
    SeismicDisplacementsType
} from '@profis-engineering/pe-ui-shared/entities/code-lists/seismic-displacements-type';
import {
    SeismicIntensityFactor
} from '@profis-engineering/pe-ui-shared/entities/code-lists/seismic-intensity-factor';
import {
    SeismicShearType as SeismicShearTypeEntity
} from '@profis-engineering/pe-ui-shared/entities/code-lists/seismic-shear-type';
import {
    SeismicTensionType as SeismicTensionTypeEntity
} from '@profis-engineering/pe-ui-shared/entities/code-lists/seismic-tension-type';
import { ShearLoadDistributionType } from '@profis-engineering/pe-ui-shared/entities/code-lists/shear-load-distribution-type';
import {
    SmartAnchorApplication
} from '@profis-engineering/pe-ui-shared/entities/code-lists/smart-anchor-application';
import {
    SmartAnchorCategory
} from '@profis-engineering/pe-ui-shared/entities/code-lists/smart-anchor-category';
import {
    SmartAnchorNeedsSolutionTextsAnchorGroup
} from '@profis-engineering/pe-ui-shared/entities/code-lists/smart-anchor-needs-solution-texts-anchor-group';
import { StandOff } from '@profis-engineering/pe-ui-shared/entities/code-lists/stand-off';
import {
    StandOffDesignMethod
} from '@profis-engineering/pe-ui-shared/entities/code-lists/stand-off-design-method';
import {
    StaticLoadType as StaticLoadTypeEntity
} from '@profis-engineering/pe-ui-shared/entities/code-lists/static-load-type';
import { SteelType } from '@profis-engineering/pe-ui-shared/entities/code-lists/steel-type';
import {
    StiffenerCustomProfileSides
} from '@profis-engineering/pe-ui-shared/entities/code-lists/stiffener-custom-profile-sides';
import {
    StiffenerLayout
} from '@profis-engineering/pe-ui-shared/entities/code-lists/stiffener-layout';
import {
    StiffenerLayoutPosition
} from '@profis-engineering/pe-ui-shared/entities/code-lists/stiffener-layout-position';
import {
    StiffenerShape
} from '@profis-engineering/pe-ui-shared/entities/code-lists/stiffener-shape';
import {
    SupplementaryReinforcementCategory
} from '@profis-engineering/pe-ui-shared/entities/code-lists/supplementary-reinforcement-category';
import {
    SupplementaryReinforcementDiameter
} from '@profis-engineering/pe-ui-shared/entities/code-lists/supplementary-reinforcement-diameter';
import {
    SupplementaryReinforcementDirectionOfCasting
} from '@profis-engineering/pe-ui-shared/entities/code-lists/supplementary-reinforcement-direction-of-casting';
import {
    SupplementaryReinforcementType
} from '@profis-engineering/pe-ui-shared/entities/code-lists/supplementary-reinforcement-type';
import {
    TerrainCategory
} from '@profis-engineering/pe-ui-shared/entities/code-lists/terrain-category';
import {
    TerrainRoughness
} from '@profis-engineering/pe-ui-shared/entities/code-lists/terrain-roughness';
import { TerrainType } from '@profis-engineering/pe-ui-shared/entities/code-lists/terrain-type';
import { TorquingType } from '@profis-engineering/pe-ui-shared/entities/code-lists/torquing-type';
import {
    WeldLocation as WeldLocationEntity
} from '@profis-engineering/pe-ui-shared/entities/code-lists/weld-location';
import {
    WeldingType as WeldingTypeEntity
} from '@profis-engineering/pe-ui-shared/entities/code-lists/welding-type';
import { WHGType } from '@profis-engineering/pe-ui-shared/entities/code-lists/whg-type';
import { WindLoadCity } from '@profis-engineering/pe-ui-shared/entities/code-lists/wind-load-city';
import {
    WindLoadState
} from '@profis-engineering/pe-ui-shared/entities/code-lists/wind-load-state';
import { WindVelocity } from '@profis-engineering/pe-ui-shared/entities/code-lists/wind-velocity';
import { WindZone } from '@profis-engineering/pe-ui-shared/entities/code-lists/wind-zone';
import { DesignCodeList } from '@profis-engineering/pe-ui-shared/entities/design-code-list';
import {
    DesignPe, ICalculateAllAnchorDetailed
} from '@profis-engineering/pe-ui-shared/entities/design-pe';
import {
    ProgressStatus
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Common';
import {
    EntityBase
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities';
import {
    CalculateDesignRequest, CalculationResultEntity as ICalculationResultEntity, ConfirmationEntity,
    ConfirmationEntity as IConfirmationEntity, ConfirmChangeDialogEntity,
    ConfirmChangeDialogEntity as IConfirmChangesEntity, CreateNewDesignFromProjectRequest,
    IdeaProjectFileRequest, IdeaProjectFileResponse, NewDesignDataEntity, ValidationErrorEntity
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation';
import {
    AllAnchorResultItem, DesignReportDataClientEntity,
    DesignReportDataClientEntity as IDesignReportDataClientEntity
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import {
    DesignCodeLists as IDesignCodeLists
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import {
    LoadCombination,
    StaticDesignData as IStaticDesignData, UIProperty as IUIProperty, UIPropertyValue
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    DesignLocationType,
    DialogType, ProjectOpenType
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display.Enums';
import {
    ProjectDesignBaseEntity
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    AdvancedCalculationType, AnchorLayout, ConcreteCharacteristic,
    DesignMethodGroup as DesignMethodGroupEnum, DesignMethodHNA,
    DesignStandard as DesignStandardEnum, DesignType as DesignTypeId, DrillingMethod,
    HandrailConnectionType, LoadCharacteristic, LoadCombinationsCalculationType, LoadType, PlateShape, SeismicShearType,
    SeismicTensionType
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    GenerateReportRequest, GenerateReportResponse
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Report';
import { DialogHelper } from '@profis-engineering/pe-ui-shared/helpers/dialog-helper';
import { ModalGridHelper } from '@profis-engineering/pe-ui-shared/helpers/modal-grid-helper';
import {
    PropertyMetaData, UIProperties
} from '@profis-engineering/pe-ui-shared/properties/properties';
import { environment } from '../../../environments/environment';
import { Design, IAllDesignDeps, IBaseDesign, ICalculationResult } from '../../entities/design';
import { DesignExternalMetaData } from '../../entities/design-external-meta-data';
import { getMetaDataFromDesign } from '../../helpers/bw-compat/design-external-meta-data.bw-compat';
import { LocalDocument } from '../../helpers/document-helper';
import { ApiService } from '../api.service';
import { BrowserService } from '../browser.service';
import { ChangesService } from '../changes.service';
import { CodeListService } from '../code-list.service';
import { CommonCodeListService } from '../common-code-list.service';
import { DateTimeService } from '../date-time.service';
import { DesignTemplateService } from '../design-template.service';
import { DocumentService, IDesignListItem } from '../document.service';
import { FeaturesVisibilityInfoService } from '../features-visibility-info.service';
import { GuidService } from '../guid.service';
import { LocalizationService } from '../localization.service';
import { LoggerService } from '../logger.service';
import { ModalService } from '../modal.service';
import { ModulesTrackingService } from '../modules-tracking.service';
import { ModulesService } from '../modules.service';
import { NumberService } from '../number.service';
import { SignalRService } from '../signalr.service';
import { TourService } from '../tour.service';
import { TranslationFormatServiceInjected } from '../translation-format.service';
import { UnitService } from '../unit.service';
import { UserSettingsService } from '../user-settings.service';
import { UserService } from '../user.service';
import {
    afterCalculation, afterCalculationUpdates, BaseCalculateDesignRequestData, calculateAsyncHelper,
    catchCalculationException, changeModel, createOnDocumentServiceAndTrack,
    ICalculateInternalOptions, loadStateBase, removeLoadingFlag, resetModel, sanitizePropertyValues,
    saveDesignStateInternal, saveState, setDesignFromDocumentDesign, triggerDesignEvent,
    UIPropertyConfigExtended, updateDesignCodeLists, updateFromProperties,
    updatePendingCalculationResult, updatePropertyValues
} from './calculation-service-base';

interface ICalculateDesignRequestOptions {
    generateReportData?: GenerateReportRequest;
    calculateAll: boolean;
    importingLoadCases?: boolean;
    calculateAdvancedBaseplate?: boolean;
    changes?: Change[];
    calculateLongRunning?: boolean;
}

export interface CalculationResultEntity extends EntityBase {
    ConfirmChangeDialogEntities: ConfirmChangeDialogEntity[];
    DesignCodeLists: IDesignCodeLists;
    DesignReportDataClient: DesignReportDataClientEntity;
    IsReadOnlyDesign: boolean;
    ProjectDesign: ProjectDesignBaseEntity;
    Properties: any;
    ReportData: GenerateReportResponse;
    ValidationError: ValidationErrorEntity;
    ValueChangeConfirmation: ConfirmationEntity;
}

@Injectable({
    providedIn: 'root'
})
export class CalculationServicePE/* extends CalculationServiceBasePE*/ {
    constructor(
        protected signalr: SignalRService,
        protected tracking: ModulesTrackingService,
        public userSettings: UserSettingsService,
        public localization: LocalizationService,
        protected documentService: DocumentService,
        public logger: LoggerService,
        protected browser: BrowserService,
        protected apiService: ApiService,
        protected designTemplateService: DesignTemplateService,
        protected changesService: ChangesService,
        public unitService: UnitService,
        protected translationFormatService: TranslationFormatServiceInjected,
        protected user: UserService,
        protected modalService: ModalService,
        private readonly numberService: NumberService,
        private readonly dateTimeService: DateTimeService,
        private readonly featuresVisibilityInfo: FeaturesVisibilityInfoService,
        private readonly guid: GuidService,
        private readonly codeList: CodeListService,
        private readonly codeListCommon: CommonCodeListService,
        private readonly modulesService: ModulesService,
        private readonly tourService: TourService
    ) { }

    public createAndOpen(dataEntity: NewDesignDataEntity) {
        const designDeps = this.getDesignDeps();
        const design = new Design(designDeps, DesignPe.commonUiProperties);

        return this.createInternal(design, dataEntity)
            .then(() => {
                return createOnDocumentServiceAndTrack(
                    this.documentService,
                    this.signalr,
                    design,
                    dataEntity.ProjectId,
                    ProjectOpenType.Blank,
                    true,
                    false,
                    () => {
                        this.tracking.trackOnDesignOpen(design)
                            .then(() => {
                                this.tracking.saveTrackingActData(design);
                            });
                    }
                );
            })
            .then(() => this.setDesignLocation(design, dataEntity.ProjectId))
            .then(() => design);
    }

    public createAndOpenFromProjectDesignFile(
        projectDesign: ProjectDesignBaseEntity, projectId: string, name?: string,
        designCreated?: (projectDesign: ProjectDesignBaseEntity, name: string) => Promise<void> | void,
        ignoreConflict?: boolean, disableCalcMessages?: boolean, trackingEnabled?: boolean) {
        const designDeps = this.getDesignDeps();
        const design = new Design(designDeps, DesignPe.commonUiProperties);

        let openType: ProjectOpenType = ProjectOpenType.ImportedProfis3File;
        if (/.pa2$/i.test(name)) {
            openType = ProjectOpenType.ImportedPA2File;
        }

        let createDesign: Promise<ProjectDesignBaseEntity> = null;

        if (designCreated != null) {
            const response = designCreated(projectDesign, name);
            if (response != null) {
                createDesign = Promise.resolve(response).then(() => projectDesign);
            }
            else {
                createDesign = Promise.resolve(projectDesign);
            }
        }
        else {
            createDesign = Promise.resolve(projectDesign);
        }

        return createDesign
            .then((projectDesignRes) => this.createFromProjectDesignInternal(design, projectDesignRes, disableCalcMessages))
            .then(() => {
                // If we import the design from the design page we save the old content to DS so next time the design is opened
                // the validation will be triggered again and the user can see the notifications.
                if (disableCalcMessages) {
                    design.designData.projectDesign = projectDesign;
                }
                this.advancedBaseplateLicenseRequiredModal(design);
                return this.createOnDocumentServiceAndTrack(design, projectId, openType, false, ignoreConflict, trackingEnabled);
            });
    }

    public openFromProjectDesign(projectDesign: ProjectDesignBaseEntity, designId: string, trackingEnabled = true, openOnly = false, disableCalcMessages?: boolean) {
        const designDeps = this.getDesignDeps();
        const design = new Design(designDeps, DesignPe.commonUiProperties);

        return this.createFromProjectDesignInternal(design, projectDesign, disableCalcMessages, openOnly)
            .then((result) => {
                this.setDesign(design, this.documentService.findDesignById(designId), trackingEnabled);

                return result;
            });
    }

    public openFromDocumentDesign(documentDesign: IBaseDesign, openOnly = false) {
        const designDeps = this.getDesignDeps();
        const design = new Design(designDeps, DesignPe.commonUiProperties);

        return (this.documentService.openDesignExclusiveByType<ProjectDesignBaseEntity>(DesignTypeId.Concrete, documentDesign))
            .then((projectDesign) => this.createFromProjectDesignInternal(design, projectDesign, undefined, openOnly))
            .then((result) => {
                this.setDesign(design, documentDesign);

                return result;
            });
    }

    public createAndOpenFromProjectDesign(projectDesign: ProjectDesignBaseEntity, projectId: string, templateId?: string, enableTracking?: boolean) {
        const designDeps = this.getDesignDeps();
        const design = new Design(designDeps, DesignPe.commonUiProperties);
        design.templateId = templateId;

        const openType = templateId != null ? ProjectOpenType.BlankFromTemplate : ProjectOpenType.OpenExisting;
        return this.createFromProjectDesignInternal(design, projectDesign)
            .then(() => this.createOnDocumentServiceAndTrack(design, projectId, openType, true, false, enableTracking));
    }

    public createFromProjectDesign(projectDesign: ProjectDesignBaseEntity, projectId: string, openOnly = false) {
        const designDeps = this.getDesignDeps();
        const design = new Design(designDeps, DesignPe.commonUiProperties);

        return this.createFromProjectDesignInternal(design, projectDesign, undefined, openOnly)
            .then(() => this.createOnDocumentServiceAndTrack(design, projectId, ProjectOpenType.Blank/*TODO: when is this called, is this really opening of design form document service*/, false, false));
    }

    public updateDesignFromExternalFile(oldDesign: IBaseDesign, projectDesign: ProjectDesignBaseEntity, disableCalcMessages?: boolean) {
        const designDeps = this.getDesignDeps();
        const design = new Design(designDeps, DesignPe.commonUiProperties);

        const metadataOverride = {
            designType: projectDesign.ProjectDesignType,
            calculationType: projectDesign.CalculationType,
            region: projectDesign.Options.RegionId,
            standard: projectDesign.Options.DesignStandard,
            designMethod: projectDesign.Options.DesignMethodGroup
        } as DesignExternalMetaData;

        return (this.documentService.openDesignExclusiveByType<ProjectDesignBaseEntity>(DesignTypeId.Concrete, oldDesign))
            .then(() => {
                let update: Promise<any>;

                // The calculation messages are disabled (called from createFromProjectDesignInternal) when importing from opened design
                // in order to avoid duplicate messages as the new design is shown in new tab ->
                // In this case we don't update the design content with the validated one from the BaseplateCalculation as the user must see the notifications first
                // The content is not saved until a calculation is not triggered manually
                if (disableCalcMessages) {
                    update = this.documentService.updateDesignWithNewContentByType(DesignTypeId.Concrete, oldDesign.id, oldDesign.projectId, oldDesign.designName, projectDesign, metadataOverride, null, false, false, DocumentAccessMode.Update)
                        .then(() => {
                            return this.createFromProjectDesignInternal(design, projectDesign, disableCalcMessages);
                        });
                }
                // Updating from main page, updating the design content and displaying BaseplateCalculation notifications only the first time
                else {
                    update = this.createFromProjectDesignInternal(design, projectDesign)
                        .then(() => {
                            return this.documentService.updateDesignWithNewContentByType(DesignTypeId.Concrete, oldDesign.id, oldDesign.projectId, oldDesign.designName, design.designData.projectDesign, metadataOverride, null, false, false, DocumentAccessMode.Update);
                        });
                }

                return update.then(() => {
                    this.setDesign(design, oldDesign, false);

                    this.advancedBaseplateLicenseRequiredModal(design);
                    return design;
                });
            });
    }

    /**
     * Opens the design as a template. No changes are stored to the document service.
     */
    public createAndOpenTemplate(projectDesign: ProjectDesignBaseEntity, templateId: string, templateName: string) {
        const designDeps = this.getDesignDeps();
        const design = new Design(designDeps, DesignPe.commonUiProperties);

        design.templateId = templateId;
        design.templateName = templateName;
        return this.createFromProjectDesignInternal(design, projectDesign)
            .then(() => {
                design.isTemplate = true;
                design.projectOpenType = ProjectOpenType.TemplateEdit;
                this.signalr.setHubConnections();
                return design;
            });
    }

    public calculateAdvancedBaseplateAll(design: Design) {
        return this.calculateAdvancedBaseplateForLoadCase(design, null);
    }

    public calculateAdvancedBaseplate(design: Design, selectedLoadCaseId: string = null) {
        if (design.isHandrailCBFEMCalculation) {
            return this.calculateAdvancedBaseplateForLoadCase(design, selectedLoadCaseId || design.selectedLoadCombinationId);
        }

        if (!design.isLoadCombinationActive) {
            return this.calculateAdvancedBaseplateAll(design);
        }

        return this.calculateAdvancedBaseplateForLoadCase(design, selectedLoadCaseId || design.selectedLoadCombinationId);
    }

    // call generate report with signalR
    public generateAndDownloadReportSignalR(design: Design, generateReportdata: GenerateReportRequest, changes: Change[], downloadPdf: boolean, skipTracking = false) {
        const requestData = this.getCalculateDesignRequestData(design, {
            calculateAll: false,
            generateReportData: generateReportdata,
            calculateAdvancedBaseplate: design.baseplateDesignData != null,
            changes
        });
        design.cancellationToken = new Deferred<void>();

        return this.signalr.common.generateAndDownloadReport(requestData, { cancel: design.cancellationToken.promise })
            .then((data: ICalculationResultEntity) => {
                this.updateCalculationData(design, data, requestData.Language, CalculationType.valueChange, null, false, true);

                if (changes != null && changes.length > 0) {
                    // save state if validation was ok
                    saveState(this.userSettings, this.localization, design, StateChange.server);

                    // update design content on document service.
                    this.documentService.updateDesign(design);
                }
                if (!skipTracking) {
                    this.tracking.saveTrackingActData(design);
                }

                if (data.ReportData != null && data.ReportData.confirmChanges != null && data.ReportData.confirmChanges.length > 0) {
                    return data.ReportData.confirmChanges.reduce((promise, change) => {
                        return promise.then(() => this.confirmReportChange(design, change));
                    }, Promise.resolve()).then(() => data);
                }

                return data;
            })
            .then((data) => {
                const blob = this.browser.base64toBlob(data.ReportData.base64Pdf, 'application/pdf');

                if (downloadPdf) {
                    this.browser.downloadBlob(blob, design.designData.projectDesign.ProjectName + '_' + design.designData.projectDesign.DesignName + '.pdf', true, true);
                }

                return blob;
            })
            .finally(() => {
                design.cancellationToken = null;
            });
    }

    public calculateAllSignalR(design: Design, onProgress?: (progress: unknown) => void) {
        const deferred = new Deferred();

        if (design.designData.calculateAllData == null) {
            design.cancellationToken = new Deferred<void>();

            const requestData = this.getCalculateDesignRequestData(design, { calculateAll: true, generateReportData: null, importingLoadCases: false, calculateAdvancedBaseplate: false });
            this.signalr.common.calculateDesign(requestData, { cancel: design.cancellationToken.promise, onProgress })
                .then((data: ICalculationResultEntity) => {
                    this.updateCalculationData(design, data, requestData.Language, CalculationType.valueChange);

                    this.tracking.saveTrackingActData(design);

                    deferred.resolve();
                })
                .catch(err => deferred.reject(err))
                .finally(() => design.cancellationToken = null);

            return deferred.promise;
        }

        deferred.resolve();
        return deferred.promise;
    }

    public calculateAsync(design: Design, changeFn?: (design: Design) => void, options?: ICalculateInternalOptions) {
        return calculateAsyncHelper(this.logger, this.calculate.bind(this), design, this.modalService, this.localization, changeFn, options);
    }

    public calculate(design: Design, calculateId: string, options: BaseCalculateDesignRequestData) {
        design.cancellationToken = new Deferred<void>();

        let isRequestCanceled = false;
        const cancellationToken = design.cancellationToken.promise;
        cancellationToken
            .finally(() => {
                isRequestCanceled = true;
            });

        const data = this.getCalculateDesignRequestData(design, options);
        design.confirmedProperties = [];
        this.signalr.common.calculateDesign(data, { cancel: cancellationToken })
            .then(response => afterCalculationUpdates(
                this.updateCalculationData.bind(this),
                this.userSettings,
                this.localization,
                this.logger,
                () => {
                    this.tracking.saveTrackingActData(design);
                },
                isRequestCanceled,
                response,
                design,
                calculateId,
                this.localization.selectedLanguage)
            )
            .then(response => {
                // don't update the design on document service if calculation fails
                if (response.ProjectDesign == null) {
                    return response;
                }

                // exceptions for meta-data not updated in the main entity at the moment of making this call to document service
                const metaDataCandidate: DesignExternalMetaData = getMetaDataFromDesign(design);
                if (response.DesignReportDataClient != null &&
                    response.DesignReportDataClient.Approval != null &&
                    response.DesignReportDataClient.Approval.Number != null) {
                    metaDataCandidate.approvalNumber = response.DesignReportDataClient.Approval.Number;
                }
                else {
                    metaDataCandidate.approvalNumber = null;
                }

                // one more exception - one more data type that can be changed here
                if (response.Properties != undefined && response.Properties != null
                    && response.Properties.AnchorLayout_Type != undefined && response.Properties.AnchorLayout_Type != null
                    && response.Properties.AnchorLayout_Type.Value != undefined && response.Properties.AnchorLayout_Type.Value != null
                    && response.Properties.AnchorLayout_Size != undefined && response.Properties.AnchorLayout_Size != null
                    && response.Properties.AnchorLayout_Size.Value != undefined && response.Properties.AnchorLayout_Size.Value != null) {
                    const anchorTypeCodeList = design.designData.designCodeLists[DesignCodeList.AnchorType] as AnchorFamily[];
                    const anchorSizeCodeList = design.designData.designCodeLists[DesignCodeList.AnchorSize] as AnchorSize[];
                    const clAnchorType = anchorTypeCodeList.find((item) => item.id == response.Properties.AnchorLayout_Type.Value);
                    const clAnchorSize = anchorSizeCodeList.find((item) => item.id == response.Properties.AnchorLayout_Size.Value);

                    if (clAnchorType != undefined && clAnchorType != null && clAnchorType.name != undefined && clAnchorType.name != null
                        && clAnchorSize != undefined && clAnchorSize != null && clAnchorSize.name != undefined && clAnchorSize.name != null) {
                        metaDataCandidate.productName = clAnchorType.name + ' ' + clAnchorSize.name;
                    }
                }

                // extract the needed values from response that we send to the document service (we need the new ones not the old ones in this. ...)
                if (!design.isTemplate) {
                    const name = response.Properties[PropertyMetaData.Option_DesignName.name].Value;

                    // do not wait for document service response
                    // multiple calls are already handled by updateDesignWithNewContent with deffered
                    this.documentService.updateDesignWithNewContentByType(design.designTypeId, design.id, design.projectId, name, response.ProjectDesign, metaDataCandidate, null, false, false, DocumentAccessMode.Update);

                    return response;
                }
                else {
                    // template update
                    const anchorName = design.anchorType != null && design.anchorType.name != null
                        && design.anchorSize != null && design.anchorSize.name != null ? design.anchorType.name + ' ' + design.anchorSize.name : null;

                    return this.designTemplateService.update({
                        designTemplateDocumentId: design.templateId,
                        designTypeId: response.ProjectDesign.ProjectDesignType,
                        designStandardId: response.ProjectDesign.Options.DesignStandard,
                        regionId: response.ProjectDesign.Options.RegionId,
                        templateName: design.templateName,
                        anchorName: anchorName,
                        approvalNumber: metaDataCandidate.approvalNumber,
                        projectDesign: JSON.stringify(response.ProjectDesign)
                    });
                }
            })
            .catch((err) => catchCalculationException(err, design, isRequestCanceled, calculateId, this.logger))
            .then((response) => removeLoadingFlag(response, design, calculateId));

        afterCalculation(design, options.calculateAll, options.calculateAdvancedBaseplate, this.logger);
    }

    public updateReportDataFromModel(design: Design) {
        if (design.designData.reportData != null) {
            // load combinations
            design.designData.reportData.LoadCombinationCount = design.loadCombinations?.length ?? 0;
            design.designData.reportData.LoadCombinationsEnabled = design.isLoadCombinationActive ?? false;
        }
    }

    /**
     * Uploade image for design when the report is ready for export.
     * @param designId - id of the design.
     * @param images - images that need to be uploaded.
     */
    public async uploadImages(designId: string, images: string[]): Promise<string[]> {
        const url = `${environment.baseplateCalculationWebServiceUrl}UploadImages`;
        const req = {
            designId,
            images
        };

        const request = new HttpRequest('POST', url, req, {
            responseType: 'json'
        });

        return (await this.apiService.request<string[]>(request, { supressErrorMessage: true })).body;
    }

    /**
     * Get custom images.
     * @param imageNames - name of images that need to be retrieved.
     * @returns
     */
    public async getCustomImages(imageNames: string[]): Promise<string[]> {
        const url = `${environment.baseplateCalculationWebServiceUrl}GetImages`;
        const req = {
            imageNames
        };

        const request = new HttpRequest('POST', url, req, {
            responseType: 'json'
        });

        return (await this.apiService.request<string[]>(request, { supressErrorMessage: true })).body;
    }

    public updateReportData(design: Design, reportData: IDesignReportDataClientEntity) {
        design.designData.reportData = {
            ...reportData,
            LoadCombinationCount: 0,
            LoadCombinationsEnabled: false
        };

        // clear some data that we don't need
        design.designData.reportData.AllAnchorResults = null;

        // convert dates
        if (design.designData.reportData.Approval?.Issued) {
            design.designData.reportData.Approval.Issued = this.dateTimeService.toDate(design.designData.reportData.Approval.Issued);
        }

        if (design.designData.reportData.Approval?.Valid) {
            design.designData.reportData.Approval.Valid = this.dateTimeService.toDate(design.designData.reportData.Approval.Valid);
        }

        return design.designData.reportData;
    }

    public updateFromProperties(design: Design, uiproperties: UIProperties, keepMissingProperties?: boolean) {
        sanitizePropertyValues(uiproperties);
        const modelChanges = this.updateModel(design, uiproperties);
        const propertyChanges = this.updatePropertyValues(design, uiproperties, keepMissingProperties);

        updateFromProperties(design.properties, modelChanges, propertyChanges, this.logger);

        return {
            modelChanges,
            propertyChanges
        };
    }

    public updateCalculationData(
        design: Design,
        data: ICalculationResultEntity,
        calculationLanguage: string,
        calculationType: CalculationType,
        messagesClosedDeferred?: Deferred<void>,
        disableCalcMessages?: boolean,
        keepMissingProperties?: boolean
    ) {
        if (data.ValidationError == null && data.ValueChangeConfirmation == null) {
            design.validationError = null;

            // update
            design.updateProjectDesign(data.ProjectDesign);
            const codeLists = this.createDesignCodeLists(data.DesignCodeLists);
            updateDesignCodeLists(design, codeLists, false);

            if (data.DesignReportDataClient != null) {
                this.updateReportData(design, data.DesignReportDataClient);
            }
            const { modelChanges, propertyChanges } = this.updateFromProperties(design, data.Properties, keepMissingProperties);
            this.updateReportDataFromModel(design);

            design.calculationLanguage = calculationLanguage;
            if (data.DesignReportDataClient != null) {
                this.updateCalculateAllDataService(design, data.DesignReportDataClient.AllAnchorResults);
            }
            updatePendingCalculationResult(design, null); // clear pending advanced baseplate result

            // Set anchor family details
            design.loadAnchorFamilyDetailed();

            if (!disableCalcMessages) {
                this.showConfirmChanges(design, data, messagesClosedDeferred, calculationType);
            }

            triggerDesignEvent(design, propertyChanges);

            design.isReadOnlyDesign = data.IsReadOnlyDesign;
            design.designDefaultsChanged = disableCalcMessages && data.ConfirmChangeDialogEntities?.length > 0;

            return modelChanges;
        }
        else if (data.ValueChangeConfirmation != null) {
            this.checkConfirmSelection(design, data.ValueChangeConfirmation, messagesClosedDeferred);
            return null;
        }
        else {
            design.validationError = data.ValidationError;

            if (messagesClosedDeferred != null) {
                messagesClosedDeferred.resolve();
            }

            // load previous values
            design.reloadState();

            return null;
        }
    }

    public confirmCalculationResult(design: Design, result: ICalculationResultEntity) {
        this.updateCalculationData(design, result, this.localization.selectedLanguage, CalculationType.valueChange);
        this.updateState(design);

        this.tracking.saveTrackingActData(design);
    }


    public performAdvancedBaseplateCalculation(design: Design, selectedLoadCaseId?: string) {
        const deferred = new Deferred<ICalculationResultEntity>();

        const requestData = this.getCalculateDesignRequestData(design, { calculateAll: false, generateReportData: null, importingLoadCases: false, calculateAdvancedBaseplate: true });

        switch (design.designType.id) {
            case DesignTypeId.Concrete:
            case DesignTypeId.MetalDeck: {
                // Triggering Idea calculation for all combinations
                if (selectedLoadCaseId == null) {
                    const property = {
                        Property: IUIProperty.Loads_LoadCombinationsCalculationType,
                        Value: LoadCombinationsCalculationType.All,
                        Confirmed: true
                    } as UIPropertyValue;
                    requestData.Properties.push(property);
                }
                // Triggering Idea calculation for specific load combination
                else if (selectedLoadCaseId != null && selectedLoadCaseId != '') {
                    const selectedLoadCombination = {
                        Property: IUIProperty.Loads_SelectedLoadCombination,
                        Value: selectedLoadCaseId,
                        Confirmed: true
                    } as UIPropertyValue;
                    requestData.Properties.push(selectedLoadCombination);
                }
                break;
            }
            case DesignTypeId.Handrail: {
                const handrailProperty = {
                    Property: IUIProperty.Loads_SelectedLoadCombination,
                    Value: selectedLoadCaseId,
                    Confirmed: true
                } as UIPropertyValue;
                requestData.Properties.push(handrailProperty);
                break;
            }
            default:
                throw new Error('Unsupported deesign type');
        }

        design.cancellationToken = new Deferred<void>();

        design.setPendingCalculation(true);
        this.modalService.loadingCustomOpen(
            this.localization.getString('Agito.Hilti.Profis3.Main.CalculateAdvancedBaseplate.Progress.Initializing'),
            () => { design.cancellationToken.reject(); }
        );
        design.trigger(DesignEvent.beforeCalculate, design, design.modelChanges.changes);
        this.signalr.common.calculateDesign(requestData, {
            cancel: design.cancellationToken.promise,
            onProgress: (prog: ProgressStatus) => {
                if (prog != null && prog.ProgressText != null && (prog.ProgressText.Template != null || prog.ProgressText.TemplateFormat != null)) {
                    design.statusText = this.translationFormatService.getLocalizedStringWithTranslationFormat(prog.ProgressText);
                    this.modalService.loadingCustomLoadingText = design.statusText;
                    design.progressAdvancedBaseplateCalculationSubject?.next(prog);
                }
            }
        })
            .then(res => deferred.resolve(res))
            .catch(err => deferred.reject(err))
            .finally(() => {
                design.setPendingCalculation(false);
                design.cancellationToken = null;
                this.modalService.loadingCustomClose();
                design.trigger(DesignEvent.calculate, design, design.modelChanges.changes);
            });

        return deferred.promise;
    }

    public wizardHNALoadTypeSeismicDialogChain(design: Design, onClose: () => void) {
        const tensionTypeCodeList: number = DesignCodeList['SeismicTensionType'];
        const shearTypeCodeList: number = DesignCodeList['SeismicShearType'];
        let alreadySet: boolean;

        if (design.designMethodHNA == DesignMethodHNA.LRFD) {
            // Default values
            let seismicDesign = false;

            this.modalService.openConfirmChange({
                id: 'wizard-hna-load-type-seismic-dialog-tension',
                title: this.localization.getString('Agito.Hilti.Profis3.Warning'),
                message: this.localization.getString('Agito.Hilti.Profis3.HNAWizard.NoEarthquakeComponentForSeismic.Message'),
                confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Yes'),
                cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.No'),
                onConfirm: (modal) => {
                    seismicDesign = true;
                    modal.close();
                },
                onCancel: (modal) => {

                    modal.close();
                }
            }).closed.then(() => {
                this.submitWizardRelatedData(design, seismicDesign, null, null);
                onClose();
            });
        }
        else if (design.designMethodHNA == DesignMethodHNA.ACI31808) {
            // Default values
            let seismicDesign = false;
            let tensionType: SeismicTensionType;
            let shearType: SeismicShearType;

            alreadySet = false;
            this.modalService.openConfirmChange({
                id: 'wizard-hna-load-type-seismic-dialog-tension',
                title: this.localization.getString('Agito.Hilti.Profis3.Warning'),
                message: this.localization.getString('Agito.Hilti.Profis3.HNAWizard.NoEarthquakeComponentForSeismic.Tension.Message'),
                confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Yes'),
                cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.No'),
                onConfirm: (modal) => {
                    // Open Seismic Tension type grid modal without None value
                    const props = this.prepareModalGrid(
                        design.designData.designCodeLists,
                        tensionTypeCodeList,
                        this.localization.getString('Agito.Hilti.Profis3.Navigation.TabLoads.RegionSeismicDesign.ControlSeismicTensionAndSeismicTypeACI31808.GridTitle'),
                        design.properties.get(PropertyMetaData.Loads_SeismicTensionType.id).allowedValues.filter(x => x != SeismicTensionType.None)
                    );
                    props.onSelect = (item) => {
                        // Should have the same items/allowed values
                        tensionType = item.value;
                        shearType = item.value;
                        seismicDesign = true;
                        alreadySet = true;
                        modal.close();
                    };
                    this.modalService.openModalGrid(props);
                },
                onCancel: (modal) => {
                    modal.close();
                }
            }).closed.then(() => {
                if (!alreadySet) {
                    seismicDesign = false;
                }

                this.submitWizardRelatedData(design, seismicDesign, tensionType, shearType);
                onClose();
            });
        }
        else {
            // Default values
            let tensionSeismicDesign = false;
            let shearSeismicDesign = false;
            let tensionType = SeismicTensionType.None;
            let shearType = SeismicShearType.None;

            alreadySet = false;
            this.modalService.openConfirmChange({
                id: 'wizard-hna-load-type-seismic-dialog-tension',
                title: this.localization.getString('Agito.Hilti.Profis3.Warning'),
                message: this.localization.getString('Agito.Hilti.Profis3.HNAWizard.NoEarthquakeComponentForSeismic.Tension.Message'),
                confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Yes'),
                cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.No'),
                onConfirm: (modal) => {
                    // Open Seismic Tension type grid modal without None value
                    const props = this.prepareModalGrid(
                        design.designData.designCodeLists,
                        tensionTypeCodeList,
                        this.localization.getString('Agito.Hilti.Profis3.Navigation.TabLoads.RegionSeismicDesign.ControlSeismicTensionType.GridTitle'),
                        design.properties.get(PropertyMetaData.Loads_SeismicTensionType.id).allowedValues.filter(x => x != SeismicTensionType.None)
                    );
                    props.onSelect = (item) => {
                        tensionType = item.value;
                        tensionSeismicDesign = true;
                        alreadySet = true;
                        modal.close();
                    };
                    this.modalService.openModalGrid(props);
                },
                onCancel: (modal) => {
                    modal.close();
                }
            }).closed.then(() => {
                if (!alreadySet) {
                    tensionSeismicDesign = false;
                    tensionType = SeismicTensionType.None;
                }

                alreadySet = false;
                this.modalService.openConfirmChange({
                    id: 'wizard-hna-load-type-seismic-dialog-shear',
                    title: this.localization.getString('Agito.Hilti.Profis3.Warning'),
                    message: this.localization.getString('Agito.Hilti.Profis3.HNAWizard.NoEarthquakeComponentForSeismic.Shear.Message'),
                    confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Yes'),
                    cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.No'),
                    onConfirm: (modal) => {
                        // Open Seismic Shear type grid modal without None value
                        const props = this.prepareModalGrid(
                            design.designData.designCodeLists,
                            shearTypeCodeList,
                            this.localization.getString('Agito.Hilti.Profis3.Navigation.TabLoads.RegionSeismicDesign.ControlSeismicShearType.GridTitle'),
                            design.properties.get(PropertyMetaData.Loads_SeismicShearType.id).allowedValues.filter(x => x != SeismicShearType.None)
                        );
                        props.onSelect = (item) => {
                            shearType = item.value;
                            shearSeismicDesign = true;
                            alreadySet = true;
                            modal.close();
                        };
                        this.modalService.openModalGrid(props);
                    },
                    onCancel: (modal) => {
                        modal.close();
                    }
                }).closed.then(() => {
                    if (!alreadySet) {
                        shearType = SeismicShearType.None;
                        if (tensionSeismicDesign) {
                            shearSeismicDesign = true;
                        }
                        else {
                            shearSeismicDesign = false;
                        }
                    }

                    this.submitWizardRelatedData(design, shearSeismicDesign, tensionType, shearType);
                    onClose();
                });
            });
        }
    }

    public generateIdeaFile(design: Design) {
        design.resolveCalculation();

        const url = `${environment.baseplateCalculationWebServiceUrl}GenerateIdeaProjectFile`;
        const data: IdeaProjectFileRequest = {
            ProjectDesign: design.designData.projectDesign,
            UseDevFeatures: environment.useDevFeatures,
            ForceFreeLicense: this.userSettings.settings.application.general.forceFreeLicense.value
        };

        return this.apiService.request<IdeaProjectFileResponse>(new HttpRequest('POST', url, data));
    }

    public createProjectDesignFromFile(projectDesignFile: File | Blob, projectName: string, designName: string, ignoreErrors?: boolean): Promise<ProjectDesignBaseEntity> {
        const url = `${environment.baseplateApplicationWebServiceUrl}ReadDesignXml`;
        const request = new HttpRequest('POST', url, projectDesignFile, {
            params: new HttpParams({
                fromObject: {
                    LCID: this.localization.selectedLanguageLCID?.toString(),
                    ProjectName: projectName,
                    DesignName: designName,
                    NumberDecimalSeparator: getNumberDecimalSeparator(this.localization.numberFormat(), this.userSettings),
                    NumberThousandsSeparator: getNumberGroupSeparator(this.localization.numberFormat(), this.userSettings)
                }
            })
        });

        return this.apiService.request<ProjectDesignBaseEntity>(request, { supressErrorMessage: ignoreErrors || false })
            .then((response) => {
                if (response.body != null && typeof response.body == 'string') {
                    throw response.body;
                }

                return response.body;
            })
            .catch((response) => {
                this.logger.logServiceError(response, 'Design', 'createProjectDesignFromFile');

                throw response;
            });
    }

    public loadState(
        design: Design,
        index: number,
    ) {
        loadStateBase(
            this.unitService,
            this.localization,
            this.userSettings,
            design,
            index,
            (designObj) => {
                designObj.updateProjectDesign(designObj.currentState.projectDesign);
                this.updateReportData(designObj, designObj.currentState.reportData);
                designObj.updateModelFromModel(cloneDeep(designObj.currentState.model));
                this.updateReportDataFromModel(designObj);
                updatePendingCalculationResult(designObj, designObj.currentState.pendingCalculationResult);
            }
        );
    }

    public undo(design: Design) {
        if (!design.canUndo) {
            return Promise.resolve();
        }

        design.resolveCalculation();
        this.loadState(design, design.states.findIndex((state) => state === design.currentState) - 1);

        return this.undoRedoCommon(design, false);
    }

    public redo(design: Design) {
        if (!design.canRedo) {
            return Promise.resolve();
        }

        design.resolveCalculation();
        this.loadState(design, design.states.findIndex((state) => state === design.currentState) + 1);

        return this.undoRedoCommon(design, true);
    }

    private showConfirmChanges(design: Design, data: ICalculationResultEntity, messagesClosedDeferred: Deferred<void>, calculationType: CalculationType) {
        if (design.confirmChangeInProgress === false) {
            design.confirmChangeInProgress = true;
            this.checkConfirmChanges(design, data.ConfirmChangeDialogEntities, messagesClosedDeferred, calculationType);
        }
        else if (messagesClosedDeferred != null) {
            messagesClosedDeferred.resolve();
        }
    }

    private updateCalculateAllDataService(design: Design, calculateAllData: { [key: string]: AllAnchorResultItem[] }) {
        if (calculateAllData != null) {
            design.designData.calculateAllData = [];

            const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
            for (const key in calculateAllData) {
                const anchorId = parseInt(key, 10);
                const anchorFamilyCodeList = (design.designData.designCodeLists[design.globalMetaProperties.productCodeListProperty] as AnchorFamily[]).find((anchorFamily) => anchorFamily.id == anchorId);

                if (anchorFamilyCodeList == null) {
                    throw new Error(`Unknown anchor family code list id = ${anchorId} returned from calculate all.`);
                }

                design.designData.calculateAllData.push({
                    id: anchorId,
                    name: anchorFamilyCodeList.getTranslatedNameText(codeListDeps),
                    detailed: calculateAllData[anchorId].map((anchorDetailed): ICalculateAllAnchorDetailed => ({
                        id: anchorDetailed.CalculatedFastenerId,
                        name: anchorDetailed.Name,
                        size: anchorDetailed.Size,
                        diameter: anchorDetailed.Diameter,
                        embedmentDepth: anchorDetailed.EmbedmentDepth,
                        isAnchorPlateSizeOk: anchorDetailed.IsAnchorPlateSizeOk,
                        isAnchorPlatePositionOk: anchorDetailed.IsAnchorPlatePositionOk,
                        isEdgeDistanceOk: anchorDetailed.IsEdgeDistanceOk,
                        isAxialSpacingOk: anchorDetailed.IsAxialSpacingOk,
                        isBaseMaterialThicknessOk: anchorDetailed.IsBaseMaterialThicknessOk,
                        calculationStatus: anchorDetailed.CalculationStatus,
                        t: anchorDetailed.TensionDecisive,
                        s: anchorDetailed.ShearDecisive,
                        c: anchorDetailed.CombinationDecisive,
                        holeDiameter: anchorDetailed.HoleDiameter,
                        fixtureThicknesMin: anchorDetailed.FixtureThicknesMin,
                        fixtureThicknesMax: anchorDetailed.FixtureThicknesMax,
                        allowedInAnchorFilters: anchorDetailed.AllowedInAnchorFilters
                    }))
                });
            }
        }
        else {
            design.designData.calculateAllData = null;
        }

        return design.designData.calculateAllData;
    }

    private getCalculateDesignRequestData(design: Design, options: ICalculateDesignRequestOptions): CalculateDesignRequest {
        return {
            ProjectDesign: design.designData.projectDesign,
            ReportData: options.generateReportData || null,
            Language: this.localization.selectedLanguage,
            NumberDecimalSeparator: getNumberDecimalSeparator(this.localization.numberFormat(), this.userSettings),
            NumberThousandsSeparator: getNumberGroupSeparator(this.localization.numberFormat(), this.userSettings),
            Properties: (options.changes || design.modelChanges.changes).map((change) => {
                const property = parseInt(change.name, 10);
                const newValue = sanitizePropertyValueForJson(change.newValue);
                return {
                    Property: property,
                    Value: newValue,
                    Confirmed: design.confirmedProperties?.some((confirmation) => confirmation == property) ?? false
                } as UIPropertyValue;
            }),
            CalculateAll: options.calculateAll ?? false,
            ImportingLoadCases: options.importingLoadCases ?? false,
            CalculateAdvancedBaseplate: options.calculateAdvancedBaseplate ?? false,
            UseDevFeatures: environment.useDevFeatures,
            SkipABPCache: false,    // Change to true to skip ABP calculation cache!
            IntegrationDocument: design.integrationDocument,
            ProjectId: design.projectId,
            CalculateLongRunning: options.calculateLongRunning,
            UIVersion: environment.applicationVersion,
            CalculationMethodState: this.userSettings.settings.application.advancedCalculation.calculationMethodState.value,
            ForceFreeLicense: this.userSettings.settings.application.general.forceFreeLicense.value
        };
    }

    private calculateAdvancedBaseplateForLoadCase(design: Design, selectedLoadCaseId: string = null): Promise<void> {
        const calculateAdvancedBaseplate = (selectedLoadId: string, showComparisonWindow: boolean) => {
            if (!showComparisonWindow) {
                design.usageCounter.CalculateAdvancedBaseplate++;

                return this.performAdvancedBaseplateCalculation(design, selectedLoadId)
                    .then((data: ICalculationResultEntity) => {
                        this.confirmCalculationResult(design, data); // for fem calculation autoconfirm data (no popup)

                        return null;
                    });
            }
            else {
                if (design.baseplateDesignData != null && selectedLoadId == design.baseplateDesignData.SelectedLoadCombiantionId) { // have confirmed data for selected lc
                    // data already exists, open compare popup
                    return this.modalService.openAdvancedBaseplateCalculation({ designReportData: design.designData.reportData }).result;
                }

                if (design.designData.pendingCalculationResult?.DesignReportDataClient?.BaseplateDesignData?.SelectedLoadCombiantionId != null && selectedLoadId == design.designData.pendingCalculationResult.DesignReportDataClient.BaseplateDesignData.SelectedLoadCombiantionId) { // have pending data for selected lc
                    // data already exists, open compare popup
                    return this.modalService.openAdvancedBaseplateCalculation({ designReportData: design.designData.pendingCalculationResult.DesignReportDataClient }).result;
                }

                design.usageCounter.CalculateAdvancedBaseplate++;

                return this.performAdvancedBaseplateCalculation(design, selectedLoadId)
                    .then((data: ICalculationResultEntity) => {
                        if (data.DesignReportDataClient.BaseplateDesignData == null) { // null is in case out of scope or calculation not ok - autoconfirm to show scope checks and stuff like that
                            this.confirmCalculationResult(design, data);

                            return null;
                        }
                        else {
                            updatePendingCalculationResult(design, data);
                            this.updateState(design);

                            return this.modalService.openAdvancedBaseplateCalculation({ designReportData: data.DesignReportDataClient }).result;
                        }
                    });
            }
        };

        // clear changes that may be pending, because no changes should be made without confirm
        design.modelChanges.clear();

        if (design.designType.id == DesignTypeId.Handrail) {
            if (design.handrailConnectionType == HandrailConnectionType.FrontDirectWelded) {
                return calculateAdvancedBaseplate(selectedLoadCaseId, true);
            }

            throw new Error('Unsupported calculation type.');
        }

        switch (design.calculationType) {
            case AdvancedCalculationType.FEM:
                return calculateAdvancedBaseplate(selectedLoadCaseId, false);

            case AdvancedCalculationType.Realistic:
            case AdvancedCalculationType.BPRigidityCheck:
                return calculateAdvancedBaseplate(selectedLoadCaseId, true);

            default:
                throw new Error('Unsupported calculation type.');
        }
    }

    private createFromProjectDesignInternal(design: Design, projectDesign: ProjectDesignBaseEntity, disableCalcMessages?: boolean, openOnly = false) {
        design.integrationDocument = projectDesign.IntegrationDocument;
        const url = `${environment.baseplateCalculationWebServiceUrl}NewDesignFromProject`;
        const data: CreateNewDesignFromProjectRequest = {
            ProjectDesign: projectDesign as any,
            Language: this.localization.selectedLanguage,
            NumberDecimalSeparator: getNumberDecimalSeparator(this.localization.numberFormat(), this.userSettings),
            NumberThousandsSeparator: getNumberGroupSeparator(this.localization.numberFormat(), this.userSettings),
            UseDevFeatures: environment.useDevFeatures,
            OpenOnly: openOnly,
            UIVersion: environment.applicationVersion,
            ForceFreeLicense: this.userSettings.settings.application.general.forceFreeLicense.value
        };

        return this.apiService.request<IStaticDesignData>(new HttpRequest('POST', url, data))
            .then((response) => {
                // set data
                resetModel(design);
                const messagesClosedDeferred = new Deferred();
                this.updateCalculationData(design, response.body.CalculationResults, data.Language, CalculationType.openDesign, messagesClosedDeferred, disableCalcMessages);
                design.designData.anchorsMarkedAsNew = response.body.AnchorsMarkedAsNew;

                // save state
                saveState(this.userSettings, this.localization, design);

                // trigger create design event
                design.trigger(DesignEvent.createDesign);

                return {
                    design,
                    calculationCanceled: false,
                    messagesClosed: messagesClosedDeferred.promise,
                    projectDesign: response.body.CalculationResults.ProjectDesign
                } as ICalculationResult;
            })
            .catch((response) => {
                this.logger.logServiceError(response, 'Design', 'createDesignFromProject');

                throw response;
            });
    }

    private createOnDocumentServiceAndTrack(
        design: Design,
        projectId: string,
        openType: ProjectOpenType,
        canGenerateUniqueName: boolean,
        ignoreConflict: boolean,
        trackingEnabled = true
    ) {
        design.usageCounter.DateAccessed = new Date();

        const promise = this.documentService.addDesignBase({
            projectId,

            designType: design.designTypeId,
            design,

            canGenerateUniqueName,
            ignoreConflict,

            setProjectDesign: (newDesign: IDesignListItemCommon, designCommon: DesignCommon) => {
                (newDesign as IDesignListItem & LocalDocument).projectDesign = (designCommon as Design).designData.projectDesign;
            },
            adjustDesignListItemContents: (newDesign: IDesignListItemCommon, designCommon: DesignCommon, project: Project) => {
                newDesign.integrationDocument = designCommon.integrationDocument;
                newDesign.projectName = project.name;
                newDesign.isSharedByMe = project.isSharedByMe;

                this.documentService.setDesignPropertiesByType(design, newDesign);
            },
            getDesignMetadata: (designType: number, design: object) => {
                return this.documentService.getMetadataFromDesign(designType, design);
            },
            getDesignObject: (designCommon: DesignCommon) => {
                const design = designCommon as Design;
                const data = {...design.designData.projectDesign};
                delete data.DesignName;
                delete data.ProjectName;
                return data;
            }
        });

        return promise.then(() => {
            design.projectOpenType = openType;
            this.setDesignLocation(design, projectId);

            this.signalr.setHubConnections();

            if (trackingEnabled) {
                this.tracking.trackOnDesignOpen(design).then(() => {
                    this.tracking.saveTrackingActData(design);
                });
            }

            return design;
        });
    }

    private confirmReportChange(design: Design, confirmChange: IConfirmChangesEntity) {
        return this.modalService.openConfirmChange({
            id: 'ConfirmReportChanges',
            title: confirmChange.Title,
            message: confirmChange.Message,
            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Ok'),
            onConfirm: (modal) => {
                modal.close();
            }
        }).closed;
    }

    private advancedBaseplateLicenseRequiredModal(design: Design) {
        // If advanced features are required and user does not have advanced license, display this popup.
        if (
            (
                design.calculationType == AdvancedCalculationType.Realistic
                || design.calculationType == AdvancedCalculationType.FEM
                || design.calculationType == AdvancedCalculationType.AISC
                || design.calculationType == AdvancedCalculationType.ASI
            )
            &&
            !this.featuresVisibilityInfo.hasAdvancedFeature
        ) {
            this.modalService.openAdvancedBaseplateLicenseRequired();
        }
    }

    private updatePropertyValues(design: Design, properties: UIProperties, keepMissingProperties?: boolean) {
        const propertyConfigs: UIPropertyConfigExtended[] = [];

        for (const name in properties) {
            const propertyConfig = properties[name as keyof typeof properties] as UIPropertyConfigExtended;

            propertyConfigs.push(propertyConfig);
        }

        return updatePropertyValues(design, propertyConfigs, keepMissingProperties);
    }

    /**
     * @deprecated DO NOT USE FOR NEW CODE. Overridden in pe-ui-pe.
     */
    private createDesignCodeLists(serviceDesignCodeLists: IDesignCodeLists) {
        const designCodeLists: ICodeLists = {};

        if (serviceDesignCodeLists != null) {
            designCodeLists[DesignCodeList.AnchorFilter] = serviceDesignCodeLists.AnchorFilters?.map((codeList) => AnchorFilter.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorFilterGroup] = serviceDesignCodeLists.AnchorFilterGroups?.map((codeList) => AnchorFilterGroup.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorEmbedmentType] = serviceDesignCodeLists.AnchorEmbedmentTypes?.map((codeList) => EmbedmentType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorFamily] = serviceDesignCodeLists.AnchorFamilies?.map((codeList) => AnchorFamily.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorFamilyAC58] = serviceDesignCodeLists.AnchorFamiliesAc58?.map((codeList) => AnchorFamily.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorLayout] = serviceDesignCodeLists.AnchorLayouts?.map((codeList) => AnchorLayoutEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorLayoutVisible] = serviceDesignCodeLists.AnchorLayoutsVisible?.map((codeList) => AnchorLayoutEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorLayoutSymmetry] = serviceDesignCodeLists.AnchorLayoutSymmetry?.map((codeList) => AnchorLayoutSymmetryEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorSize] = serviceDesignCodeLists.AnchorSizes?.map((codeList) => AnchorSize.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorSizeAC58] = serviceDesignCodeLists.AnchorSizesAc58?.map((codeList) => AnchorSize.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorType] = serviceDesignCodeLists.AnchorTypes?.map((codeList) => AnchorTypeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorTypeAC58] = serviceDesignCodeLists.AnchorTypesAc58?.map((codeList) => AnchorTypeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorEmbedmentDepth] = serviceDesignCodeLists.AnchorEmbedmentDepths?.map((codeList) => AnchorEmbedmentDepth.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorTorqueType] = serviceDesignCodeLists.AnchorTorqueTypes?.map((codeList) => AnchorTorqueType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.ConcreteCharacteristic] = serviceDesignCodeLists.ConcreteCharacteristics?.map((codeList) => ConcreteCharacteristicEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.ConcreteGrade] = serviceDesignCodeLists.ConcreteGrades?.map((codeList) => ConcreteGrade.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.ConcreteReinforcement] = serviceDesignCodeLists.ConcreteReinforcements?.map((codeList) => ConcreteReinforcementEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.DesignMethodHNA] = serviceDesignCodeLists.DesignMethodHNAs?.map((codeList) => DesignMethodHNAEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.DrillingMethod] = serviceDesignCodeLists.DrillingMethods?.map((codeList) => DrillingMethodEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.EdgeReinforcement] = serviceDesignCodeLists.EdgeReinforcements?.map((codeList) => EdgeReinforcement.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.EmbedmentOptimizationType] = serviceDesignCodeLists.EmbedmentOptimizationTypes?.map((codeList) => EmbedmentOptimizationType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.FatigueLoadType] = serviceDesignCodeLists.FatigueLoadTypes?.map((codeList) => FatigueLoadTypeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.FireExposure] = serviceDesignCodeLists.FireExposures?.map((codeList) => FireExposure.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.FireDuration] = serviceDesignCodeLists.FireDurations?.map((codeList) => FireDuration.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.Grout] = serviceDesignCodeLists.Grouts?.map((codeList) => Grout.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.GroutType] = serviceDesignCodeLists.GroutTypes?.map((codeList) => GroutType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.HoleCondition] = serviceDesignCodeLists.HoleConditions?.map((codeList) => HoleCondition.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.HoleType] = serviceDesignCodeLists.HoleTypes?.map((codeList) => HoleType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.InstallationType] = serviceDesignCodeLists.InstallationTypes?.map((codeList) => InstallationType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.LoadType] = serviceDesignCodeLists.LoadTypes?.map((codeList) => LoadTypeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.PlateShape] = serviceDesignCodeLists.PlateShapes?.map((codeList) => PlateShapeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.ProfileFamily] = serviceDesignCodeLists.ProfileFamilies?.map((codeList) => ProfileFamily.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.ProfileSize] = serviceDesignCodeLists.ProfileSizes?.map((codeList) => ProfileSize.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.ReinforcementShearCondition] = serviceDesignCodeLists.ReinforcementShearConditions?.map((codeList) => ReinforcementShearCondition.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.ReinforcementTensionCondition] = serviceDesignCodeLists.ReinforcementTensionConditions?.map((codeList) => ReinforcementTensionCondition.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SeismicCategory] = serviceDesignCodeLists.SeismicCategories?.map((codeList) => SeismicCategory.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SeismicDesignType] = serviceDesignCodeLists.SeismicDesignTypes?.map((codeList) => SeismicDesignType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SeismicDisplacementsType] = serviceDesignCodeLists.SeismicDisplacementTypes?.map((codeList) => SeismicDisplacementsType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SeismicShearType] = serviceDesignCodeLists.SeismicShearTypes?.map((codeList) => SeismicShearTypeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SeismicTensionType] = serviceDesignCodeLists.SeismicTensionTypes?.map((codeList) => SeismicTensionTypeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.StandOff] = serviceDesignCodeLists.StandOffs?.map((codeList) => StandOff.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.StaticLoadType] = serviceDesignCodeLists.StaticLoadTypes?.map((codeList) => StaticLoadTypeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.BrickLayout] = serviceDesignCodeLists.BrickLayouts?.map((codeList) => BrickLayout.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.BrickGroup] = serviceDesignCodeLists.BrickGroups?.map((codeList) => BrickGroup.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.BrickStrength] = serviceDesignCodeLists.BrickStrengths?.map((codeList) => BrickStrength.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.JointMaterial] = serviceDesignCodeLists.JointMaterials?.map((codeList) => JointMaterial.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.MasonryUseCategory] = serviceDesignCodeLists.MasonryUseCategories?.map((codeList) => MasonryUseCategory.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.MasonryFastenerConfiguration] = serviceDesignCodeLists.MasonryFastenerConfigurations?.map((codeList) => MasonryFastenerConfiguration.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.CleaningMethod] = serviceDesignCodeLists.CleaningMethods?.map((codeList) => CleaningMethod.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.FasteningOption] = serviceDesignCodeLists.FasteningOptions?.map((codeList) => FasteningOption.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.EnvironmentType] = serviceDesignCodeLists.EnvironmentTypes?.map((codeList) => EnvironmentType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.BeamType] = serviceDesignCodeLists.BeamTypes?.map((codeList) => BeamType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.HorizontalPostProfileConnectionType] = serviceDesignCodeLists.HorizontalPostProfileConnectionTypes?.map((codeList) => HorizontalPostProfileConnectionType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.CustomLoadCategory] = serviceDesignCodeLists.CustomLoadCategories?.map((codeList) => CustomLoadCategory.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.HandrailApplication] = serviceDesignCodeLists.HandrailApplication?.map((codeList) => HandrailApplicationTypeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.HandrailConnectionType] = serviceDesignCodeLists.HandrailConnectionTypes?.map((codeList) => HandrailConnectionTypeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.BuildingCategory] = serviceDesignCodeLists.BuildingCategories?.map((codeList) => BuildingCategory.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SteelType] = serviceDesignCodeLists.SteelTypes?.map((codeList) => SteelType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.WindZone] = serviceDesignCodeLists.WindZones?.map((codeList) => WindZone.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.BuildingArea] = serviceDesignCodeLists.BuildingAreas?.map((codeList) => BuildingArea.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.DistanceInsideTownTerrain] = serviceDesignCodeLists.DistancesInsideTownTerrain?.map((codeList) => DistanceInsideTownTerrain.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.DistanceUpwindToShoreline] = serviceDesignCodeLists.DistancesUpwindToShoreline?.map((codeList) => DistanceUpwindToShoreline.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.TerrainCategory] = serviceDesignCodeLists.TerrainCategories?.map((codeList) => TerrainCategory.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.TerrainRoughness] = serviceDesignCodeLists.TerrainRoughnessClasses?.map((codeList) => TerrainRoughness.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.WindLoadCity] = serviceDesignCodeLists.WindLoadCities?.map((codeList) => WindLoadCity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.WindLoadState] = serviceDesignCodeLists.WindLoadStates?.map((codeList) => WindLoadState.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.WindVelocity] = serviceDesignCodeLists.WindVelocities?.map((codeList) => WindVelocity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.TerrainType] = serviceDesignCodeLists.TerrainTypes?.map((codeList) => TerrainType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.CoordinateSystemCenter] = serviceDesignCodeLists.CoordinateSystemCenters?.map((codeList) => CoordinateSystemCenter.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.MetalDeck] = serviceDesignCodeLists.MetalDeck?.map((codeList) => MetalDeck.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.MetalDeckAnchorPosition] = serviceDesignCodeLists.MetalDeckAnchorPosition?.map((codeList) => MetalDeckAnchorPosition.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.MasonryAnchorPosition] = serviceDesignCodeLists.MasonryAnchorPosition?.map((codeList) => MasonryAnchorPosition.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.LoadCombinationHNAEquation] = serviceDesignCodeLists.LoadCombinationHNAEquations?.map((codeList) => LoadCombinationHNAEquation.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.LoadCombinationHNAFactors] = serviceDesignCodeLists.LoadCombinationHNAFactors?.map((codeList) => LoadCombinationHNAFactors.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.TorquingType] = serviceDesignCodeLists.TorquingTypes?.map((codeList) => TorquingType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.WeldLocations] = serviceDesignCodeLists.WeldLocations?.map((codeList) => WeldLocationEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.StiffenerShape] = serviceDesignCodeLists.StiffenerShape?.map((codeList) => StiffenerShape.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.StiffenerLayout] = serviceDesignCodeLists.StiffenerLayout?.map((codeList) => StiffenerLayout.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.StiffenerLayoutPosition] = serviceDesignCodeLists.StiffenerLayoutPositions?.map((codeList) => StiffenerLayoutPosition.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.ProfileStandard] = serviceDesignCodeLists.ProfileStandards?.map((codeList) => ProfileStandard.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.InstallationDirection] = serviceDesignCodeLists.InstallationDirections?.map((codeList) => InstallationDirection.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.WeldingType] = serviceDesignCodeLists.WeldingType?.map((codeList) => WeldingTypeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.CladdingType] = serviceDesignCodeLists.CladdingType?.map((codeList) => CladdingTypeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.CladdingProfile] = serviceDesignCodeLists.CladdingProfiles?.map((codeList) => CladdingProfile.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.DeckThickness] = serviceDesignCodeLists.DeckThicknesses?.map((codeList) => DeckThickness.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.StiffenerCustomProfileSides] = serviceDesignCodeLists.StiffenerCustomProfileSides?.map((codeList) => StiffenerCustomProfileSides.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SupplementaryReinforcementDirectionOfCasting] = serviceDesignCodeLists.SupplementaryReinforcementDirectionsOfCasting?.map((codeList) => SupplementaryReinforcementDirectionOfCasting.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SupplementaryReinforcementType] = serviceDesignCodeLists.SupplementaryReinforcementTypes?.map((codeList) => SupplementaryReinforcementType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SupplementaryReinforcementDiameter] = serviceDesignCodeLists.SupplementaryReinforcementDiameters?.map((codeList) => SupplementaryReinforcementDiameter.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SmartAnchorCategory] = serviceDesignCodeLists.SmartAnchorCategories?.map((codeList) => SmartAnchorCategory.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SmartAnchorApplication] = serviceDesignCodeLists.SmartAnchorApplications?.map((codeList) => SmartAnchorApplication.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SmartAnchorNeedsSolutionTextsAnchorGroup] = serviceDesignCodeLists.SmartAnchorNeedsSolutionTextsAnchorGroup?.map((codeList) => SmartAnchorNeedsSolutionTextsAnchorGroup.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SupplementaryReinforcementCategory] = serviceDesignCodeLists.SupplementaryReinforcementCategories?.map((codeList) => SupplementaryReinforcementCategory.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.HandrailSafetyClass] = serviceDesignCodeLists.HandrailSafetyClass?.map((codeList) => HandrailSafetyClass?.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorPositionsAsad] = serviceDesignCodeLists.AnchorPositionsAsad?.map((codeList) => AnchorPositionsAsadClass?.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.ProfilePositionsAsad] = serviceDesignCodeLists.ProfilePositionsAsad?.map((codeList) => ProfilePositionsAsadClass?.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SeismicIntensityFactor] = serviceDesignCodeLists.SeismicIntensityFactor?.map((codeList) => SeismicIntensityFactor?.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SafetyLevel] = serviceDesignCodeLists.SafetyLevel?.map((codeList) => SafetyLevel?.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.WHGType] = serviceDesignCodeLists.WHGType?.map((codeList) => WHGType?.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.BrickSize] = serviceDesignCodeLists.BrickSizes?.map((codeList) => BrickSizeCodelist?.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.StandOffDesignMethod] = serviceDesignCodeLists.StandOffDesignMethods?.map((codeList) => StandOffDesignMethod?.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.BrickStrengthAC58] = serviceDesignCodeLists.BrickStrengthAc58?.map((codeList) => BrickStrengthAc58?.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.HeadJointSelection] = serviceDesignCodeLists.HeadJointSelection?.map((codeList) => HeadJointSelection?.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.ShearLoadDistributionType] = serviceDesignCodeLists.ShearLoadDistributionType?.map((codeList) => ShearLoadDistributionType?.fromService(codeList)) || [];
        }

        return designCodeLists;
    }

    private updateModel(design: Design, properties: UIProperties) {
        return changeModel(design.model, design.modelChanges, this.changesService, (model) => {
            for (const name in properties) {
                const propertyConfig = properties[name as keyof typeof properties];

                // don't update the model if there's already a change pending
                if (propertyConfig != null && !design.modelChanges.changes.some((change) => change.name == propertyConfig.Property.toString())) {
                    model[propertyConfig.Property] = propertyConfig.Value;
                }
            }
        });
    }

    private updateState(design: Design, stateChange?: StateChange) {
        const oldState = design.currentState;
        const index = design.states.findIndex(state => state === design.currentState);

        design.states[index] = design.currentState = {
            properties: design.properties,
            model: cloneDeep(design.model),
            projectDesign: design.designData.projectDesign,
            projectDesignC2C: design.designData.projectDesignC2C,
            fastenerFamiliesC2C: design.fastenerFamilies,
            reportData: design.designData.reportData,
            reportDataC2C: design.designData.reportDataC2C,
            calculationLanguage: design.calculationLanguage,
            calculateAllData: design.designData.calculateAllData,
            undoRedoActions: design.pendingUndoRedoActions,
            decimalSeparator: getNumberDecimalSeparator(this.localization.numberFormat(), this.userSettings),
            groupSeparator: getNumberGroupSeparator(this.localization.numberFormat(), this.userSettings),
            pendingCalculationResult: design.designData.pendingCalculationResult,
            isReadOnlyDesign: design.isReadOnlyDesign
        };

        // trigger state changed event
        design.trigger(DesignEvent.stateChanged, design, design.currentState, oldState, stateChange);
    }

    private checkConfirmChanges(design: Design, messages: IConfirmChangesEntity[], messagesClosedDeferred: Deferred<void>, calculationType: CalculationType) {
        let messagesToProcess: IConfirmChangesEntity[] = [];

        if (messages?.length > 0) {
            // Changes types with confirm button only
            const confirmOnlyWarnings: { [key: number]: boolean } = {
                [DialogType.CannotDeleteActiveLoadCombination]: true,
                [DialogType.LoadCombinationRowMaximumReached]: true,
                [DialogType.PlasteredWallVerticalJointsCombination]: true,
                [DialogType.HammerDrillingHollowBricksCombination]: true,
                [DialogType.MultiWytheHollowSeismicCombinationDialog]: true,
                [DialogType.OptimizationUnsuccessful]: true,
                [DialogType.AdjustmentUnsuccessful]: true,
                [DialogType.FindSolutionUnsuccessful]: true,
                [DialogType.ValueChanged]: true,
                [DialogType.CSADesignMethodUpdated]: true,
                [DialogType.RotaryDrillingSolidBricksCombination]: true,
                [DialogType.UnconstrainedTopMinAnchorDistance]: true,
                [DialogType.MasonryDesignMethodGroupUpdateToETA]: true,
                [DialogType.RisaDesignDefaultValueMapping]: true,
                [DialogType.AdvancedCalculationUnsupportedAnchor]: true,
                [DialogType.DefaultValue]: true,
                [DialogType.HandrailProfileFamilySameStandard]: true
            };

            // Get changes with confirm button only and merge them into one change message
            const confirmChangesOnly = messages.filter((msg) => confirmOnlyWarnings[msg.ConfirmDialogType]);
            let msgMash: IConfirmChangesEntity = null;
            if (confirmChangesOnly.length > 0) {
                let messageStrings = '';
                let warningMessage = '';

                switch (calculationType) {
                    case CalculationType.valueChange:
                    case CalculationType.newDesign:
                        if (confirmChangesOnly.length == 1) {
                            messageStrings = escape(confirmChangesOnly[0].Message);
                        }
                        else if (confirmChangesOnly.length > 1) {
                            messageStrings = `<html lang="en"><ul>` + confirmChangesOnly.reduce((acc, msg) => acc + `<li>${escape(msg.Message)}</li>`, '') + '</ul></html>';
                        }
                        break;
                    case CalculationType.openDesign:
                        if (confirmChangesOnly.length > 1) {
                            messageStrings = `<html lang="en"><ul>` + confirmChangesOnly.reduce((acc, msg) => acc + `<li>${escape(msg.Message)}</li>`, '') + '</ul>';
                            warningMessage = `<p>${escape(this.localization.getString('Agito.Hilti.Profis3.Design.ConfirmChange.WarningMessage'))}</p></html>`;
                        }
                        else if (confirmChangesOnly.length == 1) {
                            messageStrings = `<html lang="en"><p>${escape(confirmChangesOnly[0].Message)}</p>`;
                            warningMessage = `<p>${escape(this.localization.getString('Agito.Hilti.Profis3.Design.ConfirmChange.WarningMessage'))}</p></html>`;
                        }
                        break;
                    default:
                        throw new Error('Unsupported calculation type');
                }

                msgMash = ({
                    ConfirmDialogType: DialogType.ConfirmationOnlyMergedMessages,
                    Title: this.localization.getString('Agito.Hilti.Profis3.Warning'),
                    Message: `${messageStrings}${warningMessage}`
                } as IConfirmChangesEntity);
            }

            const otherChanges = messages.filter((msg) => !confirmOnlyWarnings[msg.ConfirmDialogType]);

            if (msgMash != null) {
                messagesToProcess.push(msgMash);
            }
            messagesToProcess = [...messagesToProcess, ...otherChanges];
        }

        const confirmChangesEntity = messagesToProcess.length > 0 ? messagesToProcess[0] : null;
        if (confirmChangesEntity != null) {
            const onClose = () => {
                this.checkConfirmChanges(design, messagesToProcess.slice(1), messagesClosedDeferred, calculationType);
            };

            if (confirmChangesEntity.Message != '' && confirmChangesEntity.Title != '') {
                switch (confirmChangesEntity.ConfirmDialogType) {
                    case DialogType.ConfirmationOnlyMergedMessages:
                    case DialogType.FloatingLicenseUpdateSuccess: {
                        this.modalService.openConfirmChange({
                            id: 'ConfirmationOnlyMergedMessages',
                            title: confirmChangesEntity.Title,
                            message: confirmChangesEntity.Message,
                            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Ok'),
                            onConfirm: (modal) => {
                                design.trigger(DesignEvent.designChangesConfirmed);
                                modal.close();
                            }
                        }).closed.then(onClose);
                        break;
                    }
                    case DialogType.FloatingLicenseUpdateFail: {
                        this.modalService.openAlertWarning(
                            confirmChangesEntity.Title,
                            confirmChangesEntity.Message
                        ).closed.then(onClose);
                        break;
                    }
                    case DialogType.ETAGRedesignLoads: {
                        this.modalService.openConfirmChange({
                            id: 'ETAGRedesignLoads',
                            title: confirmChangesEntity.Title,
                            message: confirmChangesEntity.Message,
                            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Ok'),
                            onConfirm: (modal) => {
                                modal.close();
                                this.onConfirmRedefineLoads(design);
                            }
                        }).closed.then(onClose);
                        break;
                    }
                    case DialogType.ACISeismic:
                    case DialogType.LRFDRedesignLoads: {
                        this.modalService.openConfirmChange({
                            id: confirmChangesEntity.ConfirmDialogType ? 'ACISeismic' : 'LRFD',
                            title: confirmChangesEntity.Title,
                            message: confirmChangesEntity.Message,
                            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Yes'),
                            cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.No'),
                            onConfirm: (modal) => {
                                modal.close();
                                this.modalService.openLoadsInput();
                            },
                            onCancel: (modal) => {
                                modal.close();
                            }
                        }).closed.then(onClose);
                        break;
                    }
                    case DialogType.AnchorReinforcement: {
                        const opened = this.modalService.openConfirmChange({
                            id: 'AnchorReinforcement',
                            title: confirmChangesEntity.Title,
                            message: confirmChangesEntity.Message,
                            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Yes'),
                            cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.No'),
                            onConfirm: (modal) => {
                                modal.close();
                            },
                            onCancel: (modal) => {
                                this.onCancelChangeAnchorReinforcement(design, 'no-click');
                                modal.close();
                            }
                        });

                        opened.result.then(() => this.onCancelChangeAnchorReinforcement.bind(this));
                        opened.result.catch(() => this.onCancelChangeAnchorReinforcement.bind(this));
                        opened.closed.then(onClose);
                        break;
                    }
                    case DialogType.FireConditionChanged: {
                        this.modalService.openConfirmChange({
                            id: 'FireConditionChanged',
                            title: confirmChangesEntity.Title,
                            message: confirmChangesEntity.Message,
                            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Yes'),
                            cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.No'),
                            onConfirm: (modal) => {
                                this.onConfirmFireCondition(design);
                                modal.close();
                            },
                            onCancel: (modal) => {
                                modal.close();
                            }
                        }).closed.then(onClose);
                        break;
                    }
                    case DialogType.SeismicConcreteToCracked: {
                        this.modalService.openConfirmChange({
                            id: 'SeismicConcreteToCracked',
                            title: confirmChangesEntity.Title,
                            message: confirmChangesEntity.Message,
                            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Yes'),
                            cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.No'),
                            onConfirm: async (modal) => {
                                // Update model and run calculation
                                await this.calculateAsync(design,
                                    () => {
                                        design.concreteCharacteristicValue = ConcreteCharacteristic.Cracked;
                                    }
                                );

                                modal.close();
                            },
                            onCancel: (modal) => {
                                modal.close();
                            }
                        }).closed.then(onClose);
                        break;
                    }
                    case DialogType.DiamondDrillingWithRoughening: {
                        this.modalService.openConfirmChange({
                            id: 'DiamondDrillingWithRoughening',
                            title: confirmChangesEntity.Title,
                            message: confirmChangesEntity.Message,
                            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Ok'),
                            cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.No'),
                            onConfirm: async (modal) => {
                                // Update model and run calculation
                                await this.calculateAsync(design,
                                    () => {
                                        design.drillingMethod = DrillingMethod.DiamondRoughened;
                                    }
                                );

                                modal.close();
                            },
                            onCancel: (modal) => {
                                modal.close();
                            }
                        }).closed.then(onClose);
                        break;
                    }
                    case DialogType.FreeStandingWall: {
                        this.modalService.openConfirmChange({
                            id: 'FreeStandingWall',
                            title: confirmChangesEntity.Title,
                            message: confirmChangesEntity.Message,
                            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Yes'),
                            cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.No'),
                            onConfirm: async (modal) => {
                                // Update model and run calculation
                                await this.calculateAsync(design,
                                    () => {
                                        design.isFreeStandingWall = true;
                                    }
                                );

                                modal.close();
                            },
                            onCancel: (modal) => {
                                modal.close();
                            }
                        }).closed.then(onClose);
                        break;
                    }
                    case DialogType.EarthquakeComponentIrrelevantWizardHNA: {
                        this.modalService.openConfirmChange({
                            id: 'EarthquakeComponentIrrelevantWizardHNA',
                            title: confirmChangesEntity.Title,
                            message: confirmChangesEntity.Message,
                            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Yes'),
                            cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.No'),
                            onConfirm: (modal) => {
                                modal.close();
                            },
                            onCancel: (modal) => {
                                this.allLoadsToStatic(design);
                                modal.close();
                            }
                        }).closed.then(onClose);
                        break;
                    }
                    case DialogType.NoEarthquakeComponentWizardHNA: {
                        this.modalService.openConfirmChange({
                            id: 'NoEarthquakeComponentWizardHNA',
                            title: confirmChangesEntity.Title,
                            message: confirmChangesEntity.Message,
                            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Yes'),
                            cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.No'),
                            onConfirm: (modal) => {
                                this.allLoadsToSeismic(design);
                                modal.close();
                            },
                            onCancel: (modal) => {
                                this.allLoadsToStatic(design);
                                modal.close();
                            }
                        }).closed.then(onClose);
                        break;
                    }
                    case DialogType.WizardHNASeismicLoadTypeNoEarthquakeDialog: {
                        this.wizardHNALoadTypeSeismicDialogChain(design, onClose);
                        break;
                    }
                    case DialogType.DefaultCalculationMethod: {
                        // in case of navigation tour, don't display Default calculation method window
                        if (!this.tourService.isNavigationTourInProgress) {
                            this.modalService.openDefaultCalculationMethod(design, AdvancedCalculationType.Realistic).closed.then(onClose);
                        }
                        break;
                    }

                    case DialogType.DefaultCalculationMethodBPRigidityCheck: {
                        // in case of navigation tour, don't display Default calculation method window
                        if (!this.tourService.isNavigationTourInProgress) {
                            this.modalService.openDefaultCalculationMethod(design, AdvancedCalculationType.BPRigidityCheck).closed.then(onClose);
                        }
                        break;
                    }
                    case DialogType.DG1BasePlateAnalysis: {
                        this.modalService.openConfirmChange({
                            id: 'design-guide-one-base-plate-analysis',
                            title: confirmChangesEntity.Title,
                            message: confirmChangesEntity.Message.replace('{dg1ExplanationLink}', environment.dg1ExplanationLink),
                            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.DG1BasePlateAnalysis.Confirm'),
                            cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.DG1BasePlateAnalysis.Cancel'),
                            onConfirm: (modal) => {
                                modal.close();
                            },
                            onCancel: async (modal) => {
                                // Update model and run calculation
                                await this.calculateAsync(design,
                                    () => {
                                        design.calculationType = AdvancedCalculationType.Rigid;
                                    }
                                );

                                modal.close();
                            }
                        }).closed.then(onClose);
                        break;
                    }
                    case DialogType.SmartAnchorToggle: {
                        this.modalService.openConfirmChange({
                            id: 'SmartAnchorToggle',
                            title: confirmChangesEntity.Title,
                            message: confirmChangesEntity.Message,
                            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Ok'),
                            cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.Cancel'),
                            onConfirm: async (modal) => {
                                // Update model and run calculation
                                if (design.model[IUIProperty.AnchorPlate_CalculationType] != AdvancedCalculationType.Rigid) {
                                    design.model[IUIProperty.AnchorPlate_CalculationType] = AdvancedCalculationType.Rigid;
                                }
                                if (design.model[IUIProperty.Loads_LoadType] != LoadType.Static) {
                                    design.model[IUIProperty.Loads_LoadType] = LoadType.Static
                                }
                                if (design.loadCombinations?.filter(x => x.ActiveLoadType != LoadType.Static)?.length > 0) {
                                    design.loadCombinations?.filter(x => x.ActiveLoadType != LoadType.Static)
                                        .forEach(load => load.ActiveLoadType = LoadType.Static)
                                }
                                if (design.model[IUIProperty.AnchorPlate_PlateShape] != PlateShape.Rectangular) {
                                    design.model[IUIProperty.AnchorPlate_PlateShape] = PlateShape.Rectangular;
                                }
                                const designStandard = design.model[PropertyMetaData.Option_DesignStandard.id];
                                const designMethod = design.model[PropertyMetaData.Option_DesignMethodGroupId.id];
                                if ([DesignStandardEnum.EC2,
                                    DesignStandardEnum.IS,
                                    DesignStandardEnum.MS ,
                                    DesignStandardEnum.NZ].includes(designStandard as DesignStandardEnum) &&
                                    designMethod != DesignMethodGroupEnum.EN_Based) {
                                    design.model[IUIProperty.Loads_DesignMethodSelection] = DesignMethodGroupEnum.EN_Based;
                                }
                                if (design.model[PropertyMetaData.Option_DesignStandard.id] == DesignStandardEnum.ACI &&
                                    !design.properties.get(PropertyMetaData.Loads_DesignMethodHNA_Asad.id).allowedValues.includes(design.designMethodHNA)) {
                                    design.model[IUIProperty.Option_DesignMethodGroupId] = DesignMethodGroupEnum.ACI31819;
                                }
                                if (!design.model[PropertyMetaData.Profile_UseProfile.id]) {
                                    design.model[IUIProperty.Profile_UseProfile] = true;
                                }

                                if (design.model[PropertyMetaData.Option_DesignStandard.id] == DesignStandardEnum.HK &&
                                    !design.properties.get(PropertyMetaData.Option_DesignMethodGroupId_Asad.id).allowedValues.includes(design.designMethodGroup.id))
                                {
                                    design.model[IUIProperty.Option_DesignMethodGroupId] = DesignMethodGroupEnum.ETAG_001_FOS3;
                                }
                                if (design.model[PropertyMetaData.Option_DesignStandard.id] == DesignStandardEnum.SATS &&
                                    design.model[PropertyMetaData.Option_DesignMethodGroupId.id] != DesignMethodGroupEnum.AS5216
                                ) {
                                    design.model[IUIProperty.Option_DesignMethodGroupId] = DesignMethodGroupEnum.AS5216;
                                }

                                design.model[IUIProperty.SmartAnchor_Enabled] = true;

                                if (design.model[PropertyMetaData.Option_DesignStandard.id] == DesignStandardEnum.ACI || design.model[PropertyMetaData.Option_DesignStandard.id] == DesignStandardEnum.CSA)
                                    this.resetSeismicDetails(design);

                                this.calculateAsync(design, null, null);
                                modal.close();
                            },
                            onCancel: (modal) => {
                                modal.close();
                            }
                        }).closed.then(onClose);
                        break;
                    }
                    case DialogType.ShearLoadDistributionType: {
                        const dialogHelper = new DialogHelper(
                            this.localization,
                            this.modalService
                        );
                        dialogHelper.handleShearLoadDistributionType(
                            design,
                            () => this.undo(design),
                            onClose
                        );
                        break;
                    }
                    default: {
                        this.logger.log('Unimplemented DialogType: ' + confirmChangesEntity.ConfirmDialogType, LogType.warn);
                        onClose();
                        break;
                    }
                }
            }
            else {
                // TODO: pick another entity?
            }
        }
        else {
            design.confirmChangeInProgress = false;
            if (messagesClosedDeferred != null) {
                messagesClosedDeferred.resolve();
            }
        }
    }

    private checkConfirmSelection(design: Design, message: IConfirmationEntity, messagesClosedDeferred: Deferred<void>) {
        const onClose = () => {
            if (messagesClosedDeferred != null) {
                messagesClosedDeferred.resolve();
            }
        };

        if (message.Message != '') {
            if (message.ReinputDialogPropertyId == DialogType.ConfirmationETAGOnly) {
                const opened = this.modalService.openConfirmChange({
                    id: 'ConfirmationETAGOnly',
                    title: message.Title,
                    message: message.Message,
                    confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Ok'),
                    cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.No'),
                    onConfirm: (modal) => {
                        this.onConfirmChangeETAGOnly(design).then(modal.close);
                    },
                    onCancel: (modal) => {
                        this.onDisconfirmChangeETAGOnly(design, 'no-click');
                        modal.close();
                    }
                });

                opened.result.then(() => this.onDisconfirmChangeETAGOnly.bind(this));
                opened.result.catch(() => this.onDisconfirmChangeETAGOnly.bind(this));
                opened.closed.then(onClose);
            }
            else {
                this.logger.log('Unimplemented DialogType: ' + message.ReinputDialogPropertyId, LogType.warn);
            }
        }
        else {
            design.confirmChangeInProgress = false;
        }
    }

    private onCancelChangeAnchorReinforcement(design: Design, reason: string) {
        if (reason == 'no-click' || reason == 'x-click' || reason == MODAL_DISMISS_REASON_BACKDROP || reason == MODAL_DISMISS_REASON_ESC) {
            // Update model and run calculation
            design.isAnchorReinforcement = false;
            this.calculateAsync(design);
        }
    }

    private allLoadsToSeismic(design: Design) {
        design.loadCombinations.forEach(load => { load.ActiveLoadType = LoadType.Seismic; });

        // Run calculation
        this.calculateAsync(design);
    }

    private allLoadsToStatic(design: Design) {
        design.loadCombinations.forEach(load => { load.ActiveLoadType = LoadType.Static; });

        // Run calculation
        this.calculateAsync(design);
    }

    private onConfirmFireCondition(design: Design) {
        if (design.anchorPlateShape == PlateShape.Custom) {
            design.anchorPlateShape = PlateShape.Rectangular;
        }

        if (design.anchorLayout == null || design.anchorLayout == AnchorLayout.Custom) {
            design.anchorLayout = AnchorLayout.FourAnchorsRect;
        }

        if (design.filledHolesSOFA) {
            design.filledHolesSOFA = false;
        }

        if (!design.isFireDesign) {
            design.loadType = LoadType.Fire;
        }
        else {
            design.isLoadCombinationActive = false;
        }

        // Run calculation
        this.calculateAsync(design);
    }

    private onConfirmRedefineLoads(design: Design) {
        if (!design.isLoadCombinationActive || design.loadCombinations.length == 1) {
            this.modalService.openLoadsInput();
        }
        else {
            const row = design.loadCombinations.find((load) => load.ActiveLoadType == LoadType.Seismic && load.Id == design.selectedLoadCombinationId);
            if (row != null) {
                // Update model and run calculation
                design.selectedLoadCombinationId = row.Id;
                this.calculateAsync(design);
            }
        }
    }

    private onConfirmChangeETAGOnly(design: Design) {
        design.confirmedProperties.push(PropertyMetaData.Option_DesignMethodGroupId.id);

        return design.addModelChange(PropertyMetaData.Option_DesignMethodGroupId.id, true, design.model[PropertyMetaData.Option_DesignMethodGroupId.id])
            .then(response => response.messagesClosed);
    }

    private onDisconfirmChangeETAGOnly(design: Design, reason: string) {
        if (reason == 'no-click' || reason == 'x-click' || reason == MODAL_DISMISS_REASON_BACKDROP || reason == MODAL_DISMISS_REASON_ESC) {
            design.reloadState();
        }
    }

    private submitWizardRelatedData(design: Design, seismicDesign: boolean, tensionType: SeismicTensionType, shearType: SeismicShearType) {
        // Save wizard data and trigger calculation
        if (!seismicDesign) {
            design.loadType = LoadType.Static;
        }

        if (tensionType != null) {
            design.seismicTensionType = tensionType;
        }

        if (shearType != null) {
            design.seismicShearType = shearType;
        }

        this.calculateAsync(design, null, { suppressLoadingFlag: true });
    }

    private prepareModalGrid(designCodeLists: ICodeLists, codeList: number, title: string, allowedValues: number[]) {
        const itemMapping = ModalGridHelper.getPopupGridItemMapping(this.localization, this.unitService, codeList);

        const allItems = ModalGridHelper.createPopupGridItems(designCodeLists[codeList], itemMapping);

        // filter group items
        const filteredItems = allItems.filter((item) => allowedValues != null ? allowedValues.includes(item.value) : true);

        const props: IModalGridComponentInput<IModalGridItem<number>> = {
            popupTitle: title,
            items: filteredItems,
            selectedItem: allItems != null && allItems.length > 0
                ? allItems[0]
                : undefined,
            numberOfColumns: 1,
            preventClose: true
        };
        return props;
    }

    private createInternal(design: Design, dataEntity: NewDesignDataEntity) {
        const url = `${environment.baseplateCalculationWebServiceUrl}NewDesign`;

        dataEntity.Language = this.localization.selectedLanguage;
        dataEntity.NumberDecimalSeparator = getNumberDecimalSeparator(this.localization.numberFormat(), this.userSettings);
        dataEntity.NumberThousandsSeparator = getNumberGroupSeparator(this.localization.numberFormat(), this.userSettings);
        dataEntity.UIVersion = environment.applicationVersion;
        dataEntity.CustomerOriginId = this.user.authentication.customerOriginId;
        dataEntity.CountryCode = this.user.authentication.country;

        return this.apiService.request<IStaticDesignData>(new HttpRequest('POST', url, dataEntity))
            .then((response) => {
                // set data
                resetModel(design);
                const messagesClosedDeferred = new Deferred();
                this.updateCalculationData(design, response.body.CalculationResults, dataEntity.Language, CalculationType.newDesign, messagesClosedDeferred);
                design.designData.anchorsMarkedAsNew = response.body.AnchorsMarkedAsNew;

                return saveDesignStateInternal(design, messagesClosedDeferred, this.userSettings, this.localization);
            })
            .catch((response) => {
                this.logger.logServiceError(response, 'Design', 'createDesign');

                throw response;
            });
    }

    private setDesign(
        design: Design,
        documentDesign: IBaseDesign,
        trackingEnabled = true) {

        setDesignFromDocumentDesign(design, documentDesign);
        design.projectOpenType = ProjectOpenType.OpenExisting;
        this.setDesignLocation(design, documentDesign.projectId);

        this.signalr.setHubConnections();

        design.usageCounter.DateAccessed = new Date();

        this.trackOnDesignOpen(trackingEnabled, design);
    }

    private setDesignLocation(design: Design, projectId: string) {
        if (this.documentService.draftsProject.id == projectId) {
            design.designLocation = DesignLocationType.Drafts;
            return;
        }

        const project = this.documentService.findProjectById(projectId);
        if (project.isSharedByMe) {
            design.designLocation = DesignLocationType.SharedByMe;
        }
        else if (!project.owner) {
            design.designLocation = DesignLocationType.SharedWithMe;
        }
        else if (!project.isCompanyProject) {
            design.designLocation = DesignLocationType.MyProjects;
        }
    }

    private trackOnDesignOpen(trackingEnabled: boolean, design: Design) {
        if (!trackingEnabled) {
            return;
        }

        this.tracking.trackOnDesignOpen(design)
            .then(() => {
                this.tracking.saveTrackingActData(design);
            });
    }

    private getDesignDeps() {
        return {
            logger: this.logger,
            guid: this.guid,
            changes: this.changesService,
            user: this.user,
            localization: this.localization,
            commonCodeList: this.codeListCommon,
            modulesService: this.modulesService,
            moduleTrackingService: this.tracking,

            codeList: this.codeList,
            calculation: this,

            codeListC2C: undefined,
            calculationC2C: undefined
        } as IAllDesignDeps;
    }

    private undoRedoCommon(design: Design, redoStep: boolean) {
        let promise: Promise<void>;

        if (!design.isTemplate) {
            promise = this.documentService.updateDesign(design);
        }
        else {
            const anchorName = design.anchorType?.name != null
                && design.anchorSize?.name != null ? design.anchorType.name + ' ' + design.anchorSize.name : null;

            promise = this.designTemplateService.update({
                designTemplateDocumentId: design.templateId,
                designTypeId: design.designData.projectDesign.ProjectDesignType,
                designStandardId: design.designData.projectDesign.Options.DesignStandard,
                regionId: design.designData.projectDesign.Options.RegionId,
                templateName: design.templateName,
                anchorName: anchorName,
                approvalNumber: design.approvalNumber,
                projectDesign: JSON.stringify(design.designData.projectDesign)
            });
        }

        return promise.catch((err) => {
            if (err instanceof Error) {
                console.error(err);
            }

            // undo redo
            if (redoStep) {
                this.loadState(design, design.states.findIndex((state) => state === design.currentState) - 1);
            }
            else {
                this.loadState(design, design.states.findIndex((state) => state === design.currentState) + 1);
            }
        });
    }

    private resetSeismicDetails(design: Design) {
        const expressionToCheckSeismicEquations = '(E)';
        this.removeSeismicLoadsEquations(design, expressionToCheckSeismicEquations);

        this.removeSeismicLoadCombinations(design, expressionToCheckSeismicEquations);

        this.removeSeismicFactors(design);
    }

    private removeSeismicLoadsEquations(design: Design, expressionToCheckSeismicEquations: string) {
        const seismicEquationIdsFromCodeList = (design.designData.designCodeLists[DesignCodeList.LoadCombinationHNAEquation] as LoadCombinationHNAEquation[])
            .filter(x => x.designMethodHNA == design.designMethodHNA && x.descriptionFormat.includes(expressionToCheckSeismicEquations))
            .map(x => x.id);
        const finalEquations = design.loadCombinationHNAWizard.LoadCombinationHNAWizardEquations.filter(x => !seismicEquationIdsFromCodeList?.includes(x));
        design.loadCombinationHNAWizard.LoadCombinationHNAWizardEquations = finalEquations;
    }

    private removeSeismicLoadCombinations(design: Design, expressionToCheckSeismicLoadCombination: string) {

        design.loadCombinations?.filter(x => x.Description != null && !x.Description.includes(expressionToCheckSeismicLoadCombination));
        if (!design.loadCombinations) {
            const defaultLoadCombination: LoadCombination = this.createDefaultLoadCombination(design);
            design.loadCombinations.push(defaultLoadCombination);
        }
    }

    private createDefaultLoadCombination(design: Design): LoadCombination {
        return {
            Id: this.guid.new(),
            Name: '',
            Description: undefined as unknown as string,
            ActiveLoadType: design.isDynamicFatigue && design.isFatigueExpertMode ? LoadType.Fatigue : LoadType.Static,
            ForceX: 0,
            ForceY: 0,
            ForceZ: 0,
            MomentX: 0,
            MomentY: 0,
            MomentZ: 0,
            DynamicForceX: 0,
            DynamicForceY: 0,
            DynamicForceZ: 0,
            DynamicMomentX: 0,
            DynamicMomentY: 0,
            DynamicMomentZ: 0,
            ResultMessages: [] as any[],
            IsWizardGenerated: false,
            HasSustainedLoads: design.loadCombinations?.some(l => l.HasSustainedLoads),
            LoadCharacteristic: undefined as unknown as LoadCharacteristic
        };
    }

    private removeSeismicFactors(design: Design) {
        const expressionToCheckSeismicFactors = '.E';
        const seismicFactorIdsFromCodeList = (design.designData.designCodeLists[DesignCodeList.LoadCombinationHNAFactors] as LoadCombinationHNAFactors[])
            .filter(x => x.designMethodHNA == design.designMethodHNA && x.displayKey.includes(expressionToCheckSeismicFactors))
            .map(x => x.id);
        const finalFactors = design.loadCombinationHNAWizard.LoadCombinationHNAWizardFactors.filter(x => !seismicFactorIdsFromCodeList?.includes(x.Id));
        design.loadCombinationHNAWizard.LoadCombinationHNAWizardFactors = finalFactors;
    }
}
