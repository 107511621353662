import { ButtonSize, ButtonType } from '@profis-engineering/pe-ui-common/entities/main-menu/button-props';
import * as React from 'react';

import { ControlHeader } from '../../ControlHeader';
import { IControlProps } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { isControlHidden } from '../../MainMenuHelper';
import { Button } from '../Button/Button';

export interface IPopupProps extends IControlProps {
    selectedValue?: number;
    text?: string;
    buttonType?: ButtonType;
    buttonSize?: ButtonSize;
    clicked?: (selectedValue: number) => void;
}

export class Popup extends React.PureComponent<IPopupProps> {
    constructor(props?: IPopupProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    public override render() {
        if (isControlHidden(this.props)) {
            return null;
        }

        const buttonId = `${this.props.controlId}-button`;

        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled ? this.props.tooltipDisabled : this.props.tooltip;

        return (
            <div data-control-id={this.props.controlId} className={`react-popup-control control ${this.props.sizeClass}`}>
                <ControlHeader
                    text={this.props.title}
                    controlId={buttonId}
                    tooltip={tooltipText}
                    tooltipTitle={tooltipTitle}
                    localization={this.props.localization}
                />

                <div className='control-container'>
                    <Button
                        controlId={buttonId}
                        buttonSize={this.props.buttonSize}
                        buttonType={this.props.buttonType}
                        disabled={this.props.disabled}
                        hidden={this.props.hidden}
                        image={null}
                        text={this.props.text}
                        type={Button}
                        title={null}
                        size={null}
                        localization={this.props.localization}
                        clicked={this.onClick}
                        tooltip={this.props.title != null && this.props.title != '' ? null : this.props.tooltip}
                        tooltipTitle={this.props.title != null && this.props.title != '' ? null : this.props.tooltipTitle}
                    />
                </div>
            </div>
        );
    }

    private onClick() {
        if (this.props.clicked != null) {
            this.props.clicked(this.props.selectedValue);
        }
    }
}
