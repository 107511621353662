<div class="modal-header">
    <h3 class="modal-title" l10n="Agito.Hilti.Profis3.ShortcutIconPopup.Title"></h3>

    <button type="button" class="close" (click)="close()">
        <span>&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="shortcut-icon-popup" l10n="Agito.Hilti.Profis3.ShortcutIconPopup.Description">
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="button button-primary" l10n="Agito.Hilti.Profis3.Ok" (click)="close()"></button>
</div>
