<div class="modal-header">
    <h3 class="modal-title" l10n="Agito.Hilti.Profis3.RenameDesign.Title"></h3>

    <button type="button" id="rename-design-close-button" class="close" (click)="close()"
        [disabled]="submitted">
        <span>&times;</span>
    </button>
</div>

<form class="form" name="form" #ngForm="ngForm" (ngSubmit)="save(ngForm)" novalidate>
    <div class="modal-body">
        <div class="section type-general">
            <div class="two-rows">
                <pe-text-box
                    id="rename-design-name-wrapper"
                    [id]="nameTextBox.id"
                    [look]="nameTextBox.look"
                    [title]="nameTextBox.title"
                    [validators]="nameTextBox.validators"
                    [maxLength]="nameTextBox.maxLength"
                    [value]="nameTextBox.value"
                    (valueChange)="nameTextBox.value = $any($event).detail"
                    [validationData]="nameTextBox.validationData"
                    (enterPressed)="save(ngForm)"
                    (isValid)="nameTextBox.isValid = $any($event).detail"
                    [disabled]="submitted">
                </pe-text-box>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" id="rename-design-cancel-button" class="modal-button button button-default"
            l10n="Agito.Hilti.Profis3.CopyDesign.Cancel" (click)="close()" [disabled]="submitted">
        </button>

        <button type="submit" id="rename-design-rename-button" class="modal-button button button-CTA"
            [disabled]="!formValid || (ngForm.enabled && !ngForm.valid) || submitted">
            <pe-loading-button
                [loading]="submitted"
                [text]="'Agito.Hilti.Profis3.RenameDesign.Rename' | l10n:localization.selectedLanguage">
            </pe-loading-button>
        </button>
    </div>
</form>
