import { Injectable } from '@angular/core';
import { InitialDataServiceInjected } from '@profis-engineering/pe-ui-shared/services/initial-data.service.base';

// MODULARIZATION: this file can be removed once loading initial data is moved from pe-ui to modules.

@Injectable({
    providedIn: 'root'
})
export class InitialDataService extends InitialDataServiceInjected {
}
