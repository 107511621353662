<div class="project-and-design-view">

    <pe-main-header
        class="main-header"
        #mainHeaderRef
        [startTour]="startTour"
        [useNewHomePage]="false">
    </pe-main-header>

    <div class="content">
        <div class="left-navigation">
            <div id="left-navigation-menu" class="left-navigation-menu">
                <div class="tab-menu">
                    <div class="tabs-menu-scroll">
                        <ng-container *ngFor="let item of leftNavigation">
                            <button [disabled]="importing || disabled || pendingAction != null"
                                id="left-navigation-button-{{item.id}}" (click)="item.click()" class="tab-button"
                                [ngClass]="leftNavigationSelectedButton === item ? 'tab-button-selected' : ''"
                                type="button" [openDelay]="0" placement="right"
                                [ngbTooltip]="translate(item.tooltip)" *ngIf="item.visible()">
                                <div class="tab-button-content">
                                    <div class="tab-button-image">
                                        <div class="image image-menu">
                                            <div class="sprite" [ngClass]="item.image"></div>
                                        </div>
                                    </div>
                                    <span class="tab-button-red-dot" *ngIf="item.showRedDot"></span>
                                </div>
                            </button>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-control">
            <div class="content-control-body">
                <div class="navigation" sortablejs [sortablejsOptions]="sortableLeftMenuProject"
                    (sortablejsInit)="sortableLeftMenuProjectInit($event)">
                    <div class="designs-container navigation-container box-section" [attr.data-id]="sectionsCollapsed.overview.control">
                        <div class="box-section-header">
                            <span class="box-section-title drag-handle-static"
                                [ngbTooltip]="localization.getLocalizedString('Agito.Hilti.Profis3.Main.Region.DragToMove')"
                                l10n="Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Overview"></span>
                            <pe-collapse
                                [container]="'box-section-content'"
                                [collapsed]="sectionsCollapsed.overview.collapsed"
                                (collapsedChange)="onSectionCollapsedChange(sectionsCollapsed.overview, $any($event).detail)">
                            </pe-collapse>
                        </div>

                        <div class="container-content box-section-content">
                            <div id="project-and-design-navigation-view-all-designs-button"
                                class="all-designs design-view-button" (click)="designView = DesignView.allDesigns"
                                [ngClass]="{ 'selected': designView == DesignView.allDesigns }" type="button">
                                <div class="wrapper">
                                    <span class="sprite files"></span>
                                    <span class="text">{{allDesignsButtonTranslation}}</span>

                                    <span class="space"></span>
                                    <div *ngIf="!offlineService.isOffline" class="project-options dropdown-menu-container" ngbDropdown
                                        [ngClass]="{ 'show-loading': isAllDesignsLoading() }">
                                        <button id="project-and-design-navigation-all-designs-dropdown-button"
                                            type="button" class="project-options-button dropdown-menu-button"
                                            [disabled]="pendingAction != null" ngbDropdownToggle>
                                            <span class="sprite sprite-dots"></span>
                                        </button>

                                        <div class="project-options-container" ngbDropdownMenu>
                                            <button id="project-and-design-navigation-all-designs-download-button"
                                                class="download" (click)="downloadAllDesigns()"
                                                l10n="Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Dropdown.Dowload"
                                                type="button" [attr.disabled]="!hasDesign ? true : null" ngbDropdownItem></button>
                                        </div>

                                        <div class="loading-container">
                                            <pe-loading [width]="24" [height]="6" [color]="'#524f53'"></pe-loading>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="project-and-design-navigation-view-templates-button"
                                class="templates design-view-button" (click)="templatesClick()"
                                [ngClass]="{ 'selected': designView == DesignView.templates, 'disabled': templatesDisabled }"
                                type="button" *ngIf="!templatesHidden" [ngbTooltip]="templatesTooltip">
                                <div class="wrapper">
                                    <span class="sprite file"></span>
                                    <span
                                        class="text">{{getOverViewTranslation('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Designs.Templates',
                                        DesignView.templates)}}</span>
                                </div>
                            </div>
                            <div id="project-and-design-navigation-view-drafts-button" class="drafts design-view-button"
                                [attr.data-project-id]="draftsProject.id" (click)="designView = DesignView.drafts"
                                [ngClass]="{ 'selected': designView == DesignView.drafts }" type="button" appDrop
                                [active]="designDropActive" [disabled]="designDropDisabled"
                                *ngIf="!offlineService.isOffline"
                                (designDrop)="dropEvent($event)">
                                <div class="wrapper">
                                    <span class="sprite sprite-draft"></span>
                                    <span
                                        class="text">{{getOverViewTranslation('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Designs.Drafts',
                                        DesignView.drafts)}}</span>
                                    <span class="space"></span>
                                    <div class="project-options dropdown-menu-container" ngbDropdown
                                        [ngClass]="{ 'show-loading': isProjectLoading(draftsProject.id) }">
                                        <button id="project-and-design-navigation-drafts-dropdown-button" type="button"
                                            class="project-options-button dropdown-menu-button"
                                            [disabled]="pendingAction != null" ngbDropdownToggle>
                                            <span class="sprite sprite-dots"></span>
                                        </button>

                                        <div class="project-options-container" ngbDropdownMenu>
                                            <button id="project-and-design-navigation-drafts-download-button"
                                                class="download"
                                                (click)="downloadProject(draftsProject)"
                                                l10n="Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Dropdown.Dowload"
                                                type="button" [attr.disabled]="emptyProject(draftsProject) ? true : null" ngbDropdownItem></button>
                                        </div>

                                        <div class="loading-container">
                                            <pe-loading [width]="24" [height]="6" [color]="'#524f53'"></pe-loading>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="projects-container navigation-container box-section" [attr.data-id]="sectionsCollapsed.projects.control"
                        *ngIf="!offlineService.isOffline">
                        <div class="box-section-header">
                            <span class="box-section-title drag-handle-static"
                                [ngbTooltip]="localization.getLocalizedString('Agito.Hilti.Profis3.Main.Region.DragToMove')">{{getOverViewTranslation('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects',
                                DesignView.projects)}}</span>
                            <pe-collapse
                                [container]="'box-section-content'"
                                [collapsed]="sectionsCollapsed.projects.collapsed"
                                (collapsedChange)="onSectionCollapsedChange(sectionsCollapsed.projects, $any($event).detail)">
                            </pe-collapse>
                        </div>

                        <div class="container-content box-section-content">
                            <div class="no-projects" *ngIf="!hasProject">
                                <p class="title"
                                    l10n="Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Tip.Title"></p>

                                <p class="message"
                                    l10n="Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Tip.Content"></p>
                            </div>

                            <div class="new-project"
                                [ngClass]="{ 'edit-project': editProjectType != null && editProjectType == EditProjectType.new }">
                                <button id="project-and-design-navigation-new-project-button"
                                    class="new-project-button button button-default" type="button"
                                    (click)="newProject()" [disabled]="pendingAction != null"
                                    l10n="Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.NewProject"></button>

                                <input id="project-and-design-navigation-new-project-textfield"
                                    class="edit-project-input input" type="text" maxlength="250"
                                    (blur)="editProjectInputBlur()"
                                    (keypress)="$event.keyCode == 13 ? editProjectConfirm() : undefined"
                                    [(ngModel)]="editProjectName" [disabled]="pendingAction != null" />

                                <div class="edit-project-button-container"
                                    [ngClass]="{ 'show-loading': pendingAction != null }">
                                    <button id="project-and-design-navigation-new-project-ok-button"
                                        class="project-and-design-navigation-button edit-project-ok-button button button-default"
                                        (click)="editProjectConfirm()">
                                        <div class="sprite sprite-ok"></div>
                                    </button>

                                    <button id="project-and-design-navigation-new-project-cancel-button"
                                        class="project-and-design-navigation-button edit-project-cancel-button button button-default"
                                        (click)="editProjectReject()">
                                        <div class="sprite sprite-x"></div>
                                    </button>

                                    <div class="loading-container">
                                        <pe-loading [width]="24" [height]="6" [color]="'#524f53'"></pe-loading>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="addNewCompanyProjectVisible" class="new-project new-company-project"
                                [ngClass]="{ 'edit-project': editProjectType != null && editProjectType == EditProjectType.newCompanyProject }"
                                [ngbTooltip]="getCompanyDisabledTooltip">
                                <button id="project-and-design-navigation-new-company-project-button"
                                    class="new-project-button button button-default" (click)="newCompanyProject()"
                                    [disabled]="pendingAction != null" [ngClass]="{ 'disabled':companyProjectDisabled }"
                                    l10n="Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.NewCompanyProject"
                                    type="button"></button>

                                <input id="project-and-design-navigation-new-company-project-textfield"
                                    class="edit-project-input input" type="text" maxlength="250"
                                    (blur)="editProjectInputBlur()"
                                    (keypress)="$event.keyCode == 13 ? editProjectConfirm() : undefined"
                                    [(ngModel)]="editProjectName" [disabled]="pendingAction != null" />

                                <div class="edit-project-button-container"
                                    [ngClass]="{ 'show-loading': pendingAction != null }">
                                    <button id="project-and-design-navigation-new-company-project-ok-button"
                                        class="project-and-design-navigation-button edit-project-ok-button button button-default"
                                        (click)="editProjectConfirm()">
                                        <div class="sprite sprite-ok"></div>
                                    </button>

                                    <button id="project-and-design-navigation-new-company-project-cancel-button"
                                        class="project-and-design-navigation-button edit-project-cancel-button button button-default"
                                        (click)="editProjectReject()">
                                        <div class="sprite sprite-x"></div>
                                    </button>

                                    <div class="loading-container">
                                        <pe-loading [width]="24" [height]="6" [color]="'#524f53'"></pe-loading>
                                    </div>
                                </div>
                            </div>

                            <div class="search-project">
                                <div class="search-container">
                                    <div class="search-image-container">
                                        <span class="search-image sprite sprite-search"></span>
                                    </div>
                                    <input id="project-and-design-navigation-search-project-textfield"
                                        class="search-project-input input" type="text" maxlength="250"
                                        [placeholder]="projectFilterPlaceholder" (blur)="editProjectInputBlur()"
                                        [(ngModel)]="projectFilterString" />
                                    <button id="project-and-design-navigation-search-project-clear-button"
                                        class="clear-search-button" type="button"
                                        *ngIf="projectFilterString?.trim().length > 0"
                                        (click)="projectFilterString = null; filterProjects()">
                                        <span class="sprite sprite-x-circle"></span>
                                    </button>
                                </div>
                            </div>

                            <div class="projects" *ngIf="projects.length > 0">
                                <div class="projects-scroll">
                                    <div class="project-container"
                                        *ngFor="let project of projects; trackBy: trackProjectById">
                                        <div id="project-and-design-navigation-project-{{project.id}}"
                                            class="project"
                                            [attr.data-project-id]="project.id" tabindex="0"
                                            (click)="setSelectedProject(project)"
                                            (keypress)="$event.keyCode == 13 || $event.keyCode == 32 ? setSelectedProject(project) : undefined"
                                            [ngClass]="{ 'selected': selectedProject.id == project.id, 'read-only': project.readOnly, 'hidden': renameProjectType[project.id] == EditProjectType.rename || !projectFilterResult[project.id]}"
                                            appDrop [active]="designDropActive"
                                            [disabled]="designDropConfigForProject(project)"
                                            (designDrop)="dropEvent($event)">
                                            <span *ngIf="!hasChildProject(project)" class="selector"></span>
                                            <span *ngIf="hasChildProject(project)"
                                                class="sprite project-contents-toggle"
                                                [ngClass]="{'arrow-right': !project.expanded, 'arrow-down': project.expanded}"
                                                (click)="toggleExpandProject(project)"></span>
                                            <span class="sprite"
                                                [ngClass]="{'shared-folder': project.isCompanyProject, 'folder': !project.isCompanyProject}"></span>
                                            <span class="text project-text">{{project.rawProject.getDisplayName(localization)}}</span>
                                            <span class="counter">({{projectItemCount[project.id]}})</span>
                                            <span class="space"></span>
                                            <div class="project-options dropdown-menu-container" ngbDropdown display="static" placement="bottom-left"
                                                (click)="dropdownFromBodyDirection(project.id)"
                                                *ngIf="!project.readOnly"
                                                [ngClass]="{ 'show-loading': isProjectLoading(project.id)}"
                                                (openChange)="onDropdownOpenChanged('project-' + project.id, $event); projectMenuToggled($event)">
                                                <button
                                                    id="project-and-design-navigation-project-{{project.id}}-dropdown-button"
                                                    type="button" class="project-options-button dropdown-menu-button"
                                                    [disabled]="pendingAction != null" ngbDropdownToggle>
                                                    <span class="sprite dots"></span>
                                                </button>

                                                <div id="dropdown-direction-{{project.id}}" class="dropdown-menu project-options-container"
                                                    ngbDropdownMenu>
                                                    <div class="dropdown-from-body"
                                                        *ngIf="displayedDropdown === ('project-' + project.id)">
                                                        <button
                                                            id="project-and-design-navigation-project-{{project.id}}-add-sub-project-button"
                                                            class="add-sub-project"
                                                            *ngIf="project.owner && !project.isCompanyProject"
                                                            [attr.disabled]="project.parentId != null ? true : null"
                                                            (click)="newSubProject(project)"
                                                            l10n="Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Dropdown.AddSubProject"
                                                            type="button" ngbDropdownItem></button>
                                                        <button
                                                            id="project-and-design-navigation-project-{{project.id}}-add-sub-project-button"
                                                            class="add-sub-project"
                                                            *ngIf="project.isCompanyProject"
                                                            [attr.disabled]="(project.parentId != null && companyProjectDisabled) ? true : null"
                                                            (click)="newCompanySubProject(project)"
                                                            l10n="Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Dropdown.AddSubProject"
                                                            type="button" ngbDropdownItem></button>
                                                        <button
                                                            id="project-and-design-navigation-project-{{project.id}}-rename-button"
                                                            class="rename"
                                                            *ngIf="project.owner || project.isCompanyProject"
                                                            (click)="renameProject(project)"
                                                            l10n="Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Dropdown.Rename"
                                                            type="button" ngbDropdownItem></button>
                                                        <div [ngbTooltip]="shareProjectTooltip()">
                                                            <button
                                                                id="project-and-design-navigation-project-{{project.id}}-share-button"
                                                                class="share"
                                                                *ngIf="(project.owner || project.isCompanyProject) && !isShareProjectHidden()"
                                                                [attr.disabled]="isShareProjectDisabled() ? true : null"
                                                                (click)="shareProjectOpen(project)"
                                                                l10n="Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Dropdown.Share"
                                                                type="button" ngbDropdownItem></button>
                                                        </div>
                                                        <button
                                                            id="project-and-design-navigation-project-{{project.id}}-download-button"
                                                            class="download"
                                                            (click)="downloadProject(project.rawProject)"
                                                            l10n="Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Dropdown.Dowload"
                                                            type="button" [attr.disabled]="emptyProject(project.rawProject) ? true : null"
                                                            ngbDropdownItem></button>
                                                        <button
                                                            id="project-and-design-navigation-project-{{project.id}}-archive-button"
                                                            class="archive"
                                                            *ngIf="project.owner || project.isCompanyProject"
                                                            (click)="archiveProject(project)"
                                                            l10n="Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Dropdown.Archive"
                                                            type="button" ngbDropdownItem></button>
                                                        <button
                                                            id="project-and-design-navigation-project-{{project.id}}-delete-button"
                                                            class="delete"
                                                            *ngIf="project.owner || project.isCompanyProject"
                                                            (click)="openDeleteProjectDialog()"
                                                            l10n="Agito.Hilti.Profis3.Delete" type="button"
                                                            [attr.disabled]="!emptyProject(project) ? true : null"
                                                            ngbDropdownItem></button>
                                                        <div [ngbTooltip]="getCompanyDisabledTooltip">
                                                            <button
                                                                id="project-and-design-navigation-project-{{project.id}}-convert-button"
                                                                class="convert"
                                                                *ngIf="project.owner && companyProjectVisible(project)"
                                                                (click)="openConvertProjectDialog()"
                                                                l10n="Agito.Hilti.Profis3.ConvertToCompanyProject"
                                                                type="button" [attr.disabled]="companyProjectDisabled ? true : null"
                                                                ngbDropdownItem>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="selectedProject.id == project.id"  class="loading-container">
                                                    <pe-loading [width]="24" [height]="6" [color]="'#524f53'">
                                                    </pe-loading>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="renameProjectType[project.id] == EditProjectType.rename"
                                            class="rename-project">
                                            <input id="project-and-design-navigation-rename-project-textfield"
                                                class="rename-project-input input" (blur)="renameProjectInputBlur()"
                                                (keypress)="$event.keyCode == 13 ? renameProjectConfirm() : undefined"
                                                [(ngModel)]="editProjectName" [disabled]="pendingAction != null"
                                                type="text" maxlength="250" />

                                            <div class="rename-project-button-container"
                                                [ngClass]="{ 'show-loading': pendingAction != null }">
                                                <button id="project-and-design-navigation-rename-project-ok-button"
                                                    class="project-and-design-navigation-button rename-project-ok-button button button-default"
                                                    (click)="renameProjectConfirm()">
                                                    <div class="sprite sprite-ok"></div>
                                                </button>

                                                <button id="project-and-design-navigation-rename-project-cancel-button"
                                                    class="project-and-design-navigation-button rename-project-cancel-button button button-default"
                                                    (click)="renameProjectReject()">
                                                    <div class="sprite sprite-x"></div>
                                                </button>

                                                <div class="loading-container">
                                                    <pe-loading [width]="24" [height]="6" [color]="'#524f53'">
                                                    </pe-loading>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="new-project edit-project"
                                            *ngIf="editProjectType != null && editProjectType != EditProjectType.none && project.id == newProjectParent?.id">
                                            <input id="project-and-design-navigation-new-sub-project-textfield"
                                                class="edit-project-input input" (blur)="editProjectInputBlur()"
                                                (keypress)="$event.keyCode == 13 ? editProjectConfirm() : undefined"
                                                [(ngModel)]="editProjectName" [disabled]="pendingAction != null"
                                                type="text" maxlength="250" />

                                            <div class="edit-project-button-container"
                                                [ngClass]="{ 'show-loading': pendingAction != null }">
                                                <button id="project-and-design-navigation-new-project-ok-button"
                                                    class="project-and-design-navigation-button edit-project-ok-button button button-default"
                                                    (click)="editProjectConfirm()">
                                                    <div class="sprite sprite-ok"></div>
                                                </button>

                                                <button id="project-and-design-navigation-new-project-cancel-button"
                                                    class="project-and-design-navigation-button edit-project-cancel-button button button-default"
                                                    (click)="editProjectReject()">
                                                    <div class="sprite sprite-x"></div>
                                                </button>

                                                <div class="loading-container">
                                                    <pe-loading [width]="24" [height]="6" [color]="'#524f53'">
                                                    </pe-loading>
                                                </div>
                                            </div>
                                        </div>

                                        <ng-container
                                            *ngFor="let subProject of project.subProjects; trackBy : trackSubprojectById">
                                            <div id="project-and-design-navigation-project-{{subProject.id}}"
                                                *ngIf="projectFilterResult[subProject.id] && project.expanded"
                                                [attr.data-project-id]="subProject.id" class="project sub-project"
                                                tabindex="0" (click)="selectedProject = subProject"
                                                (keypress)="$event.keyCode == 13 || $event.keyCode == 32 ? selectedProject = subProject : undefined"
                                                [ngClass]="{ 'read-only': subProject.readOnly, 'selected': selectedProject.id == subProject.id, 'renaming':  renameProjectType[subProject.id] == EditProjectType.rename}"
                                                appDrop [active]="designDropActive"
                                                [disabled]="designDropConfigForProject(subProject)"
                                                (designDrop)="dropEvent($event)">
                                                <span *ngIf="renameProjectType[subProject.id] != EditProjectType.rename"
                                                    class="selector"></span>

                                                <span class="sprite"
                                                    [ngClass]="{'shared-folder': subProject.isCompanyProject, 'folder': !subProject.isCompanyProject}"
                                                    *ngIf="renameProjectType[subProject.id] != EditProjectType.rename"></span>

                                                <span *ngIf="renameProjectType[subProject.id] != EditProjectType.rename"
                                                    class="text project-text">{{subProject.rawProject.getDisplayName(localization)}}</span>

                                                <span *ngIf="renameProjectType[subProject.id] != EditProjectType.rename"
                                                    class="counter">({{projectItemCount[subProject.id]}})</span>

                                                <span *ngIf="renameProjectType[subProject.id] != EditProjectType.rename"
                                                    class="space">
                                                </span>

                                                <div *ngIf="renameProjectType[subProject.id] != EditProjectType.rename"
                                                    class="project-options dropdown-menu-container" ngbDropdown display="static" placement="bottom-left"
                                                    (click)="dropdownFromBodyDirection(subProject.id)"
                                                    (openChange)="onDropdownOpenChanged('subProject-' + subProject.id, $event)"
                                                    [ngClass]="{ 'show-loading': isProjectLoading(subProject.id)}"
                                                    (openChange)="projectMenuToggled($event)">
                                                    <button
                                                        id="project-and-design-navigation-project-{{subProject.id}}-dropdown-button"
                                                        type="button"
                                                        class="project-options-button dropdown-menu-button"
                                                        [disabled]="pendingAction != null" ngbDropdownToggle>
                                                        <span class="sprite dots"></span>
                                                    </button>

                                                    <div id="dropdown-direction-{{subProject.id}}"
                                                        class="project-options-container"
                                                        ngbDropdownMenu>
                                                        <div *ngIf="displayedDropdown === ('subProject-' + subProject.id)">
                                                            <button
                                                                id="project-and-design-navigation-project-{{subProject.id}}-rename-button"
                                                                class="rename"
                                                                *ngIf="subProject.owner || subProject.isCompanyProject"
                                                                (click)="renameProject(subProject)"
                                                                l10n="Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Dropdown.Rename"
                                                                type="button" ngbDropdownItem></button>
                                                            <div [ngbTooltip]="shareProjectTooltip()"><button
                                                                    id="project-and-design-navigation-project-{{subProject.id}}-share-button"
                                                                    class="share"
                                                                    *ngIf="(project.owner || project.isCompanyProject) && !isShareProjectHidden()"
                                                                    [disabled]="isShareProjectDisabled()"
                                                                    (click)="shareProjectOpen(subProject)"
                                                                    l10n="Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Dropdown.Share"
                                                                    type="button" ngbDropdownItem></button></div>
                                                            <button
                                                                id="project-and-design-navigation-project-{{subProject.id}}-download-button"
                                                                class="download"
                                                                (click)="downloadProject(subProject.rawProject)"
                                                                l10n="Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Dropdown.Dowload"
                                                                type="button"
                                                                [disabled]="emptyProject(subProject.rawProject)" ngbDropdownItem></button>
                                                            <button
                                                                id="project-and-design-navigation-project-{{subProject.id}}-archive-button"
                                                                class="archive"
                                                                *ngIf="subProject.owner || subProject.isCompanyProject"
                                                                (click)="archiveProject(subProject)"
                                                                l10n="Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Dropdown.Archive"
                                                                type="button" ngbDropdownItem></button>
                                                            <button
                                                                id="project-and-design-navigation-project-{{subProject.id}}-delete-button"
                                                                class="delete"
                                                                *ngIf="subProject.owner || subProject.isCompanyProject"
                                                                (click)="openDeleteProjectDialog()"
                                                                l10n="Agito.Hilti.Profis3.Delete" type="button"
                                                                [disabled]="!emptyProject(subProject)" ngbDropdownItem></button>
                                                            <div [ngbTooltip]="getCompanyDisabledTooltip"><button
                                                                    id="project-and-design-navigation-project-{{subProject.id}}-convert-button"
                                                                    class="convert"
                                                                    *ngIf="subProject.owner && companyProjectVisible(subProject)"
                                                                    (click)="openConvertProjectDialog()"
                                                                    l10n="Agito.Hilti.Profis3.ConvertToCompanyProject"
                                                                    type="button"
                                                                    [disabled]="companyProjectDisabled" ngbDropdownItem></button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="loading-container">
                                                        <pe-loading [width]="24" [height]="6" [color]="'#524f53'">
                                                        </pe-loading>
                                                    </div>
                                                </div>

                                                <div *ngIf="renameProjectType[subProject.id] == EditProjectType.rename"
                                                    class="rename-project">
                                                    <input id="project-and-design-navigation-rename-project-textfield"
                                                        class="rename-project-input input"
                                                        (blur)="renameProjectInputBlur()"
                                                        (keypress)="$event.keyCode == 13 ? renameProjectConfirm() : undefined"
                                                        [(ngModel)]="editProjectName" [disabled]="pendingAction != null"
                                                        type="text" maxlength="250" />

                                                    <div class="rename-project-button-container"
                                                        [ngClass]="{ 'show-loading': pendingAction != null }">
                                                        <button
                                                            id="project-and-design-navigation-rename-project-ok-button"
                                                            class="project-and-design-navigation-button rename-project-ok-button button button-default"
                                                            (click)="renameProjectConfirm()">
                                                            <div class="sprite sprite-ok"></div>
                                                        </button>

                                                        <button
                                                            id="project-and-design-navigation-rename-project-cancel-button"
                                                            class="project-and-design-navigation-button rename-project-cancel-button button button-default"
                                                            (click)="renameProjectReject()">
                                                            <div class="sprite sprite-x"></div>
                                                        </button>

                                                        <div class="loading-container">
                                                            <pe-loading [width]="24" [height]="6" [color]="'#524f53'">
                                                            </pe-loading>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                    </div>
                                </div>
                            </div>
                            <div class="project-pagination" *ngIf="isArchiveVisible">
                                <button class="button button-default button-archive" (click)="archivePopupOpen()"
                                    [disabled]="!isArchiveVisible"
                                    [ngbTooltip]="translate('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Archive')"
                                    placement="top">
                                    {{archiveButtonText}}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="box-section" [attr.data-id]="sectionsCollapsed.notificationsMainLeft.control" *ngIf="offlineService.isOffline">
                        <div class="box-section-header">
                            <span class="box-section-title drag-handle-static"
                                [ngbTooltip]="localization.getLocalizedString('Agito.Hilti.Profis3.Main.Region.DragToMove')"
                                l10n="Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Notifications"></span>
                            <pe-collapse
                                [container]="'box-section-content'"
                                [collapsed]="sectionsCollapsed.notificationsMainLeft.collapsed"
                                (collapsedChange)="onSectionCollapsedChange(sectionsCollapsed.notificationsMainLeft, $any($event).detail)">
                            </pe-collapse>
                        </div>

                        <div class="box-section-content right-navigation-box-container right-navigation-box-container-notifications">
                            <div class="notification-container notification-info">
                                <div class="notification-icon sprite sprite-message"></div>
                                <div class="notification-content offline-info">
                                    <div class="text" l10n="Agito.Hilti.Profis3.Notification.Learn"></div>
                                    <button class="button button-primary button-start-tour" (click)="startTour()" l10n="Agito.Hilti.Profis3.Notification.StartTour" type="button"></button>
                                    <button class="button button-default button-go-to-hilti" (click)="openInBrowser()" l10n="Agito.Hilti.Profis3.Notification.GoToHilti" type="button"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main main-control">
                    <div class="box-section box-section-white no-designs main-container" *ngIf="!hasDesign && !offlineService.isOffline">
                        <div class="box-section-content">
                            <div class="container-content">
                                <p class="title" l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.NoDesigns.Title"></p>
                                <p class="sub-title"
                                    l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.NoDesigns.SubTitle"></p>
                                <p class="message" l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.NoDesigns.Content">
                                </p>
                                <button id="project-and-design-main-start-tour-button"
                                    class="tour button button-default" (click)="startTour()"
                                    l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.NoDesigns.Tour"
                                    type="button"></button>
                            </div>
                        </div>
                    </div>

                    <div class="box-section box-section-white quick-start main-container"
                        *ngIf="(designView == DesignView.allDesigns && offlineService.isOffline) || !offlineService.isOffline">
                        <div class="box-section-header">
                            <div class="box-section-title container-header">
                                <span class="title"
                                    l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.Title"></span>

                                <button class="settings-button" type="button" (click)="openQuickStartSettings()"
                                    [ngbTooltip]="translate('Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.Settings.Tooltip')"
                                    placement="top">
                                    <span class="sprite sprite-settings"></span>
                                </button>

                                <ng-container *ngFor="let quickStart of quickStartApplications">
                                    <button id="{{quickStart.idCollapsed}}"
                                        class="button button-default quick-start-button"
                                        (click)="newDesignFromQuickStart(quickStart)" type="button"
                                        *ngIf="sectionsCollapsed.quickStart.collapsed && quickStart.showInCollapsedState && SafeFunctionInvokerHelper.safeInvoke(quickStart.isEnabled, false)"
                                        [disabled]="SafeFunctionInvokerHelper.safeInvoke(quickStart.isDesignTypeDisabled, true) || pendingAction != null"
                                        [ngbTooltip]="SafeFunctionInvokerHelper.safeInvoke(quickStart.getButtonTooltip)">
                                        <pe-loading-button
                                            [loading]="showLoading(quickStart)">
                                            <span class="text">{{quickStart.collapsedTextTranslation ??
                                                translate(quickStart.collapsedTextTranslationKey)}}</span>
                                        </pe-loading-button>
                                    </button>
                                </ng-container>
                            </div>

                            <pe-collapse
                                [container]="'box-section-content'"
                                [collapsed]="sectionsCollapsed.quickStart.collapsed"
                                (collapsedChange)="onSectionCollapsedChange(sectionsCollapsed.quickStart, $any($event).detail)">
                            </pe-collapse>
                        </div>

                        <div class="box-section-content">
                            <div class="container-content">
                                <ng-container *ngFor="let quickStart of quickStartApplications">
                                    <div id="{{ quickStart.idQuickStart }}" class="quick-start-button"
                                        *ngIf="quickStart.showInQuickStart && SafeFunctionInvokerHelper.safeInvoke(quickStart.isEnabled, false)"
                                        (click)="newDesignFromQuickStart(quickStart)"
                                        [ngClass]="{ 'disabled': (SafeFunctionInvokerHelper.safeInvoke(quickStart.isDesignTypeDisabled, true) || pendingAction != null) }"
                                        [ngbTooltip]="SafeFunctionInvokerHelper.safeInvoke(quickStart.getButtonTooltip)">
                                        <div class="image-container"
                                            [ngClass]="{ 'show-loading': showLoading(quickStart) }">
                                            <span class="model3d-image sprite" [ngStyle]="quickStart.imageStyle"></span>

                                            <div class="loading-container">
                                                <pe-loading [color]="'#524f53'"></pe-loading>
                                            </div>

                                            <div class="new-label" *ngIf="quickStart.showNewLabel != null ? SafeFunctionInvokerHelper.safeInvoke(quickStart.showNewLabel, false) : false">
                                                <span class="text" l10n="{{ quickStart.newLabelTranslationKey }}"></span>
                                            </div>
                                        </div>

                                        <div class="content-container"
                                            [ngbTooltip]="(quickStart.designTypeTranslation ?? translate(quickStart.designTypeTranslationKey)) + ' / ' + SafeFunctionInvokerHelper.safeInvoke(quickStart.createRegionDesignStandardApprovalNumber, '')">
                                            <span class="text action"
                                                l10n="{{ quickStart.actionTranslationKey }}"></span>
                                            <span class="text design-type">
                                                {{ quickStart.designTypeTranslation ?? translate(quickStart.designTypeTranslationKey) }}
                                            </span>
                                            <span class="text settings">
                                                {{ SafeFunctionInvokerHelper.safeInvoke(quickStart.createRegionDesignStandardApprovalNumber, '') }}
                                            </span>
                                        </div>

                                        <button type="button"
                                            class="control-tooltip-popup sprite sprite-info-tooltip kbTooltip bottom-right-abs"
                                            *ngIf="quickStart.isKbTooltipEnabled != null ? SafeFunctionInvokerHelper.safeInvoke(quickStart.isKbTooltipEnabled, false) : false"
                                            (click)="openKbLink($event, SafeFunctionInvokerHelper.safeInvoke(quickStart.getKbLink))"
                                            [ngbTooltip]="translate(quickStart.kbTooltipTranslationKey)"></button>

                                    </div>
                                </ng-container>

                                <app-module-quick-start-button
                                    imageSprite="sprite-wood-image"
                                    titleTextTranslationKey="Agito.Hilti.Profis3.ProjectAndDesign.Main.QuickStart.Wood.EntryPoint"
                                    [moduleEnabled]="isWoodModuleShown"
                                    [disabled]="pendingAction != null"
                                    *ngFor="let woodLink of woodLinks; trackBy : trackApplicationById"
                                    (click)="openWoodLinks(woodLink.Url)">
                                </app-module-quick-start-button>

                                <app-module-quick-start-button
                                    *ngFor="let externalApp of externalAppsService.externalApps; trackBy : trackApplicationById"
                                    imageSrc="{{externalApp.Image}}"
                                    titleText="{{externalAppsService.getExternalAppTitle(externalApp)}}"
                                    [moduleEnabled]="true"
                                    [disabled]="pendingAction != null"
                                    [displayIsNewLabel]="externalApp.ShowNewLabel"
                                    (click)="openExternalAppLink(externalApp.Id)">
                                </app-module-quick-start-button>
                            </div>
                        </div>

                        <div class="box-section-content" *ngIf="offlineService.isOffline">
                            <div class="container-content new-design-box">
                                <div id="quick-start-button-custom-design" class="quick-start-button"
                                    (click)="newDesignClick()"
                                    [ngClass]="{ 'disabled': importing || pendingAction != null }">
                                    <div class="image-container"
                                        [ngClass]="{ 'show-loading': pendingAction == PendingAction.createDesign }">
                                        <span class="model3d-image sprite sprite-concrete-fixing"></span>

                                        <div class="loading-container">
                                            <pe-loading [color]="'#524f53'"></pe-loading>
                                        </div>
                                    </div>

                                    <div class="content-container"
                                        [ngbTooltip]="localization.getLocalizedString('Agito.Hilti.Profis3.ProjectAndDesing.Main.Custom.DesignType') + ' / ' + localization.getLocalizedString('Agito.Hilti.Profis3.ProjectAndDesing.Main.Custom.Info')">
                                        <span class="text action"
                                            l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.Custom.Action"></span>
                                        <span class="text design-type"
                                            l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.Custom.DesignType"></span>
                                        <span
                                            class="text settings">{{localization.getLocalizedString('Agito.Hilti.Profis3.ProjectAndDesing.Main.Custom.Info')}}</span>
                                    </div>
                                </div>

                                <div id="quick-start-button-import" class="quick-start-button"
                                    [ngClass]="{ 'disabled': importing || pendingAction != null }">
                                    <div class="image-container white-background">
                                        <app-import-design [(importing)]="importing" [mode]="importDesign.mode"
                                            [disabled]="importDesign.disabled" [onDesignImporting]="onDesignImporting"
                                            (onDesignImported)="onDesignImported($event)">
                                        </app-import-design>
                                    </div>

                                    <div class="content-container"
                                        [ngbTooltip]="localization.getLocalizedString('Agito.Hilti.Profis3.ProjectAndDesing.Main.Existing.DesignType') + ' / ' + localization.getLocalizedString('Agito.Hilti.Profis3.ProjectAndDesing.Main.Existing.Info')">
                                        <span class="text action"
                                            l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.Existing.Action"></span>
                                        <span class="text design-type"
                                            l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.Existing.DesignType"></span>
                                        <span
                                            class="text settings">{{localization.getLocalizedString('Agito.Hilti.Profis3.ProjectAndDesing.Main.Existing.Info')}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="box-section box-section-white hilti-aplications main-container"
                        *ngIf="isHiltiApplicationsSectionVisible">
                        <div class="box-section-header">
                            <div class="box-section-title container-header">
                                <span class="title"
                                    l10n="Agito.Hilti.Profis3.ProjectAndDesign.Main.HiltiApplications.Title"></span>
                            </div>
                            <pe-collapse
                                [container]="'box-section-content'"
                                [collapsed]="sectionsCollapsed.hiltiApplications.collapsed"
                                (collapsedChange)="onSectionCollapsedChange(sectionsCollapsed.hiltiApplications, $any($event).detail)">
                            </pe-collapse>
                        </div>

                        <div class="box-section-content">
                            <div class="container-content">
                                <div class="hilti-aplications-button"
                                    *ngFor="let application of externalAppsService.hiltiApplications; trackBy : trackApplicationById"
                                    (click)="openHiltiApplication(application.Id)">
                                    <div class="image-container">
                                        <img *ngIf="application.Image != null" class="model3d-image sprite" src="{{application.Image}}" alt="Hilti Application">

                                        <div class="new-label" *ngIf="application.ShowNewLabel">
                                            <span class="text" l10n="Agito.Hilti.Profis3.ProjectAndDesign.HiltiAplications.New"></span>
                                        </div>
                                    </div>
                                    <div class="content-container">
                                        <span class="text design-type">{{ externalAppsService.getHiltiApplicationTitle(application) }}</span>
                                        <span class="text settings">{{ externalAppsService.getHiltiApplicationSubtitle(application) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="box-section box-section-white design-guides main-container"
                        *ngIf="designGuides.length > 0">
                        <div class="box-section-header">
                            <div class="box-section-title container-header">
                                <span class="title"
                                    l10n="Agito.Hilti.Profis3.ProjectAndDesign.Main.DesignGuides.Title"></span>
                            </div>
                            <pe-collapse
                                [container]="'box-section-content'"
                                [collapsed]="sectionsCollapsed.designGuide.collapsed"
                                (collapsedChange)="onSectionCollapsedChange(sectionsCollapsed.designGuide, $any($event).detail)">
                            </pe-collapse>
                        </div>

                        <div class="box-section-content">
                            <div class="container-content">
                                <div class="design-guides-button"
                                    *ngFor="let designGuide of designGuides;  trackBy : trackApplicationById"
                                    (click)="openDesignGuide(designGuide.Url)">
                                    <div class="image-container">
                                        <span [ngClass]="'aplication-image sprite sprite-' + designGuide.Image"></span>
                                    </div>
                                    <div class="content-container">
                                        <span class="text design-type" l10n="Agito.Hilti.Profis3.ProjectAndDesign.DesignGuide.{{ designGuide.DisplayKey }}"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="box-section box-section-white designs main-container"
                        *ngIf="((designView != DesignView.templates && hasDesign || designView == DesignView.templates && hasTemplate) && !offlineService.isOffline) || (designView == DesignView.templates && offlineService.isOffline)">
                        <div class="box-section-header all-designs"
                            *ngIf="designView == DesignView.drafts || designView == DesignView.projects">
                            <div class="box-section-title">
                                <div class="box-section-title-text"
                                    l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.Designs.TitleInProject"></div>
                                <div class="project-name" [ngbTooltip]="selectedProjectDisplayName">
                                    &nbsp;{{selectedProjectDisplayName}}</div>
                            </div>
                        </div>
                        <div class="box-section-header" *ngIf="designView == DesignView.allDesigns">
                            <span class="box-section-title"
                                l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.Designs.Title"
                                style="text-transform: none;"></span>
                        </div>
                        <div class="box-section-header" *ngIf="designView == DesignView.templates">
                            <span class="box-section-title"
                                l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.Designs.TitleMyTemplates"
                                style="text-transform: none;"></span>
                        </div>

                        <div class="box-section-content">
                            <div class="container-content">
                                <div class="top-container">
                                    <div class="search-container">
                                        <div class="search-image-container">
                                            <span class="search-image sprite sprite-search"></span>
                                        </div>
                                        <input id="project-and-design-main-search-textfield"
                                            class="search-input" [(ngModel)]="designSearchValue"
                                            name="project-and-design-main-search-textfield" (blur)="searchInputBlur()"
                                            placeholder="{{translate('Agito.Hilti.Profis3.ProjectAndDesing.' + (designView == DesignView.templates ? 'TemplateSearchPlaceholder' : 'SearchPlaceholder'))}}"
                                            type="text" />
                                        <button id="project-and-design-main-search-clear-button"
                                            class="clear-search-button" *ngIf="designSearchValue?.trim().length > 0"
                                            (click)="designSearchValue = null" type="button">
                                            <span class="sprite sprite-x-circle"></span>
                                        </button>
                                    </div>
                                    <div class="group-container btn-group">
                                        <div class="btn-group-toggle">
                                            <input id="project-and-design-main-design-group-button-none" class="btn-check"
                                                    [value]="DesignGroup.none" type="radio" name="designGroup" [(ngModel)]="designGroup">
                                            <label class="group-button design-group-none-button btn btn-default" for="project-and-design-main-design-group-button-none"
                                                [ngbTooltip]="translate('Agito.Hilti.Profis3.ProjectAndDesing.GridView')">
                                                <span class="sprite sprite-group-square"></span>
                                            </label>
                                            <input id="project-and-design-main-design-group-button-list" class="btn-check"
                                                [value]="DesignGroup.list" type="radio" name="designGroup" [(ngModel)]="designGroup">
                                            <label class="group-button design-group-list-button btn btn-default" for="project-and-design-main-design-group-button-list"
                                                [ngbTooltip]="translate('Agito.Hilti.Profis3.ProjectAndDesing.ListView')">
                                                <span class="sprite sprite-group-list"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="sort-designs">
                                        <pe-control-title
                                            class="sort-designs-title"
                                            [focusControlId]="'project-and-design-sort-dropdown'"
                                            [title]="localization.getLocalizedString('Agito.Hilti.Profis3.ProjectAndDesign.SortBy')">
                                        </pe-control-title>

                                        <div class="sort-designs-dropdown-wrapper">
                                            <pe-dropdown
                                                class="sort-designs-dropdown"
                                                [id]="'project-and-design-sort-dropdown'"
                                                [items]="sortDesignsDropdown.items"
                                                [selectedValue]="sortDesignsSelectedValue"
                                                (selectedValueChange)="sortDesignsSelectedValue = $any($event).detail">
                                            </pe-dropdown>
                                        </div>
                                    </div>

                                    <div class="multi-designs" *ngIf="exportReportsAvailable()">
                                        <pe-button [disabled]="canExportReports" [ngbTooltip]="canExportReports" sprite="sprite-printer" id="export-reports" look="Default" (buttonClick)="exportReports()" [text]="translate('Agito.Hilti.Profis3.ProjectAndDesign.ExportReports')"></pe-button>
                                    </div>
                                </div>

                                <div class="designs-list design-group-none" *ngIf="designGroup == DesignGroup.none">
                                    <div class="design-type-container"
                                        *ngFor="let group of groupedDesigns; trackBy : trackGroupByDesignType">
                                        <p class="design-type-name">{{group.designTypeName}}</p>

                                        <div class="design-type-designs" [ngStyle]="{'max-height.px': typeDesignsMaxHeight * (groupedDesignsLimit[group.designType] / groupedDesignsLimitStep) }">
                                            <div class="design" [ngClass]="{ 'disabled': pendingAction != null }"
                                                [attr.data-design-id]="design.id"
                                                *ngFor="let design of group.designs; trackBy : trackDesignTypeById"
                                                [attr.draggable]="pendingAction == null && design.displayDesignType == DisplayDesignType.design"
                                                appDrag config="designDragDisabled" (dragActive)="dragActive($event)">
                                                <div id="project-and-design-main-design-{{design.id}}" tabindex="0"
                                                    (click)="displayDesignClick(design)">
                                                    <div class="image-container"
                                                        [class.show-loading]="isDesignLoading(design)">
                                                        <span appClickStopPropagation *ngIf="isSelectDesignForReportAvailable(design)" class="select-design-checkbox" [class.hide]="!selectedDesigns.length" (click)="selectDesign(design)">
                                                            <input class="checkbox-input" id="check-design-{{design.id}}" [checked]="isDesignSelected(design)" [attr.checked]="isDesignSelected(design)" type="checkbox">
                                                            <span class="checkbox-button sprite" [class.sprite-checkbox]="isDesignSelected(design)"></span>
                                                        </span>
                                                        <span class="model3d-image thumbnail sprite"
                                                            *ngIf="design.thumbnail == null && design.image != null"
                                                            [ngStyle]="design.image"></span>
                                                        <img class="model3d-image thumbnail"
                                                            *ngIf="design.thumbnail != null" [src]="design.thumbnail" alt="3D model thumbnail" />

                                                        <span class="calculation-type" *ngIf="isCalculationTypeTextAvailable(design)">{{design.calculationTypeText}}</span>
                                                        <div class="loading-container">
                                                            <pe-loading [color]="'#524f53'"></pe-loading>
                                                        </div>
                                                    </div>

                                                    <div class="product"
                                                        *ngIf="design.productName != null && design.productName != ''"
                                                        [ngbTooltip]="design.productName">
                                                        <div class="icon"></div>
                                                        <p class="product-name">{{design.productName}}</p>
                                                    </div>

                                                    <div class="design-content-container"
                                                        [ngbTooltip]="getProjectName(design) + ' / ' + design.name + ' / ' + design.regionDesignStandardApprovalNumber">
                                                        <span
                                                            class="name project-name">{{getProjectName(design)}}</span>
                                                        <span class="name design-name">{{design.name}}</span>
                                                        <span class="region-design-standard-approval-number-name">{{design.regionDesignStandardApprovalNumber}}</span>
                                                    </div>
                                                </div>

                                                <div class="options-container design-options"
                                                    *ngIf="design.displayDesignType == DisplayDesignType.design">
                                                    <div class="create-date" (click)="displayDesignClick(design)"
                                                        [ngbTooltip]="design.createdDisplay()">
                                                        <span
                                                            class="create-date-text">{{design.createdDisplay()}}</span>
                                                    </div>
                                                    <div class="dropdown-menu-container options-button options-dropdown-menu-container"
                                                        ngbDropdown display="static" placement="top-left" (click)="dropdownDesignDirection(design.id, false)"
                                                        (openChange)="onDropdownOpenChanged('design-' + design.id, $event)">
                                                        <button
                                                            id="project-and-design-main-design-{{design.id}}-template-options-dropdown-button"
                                                            type="button"
                                                            class="dropdown-menu-button options-dropdown-menu-button"
                                                            [disabled]="pendingAction != null" ngbDropdownToggle
                                                            (click)="setDropdownDirection(design.id, design.displayDesignType)"
                                                            [ngbTooltip]="translate('Agito.Hilti.Profis3.ProjectAndDesing.Main.Designs.Options.TemplateOptions')"
                                                            placement="top">
                                                            <span class="sprite dots"></span>
                                                        </button>
                                                        <div class="dropdown-from-body" ngbDropdownMenu>
                                                            <div id="project-and-design-main-dropdown-{{design.id}}"
                                                                *ngIf="displayedDropdown === ('design-' + design.id)"
                                                                class="design-templates-menu-design dropup-menu-design-right">
                                                                <button
                                                                    id="project-and-design-main-design-{{design.id}}-design-options-settings"
                                                                    type="button"
                                                                    class="options-settings-button"
                                                                    (click)="openDesign(design, OpenDesignType.settings)"
                                                                    [attr.disabled]="pendingAction != null ? true : null"
                                                                    l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.Designs.Options.EditDesignSettings"
                                                                    ngbDropdownItem></button>
                                                                <button
                                                                    id="project-and-design-main-design-{{design.id}}-design-options-copy"
                                                                    type="button"
                                                                    class="options-copy-button"
                                                                    (click)="copyDesignClick(design)"
                                                                    [attr.disabled]="pendingAction != null ? true : null"
                                                                    l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.Designs.Options.CopyDesign"
                                                                    ngbDropdownItem></button>
                                                                <button
                                                                    id="project-and-design-main-design-{{design.id}}-design-options-delete"
                                                                    type="button"
                                                                    class="options-delete-button"
                                                                    (click)="deleteDesign(design)"
                                                                    [attr.disabled]="pendingAction != null ? true : null"
                                                                    l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.Designs.Options.DeleteDesign"
                                                                    ngbDropdownItem></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="options-container template-options"
                                                    *ngIf="design.displayDesignType == DisplayDesignType.template">
                                                    <div class="dropdown-menu-container options-button options-dropdown-menu-container"
                                                        ngbDropdown display="static" placement="top-left" (click)="dropdownDesignDirection(design.id, true)">
                                                        <button
                                                            id="project-and-design-main-design-{{design.id}}-template-options-dropdown-button"
                                                            type="button"
                                                            class="dropdown-menu-button options-dropdown-menu-button"
                                                            [disabled]="pendingAction != null" ngbDropdownToggle
                                                            [ngbTooltip]="translate('Agito.Hilti.Profis3.ProjectAndDesing.Main.Designs.Options.TemplateOptions')">
                                                            <span class="sprite dots"></span>
                                                        </button>
                                                        <div id="project-and-design-main-template-dropdown-{{design.id}}"
                                                            ngbDropdownMenu
                                                            class="design-templates-menu-menu dropup-menu-design-right">
                                                            <button
                                                                id="project-and-design-main-design-{{design.id}}-template-options-dropdown-settings"
                                                                class="settings-button"
                                                                (click)="openTemplate(design, OpenDesignType.settings)"
                                                                [attr.disabled]="pendingAction != null ? true : null"
                                                                l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.Designs.Options.EditTemplateSettings"
                                                                type="button" ngbDropdownItem></button>
                                                            <button
                                                                id="project-and-design-main-design-{{design.id}}-template-options-dropdown-delete"
                                                                class="delete-button"
                                                                (click)="deleteTemplate(design)"
                                                                [attr.disabled]="pendingAction != null ? true : null"
                                                                l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.Designs.Options.DeleteTemplate"
                                                                type="button" ngbDropdownItem></button>
                                                            <button
                                                                id="project-and-design-main-design-{{design.id}}-template-options-dropdown-create-design-button"
                                                                class="create-design"
                                                                (click)="newDesignFromTemplate(design)"
                                                                l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.Designs.Options.CreateDesign"
                                                                type="button" ngbDropdownItem></button>
                                                            <button
                                                                id="project-and-design-main-design-{{design.id}}-template-options-dropdown-edit-template-button"
                                                                class="edit-template"
                                                                (click)="openTemplate(design, OpenDesignType.threeDee)"
                                                                l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.Designs.Options.EditTemplate"
                                                                type="button" ngbDropdownItem></button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="show-more-designs-container">
                                            <button class="show-more-designs button button-default"
                                                (click)="showMoreDesigns(group.designType)"
                                                *ngIf="group.designsCount > group.designs.length"
                                                l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.Designs.ShowMoreDesigns"
                                                type="button"></button>
                                        </div>
                                    </div>
                                </div>

                                <div class="designs-list design-group-list" *ngIf="designGroup == DesignGroup.list">
                                    <div class="design-type-grid"
                                        *ngFor="let group of groupedDesigns; trackBy : trackGroupByDesignType">
                                        <div class="grid-header">
                                            <span>{{group.designTypeName}}</span>
                                        </div>
                                        <div class="grid-body">
                                            <div class="grid-row" [ngClass]="{ 'disabled': pendingAction != null }"
                                                [attr.data-design-id]="design.id"
                                                *ngFor="let design of group.designs; trackBy : trackDesignTypeById"
                                                [attr.draggable]="pendingAction == null && design.displayDesignType == DisplayDesignType.design"
                                                appDrag config="designDragDisabled" (dragActive)="dragActive($event)">
                                                <div class="project-design-wrapper" tabindex="0"
                                                    (click)="displayDesignClick(design)">
                                                    <div class="grid-cell grid-cell-image">
                                                        <div class="image-container"
                                                            [ngClass]="{ 'show-loading': isDesignLoading(design) }">
                                                            <span appClickStopPropagation *ngIf="isSelectDesignForReportAvailable(design)" class="select-design-checkbox" [class.hide]="!selectedDesigns.length" (click)="selectDesign(design)">
                                                                <input class="checkbox-input" id="check-design-{{design.id}}" [checked]="isDesignSelected(design)" [attr.checked]="isDesignSelected(design)" type="checkbox">
                                                                <span class="checkbox-button sprite" [class.sprite-checkbox]="isDesignSelected(design)"></span>
                                                            </span>
                                                            <span class="model3d-image thumbnail sprite sprite-responsive sprite-set-size"
                                                                *ngIf="design.thumbnail == null && design.image != null"
                                                                [ngStyle]="design.image"></span>
                                                            <img class="model3d-image thumbnail"
                                                                *ngIf="design.thumbnail != null"
                                                                [src]="design.thumbnail"
                                                                alt="3D model thumbnail" />

                                                            <span class="calculation-type" *ngIf="isCalculationTypeTextAvailable(design)">{{design.calculationTypeText}}</span>

                                                            <div class="loading-container">
                                                                <pe-loading [color]="'#524f53'"></pe-loading>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="grid-cell grid-cell-name">
                                                        <span class="text design-name-text"
                                                            [ngbTooltip]="getDesignNameProjectName(design)">{{getDesignNameProjectName(design)}}</span>
                                                        <span class="text"
                                                            [ngbTooltip]="design.productName">{{design.productName}}</span>
                                                    </div>
                                                    <div class="grid-cell grid-cell-details">
                                                        <span class="text region-name-text"
                                                            [ngbTooltip]="design.regionText">{{design.regionText}}</span>
                                                        <span class="text design-standard-text"
                                                            [ngbTooltip]="design.designStandardTextApprovalNumberText">{{design.designStandardTextApprovalNumberText}}</span>
                                                    </div>
                                                    <div class="grid-cell grid-cell-date">
                                                        <span class="text create-date-text"
                                                            [ngbTooltip]="design.createdDisplay()">{{design.createdDisplay()}}</span>
                                                    </div>
                                                </div>
                                                <div class="design-options-wrapper">
                                                    <div class="grid-cell grid-cell-options grid-cell-design-options"
                                                        *ngIf="design.displayDesignType == DisplayDesignType.design">
                                                        <div class="dropdown-menu-container options-button options-dropdown-menu-container"
                                                            ngbDropdown placement="top-right">
                                                            <button
                                                                id="project-and-design-main-design-{{design.id}}-template-options-dropdown-button"
                                                                type="button"
                                                                class="dropdown-menu-button options-dropdown-menu-button"
                                                                [disabled]="pendingAction != null" ngbDropdownToggle
                                                                [ngbTooltip]="translate('Agito.Hilti.Profis3.ProjectAndDesing.Main.Designs.Options.TemplateOptions')">
                                                                <span class="sprite dots"></span>
                                                            </button>
                                                            <div ngbDropdownMenu
                                                                class="design-templates-menu-design dropup-menu-design-left">
                                                                <button
                                                                    id="project-and-design-main-design-{{design.id}}-design-options-settings"
                                                                    type="button"
                                                                    class="options-settings-button"
                                                                    (click)="openDesign(design, OpenDesignType.settings)"
                                                                    [attr.disabled]="pendingAction != null ? true : null"
                                                                    l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.Designs.Options.EditDesignSettings"
                                                                    ngbDropdownItem></button>
                                                                <button
                                                                    id="project-and-design-main-design-{{design.id}}-design-options-copy"
                                                                    type="button"
                                                                    class="options-copy-button"
                                                                    (click)="copyDesignClick(design)"
                                                                    [attr.disabled]="pendingAction != null ? true : null"
                                                                    l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.Designs.Options.CopyDesign"
                                                                    ngbDropdownItem></button>
                                                                <button
                                                                    id="project-and-design-main-design-{{design.id}}-design-options-delete"
                                                                    type="button"
                                                                    class="options-delete-button"
                                                                    (click)="deleteDesign(design)"
                                                                    [attr.disabled]="pendingAction != null ? true : null"
                                                                    l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.Designs.Options.DeleteDesign"
                                                                    ngbDropdownItem></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="grid-cell grid-cell-options grid-cell-template-options"
                                                        *ngIf="design.displayDesignType == DisplayDesignType.template">

                                                        <div class="dropdown-menu-container options-button options-dropdown-menu-container"
                                                            ngbDropdown placement="top-right">
                                                            <button
                                                                id="project-and-design-main-design-{{design.id}}-template-options-dropdown-button"
                                                                type="button"
                                                                class="dropdown-menu-button options-dropdown-menu-button"
                                                                [disabled]="pendingAction != null" ngbDropdownToggle
                                                                [ngbTooltip]="translate('Agito.Hilti.Profis3.ProjectAndDesing.Main.Designs.Options.TemplateOptions')">
                                                                <span class="sprite dots"></span>
                                                            </button>
                                                            <div ngbDropdownMenu
                                                                class="design-templates-menu-menu dropup-menu-design-left">
                                                                <button
                                                                    id="project-and-design-main-design-{{design.id}}-template-options-dropdown-settings"
                                                                    class="settings-button"
                                                                    (click)="openTemplate(design, OpenDesignType.settings)"
                                                                    [attr.disabled]="pendingAction != null ? true : null"
                                                                    l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.Designs.Options.EditTemplateSettings"
                                                                    type="button" ngbDropdownItem></button>
                                                                <button
                                                                    id="project-and-design-main-design-{{design.id}}-template-options-dropdown-delete"
                                                                    class="delete-button"
                                                                    (click)="deleteTemplate(design)"
                                                                    [attr.disabled]="pendingAction != null ? true : null"
                                                                    l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.Designs.Options.DeleteTemplate"
                                                                    type="button" ngbDropdownItem></button>
                                                                <button
                                                                    id="project-and-design-main-design-{{design.id}}-template-options-dropdown-create-design-button"
                                                                    class="create-design"
                                                                    (click)="newDesignFromTemplate(design)"
                                                                    l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.Designs.Options.CreateDesign"
                                                                    type="button" ngbDropdownItem></button>
                                                                <button
                                                                    id="project-and-design-main-design-{{design.id}}-template-options-dropdown-edit-template-button"
                                                                    class="edit-template"
                                                                    (click)="openTemplate(design, OpenDesignType.threeDee)"
                                                                    l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.Designs.Options.EditTemplate"
                                                                    type="button" ngbDropdownItem></button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="grid-footer">
                                            <button class="show-more-designs button button-default"
                                                (click)="showMoreDesigns(group.designType)"
                                                *ngIf="group.designsCount > group.designs.length"
                                                l10n="Agito.Hilti.Profis3.ProjectAndDesing.Main.Designs.ShowMoreDesigns"
                                                type="button"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="right-navigation" sortablejs [sortablejsOptions]="sortableRightMenuProject"
            (sortablejsInit)="sortableRightMenuProjectInit($event)">
            <div class="right-navigation-right-container">
                <div class="right-navigation-right-scroll">
                    <div *ngIf="!offlineService.isOffline"
                        class="box-section box-section-white right-navigation-box new-design-box" [attr.data-id]="sectionsCollapsed.designs.control">
                        <div class="box-section-header">
                            <span class="box-section-title drag-handle-static"
                                [ngbTooltip]="localization.getLocalizedString('Agito.Hilti.Profis3.Main.Region.DragToMove')"
                                l10n="Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Designs"></span>
                            <pe-collapse
                                [container]="'box-section-content'"
                                [collapsed]="sectionsCollapsed.designs.collapsed"
                                (collapsedChange)="onSectionCollapsedChange(sectionsCollapsed.designs, $any($event).detail)">
                            </pe-collapse>
                        </div>

                        <div class="box-section-content right-navigation-box-container">
                            <div class="button-column">
                                <button [disabled]="importing || pendingAction != null" id="new-custom-design-button"
                                    class="button button-primary" (click)="newDesignClick()"
                                    l10n="Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Designs.NewDesign"
                                    type="button">
                                </button>

                                <app-import-design [(importing)]="importing" [mode]="importDesign.mode"
                                    [disabled]="importDesign.disabled" [onDesignImporting]="onDesignImporting"
                                    (onDesignImported)="onDesignImported($event)">
                                </app-import-design>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="fileImportsSectionVisible"
                        class="notifications box-section box-section-white right-navigation-box" [attr.data-id]="sectionsCollapsed.fileImports.control">
                        <div class="box-section-header">
                            <span class="box-section-title drag-handle-static"
                                [ngbTooltip]="localization.getLocalizedString('Agito.Hilti.Profis3.Main.Region.DragToMove')"
                                l10n="Agito.Hilti.Profis3.ProjectAndDesign.Navigation.FileImports">
                            </span>

                            <pe-collapse
                                [container]="'box-section-content'"
                                [collapsed]="sectionsCollapsed.fileImports.collapsed"
                                (collapsedChange)="onSectionCollapsedChange(sectionsCollapsed.fileImports, $any($event).detail)">
                            </pe-collapse>
                        </div>

                        <div class="box-section-content right-navigation-box-container">
                            <div class="buttons">
                                <div class="notification-container notification-container-with-button notification-info"
                                    *ngFor="let item of integrationsDocument.RisaDocuments">
                                    <div class="notification-content-wrapper">
                                        <div class="notification-content notification-content-text"
                                            [innerHTML]="integrationsDocument.exportDocumentReportButtonMessage(item.DocumentName, DocumentIntegrationType.Risa)">
                                        </div>
                                    </div>
                                    <div class="notification-content notification-button">
                                        <button class="button button-default button-margin-top"
                                            l10n="Agito.Hilti.Profis3.Notifications.ExportRisa.ButtonContent"
                                            (click)="openNewDesignFromIntegrationDocument(item, DocumentIntegrationType.Risa);" [disabled]="pendingAction != null"
                                            type="button"></button>
                                    </div>
                                </div>

                                <div class="notification-container notification-container-with-button notification-info"
                                    *ngFor="let item of integrationsDocument.RamDocuments">
                                    <div class="notification-content-wrapper">
                                        <div class="notification-content notification-content-text"
                                            [innerHTML]="integrationsDocument.exportDocumentReportButtonMessage(item.DocumentName, DocumentIntegrationType.Ram)">
                                        </div>
                                    </div>
                                    <div class="notification-content notification-button">
                                        <button class="button button-default button-margin-top"
                                            l10n="Agito.Hilti.Profis3.Notifications.ExportRam.ButtonContent"
                                            (click)="openNewDesignFromIntegrationDocument(item, DocumentIntegrationType.Ram);" [disabled]="pendingAction != null"
                                            type="button"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!offlineService.isOffline && inAppCampaigns?.length"
                        class="notifications box-section box-section-white" [attr.data-id]="sectionsCollapsed.inAppMarketing.control">
                        <div class="box-section-header">
                            <span class="box-section-title right-navigation-box-notification-title drag-handle-static"
                                [ngbTooltip]="localization.getLocalizedString('Agito.Hilti.Profis3.Main.Region.DragToMove')"
                                l10n="Agito.Hilti.Profis3.ProjectAndDesing.Navigation.InAppMarketing"></span>
                        </div>

                        <div class="box-section-content">
                            <div class="notification-container notification-info notification-marketing" *ngFor="let inAppCampaign of inAppCampaigns">
                                <iframe *ngIf="inAppCampaign.CampaignType == 0;else inAppImageCampaign" type="text/html" [src]="sanitizer.bypassSecurityTrustResourceUrl(inAppCampaign.Url)"
                                    allowfullscreen frameborder="0" title="video inapp campaign"></iframe>
                                <ng-template #inAppImageCampaign>
                                    <img [src]="inAppCampaign.Url" alt="inAppCampaign">
                                </ng-template>
                                <a class="hilti-styled-button hilti-styled-button-secondary" *ngIf="inAppCampaign.Link"
                                    l10n="Agito.Hilti.Profis3.Notifications.InAppMarketing.ButtonContent"
                                    target="_blank" [href]="inAppCampaign.Link" (click)="trackInAppCampaignClick(inAppCampaign)"
                                ></a>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!offlineService.isOffline"
                        class="notifications box-section box-section-white" [attr.data-id]="sectionsCollapsed.notificationsMainRight.control">
                        <div class="box-section-header">
                            <span class="box-section-title right-navigation-box-notification-title drag-handle-static"
                                [ngbTooltip]="localization.getLocalizedString('Agito.Hilti.Profis3.Main.Region.DragToMove')"
                                l10n="Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Notifications"></span>
                            <div class="notification-indicator-wrapper"
                                [ngClass]="{ 'notification-indicator-wrapper-hidden': !sectionsCollapsed.notificationsMainRight.collapsed }" *ngIf="sectionsCollapsed.notificationsMainRight.collapsed">
                                <div class="notification-indicator-container notification-{{NotificationTypeName[NotificationType.info]}}"
                                    *ngIf="getNotificationCount(NotificationType.info) > 0">
                                    <div class="notification-indicator-icon sprite sprite-{{NotificationTypeImage[NotificationType.info]}}"></div>
                                    <div class="notification-indicator-content">
                                        {{getNotificationCount(NotificationType.info)}}</div>
                                </div>

                                <div class="notification-indicator-container notification-{{NotificationTypeName[NotificationType.alert]}}"
                                    *ngIf="getNotificationCount(NotificationType.alert) > 0">
                                    <div class="notification-indicator-icon sprite sprite-{{NotificationTypeImage[NotificationType.alert]}}"></div>
                                    <div class="notification-indicator-content">
                                        {{getNotificationCount(NotificationType.alert)}}</div>
                                </div>
                            </div>
                            <pe-collapse
                                [container]="'box-section-content'"
                                [collapsed]="sectionsCollapsed.notificationsMainRight.collapsed"
                                (collapsedChange)="onSectionCollapsedChange(sectionsCollapsed.notificationsMainRight, $any($event).detail)">
                            </pe-collapse>
                        </div>

                        <div class="box-section-content">
                            <!-- Message notifications -->
                            <div class="notification-container notification-{{NotificationTypeName[item.type]}}"
                                *ngFor="let item of notifications">
                                <div class="notification-icon sprite sprite-{{NotificationTypeImage[item.type]}}"></div>
                                <div class="notification-content notification-content-text">{{item.message}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
