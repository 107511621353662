// C2C-10498: cleanup needed!
import {
    UIProperty as UIPropertyC2C
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { UnitGroup, UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import {
    PropertyMetaData, UIPropertyId
} from '@profis-engineering/pe-ui-shared/properties/properties';

/**
 * @deprecated DO NOT USE FOR NEW CODE. Overridden in pe-ui-pe.
 */
export class UnitHelperPE {

    public getPrecision_BaseMaterial_CylinderStrength(unit: Unit) {
        return this.getPrecision_BaseMaterial_CubeStrength(unit);
    }

    public getPrecision_BaseMaterial_PrismStrength(unit: Unit) {
        return this.getPrecision_BaseMaterial_CubeStrength(unit);
    }

    public getPrecision_BaseMaterial_CubeStrength_Asad(unit: Unit) {
        return this.getPrecision_BaseMaterial_CubeStrength(unit);
    }

    public getPrecision_BaseMaterial_CylinderStrength_Asad(unit: Unit) {
        return this.getPrecision_BaseMaterial_CubeStrength(unit);
    }

    public getPrecision_Profile_CustomHeight(unit: Unit) {
        switch (unit) {
            case Unit.mm:
                return 0;
            case Unit.cm:
                return 0;
            case Unit.m:
                return 1;
            case Unit.ft:
                return 1;
            case Unit.mi:
                return 1;
            default:
                return 0;
        }
    }

    public getPrecision_Profile_CustomWidth(unit: Unit) {
        return this.getPrecision_Profile_CustomHeight(unit);
    }

    public getPrecision_Profile_CustomFlangeThickness(unit: Unit) {
        return this.getPrecision_Profile_CustomHeight(unit);
    }

    public getPrecision_Profile_CustomThickness(unit: Unit) {
        return this.getPrecision_Profile_CustomHeight(unit);
    }

    public getPrecision_Profile_CustomHeight_Asad(unit: Unit) {
        return this.getPrecision_Profile_CustomHeight(unit);
    }

    public getPrecision_Profile_CustomWidth_Asad(unit: Unit) {
        return this.getPrecision_Profile_CustomHeight(unit);
    }

    public getPrecision_Profile_CustomFlangeThickness_Asad(unit: Unit) {
        return this.getPrecision_Profile_CustomHeight(unit);
    }

    public getPrecision_Profile_CustomThickness_Asad(unit: Unit) {
        return this.getPrecision_Profile_CustomHeight(unit);
    }

    public getPrecision_Loads_SeismicDLSShear(unit: Unit) {
        switch (unit) {
            case Unit.mm:
                return 1;
            case Unit.cm:
                return 2;
            case Unit.inch:
                return 2;
            case Unit.ft:
                return 2;
            default:
                return 0;
        }
    }

    public getPrecision_Loads_SeismicDLSTension(unit: Unit) {
        return this.getPrecision_Loads_SeismicDLSShear(unit);
    }

    public getPrecision_Loads_CompressiveStress(unit: Unit) {
        switch (unit) {
            case Unit.Nmm2:
                return 2;
            case Unit.PSI:
                return 2;
            case Unit.KSI:
                return 2;
            default:
                return 0;
        }
    }

    public getPrecision_Application_WindVelocityPressure(unit: Unit) {
        switch (unit) {
            case Unit.Nmm2:
                return 6;
            case Unit.PSI:
                return 6;
            case Unit.KSI:
                return 6;
            case Unit.kNm2:
                return 6;
            case Unit.Nm2:
                return 6;
            case Unit.kgfcm2:
                return 6;
            default:
                return 0;
        }
    }

    public getPrecision_BaseMaterial_BrickStrength(unit: Unit) {
        switch (unit) {
            case Unit.Nmm2:
                return 0;
            case Unit.PSI:
                return 0;
            case Unit.KSI:
                return 3;
            default:
                return 0;
        }
    }

    public getPrecision_Application_WindPressure(unit: Unit) {
        switch (unit) {
            case Unit.Nmm2:
                return 6;
            case Unit.PSI:
                return 6;
            case Unit.KSI:
                return 6;
            case Unit.kNm2:
                return 6;
            case Unit.Nm2:
                return 6;
            case Unit.kgfcm2:
                return 6;
            default:
                return 0;
        }
    }

    public getPrecision_Application_WindUplift(unit: Unit) {
        switch (unit) {
            case Unit.Nmm2:
                return 6;
            case Unit.PSI:
                return 6;
            case Unit.KSI:
                return 6;
            case Unit.kNm2:
                return 6;
            case Unit.Nm2:
                return 6;
            case Unit.kgfcm2:
                return 6;
            default:
                return 0;
        }
    }

    public getPrecision(unit: Unit, uiProperty?: UIPropertyC2C) {
        let extraPrecision = 0;

        if (uiProperty != null) {
            let precisionFunctionName: string;

            if (PropertyMetaData.getById(uiProperty as UIPropertyId) != null) {
                precisionFunctionName = 'getPrecision_' + PropertyMetaData.getById(uiProperty as UIPropertyId).name;
            }

            if ((this as any)[precisionFunctionName] != null) {
                extraPrecision = (this as any)[precisionFunctionName](unit) ?? 0;
            }
        }

        return this.getUnitOnlyPrecision(unit) + extraPrecision;
    }


    private getPrecision_BaseMaterial_CubeStrength(unit: Unit) {
        switch (unit) {
            case Unit.Nmm2:
                return 1;
            case Unit.PSI:
                return 1;
            case Unit.KSI:
                return 1;
            default:
                return 0;
        }
    }

    private getUnitOnlyPrecision(unit: Unit) {
        if (this.getUnitGroupFromUnit(unit) == UnitGroup.ForcePerLength) {
            // force per length
            // always 2
            return 2;
        }

        if (this.getUnitGroupFromUnit(unit) == UnitGroup.MomentPerLength) {
            // moment per length
            // always 2
            return 2;
        }

        switch (unit) {
            // area
            case Unit.mm2:
                return 0;
            case Unit.cm2:
                return 2;
            case Unit.inch2:
                return 6;
            case Unit.ft2:
                return 4;

            // length
            case Unit.mm:
                return 1;
            case Unit.cm:
                return 2;
            case Unit.inch:
                return 3;
            case Unit.ft:
                return 2;
            case Unit.m:
                return 4;
            case Unit.mi:
                return 6;

            // stress
            case Unit.Nmm2:
                return 0;
            case Unit.Nm2:
                return 0;
            case Unit.PSI:
                return 0;
            case Unit.KSI:
                return 3;
            case Unit.kNm2:
                return 0;
            case Unit.kgfcm2:
                return 0;
            case Unit.PSF:
                return 0;

            // temperature
            case Unit.C:
                return 0;
            case Unit.F:
                return 0;

            // force
            case Unit.N:
                return 0;
            case Unit.daN:
                return 1;
            case Unit.kN:
                return 3;
            case Unit.lb:
                return 0;
            case Unit.Kip:
                return 3;
            case Unit.kgf:
                return 0;

            // density
            case Unit.kg_m3:
            case Unit.lb_ft3:
                return 2;

            // moment
            case Unit.Nm:
                return 0;
            case Unit.daNm:
                return 1;
            case Unit.kNm:
                return 3;
            case Unit.in_lb:
                return 0;
            case Unit.ft_lb:
                return 3;
            case Unit.in_kip:
                return 6;
            case Unit.ft_kip:
                return 5;
            case Unit.kgfcm:
                return 0;

            // angle
            case Unit.degree:
                return 1;
            case Unit.rad:
                return 6;

            // percentage
            case Unit.percent:
                return 2;

            // velocity
            case Unit.m_s:
                return 1;

            // area per length
            case Unit.mm2_m:
                return 0;
            case Unit.cm2_m:
            case Unit.inch2_ft:
                return 2;


            // items per area
            case Unit.item_mm2:
                return 0;
            case Unit.item_cm2:
            case Unit.item_m2:
                return 2;
            case Unit.item_inch2:
                return 6;
            case Unit.item_ft2:
                return 4;

            // Specific weight
            case Unit.N_mm3:
                return 0;
            case Unit.kN_m3:
            case Unit.lbf_ft3:
                return 2;

            // volume
            case Unit.mm3:
                return 0;
            case Unit.cm3:
                return 2;
            case Unit.m3:
                return 2;
            case Unit.inch3:
                return 6;
            case Unit.ft3:
                return 4;

            case Unit.None:
                return 6;

            // default
            default:
                return 6;
        }
    }

    private getUnitGroupFromUnit(unit: Unit) {
        return (Math.floor(unit / 100) * 100) as UnitGroup;
    }
}
