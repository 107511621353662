<div class="modal-header">
    <h3 class="modal-title" l10n="Agito.Hilti.Profis3.UserSettings.Title"></h3>

    <button id="user-settings-close-button" type="button" class="close" (click)="close()" [disabled]="submitted">
        <span>&times;</span>
    </button>
</div>

<form class="form" #ngForm="ngForm" (ngSubmit)="save(ngForm)" novalidate>
    <div class="modal-body">
        <div class="section">
            <div class="section-row">
                <div class="section-column user-settings-column">
                    <p class="title-strike title" l10n="Agito.Hilti.Profis3.UserSettings.General"></p>

                    <pe-text-box
                        [title]="'Agito.Hilti.Profis3.UserSettings.LoginEmail' | l10n:localization.selectedLanguage"
                        [value]="loginEmail"
                        [disabled]="true">
                    </pe-text-box>

                    <pe-text-box
                        [title]="'Agito.Hilti.Profis3.UserSettings.Name' | l10n:localization.selectedLanguage"
                        [value]="name"
                        (valueChange)="name = $any($event).detail"
                        (enterPressed)="save(ngForm)"
                        [disabled]="disabled">
                    </pe-text-box>

                    <button
                        *ngIf="softwareSellingLinkAvailable"
                        type="button"
                        id="user-settings-my-subscription-button"
                        class="button button-default"
                        l10n="Agito.Hilti.Profis3.UserSettings.MySubscriptions"
                        (click)="openSoftwareSellingLink()"
                        [disabled]="submitted">
                    </button>
                </div>

                <div class="section-column user-settings-column">
                    <p class="title-strike title" l10n="Agito.Hilti.Profis3.UserSettings.CompanyDetails"></p>

                    <pe-text-box
                        [title]="'Agito.Hilti.Profis3.UserSettings.CompanyName' | l10n:localization.selectedLanguage"
                        [value]="companyName"
                        (valueChange)="companyName = $any($event).detail"
                        (enterPressed)="save(ngForm)"
                        [disabled]="disabled">
                    </pe-text-box>

                    <pe-text-box
                        [title]="'Agito.Hilti.Profis3.UserSettings.Address' | l10n:localization.selectedLanguage"
                        [value]="address"
                        (valueChange)="address = $any($event).detail"
                        (enterPressed)="save(ngForm)"
                        [disabled]="disabled">
                    </pe-text-box>

                    <pe-text-box
                        [title]="'Agito.Hilti.Profis3.UserSettings.PhoneNumber' | l10n:localization.selectedLanguage"
                        [value]="phoneNumber"
                        (valueChange)="phoneNumber = $any($event).detail"
                        (enterPressed)="save(ngForm)"
                        [disabled]="disabled">
                    </pe-text-box>

                    <pe-text-box
                        [title]="'Agito.Hilti.Profis3.UserSettings.Email' | l10n:localization.selectedLanguage"
                        [value]="email"
                        (valueChange)="email = $any($event).detail"
                        [validators]="emailValidator"
                        (isValid)="emailValid = $any($event).detail"
                        (enterPressed)="save(ngForm)"
                        [disabled]="disabled">
                    </pe-text-box>

                    <pe-text-box
                        [title]="'Agito.Hilti.Profis3.UserSettings.Fax' | l10n:localization.selectedLanguage"
                        [value]="fax"
                        (valueChange)="fax = $any($event).detail"
                        (enterPressed)="save(ngForm)"
                        [disabled]="disabled">
                    </pe-text-box>

                    <pe-text-box
                        [title]="'Agito.Hilti.Profis3.UserSettings.Website' | l10n:localization.selectedLanguage"
                        [value]="website"
                        (valueChange)="website = $any($event).detail"
                        [validators]="urlValidator"
                        (isValid)="websiteValid = $any($event).detail"
                        (enterPressed)="save(ngForm)"
                        [disabled]="disabled">
                    </pe-text-box>
                </div>
            </div>
        </div>
        <div class="sap-account-data-disclaimer">
            <p *ngIf="showSapAccountDisclaimer" [innerHtml]="sapAccountDisclaimer"></p>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" id="user-settings-cancel-button" class="modal-button button button-default"
            l10n="Agito.Hilti.Profis3.UserSettings.Cancel" (click)="close()" [disabled]="submitted">
        </button>

        <button id="user-settings-save-button" type="submit" class="modal-button button button-CTA"
            [disabled]="!formValid || !ngForm.valid || submitted">
            <pe-loading-button
                [loading]="submitted"
                [text]="'Agito.Hilti.Profis3.UserSettings.Save' | l10n:localization.selectedLanguage">
            </pe-loading-button>
        </button>
    </div>
</form>
