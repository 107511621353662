import { BaseDesign } from '@profis-engineering/pe-ui-decking/src/decking/entities/decking-design/base-design';
import {
    DesignMethod
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.Enums';
import { AdvancedCalculationType, HandrailConnectionType } from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { Design } from '../../entities/design';
import { DesignExternalMetaData } from '../../entities/design-external-meta-data';

/**
 * Read the design meta-data from design entity for storage in document service
 * @param design
 */
export function getMetaDataFromDesign(design: Design): DesignExternalMetaData {
    const ret: DesignExternalMetaData = {
        id: design.id,
        name: design.designName,
        region: design.region.id,
        standard: design.designStandardId,
        designType: design.designType.id,
        approvalNumber: design.approvalNumber,

        productName: '',
        designMethod: DesignMethod.None,
        calculationType: AdvancedCalculationType.Unknown,
        handrailConnectionType: HandrailConnectionType.Unknown
    };

    if (design.isC2C) {
        ret.productName = design.productNameC2C;
    }
    else {
        ret.productName = design.anchorType?.name != null && design.anchorSize?.name != null
            ? design.anchorType.name + ' ' + design.anchorSize.name
            : null;
        ret.designMethod = design.designMethodGroup.id;
        ret.calculationType = design.calculationType;
        ret.handrailConnectionType = design.handrailConnectionType
    }

    return ret;
}

/**
 * Read the design meta-data from deckingDesign entity for storage in document service
 */
export function getMetaDataFromDeckingDesign(deckingProject: BaseDesign): DesignExternalMetaData {
    const ret: DesignExternalMetaData = {
        id: deckingProject.id,
        name: deckingProject.name,
        region: deckingProject.settings.region.id,
        standard: 0,
        designMethod: deckingProject.settings.designMethod.id,
        productName: '',
        designType: 106,
        approvalNumber: '',
        calculationType: null,
        handrailConnectionType: null
    };
    return ret;
}
