import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';

export class DragActiveEvent {
    event: DragEvent;
    active: boolean;

    constructor(event: DragEvent, active: boolean) {
        this.event = event;
        this.active = active;
    }
}

@Directive({
    selector: '[appDrag]'
})
export class DragDirective {

    @Input()
    public disabled: boolean;

    @Output()
    public dragActive: EventEmitter<DragActiveEvent> = new EventEmitter<DragActiveEvent>();

    constructor(
        public elementRef: ElementRef
    ) { }

    @HostListener('dragstart', ['$event'])
    public onDragStart(event: DragEvent): void {
        if (this.disabled) {
            return;
        }
        this.dragActive.emit(this.prepareEventObject(event, true));
    }

    @HostListener('dragend', ['$event'])
    public onDragEnd(event: DragEvent): void {
        if (this.disabled) {
            return;
        }
        this.dragActive.emit(this.prepareEventObject(event, false));
    }

    private prepareEventObject(event: DragEvent, active: boolean): DragActiveEvent {
        return new DragActiveEvent(event, active);
    }

}
