<div *ngIf="treeData" class="tree-container">

    <ng-container *ngTemplateOutlet="TreeNode; context:{ list: [filteredData], level: 0}">
    </ng-container>

    <ng-template #TreeNode let-list="list" let-level="level">
        <ng-container *ngFor="let item of list">

            <div [style.paddingLeft.px]="(level+0.5)*NODE_PADDING" (click)="selectNode(item)"
                [ngClass]="{'selected': isSelected(item), 'disabled': item.disabled}"
                id="{{filteredData.id+'-'+item.id}}" class="node level-{{level}}"
                [attr.data-parentId]="item.parentId ?? 'root'" tabindex="0"
                (keypress)="$event.keyCode == 13 || $event.keyCode == 32 ? selectNode(item) : undefined" appDrop
                [active]="item.dropActive && !item.readOnly" (designDrop)="dropEvent($event)"
                [ngbTooltip]="ngbTooltipTemplate(item.tooltip, itemTooltipTemplate)">

                <ng-template #itemTooltipTemplate>
                    <pe-content-tooltip [tooltip]="item.tooltip"></pe-content-tooltip>
                </ng-template>

                <span *ngIf="!hasChild(item)" class="selector"></span>

                <span class="node-toggle sprite sprite-arrow-tree" id="{{item.id + '-expand-collapse'}}"
                    *ngIf="hasChild(item)" [ngClass]="{'arrow-down': item.expanded}"
                    (click)="$event.stopPropagation();toggleExpand(item)">
                </span>

                <span class="node-content" [ngClass]="{'read-only': item.readOnly}">
                    <span [ngClass]="'sprite sprite-'+item.icon" class="icon"></span>
                    <span id="{{item.id + '-name'}}" class="text">{{item.name}}</span>
                </span>
                <span class="space"></span>
                <span class="context-menu-trigger" *ngIf="isMenuAvailable(item)">
                    <pe-context-menu
                        [menu]="item.contextMenu"
                        [icon]="'sprite sprite-ellipsis-vertical'"
                        [onMenuToggled]="onMenuToggled"
                        [fixedPosition]="dropdownMenufixedPosition"></pe-context-menu>
                </span>
                <!-- Virtual Tour Icon -->
                <span class="context-menu-trigger" *ngIf="isVirtualTourMenuSelected(item)">
                    <button class="option" ngbDropdownItem>
                        <span class="icon virtual-tour-icon">
                            <span class="sprite sprite-ellipsis-vertical"></span>
                        </span>
                    </button>
                </span>
            </div>

            <ng-container *ngIf="item.subItems?.length>0 && item.expanded && !disabled">
                <ng-container *ngTemplateOutlet="TreeNode; context:{ list: item.subItems, level: level + 1}">
                </ng-container>
            </ng-container>

        </ng-container>
    </ng-template>
</div>