<div class="content-group" *ngIf="dataLoaded">
    <div class="content">
        <p class="title-strike" l10n="Agito.Hilti.Profis3.ApplicationSettings.AdvancedCalculation.DefaultCalculationMethod">
        </p>

        <div class="content-controls calculation-method">
            <pe-radio-button
                class="control"
                [id]="calculationMethodState.id"
                [title]="calculationMethodState.title"
                [items]="calculationMethodState.items"
                [selectedValue]="advancedBaseplateCalculationValues.calculationMethodState"
                (selectedValueChange)="advancedBaseplateCalculationValues.calculationMethodState = $any($event).detail"
                [disabled]="submitted">
            </pe-radio-button>
        </div>
    </div>

    <app-advanced-calculation-inputs
        [advancedCalculationData]="advancedBaseplateCalculationValues.advancedBaseplateData"
        [advancedCalculationInputs]="advancedBaseplateCalculationValues.advancedBaseplateInputs"
        [selectedSteelGuideline]="advancedBaseplateCalculationValues.advancedBaseplateInputs.selectedSteelGuideline"
        [decimalSeparator]="decimalSeparator"
        [groupSeparator]="groupSeparator"
        [submitted]="submitted">
    </app-advanced-calculation-inputs>
</div>
