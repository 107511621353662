import { Injectable } from '@angular/core';
import { UnitType as Unit, UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { MathServiceBase } from '@profis-engineering/pe-ui-common/services/math.common';
import { UnitValue } from '@profis-engineering/pe-ui-common/services/unit.common';
import {
    UIProperty
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';

import {
    UIProperty as UIPropertyC2C
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { NumberService, NumberType } from './number.service';
import { UnitService } from './unit.service';

enum BufferType {
    Unknown = 0,
    Number = 1,
    Op = 2,
    Unit = 3,
    UnitValue = 4
}

abstract class IBaseToken {
    public type: BufferType;
    constructor(cons: IBaseTokenConstructor) {
        this.type = cons.type;
    }
}

interface IBaseTokenConstructor {
    type: BufferType;
}

interface IUnitTokenConstructor {
    value: string;
}

interface IOpTokenConstructor {
    op: string;
    sign: boolean;
}

interface INumberTokenConstructor {
    valueString: string;
    value: number;
}

interface IUnitValueConstructor {
    value: NumberToken;
    unit: UnitToken;
}

class NumberToken extends IBaseToken {
    valueString: string;
    value: number;

    constructor(value: INumberTokenConstructor) {
        super({ type: BufferType.Number });
        this.value = value.value;
        this.valueString = value.valueString;
    }
}

class OpToken extends IBaseToken {
    value: string;
    sign: boolean;

    constructor(value: IOpTokenConstructor) {
        super({ type: BufferType.Op });
        this.value = value.op;
        this.sign = value.sign;
    }
}

class UnitToken extends IBaseToken {
    value: string;

    constructor(value: IUnitTokenConstructor) {
        super({ type: BufferType.Unit });
        this.value = value.value;
    }
}

class UnitValueToken extends IBaseToken {
    value: NumberToken;
    unit: UnitToken;

    constructor(value: IUnitValueConstructor) {
        super({ type: BufferType.UnitValue });
        this.value = value.value;
        this.unit = value.unit;
    }
}

interface ExpressionTreeNode {
    root: IBaseToken;
    left: ExpressionTreeNode;
    right: ExpressionTreeNode;
}

@Injectable({
    providedIn: 'root'
})
export class MathService extends MathServiceBase {
    private static readonly ops = ['+', '-', '*', '/'];

    private unitAbbrevations: { [key: string]: string[] };
    private unitAbbrToUnit: { [key: string]: string };
    private unitAbbrToUnitOrderedKeys: string[];

    constructor(
        private unit: UnitService,
        private numberService: NumberService,
        private localization: LocalizationService,
        private logger: LoggerService
    ) {
        super();

        this.unitAbbrevations = this.unit.getUnitStringDictionary();
        this.unitAbbrToUnit = this.createUnitTextDictionary();
        this.unitAbbrToUnitOrderedKeys = Object.keys(this.unitAbbrToUnit).map((it2) => it2).sort((a: string, b: string) => {
            if (a.length > b.length) {
                return -1;
            }
            else if (a.length < b.length) {
                return 1;
            }
            return 0;
        });
    }

    /**
     * Tries to compute a math expression and returns result in localized form with unit (takes current language).
     * If an evaluation fails then it returns null.
     * @param value Math expression in string
     * @param unitGroup Target unit group
     * @param unit Output unit
     * @param uiProperty UIProperty.
     * @param precision the precision number for rounding.
     */
    public tryComputeUnitValue(value: string, unitGroup?: UnitGroup, unit?: Unit, uiProperty?: UIProperty | UIPropertyC2C, precision?: number): string {
        try {
            return this.logger.logTrace('MathService.tryComputeUnitValue', () => {
                return this.internalComputeUnitValue(value, unitGroup == UnitGroup.None ? null : unitGroup, unit, uiProperty, precision);
            });

        }
        catch (error) {
            // this.logger.log(`%o`, Enums.LogType.info, error);

            return null;
        }
    }

    /**
     * Computes a math expression and returns result in localized form with unit (takes current language).
     * If expression is in invalid format or contains invalid tokens then an exception is thrown.
     * @param value Math expression in string
     * @param unitGroup Target unit group
     * @param unit Output unit
     * @param uiProperty UIProperty.
     */
    public computeUnitValue(value: string, unitGroup?: UnitGroup, unit?: Unit, uiProperty?: UIProperty) {
        return this.logger.logTrace('MathService.computeUnitValue', () => {
            return this.internalComputeUnitValue(value, unitGroup == UnitGroup.None ? null : unitGroup, unit, uiProperty);
        });
    }

    /**
     * Creates unit dictionary with unit text presentation as key and id as value
     */
    private createUnitTextDictionary() {
        const d: { [key: string]: string } = {};

        for (const a in this.unitAbbrevations) {
            for (const b in this.unitAbbrevations[a]) {
                const u = this.unitAbbrevations[a][b];
                d[u] = a;
            }
        }

        return d;
    }

    /**
     * Checks if value is ascii alphabet character
     * @param val String value
     */
    private isAlphabet(val: string) {
        for (let i = 0; i < val.length; i++) {
            if (!((val.charCodeAt(i) >= 65 && val.charCodeAt(i) < 91)
                || (val.charCodeAt(i) >= 97 && val.charCodeAt(i) < 123))) {
                return false;
            }
        }

        return true;
    }

    /**
     * Checks if value is part of unit marker(units are replace with value `{id}` - ('marker'))
     * @param val Character value
     */
    private isUnitPart(val: string) {
        if (val == '{' || val == '}' || !isNaN(parseInt(val))) {
            return true;
        }

        return false;
    }

    /**
     * Tokenizes input math expression and returns an array of tokens
     * @param value Math expression in string
     */
    private constructTokens(value: string) {
        const items: IBaseToken[] = [];
        value = value.trim();

        // console.log(value);

        // Replace unit text with unit id (create markers)
        for (const a in this.unitAbbrToUnitOrderedKeys) {
            const key = this.unitAbbrToUnitOrderedKeys[a];
            value = value.split(key).join('{' + a + '}'); // value.replace(key, '{' + a + '}');
        }

        // console.log(value);

        const createToken = (bufferType: BufferType, buffer: string) => {
            if (bufferType == BufferType.Unknown && buffer.trim().length == 0) {
                return false;
            }

            switch (bufferType) {
                case BufferType.Number:
                    buffer = buffer.trim();
                    items.push(new NumberToken({ valueString: buffer, value: this.numberService.parse(buffer, NumberType.real) }));
                    return true;
                case BufferType.Unit:
                    buffer = buffer.trim().replace('{', '').replace('}', '');
                    if (this.unitAbbrToUnitOrderedKeys[buffer as unknown as number] == null) {
                        throw new Error('MathService Unknow token: ' + buffer);
                    }

                    items.push(new UnitToken({ value: this.unitAbbrToUnitOrderedKeys[buffer as unknown as number] }));
                    return true;
                default:
                    throw new Error('MathService Unknow token: ' + buffer);
            }
        };

        if (value.length > 0) {
            let buffer = '';
            let bufferType = BufferType.Unknown;
            for (let i = 0; i < value.length; i++) {
                const currChar = value[i];
                const currCharNum = this.numberService.parse(currChar, NumberType.real);

                if (bufferType == BufferType.Unknown && currChar == ' ') {
                    // Skips white space
                    continue;
                }
                else if (bufferType == BufferType.Unknown && MathService.ops.some((it) => it == currChar)) {
                    items.push(new OpToken({ op: currChar, sign: (currChar == '-' || currChar == '+') }));
                }
                else if
                    (
                    ((bufferType == BufferType.Unknown || bufferType == BufferType.Number) && !isNaN(currCharNum == null ? NaN : currCharNum))
                    || (bufferType == BufferType.Number && currChar == this.localization.numberFormat().NumberDecimalSeparator)
                    || (bufferType == BufferType.Number && currChar == this.localization.numberFormat().NumberGroupSeparator)
                ) {
                    bufferType = BufferType.Number;
                    buffer += currChar;
                }
                else if ((bufferType == BufferType.Unknown && currChar == '{') || (bufferType == BufferType.Unit && this.isUnitPart(currChar))) {
                    bufferType = BufferType.Unit;
                    buffer += currChar;
                    if (currChar == '}') {
                        if (createToken(bufferType, buffer)) {
                            buffer = '';
                            bufferType = BufferType.Unknown;
                        }
                    }
                }
                else {
                    if (createToken(bufferType, buffer)) {
                        buffer = '';
                        bufferType = BufferType.Unknown;
                        i -= 1;
                    }
                    else if (buffer.length == 0) {
                        throw new Error(`Invalid symbol ${currChar}`);
                    }
                }
            }

            createToken(bufferType, buffer);
        }

        return items;
    }

    /**
     * Constructs syntax tree
     * @param tokens Tokens
     * @param unitGroup Target unit group
     */
    private constructSyntaxTree(tokens: IBaseToken[], targetUnitGroup: UnitGroup, defaultUnit?: Unit) {
        // Utility function to test if token at given position is same type as given target type
        const check = (type: BufferType, tokenIndex: number) => {
            if (tokenIndex >= 0 && tokenIndex < tokens.length) {
                if (tokens[tokenIndex].type == type) {
                    return tokens[tokenIndex];
                }
            }

            return null;
        };

        // Check if all unit are from the same unitgroup
        for (const a in tokens) {
            if (tokens[a] instanceof UnitToken) {
                const token = tokens[a] as UnitToken;
                const uId = this.unitAbbrToUnit[token.value];
                const ug = this.unit.getUnitGroupFromUnit(parseInt(uId));
                if (Math.abs(ug as number) != Math.abs(targetUnitGroup as number)) {
                    throw new Error('Units in expression are not from the same UnitGroup');
                }
            }
        }

        let out: IBaseToken[] = [];
        let prev: IBaseToken = null;
        // pair numbers with units
        for (let i = 0; i < tokens.length; i++) {
            let token = tokens[i];
            if (prev != null && prev.type == token.type) {
                // Same tokens cannot be in a sequence
                throw new Error('Expression is not valid');
            }
            else if (prev == null && token.type == BufferType.Op && !(token as OpToken).sign) {
                throw new Error('Expression cannot start with a math operator');
            }
            else {
                if (token.type == BufferType.Op && (token as OpToken).value != '-') {
                    // If token is operation and not minus('-') then push it
                    // Minus token is merged into UnitValue because when we convert numbers from a given to the default unit sign has an affect to the conversion outcome (ex. °F -> °C)
                    out.push(token);
                }
                else if (token.type == BufferType.Number || (token.type == BufferType.Op && (token as OpToken).value == '-')) {
                    // If number start with number
                    if (token.type == BufferType.Number) {
                        const next = check(BufferType.Unit, i + 1);
                        if (next != null) {
                            out.push(new UnitValueToken({ value: token, unit: next } as IUnitValueConstructor));
                            i++;
                        }
                        else {
                            out.push(new UnitValueToken({ value: token, unit: new UnitToken({ value: '' }) } as IUnitValueConstructor));
                        }
                    }
                    else {
                        // If number starts with '-' sign, then we push '+' sign and add '-' sign to the number itself.
                        out.push(new OpToken({ op: '+', sign: true })); // push +

                        const next = check(BufferType.Number, i + 1);
                        (next as NumberToken).value *= -1;
                        (next as NumberToken).valueString = (next as NumberToken).value + '';

                        const nextNext = check(BufferType.Unit, i + 2);
                        if (nextNext != null) {
                            out.push(new UnitValueToken({ value: next, unit: nextNext } as IUnitValueConstructor));
                            i += 2;
                        }
                        else {
                            out.push(new UnitValueToken({ value: next, unit: new UnitToken({ value: '' }) } as IUnitValueConstructor));
                            i++;
                        }

                        token = next;
                    }
                }
                else if (prev == null) {
                    throw new Error('Expression can only begin with sign (+,-) or number');
                }
            }

            prev = token;
        }

        if (out[out.length - 1].type == BufferType.Op) {
            throw new Error('Expression cannot end with an operator');
        }

        out = this.normalizeUnits(out, targetUnitGroup, defaultUnit).normalized;

        return {
            tokens: out, unitGroup: targetUnitGroup, tree: this.buildTree(out)
        };
    }

    /**
     * Build syntax tree by grammar definition and returns binary expression tree starting with root element
     * @param out Array of tokens
     */
    private buildTree(out: IBaseToken[]) {
        let currentTokenPos = 0;
        let currentToken: IBaseToken;
        const zeroNode = { root: new UnitValueToken({ unit: new UnitToken({ value: '' }), value: new NumberToken({ value: 0, valueString: '0' }) }), left: null, right: null } as ExpressionTreeNode;

        // Utility function to read next token in buffer
        const nextToken = () => {
            if (currentTokenPos < out.length) {
                return out[currentTokenPos++];
            }

            return null;
        };

        //#region Grammar definition
        const parseMultiplyExpression = (left: ExpressionTreeNode) => {
            const m = parsePrefixExpression(left);
            const r = parseMultiplyExpression2(m);

            return r;
        };

        const parseAddExpression = (left: ExpressionTreeNode): ExpressionTreeNode => {
            const m = parseMultiplyExpression(left);
            let r = parseAddExpression2(m);

            if (r != null) {
                r = ({ root: new OpToken({ op: '+', sign: true }), left: r, right: parseAddExpression(null) || zeroNode } as ExpressionTreeNode);
            }

            if (r == null) {
                return left;
            }
            else {
                return r;
            }
        };

        const parsePrefixExpression = (left: ExpressionTreeNode): ExpressionTreeNode => {
            const curr = currentToken = currentToken == null ? nextToken() : currentToken;
            if (curr == null) {
                return null;
            }

            if (curr.type == BufferType.Op) {
                const curr2 = curr as OpToken;
                if (curr2.value == '+') {
                    currentToken = null;
                    const rAdd = parsePrefixExpression(left);
                    return { root: curr, left: left || zeroNode, right: rAdd } as ExpressionTreeNode;
                }
                else if (curr2.value == '-') {
                    currentToken = null;
                    const rSub = parsePrefixExpression(left);
                    return { root: curr, left: left || zeroNode, right: rSub } as ExpressionTreeNode;
                }
            }

            currentToken = null;
            return { root: curr } as ExpressionTreeNode;
        };

        const parseMultiplyExpression2 = (left: ExpressionTreeNode): ExpressionTreeNode => {
            const curr = currentToken = currentToken == null ? nextToken() : currentToken;
            if (curr == null) {
                return left;
            }

            if (curr.type == BufferType.Op) {
                const curr2 = curr as OpToken;
                if (curr2.value == '*') {
                    currentToken = null;
                    const prefixMul = parsePrefixExpression(left);
                    const mulExp = { root: curr, left: left || zeroNode, right: prefixMul } as ExpressionTreeNode;
                    return parseMultiplyExpression2(mulExp);
                }
                else if (curr2.value == '/') {
                    currentToken = null;
                    const prefixDiv = parsePrefixExpression(left);
                    const divExp = { root: curr, left: left || zeroNode, right: prefixDiv } as ExpressionTreeNode;
                    return parseMultiplyExpression2(divExp);
                }
            }

            return left;
        };

        const parseAddExpression2 = (left: ExpressionTreeNode): ExpressionTreeNode => {
            const curr = currentToken = currentToken == null ? nextToken() : currentToken;
            if (curr == null) {
                return left;
            }

            if (curr.type == BufferType.Op) {
                const curr2 = curr as OpToken;
                if (curr2.value == '+') {
                    currentToken = null;
                    const prefixPlus = parseMultiplyExpression(left);
                    const plusExp = { root: curr, left: left || zeroNode, right: prefixPlus } as ExpressionTreeNode;
                    return parseMultiplyExpression2(plusExp);
                }
                else if (curr2.value == '-') {
                    currentToken = null;
                    const prefixMinus = parseMultiplyExpression(left);
                    const minusExp = { root: curr, left: left || zeroNode, right: prefixMinus } as ExpressionTreeNode;
                    return parseMultiplyExpression2(minusExp);
                }
            }

            return left;
        };
        //#endregion

        return parseAddExpression(null); // root;
    }

    /**
     * Iterates over tokens and converts every UnitValue to default unit from the given unit group.
     * @param tokens Array of tokens
     * @param unitGroup Target unit group
     * @param defaultUnit The default unit
     */
    private normalizeUnits(tokens: IBaseToken[], unitGroup: UnitGroup, defaultUnit?: Unit) {
        const normalizedArray: IBaseToken[] = [];
        let text = '';

        if (defaultUnit == null) {
            defaultUnit = unitGroup != null && unitGroup != UnitGroup.None ? this.unit.getDefaultUnit(unitGroup) : null;
        }

        for (let i = 0; i < tokens.length; i++) {
            if (tokens[i] instanceof OpToken) {
                // If token is an OpToken then we can directly put it into the output buffer
                const t = tokens[i] as OpToken;
                text += t.value;
                normalizedArray.push(t);
            }
            else if (tokens[i] instanceof UnitValueToken) {
                // If token is UnitValue then we need to check whether it contains unit or not.
                // If it contains unit then we need to convert from the given unit to default unit from the given unit group.
                // If unit is not available then we assume that value is in default units already.
                const t = tokens[i] as UnitValueToken;
                if (unitGroup != null) {
                    const t = tokens[i] as UnitValueToken;
                    const convertFrom = t.unit != null && t.unit.value != null && t.unit.value != '' ? (parseInt(this.unitAbbrToUnit[t.unit.value])) : defaultUnit;
                    const convertedNumber = this.unit.convertUnitValueArgsToUnit(t.value.value, convertFrom, defaultUnit);

                    text += convertedNumber;
                    normalizedArray.push(new UnitValueToken({ value: { type: BufferType.Number, value: convertedNumber, valueString: convertedNumber + '' }, unit: new UnitToken({ value: '' }) }));
                }
                else {
                    text += t.value.value;
                    normalizedArray.push(t);
                }
            }
        }

        return { stringValue: text, unit: (defaultUnit != null && defaultUnit != Unit.None) ? this.unit.getUnitStrings(defaultUnit) : '', normalized: normalizedArray };
    }

    /**
     * Traverses and prints a tree in postfix order
     * @param root Root node of an expression tree
     */
    private traverseTreeText(root: ExpressionTreeNode): string {
        if (root == null) {
            return '';
        }

        if (root.root.type == BufferType.Op) {
            const op = (root.root as OpToken);
            return op.value + '(' + this.traverseTreeText(root.left) + ',' + this.traverseTreeText(root.right) + ')';
        }
        else {
            return (root.root as UnitValueToken).value.valueString;
        }
    }

    /**
     * Traverses and evaluate a tree in postfix order
     * @param root Root node of an expression tree
     */
    private traverseTreeEval(root: ExpressionTreeNode): number {
        if (root == null) {
            return 0;
        }

        if (root.root.type == BufferType.Op) {
            const op = (root.root as OpToken);
            const a = this.traverseTreeEval(root.left);
            const b = this.traverseTreeEval(root.right);
            if (op.value == '+') {
                return a + b;
            }
            else if (op.value == '-') {
                return a - b;
            }
            else if (op.value == '*') {
                return a * b;
            }
            else if (op.value == '/') {
                // Uncomment/comment this block below if you want to throw an exception when dividing by 0, otherwise the result will be sign(a) * Infinity
                if (b === 0) {
                    throw new Error('Cannot divide by 0');
                }

                return a / b;
            }
        }

        return (root.root as UnitValueToken).value.value as number;
    }

    /**
     * Computes a math expression and returns result in localized form with unit (takes current language).
     * If expression is in invalid format or contains invalid tokens then an exception is thrown.
     * @param value Math expression in string
     * @param unitGroup Target unit group
     * @param unit Output unit
     * @param uiProperty UIProperty.
     */
    private internalComputeUnitValue(value: string, unitGroup: UnitGroup, defaultUnit?: Unit, uiProperty?: UIProperty | UIPropertyC2C, precision?: number) {
        const t1 = this.logger.logTrace('MathService.constructTokens', () => this.constructTokens(value));
        const t2 = this.logger.logTrace('MathService.constructSyntaxTree', () => this.constructSyntaxTree(t1, unitGroup, defaultUnit));
        const t3 = this.logger.logTrace('MathService.traverseTreeEval', () => this.traverseTreeEval(t2.tree));

        let result = '';
        if (t2.unitGroup != null && t2.unitGroup != UnitGroup.None) {
            if (defaultUnit == null) defaultUnit = this.unit.getDefaultUnit(t2.unitGroup);
            result = this.unit.formatUnitValue(new UnitValue(t3, defaultUnit), precision, null, null, uiProperty);
        }
        else {
            result = this.unit.formatNumber(t3, 6);
        }

        this.logger.logTrace('MathService.traverseTreeTextResult: ' + this.traverseTreeText(t2.tree), null);
        this.logger.logTrace('MathService.traverseTreeEvalResult: ' + t3, null);
        this.logger.logTrace('MathService.computeUnitValueResult: ' + result, null);

        return result;
    }
}
