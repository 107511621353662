import escape from 'lodash-es/escape';

import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import {
    ReleaseNote
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.ProductInformationService.Shared.Entities';

import { LocalizationService } from '../../services/localization.service';

interface IReleaseNote extends ReleaseNote {
    SanitizedTitle: SafeHtml;
    SanitizedContent: SafeHtml;
    isNoteCollapsed: boolean;
}

interface IWhatsNewComponentInput {
    releaseNotes: ReleaseNote[];
}

@Component({
    selector: 'app-whats-new',
    templateUrl: './whats-new.component.html',
    styleUrls: ['./whats-new.component.scss']
})
export class WhatsNewComponent implements OnInit {
    public releaseNotes: IReleaseNote[] = [];
    public viewLimit = 4;

    constructor(
        public localization: LocalizationService,
        private sanitizer: DomSanitizer,
        private modalInstance: ModalInstance<IWhatsNewComponentInput>
    ) { }

    ngOnInit(): void {
        if (this.modalInstance.input?.releaseNotes != null) {
            this.releaseNotes = this.modalInstance.input.releaseNotes.map((inputReleaseNote, i): IReleaseNote => {
                const title = inputReleaseNote.Title.substring(0, 100);

                const SanitizedTitle = this.sanitizer.bypassSecurityTrustHtml(escape(title + ' - ' + this.localization.moment(inputReleaseNote.ReleaseDate).format('LL')));
                const SanitizedContent = this.sanitizer.bypassSecurityTrustHtml(escape(inputReleaseNote.Content));
                const isNoteCollapsed = i != 0;  // Expand the first one!

                return {
                    ...inputReleaseNote,
                    SanitizedTitle,
                    SanitizedContent,
                    isNoteCollapsed
                };
            });
        }
    }

    public close() {
        this.modalInstance.close();
    }

    public viewMore() {
        this.viewLimit += 4;
    }
}
