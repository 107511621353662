<div class="section control-body">
    <!-- body table -->
    <div class="table">
        <table *ngIf="allFeatures != null">
            <caption>License features table</caption>
            <tr class="header">
                <th class="info"></th>
                <th class="standard-premium">
                    <span l10n="Agito.Hilti.Profis3.FreeTrial.Standard"></span>
                </th>
                <th class="standard-premium">
                    <span l10n="Agito.Hilti.Profis3.FreeTrial.Premium"></span>
                </th>
            </tr>
            <tr *ngFor="let feature of allFeatures">
                <td class="info">
                    <span [l10n]="feature.displayKey"></span>
                </td>
                <td class="standard-premium">
                    <span *ngIf="feature.isStandardLicenseFeature" class="sprite sprite-hilti-styled-notification-ok-small"></span>
                </td>
                <td class="standard-premium">
                    <span *ngIf="feature.isPremiumLicenseFeature" class="sprite sprite-hilti-styled-notification-ok-small"></span>
                </td>
            </tr>
        </table>
    </div>
</div>
