<div class="version-details-view">
    <div class="content">
        <div class="version-pe-suite">
            <p class="title-strike">
                Suite Version
            </p>
            <div class="version-details-content">
                Version: {{suiteVersion}} (Build: {{suiteBuildVersion}})
            </div>
        </div>

        <div class="version-modules">
            <p class="title-strike addMargin">
                Module Versions
            </p>

            <table class="table-versions">
                <caption>Module Versions</caption>
                <thead>
                    <tr class="table-version-row-header">
                        <th>Module</th>
                        <th>Version</th>
                        <th>Cluster</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody #container>
                    <tr *ngFor="let module of moduleVersionDetails" class="table-version-row">
                        <td>
                            {{module.name}}

                            <span class="description">
                                {{module.description}}
                            </span>
                        </td>
                        <td class="version">{{module.version}} <span *ngIf="module.buildVersion">(Build: {{module.buildVersion}})</span></td>
                        <td class="version">{{module.cluster}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="version-common">
            <p class="title-strike addMargin">
                Common Service Versions
            </p>

            <table class="table-versions">
                <caption>Common Service Versions</caption>
                <thead>
                    <tr class="table-version-row-header">
                        <th>Service</th>
                        <th>Version</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody #container>
                    <tr *ngFor="let common of commonServiceVersionDetails" class="table-version-row">
                        <td>
                            {{common.name}}

                            <span class="description">
                                {{common.description}}
                            </span>
                        </td>
                        <td class="version">{{common.version}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
