import debounce from 'lodash-es/debounce';
import sortBy from 'lodash-es/sortBy';
import { Subscription } from 'rxjs';

import { Injectable } from '@angular/core';
import { ViewType } from '@profis-engineering/gl-model/gl-model';
import { glModelConstants } from '@profis-engineering/gl-model/gl-model-helper';
import { DesignEvent } from '@profis-engineering/pe-ui-common/entities/design';
import {
    UnitGroup, UnitType as Unit
} from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import {
    UIProperty
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';

import { ICheckboxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/checkbox-props';
import { IControlProps } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { IMainMenuControl, IMenu, IRegion, ITab } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import {
    BaseControl, CheckBox as CheckBoxControl, CustomControl, Menu, Region, Tab, TextBox as TextBoxControl,
    ToggleButtonGroup as ToggleButtonGroupControl
} from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { ITextBoxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/textbox-props';
import { IToggleButtonGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-button-group-props';
import { MenuType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.UserSettings.Shared.Enums';
import { IGetStringOptions } from '@profis-engineering/pe-ui-common/services/localization.common';
import {
    Controls2dEditorBase, IMenu2dContext as IMenu2dContextBase, IMenu2dServiceExtensions, Menu2dServiceBase, Menu2dServiceExtensionsDefault
} from '@profis-engineering/pe-ui-common/services/menu-2d.common';
import { isControlHidden as isTabControlHidden } from '../components/main-menu/MainMenuHelper';
import { Design, IDesignState } from '../entities/design';
import { FavoritesService } from '../services/favorites.service';
import { FeaturesVisibilityInfoService } from '../services/features-visibility-info.service';
import { LocalizationService } from '../services/localization.service';
import { MenuService } from '../services/menu.service';
import { UnitService } from '../services/unit.service';
import { UserSettingsService } from '../services/user-settings.service';
import { UserService } from '../services/user.service';

// eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-explicit-any
export interface IMenu2dContext extends IMenu2dContextBase<any> { }

@Injectable({
    providedIn: 'root'
})
export class MenuService2d extends Menu2dServiceBase {
    public menuExtensions: IMenu2dServiceExtensions;

    constructor(
        private readonly favoritesService: FavoritesService,
        private readonly featuresVisibilityInfoService: FeaturesVisibilityInfoService,
        private readonly localizationService: LocalizationService,
        private readonly menuService: MenuService,
        private readonly unitService: UnitService,
        private readonly userService: UserService,
        private readonly userSettingsService: UserSettingsService
    ) {
        super();
        this.setMenuExtensions(new Menu2dServiceExtensionsDefault());
        this.saveUserSettings = debounce(this.saveUserSettings.bind(this), 2000);
    }

    public setMenuExtensions(menuExtensions: IMenu2dServiceExtensions) {
        this.menuExtensions = menuExtensions ?? new Menu2dServiceExtensionsDefault();
    }

    private get design() {
        return this.userService.design;
    }

    public createMenu(
        context: IMenu2dContext,
        commands: Record<string, (navigationControl: BaseControl) => void>,
    ) {
        const menuStructure = this.menuExtensions.getMenuStructure(this.design);

        this.menuService.clearUpdaters();

        // Pending calculation change subscriptions
        const pendingCalculationChangeSubscriptions: Subscription[] = [];

        const menu2d: IMenu = {
            menuType: MenuType.Menu2D,
            regions: {},
            controls: {},
            selectedTab: this.menuExtensions.getSelectedTab(),
            tabs: {},
            tabsOrder: [],
            dispose: null,
            menuExpanded: false
        };

        for (const navTab of menuStructure.Tabs) {
            const tab = this.createTab(context, menu2d, navTab);

            for (const navRegion of navTab.TabRegions) {
                const tabRegion = this.createTabRegion(menu2d, tab, navRegion);

                const navigationControls = navRegion.Controls?.filter((control) => control.ParentControlName == null) ?? [];
                const childNavigationControls = navRegion.Controls?.filter((control) => control.ParentControlName != null) ?? [];
                for (const navControl of navigationControls) {
                    this.createTabRegionControl(
                        context,
                        menuStructure,
                        menu2d,
                        tabRegion,
                        navControl,
                        childNavigationControls,
                        this.design,
                        commands,
                        pendingCalculationChangeSubscriptions
                    );
                }
            }

            // sort regions
            tab.regionsOrder = sortBy(tab.regionsOrder, (regionId: string) => {
                const regionIndex = this.favoritesService.order2D.findIndex((orderId) => regionId == `region-${orderId}`);
                return regionIndex < 0 ? Number.MAX_SAFE_INTEGER : regionIndex;
            });

            tab.hidden = tab.regionsOrder.every((regionId) => menu2d.regions[regionId].controlsOrder.every(id => isTabControlHidden(menu2d.controls[id])));
        }

        const onAllowedValuesChangedFn = (design: Design, propertyIds: number[]) => {
            if (context.currentView() == ViewType.View2d) {
                this.menuExtensions.onMenuAllowedValuesChanged(
                    design,
                    propertyIds,
                    context.setState
                );
            }
        };

        const onStateChangedFn = (design: Design, state: IDesignState) => {
            if (context.currentView() == ViewType.View2d) {
                this.menuExtensions.onMenuStateChanged(context, design, state);

                const controls = this.menuExtensions.getMenuControls(
                    this.design,
                    state,
                    context.currentMenu(),
                    this.controlName,
                    this.getControlByName
                );
                if (controls != null) {
                    context.setState(menu => this.updateMainMenuControls(menu, controls));
                }
                else {
                    context.setState(menu => {
                        const controlPropsList: { [id: string]: IControlProps } = {};
                        for (const id in this.menuService.onStateChangedUpdaters) {
                            if (this.menuService.onStateChangedUpdaters[id] != null) {
                                controlPropsList[id] = this.menuService.onStateChangedUpdaters[id](state, menu);
                            }
                        }

                        this.menuExtensions.updateMenuControlsProps(
                            controlPropsList,
                            this.design,
                            context.currentMenu(),
                            this.controlName,
                            this.getControlByName
                        );

                        return this.menuService.updateAllMainMenuControls(menu, controlPropsList);
                    });
                }

                context.resize3dAfterUI();
            }
        };
        this.design.onStateChanged(onStateChangedFn);
        this.design.onAllowedValuesChanged(onAllowedValuesChangedFn);

        menu2d.dispose = () => {
            this.design.off(DesignEvent.stateChanged, onStateChangedFn);
            this.design.off(DesignEvent.allowedValuesChanged, onAllowedValuesChangedFn);

            // Unsubscribe from calculation change notifications
            pendingCalculationChangeSubscriptions.forEach(fn => fn.unsubscribe());
        };

        return menu2d;
    }

    public getControlByName(menu: IMenu, name: string) {
        return menu.controls[name];
    }

    public controlName(name: string) {
        return `control-${name}`;
    }

    public updateMainMenuControls<TControl extends IControlProps>(menu: IMenu, controls: { [id: string]: TControl }) {
        return {
            ...menu,
            controls: {
                ...menu.controls,
                ...Object.entries(controls).reduce((obj, [id, control]) => ({ ...obj, [id]: { ...menu.controls[id], ...control } }), {})
            }
        };
    }

    public updateMainMenuControl<TControl extends IControlProps>(menu: IMenu, id: string, control: TControl) {
        return {
            ...menu,
            controls: {
                ...menu.controls,
                [id]: {
                    ...menu.controls[id],
                    ...control
                }
            }
        };
    }

    // Tab
    private createTab(
        context: IMenu2dContext,
        menu2d: IMenu,
        navTab: Tab<BaseControl, string>
    ) {
        const tab: ITab = {
            controlId: `tab-${navTab.Name}`,
            image: `sprite-${navTab.Image}`,
            imageStyle: navTab.IconImage,
            selectedImage: `sprite-${navTab.Image}`,
            selectedImageStyle: navTab.IconImage,
            regionsOrder: [],
            bottomSeparator: false,
            displayKey: navTab.DisplayKey,
            is2d: true,
            tabSelected: () => {
                context.setState(prevState => ({
                    ...prevState,
                    selectedTab: tab.controlId
                }));

                context.tabSelected(tab.controlId);
            },
            width: navTab.Width
        };

        menu2d.tabs[tab.controlId] = tab;
        menu2d.tabsOrder.push(tab.controlId);
        return tab;
    }

    // TabRegion
    private createTabRegion(
        menu2d: IMenu,
        tab: ITab,
        navRegion: Region<BaseControl, string>
    ) {
        const tabRegionId = `region-${navRegion.Name}`;
        const tabRegion: IRegion = {
            controlId: tabRegionId,
            title: this.getTranslation(navRegion.DisplayKey),
            favorite: false,
            preventFavorites: navRegion.PreventFavorites,
            collapsed: this.userSettingsService.settings.applicationCollapsedState.value[tabRegionId] != null && this.userSettingsService.settings.applicationCollapsedState.value[tabRegionId] == true,
            controlsOrder: [],
            is2d: true,
            disabled: this.featuresVisibilityInfoService.isDisabled(navRegion.Feature, this.design.region.id),
            disabledTooltip: this.menuService.getDisabledTooltip(navRegion.Feature)
        };

        menu2d.regions[tabRegion.controlId] = tabRegion;
        tab.regionsOrder.push(tabRegion.controlId);

        return tabRegion;
    }

    // TabRegionControl
    private createTabRegionControl(
        context: IMenu2dContext,
        menuStructure: Menu<BaseControl, string>,
        menu2d: IMenu,
        tabRegion: IRegion,
        navControl: BaseControl,
        childNavigationControls: BaseControl[],
        design: Design,
        commands: Record<string, (navigationControl: BaseControl) => void>,
        pendingCalculationChangeSubscriptions: Subscription[]
    ) {
        const menuCommands = this.menuExtensions.getMenuCommands(design, commands);

        const controlChildNavigationControls = childNavigationControls.filter((control) => control.ParentControlName == navControl.Name);
        const control = this.menuService.createMainMenuControl(
            navControl,
            this.design,
            menu2d,
            menuStructure,
            context.setState,
            pendingCalculationChangeSubscriptions,
            menuCommands,
            this.menuExtensions.getMenuModals(),
            controlChildNavigationControls
        );
        if (control != null) {
            if (navControl.ControlType == 'Checkbox') {
                this.createCheckbox(context, navControl as CheckBoxControl, control);
            }
            else if (navControl.ControlType == 'TextBox') {
                this.createTextBox(context, navControl as TextBoxControl, control);
            }
            else if (navControl.ControlType == 'ToggleButtonGroup') {
                this.createToggleButtonGroup(context, navControl as ToggleButtonGroupControl, control);
            }
            else if (navControl.ControlType == 'CustomControl') {
                this.menuExtensions.createCustomControl(context, navControl as CustomControl<IControlProps, string>, control, control.controlProps);
            }

            menu2d.controls[control.controlProps.controlId] = control.controlProps;
            this.menuService.onStateChangedUpdaters[control.controlProps.controlId] = control.onStateChanged;
            this.menuService.onAllowedValuesChangedUpdaters[control.controlProps.controlId] = control.onAllowedValuesChanged;
            this.menuService.onStructuralCalculationSoftwareChangedUpdaters[control.controlProps.controlId] = control.onStructuralCalculationSoftwareChanged;
            this.menuService.onApplicationSettingChangedUpdaters[control.controlProps.controlId] = control.onApplicationSettingChanged;
            tabRegion.controlsOrder.push(control.controlProps.controlId);
        }
    }

    // Checkbox
    private createCheckbox(
        context: IMenu2dContext,
        navControl: CheckBoxControl,
        control: IMainMenuControl
    ) {
        const checkBoxProps = control.controlProps as ICheckboxProps;

        if (navControl.Name == Controls2dEditorBase.ShowGrid) {
            checkBoxProps.checked = this.userSettingsService.settings.application.controls.showGrid.value == null || this.userSettingsService.settings.application.controls.showGrid.value;
            checkBoxProps.checkedChanged = this.onChangeShowGrid.bind(this, context);
            return;
        }

        this.menuExtensions.createCheckbox(
            context,
            navControl,
            control,
            checkBoxProps
        );
    }

    // TextBox
    private createTextBox(
        context: IMenu2dContext,
        navControl: TextBoxControl,
        control: IMainMenuControl
    ) {
        const textBoxProps = control.controlProps as ITextBoxProps;

        if (navControl.Name == Controls2dEditorBase.GridSpacing) {
            if (this.userSettingsService.settings.application.controls.gridSpacing.value == null) {
                const defaultUnits = this.unitService.getDefaultUnit(UnitGroup.Length);
                const defaultSpace = this.unitService.convertUnitValueArgsToUnit(glModelConstants.defaultSpacing, Unit.mm, defaultUnits);
                textBoxProps.value = this.unitService.formatUnitValueArgs(defaultSpace, defaultUnits);
            }
            else {
                textBoxProps.value = this.unitService.formatUnitAsDefault(this.userSettingsService.settings.application.controls.gridSpacing.value, UnitGroup.Length);
            }

            textBoxProps.valueChanged = (value, exactValue, control) => this.onChangeGridSpacing(context, value, control);
            textBoxProps.commitValue = this.onChangeGridSpacingCommit.bind(this, context);
            textBoxProps.stepperCommitDelay = 0;
            textBoxProps.maxValue = glModelConstants.maxSpacing;
            textBoxProps.minValue = glModelConstants.minSpacing;
            return;
        }

        this.menuExtensions.createTextBox(
            context,
            navControl,
            control,
            textBoxProps
        );
    }

    // ToggleButtonGroup
    private createToggleButtonGroup(
        context: IMenu2dContext,
        navControl: ToggleButtonGroupControl,
        control: IMainMenuControl
    ) {
        this.menuExtensions.createToggleButtonGroup(
            context,
            navControl,
            control,
            control.controlProps as IToggleButtonGroupProps
        );
    }

    // Helpers
    private saveUserSettings() {
        this.userSettingsService.save();
    }

    private onChangeGridSpacingCommit(
        context: IMenu2dContext,
        value: string,
        control: ITextBoxProps
    ) {
        this.onChangeGridSpacing(context, value, control, true);
    }

    private onChangeGridSpacing(
        context: IMenu2dContext,
        value: string,
        control: ITextBoxProps,
        commit?: boolean
    ) {
        const { minSpacing, maxSpacing, defaultSpacing } = glModelConstants;

        if (commit) {
            const spacingUnitValue = this.unitService.parseUnitValue(value, UnitGroup.Length);

            if (spacingUnitValue != null && !Number.isNaN(spacingUnitValue.value)) {
                let spacing = this.unitService.convertUnitValueToInternalUnitValue(spacingUnitValue).value;

                spacing = spacing < minSpacing ? minSpacing : spacing;
                spacing = spacing > maxSpacing ? maxSpacing : spacing;

                const defaultUnit = this.unitService.getDefaultUnit(UnitGroup.Length);
                const num = this.unitService.convertUnitValueArgsToUnit(spacing, this.unitService.getInternalUnit(UnitGroup.Length), defaultUnit);
                value = this.unitService.formatUnitValueArgs(num, defaultUnit, null, null, null, control.uiProperty);

                context.glModelComponent.update({ grid2d: { spacing } });
            }
            else {
                let spacingNumberValue = defaultSpacing;
                if (this.userSettingsService.settings.application.controls.gridSpacing.value == null) {
                    spacingNumberValue = this.unitService.convertUnitValueArgsToUnit(defaultSpacing, Unit.mm, this.unitService.getDefaultUnit(UnitGroup.Length));
                    value = this.unitService.formatUnitValueArgs(spacingNumberValue, this.unitService.getDefaultUnit(UnitGroup.Length), null, null, null, control.uiProperty);
                }
                else {
                    const unitValue = this.unitService.parseUnknownUnitValue(this.userSettingsService.settings.application.controls.gridSpacing.value);
                    spacingNumberValue = this.unitService.convertUnitValueToInternalUnitValue(unitValue)?.value;
                    value = this.unitService.formatUnitValueArgs(this.unitService.convertUnitValueToUnit(unitValue, this.unitService.getDefaultUnit(UnitGroup.Length)).value, this.unitService.getDefaultUnit(UnitGroup.Length), null, null, null, control.uiProperty);
                }

                context.glModelComponent.update({ grid2d: { spacing: spacingNumberValue } });
            }

            this.userSettingsService.settings.application.controls.gridSpacing.value = value;
            this.saveUserSettings();
        }

        context.setState(menu => this.updateMainMenuControl<ITextBoxProps>(menu, this.controlName(Controls2dEditorBase.GridSpacing), { value } as any));
    }

    private onChangeShowGrid(
        context: IMenu2dContext,
        checked: boolean
    ) {
        context.setState(menu => this.updateMainMenuControl<ICheckboxProps>(menu, this.controlName(Controls2dEditorBase.ShowGrid), { checked } as any));

        context.glModelComponent.update({
            grid2d: { visible: checked }
        });

        this.userSettingsService.settings.application.controls.showGrid.value = checked;
        this.saveUserSettings();
    }

    public get isPlateEditable() {
        return !(this.design.properties.get(UIProperty.AnchorPlate_PlateShapeCustom).disabled || this.design.properties.get(UIProperty.AnchorPlate_PlateShapeCustom).hidden) && (this.design.model[UIProperty.AnchorPlate_CustomLayoutPoints] as number[]).length > 2;
    }

    private getTranslation(key: string, opts?: IGetStringOptions): string {
        const localizationExtension = this.menuExtensions.localizationExtension;
        return localizationExtension?.getTranslation ? localizationExtension.getTranslation(key, opts) : this.localizationService.getString(key, opts);
    }
}
