import { Component } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';

interface ILoadsMessagesComponentInput {
    messages: string[];
}

@Component({
    selector: 'app-loads-messages',
    templateUrl: './loads-messages.component.html',
    styleUrls: ['./loads-messages.component.scss']
})
export class LoadsMessagesComponent {

    constructor(
        private modalInstance: ModalInstance<ILoadsMessagesComponentInput>
    ) { }

    public get messages() {
        return this.modalInstance.input?.messages != null
            ? this.modalInstance.input.messages
            : [];
    }

    public close() {
        this.modalInstance.close();
    }
}
