import cloneDeep from 'lodash-es/cloneDeep';
import get from 'lodash-es/get';

import { HttpErrorResponse, HttpHeaders, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    IDesignTemplateResponse, TemplateFolderUser
} from '@profis-engineering/pe-ui-common/entities/template';
import {
    ArchiveProjectTemplateResponseModel, DesignTemplateEntity
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.DesignTemplate';
import {
    ProjectExpandedState
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.Projects';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';
import {
    DesignTemplateServiceBase, IDesignTemplateDocument
} from '@profis-engineering/pe-ui-common/services/design-template.common';
import {
    CantDeleteProjectsBecauseDocumentInUse, CantRestoreProjectsBecauseDocumentInUse, IDefferedImageData, IDefferedRequests
} from '@profis-engineering/pe-ui-common/services/document.common';

import { environment } from '../../environments/environment';
import { PendingAction } from '../components/home-page/home-page.common';
import {
    DesignTemplateFolderDetail, TemplateDocumentRequestModel, TemplateFolderRequestModel
} from '../components/home-page/template-folder';
import { SharedTemplateUser, TemplateUser } from '../entities/template';
import { ApiService } from './api.service';
import { DateTimeService } from './date-time.service';
import { DesignTemplateService, IDesignTemplateFolderUpdate } from './design-template.service';
import { DocumentService } from './document.service';
import { ErrorHandlerService } from './error-handler.service';
import { FeatureVisibilityService } from './feature-visibility.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { ModalService } from './modal.service';
import { OfflineService } from './offline.service';
import { PendingActionService } from './pending-action.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class DesignTemplateWebV2Service extends DesignTemplateService implements DesignTemplateServiceBase {

    private documentThumbnails: Record<string, string> = {};
    private documentThumbnailPromise: Record<string, Promise<unknown>> = {};
    private defferedImageUpload: IDefferedRequests<unknown, unknown> = {};

    constructor(
        private featureVisibilityService: FeatureVisibilityService,
        loggerService: LoggerService,
        dateTimeService: DateTimeService,
        apiService: ApiService,
        documentService: DocumentService,
        errorHandlerService: ErrorHandlerService,
        modalService: ModalService,
        offlineService: OfflineService,
        localizationService: LocalizationService,
        private userService: UserService,
        private pendingActionService: PendingActionService
    ) {
        super('DesignTemplateService', loggerService, dateTimeService, apiService, documentService, errorHandlerService, modalService, offlineService, localizationService);
    }

    public async getDocumentThumbnails(documentIds: string[]): Promise<Record<string, string>> {
        const url = `${environment.documentWebServiceUrl}DocumentDesignTemplate/GetMultipleThumbnail`;

        if (documentIds == null || documentIds.length == 0) {
            return this.documentThumbnails;
        }

        const missingImages: string[] = [];

        for (const id of documentIds) {
            if (this.documentThumbnails[id] == null && this.documentThumbnailPromise[id] == null) {
                missingImages.push(id);
            }
        }

        if (missingImages.length > 0) {
            const promise = this.apiService.request<unknown>(new HttpRequest('POST', url, missingImages), this.getApiOptions());
            for (const id of missingImages) {
                this.documentThumbnailPromise[id] = promise;
            }

            try {
                const response = await promise;
                const data: any = response.body;

                this.logServiceResponse('getDocumentThumbnails', data);

                for (const item of data) {
                    this.documentThumbnails[item.designTemplateDocumentId] =
                        item.thumbnailImageContent != null && item.thumbnailImageContent != '' ? item.thumbnailImageContent : '';
                }

                return this.documentThumbnails;
            }
            finally {
                for (const id of missingImages) {
                    this.documentThumbnailPromise[id] = null;
                }
            }
        }
        else {
            return this.documentThumbnails;
        }
    }

    /**
     * Upload design printscreen image when a design report is ready for export
     * @param designId - desing id
     * @param imageContent - the design image content in base64 encoded xml format
     * @param base64ThumbnailXmlContent - the design thumbnail image content in base64 encoded xml format
     */
    public uploadDesignImage(designId: string, imageContent: string, thumbnailContent: string): Promise<void> {
        return this.uploadDesignImageInternal(designId, imageContent, thumbnailContent);
    }

    /**
     * Update design thumbnail image.
     * @param designId Design id
     * @param thumbnailContent Thumbnail image
     * @param respond If server should send response
     */
    public async updateDesignThumbnailImage(designId: string, thumbnailContent: string, respond: boolean): Promise<void> {
        // If there are no pending requests start normally
        if (this.defferedImageUpload[designId] == null || (this.defferedImageUpload[designId] != null && this.defferedImageUpload[designId].running == null)) {
            this.defferedImageUpload[designId] = {
                running: this.updateDesignThumbnailImagePromise({ designId, thumbnailContent, respond } as IDefferedImageData),
                defferedData: null,
                deffered: null
            };

            await this.defferedImageUpload[designId].running;
        }
        else {
            // Else create deffer object save request data and return deffered promise
            if (this.defferedImageUpload[designId].deffered == null) {
                this.defferedImageUpload[designId].deffered = new Deferred();
            }

            this.defferedImageUpload[designId].defferedData = {
                designId,
                thumbnailContent,
                respond,
                isTemplate: true
            };

            await this.defferedImageUpload[designId].deffered.promise;
        }
    }

    /**
     * Creates promise to update thumbnail image.
     * @param data Request data
     */
    private async updateDesignThumbnailImagePromise(data: IDefferedImageData): Promise<void> {
        try {
            await this.uploadDesignImageInternal(data.designId, null, data.thumbnailContent, data.respond);
        }
        finally {
            this.defferedImageUpload[data.designId].running = null;

            // start new request for deffered and save reference into runningDefferd to resolve it later
            // clear defferd so new requests can be added
            if (this.defferedImageUpload[data.designId].deffered != null) {
                const runningDeffered = this.defferedImageUpload[data.designId].deffered;
                this.defferedImageUpload[data.designId].running = runningDeffered.promise;

                this.updateDesignThumbnailImagePromise(this.defferedImageUpload[data.designId].defferedData as IDefferedImageData)
                    .then(() => runningDeffered.resolve())
                    .catch((err) => runningDeffered.reject(err));

                this.defferedImageUpload[data.designId].deffered = null;
                this.defferedImageUpload[data.designId].defferedData = null;
            }
        }
    }

    /**
     * Update design printscreen image when a design report is ready for export
     * @param id - desing id
     * @param base64XmlContent - the design image content in base64 encoded xml format
     * @param base64ThumbnailXmlContent - the design thumbnail image content in base64 encoded xml format
     */
    private async uploadDesignImageInternal(
        designId: string,
        base64XmlContent: string,
        base64ThumbnailXmlContent: string,
        respond = true
    ): Promise<void> {
        const url = `${this.getUrl()}DocumentDesignTemplate/ThumbnailUpdate`;
        const rData = {
            designTemplateDocumentId: designId,
            thumbnailImageContent: base64ThumbnailXmlContent
        };

        this.logServiceRequest('uploadDocumentImage', rData, url);
        const request = new HttpRequest('POST', url, rData, {
            responseType: 'json'
        });

        try {
            const response = await this.apiService.request<any>(request, this.getApiOptions());

            if (respond) {
                this.logServiceResponse('uploadDocumentImage', response);
                const data = response.body;

                this.documentThumbnails[data.designTemplateDocumentId] = data.thumbnailImageContent;
            }
            else {
                this.documentThumbnails[designId] = base64ThumbnailXmlContent;
            }
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'design-template-service', 'upload design image failed');

            if (!this.handleError(response, { designId }, [428])) {
                if (typeof response != 'object' || !('status' in response) || response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: rData
                    });
                }
            }
        }
    }

    /**
     * Get list of all Design Templates
     * @param options - Api Options
     */
    public async getList(options?: ApiOptions) {
        const url = `${this.getUrl()}DocumentDesignTemplate/List`;

        const response = await this.apiService.request<DesignTemplateEntity[]>(new HttpRequest('GET', url, options));
        this.initialize(response.body);

        return this.templates;
    }

    /**
     * Get list of all Design Templates
     * @param options - Api Options
     */
    public async getListV2(options?: ApiOptions) {
        const url = `${this.getUrl()}DocumentDesignTemplate/ListV2`;

        const response = await this.apiService.request<IDesignTemplateResponse>(new HttpRequest('GET', url, options));
        this.initializeAll(response.body);

        return this.templateV2;
    }

    /**
     * Get specific template
     * @param templateId - template Id
     */
    public async getById(templateId: string) {
        const url = `${this.getUrl()}DocumentDesignTemplate/Get/` + templateId;

        const { body: template } = await this.apiService.request<DesignTemplateEntity>(new HttpRequest('GET', url));

        template.DateCreate = this.dateTimeService.toDate(template.DateCreate);
        return template;
    }

    public async create(
        designTemplateDocument: IDesignTemplateDocument,
        thumbnailId?: string
    ) {
        if (!designTemplateDocument.templateName) {
            throw new Error('Missing Template Name');
        }

        const url = `${this.getUrl()}DocumentDesignTemplate/Create`;

        try {
            if (thumbnailId) {
                const thumbnails = await this.documentService.getDocumentThumbnails([thumbnailId]);
                designTemplateDocument.thumbnailImageContent = thumbnails[thumbnailId];
            }

            const response = await this.apiService.request<string>(new HttpRequest('POST', url, designTemplateDocument), this.getApiOptions());
            if (this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage')) {
                await this.getListV2();
            } else {
                await this.getList();
            }
            this.pendingActionService.setPendingAction(PendingAction.createTemplateSuccess);
            return response.body;
        }
        catch (error: HttpErrorResponse | unknown) {
            this.handleCreateErrors(error, url, designTemplateDocument);

            return undefined;
        }
    }

    public override async createTemplateFolder(templateFolder: TemplateFolderRequestModel): Promise<DesignTemplateFolderDetail> {
        if (!templateFolder) {
            throw new Error('Missing Template Name');
        }
        if (templateFolder.name.length > 250) {
            throw new Error('Max length for folder name is 250 characters!');
        }
        const url = `${this.getUrl()}TemplateFolder/Create`;

        try {
            const response = await this.apiService.request<DesignTemplateFolderDetail>(new HttpRequest('POST', url, templateFolder), this.getApiOptions());
            const item: DesignTemplateFolderDetail = new DesignTemplateFolderDetail(response.body);
            if (item) {
                item.owner = true;
                item.expandedState = { isExpanded: true } as ProjectExpandedState;
                this._flatTemplatesFolders[item.templateFolderId] = item;
                if (item.parentTemplateFolderId) {
                    const parentItem = this.findTemplateFolderById(item.parentTemplateFolderId);
                    item.isSharedByMe = parentItem.isSharedByMe;
                }
            }
            return Promise.resolve(item);
        } catch (response) {
            this.loggerService.logServiceError(response, 'design-template-service', 'post template folder');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 409) {
                    this.errorHandlerService.showExistingTemplateFolderNameModal();
                }
                else if (response.status == 404) {
                    this.errorHandlerService.showTemplateFolderArchivedModal(response, url, templateFolder);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: templateFolder
                    });
                }
            }
            return Promise.reject(response);
        }
    }

    private handleCreateErrors(error: unknown, url: string, designTemplateDocument: IDesignTemplateDocument) {
        if (!this.handleError(error, null, null)) {
            if (typeof error == 'object' && 'status' in error && error.status == 409) {
                const conflictType: unknown = get(error, ['error', 'content', 'conflictType']);

                if (conflictType == 1) {
                    this.modalService.openMaxDesignTemplatesLimitReached();
                    throw error;
                }
                else if (conflictType == 0) {
                    this.errorHandlerService.showExistingTemplateNameModal(error, url, designTemplateDocument);
                    throw error;
                }
            }

            if (typeof error != 'object' || !('status' in error) || error.status != 401) {
                this.modalService.openAlertServiceError({
                    response: error,
                    endPointUrl: url,
                    requestPayload: designTemplateDocument
                });
                throw error;
            }
        }
    }

    public async delete(templateId: string) {
        const url = `${this.getUrl()}DocumentDesignTemplate/Delete/` + templateId;
        this.logServiceRequest('delete template', url);
        await this.apiService.request(new HttpRequest('DELETE', url, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }));
        this._designTemplateArchive = this._designTemplateArchive.filter((it) => it.id != templateId);
    }

    public async update(
        designTemplateDocument: IDesignTemplateDocument
    ) {
        if (!designTemplateDocument.designTemplateDocumentId) {
            throw new Error('Missing design Template Document Id');
        }
        if (!designTemplateDocument.templateName) {
            throw new Error('Missing Template Name');
        }

        const url = `${this.getUrl()}DocumentDesignTemplate/Update`;

        try {
            await this.apiService.request(new HttpRequest('PUT', url, designTemplateDocument), this.getApiOptions());
            await this.getListV2();
        }
        catch (error: HttpErrorResponse | unknown) {
            if (!this.handleError(error, null, null)) {
                if (typeof error == 'object' && 'status' in error && error.status == 409) {
                    this.errorHandlerService.showExistingTemplateNameModal(error, url, designTemplateDocument);
                }
                else if (typeof error != 'object' || !('status' in error) || error.status != 401) {
                    this.modalService.openAlertServiceError({
                        response: error,
                        endPointUrl: url,
                        requestPayload: designTemplateDocument
                    });
                }
            }

            throw error;
        }
    }

    override async updateTemplateFolder(
        designTemplateFolder: IDesignTemplateFolderUpdate
    ) {
        if (!designTemplateFolder.designTemplateDocumentId) {
            throw new Error('Missing design Template Document Id');
        }

        const url = `${this.getUrl()}DocumentDesignTemplate/UpdateFolder`;

        try {
            await this.apiService.request(new HttpRequest('PUT', url, designTemplateFolder), this.getApiOptions());
            await this.getListV2();
        }
        catch (error: HttpErrorResponse | unknown) {
            if (!this.handleError(error, null, null)) {
                if (typeof error != 'object' || !('status' in error) || error.status != 401) {
                    this.modalService.openAlertServiceError({
                        response: error,
                        endPointUrl: url,
                        requestPayload: designTemplateFolder
                    });
                }
            }

            throw error;
        }
    }

    override async getUsersOnTemplateFolderById(templateFolderId: string): Promise<TemplateFolderUser[]> {
        const response = await this.getUsersOnTemplateFolder(templateFolderId);

        return response.map((item) => {
            item.templateFolderId = templateFolderId;
            // item.DateCreate = item.DateCreate != null ? new Date(item.DateCreate as any) : item.DateCreate;
            return item;
        });
    }

    private async getUsersOnTemplateFolder(templateFolderId: string): Promise<TemplateFolderUser[]> {
        const url = `${environment.documentWebServiceUrl}User/templates?templateFolderId=${templateFolderId}`;

        try {
            return (await this.apiService.request<TemplateFolderUser[]>(new HttpRequest('GET', url), this.getApiOptions())).body;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'get users on project by id');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    override addUsersOnTemplateFolderById(newUser: TemplateFolderUser): Promise<void> {
        return this.addUsersOnTemplateFolderByIdInternal(newUser);
    }

    /**
* Adds user to the template folder
* @param data - Template folder user data
*/
    private async addUsersOnTemplateFolderByIdInternal(data: TemplateFolderUser): Promise<void> {
        const url = `${environment.documentWebServiceUrl}User/shareTemplate`;

        const sendData = {
            templateFolderId: data.templateFolderId,
            user: data.user
        };
        this.logServiceRequest('ShareTemplateFolder', sendData, url);

        try {
            await this.apiService.request(new HttpRequest('POST', url, sendData), this.getApiOptions()).then(() => {
                const foundedProject = this.findTemplateFolderById(data.templateFolderId);
                foundedProject.isSharedByMe = true;
                this.setSharedByMeTillParentNode(foundedProject, true);
                this.setSharedByMeTillChildNode(foundedProject, true);
            });
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'add user to the project by id');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.modalService.openAlertError(
                        this.localizationService.getString('Agito.Hilti.Profis3.ShareProject.UserNotAdded.Title'),
                        this.localizationService.getString('Agito.Hilti.Profis3.ShareProject.UserNotAdded.Message'),
                        {
                            response,
                            endPointUrl: url,
                            requestPayload: sendData
                        }
                    );
                }
                else {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: sendData
                    });
                }
            }

            throw response;
        }
    }

    public override userHasAccessToTemlateFolder(templateFolderId: string): Promise<boolean> {
        return this.userHasAccessToTemlateFolderInternal(templateFolderId);
    }

    private async userHasAccessToTemlateFolderInternal(templateFolderId: string): Promise<boolean> {
        const url = `${environment.documentWebServiceUrl}User/isuserontemplatefolder/${templateFolderId}`;

        try {
            return (await this.apiService.request<boolean>(new HttpRequest('GET', url), this.getApiOptions())).body;
        }
        catch (response) {
            console.error(response);

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    public override removeUsersOnTemplateFolderById(data: TemplateFolderUser): Promise<void> {
        return this.removeUsersOnTemplateFolderByIdInternal(data);
    }

    /**
     * Removes user from the project
     * @param data - Project user data
     */
    private async removeUsersOnTemplateFolderByIdInternal(data: TemplateFolderUser): Promise<void> {
        const url = `${environment.documentWebServiceUrl}User/unShareTemplate`;

        const sendData = {
            templateFolderId: data.templateFolderId,
            user: data.user
        };
        this.logServiceRequest('unShareTemplate', sendData, url);

        const request = new HttpRequest('PATCH', url, sendData, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });

        try {
            await this.apiService.request(request, this.getApiOptions()).then(async () => {
                // check if parent project contains any shared users, and if not remove parent also from shared tree
                await this.setSharedByMeForProject(data.templateFolderId);
                // Remove child projects as parent project is unshared
                await this.setSharedByMeForChild(data.templateFolderId);
            });
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'delete user from the project by id');

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: sendData
                    });
                }
            }

            throw response;
        }
    }

    override async renameTemplateFolder(templateFolder: DesignTemplateFolderDetail): Promise<DesignTemplateFolderDetail> {
        if (!templateFolder) {
            throw new Error('Missing Template Name');
        }
        if (templateFolder.name.length > 250) {
            throw new Error('Max length for folder name is 250 characters!');
        }
        const url = `${this.getUrl()}TemplateFolder/rename`;

        try {
            const response = await this.apiService.request<DesignTemplateFolderDetail>(new HttpRequest('PUT', url, templateFolder), this.getApiOptions());
            const item: DesignTemplateFolderDetail = response.body;
            if (item) {
                this._flatTemplatesFolders[item.templateFolderId] = item;
            }
            return Promise.resolve(item);
        } catch (error) {
            if (!this.handleError(error, null, null)) {
                if (typeof error == 'object' && 'status' in error && error.status == 409) {
                    this.errorHandlerService.showExistingTemplateNameModal(error, url, templateFolder);
                }
                else if (typeof error != 'object' || !('status' in error) || error.status != 401) {
                    this.modalService.openAlertServiceError({
                        response: error,
                        endPointUrl: url,
                        requestPayload: templateFolder
                    });
                }
            }
            return Promise.reject(error);
        }
    }

    public override async archiveExistingFolderTemplate(templateFolderId: string): Promise<unknown> {
        const url = `${environment.documentWebServiceUrl}TemplateFolder/archive/${templateFolderId}`;

        this.logServiceRequest('archiveExistingTemplateFolder', url);

        try {
            const response = await this.apiService.request<unknown>(new HttpRequest('DELETE', url), this.getApiOptions());
            const item = response.body as ArchiveProjectTemplateResponseModel[];

            if (item) {
                const deletedTemplateFolder = this.findTemplateFolderById(templateFolderId);
                deletedTemplateFolder.isSharedByMe = false;
                Object.values(deletedTemplateFolder.templates).forEach(x => {
                    const parentFolder = this.findTemplateFolderById(x.templateFolderId);
                    this._designTemplateArchive.push({
                        archived: item.find((it: ArchiveProjectTemplateResponseModel)=> it.templateid == x.templateFolderId)?.archived,
                        created: x.DateCreate,
                        id: x.DesignTemplateDocumentId,
                        designs: 0,//set to 0 since it's not displayed in design grid
                        members: parentFolder.users?.length,
                        name: x.DesignTemplateName,
                        parentProjectId: x.templateFolderId,
                        projectname: parentFolder.name
                    })
                    this._templates = this._templates.filter(template => template.DesignTemplateDocumentId != x.DesignTemplateDocumentId);
                    delete this._templatesV2[x.DesignTemplateDocumentId];
                });
                for (const index in item) {
                    const x = item[index];
                    if(!this._templatesFolderArchive.find(it => it.id == x.templateid)){
                        this._templatesFolderArchive.push({
                            archived: x.archived,
                            created: x.created,
                            designs: x.designs,
                            members: x.members,
                            id: x.templateid,
                            parentId: x.parenttemplateid,
                            name: x.name
                        });
                    }
                    delete this._flatTemplatesFolders[x.templateid];
                }
            }

            return Promise.resolve(item);
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'delete project');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 423) {
                    // throw new CantArchiveProjectsBecauseDocumentInUse();
                }

                if (response.status == 404) {
                    this.errorHandlerService.showTemplateFolderArchivedModal(response, url);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    public override async archiveExistingTemplate(templateDocumentRequestModel: TemplateDocumentRequestModel): Promise<unknown> {
        const url = `${this.getUrl()}DocumentDesignTemplate/Archive`;

        this.logServiceRequest('archiveExistingTemplate', url);
        try {
            const response = await this.apiService.request<unknown>(new HttpRequest('Patch', url, templateDocumentRequestModel), this.getApiOptions());
            const item: any = response.body;
            if (response.status == HttpStatusCode.Ok) {
                templateDocumentRequestModel.designTemplateDocumentIds.forEach(x => {
                    if (this._templatesV2[x]) {
                        const template = this.findById(x);
                        //old homepage
                        this._templates = this._templates.filter(template => template.DesignTemplateDocumentId != x)
                        //new homepage
                        if (this._templatesV2[x]?.templateFolderId) {
                            delete this._flatTemplatesFolders[template.templateFolderId]?.templates[template.DesignTemplateDocumentId];
                        }
                        delete this._templatesV2[x];
                    }
                });

                for (const index in item) {
                    this._designTemplateArchive.push(this.mapArchiveDesignResponseEntityToIDocumentArchive(item[index]));
                }
            }

            return Promise.resolve();
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'delete project');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 423) {
                    //throw error
                }

                if (response.status == 404) {
                    //show template archive popup
                    // this.errorHandlerService.showTemplateFolderArchivedModal(response, url);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    public override async getUsersOnTemplateById(templateId: string): Promise<TemplateUser[]> {
        const response = await this.getUsersOnTemplateByIdInternal(templateId);

        return response.map((item) => {
            item.templateId = templateId;
            item.dateadded = item.dateadded != null ? new Date(item.dateadded as any) : item.dateadded;
            return item;
        });
    }

    /**
* Returns users on the template
* @param templateId The template id
*/
    private async getUsersOnTemplateByIdInternal(templateId: string): Promise<TemplateUser[]> {
        const url = `${environment.documentWebServiceUrl}user/template/${templateId}`;

        try {
            return (await this.apiService.request<TemplateUser[]>(new HttpRequest('GET', url), this.getApiOptions())).body;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'get users on templateId by id');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    public override async addUsersOnTemplate(data: SharedTemplateUser): Promise<void> {
        return this.addUsersOnTemplateInternal(data);
    }

    /**
    * Adds user to the template
    * @param data - template user data
    */
    private async addUsersOnTemplateInternal(data: SharedTemplateUser): Promise<void> {
        const url = `${environment.documentWebServiceUrl}user/shareTemplateDocument`;

        const sendData: SharedTemplateUser = {
            sharedByUserId: this.userService.authentication.userId,
            ...data
        };

        try {
            await this.apiService.request(new HttpRequest('POST', url, sendData), this.getApiOptions()).then(() => {
                const template = this.findById(data.templateId);
                template.isSharedByMe = true;
                const folder = this.findTemplateFolderById(template?.templateFolderId);
                folder.isSharedByMe = true;
                this.setSharedByMeTillParentNode(folder, true);
            });
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'add user to the document by id');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.modalService.openAlertError(
                        this.localizationService.getString('Agito.Hilti.Profis3.ShareProject.UserNotAdded.Title'),
                        this.localizationService.getString('Agito.Hilti.Profis3.ShareProject.UserNotAdded.Message'),
                        {
                            response,
                            endPointUrl: url,
                            requestPayload: sendData
                        }
                    );
                }
                else {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: sendData
                    });
                }
            }

            throw response;
        }
    }

    private setSharedByMeTillChildNode(item: DesignTemplateFolderDetail, isSharedByMe: boolean) {
        item.isSharedByMe = isSharedByMe;
        Object.values(item.templates).forEach((template) => {
            template.isSharedByMe = isSharedByMe;
        });
        for (const subItem in item.subFolders) {
            const folder = item.subFolders[subItem];
            this.setSharedByMeTillChildNode(folder, isSharedByMe);
        }
    }

    public override async removeUsersOnTemplate(data: SharedTemplateUser, markDesign = false): Promise<void> {
        return this.removeUsersOnTemplateInternal(data, markDesign);
    }

    /**
    * Adds user to the document
    * @param data - Document user data
    */
    private async removeUsersOnTemplateInternal(data: SharedTemplateUser, markDesign = false): Promise<void> {
        const url = `${environment.documentWebServiceUrl}user/unShareTemplateDocument`;

        const sendData: SharedTemplateUser = {
            ...data,
            sharedByUserId: this.userService.authentication.userId
        };

        try {
            const request = new HttpRequest('DELETE', url, sendData, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
            await this.apiService.request(request, this.getApiOptions()).then(() => {
                if (markDesign) {
                    const template = this.findById(data.templateId);
                    template.isSharedByMe = false;
                    const project = this.findTemplateFolderById(template?.templateFolderId);
                    this.setSharedByMeForTemplateFolderByDesigns(project.templateFolderId);
                }
            });
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'remove user from the document by id');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.modalService.openAlertError(
                        this.localizationService.getString('Agito.Hilti.Profis3.ShareProject.UserNotAdded.Title'),
                        this.localizationService.getString('Agito.Hilti.Profis3.ShareProject.UserNotAdded.Message'),
                        {
                            response,
                            endPointUrl: url,
                            requestPayload: sendData
                        }
                    );
                }
                else {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: sendData
                    });
                }
            }

            throw response;
        }
    }


    private async setSharedByMeForProject(templateFolderId: string) {
        const item = await this.findTemplateFolderById(templateFolderId);
        const projectUsers = await this.getUsersOnTemplateFolderById(item.templateFolderId);

        if (projectUsers?.length <= 1) {
            // check if any design is marked as shared by me and if not set it false
            if (Object.values(item.templates).some((design) => design.isSharedByMe) || item.isSharedByMe) {
                this._flatTemplatesFolders[item.templateFolderId].isSharedByMe = false;
                item.isSharedByMe = false;
                Object.values(item.templates).forEach((design) => {
                    design.isSharedByMe = false;
                });
            }
        }

        const parentItem = await this.findTemplateFolderById(item.parentTemplateFolderId); // Find the parent item

        if (parentItem) {
            await this.setSharedByMeForProject(parentItem.templateFolderId); // Recursively call for the parent item
        }
    }

    private async setSharedByMeForChild(templateFolderId: string) {
        const item = await this.findTemplateFolderById(templateFolderId);
        const projectUsers = await this.getUsersOnTemplateFolderById(item.templateFolderId);

        if (projectUsers?.length <= 1) {
            this.templatesFoldersFlat[item.templateFolderId].isSharedByMe = false;
            item.isSharedByMe = false;
            Object.values(this.templateV2).filter(x => x.templateFolderId == item?.templateFolderId).forEach((design) => {
                design.isSharedByMe = false;
            });
        }
        for (const subProject in item.subFolders) {
            const project = item.subFolders[subProject];
            await this.setSharedByMeForChild(project.templateFolderId);
        }
    }

    // set isSharedByMe to parent project and its parent project by design id
    private async setSharedByMeForTemplateFolderByDesigns(templateFolderId: string) {
        const project = this.findTemplateFolderById(templateFolderId);
        project.isSharedByMe = false;
        this._flatTemplatesFolders[templateFolderId].isSharedByMe = false;

        if (Object.values(this.templateV2).filter(x => x.templateFolderId == templateFolderId).some(x => x.isSharedByMe)) {
            project.isSharedByMe = true;
        }

        if (project.parentTemplateFolderId) {
            this.setSharedByMeForTemplateFolderByDesigns(project.parentTemplateFolderId);
        }
    }

    public override async toggleExpandTemplateFolder(templateFolderId: string, expandedState: ProjectExpandedState): Promise<void> {
        if (this._flatTemplatesFolders[templateFolderId] == null) {
            throw new Error('Template Folder with ID does not exist.');
        }

        await this.toggleExpandExistingFolder(templateFolderId, expandedState);
    }

    private async toggleExpandExistingFolder(templateFolderId: string, expandedState: ProjectExpandedState): Promise<void> {
        const url = `${environment.documentWebServiceUrl}templateFolder/setExpanded/${templateFolderId}`;
        const sendData = expandedState;

        this.logServiceRequest('toggleExpandExistingTemplateFolder', url, expandedState);

        try {
            await this.apiService.request(new HttpRequest('PUT', url, sendData), this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'put Template Folder setExpanded');

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    public override async restoreExistingTemplateFolder(templateFolderId: string): Promise<string[]>{
        const fToRestoreCandidate = Object.values(this._templatesFolderArchive).find((item) => item.id == templateFolderId);
        if (fToRestoreCandidate == null) {
            throw new Error('Template folder with ID does not exist.');
        }
        if(this.templateFolderNameExists(fToRestoreCandidate.name,fToRestoreCandidate.parentId)){
            this.errorHandlerService.showExistingTemplateFolderNameModal();
            return Promise.reject();
        }
        try{
            const x = await this.restoreExistingArchivedTemplateFolder(templateFolderId);
            if(x){
                const {DesignTemplates, TemplateFolder} = x;

                //old homepage
                const templatesList = Object.values(DesignTemplates);
                this._templates = [...this._templates, ...templatesList];
                this.initialize(this._templates);

                //new homepage
                Object.assign(this._templatesV2, DesignTemplates);
                const newList: { [key: string]: DesignTemplateFolderDetail } = {};
                Object.values(TemplateFolder).forEach(e => {
                    newList[e.templateFolderId] = new DesignTemplateFolderDetail(e);
                    this.templates.filter(x => x.templateFolderId == e.templateFolderId).forEach(x => {
                        newList[e.templateFolderId].templates[x.DesignTemplateDocumentId] = x;
                    });
                });
                Object.assign(this._flatTemplatesFolders, newList);

                //new homepage
                this._templatesFolders = cloneDeep(this.buildTemplateTree(this._flatTemplatesFolders));
            }

             //Remove project from archived project list
             const templateFolderIdsToRemove = Object.values(x.TemplateFolder).map((templateFolder) => templateFolder.templateFolderId);
             this._templatesFolderArchive = this._templatesFolderArchive.filter((x) => !templateFolderIdsToRemove.includes(x.id));
             //Remove project desings from archived designs list
             const templateIdsToRemove = Object.values(x.DesignTemplates).map((template) => template.DesignTemplateDocumentId);
             this._designTemplateArchive = this._designTemplateArchive.filter((x) => !templateIdsToRemove.includes(x.id));
             return Promise.resolve(templateFolderIdsToRemove);
        }
        catch(ex){
            // catch exception code that happens when project cannot be deleted because a file in the project is locked, and fetch additional details from the document service to display to the user whitch document is locked and by whom.
            if (ex instanceof CantRestoreProjectsBecauseDocumentInUse) {
                //const lockedFiles = await this.readLockedDesignsOfProject(projectId);
                //ex.documentsInQuestion = lockedFiles;
            }

            throw ex;
        }
    }

    private async restoreExistingArchivedTemplateFolder(templateFolderId: string): Promise<IDesignTemplateResponse>{
        const url =`${environment.documentWebServiceUrl}TemplateFolder/dearchive/${templateFolderId}`;

        const data = templateFolderId;

        try{
            const response = await this.apiService.request<any>(new HttpRequest('PATCH', url, data));
            this.logServiceRequest('restoreTemplateFolder', url);
            return response.body;
        }
        catch(response){
            this.loggerService.logServiceError(response, 'document-service', 'restore template folder');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 423) {
                    throw new CantRestoreProjectsBecauseDocumentInUse();
                }

                if (response.status == 409) {
                    this.errorHandlerService.showExistingTemplateFolderNameModal();
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    public override async deleteTemplateFolderLocal(templateFolderId: string) : Promise<void> {
        const pToDeleteCandidate = Object.values(this._templatesFolderArchive).find((item) => item.id == templateFolderId);
        if (pToDeleteCandidate == null) {
            throw new Error('Project with ID does not exist.');
        }

        try {
            await this.removeExistingArchivedProject(templateFolderId);

            // remove from archived project and documentslist
            this._templatesFolderArchive = this._templatesFolderArchive.filter((x) => x.id != templateFolderId);
            this._designTemplateArchive = this._designTemplateArchive.filter((x) => x.parentProjectId != templateFolderId);
        }
        catch (ex) {
            //TODO: Implement locking mechanism for templates
            // catch exception code that happens when project cannot be deleted because a file in the project is locked, and fetch additional details from the document service to display to the user whitch document is locked and by whom.
            if (ex instanceof CantDeleteProjectsBecauseDocumentInUse) {
                //const lockedFiles = await this.readLockedDesignsOfProject(projectId);
                //ex.documentsInQuestion = lockedFiles;
            }

            throw ex;
        }
    }

    private async removeExistingArchivedProject(templateFolderId: string) : Promise<void>{
        const url = `${environment.documentWebServiceUrl}TemplateFolder/permanent/${templateFolderId}`;

        this.logServiceRequest('removeExistingProject', url);

        try {
            await this.apiService.request(new HttpRequest('DELETE', url), this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'permanently delete template folder');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 423) {
                    throw new CantDeleteProjectsBecauseDocumentInUse();
                }

                if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    public override async restoreExisitingTemplate(templateId: string) : Promise<void>{
        const tToRestoreCandidate = this._designTemplateArchive.find((item) => (item.id == templateId));

        if(tToRestoreCandidate == null){
            throw new Error('Template with ID does not exist.');
        }

        const x = await this.restoreExisitingArchivedTemplate(templateId);

        if (x) {
            //old homepage
            this._templates = [...this._templates, ...x];
            this.initialize(this._templates);
            //new homepage
            x.forEach((item) => {
                this._templatesV2[item.DesignTemplateDocumentId] = item;
                if (item.templateFolderId) {
                    this._flatTemplatesFolders[item.templateFolderId].templates[item.DesignTemplateDocumentId] = item;
                }
                this._designTemplateArchive = this._designTemplateArchive.filter((it) => (it.id != item.DesignTemplateDocumentId));
            })
            this._templatesFolders = cloneDeep(this.buildTemplateTree(this._flatTemplatesFolders));
        }
    }

    private async restoreExisitingArchivedTemplate(templateId : string) : Promise<DesignTemplateEntity[]>{
        const url = `${environment.documentWebServiceUrl}DocumentDesignTemplate/Dearchive`;

        const data = {DesignTemplateDocumentIds:[templateId]};
        try{
            this.logServiceRequest('removeExistingProject', url);
            const response = await this.apiService.request<any>(new HttpRequest('PATCH',url,data), this.getApiOptions());
            return response.body;
        }
        catch(response){
            this.loggerService.logServiceError(response, 'document-service', 'restore template document');

            if (response instanceof HttpErrorResponse) {
                if (response instanceof HttpErrorResponse) {
                    if (response.status == 409) {
                        this.errorHandlerService.showExistingTemplateNameModal();
                    }
                    else if (response.status != 401) {
                        this.modalService.openAlertServiceError({
                            response,
                            endPointUrl: url
                        });
                    }
                }
            }

            throw response;
        }
    }

    /**
     * Check is there are already template project with the same name
     * @param templateFolderName - Template Project name
     * @param parentTemplateFolderId - Parent project id
     * @param projectId - Project id
     */
    private templateFolderNameExists(templateFolderName: string, parentTemplateFolderId: string, templateFolderId?: string): boolean {
        return Object.values(this._flatTemplatesFolders).some(
            (p) => p.name.toLowerCase().trim() == templateFolderName.toLowerCase().trim() &&
            (p.parentTemplateFolderId == parentTemplateFolderId) &&
            (templateFolderId == null || p.templateFolderId != templateFolderId) &&
            p.owner
        );
    }
}
